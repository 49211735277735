import "./Harita.scss";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
// import CagriGuncelle from "./CagriGuncelle";
import { setCagrilarim } from "./kullaniciCagrilari";
import { Navigate, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Cookies from "universal-cookie";
import Spinner from "../media/spinner.gif";

const Cagrilarim = () => {
  const cookies = new Cookies();
  const cookieUser = cookies.get("user");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const user = useSelector((state) => state.user.value);
  const auth = getAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  const cagrilarim = useSelector(
    (state) => state.kullaniciCagrilari.cagrilarim
  );

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        setIsLoggedIn(false);
      }
    });
  }, [auth]);

  const fetchUserCagrilar = () => {
    setLoading(true);
    axios
      .get(
        `https://rn3ovzqjig.execute-api.eu-central-1.amazonaws.com/api/emergency/createdcalls/${cookieUser.user_id}`
      )
      .then((res) => {
        dispatch(setCagrilarim(res.data));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (cookieUser && cookieUser.user_id) {
      fetchUserCagrilar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (cagri && cookieUser && cagri.user_id !== cookieUser.user_id) {
  //     navigate("/login");
  //   }
  // }, [ cookieUser, navigate]);

  // if any of the cagrilarim's user_id is not equal to cookieUser.user_id, navigate to login page.
  useEffect(() => {
    if (cagrilarim && cagrilarim.length > 0 && cookieUser) {
      const filteredCagrilarim = cagrilarim.filter(
        (c) => c.user_id !== cookieUser.user_id
      );
      if (filteredCagrilarim.length > 0) {
        navigate("/login");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cagrilarim, cookieUser]);

  const convertStatus = (status) => {
    // "0": "Yardım Bekleniyor", "1": "Yardım Yolda", "2": "Yardım Edildi"
    switch (parseInt(status)) {
      case 0:
        return "Yardım Bekleniyor";
      case 1:
        return "Yardım Yolda";
      case 2:
        return "Yardım Edildi";
      case 3:
        return "İptal Edildi";
      default:
        return "Yardım Bekleniyor";
    }
  };

  // <img src={Spinner} alt="loading" style={{ width: "25%" }} />

  if (loading) {
    return (
      <div>
        <p>Çağrılarınız Yükleniyor.</p>
        <img src={Spinner} alt="loading" />
        <p>
          Çağrılarınız yüklenmezse iletişim formu doldurup bize ulaşabilirsiniz.
        </p>
      </div>
    );
  }
  return (
    <div>
      {!isLoggedIn && <Navigate to="/login" replace={true} />}
      {cagrilarim && cagrilarim.length > 0 ? (
        <Table
          striped
          bordered
          hover
          variant="dark"
          style={{ marginTop: "2rem" }}
          className="cagrilarim-table"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Kategori</th>
              <th>Şehir</th>
              <th>Mesaj</th>
              <th>Durum</th>
              <th>Güncelle</th>
              <th>Detaylar</th>
            </tr>
          </thead>
          <tbody>
            {cagrilarim.map((cagri, index) => (
              <tr key={cagri.emergency_call_id}>
                <td>{index + 1}</td>
                <td>{cagri.category}</td>
                <td>{cagri.city}</td>

                <td>
                  {" "}
                  {cagri.message.length > 200
                    ? cagri.message.substring(0, 200) + "..."
                    : cagri.message}
                </td>
                <td>{convertStatus(cagri.status)}</td>
                <td>
                  <Link to={`/cagrilarim/${cagri.emergency_call_id}`}>
                    <Button className="cagri-card-button">
                      Çağrıyı Güncelle
                    </Button>
                  </Link>
                </td>
                <td>
                  <Link to={`/cagri/${cagri.emergency_call_id}`}>
                    <Button className="cagri-card-button">Detaylar</Button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Alert>Henüz bir yardım çağrısı oluşturmadınız.</Alert>
      )}
    </div>
  );
};

export default Cagrilarim;
