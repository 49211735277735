// redux reducer for storing username and email
import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    value: {
      username: "",
      email: "",
      user_id: "",
    },
  },
  reducers: {
    reset: (state, action) => {
      state.value = {
        username: "",
        email: "",
        user_id: "",
      };
    },
    set: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { set, reset } = userSlice.actions;

export default userSlice.reducer;
