import React, { useState } from "react";
import Cookies from "universal-cookie";
import Alert from "@mui/material/Alert";
import axios from "axios";

export default function Iletisim() {
  const cookies = new Cookies();
  const formCookie = cookies.get("form");
  const [postData, addFormData] = useState({
    name: "",
    email: "",
    message: "",
    topic: "oneri",
  });
  const [validationError, setValidationError] = useState();
  const [success, setSuccess] = useState(false);

  const submitForm = () => {
    setValidationError("");
    // check if 1 day passed since last form submission
    if (formCookie && Math.floor(Date.now() / 1000) - formCookie < 86400) {
      setValidationError("Lütfen 24 saat sonra tekrar deneyin.");
      setSuccess(false);
    } else if (postData && !postData.message) {
      setValidationError("Lütfen mesajınızı yazın.");
      setSuccess(false);
    } else if (
      (postData && !postData.email) ||
      (postData.email && !postData.email.includes("@")) ||
      !postData.email.includes(".")
    ) {
      setValidationError("Lütfen geçerli bir email adresi girin.");
      setSuccess(false);
    } else {
      axios
        .post(
          "https://32x0uyol7i.execute-api.eu-central-1.amazonaws.com/api/iletisim",
          {
            ...postData,
            created_at: Math.floor(Date.now() / 1000),
          }
        )
        .then((res) => {
          cookies.set("form", Math.floor(Date.now() / 1000), {
            path: "/",
          });
          setValidationError("");
          setSuccess(true);
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            setSuccess(false);

            setValidationError(
              "Servera bağlanılamıyor. Lütfen daha sonra tekrar deneyin"
            );
          } else {
            setSuccess(false);

            setValidationError(
              "Bilinmeyen bir hata oluştu. Lütfen daha sonra tekrar deneyin"
            );
          }
        });
    }
  };

  const onChange = (e) => {
    addFormData({
      ...postData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <h1>İletişim Formu</h1>
      <div className="form-container">
        <div className="form">
          <div className="input-container">
            <label>Email*</label>
            <input
              type="email"
              className="input"
              label="Email"
              variant="outlined"
              name="email"
              placeholder="Email Adresiniz"
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="input-container">
            <label>Tam Adınız</label>
            <input
              type="text"
              className="input"
              label="Name"
              variant="outlined"
              name="name"
              placeholder="Adınız ve Soyadınız"
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="input-container">
            <label>Konu</label>
            <select
              className="input"
              name="topic"
              onChange={(e) => onChange(e)}
              defaultValue="oneri"
            >
              <option value="oneri">Öneri</option>
              <option value="hata">Hata Bildirimi</option>
            </select>
          </div>
          <div className="input-container">
            <label>Mesajınız*</label>
            <textarea
              className="input textarea"
              rows="4"
              label="Message"
              variant="outlined"
              name="message"
              placeholder="Mesajınız..."
              onChange={(e) => onChange(e)}
            />
          </div>

          <button className="button" onClick={() => submitForm()}>
            Çağrı Oluştur
          </button>

          {validationError && (
            <Alert variant="filled" severity="error">
              {validationError}
            </Alert>
          )}
          {success && (
            <Alert variant="filled" severity="success">
              Mesajınız alındı. En kısa sürede size dönüş yapacağım.
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
}

// {
//     "created_at":1853195,
//     "email":"canoltasign@gmail.com",
//     "topic":"sensin topik",
//     "message":"mesaj mesaj mesaaaj"
// }
