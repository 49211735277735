import React, { useState, useEffect } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { errors } from "../errors/authErrors";
import { useDispatch } from "react-redux";
import { set } from "../auth/userSlice";
import Alert from "@mui/material/Alert";
import axios from "axios";
import Cookies from "universal-cookie";
import "./Login.scss";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

export default function Login(props) {
  const cookies = new Cookies();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitPressed, setSP] = useState(0);
  const [authError, setAuthError] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const getUserInfo = (uid) => {
    axios
      .get(
        `https://bfqidatjs5.execute-api.eu-central-1.amazonaws.com/dev/users/${uid}`
      )
      .then((response) => {
        cookies.set("user", response.data, {
          path: "/",
        });

        dispatch(set(response.data));

        navigate(state?.previousPath || "/");
        // navigate(-1);
      })
      .catch((error) => {
        if (!error.response) {
          // network error
          setAuthError("Bir hata oluştu, daha sonra tekrar deneyin");
        } else {
          setAuthError("Bir hata oluştu, daha sonra tekrar deneyin");
        }
      });
  };

  useEffect(() => {
    if (submitPressed > 0) {
      setAuthError();
      const auth = getAuth();

      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          setAuthError();
          // Signed in
          const user = userCredential.user;

          getUserInfo(user.uid);
          // navigate("/");

          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          // const errorMessage = error.message;
          setAuthError(`${errors[errorCode]}`);
          if (error.message === "EMAIL_NOT_FOUND") {
            setAuthError("Bu email adresiyle kayıtlı bir hesap bulunamadı.");
          }
        });
    }

    // eslint-disable-next-line
  }, [submitPressed]);

  const onChange = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
    }
  };

  return (
    <div className="form-container auth">
      <div className="form">
        <h1>Giriş Yap</h1>
        <input
          className="input"
          label="Email"
          variant="outlined"
          name="email"
          placeholder="Email"
          onChange={(e) => onChange(e)}
        />
        <div className="password-container">
          <input
            className="input"
            label="Password"
            variant="outlined"
            name="password"
            placeholder="Şifre"
            type={showPassword ? "text" : "password"}
            onChange={(e) => onChange(e)}
          />
          <div className="password-icon">
            {showPassword ? (
              <AiFillEyeInvisible
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <AiFillEye onClick={() => setShowPassword(!showPassword)} />
            )}
          </div>
        </div>

        <button className="button" onClick={() => setSP(submitPressed + 1)}>
          Giriş Yap
        </button>
        {/* don't have an account? register */}
        <div className="register">
          <p>
            Hesabın yoksa <a href="/register">kayıt ol</a>
          </p>
          <p>
            Şifreni mi unuttun? <a href="/resetpassword">Şifreni sıfırla</a>
          </p>
        </div>

        {authError && (
          <Alert variant="filled" severity="warning">
            {authError}
          </Alert>
        )}
      </div>
    </div>
  );
}
//sx={{ p: 5 }}
