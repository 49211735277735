export const errors = {
  "auth/claims-too-large":
    "Beklenmedik bir hata oluştu. Lütfen desteğe bağlanın ve tekrar deneyin. (claims-too-large)",
  "auth/email-already-exists": "Bu e-posta ile hesap zaten mevcut",
  "auth/id-token-expired":
    "Beklenmedik bir hata oluştu. Lütfen desteğe bağlanın ve tekrar deneyin. (id-token-expired)",
  "auth/id-token-revoked":
    "Beklenmedik bir hata oluştu. Lütfen desteğe bağlanın ve tekrar deneyin. (id-token-revoked)",
  "auth/insufficient-permission":
    "Beklenmedik bir hata oluştu. Lütfen desteğe bağlanın ve tekrar deneyin. (insufficient-permission)",
  "auth/internal-error":
    "Beklenmedik bir hata oluştu. Lütfen desteğe bağlanın ve tekrar deneyin. (internal-error)",
  "auth/invalid-argument":
    "Beklenmedik bir hata oluştu. Lütfen desteğe bağlanın ve tekrar deneyin. (invalid-argument)",
  "auth/invalid-claims":
    "Beklenmedik bir hata oluştu. Lütfen desteğe bağlanın ve tekrar deneyin. (invalid-claims)",
  "auth/invalid-continue-uri":
    "Beklenmedik bir hata oluştu. Lütfen desteğe bağlanın ve tekrar deneyin. (invalid-continue-uri)",
  "auth/invalid-creation-time":
    "Beklenmedik bir hata oluştu. Lütfen desteğe bağlanın ve tekrar deneyin. (invalid-creation-time)",
  "auth/invalid-credential":
    "Beklenmedik bir hata oluştu. Lütfen desteğe bağlanın ve tekrar deneyin. (invalid-credential)",
  "auth/invalid-disabled-field":
    "Beklenmedik bir hata oluştu. Lütfen desteğe bağlanın ve tekrar deneyin. (invalid-disabled-field)",
  "auth/invalid-display-name": "Lütfen geçerli bir görüntü adı girin",
  "auth/invalid-dynamic-link-domain":
    "Beklenmedik bir hata oluştu. Lütfen desteğe bağlanın ve tekrar deneyin. (invalid-dynamic-link-domain)",
  "auth/invalid-email": "Lütfen geçerli bir e-posta adresi girin",
  "auth/invalid-email-verified": "Lütfen e-postanızı doğrulayın",
  "auth/invalid-hash-algorithm":
    "Beklenmedik bir hata oluştu. Lütfen destek ile iletişime geçin ve tekrar deneyin. (invalid-hash-algorithm)",
  "auth/invalid-hash-block-size":
    "Beklenmedik bir hata oluştu. Lütfen destek ile iletişime geçin ve tekrar deneyin. (invalid-hash-block-size)",
  "auth/invalid-hash-derived-key-length":
    "Beklenmedik bir hata oluştu. Lütfen destek ile iletişime geçin ve tekrar deneyin. (invalid-hash-derived-key-length)",
  "auth/invalid-hash-key":
    "Beklenmedik bir hata oluştu. Lütfen destek ile iletişime geçin ve tekrar deneyin. (invalid-hash-key)",
  "auth/invalid-hash-memory-cost":
    "Beklenmedik bir hata oluştu. Lütfen destek ile iletişime geçin ve tekrar deneyin. (invalid-hash-memory-cost)",
  "auth/invalid-hash-parallelization":
    "Beklenmedik bir hata oluştu. Lütfen destek ile iletişime geçin ve tekrar deneyin. (invalid-hash-parallelization)",
  "auth/invalid-hash-rounds":
    "Beklenmedik bir hata oluştu. Lütfen destek ile iletişime geçin ve tekrar deneyin. (invalid-hash-rounds)",
  "auth/invalid-hash-salt-separator":
    "Beklenmedik bir hata oluştu. Lütfen destek ile iletişime geçin ve tekrar deneyin. (invalid-hash-salt-separator)",
  "auth/invalid-id-token":
    "Beklenmedik bir hata oluştu. Lütfen destek ile iletişime geçin ve tekrar deneyin. (invalid-id-token)",
  "auth/invalid-last-sign-in-time":
    "Beklenmedik bir hata oluştu. Lütfen destek ile iletişime geçin ve tekrar deneyin. (invalid-last-sign-in-time)",
  "auth/invalid-page-token":
    "Beklenmeyen bir hata oluştu. Lütfen desteğe bağlanın ve tekrar deneyin. (invalid-page-token)",
  "auth/invalid-password":
    "Lütfen geçerli bir şifre girin. En az 6 karakter olmalıdır",
  "auth/invalid-password-hash":
    "Beklenmeyen bir hata oluştu. Lütfen desteğe bağlanın ve tekrar deneyin. (invalid-password-hash)",
  "auth/invalid-password-salt":
    "Beklenmeyen bir hata oluştu. Lütfen desteğe bağlanın ve tekrar deneyin. (invalid-password-salt)",
  "auth/invalid-phone-number": "Lütfen geçerli bir telefon numarası girin",
  "auth/invalid-photo-url": "Lütfen geçerli bir foto/URL kullanın",
  "auth/invalid-provider-data":
    "Beklenmeyen bir hata oluştu. Lütfen desteğe bağlanın ve tekrar deneyin. (invalid-provider-data)",
  "auth/invalid-provider-id":
    "Beklenmeyen bir hata oluştu. Lütfen desteğe bağlanın ve tekrar deneyin. (invalid-provider-id)",
  "auth/invalid-oauth-responsetype":
    "Beklenmeyen bir hata oluştu. Lütfen desteğe bağlanın ve tekrar deneyin. (invalid-oauth-responsetype)",
  "auth/invalid-session-cookie-duration":
    "Beklenmeyen bir hata oluştu. Lütfen desteğe bağlanın ve tekrar deneyin. (invalid-session-cookie-duration)",
  "auth/invalid-uid":
    "Beklenmeyen bir hata oluştu. Lütfen desteğe bağlanın ve tekrar deneyin. (invalid-uid)",
  "auth/invalid-user-import":
    "Beklenmeyen bir hata oluştu. Lütfen desteğe bağlanın ve tekrar deneyin. (invalid-user-import)",
  "auth/maximum-user-count-exceeded":
    "Beklenmeyen bir hata oluştu. Lütfen desteğe bağlanın ve tekrar deneyin. (maximum-user-count-exceeded)",
  "auth/missing-android-pkg-name":
    "Beklenmedik bir hata oluştu. Lütfen destek ile iletişime geçin ve tekrar deneyin. (missing-android-pkg-name)",
  "auth/missing-continue-uri":
    "Beklenmedik bir hata oluştu. Lütfen destek ile iletişime geçin ve tekrar deneyin. (missing-continue-uri)",
  "auth/missing-hash-algorithm":
    "Beklenmedik bir hata oluştu. Lütfen destek ile iletişime geçin ve tekrar deneyin. (missing-hash-algorithm)",
  "auth/missing-ios-bundle-id":
    "Beklenmedik bir hata oluştu. Lütfen destek ile iletişime geçin ve tekrar deneyin. (missing-ios-bundle-id)",
  "auth/missing-uid":
    "Beklenmedik bir hata oluştu. Lütfen destek ile iletişime geçin ve tekrar deneyin. (missing-uid)",
  "auth/missing-oauth-client-secret":
    "Beklenmedik bir hata oluştu. Lütfen destek ile iletişime geçin ve tekrar deneyin. (missing-oauth-client-secret)",
  "auth/operation-not-allowed":
    "Beklenmedik bir hata oluştu. Lütfen destek ile iletişime geçin ve tekrar deneyin. (operation-not-allowed)",
  "auth/phone-number-already-exists": "Bu telefon numarası zaten kullanılıyor",
  "auth/project-not-found":
    "Beklenmedik bir hata oluştu. Lütfen destek ile iletişime geçin ve tekrar deneyin. (project-not-found)",
  "auth/reserved-claims":
    "Beklenmedik bir hata oluştu. Lütfen destek ile iletişime geçin ve tekrar deneyin. (reserved-claims)",
  "auth/session-cookie-expired":
    "Beklenmedik bir hata oluştu. Lütfen destek ile iletişime geçin ve tekrar deneyin. (session-cookie-expired)",
  "auth/session-cookie-revoked":
    "Beklenmedik bir hata oluştu. Lütfen destek ile iletişime geçin ve tekrar deneyin. (session-cookie-revoked)",
  "auth/uid-already-exists":
    "Beklenmedik bir hata oluştu. Lütfen destek ile iletişime geçin ve tekrar deneyin. (uid-already-exists)",
  "auth/unauthorized-continue-uri":
    "Beklenmedik bir hata oluştu. Lütfen destek ile iletişime geçin ve tekrar deneyin. (unauthorized-continue-uri)",
  "auth/user-not-found": "Bu kimlik bilgilerine sahip bir kullanıcı yok",
  "auth/weak-password":
    "Lütfen daha güçlü bir şifre girin. En az 6 karakter olmalı",
  "auth/wrong-password": "Yanlış Şifre",
};
