import "./Harita.scss";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Cookies from "universal-cookie";
import { useParams } from "react-router-dom";
// import { getAuth } from "firebase/auth";

const categories = {
  diger: "Diğer",
  gida: "Gıda",
  saglik: "Sağlık",
};

const CagriGuncelle = (props) => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const cookieUser = cookies.get("user");
  // const user = useSelector((state) => state.user.value);
  // const auth = getAuth();
  let { cagri_id } = useParams();
  const [authError, setAuthError] = useState();
  const [cagri, setCagri] = useState();
  const cagrilarim = useSelector(
    (state) => state.kullaniciCagrilari.cagrilarim
  );
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (cagrilarim.length > 0) {
      const cagrim = cagrilarim.find((c) => c.emergency_call_id === cagri_id);

      setCagri(cagrim);
    }
  }, [cagrilarim, cagri_id]);

  const updateEmergencyCall = () => {
    // if cagri message or address is empty, create auth error message
    setAuthError("");
    if (cagri && (!cagri.message || !cagri.address)) {
      setAuthError("Mesaj ve Adres alanları boş bırakılamaz");
    } else {
      axios
        .post(
          `https://rn3ovzqjig.execute-api.eu-central-1.amazonaws.com/api/emergency/${cagri_id}`,
          {
            ...cagri,
            updated_at: Math.floor(Date.now() / 1000),
          }
        )
        .then((res) => {
          setAuthError("");
          setSuccess(true);
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            setAuthError("Bir hata oluştu, lütfen daha sonra tekrar deneyin");
          } else {
            setAuthError("Bir hata oluştu, lütfen daha sonra tekrar deneyin");
          }
        });
    }
  };

  const onChange = (e) => {
    setCagri({
      ...cagri,
      [e.target.name]: e.target.value,
    });
  };

  // when cagri loaded (when its not empty), check if current user stored in cookies is the same as the user who created the cagri. if not, redirect to /login
  useEffect(() => {
    if (cagri && cookieUser && cagri.user_id !== cookieUser.user_id) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cagri, cookieUser]);

  if (success) {
    return (
      <Alert severity="success">
        Yardim Çağrınız başarıyla güncellendi.{" "}
        <Link to="/cagrilarim">Çağrılarım</Link>
      </Alert>
    );
  }
  return (
    <div>
      {/* {user && user.user_id === "" && <Navigate to="/login" replace={true} />} */}
      <h2>Çağrıyı Güncelle</h2>
      {cagri && cagri.emergency_call_id && (
        <div className="form-container">
          <div className="form">
            <p className="form-note">
              Şehir, pin konumu ve kategoriyi güncelleyemezsiniz. Güncellemeniz
              gerekliyse lütfen çağrı durumunuzu "İptal Edildi" seçtikten sonra
              yeni bir çağrı oluşturun.
            </p>
            <div className="input-container">
              <label>Şehir</label>
              {cagri.city}
            </div>
            <div className="input-container">
              <label>Kategori</label>
              {categories[cagri.category]}
            </div>
            <div className="input-container">
              <label>Mesajınız:</label>
              <textarea
                className="input textarea"
                rows="4"
                label="Message"
                variant="outlined"
                name="message"
                value={cagri["message"]}
                placeholder="Mesajınız. İhtiyacınız olan ürünler, sağlık durumunuz, ısıtma gibi."
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className="input-container">
              <label>Adresiniz:</label>
              <input
                className="input"
                label="Address"
                variant="outlined"
                name="address"
                placeholder="Adres"
                value={cagri["address"]}
                onChange={(e) => {
                  onChange(e);
                }}
              />
            </div>
            {/* change status with dropdown."0": "Yardım Bekleniyor", "1": "Yardım Yolda", "2": "Yardım Edildi" */}
            <div className="input-container">
              <label>Çağrı Durumu:</label>
              <select
                className="input"
                name="status"
                value={cagri.status}
                onChange={(e) => onChange(e)}
                // defaultValue={cagri.status}
              >
                <option value="0">Yardım Bekleniyor</option>
                <option value="1">Yardım Yolda</option>
                <option value="2">Yardım Edildi</option>
                <option value="3">İptal Edildi</option>
              </select>
            </div>

            <button className="button" onClick={() => updateEmergencyCall()}>
              Çağrıyı Güncelle
            </button>
            <Link to="/cagrilarim">
              <button className="button">İptal</button>
            </Link>
            {authError && (
              <Alert variant="filled" severity="warning">
                {authError}
              </Alert>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CagriGuncelle;
