import "./NavigationBar.scss";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import { FiLogOut } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { BsPinMap } from "react-icons/bs";
import { TbMap2 } from "react-icons/tb";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { reset } from "../../auth/userSlice";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { MdAccountCircle } from "react-icons/md";
import Cookies from "universal-cookie";
import logo from "../../media/MarasDepremLogo.png";

const NavigationBar = () => {
  const auth = getAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const cookies = new Cookies();
  const cookieUser = cookies.get("user");
  const navigate = useNavigate();
  // const user = useSelector((state) => state.user.value);
  const dispatch = useDispatch();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        cookies.remove("user", { path: "/" });
        setIsLoggedIn(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  // if user is logged in but cookieUser is null or doesn't have username
  useEffect(() => {
    if (isLoggedIn && (!cookieUser || !cookieUser.username)) {
      cookies.remove("user", { path: "/" });
      setIsLoggedIn(false);
    }
  }, [isLoggedIn, cookieUser]);

  const logOut = () => {
    signOut(auth)
      .then(() => {
        dispatch(reset());
        setIsLoggedIn(false);
        navigate("/");
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <Navbar collapseOnSelect fixed="top" expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img
              src={logo}
              alt="logo"
              style={{ width: "50px", paddingRight: "5px" }}
            />
            {/* Maras Deprem */}
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            style={{
              margin: "0 25px",
            }}
          >
            <Link to="/harita">
              <TbMap2 color="white" style={{ marginRight: "2px" }} />
              Çağrı Haritası
            </Link>
          </Nav>
          <Nav
            style={{
              margin: "0 25px 0 0 ",
            }}
          >
            <Link to="/cagrilistesi">
              <AiOutlineUnorderedList
                color="white"
                style={{ marginRight: "2px" }}
              />
              Çağrı Listesi
            </Link>
          </Nav>
          <Nav>
            <Link to="/cagriformu">
              <BsPinMap
                className="main-button-icon"
                color="white"
                style={{ marginRight: "2px" }}
              />
              Çağrı Oluştur
            </Link>
          </Nav>
          {/* if authentication cureent user is null and cookieUser does not exist */}
          {!isLoggedIn && !cookieUser ? (
            <Nav style={{ marginLeft: "auto" }}>
              <Nav.Item>
                <Link to="/login">Giriş Yap</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/register">Kayıt Ol</Link>
              </Nav.Item>
            </Nav>
          ) : (
            <Nav style={{ marginLeft: "auto" }}>
              <Nav.Item>
                <Link to="/cagrilarim">
                  <MdAccountCircle /> Cagrilarim
                  {cookieUser && "(" + cookieUser.username + ")"}
                </Link>
              </Nav.Item>
              <Nav.Item onClick={() => logOut()}>
                <Link>Çıkış {<FiLogOut />}</Link>
              </Nav.Item>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
