import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "./Layout";
import Home from "./pages/Home";
import CagriFormu from "./pages/CagriFormu";
import Bagis from "./pages/Bagis";
import Harita from "./pages/Harita";
import Haritav2 from "./pages/Haritav2";
import Haritav3 from "./pages/Haritav3";
import Login from "./auth/Login";
import Register from "./auth/Register";
import ForgotPassword from "./auth/ForgotPassword";
import Cagrilarim from "./pages/Cagrilarim";
import CagriGuncelle from "./pages/CagriGuncelle";
import CagriDetaylari from "./pages/CagriDetaylari";
import CagriListesi from "./pages/CagriListesi";
import YardimHatlari from "./pages/YardimHatlari";
import Iletisim from "./pages/Iletisim";
import NotFound from "./pages/NotFound";
import axios from "axios";
import { getAuth } from "firebase/auth";
import "./App.scss";

function App() {
  const auth = getAuth();

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/harita" element={<Haritav3 />} />
          <Route path="/cagrilistesi" element={<CagriListesi />} />
          <Route path="/cagriformu" element={<CagriFormu />} />
          <Route path="/bagis" element={<Bagis />} />
          <Route path="/yardimhatlari" element={<YardimHatlari />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/iletisim" element={<Iletisim />} />
          <Route path="/resetpassword" element={<ForgotPassword />} />
          <Route path="/cagri/:cagri_id" element={<CagriDetaylari />} />
          <Route path="/cagrilarim/:cagri_id" element={<CagriGuncelle />} />
          <Route path="/cagrilarim" element={<Cagrilarim />} />
          <Route path="/notfound" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
