import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  emergencycalls: [],
  selectedCalls: [],
  focusedCity: {},
};

const cagriSlice = createSlice({
  name: "cagri",
  initialState,
  reducers: {
    // setFocused city
    setFocusedCity: (state, action) => {
      state.focusedCity = action.payload;
    },
    addToemergencycalls: (state, action) => {
      if (!state.emergencycalls.includes(action.payload)) {
        state.emergencycalls.push(action.payload);
      }
    },
    addToselectedCalls: (state, action) => {
      const emergencyCallId = action.payload.emergency_call_id;
      if (
        !state.selectedCalls.find(
          (item) => item.emergency_call_id === emergencyCallId
        )
      ) {
        state.selectedCalls.push(action.payload);
      }
    },
    setemergencycalls: (state, action) => {
      state.emergencycalls = action.payload;
    },
    setSelectedCalls: (state, action) => {
      state.selectedCalls = action.payload;
    },
    removeFromemergencycalls: (state, action) => {
      state.emergencycalls = state.emergencycalls.filter(
        (item) => item !== action.payload
      );
    },
    removeFromselectedCalls: (state, action) => {
      state.selectedCalls = state.selectedCalls.filter(
        (item) => item !== action.payload
      );
    },
  },
});

export const {
  addToemergencycalls,
  addToselectedCalls,
  removeFromemergencycalls,
  removeFromselectedCalls,
  setemergencycalls,
  setSelectedCalls,
  setFocusedCity,
} = cagriSlice.actions;
export default cagriSlice.reducer;
