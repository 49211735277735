import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../auth/userSlice";
import cagriReducer from "../pages/cagriSlice";
import kullaniciCagrilariReducer from "../pages/kullaniciCagrilari";

export default configureStore({
  reducer: {
    user: userReducer,
    cagri: cagriReducer,
    kullaniciCagrilari: kullaniciCagrilariReducer,
  },
});
