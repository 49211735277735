import "./Harita.scss";
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import foodPin from "../media/food_pin.png";
import healthPin from "../media/health_pin.png";
import otherPin from "../media/other_pin.png";
import axios from "axios";
import { GoogleMapsProvider } from "@ubilabs/google-maps-react-hooks";
import MapMarkers from "./MapMarkers";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import {
  setemergencycalls,
  setSelectedCalls,
  setFocusedCity,
} from "./cagriSlice";
import Spinner from "../media/spinner.gif";
import { useNavigate } from "react-router-dom";

const cities = [
  "Kahramanmaraş",
  "Hatay",
  "Gaziantep",
  "Malatya",
  "Diyarbakır",
  "Kilis",
  "Şanlıurfa",
  "Adıyaman",
  "Osmaniye",
  "Adana",
];

const statusList = [
  { id: "0", label: "Yardım Bekleniyor" },
  { id: "1", label: "Yardım Yolda" },
  { id: "2", label: "Yardım Edildi" },
];

// cities with coordinates to be used in the map
const citiesWithCoords = [
  { city: "Kahramanmaraş", lat: 37.5554469, lng: 36.8765725 },
  { city: "Hatay", lat: 36.4200173, lng: 35.6782989 },
  { city: "Gaziantep", lat: 37.0588348, lng: 37.3451176 },
  { city: "Malatya", lat: 38.3530218, lng: 38.2059178 },
  { city: "Diyarbakır", lat: 37.922855, lng: 40.1276586 },
  { city: "Kilis", lat: 36.7195911, lng: 37.10837 },
  { city: "Şanlıurfa", lat: 37.1672537, lng: 38.7557854 },
  { city: "Adıyaman", lat: 37.7674341, lng: 38.2584044 },
  { city: "Adana", lat: 36.9975315, lng: 35.2180273 },
  { city: "Osmaniye", lat: 37.0755336, lng: 36.2316275 },
];

const categories = [{ diger: "Diğer" }, { gida: "Gıda" }, { saglik: "Sağlık" }];
const categoryList = { diger: "Diğer", gida: "Gıda", saglik: "Sağlık" };

export default function Haritav3() {
  console.log = console.warn = console.error = () => {};
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showEmptyDataAlert, setShowEmptyDataAlert] = useState(false);
  // const [showInfoAlert, setShowInfoAlert] = useState(true);
  const [mapContainer, setMapContainer] = useState(null);
  // const [map, setMap] = useState(null);
  // const onLoad = useCallback((map) => setMap(map), []);
  const dispatch = useDispatch();
  const cagrilar = useSelector((state) => state.cagri.emergencycalls);
  const selectedCagrilar = useSelector((state) => state.cagri.selectedCalls);
  const [cityFilter, setCityFilter] = useState("Kahramanmaraş");
  const [selectedCityAlert, setSelectedCityAlert] = useState("false");
  const [categoryFilter, setCategoryFilter] = useState([
    "diger",
    "gida",
    "saglik",
  ]);
  const [statusFilter, setStatusFilter] = useState(["0", "1", "2"]);

  const [mapOptions, setMapOptions] = useState({
    center: { lat: 37.5753, lng: 36.9228 },
    zoom: 9,
  });

  function generateTextFile(data) {
    let text = "";
    data.forEach((item, index) => {
      const { message, city, address, lat, lng, emergency_call_id } = item;
      text += `${
        index + 1
      }) \nMesaj: ${message}\nŞehir: ${city}\nAdres: ${address}\nKonum Linki: https://www.google.com/maps/dir/?api=1&destination=${lat},${lng} \nÇağrı Linki: https://www.yardimcagrisi.com/cagri/${emergency_call_id}\n\n`;
    });
    return text;
  }

  function handleDownload() {
    const text = generateTextFile(selectedCagrilar);
    const element = document.createElement("a");
    const file = new Blob([text], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "yardim_cagrilari.txt";
    document.body.appendChild(element);
    element.click();
  }

  const fetchCagrilar = () => {
    setShowEmptyDataAlert(false);
    setSelectedCityAlert(cityFilter);
    setLoading(true);
    let apiUrl = `https://rn3ovzqjig.execute-api.eu-central-1.amazonaws.com/api/emergency/filter?city=${cityFilter}`;
    if (categoryFilter !== "") {
      apiUrl += `&category=${categoryFilter}`;
    }
    if (statusFilter !== "") {
      apiUrl += `&status=${statusFilter}`;
    }

    const city = citiesWithCoords.find((city) => city.city === cityFilter);
    // console.log("city", city);

    dispatch(setFocusedCity(city));
    axios
      .get(apiUrl)
      .then((res) => {
        if (res.data.body.length === 0) {
          setShowEmptyDataAlert(true);
          dispatch(setemergencycalls(res.data.body));
        } else {
          setShowEmptyDataAlert(false);
          dispatch(setemergencycalls(res.data.body));
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // console.log("error", error);
      });
  };

  useEffect(() => {
    if (cityFilter !== "") fetchCagrilar();

    // eslint-disable-next-line
  }, []);

  return (
    <GoogleMapsProvider
      googleMapsAPIKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      mapContainer={mapContainer}
      mapOptions={mapOptions}
    >
      <h1>Çağrı Haritası</h1>
      <Alert severity="error">Gördüğünüz Çağrılar Test Amaçlıdır</Alert>
      <Container>
        <Row>
          <Col xs={12} md={12} xl={6}>
            <Container className="filtre-box city">
              <span>Şehir Seç:</span>
              <Row xs={2} md={3}>
                {cities.map((city, index) => (
                  <Col key={city}>
                    <div style={{ margin: "0.2rem" }}>
                      <input
                        type="radio"
                        name="city"
                        value={city}
                        checked={cityFilter === city}
                        onChange={() => setCityFilter(city)}
                      />
                      <label htmlFor={city} style={{ marginLeft: "0.5rem" }}>
                        {city}
                      </label>
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
          </Col>
          <Col xs={12} xl={6}>
            <Container className="filtre-box status">
              <span>Durum Seç:</span>

              {statusList.map((status, index) => (
                <Row key={index}>
                  <div style={{ margin: "1rem 0" }}>
                    <input
                      type="checkbox"
                      name="status"
                      value={status.id}
                      checked={statusFilter.includes(status.id)}
                      onChange={() => {
                        if (statusFilter.includes(status.id)) {
                          setStatusFilter(
                            statusFilter.filter((id) => id !== status.id)
                          );
                        } else {
                          setStatusFilter([...statusFilter, status.id]);
                        }
                      }}
                    />
                    <label htmlFor={status.id} style={{ marginLeft: "0.5rem" }}>
                      {status.label}
                    </label>
                  </div>
                </Row>
              ))}
            </Container>
          </Col>
          <Col xs={12} xl={6}>
            <Container className="filtre-box category">
              <span>Kategori Seç:</span>
              {categories.map((category, index) => {
                const [categoryId, categoryName] = Object.entries(category)[0];
                const isChecked = categoryFilter.includes(categoryId);
                const pinImage =
                  categoryId === "gida"
                    ? foodPin
                    : categoryId === "saglik"
                    ? healthPin
                    : otherPin;
                return (
                  <Row key={index}>
                    <div style={{ margin: "1rem 0" }}>
                      <input
                        type="checkbox"
                        name="category"
                        value={categoryId}
                        checked={isChecked}
                        onChange={() => {
                          if (isChecked) {
                            setCategoryFilter(
                              categoryFilter.filter((id) => id !== categoryId)
                            );
                          } else {
                            setCategoryFilter([...categoryFilter, categoryId]);
                          }
                        }}
                      />
                      <label
                        htmlFor="category"
                        style={{ marginLeft: "0.5rem" }}
                      >
                        {categoryName}
                      </label>
                      <img
                        src={pinImage}
                        alt={categoryId}
                        height="32"
                        width="20"
                        style={{ marginLeft: "-1rem" }}
                      />
                    </div>
                  </Row>
                );
              })}
            </Container>
          </Col>
        </Row>
      </Container>
      <Button onClick={fetchCagrilar}>
        Çağrıları Göster{" "}
        {loading && (
          <img
            src={Spinner}
            alt="loading"
            style={{ width: "1.5rem", padding: "0" }}
          />
        )}
      </Button>
      {showEmptyDataAlert && (
        <Alert severity="warning" onClose={() => setShowEmptyDataAlert(false)}>
          Seçtiğiniz şehirde{" "}
          {selectedCityAlert && "(" + selectedCityAlert + ")"} bu filtrelerle
          bir çağrı bulunamadı.
        </Alert>
      )}
      {/* {showInfoAlert && ( */}
      {/* // <Alert severity="info" onClose={() => setShowInfoAlert(false)}> */}
      <Alert severity="info" variant="filled">
        Harita üzerindeki çağrı pinlerine tıklayarak çağrı detaylarını
        görebilirsiniz ve indirmek üzere listeye ekleyebilirsiniz.
      </Alert>
      {/* )} */}
      <div
        id="container"
        ref={(node) => setMapContainer(node)}
        style={{ height: "600px" }}
      >
        {cagrilar.length > 0 && <MapMarkers cagrilar={cagrilar} />}
      </div>
      {selectedCagrilar.length > 0 && (
        <>
          <Table
            striped
            bordered
            hover
            variant="dark"
            style={{ marginTop: "2rem" }}
            className="cagri-table"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Kategori</th>
                <th>Şehir</th>
                <th>Mesaj</th>
                <th>Aksiyon</th>
              </tr>
            </thead>
            <tbody>
              {selectedCagrilar
                .slice()
                .reverse()
                .map((marker, index) => (
                  <tr key={marker.emergency_call_id}>
                    <td>{index + 1}</td>
                    {/* map categories with the value of corresponding category in categories array */}
                    <td>{categoryList[marker.category]}</td>
                    {/* <td>{marker.category}</td> */}
                    <td>{marker.city}</td>
                    {/* first 200 character of the message. if its longer than 200 chars, add ... at the end */}
                    <td>
                      {marker.message.length > 200
                        ? marker.message.substring(0, 200) + "..."
                        : marker.message}
                    </td>
                    <td>
                      <Button
                        className="card-button"
                        onClick={() => {
                          navigate(`/cagri/${marker.emergency_call_id}`);
                        }}
                      >
                        Detay
                      </Button>
                      <Button
                        className="card-button"
                        href={`https://www.google.com/maps/dir/?api=1&destination=${marker.lat},${marker.lng}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Yol Tarifi Al
                      </Button>
                      <Button
                        className="card-button"
                        onClick={() => {
                          dispatch(
                            setSelectedCalls(
                              selectedCagrilar.filter((_, i) => i !== index)
                            )
                          );
                        }}
                      >
                        Listeden Çıkar
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Button onClick={handleDownload}>Listeyi İndir</Button>
        </>
      )}
    </GoogleMapsProvider>
  );
}
