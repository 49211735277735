import "./Login.scss";
import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { errors } from "../errors/authErrors";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [submitPressed, setSP] = useState(0);
  const [authError, setAuthError] = useState();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (submitPressed > 0) {
      setAuthError();
      const auth = getAuth();

      sendPasswordResetEmail(auth, email)
        .then(() => {
          setAuthError();
          setSuccess(true);
          // Password reset email sent!
          // ..
        })
        .catch((error) => {
          setSuccess(false);
          const errorCode = error.code;
          // const errorMessage = error.message;
          setAuthError(`${errors[errorCode]}`);
          if (error.message === "EMAIL_NOT_FOUND") {
            setAuthError("Bu email adresiyle kayıtlı bir hesap bulunamadı.");
          }
        });
    }

    // eslint-disable-next-line
  }, [submitPressed]);

  const onChange = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
    }
  };

  if (success) {
    return (
      <h5>
        Şifre Sıfırlama Linki gönderildi. Lütfen {email} adresini kontrol edin.
      </h5>
    );
  }
  return (
    <div className="form-container auth">
      <div className="form">
        <h1>Şifre Sıfırla</h1>
        <input
          className="input"
          label="Email"
          variant="outlined"
          name="email"
          placeholder="Email"
          onChange={(e) => onChange(e)}
        />
        <button className="button" onClick={() => setSP(submitPressed + 1)}>
          Şifre Sıfırlama Linki Gönder
        </button>

        {authError && (
          <Alert variant="filled" severity="warning">
            {authError}
          </Alert>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
