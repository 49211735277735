import "../auth/Login.scss";
import "./YorumYap.scss";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Spinner from "../media/spinner.gif";
import { Col, Row } from "react-bootstrap";
import Cookies from "universal-cookie";

export default function YorumYap({ cagri }) {
  const cookies = new Cookies();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const cookieUser = cookies.get("user");
  const [postData, addFormData] = useState({});
  const [authError, setAuthError] = useState();
  const [comments, setComments] = useState([]);
  // count characters
  const [charLimit, setRemainingChars] = useState(800);
  // const user = useSelector((state) => state.user.value);
  const auth = getAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const postComment = () => {
    axios
      .post(
        "https://hd0xsaurse.execute-api.eu-central-1.amazonaws.com/api/comments",
        {
          ...postData,
          user_id: cookieUser.user_id,
          username: cookieUser.username,
          created_at: Math.floor(Date.now() / 1000),
          emergency_call_id: cagri.emergency_call_id,
        }
      )
      .then((res) => {
        setAuthError("");
        navigate(0);
      })
      .catch((error) => {
        if (!error.response) {
          // network error
          setAuthError(
            "Servera bağlanılamıyor. Lütfen daha sonra tekrar deneyin"
          );
        } else {
          setAuthError(
            "Bilinmeyen bir hata oluştu. Lütfen daha sonra tekrar deneyin"
          );
        }
      });
  };

  const createComment = () => {
    if (postData && !postData.comment_text) {
      setAuthError("Boş yorum gönderemezsiniz. Lütfen bir yorum girin");
    } else if (comments && comments.length > 3 && auth && auth.currentUser) {
      // if last 3 comments by date are from the same user, do not allow to post
      const lastThreeComments = comments
        .sort((a, b) => a.created_at - b.created_at)
        .slice(-3);
      const lastThreeCommentsBySameUser = lastThreeComments.filter(
        (comment) => comment.user_id === auth.currentUser.uid
      );
      if (lastThreeCommentsBySameUser.length >= 3) {
        setAuthError("Art arda 3 yorumdan fazla gönderemezsiniz.");
      } else {
        postComment();
      }
    } else {
      postComment();
    }
  };

  const getCommentsByCagriId = () => {
    axios
      .get(
        ` https://hd0xsaurse.execute-api.eu-central-1.amazonaws.com/api/comments/${cagri.emergency_call_id}`
      )
      .then((res) => {
        setComments(res.data);

        // setComments(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onChange = (e) => {
    if (e.target.name === "comment_text") {
      // decrease remaining chars
      setRemainingChars(800 - e.target.value.length);
      addFormData({
        ...postData,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    if (cagri && cagri.emergency_call_id) {
      setLoading(true);
      getCommentsByCagriId();
    }
    // eslint-disable-next-line
  }, [cagri]);

  const epochtoTime = (epoch) => {
    const date = new Date(epoch * 1000);
    if (isNaN(date.getTime())) {
      return "Tarih Bilinmiyor";
    }

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    const formattedDate = date.toLocaleString("default", options);

    return formattedDate;
  };

  useEffect(() => {
    if (isLoggedIn && (!cookieUser || !cookieUser.username)) {
      cookies.remove("user", { path: "/" });
      setIsLoggedIn(false);
    }
  }, [isLoggedIn, cookieUser]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    if (!cookieUser) {
      setIsLoggedIn(false);
    }
  }, [auth, cookieUser]);

  return (
    <div>
      {loading ? (
        <div>
          <p>Yorumlar Yükleniyor...</p>
          <img src={Spinner} alt="loading" style={{ width: "25%" }} />
        </div>
      ) : (
        <div>
          {comments && comments.length > 0 && (
            <div>
              <h4>Yorumlar</h4>
              {/* map comments according to date of creation. earliest to latest  */}
              {comments
                .sort((a, b) => a.created_at - b.created_at)
                .map((comment) => (
                  <div
                    key={comment.comment_id}
                    className={
                      auth &&
                      auth.currentUser &&
                      comment.user_id === auth.currentUser.uid
                        ? "kullanici-yorumu-kendisi"
                        : "kullanici-yorumu"
                    }
                  >
                    <Row>
                      <Col sm={8} className="username">
                        <p>{comment.username}</p>
                      </Col>
                      <Col sm={4} className="date">
                        <p>{epochtoTime(comment.created_at)}</p>
                      </Col>
                    </Row>
                    <Row className="comment">{comment.comment_text}</Row>
                  </div>
                ))}
            </div>
          )}
          {isLoggedIn ? (
            <div className="form-container">
              <div className="form">
                <div>
                  <p style={{ fontWeight: "bold" }}>Mesajınız:</p>

                  <textarea
                    className="input textarea"
                    rows="4"
                    label="Comment Test"
                    variant="outlined"
                    name="comment_text"
                    placeholder="Mesajınız. Çağrı sahibine sormak istediğiniz soruları buraya yazabilirsiniz."
                    onChange={(e) => onChange(e)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  />
                  <p style={{ textAlign: "right" }}>{charLimit}</p>
                </div>

                {authError && (
                  <Alert
                    variant="filled"
                    severity="warning"
                    style={{ width: "80%" }}
                  >
                    {authError}
                  </Alert>
                )}
                <button className="button" onClick={() => createComment()}>
                  Yorum Yap
                </button>
              </div>
            </div>
          ) : (
            <div>
              <Alert>
                Yorum Yapmak için{" "}
                <b
                  onClick={() =>
                    navigate("/login", {
                      state: {
                        previousPath: "/cagri/" + cagri.emergency_call_id,
                      },
                    })
                  }
                  className="link-login"
                >
                  Giriş Yapınız
                </b>
              </Alert>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
