// json variable to be used in another component
export const cityBoundaryList = {
  Hatay: [
    [35.7800932, 36.3217148],
    [35.7799977, 36.321479],
    [35.7800621, 36.3213623],
    [35.7801452, 36.3213282],
    [35.7801566, 36.321359],
    [35.7801761, 36.3215044],
    [35.7801955, 36.3215476],
    [35.780278, 36.3215638],
    [35.7802358, 36.3215076],
    [35.7802257, 36.3214601],
    [35.7802264, 36.3213596],
    [35.780341, 36.3213709],
    [35.7807112, 36.3214617],
    [35.7809101, 36.3213181],
    [35.7809794, 36.3212067],
    [35.7808745, 36.3206385],
    [35.7806613, 36.3201803],
    [35.7806807, 36.3199251],
    [35.7804997, 36.31942],
    [35.7806677, 36.3193367],
    [35.7808487, 36.3192378],
    [35.7808916, 36.3187374],
    [35.7805094, 36.3186103],
    [35.7803155, 36.3185035],
    [35.7802121, 36.3184437],
    [35.7801475, 36.3183656],
    [35.780099, 36.318264],
    [35.7800247, 36.3181468],
    [35.7800441, 36.3180453],
    [35.7801087, 36.3179464],
    [35.7802153, 36.3178813],
    [35.7803866, 36.3178188],
    [35.780515, 36.317821],
    [35.7807841, 36.3176886],
    [35.780981, 36.317553],
    [35.780963, 36.31704],
    [35.780923, 36.3167981],
    [35.7812332, 36.3162696],
    [35.7813302, 36.316194],
    [35.7814659, 36.3160613],
    [35.781495, 36.3159675],
    [35.7815435, 36.315853],
    [35.781393, 36.315746],
    [35.7818989, 36.3149364],
    [35.7820605, 36.3145875],
    [35.7819668, 36.3141188],
    [35.7819765, 36.3140381],
    [35.7820249, 36.3138949],
    [35.7820669, 36.3137335],
    [35.7820411, 36.3134991],
    [35.7820152, 36.3133559],
    [35.7819959, 36.3130877],
    [35.7820572, 36.3129601],
    [35.7821542, 36.3128612],
    [35.782393, 36.31264],
    [35.7826292, 36.3124445],
    [35.7831042, 36.3119576],
    [35.7833789, 36.311515],
    [35.7835469, 36.3110567],
    [35.7836665, 36.3108015],
    [35.7836794, 36.3104682],
    [35.7835469, 36.3103067],
    [35.7834985, 36.310213],
    [35.7833821, 36.3100151],
    [35.7832529, 36.3097651],
    [35.7831204, 36.3095646],
    [35.7828683, 36.3091636],
    [35.7830719, 36.3088615],
    [35.783366, 36.308765],
    [35.7837182, 36.3083772],
    [35.784009, 36.3078746],
    [35.7842999, 36.3065153],
    [35.7844711, 36.3060856],
    [35.7846812, 36.3057132],
    [35.7847361, 36.3054945],
    [35.7847264, 36.3050987],
    [35.7847878, 36.3049789],
    [35.784833, 36.3047966],
    [35.784749, 36.3046273],
    [35.7846489, 36.3045388],
    [35.7845519, 36.3043773],
    [35.7846133, 36.3041976],
    [35.7847038, 36.304044],
    [35.7846198, 36.3035596],
    [35.7843968, 36.3030622],
    [35.7841157, 36.3027679],
    [35.7840317, 36.302669],
    [35.7839929, 36.3025075],
    [35.7839089, 36.3023044],
    [35.7836277, 36.3020231],
    [35.7834985, 36.3017992],
    [35.7834209, 36.3016195],
    [35.783408, 36.3013981],
    [35.7834015, 36.3011637],
    [35.7833627, 36.300958],
    [35.783269, 36.3008121],
    [35.7831656, 36.3005335],
    [35.7828296, 36.3001741],
    [35.7826421, 36.2999032],
    [35.7825807, 36.299635],
    [35.7822608, 36.2996272],
    [35.782109, 36.2995673],
    [35.7820055, 36.2994787],
    [35.7819797, 36.2993876],
    [35.782109, 36.2993016],
    [35.78218, 36.2992287],
    [35.7822996, 36.2993016],
    [35.7823869, 36.2993381],
    [35.7824644, 36.2992938],
    [35.7824418, 36.2992131],
    [35.7823965, 36.299135],
    [35.7823416, 36.2990881],
    [35.7822544, 36.2990464],
    [35.7820799, 36.2990178],
    [35.7819441, 36.2990621],
    [35.7819021, 36.2991897],
    [35.781789, 36.2993329],
    [35.781705, 36.2994631],
    [35.7815822, 36.2995699],
    [35.7813819, 36.2996064],
    [35.7812203, 36.2995673],
    [35.7811428, 36.2994944],
    [35.7812106, 36.2993902],
    [35.7813399, 36.2993173],
    [35.7814174, 36.2992808],
    [35.781439, 36.299177],
    [35.7811686, 36.2990647],
    [35.7809909, 36.2990907],
    [35.7808487, 36.2991011],
    [35.7805579, 36.2989683],
    [35.7805352, 36.2988251],
    [35.7807097, 36.2987339],
    [35.7809618, 36.2987105],
    [35.7811848, 36.2987599],
    [35.7812882, 36.298799],
    [35.7814207, 36.2988146],
    [35.781537, 36.2988146],
    [35.781802, 36.2986115],
    [35.78219, 36.298004],
    [35.782584, 36.2970567],
    [35.7828974, 36.2964629],
    [35.7830202, 36.2957336],
    [35.7832206, 36.2953013],
    [35.7833821, 36.2946684],
    [35.7834532, 36.2943871],
    [35.7834177, 36.2942361],
    [35.7833563, 36.2939001],
    [35.7833983, 36.2934495],
    [35.783576, 36.2930667],
    [35.7835502, 36.2926551],
    [35.783574, 36.292321],
    [35.783492, 36.2919623],
    [35.7833531, 36.2916758],
    [35.78366, 36.2914883],
    [35.7840607, 36.291293],
    [35.784639, 36.291012],
    [35.784788, 36.290752],
    [35.7853792, 36.2900506],
    [35.7857217, 36.2895505],
    [35.785843, 36.289186],
    [35.786155, 36.28905],
    [35.7866394, 36.2888524],
    [35.786934, 36.288647],
    [35.7872598, 36.2883159],
    [35.7874214, 36.2880554],
    [35.787695, 36.28773],
    [35.788003, 36.287466],
    [35.7882745, 36.287201],
    [35.7885072, 36.2869197],
    [35.7887011, 36.2865603],
    [35.7888704, 36.2861936],
    [35.7889831, 36.2857828],
    [35.7890958, 36.2854239],
    [35.7892352, 36.2850348],
    [35.7895356, 36.2844899],
    [35.7897609, 36.2836251],
    [35.7900721, 36.2827127],
    [35.7903832, 36.2813505],
    [35.7905441, 36.2809008],
    [35.790925, 36.2803818],
    [35.791263, 36.2801829],
    [35.7917029, 36.279798],
    [35.7920033, 36.2795688],
    [35.7921427, 36.2794002],
    [35.79246, 36.279015],
    [35.7926738, 36.2787428],
    [35.793696, 36.278086],
    [35.7941383, 36.2777698],
    [35.794629, 36.277551],
    [35.795253, 36.277279],
    [35.796195, 36.277],
    [35.79682, 36.276729],
    [35.796996, 36.2765632],
    [35.797431, 36.276073],
    [35.7981332, 36.2753698],
    [35.7989723, 36.2746349],
    [35.799426, 36.274228],
    [35.8007668, 36.2727254],
    [35.8014607, 36.2720006],
    [35.8020508, 36.2713433],
    [35.8031344, 36.2698295],
    [35.804286, 36.268207],
    [35.8047169, 36.2675286],
    [35.805191, 36.266901],
    [35.805489, 36.266381],
    [35.8060956, 36.2656904],
    [35.8064443, 36.2653314],
    [35.8069378, 36.2649335],
    [35.8072382, 36.2646696],
    [35.808106, 36.264136],
    [35.8083916, 36.2639819],
    [35.8087778, 36.263584],
    [35.809029, 36.263343],
    [35.809644, 36.262815],
    [35.8099848, 36.2625199],
    [35.8103496, 36.2620484],
    [35.810711, 36.261635],
    [35.8109075, 36.2613218],
    [35.8110577, 36.2610233],
    [35.8109289, 36.2608157],
    [35.8105534, 36.2607248],
    [35.8103979, 36.260647],
    [35.8102959, 36.2605821],
    [35.8102477, 36.260474],
    [35.8102155, 36.2603442],
    [35.8104086, 36.2602231],
    [35.8106446, 36.2602404],
    [35.8107358, 36.2603658],
    [35.8109182, 36.2604177],
    [35.8110094, 36.2604307],
    [35.8111542, 36.2604134],
    [35.8117121, 36.2602404],
    [35.812211, 36.2599895],
    [35.8124739, 36.2598251],
    [35.8125329, 36.2596521],
    [35.8122325, 36.2594964],
    [35.812055, 36.259291],
    [35.812367, 36.259155],
    [35.8126992, 36.2593536],
    [35.81301, 36.259397],
    [35.813327, 36.25939],
    [35.813635, 36.259125],
    [35.813992, 36.2588865],
    [35.8142549, 36.258402],
    [35.8142924, 36.2581771],
    [35.8143407, 36.2579565],
    [35.81453, 36.257563],
    [35.814838, 36.257299],
    [35.8154726, 36.2569529],
    [35.8161753, 36.2565463],
    [35.816702, 36.256228],
    [35.81701, 36.255964],
    [35.8174574, 36.2555687],
    [35.8179671, 36.2550193],
    [35.8190936, 36.2537994],
    [35.8200523, 36.2523295],
    [35.820202, 36.252164],
    [35.820193, 36.251908],
    [35.820491, 36.251387],
    [35.8205152, 36.2510178],
    [35.8208692, 36.2504857],
    [35.8214432, 36.2494085],
    [35.8218026, 36.2486168],
    [35.8221406, 36.248115],
    [35.8227628, 36.2471199],
    [35.8232564, 36.2467608],
    [35.8235192, 36.2463585],
    [35.8237827, 36.246003],
    [35.8240879, 36.2458696],
    [35.8243561, 36.2457658],
    [35.8244741, 36.2457182],
    [35.8248281, 36.2454586],
    [35.82515, 36.245225],
    [35.8254719, 36.2447491],
    [35.8255765, 36.244509],
    [35.8256301, 36.2443619],
    [35.8256248, 36.2442905],
    [35.825614, 36.2442321],
    [35.8257803, 36.2440244],
    [35.825842, 36.2439833],
    [35.8260217, 36.243847],
    [35.8260995, 36.2438146],
    [35.8262175, 36.2437021],
    [35.826475, 36.2433603],
    [35.8267593, 36.243144],
    [35.8270544, 36.2427633],
    [35.8279127, 36.2424085],
    [35.8282614, 36.2423393],
    [35.8286208, 36.2422354],
    [35.8287334, 36.2421878],
    [35.8287173, 36.242045],
    [35.8290821, 36.2418979],
    [35.8293021, 36.2417898],
    [35.8294254, 36.2416686],
    [35.8294898, 36.2415951],
    [35.8295435, 36.2413182],
    [35.8296829, 36.2409158],
    [35.8300977, 36.240838],
    [35.830434, 36.2406822],
    [35.8305252, 36.2403447],
    [35.8305627, 36.2399986],
    [35.830699, 36.239844],
    [35.8309597, 36.2397866],
    [35.8310294, 36.2397909],
    [35.8311582, 36.239726],
    [35.8312279, 36.2396221],
    [35.8312601, 36.2395399],
    [35.8311957, 36.239315],
    [35.8311635, 36.2391808],
    [35.8313245, 36.2390121],
    [35.8312708, 36.2387655],
    [35.8311528, 36.2386703],
    [35.8308846, 36.2386313],
    [35.8306271, 36.2385621],
    [35.830729, 36.2383198],
    [35.8308256, 36.2381943],
    [35.830965, 36.238177],
    [35.8313298, 36.2381467],
    [35.8313781, 36.2380645],
    [35.8314049, 36.2379737],
    [35.8314731, 36.2378431],
    [35.8315551, 36.2377487],
    [35.8316678, 36.2376708],
    [35.8317536, 36.2376318],
    [35.8320808, 36.2376145],
    [35.8322793, 36.2376578],
    [35.8324242, 36.2376578],
    [35.8325744, 36.2376232],
    [35.8328479, 36.2374371],
    [35.8330947, 36.2372338],
    [35.8334219, 36.2370109],
    [35.8335641, 36.2369158],
    [35.8337304, 36.2368357],
    [35.8340925, 36.2366756],
    [35.8344251, 36.2366021],
    [35.8346021, 36.2365415],
    [35.834854, 36.236407],
    [35.8350205, 36.2362148],
    [35.8351681, 36.2360266],
    [35.8352753, 36.2359444],
    [35.8353719, 36.2358362],
    [35.8354658, 36.235728],
    [35.835557, 36.2355896],
    [35.835785, 36.2353386],
    [35.8358923, 36.2352088],
    [35.8359674, 36.2350898],
    [35.8360103, 36.2350379],
    [35.8362624, 36.2347956],
    [35.8363697, 36.2347069],
    [35.8364823, 36.2344538],
    [35.8366352, 36.2342569],
    [35.8367318, 36.234153],
    [35.8367747, 36.2339799],
    [35.8368203, 36.2338761],
    [35.8368418, 36.2337485],
    [35.836772, 36.2336835],
    [35.8366969, 36.2336273],
    [35.8366674, 36.2335429],
    [35.8367747, 36.2334823],
    [35.8367667, 36.2334391],
    [35.836705, 36.2333331],
    [35.8367613, 36.2332595],
    [35.8368552, 36.2332746],
    [35.8369249, 36.2333547],
    [35.8369678, 36.2333915],
    [35.8371341, 36.233385],
    [35.8374077, 36.233359],
    [35.8377966, 36.233134],
    [35.8381078, 36.2328419],
    [35.8383653, 36.2326018],
    [35.838502, 36.232366],
    [35.8386335, 36.2322167],
    [35.8387944, 36.2319982],
    [35.8389983, 36.2317732],
    [35.8391672, 36.2315179],
    [35.8393818, 36.2311652],
    [35.8396393, 36.2309012],
    [35.840039, 36.2303236],
    [35.8403957, 36.2295836],
    [35.8403099, 36.2293543],
    [35.8402401, 36.2292071],
    [35.8402133, 36.2290557],
    [35.8403474, 36.2288047],
    [35.8405727, 36.2283114],
    [35.8407658, 36.2280128],
    [35.8408785, 36.2276796],
    [35.8409911, 36.2274026],
    [35.8412057, 36.2270565],
    [35.841326, 36.226878],
    [35.8415866, 36.2265242],
    [35.8418709, 36.2261217],
    [35.8422089, 36.2256717],
    [35.8426648, 36.2251351],
    [35.8434427, 36.2241874],
    [35.8439952, 36.2233348],
    [35.8443224, 36.2229324],
    [35.8448052, 36.2223005],
    [35.8455026, 36.2214653],
    [35.8458352, 36.220894],
    [35.8462322, 36.2203228],
    [35.846469, 36.220078],
    [35.8470851, 36.2190417],
    [35.8475534, 36.218491],
    [35.8477294, 36.2182841],
    [35.8483726, 36.2176136],
    [35.8488144, 36.2172271],
    [35.849659, 36.216278],
    [35.8507184, 36.2151827],
    [35.8514571, 36.2144585],
    [35.8521008, 36.2135755],
    [35.8526104, 36.2130728],
    [35.8532672, 36.2122878],
    [35.8535975, 36.2117837],
    [35.8538228, 36.2113422],
    [35.8540159, 36.2110522],
    [35.8540481, 36.2106626],
    [35.853855, 36.2103597],
    [35.8537584, 36.2102082],
    [35.8536196, 36.21001],
    [35.8536994, 36.209507],
    [35.853796, 36.2093901],
    [35.8538175, 36.2090915],
    [35.8538657, 36.2088664],
    [35.8539408, 36.2086283],
    [35.853973, 36.2084595],
    [35.8542144, 36.2081219],
    [35.85437, 36.2077886],
    [35.8544987, 36.2076501],
    [35.8545738, 36.2075549],
    [35.8546238, 36.2073706],
    [35.8548824, 36.2067958],
    [35.8549172, 36.2066026],
    [35.8547991, 36.2064901],
    [35.8547938, 36.2063429],
    [35.8547938, 36.206213],
    [35.854995, 36.206133],
    [35.8551592, 36.205885],
    [35.8551317, 36.205685],
    [35.8550942, 36.2055464],
    [35.8552015, 36.2054772],
    [35.8553356, 36.2054512],
    [35.8555019, 36.2052737],
    [35.855754, 36.2049621],
    [35.8561456, 36.2046418],
    [35.85636, 36.204431],
    [35.8566338, 36.2042219],
    [35.8569503, 36.2039968],
    [35.8572078, 36.2036938],
    [35.8577109, 36.2029958],
    [35.8575994, 36.2028497],
    [35.857594, 36.2027718],
    [35.857597, 36.202561],
    [35.8577925, 36.2023389],
    [35.8577781, 36.2021312],
    [35.8578569, 36.2018801],
    [35.8580238, 36.2015919],
    [35.8582534, 36.2015139],
    [35.8589025, 36.2009694],
    [35.8591857, 36.2007737],
    [35.8594507, 36.2006153],
    [35.859856, 36.200367],
    [35.8605552, 36.1996118],
    [35.8608931, 36.1991356],
    [35.8612579, 36.1987373],
    [35.8615648, 36.19825],
    [35.8617923, 36.1979413],
    [35.8619231, 36.1975512],
    [35.8621645, 36.1971875],
    [35.8626436, 36.1965894],
    [35.8631408, 36.1958455],
    [35.863478, 36.1954],
    [35.8640957, 36.1944905],
    [35.8648574, 36.193642],
    [35.8654207, 36.192958],
    [35.865898, 36.19226],
    [35.865984, 36.191867],
    [35.8659567, 36.1913947],
    [35.8659942, 36.1910259],
    [35.866338, 36.1906461],
    [35.8664303, 36.1905025],
    [35.86662, 36.190316],
    [35.8669549, 36.1901136],
    [35.8673412, 36.1898625],
    [35.8675925, 36.1895721],
    [35.8679098, 36.1890918],
    [35.8681136, 36.1888754],
    [35.8685374, 36.1883428],
    [35.868693, 36.1881913],
    [35.8689129, 36.1878882],
    [35.8692528, 36.187614],
    [35.8697605, 36.1868535],
    [35.870125, 36.1864058],
    [35.8706886, 36.1856758],
    [35.8716983, 36.1843099],
    [35.8725812, 36.1831932],
    [35.8731937, 36.1823982],
    [35.8734888, 36.1820475],
    [35.8741658, 36.1810978],
    [35.8743846, 36.1806922],
    [35.8743759, 36.1803791],
    [35.8739233, 36.1802722],
    [35.8737141, 36.1802938],
    [35.8736847, 36.1801779],
    [35.8734198, 36.1798743],
    [35.8736229, 36.1797656],
    [35.8739364, 36.179733],
    [35.8745694, 36.179718],
    [35.8746314, 36.1796487],
    [35.8745992, 36.1795621],
    [35.8744705, 36.1794885],
    [35.8742291, 36.1793191],
    [35.8746582, 36.1792287],
    [35.8748138, 36.1792113],
    [35.8749211, 36.1792113],
    [35.8751422, 36.1791305],
    [35.8754307, 36.1792633],
    [35.8756025, 36.1792702],
    [35.8764033, 36.1791776],
    [35.8768181, 36.1789736],
    [35.8772359, 36.1785334],
    [35.8775094, 36.1781245],
    [35.8780874, 36.1772568],
    [35.8783275, 36.1766393],
    [35.8783811, 36.1764141],
    [35.8784455, 36.1762019],
    [35.878612, 36.17603],
    [35.878939, 36.1752146],
    [35.8791643, 36.1749245],
    [35.8792823, 36.1746344],
    [35.8793628, 36.1742533],
    [35.8793628, 36.1739589],
    [35.8793253, 36.1736947],
    [35.8792823, 36.1734002],
    [35.8792233, 36.1731231],
    [35.879395, 36.1730322],
    [35.8796042, 36.1730495],
    [35.8798671, 36.1729889],
    [35.8800973, 36.17295],
    [35.8803445, 36.1728027],
    [35.8802587, 36.1725039],
    [35.8804625, 36.1724692],
    [35.8805966, 36.1724909],
    [35.8808273, 36.1726727],
    [35.8810848, 36.1726944],
    [35.8812618, 36.1726641],
    [35.8814388, 36.1726121],
    [35.882126, 36.172477],
    [35.882433, 36.172213],
    [35.88274, 36.171949],
    [35.883047, 36.171684],
    [35.883824, 36.171281],
    [35.884131, 36.171017],
    [35.885206, 36.170091],
    [35.8855947, 36.1698009],
    [35.8857766, 36.1695605],
    [35.886747, 36.168899],
    [35.886895, 36.168638],
    [35.8877024, 36.1678105],
    [35.888258, 36.166935],
    [35.889179, 36.166143],
    [35.8894677, 36.1657607],
    [35.889937, 36.165226],
    [35.890235, 36.164705],
    [35.8910401, 36.1638856],
    [35.891444, 36.163135],
    [35.892979, 36.161813],
    [35.894054, 36.160888],
    [35.894361, 36.160624],
    [35.8947314, 36.1602592],
    [35.895119, 36.159707],
    [35.895733, 36.159178],
    [35.8958182, 36.1587623],
    [35.8958477, 36.158732],
    [35.8958799, 36.1587103],
    [35.8959952, 36.1586259],
    [35.8961803, 36.1584288],
    [35.8964404, 36.1580953],
    [35.8966362, 36.1578831],
    [35.8967543, 36.1577055],
    [35.8969447, 36.1574067],
    [35.8971485, 36.1571468],
    [35.897339, 36.1568891],
    [35.8976045, 36.1565664],
    [35.8977199, 36.1564646],
    [35.8978727, 36.156287],
    [35.8980739, 36.1559557],
    [35.8981705, 36.1557586],
    [35.8983126, 36.1556308],
    [35.8987659, 36.1550851],
    [35.8991414, 36.154587],
    [35.8995652, 36.1540066],
    [35.8999997, 36.1533374],
    [35.900276, 36.1528934],
    [35.9005871, 36.1524841],
    [35.9009278, 36.1520293],
    [35.9012926, 36.1515658],
    [35.9016734, 36.1510092],
    [35.902057, 36.150292],
    [35.9024214, 36.1496469],
    [35.902958, 36.148986],
    [35.903256, 36.148465],
    [35.9034283, 36.148109],
    [35.903841, 36.147167],
    [35.904148, 36.146903],
    [35.904288, 36.146385],
    [35.904548, 36.1461859],
    [35.9047686, 36.1459259],
    [35.90505, 36.145596],
    [35.905348, 36.145076],
    [35.9051568, 36.1446199],
    [35.904845, 36.144317],
    [35.904523, 36.1438917],
    [35.904355, 36.143647],
    [35.9040868, 36.1433772],
    [35.9041141, 36.1430954],
    [35.9048708, 36.1432408],
    [35.9055607, 36.1429737],
    [35.9063192, 36.1428328],
    [35.907142, 36.142206],
    [35.908369, 36.141149],
    [35.908667, 36.140629],
    [35.908955, 36.139851],
    [35.909875, 36.139058],
    [35.910163, 36.13828],
    [35.910461, 36.13776],
    [35.91072, 36.136212],
    [35.911315, 36.13517],
    [35.911622, 36.134906],
    [35.911872, 36.133102],
    [35.912169, 36.132581],
    [35.9121116, 36.1323795],
    [35.9124863, 36.1317211],
    [35.9124863, 36.1312974],
    [35.9130579, 36.1309568],
    [35.913695, 36.1308509],
    [35.9139573, 36.1305785],
    [35.9143321, 36.1296628],
    [35.9143883, 36.1289968],
    [35.9142665, 36.1285806],
    [35.9142572, 36.1279449],
    [35.9138637, 36.127786],
    [35.913517, 36.1278465],
    [35.9132265, 36.1277482],
    [35.9134045, 36.1275741],
    [35.9135357, 36.1272033],
    [35.9133483, 36.1269384],
    [35.9133764, 36.1267795],
    [35.9136575, 36.1268703],
    [35.9137325, 36.126787],
    [35.9142515, 36.1271064],
    [35.9158931, 36.1268521],
    [35.9163053, 36.1263012],
    [35.9161629, 36.1262225],
    [35.9166876, 36.1255323],
    [35.9164627, 36.1253385],
    [35.9148887, 36.1245878],
    [35.9145589, 36.1245454],
    [35.9117255, 36.1271125],
    [35.9114407, 36.1274878],
    [35.9112533, 36.1274878],
    [35.9112053, 36.1272972],
    [35.9113807, 36.1271306],
    [35.9143303, 36.1244031],
    [35.9145926, 36.1243199],
    [35.9148362, 36.1243123],
    [35.9150142, 36.1242215],
    [35.9156795, 36.1243123],
    [35.9161948, 36.1245999],
    [35.916682, 36.1246529],
    [35.9171973, 36.1244637],
    [35.9176096, 36.1244939],
    [35.9177689, 36.1246453],
    [35.9180968, 36.1245848],
    [35.9195584, 36.1228138],
    [35.9203736, 36.1216937],
    [35.9206828, 36.1211412],
    [35.9212449, 36.1205282],
    [35.9212449, 36.120286],
    [35.9214792, 36.1201497],
    [35.9215541, 36.1199984],
    [35.921348, 36.1198546],
    [35.9213761, 36.1196881],
    [35.9216103, 36.1197562],
    [35.9218727, 36.1191885],
    [35.9218727, 36.1186739],
    [35.9220507, 36.1184392],
    [35.9226691, 36.1183257],
    [35.922908, 36.1180472],
    [35.9234093, 36.1174629],
    [35.9251801, 36.1143294],
    [35.9261639, 36.1127247],
    [35.9269509, 36.1116044],
    [35.927307, 36.1109459],
    [35.9285157, 36.1089627],
    [35.9303052, 36.1060862],
    [35.931795, 36.1035351],
    [35.932104, 36.1030574],
    [35.9327694, 36.1020287],
    [35.9333925, 36.1008628],
    [35.9342826, 36.099538],
    [35.9345754, 36.0991973],
    [35.9347628, 36.0986863],
    [35.9355474, 36.0973047],
    [35.9363907, 36.0958852],
    [35.937344, 36.094466],
    [35.9378401, 36.0934813],
    [35.938235, 36.092903],
    [35.939127, 36.09134],
    [35.939424, 36.090819],
    [35.93973, 36.090555],
    [35.940027, 36.090034],
    [35.940315, 36.089256],
    [35.940612, 36.088736],
    [35.940918, 36.088471],
    [35.940899, 36.087958],
    [35.941206, 36.087693],
    [35.941503, 36.087173],
    [35.942097, 36.086131],
    [35.942691, 36.085089],
    [35.942997, 36.084824],
    [35.942978, 36.084311],
    [35.9433419, 36.0835215],
    [35.943888, 36.083261],
    [35.944166, 36.082227],
    [35.944473, 36.081963],
    [35.9453315, 36.0799847],
    [35.945938, 36.078844],
    [35.9464437, 36.0783073],
    [35.9467085, 36.0777603],
    [35.9472453, 36.0767208],
    [35.947423, 36.076239],
    [35.947413, 36.075983],
    [35.9476299, 36.0755127],
    [35.948017, 36.075197],
    [35.9480456, 36.074708],
    [35.9483416, 36.074187],
    [35.948898, 36.073378],
    [35.9490712, 36.073074],
    [35.949195, 36.072857],
    [35.9495716, 36.0722729],
    [35.9496115, 36.0715382],
    [35.9498792, 36.0710053],
    [35.950364, 36.070259],
    [35.95067, 36.069995],
    [35.950967, 36.069474],
    [35.951254, 36.068696],
    [35.951848, 36.067655],
    [35.952135, 36.066877],
    [35.952442, 36.066613],
    [35.952739, 36.066091],
    [35.953026, 36.065314],
    [35.953323, 36.064793],
    [35.9534239, 36.0643385],
    [35.9535998, 36.064031],
    [35.953907, 36.063494],
    [35.9540868, 36.0630678],
    [35.9543488, 36.0626853],
    [35.9546458, 36.0621643],
    [35.9549328, 36.0613863],
    [35.955391, 36.060889],
    [35.955669, 36.059855],
    [35.955975, 36.05959],
    [35.956272, 36.059069],
    [35.9565218, 36.0581396],
    [35.95655, 36.058035],
    [35.956846, 36.057514],
    [35.957124, 36.05648],
    [35.95743, 36.056215],
    [35.957727, 36.055695],
    [35.958034, 36.05543],
    [35.958321, 36.054652],
    [35.958608, 36.053875],
    [35.958914, 36.05361],
    [35.959508, 36.052568],
    [35.9595999, 36.0520233],
    [35.959785, 36.051534],
    [35.960379, 36.050492],
    [35.960685, 36.050228],
    [35.960972, 36.04945],
    [35.9610446, 36.049032],
    [35.961575, 36.048665],
    [35.9616351, 36.0478786],
    [35.9619311, 36.0473576],
    [35.9619971, 36.0471257],
    [35.9620817, 36.0467814],
    [35.9621388, 36.0464039],
    [35.9625002, 36.0459825],
    [35.9627259, 36.0458884],
    [35.9629032, 36.0456645],
    [35.9631282, 36.045473],
    [35.9632033, 36.0453168],
    [35.9633696, 36.0450523],
    [35.9637657, 36.0440779],
    [35.963981, 36.0436821],
    [35.964504, 36.042428],
    [35.964811, 36.042164],
    [35.965107, 36.041642],
    [35.9654543, 36.0412593],
    [35.9664002, 36.039807],
    [35.9666, 36.039294],
    [35.966897, 36.038773],
    [35.96751, 36.038244],
    [35.967797, 36.037466],
    [35.968103, 36.037202],
    [35.9684, 36.036681],
    [35.9687776, 36.036152],
    [35.968993, 36.035639],
    [35.9694155, 36.0349204],
    [35.9696529, 36.0342809],
    [35.970189, 36.033811],
    [35.9703338, 36.0334167],
    [35.970792, 36.033026],
    [35.971079, 36.032248],
    [35.9712238, 36.03183],
    [35.9713424, 36.0316543],
    [35.9715355, 36.0313682],
    [35.9719204, 36.0308936],
    [35.9721138, 36.0303489],
    [35.9724208, 36.0300849],
    [35.9727168, 36.0295639],
    [35.9730038, 36.0287859],
    [35.973758, 36.027815],
    [35.974045, 36.027037],
    [35.974658, 36.026509],
    [35.974954, 36.025988],
    [35.975232, 36.024953],
    [35.975538, 36.024689],
    [35.975835, 36.024168],
    [35.976121, 36.02339],
    [35.976428, 36.023126],
    [35.977001, 36.02157],
    [35.977308, 36.021306],
    [35.977604, 36.020785],
    [35.978465, 36.018452],
    [35.979058, 36.01741],
    [35.979345, 36.016632],
    [35.979641, 36.016111],
    [35.979928, 36.015333],
    [35.979909, 36.01482],
    [35.980502, 36.013778],
    [35.980482, 36.013265],
    [35.980779, 36.012744],
    [35.9808919, 36.0123886],
    [35.981353, 36.011188],
    [35.9814579, 36.0104826],
    [35.98163, 36.010154],
    [35.981453, 36.009645],
    [35.9809895, 36.009664],
    [35.980826, 36.009788],
    [35.9806297, 36.0096992],
    [35.980347, 36.009672],
    [35.9799131, 36.0092217],
    [35.9796315, 36.0088642],
    [35.9797407, 36.0087212],
    [35.9793927, 36.0086538],
    [35.9791311, 36.0084204],
    [35.9790902, 36.0082665],
    [35.9793297, 36.0082652],
    [35.9797794, 36.0084813],
    [35.9801083, 36.0080791],
    [35.9801518, 36.0076983],
    [35.9800862, 36.0075948],
    [35.9799974, 36.007455],
    [35.9796627, 36.0070521],
    [35.9790267, 36.0066707],
    [35.9791467, 36.00653],
    [35.9788673, 36.0064203],
    [35.9786687, 36.0060861],
    [35.9785426, 36.0059217],
    [35.9788179, 36.005822],
    [35.9788097, 36.0053366],
    [35.978584, 36.004836],
    [35.978422, 36.004711],
    [35.977791, 36.004727],
    [35.9771355, 36.0043245],
    [35.97694, 36.0036047],
    [35.976632, 36.003343],
    [35.9760699, 36.0029224],
    [35.9759209, 36.0028401],
    [35.9757343, 36.0029443],
    [35.975436, 36.0029232],
    [35.9751812, 36.0028999],
    [35.9748722, 36.002881],
    [35.9745774, 36.0026921],
    [35.9743634, 36.0024976],
    [35.974421, 36.0023946],
    [35.9746799, 36.0022483],
    [35.9746634, 36.0018793],
    [35.9742659, 36.0014334],
    [35.9738924, 36.0011825],
    [35.9731923, 36.0008487],
    [35.9724814, 36.0006193],
    [35.9716855, 36.0006134],
    [35.971636, 36.00077],
    [35.9714149, 36.0008635],
    [35.9711582, 36.0007058],
    [35.9709774, 36.0004232],
    [35.9707842, 36.0002869],
    [35.9709157, 35.9999478],
    [35.9708171, 35.9998346],
    [35.9705911, 35.9997018],
    [35.9702089, 35.9997616],
    [35.9699893, 35.9996095],
    [35.9699829, 35.9994923],
    [35.9695802, 35.9994092],
    [35.9696254, 35.9984052],
    [35.969334, 35.998388],
    [35.968594, 35.9984351],
    [35.967741, 35.998041],
    [35.9673201, 35.9976472],
    [35.9673694, 35.9974145],
    [35.9671804, 35.9973579],
    [35.9669599, 35.9973924],
    [35.966133, 35.997311],
    [35.9656318, 35.9971491],
    [35.9658654, 35.9968526],
    [35.9656065, 35.996683],
    [35.9653495, 35.996564],
    [35.9649573, 35.9963146],
    [35.9643244, 35.996171],
    [35.9638641, 35.9958718],
    [35.96386, 35.9957089],
    [35.9641189, 35.9957023],
    [35.964008, 35.995437],
    [35.964156, 35.995177],
    [35.9637203, 35.9950938],
    [35.9637326, 35.9949409],
    [35.9636957, 35.9947913],
    [35.9634943, 35.9949442],
    [35.9632765, 35.9949409],
    [35.9629478, 35.9947381],
    [35.962767, 35.9944721],
    [35.962619, 35.9944322],
    [35.9622944, 35.9940964],
    [35.9622122, 35.9938803],
    [35.9618177, 35.9936409],
    [35.9616903, 35.993375],
    [35.9612301, 35.9931056],
    [35.9609876, 35.9929195],
    [35.9606424, 35.9930325],
    [35.9603835, 35.9927765],
    [35.9601082, 35.9926668],
    [35.9601, 35.9925105],
    [35.9598082, 35.9924008],
    [35.959778, 35.992203],
    [35.9596439, 35.9916327],
    [35.9596809, 35.9913601],
    [35.959763, 35.9911905],
    [35.9594507, 35.9910476],
    [35.959414, 35.990927],
    [35.9592083, 35.9907317],
    [35.9592576, 35.9904757],
    [35.9592535, 35.9900168],
    [35.9592206, 35.9896312],
    [35.9590686, 35.9895447],
    [35.958888, 35.989527],
    [35.958725, 35.989403],
    [35.958563, 35.989278],
    [35.9584, 35.989154],
    [35.9584398, 35.9889628],
    [35.9588056, 35.9887766],
    [35.9589535, 35.9887966],
    [35.959159, 35.9887035],
    [35.9591055, 35.988361],
    [35.959472, 35.988229],
    [35.9596685, 35.9881715],
    [35.9597858, 35.9880382],
    [35.9597959, 35.9877392],
    [35.9597174, 35.9872143],
    [35.9596891, 35.9870244],
    [35.9592247, 35.9867883],
    [35.9591672, 35.9866719],
    [35.9589165, 35.986775],
    [35.958783, 35.986705],
    [35.958619, 35.986581],
    [35.9585344, 35.9864225],
    [35.9583987, 35.9863926],
    [35.9581275, 35.9860568],
    [35.957959, 35.985826],
    [35.9579385, 35.9856378],
    [35.9579714, 35.9854882],
    [35.9582262, 35.9854516],
    [35.9582796, 35.9852488],
    [35.9581727, 35.9851423],
    [35.95777, 35.9850692],
    [35.9576673, 35.9848896],
    [35.9577988, 35.9848098],
    [35.9578276, 35.9846336],
    [35.9579056, 35.9843676],
    [35.9582426, 35.9843343],
    [35.9589905, 35.9841614],
    [35.9589588, 35.9839259],
    [35.9589535, 35.9838854],
    [35.9587891, 35.9838987],
    [35.9586001, 35.9838356],
    [35.9587727, 35.9836959],
    [35.9586617, 35.9835163],
    [35.9584974, 35.9832603],
    [35.9582714, 35.9832736],
    [35.958017, 35.983128],
    [35.957854, 35.983004],
    [35.9577289, 35.9825886],
    [35.9578234, 35.9823525],
    [35.957668, 35.982238],
    [35.9572646, 35.9822893],
    [35.9570016, 35.9821929],
    [35.9565824, 35.982276],
    [35.956303, 35.982419],
    [35.9561756, 35.9825221],
    [35.9559208, 35.9827682],
    [35.9556743, 35.9827083],
    [35.955462, 35.98242],
    [35.955299, 35.982296],
    [35.9552839, 35.9820765],
    [35.954951, 35.9819468],
    [35.9547045, 35.9816775],
    [35.9548853, 35.9815245],
    [35.955025, 35.9813117],
    [35.9550743, 35.9811454],
    [35.954951, 35.9809659],
    [35.9547538, 35.9808561],
    [35.954453, 35.980776],
    [35.9541251, 35.9807863],
    [35.9539073, 35.9807331],
    [35.9535908, 35.9804105],
    [35.9533114, 35.9802177],
    [35.9530525, 35.9801312],
    [35.952884, 35.9800181],
    [35.952841, 35.979916],
    [35.9526252, 35.9798353],
    [35.9522841, 35.9796224],
    [35.9518197, 35.9794595],
    [35.9515978, 35.9793265],
    [35.9514581, 35.9792234],
    [35.9512362, 35.9790804],
    [35.951039, 35.9789707],
    [35.9505746, 35.9787645],
    [35.95025, 35.9787711],
    [35.9500733, 35.9787711],
    [35.9500198, 35.978555],
    [35.9500897, 35.9783023],
    [35.9502212, 35.9782224],
    [35.95025, 35.9780695],
    [35.9502911, 35.9780329],
    [35.949918, 35.977805],
    [35.9496747, 35.9776239],
    [35.9494939, 35.9776571],
    [35.9493706, 35.9776039],
    [35.949268, 35.977308],
    [35.9488939, 35.9772847],
    [35.948561, 35.9772381],
    [35.9484172, 35.9771683],
    [35.9482857, 35.9767426],
    [35.948446, 35.9766129],
    [35.9485857, 35.9765863],
    [35.9485282, 35.9764633],
    [35.9483309, 35.9765165],
    [35.947971, 35.97644],
    [35.9477063, 35.9762538],
    [35.9473488, 35.9760775],
    [35.9471269, 35.9762636],
    [35.947015, 35.976207],
    [35.946852, 35.976083],
    [35.9471187, 35.9757483],
    [35.946991, 35.975566],
    [35.9467201, 35.9755022],
    [35.946359, 35.975581],
    [35.9461776, 35.9754324],
    [35.9461612, 35.9752694],
    [35.9463132, 35.9751896],
    [35.946531, 35.9750632],
    [35.9462598, 35.9746874],
    [35.9462598, 35.9744879],
    [35.9459146, 35.9743582],
    [35.9456968, 35.9740955],
    [35.9455119, 35.9739159],
    [35.9454585, 35.9737197],
    [35.945401, 35.97358],
    [35.945025, 35.973687],
    [35.944686, 35.9736033],
    [35.9446243, 35.9734503],
    [35.9448996, 35.9734004],
    [35.9446449, 35.9730047],
    [35.9444764, 35.972722],
    [35.9440983, 35.9724593],
    [35.9437326, 35.9722032],
    [35.9431943, 35.9718241],
    [35.9425121, 35.9713585],
    [35.9421587, 35.9708796],
    [35.94207, 35.970678],
    [35.9419902, 35.9704705],
    [35.9419615, 35.9702111],
    [35.941719, 35.9699883],
    [35.9411725, 35.9699849],
    [35.9410163, 35.9697688],
    [35.9408889, 35.9696224],
    [35.940606, 35.969558],
    [35.9403835, 35.9692566],
    [35.9402273, 35.9689739],
    [35.9400178, 35.9688974],
    [35.9398781, 35.9689639],
    [35.939915, 35.9692466],
    [35.9398164, 35.9692965],
    [35.9396644, 35.9693763],
    [35.9395082, 35.9693663],
    [35.9393233, 35.9691801],
    [35.9385261, 35.9686014],
    [35.9381604, 35.9682123],
    [35.9380905, 35.9681058],
    [35.9378686, 35.9679961],
    [35.9377248, 35.9678631],
    [35.9376837, 35.9680959],
    [35.9375563, 35.9680759],
    [35.9371618, 35.9677799],
    [35.9369563, 35.9675172],
    [35.9367098, 35.9673841],
    [35.9363933, 35.9672644],
    [35.936081, 35.9669584],
    [35.9359167, 35.9665294],
    [35.9356167, 35.9663864],
    [35.9352879, 35.9661369],
    [35.9353783, 35.9660205],
    [35.9352756, 35.9659573],
    [35.934944, 35.965972],
    [35.9343633, 35.9655848],
    [35.9342072, 35.9652722],
    [35.933796, 35.964845],
    [35.9335538, 35.9645804],
    [35.9335292, 35.9644074],
    [35.9331963, 35.963975],
    [35.9329826, 35.9638021],
    [35.932621, 35.963383],
    [35.9324973, 35.9632556],
    [35.9324595, 35.963091],
    [35.9323462, 35.9631168],
    [35.9322765, 35.9632368],
    [35.9318348, 35.9630298],
    [35.9318784, 35.9628746],
    [35.9318464, 35.9626794],
    [35.9318116, 35.9625312],
    [35.9316953, 35.9626018],
    [35.9315733, 35.9627335],
    [35.9314396, 35.9627523],
    [35.93086, 35.962348],
    [35.9304284, 35.9620471],
    [35.9296887, 35.9617212],
    [35.9291792, 35.9612389],
    [35.928909, 35.960855],
    [35.9285299, 35.9607167],
    [35.9283203, 35.9605204],
    [35.9285381, 35.9602876],
    [35.9286861, 35.9599982],
    [35.9291628, 35.9597088],
    [35.929168, 35.959307],
    [35.9296148, 35.9591667],
    [35.9298737, 35.9591168],
    [35.9299353, 35.9588474],
    [35.9302106, 35.9586511],
    [35.9301202, 35.9582553],
    [35.929742, 35.957752],
    [35.92958, 35.957627],
    [35.928939, 35.957386],
    [35.928776, 35.957262],
    [35.9284751, 35.9569261],
    [35.9283451, 35.9568236],
    [35.9281863, 35.9567144],
    [35.9279532, 35.9565878],
    [35.927323, 35.956398],
    [35.9267917, 35.9563659],
    [35.9260849, 35.9565389],
    [35.9258137, 35.9565422],
    [35.9256575, 35.9565821],
    [35.9254397, 35.9568316],
    [35.925133, 35.956965],
    [35.9247863, 35.9570977],
    [35.924186, 35.956988],
    [35.9235124, 35.9564025],
    [35.923354, 35.955853],
    [35.9230317, 35.9555676],
    [35.9226289, 35.9554079],
    [35.9224563, 35.9552782],
    [35.9223084, 35.9551086],
    [35.9224194, 35.9549921],
    [35.9228385, 35.9551185],
    [35.9229371, 35.9548691],
    [35.9230111, 35.9543867],
    [35.9227645, 35.9540541],
    [35.922744, 35.9536982],
    [35.9225632, 35.9533722],
    [35.9225426, 35.9530728],
    [35.9224276, 35.9530828],
    [35.9221482, 35.9525605],
    [35.9218211, 35.9520504],
    [35.9217906, 35.951975],
    [35.9217781, 35.9519105],
    [35.9217459, 35.9517436],
    [35.9217463, 35.9516772],
    [35.9217639, 35.9516303],
    [35.9218103, 35.951617],
    [35.9218703, 35.9516115],
    [35.9218796, 35.9515568],
    [35.9218793, 35.9514778],
    [35.9218596, 35.9513662],
    [35.9218251, 35.9512553],
    [35.9217728, 35.951184],
    [35.9217142, 35.9511399],
    [35.9216381, 35.9510996],
    [35.9215463, 35.9510541],
    [35.9213678, 35.9510254],
    [35.9212828, 35.9510205],
    [35.9212112, 35.9510303],
    [35.9211921, 35.9509755],
    [35.921203, 35.9508972],
    [35.9212521, 35.950872],
    [35.9212718, 35.9508475],
    [35.9212314, 35.9507292],
    [35.9212753, 35.9507543],
    [35.9213151, 35.9507914],
    [35.9213673, 35.9508142],
    [35.9214247, 35.9508178],
    [35.9215542, 35.9507693],
    [35.9216127, 35.9507256],
    [35.9216481, 35.950679],
    [35.9215317, 35.9505411],
    [35.921534, 35.9505068],
    [35.9215769, 35.9504972],
    [35.9216984, 35.9505452],
    [35.921766, 35.9504847],
    [35.9217411, 35.950445],
    [35.9215748, 35.9503185],
    [35.9216437, 35.9502842],
    [35.9217495, 35.9502951],
    [35.9217947, 35.9501853],
    [35.9215223, 35.9500856],
    [35.9214231, 35.9500406],
    [35.9212811, 35.9499492],
    [35.9211989, 35.9498893],
    [35.9210592, 35.9497263],
    [35.9208126, 35.94953],
    [35.920746, 35.949367],
    [35.9207222, 35.9490377],
    [35.9205209, 35.9489545],
    [35.9203359, 35.9488081],
    [35.9200771, 35.9487383],
    [35.9199866, 35.9487582],
    [35.9198387, 35.9486684],
    [35.9195716, 35.9484954],
    [35.9192881, 35.9485719],
    [35.9191977, 35.948522],
    [35.9191894, 35.9481528],
    [35.918957, 35.947998],
    [35.918795, 35.947873],
    [35.918873, 35.9477635],
    [35.918947, 35.9476271],
    [35.919102, 35.947609],
    [35.919362, 35.9478434],
    [35.9194648, 35.9479299],
    [35.9196743, 35.9479498],
    [35.9198223, 35.9478966],
    [35.9201757, 35.9475406],
    [35.920192, 35.947197],
    [35.9202907, 35.947025],
    [35.920173, 35.946684],
    [35.920151, 35.9463031],
    [35.9200729, 35.94619],
    [35.9198099, 35.94619],
    [35.9194113, 35.9462299],
    [35.9191442, 35.94616],
    [35.9189018, 35.9460968],
    [35.9188977, 35.9458307],
    [35.918873, 35.945688],
    [35.9189799, 35.9454847],
    [35.9189593, 35.9452585],
    [35.9191196, 35.9450888],
    [35.9190251, 35.9446563],
    [35.9190662, 35.9442405],
    [35.9187333, 35.9436915],
    [35.9185196, 35.9432724],
    [35.918347, 35.9431925],
    [35.9182155, 35.9430162],
    [35.9182977, 35.94278],
    [35.9182854, 35.942394],
    [35.9183059, 35.9417919],
    [35.9184621, 35.941539],
    [35.9186224, 35.9407805],
    [35.9188895, 35.9406174],
    [35.919284, 35.9406673],
    [35.919464, 35.94028],
    [35.9195552, 35.9398156],
    [35.9194853, 35.9392667],
    [35.9194483, 35.9387077],
    [35.9194812, 35.9379824],
    [35.9193785, 35.9377229],
    [35.91921, 35.9376896],
    [35.919201, 35.937461],
    [35.9193661, 35.9373469],
    [35.919325, 35.9370708],
    [35.9193497, 35.9368079],
    [35.9192552, 35.9364686],
    [35.919154, 35.936178],
    [35.9190497, 35.9354538],
    [35.919116, 35.935151],
    [35.919098, 35.934638],
    [35.919069, 35.933868],
    [35.91906, 35.933612],
    [35.9190013, 35.9333365],
    [35.918964, 35.9333058],
    [35.9189411, 35.9332753],
    [35.9189276, 35.933234],
    [35.9189284, 35.9331942],
    [35.9189575, 35.9331446],
    [35.9189694, 35.9331126],
    [35.9189531, 35.9330829],
    [35.9189483, 35.9330534],
    [35.9189503, 35.933022],
    [35.9189506, 35.9329734],
    [35.9189265, 35.9329175],
    [35.9189149, 35.932879],
    [35.9189072, 35.9328382],
    [35.9189023, 35.9327531],
    [35.9188955, 35.9325368],
    [35.9188759, 35.9317766],
    [35.9188663, 35.9315441],
    [35.9188706, 35.9314632],
    [35.9188643, 35.9313773],
    [35.9188589, 35.9313163],
    [35.9188365, 35.9311476],
    [35.9188105, 35.9309227],
    [35.918776, 35.9307212],
    [35.9187664, 35.9306361],
    [35.918761, 35.9305628],
    [35.9187445, 35.9304059],
    [35.9187346, 35.9303537],
    [35.9187221, 35.9303136],
    [35.9186536, 35.9300775],
    [35.9185677, 35.9298205],
    [35.9185373, 35.9297401],
    [35.9185049, 35.9296732],
    [35.9184405, 35.9295383],
    [35.9181667, 35.9291672],
    [35.9181089, 35.9290889],
    [35.9180627, 35.9290366],
    [35.918024, 35.9289966],
    [35.917935, 35.9289141],
    [35.9177629, 35.9287314],
    [35.917962, 35.9285957],
    [35.9198935, 35.9271341],
    [35.9224887, 35.922338],
    [35.922849, 35.9219057],
    [35.9233787, 35.9215527],
    [35.9234976, 35.9214733],
    [35.9275671, 35.9217595],
    [35.9288661, 35.9213292],
    [35.9302352, 35.9208968],
    [35.9314389, 35.9205373],
    [35.93355, 35.9214733],
    [35.9487533, 35.9295],
    [35.9543189, 35.9298023],
    [35.9552436, 35.9301934],
    [35.9557059, 35.9301934],
    [35.9565594, 35.929251],
    [35.957324, 35.9289665],
    [35.9579108, 35.9294111],
    [35.9582131, 35.9292688],
    [35.9593867, 35.9274906],
    [35.9596679, 35.9274995],
    [35.9602854, 35.9278082],
    [35.9626196, 35.9289754],
    [35.9658593, 35.9313848],
    [35.9664994, 35.9314915],
    [35.9682361, 35.9336899],
    [35.9698602, 35.9336609],
    [35.9706248, 35.9338387],
    [35.9720077, 35.9345918],
    [35.9723322, 35.9344701],
    [35.9728868, 35.9340836],
    [35.9735246, 35.9341408],
    [35.9739225, 35.9348431],
    [35.9742215, 35.9353707],
    [35.9745252, 35.9355666],
    [35.9750168, 35.9358836],
    [35.9749587, 35.9366735],
    [35.9749279, 35.9370928],
    [35.9752464, 35.9373795],
    [35.9756396, 35.9382586],
    [35.9759433, 35.9389374],
    [35.978086, 35.9393833],
    [35.9812002, 35.9400313],
    [35.9813913, 35.940071],
    [35.9830356, 35.9404132],
    [35.9835927, 35.940393],
    [35.9854045, 35.9403272],
    [35.9868051, 35.9402764],
    [35.9890808, 35.9401939],
    [35.9919776, 35.9400887],
    [35.9932026, 35.9400443],
    [35.9932548, 35.9399529],
    [35.9940226, 35.9386094],
    [35.9977122, 35.9397163],
    [35.998631, 35.9391732],
    [35.9991061, 35.9386094],
    [36.001205, 35.9396604],
    [36.0017722, 35.9396933],
    [36.0023077, 35.9397244],
    [36.0039743, 35.9398212],
    [36.0044331, 35.939998],
    [36.0065395, 35.94081],
    [36.0080604, 35.9379751],
    [36.0093028, 35.9357934],
    [36.0105991, 35.9335168],
    [36.0107424, 35.933265],
    [36.0117322, 35.9315266],
    [36.0121052, 35.9308714],
    [36.0143918, 35.9268553],
    [36.0148233, 35.9260971],
    [36.0173148, 35.921969],
    [36.0178886, 35.9210181],
    [36.015079, 35.9148123],
    [36.0132475, 35.9139054],
    [36.0127852, 35.9124117],
    [36.0111753, 35.9106844],
    [36.0105592, 35.9094662],
    [36.010644, 35.9091168],
    [36.0100312, 35.9080701],
    [36.0101786, 35.9076559],
    [36.0105713, 35.9073037],
    [36.0102028, 35.9060952],
    [36.0102045, 35.9056883],
    [36.0099434, 35.905597],
    [36.0101369, 35.9051955],
    [36.0102427, 35.9050144],
    [36.0102709, 35.9049709],
    [36.0102836, 35.9049514],
    [36.0104818, 35.9047355],
    [36.0103586, 35.9044614],
    [36.0102946, 35.9043191],
    [36.0100683, 35.9038537],
    [36.0088799, 35.9029932],
    [36.0076906, 35.9025034],
    [36.0055778, 35.8976877],
    [36.0054794, 35.8974999],
    [36.0052622, 35.897086],
    [35.9990477, 35.8852412],
    [35.9983441, 35.8839003],
    [35.998955, 35.8818943],
    [35.9995215, 35.8800336],
    [36.0204543, 35.8818797],
    [36.0290454, 35.8825193],
    [36.0325197, 35.8785945],
    [36.0332791, 35.8784878],
    [36.0391484, 35.8776641],
    [36.042172, 35.8778531],
    [36.0476215, 35.8800386],
    [36.0533216, 35.8778822],
    [36.0587873, 35.8760215],
    [36.0592324, 35.875717],
    [36.0646187, 35.8720331],
    [36.0683504, 35.8692689],
    [36.073326, 35.8707892],
    [36.0751861, 35.8711611],
    [36.0753992, 35.8712038],
    [36.0817569, 35.8718949],
    [36.0907143, 35.8653669],
    [36.0952713, 35.86419],
    [36.1063063, 35.863249],
    [36.1102008, 35.8623245],
    [36.1125954, 35.8620372],
    [36.1128556, 35.8620061],
    [36.1134883, 35.8616715],
    [36.1155288, 35.8605927],
    [36.1164199, 35.8594865],
    [36.1173714, 35.856208],
    [36.1229875, 35.8537899],
    [36.1249895, 35.8507739],
    [36.1298597, 35.8473957],
    [36.1310398, 35.8460738],
    [36.1356756, 35.8413098],
    [36.137161, 35.8374186],
    [36.1381934, 35.8367841],
    [36.1382579, 35.8360528],
    [36.1383654, 35.8356442],
    [36.1385698, 35.8341171],
    [36.1397634, 35.8336977],
    [36.1394516, 35.8326116],
    [36.139258, 35.8307619],
    [36.1401506, 35.8302349],
    [36.1390769, 35.8258576],
    [36.1389346, 35.8234037],
    [36.1393547, 35.8216062],
    [36.1390591, 35.8205409],
    [36.1397731, 35.8188702],
    [36.1395036, 35.8155798],
    [36.1414063, 35.8138728],
    [36.1465125, 35.8108358],
    [36.1479378, 35.8107747],
    [36.149783, 35.8097253],
    [36.1515656, 35.8080485],
    [36.1539433, 35.8076804],
    [36.1561152, 35.808155],
    [36.1563792, 35.8083133],
    [36.1564584, 35.8085509],
    [36.1565112, 35.8089997],
    [36.1561019, 35.8095795],
    [36.1564665, 35.8103015],
    [36.1581409, 35.8107399],
    [36.1593559, 35.8112844],
    [36.1598419, 35.81163],
    [36.1607846, 35.8117005],
    [36.1612508, 35.8117548],
    [36.1615683, 35.8119395],
    [36.1619363, 35.8122119],
    [36.162027, 35.812647],
    [36.162143, 35.8135265],
    [36.1623513, 35.8140426],
    [36.1627316, 35.8143631],
    [36.1651542, 35.8151332],
    [36.1653737, 35.8154575],
    [36.1650421, 35.81625],
    [36.1638684, 35.8165496],
    [36.1636308, 35.8166816],
    [36.1631004, 35.8170751],
    [36.1629452, 35.8177567],
    [36.1630731, 35.818239],
    [36.1651618, 35.8205619],
    [36.1652146, 35.8207731],
    [36.1651354, 35.8209842],
    [36.1646075, 35.8215386],
    [36.1644755, 35.8217233],
    [36.1644491, 35.8219345],
    [36.1646075, 35.8229112],
    [36.1645811, 35.8231488],
    [36.1644227, 35.8232543],
    [36.1632613, 35.8234391],
    [36.1628917, 35.8237559],
    [36.1628654, 35.8241782],
    [36.1635253, 35.8247325],
    [36.1647395, 35.8244158],
    [36.1673791, 35.8251021],
    [36.1676167, 35.8256828],
    [36.168303, 35.8264483],
    [36.170098, 35.8273194],
    [36.1704939, 35.8282169],
    [36.1705731, 35.8293783],
    [36.1704675, 35.8301174],
    [36.1722361, 35.8320708],
    [36.1724473, 35.8324931],
    [36.1721833, 35.8343145],
    [36.1723681, 35.834684],
    [36.1733711, 35.8355287],
    [36.1729752, 35.8369805],
    [36.1730544, 35.8379836],
    [36.1728168, 35.839013],
    [36.1721569, 35.8392242],
    [36.171761, 35.8414679],
    [36.1729224, 35.844002],
    [36.1726584, 35.8466152],
    [36.1703774, 35.8504537],
    [36.1737929, 35.8589989],
    [36.1736684, 35.8596568],
    [36.173864, 35.8604214],
    [36.1736684, 35.8619862],
    [36.1734372, 35.8628931],
    [36.1734906, 35.8640133],
    [36.1729749, 35.8651336],
    [36.1729393, 35.8655781],
    [36.1742196, 35.8652758],
    [36.1752332, 35.8662005],
    [36.1752154, 35.8688322],
    [36.1741663, 35.8698279],
    [36.1743619, 35.8711616],
    [36.1735084, 35.8718728],
    [36.173295, 35.8737399],
    [36.1713177, 35.8770087],
    [36.170212, 35.8797729],
    [36.1692445, 35.8825371],
    [36.1691399, 35.8835716],
    [36.1714361, 35.8854539],
    [36.1718955, 35.8881199],
    [36.170915, 35.8879774],
    [36.1720602, 35.8896006],
    [36.1729947, 35.8914238],
    [36.1731572, 35.8930586],
    [36.1727496, 35.8944927],
    [36.1734415, 35.8949134],
    [36.173505, 35.8952709],
    [36.1730258, 35.8960598],
    [36.1728515, 35.8966008],
    [36.1726283, 35.8968845],
    [36.1726258, 35.8973189],
    [36.1737233, 35.8984288],
    [36.173888, 35.8992423],
    [36.1732518, 35.9004073],
    [36.1737264, 35.9009358],
    [36.1736691, 35.9015007],
    [36.1740822, 35.9022753],
    [36.1739529, 35.9029197],
    [36.174374, 35.9034068],
    [36.1744507, 35.9036649],
    [36.174094, 35.904235],
    [36.1738818, 35.9053558],
    [36.1736684, 35.9074363],
    [36.173046, 35.9094989],
    [36.1743619, 35.9142644],
    [36.1758733, 35.9215549],
    [36.1779518, 35.9252443],
    [36.1794475, 35.9282409],
    [36.181525, 35.9301139],
    [36.1842733, 35.9341471],
    [36.1869502, 35.9370024],
    [36.1910292, 35.9412849],
    [36.1939458, 35.9441408],
    [36.1969659, 35.9468656],
    [36.1977776, 35.9476028],
    [36.1982689, 35.9480491],
    [36.1997892, 35.949293],
    [36.2015393, 35.9500742],
    [36.2064482, 35.9524778],
    [36.2075561, 35.9530012],
    [36.2083327, 35.9533344],
    [36.2148429, 35.9545337],
    [36.2183094, 35.9553743],
    [36.2214883, 35.9556508],
    [36.229495, 35.9579179],
    [36.2304586, 35.958025],
    [36.2336882, 35.959502],
    [36.2380736, 35.9604881],
    [36.2420213, 35.9614927],
    [36.247638, 35.9620941],
    [36.2511058, 35.9625767],
    [36.2547036, 35.9629855],
    [36.2556954, 35.9629863],
    [36.2569224, 35.9627018],
    [36.25891, 35.961958],
    [36.2604609, 35.9614749],
    [36.262897, 35.9593233],
    [36.2661689, 35.9558025],
    [36.2681426, 35.9533309],
    [36.269052, 35.9527746],
    [36.27129, 35.9520328],
    [36.2724636, 35.9511259],
    [36.2736551, 35.9507707],
    [36.2768201, 35.94949],
    [36.2803409, 35.9496501],
    [36.2809099, 35.9495434],
    [36.2816567, 35.9498279],
    [36.2871868, 35.9521217],
    [36.2911699, 35.9543444],
    [36.2926458, 35.9553402],
    [36.2931082, 35.9558025],
    [36.2944899, 35.9564188],
    [36.2965183, 35.9588885],
    [36.2980591, 35.9611301],
    [36.2989096, 35.962779],
    [36.2983386, 35.964742],
    [36.2970755, 35.9661362],
    [36.2955903, 35.9677758],
    [36.2924038, 35.9707631],
    [36.2919346, 35.9709881],
    [36.2908789, 35.9766631],
    [36.2861319, 35.9833732],
    [36.2854538, 35.9913682],
    [36.286031, 35.9942466],
    [36.2857441, 35.9950119],
    [36.2859178, 36.0021471],
    [36.2857564, 36.0038864],
    [36.2858946, 36.0059596],
    [36.2864475, 36.0087238],
    [36.2879678, 36.009553],
    [36.2898541, 36.0099301],
    [36.2903164, 36.0105524],
    [36.2939261, 36.0097878],
    [36.2951524, 36.0106127],
    [36.2957398, 36.0107125],
    [36.2968448, 36.0103466],
    [36.2971268, 36.0103568],
    [36.2979057, 36.0106127],
    [36.2988161, 36.0108191],
    [36.3000394, 36.0109569],
    [36.3039126, 36.008529],
    [36.304009, 36.0083829],
    [36.306667, 36.0071502],
    [36.3075889, 36.0071013],
    [36.3092752, 36.0064539],
    [36.3111744, 36.0059825],
    [36.3142862, 36.0050401],
    [36.3188304, 36.0017679],
    [36.3209721, 36.0003813],
    [36.3215055, 35.9993499],
    [36.3230703, 35.9981408],
    [36.3260221, 35.9959714],
    [36.327089, 35.9948334],
    [36.3278181, 35.9934642],
    [36.3297207, 35.9922373],
    [36.330752, 35.991277],
    [36.3323168, 35.9895522],
    [36.336051, 35.988592],
    [36.3392161, 35.9884675],
    [36.340532, 35.9889299],
    [36.3420256, 35.9892499],
    [36.3429952, 35.9893625],
    [36.3491028, 35.9920239],
    [36.3527836, 35.9939087],
    [36.3559843, 35.9962026],
    [36.3567667, 35.9964871],
    [36.3598785, 35.9984253],
    [36.3624568, 35.9997056],
    [36.3645195, 36.0010036],
    [36.3653741, 36.0013192],
    [36.3662799, 36.0016971],
    [36.3678269, 36.0029063],
    [36.3727336, 36.0070957],
    [36.3762168, 36.0115926],
    [36.3743783, 36.0137128],
    [36.3742076, 36.0144283],
    [36.374682, 36.0153748],
    [36.3761244, 36.0164355],
    [36.3767902, 36.0172806],
    [36.3767628, 36.0182497],
    [36.3762142, 36.0191813],
    [36.3752043, 36.0197073],
    [36.3729215, 36.019729],
    [36.3716204, 36.019442],
    [36.369599, 36.0194505],
    [36.3695933, 36.0200403],
    [36.370186, 36.0202771],
    [36.3717935, 36.0201503],
    [36.3724775, 36.0206094],
    [36.3722638, 36.0212156],
    [36.3734214, 36.0223126],
    [36.373638, 36.0229404],
    [36.3731507, 36.0235023],
    [36.3718114, 36.024372],
    [36.3714774, 36.0246753],
    [36.3726552, 36.025687],
    [36.3741561, 36.0261408],
    [36.3765525, 36.0276292],
    [36.3780301, 36.0285281],
    [36.3797447, 36.0290739],
    [36.3799439, 36.0301561],
    [36.3794444, 36.0306755],
    [36.3775339, 36.0315629],
    [36.3776154, 36.0321751],
    [36.3786805, 36.033057],
    [36.3801013, 36.033422],
    [36.3809805, 36.0345441],
    [36.3809523, 36.0352649],
    [36.3799196, 36.0364277],
    [36.3801946, 36.037449],
    [36.380852, 36.0380809],
    [36.3843007, 36.03844],
    [36.3857527, 36.0388135],
    [36.3884873, 36.0392195],
    [36.3895765, 36.0396488],
    [36.3900438, 36.0401756],
    [36.3900846, 36.0412762],
    [36.3898104, 36.0422299],
    [36.3881529, 36.0433086],
    [36.3861352, 36.0440434],
    [36.3831693, 36.0442047],
    [36.3805199, 36.0439343],
    [36.3800053, 36.0448952],
    [36.3800582, 36.0461026],
    [36.3790181, 36.047678],
    [36.3790796, 36.0484038],
    [36.379394, 36.0486762],
    [36.3803038, 36.0486659],
    [36.3815942, 36.0490502],
    [36.3834266, 36.0505735],
    [36.3847866, 36.0499337],
    [36.3852191, 36.0493655],
    [36.3861401, 36.0492435],
    [36.3865234, 36.0498514],
    [36.3867734, 36.0512553],
    [36.3873157, 36.0523008],
    [36.3871346, 36.0530148],
    [36.3804138, 36.0554892],
    [36.3793307, 36.0563646],
    [36.3790488, 36.0570029],
    [36.3794201, 36.0573602],
    [36.3810372, 36.057564],
    [36.3814064, 36.0590561],
    [36.3808349, 36.0603396],
    [36.3796807, 36.0614225],
    [36.3793246, 36.0621422],
    [36.3794046, 36.0633228],
    [36.3799652, 36.0642454],
    [36.3811195, 36.0652124],
    [36.3810082, 36.0659858],
    [36.3796597, 36.0675789],
    [36.3791634, 36.0683651],
    [36.3793808, 36.0693079],
    [36.3801808, 36.0705329],
    [36.3797497, 36.071193],
    [36.3786837, 36.0716158],
    [36.3786654, 36.0721776],
    [36.379383, 36.0732571],
    [36.380321, 36.073801],
    [36.3815059, 36.0749238],
    [36.382629, 36.0752525],
    [36.3835066, 36.0748053],
    [36.383715, 36.0743672],
    [36.3843523, 36.0741281],
    [36.3853831, 36.0741975],
    [36.3862877, 36.0745109],
    [36.3873734, 36.0756981],
    [36.3873734, 36.076304],
    [36.3865532, 36.0770688],
    [36.3861591, 36.0776624],
    [36.386945, 36.0779078],
    [36.388286, 36.0779755],
    [36.388865, 36.0784614],
    [36.3894888, 36.0801253],
    [36.3901475, 36.0820542],
    [36.3893831, 36.0833538],
    [36.3886127, 36.0838798],
    [36.3875802, 36.0851844],
    [36.3859586, 36.0865085],
    [36.384583, 36.0874153],
    [36.3837283, 36.0884115],
    [36.3816043, 36.0902981],
    [36.3806814, 36.0921689],
    [36.3786204, 36.0937436],
    [36.3776748, 36.0952603],
    [36.3774906, 36.0969551],
    [36.3772154, 36.0977172],
    [36.3766551, 36.0987443],
    [36.3766475, 36.0991902],
    [36.376906, 36.0995834],
    [36.3782321, 36.0999046],
    [36.3795528, 36.0997986],
    [36.3807287, 36.0994061],
    [36.3820813, 36.0984069],
    [36.3826645, 36.0971399],
    [36.383237, 36.0955369],
    [36.3843286, 36.0944768],
    [36.385316, 36.0944362],
    [36.3860239, 36.0951076],
    [36.3864951, 36.0963741],
    [36.3860965, 36.0979294],
    [36.385957, 36.0990303],
    [36.3856075, 36.1004715],
    [36.3858959, 36.1020398],
    [36.3856137, 36.1027665],
    [36.3850429, 36.1033361],
    [36.3823694, 36.1048627],
    [36.3813234, 36.1051739],
    [36.3801913, 36.104869],
    [36.3798469, 36.1040928],
    [36.3797519, 36.1027559],
    [36.379254, 36.1020441],
    [36.378935, 36.1018563],
    [36.3785128, 36.1018491],
    [36.3772881, 36.1026668],
    [36.3767014, 36.1028284],
    [36.3756159, 36.1027276],
    [36.3752443, 36.1029096],
    [36.3749117, 36.1032736],
    [36.3746054, 36.1042832],
    [36.3746703, 36.1049076],
    [36.3751185, 36.1056754],
    [36.3754535, 36.1060952],
    [36.3760185, 36.1064156],
    [36.3775939, 36.1068146],
    [36.3785306, 36.1068368],
    [36.3804853, 36.1064332],
    [36.3811994, 36.1065066],
    [36.3816425, 36.1071438],
    [36.3816762, 36.1079198],
    [36.3814073, 36.1090149],
    [36.3808367, 36.1100838],
    [36.3800278, 36.1106395],
    [36.3789511, 36.1108972],
    [36.3783539, 36.1108258],
    [36.3757889, 36.1093184],
    [36.3752764, 36.109285],
    [36.3748816, 36.1094555],
    [36.3745791, 36.1099437],
    [36.374726, 36.1102165],
    [36.3752063, 36.1104824],
    [36.3753632, 36.1107217],
    [36.3747389, 36.1134986],
    [36.374577, 36.1144554],
    [36.3731016, 36.1153919],
    [36.37104, 36.115936],
    [36.370471, 36.1166714],
    [36.370947, 36.1174558],
    [36.3731147, 36.1183885],
    [36.3747693, 36.1188612],
    [36.3770245, 36.1184743],
    [36.3782263, 36.1167917],
    [36.3783534, 36.115507],
    [36.3791611, 36.1153152],
    [36.3800533, 36.1157402],
    [36.3799245, 36.1179962],
    [36.3786727, 36.1185523],
    [36.37828, 36.1194089],
    [36.3782594, 36.1216231],
    [36.3777377, 36.1225888],
    [36.3768005, 36.1227871],
    [36.3754488, 36.1224971],
    [36.3729408, 36.1215505],
    [36.3722075, 36.1214438],
    [36.3717349, 36.121658],
    [36.3715327, 36.122244],
    [36.3722606, 36.1233684],
    [36.3732432, 36.1243396],
    [36.3745367, 36.1249086],
    [36.3771266, 36.1250148],
    [36.3782565, 36.125387],
    [36.3774915, 36.1263258],
    [36.375347, 36.1273353],
    [36.3749053, 36.1279696],
    [36.3750661, 36.1285079],
    [36.3764355, 36.1287742],
    [36.3782365, 36.1293169],
    [36.3791737, 36.1304257],
    [36.3799585, 36.1307017],
    [36.3816662, 36.1311062],
    [36.3834736, 36.130344],
    [36.3841285, 36.1309057],
    [36.3830498, 36.1317793],
    [36.3826506, 36.1319847],
    [36.3824314, 36.1321281],
    [36.382386, 36.1325405],
    [36.3828985, 36.1330579],
    [36.3830652, 36.1334373],
    [36.3822194, 36.1345366],
    [36.382663, 36.135867],
    [36.3839592, 36.1370091],
    [36.3846429, 36.1380252],
    [36.3841657, 36.1394598],
    [36.3844227, 36.140142],
    [36.3852708, 36.1405489],
    [36.3872302, 36.1404109],
    [36.3881305, 36.1407745],
    [36.3880863, 36.1415707],
    [36.3861297, 36.1431825],
    [36.38624, 36.1439544],
    [36.386548, 36.1443187],
    [36.3873783, 36.1440229],
    [36.3885973, 36.1438332],
    [36.388654, 36.1449895],
    [36.3891101, 36.1460314],
    [36.3886692, 36.1465317],
    [36.3876948, 36.1465535],
    [36.3859391, 36.1471999],
    [36.3852236, 36.1478085],
    [36.3845482, 36.1488139],
    [36.3836227, 36.149405],
    [36.3827724, 36.1494164],
    [36.3815947, 36.1489078],
    [36.3802681, 36.1473381],
    [36.3794817, 36.1471036],
    [36.378522, 36.1474811],
    [36.3773324, 36.1493293],
    [36.3770038, 36.1509212],
    [36.3761068, 36.152732],
    [36.3767721, 36.1531117],
    [36.3791939, 36.1527523],
    [36.3798942, 36.1529446],
    [36.3808909, 36.1535928],
    [36.3809606, 36.1544592],
    [36.3807102, 36.1553463],
    [36.3794662, 36.1559043],
    [36.3781016, 36.1559882],
    [36.3767235, 36.1572585],
    [36.3760522, 36.1576274],
    [36.3743185, 36.1571463],
    [36.3734949, 36.1569213],
    [36.3723538, 36.1572452],
    [36.3706837, 36.1588166],
    [36.3699069, 36.1591078],
    [36.3681981, 36.1591289],
    [36.3674555, 36.159689],
    [36.3674799, 36.160388],
    [36.3679661, 36.1610883],
    [36.368086, 36.1623243],
    [36.368059, 36.1629353],
    [36.3690139, 36.1645431],
    [36.3690608, 36.1651529],
    [36.3690203, 36.1658944],
    [36.3683764, 36.1663552],
    [36.3666239, 36.1662256],
    [36.3657176, 36.1664036],
    [36.3654448, 36.1670259],
    [36.365904, 36.1680078],
    [36.3665661, 36.1682274],
    [36.3675374, 36.1679451],
    [36.3684557, 36.16799],
    [36.369286, 36.1684591],
    [36.3698673, 36.1695009],
    [36.369888, 36.1711823],
    [36.3701692, 36.1714113],
    [36.3711148, 36.171471],
    [36.3720417, 36.1708599],
    [36.372548, 36.1697833],
    [36.3729165, 36.1696616],
    [36.3735066, 36.1700297],
    [36.3741046, 36.1705643],
    [36.3743062, 36.172216],
    [36.3741163, 36.1727614],
    [36.3734656, 36.1728859],
    [36.3729457, 36.1732795],
    [36.3719039, 36.1748328],
    [36.3700491, 36.1766218],
    [36.3677821, 36.1778788],
    [36.3676375, 36.1789801],
    [36.3678161, 36.1795572],
    [36.3683113, 36.1798519],
    [36.3695634, 36.1801652],
    [36.3701302, 36.1801056],
    [36.3714733, 36.1795376],
    [36.3724039, 36.1783116],
    [36.3732452, 36.1786696],
    [36.3739532, 36.18019],
    [36.3744204, 36.1807811],
    [36.3753907, 36.1811779],
    [36.3768395, 36.1821392],
    [36.3773836, 36.1839272],
    [36.377942, 36.1840604],
    [36.3790101, 36.1828587],
    [36.3798849, 36.1824848],
    [36.3801524, 36.1824585],
    [36.3815703, 36.1835131],
    [36.3817939, 36.184359],
    [36.380657, 36.1867916],
    [36.3806696, 36.187501],
    [36.3811518, 36.1882294],
    [36.3826036, 36.1885508],
    [36.3844942, 36.1891231],
    [36.3858077, 36.1892582],
    [36.3868934, 36.189034],
    [36.3873982, 36.1885877],
    [36.3873625, 36.1880777],
    [36.3867036, 36.1871777],
    [36.3857731, 36.1867617],
    [36.3844104, 36.1867193],
    [36.383147, 36.1863355],
    [36.3822688, 36.1855467],
    [36.3827337, 36.1847729],
    [36.3837336, 36.1843307],
    [36.3857716, 36.1838667],
    [36.386364, 36.1833233],
    [36.3871271, 36.1833588],
    [36.3879303, 36.1838284],
    [36.3887417, 36.1848496],
    [36.3882517, 36.1863354],
    [36.3886394, 36.1870723],
    [36.3892715, 36.1875988],
    [36.3903769, 36.1881523],
    [36.3927497, 36.1882275],
    [36.3948277, 36.1877933],
    [36.3956105, 36.1878379],
    [36.3965311, 36.1881781],
    [36.3971405, 36.1890364],
    [36.3971189, 36.1902671],
    [36.3968223, 36.1912585],
    [36.3967224, 36.1918714],
    [36.3970891, 36.1923308],
    [36.39746, 36.1924915],
    [36.3980361, 36.1923314],
    [36.3984616, 36.1919333],
    [36.3985289, 36.1913972],
    [36.3981704, 36.1908866],
    [36.3982355, 36.1903936],
    [36.3989834, 36.1903526],
    [36.4000726, 36.1911421],
    [36.4004251, 36.1925725],
    [36.4001765, 36.1937091],
    [36.3989799, 36.1950411],
    [36.3959686, 36.1960132],
    [36.3954172, 36.196394],
    [36.395098, 36.1971442],
    [36.395319, 36.1980853],
    [36.3957202, 36.1987113],
    [36.3963418, 36.19918],
    [36.3960881, 36.1997195],
    [36.3953717, 36.1998278],
    [36.3938528, 36.1994533],
    [36.3933453, 36.1983269],
    [36.3935524, 36.1968232],
    [36.3949852, 36.1954741],
    [36.3951473, 36.1952818],
    [36.394824, 36.1950056],
    [36.3943577, 36.1948758],
    [36.3935812, 36.1950393],
    [36.3909017, 36.196527],
    [36.3906059, 36.1971443],
    [36.3907099, 36.1980447],
    [36.3912399, 36.1988812],
    [36.3922244, 36.1994716],
    [36.3923655, 36.2000284],
    [36.3920935, 36.2008198],
    [36.3913789, 36.2018089],
    [36.3912519, 36.2024774],
    [36.3917291, 36.2036462],
    [36.3923495, 36.2042475],
    [36.3929415, 36.2045119],
    [36.3934273, 36.2043974],
    [36.3942936, 36.203972],
    [36.3950756, 36.2027721],
    [36.3961348, 36.2016919],
    [36.3966107, 36.2016117],
    [36.3969426, 36.2018832],
    [36.3969086, 36.2024849],
    [36.3966141, 36.2034928],
    [36.3949491, 36.2059998],
    [36.3942819, 36.2076983],
    [36.3936531, 36.2091324],
    [36.3925038, 36.2103908],
    [36.3913472, 36.2107019],
    [36.3906568, 36.2104047],
    [36.3905335, 36.2088118],
    [36.3900225, 36.207448],
    [36.3888805, 36.2066495],
    [36.3878994, 36.2069039],
    [36.3881224, 36.2075638],
    [36.3876821, 36.2089526],
    [36.3882523, 36.2099953],
    [36.3895196, 36.2102829],
    [36.3907203, 36.2112851],
    [36.3912983, 36.2126841],
    [36.3910088, 36.2140857],
    [36.3898128, 36.2153835],
    [36.3885216, 36.2158538],
    [36.3870226, 36.2158203],
    [36.3850873, 36.2147904],
    [36.3841265, 36.2133048],
    [36.3835643, 36.2131063],
    [36.3827418, 36.2135286],
    [36.3818763, 36.2145442],
    [36.3812916, 36.2158968],
    [36.3818245, 36.2167569],
    [36.3830775, 36.2174834],
    [36.3853358, 36.2180268],
    [36.388647, 36.2178314],
    [36.3892289, 36.2181203],
    [36.3895183, 36.2184764],
    [36.389535, 36.2188963],
    [36.3893474, 36.2192505],
    [36.3949725, 36.2253354],
    [36.3990786, 36.2235429],
    [36.4037186, 36.22133],
    [36.4106785, 36.2173682],
    [36.4160323, 36.2144057],
    [36.4188232, 36.2130512],
    [36.4283054, 36.2078021],
    [36.4298917, 36.2072841],
    [36.4314807, 36.2070358],
    [36.4401382, 36.2065183],
    [36.4481263, 36.2070398],
    [36.4570158, 36.2078262],
    [36.4557931, 36.2045548],
    [36.4559245, 36.2027436],
    [36.4607542, 36.1990939],
    [36.4626459, 36.1988084],
    [36.4655416, 36.1982287],
    [36.4651011, 36.1969439],
    [36.4681844, 36.1975679],
    [36.4698729, 36.1988894],
    [36.4700698, 36.199665],
    [36.4698474, 36.2009505],
    [36.473411, 36.2014664],
    [36.4736052, 36.2036161],
    [36.4718901, 36.2078741],
    [36.4737039, 36.2102573],
    [36.4759807, 36.2123026],
    [36.4764777, 36.2142511],
    [36.482278, 36.2165956],
    [36.4849596, 36.2176801],
    [36.4862825, 36.2182579],
    [36.4865118, 36.2184304],
    [36.4865537, 36.2186329],
    [36.4864931, 36.2189874],
    [36.4863981, 36.219228],
    [36.4861784, 36.2193929],
    [36.485488, 36.2197056],
    [36.4851176, 36.2199914],
    [36.4849803, 36.2203132],
    [36.4847196, 36.2215063],
    [36.4844304, 36.2233172],
    [36.4844447, 36.22347],
    [36.4845388, 36.2236031],
    [36.484711, 36.223753],
    [36.4863726, 36.2249983],
    [36.4864295, 36.225196],
    [36.4859255, 36.2268694],
    [36.4832044, 36.2327871],
    [36.4834186, 36.2329655],
    [36.4844894, 36.2326443],
    [36.4882839, 36.2310551],
    [36.4889453, 36.2308357],
    [36.4900305, 36.2310044],
    [36.4912193, 36.2308504],
    [36.4913502, 36.2304441],
    [36.492651, 36.2266148],
    [36.4996516, 36.2234887],
    [36.5033686, 36.2261449],
    [36.5010707, 36.2283595],
    [36.5009818, 36.2285906],
    [36.5012663, 36.2293908],
    [36.5012308, 36.2296931],
    [36.5004484, 36.2302443],
    [36.5002528, 36.2306711],
    [36.5015153, 36.231578],
    [36.5014264, 36.2356144],
    [36.5015153, 36.23597],
    [36.5016398, 36.2362368],
    [36.5027073, 36.2376598],
    [36.5029586, 36.2410534],
    [36.5033229, 36.2412957],
    [36.5037803, 36.2414863],
    [36.5060079, 36.2411109],
    [36.507852, 36.2403564],
    [36.5116423, 36.2424098],
    [36.5192724, 36.2430273],
    [36.5200588, 36.2428884],
    [36.5238958, 36.2428896],
    [36.5260196, 36.2420024],
    [36.5270401, 36.2422201],
    [36.5285182, 36.2425177],
    [36.5323305, 36.2436502],
    [36.5343263, 36.24432],
    [36.5431524, 36.2331089],
    [36.5478058, 36.2284385],
    [36.5513316, 36.2252664],
    [36.5537624, 36.2240635],
    [36.5588806, 36.2278355],
    [36.5617597, 36.230511],
    [36.5600806, 36.2342257],
    [36.5607132, 36.2350053],
    [36.5629047, 36.2367141],
    [36.5717044, 36.2339054],
    [36.5760453, 36.2317892],
    [36.5769422, 36.2311401],
    [36.577734, 36.2308368],
    [36.5804936, 36.2278253],
    [36.5818977, 36.2267233],
    [36.5826273, 36.2262386],
    [36.5834427, 36.226204],
    [36.5849584, 36.2256999],
    [36.5859961, 36.2259443],
    [36.587521, 36.2261349],
    [36.5885977, 36.2265849],
    [36.5902662, 36.226879],
    [36.590867, 36.2266194],
    [36.5959519, 36.2250527],
    [36.5986776, 36.2232266],
    [36.6024542, 36.2218937],
    [36.602396, 36.22171],
    [36.6015061, 36.2207778],
    [36.6065311, 36.2191586],
    [36.6105437, 36.2177737],
    [36.6145134, 36.2161118],
    [36.6164875, 36.215627],
    [36.6188264, 36.2155405],
    [36.6246873, 36.2143254],
    [36.6251349, 36.2144152],
    [36.6253066, 36.2162676],
    [36.6255855, 36.217012],
    [36.6265726, 36.2178256],
    [36.6284823, 36.2185873],
    [36.6285467, 36.2192452],
    [36.6275167, 36.2209416],
    [36.6286754, 36.2237806],
    [36.6284465, 36.22402],
    [36.6272378, 36.2238671],
    [36.6264533, 36.2241147],
    [36.6259289, 36.2249404],
    [36.6259912, 36.2257993],
    [36.6260077, 36.2266829],
    [36.6268054, 36.228723],
    [36.627525, 36.2311968],
    [36.6278815, 36.2312582],
    [36.6303462, 36.2312845],
    [36.6314758, 36.2310457],
    [36.6322657, 36.2307444],
    [36.6329018, 36.2305217],
    [36.6346044, 36.2298593],
    [36.6366164, 36.2293159],
    [36.6379904, 36.2290837],
    [36.639353, 36.2289386],
    [36.6402278, 36.2294744],
    [36.6408849, 36.2296312],
    [36.6419792, 36.2296485],
    [36.6421804, 36.2298286],
    [36.6420436, 36.2301159],
    [36.6404557, 36.2310678],
    [36.6400909, 36.2315352],
    [36.640048, 36.2321583],
    [36.6402481, 36.2324794],
    [36.6412976, 36.2332139],
    [36.6444387, 36.2334477],
    [36.6459918, 36.2335256],
    [36.6470151, 36.233223],
    [36.6493656, 36.2319515],
    [36.6532047, 36.2308574],
    [36.6542208, 36.2303322],
    [36.6548645, 36.2297178],
    [36.656075, 36.2289332],
    [36.6564417, 36.2285494],
    [36.6577506, 36.2278051],
    [36.6584587, 36.2276407],
    [36.6594964, 36.2275648],
    [36.6602933, 36.227407],
    [36.6610658, 36.22713],
    [36.6621816, 36.2270089],
    [36.6641021, 36.2274762],
    [36.6654348, 36.2280979],
    [36.6670203, 36.2287138],
    [36.6671237, 36.2291299],
    [36.6670418, 36.2296399],
    [36.666441, 36.2306438],
    [36.6657805, 36.2313752],
    [36.6656273, 36.2325908],
    [36.6655503, 36.2336008],
    [36.6655826, 36.2340881],
    [36.6658723, 36.2345728],
    [36.66628, 36.2348324],
    [36.6668379, 36.2348756],
    [36.6678989, 36.2347968],
    [36.670475, 36.2349189],
    [36.6716659, 36.2354641],
    [36.6726744, 36.2355939],
    [36.6731804, 36.2355301],
    [36.6735649, 36.2350574],
    [36.674037, 36.2337333],
    [36.6746496, 36.2329584],
    [36.6751782, 36.2326649],
    [36.6755717, 36.2323836],
    [36.6762193, 36.2317823],
    [36.6765438, 36.2316354],
    [36.6767507, 36.2316245],
    [36.6769338, 36.2316568],
    [36.6771116, 36.2317212],
    [36.6772895, 36.231831],
    [36.6778561, 36.2323438],
    [36.6820645, 36.2322976],
    [36.6826378, 36.2329324],
    [36.6809577, 36.2339684],
    [36.6822739, 36.2373814],
    [36.682899, 36.2378698],
    [36.6838431, 36.2382246],
    [36.6848436, 36.2383759],
    [36.6855919, 36.2385102],
    [36.6867506, 36.2386919],
    [36.6890715, 36.2383711],
    [36.6899264, 36.2386313],
    [36.6919756, 36.2389169],
    [36.6931772, 36.2391332],
    [36.695044, 36.239263],
    [36.695162, 36.2394361],
    [36.695205, 36.2396784],
    [36.6949689, 36.239938],
    [36.6940355, 36.2405091],
    [36.6940462, 36.2405783],
    [36.6947114, 36.2407428],
    [36.6957307, 36.2412966],
    [36.6990137, 36.2424734],
    [36.7009663, 36.2427849],
    [36.7019534, 36.2427676],
    [36.7025435, 36.2428368],
    [36.7041421, 36.2432695],
    [36.7047751, 36.2445414],
    [36.7048073, 36.2459778],
    [36.7046034, 36.2470248],
    [36.7045694, 36.2478672],
    [36.7038953, 36.2487552],
    [36.7018247, 36.2496983],
    [36.6991854, 36.2504511],
    [36.6982841, 36.2509183],
    [36.6980266, 36.2511259],
    [36.6981661, 36.2513942],
    [36.698724, 36.2514807],
    [36.6998827, 36.2513422],
    [36.7003226, 36.2514028],
    [36.7005372, 36.2515759],
    [36.701374, 36.252294],
    [36.7012989, 36.2524064],
    [36.7007947, 36.2525103],
    [36.7002261, 36.2524843],
    [36.6990781, 36.2526141],
    [36.6985309, 36.2527958],
    [36.6980374, 36.2532889],
    [36.6976999, 36.2544961],
    [36.6972112, 36.257113],
    [36.6907077, 36.2616237],
    [36.689744, 36.2621306],
    [36.6891432, 36.2626497],
    [36.6885407, 36.2636123],
    [36.6882158, 36.2652058],
    [36.686933, 36.266629],
    [36.6846357, 36.2680685],
    [36.6809249, 36.2712654],
    [36.6806631, 36.2718526],
    [36.6812038, 36.2743966],
    [36.6809892, 36.2755037],
    [36.6803688, 36.2763213],
    [36.6796374, 36.2768357],
    [36.6784787, 36.2771644],
    [36.6790151, 36.2778909],
    [36.680367, 36.278012],
    [36.6812467, 36.2785656],
    [36.682266, 36.2813418],
    [36.6828122, 36.2823458],
    [36.6835481, 36.2832056],
    [36.6839712, 36.284089],
    [36.6843259, 36.2842824],
    [36.6850846, 36.2843849],
    [36.6877392, 36.2870855],
    [36.6877776, 36.2880818],
    [36.6880381, 36.2884854],
    [36.6891432, 36.2895058],
    [36.689862, 36.2899555],
    [36.6909885, 36.2902755],
    [36.6919728, 36.2903274],
    [36.6924893, 36.2903088],
    [36.6925697, 36.290593],
    [36.692154, 36.2927281],
    [36.6890009, 36.292774],
    [36.6876692, 36.2925807],
    [36.6870481, 36.2925566],
    [36.686061, 36.2924445],
    [36.6843752, 36.2924623],
    [36.6836523, 36.292653],
    [36.681261, 36.2937273],
    [36.6798621, 36.2938907],
    [36.6752446, 36.2949586],
    [36.6737451, 36.2953411],
    [36.6716583, 36.2961509],
    [36.6697434, 36.2966534],
    [36.6687119, 36.2966318],
    [36.6631476, 36.2964635],
    [36.6623675, 36.2962899],
    [36.65726, 36.2964006],
    [36.6543703, 36.296999],
    [36.653738, 36.2979716],
    [36.6525656, 36.3063811],
    [36.6530539, 36.3105095],
    [36.6524769, 36.3116173],
    [36.6522424, 36.3127036],
    [36.6521405, 36.3138913],
    [36.6532238, 36.3153093],
    [36.6558157, 36.3188997],
    [36.656524, 36.3197312],
    [36.6586358, 36.3217996],
    [36.6608019, 36.3236671],
    [36.661853, 36.3250967],
    [36.6622031, 36.3249798],
    [36.6625571, 36.3249669],
    [36.6627663, 36.325157],
    [36.6640806, 36.3256886],
    [36.6644717, 36.3259305],
    [36.6645688, 36.3266005],
    [36.664381, 36.3281002],
    [36.6645688, 36.3289646],
    [36.6641269, 36.3295993],
    [36.6644668, 36.3298548],
    [36.6622052, 36.3327862],
    [36.6596309, 36.334038],
    [36.6575092, 36.3388174],
    [36.657394, 36.3388841],
    [36.6523196, 36.3389039],
    [36.6518032, 36.3388045],
    [36.6417024, 36.3357766],
    [36.6385138, 36.33982],
    [36.637398, 36.3418163],
    [36.6366819, 36.3421597],
    [36.6364076, 36.3422149],
    [36.6359317, 36.3422863],
    [36.6354796, 36.3424053],
    [36.6351227, 36.3425956],
    [36.6349085, 36.342786],
    [36.6347658, 36.3430239],
    [36.6344326, 36.3432143],
    [36.6337188, 36.343476],
    [36.6330288, 36.3437854],
    [36.6326481, 36.3441661],
    [36.6323149, 36.3442851],
    [36.6313631, 36.3447134],
    [36.6306017, 36.3448323],
    [36.6297451, 36.3448085],
    [36.6290551, 36.3446658],
    [36.6284126, 36.3444516],
    [36.6278891, 36.3442137],
    [36.6276274, 36.3439995],
    [36.6271515, 36.3439281],
    [36.626747, 36.3438092],
    [36.6264139, 36.343595],
    [36.6262235, 36.3433333],
    [36.6261283, 36.3430477],
    [36.6262235, 36.342667],
    [36.6263187, 36.3421911],
    [36.6264853, 36.3418342],
    [36.6266756, 36.3416439],
    [36.6269136, 36.3413821],
    [36.6270801, 36.34093],
    [36.6271991, 36.3402162],
    [36.6274846, 36.3391454],
    [36.6275798, 36.3385506],
    [36.6275084, 36.3374084],
    [36.6273894, 36.3370515],
    [36.6269849, 36.3368373],
    [36.6263206, 36.336576],
    [36.6257164, 36.3367341],
    [36.6247958, 36.3380033],
    [36.6243199, 36.338265],
    [36.6240344, 36.3382888],
    [36.6236299, 36.3377891],
    [36.6234157, 36.3371943],
    [36.6226067, 36.3364804],
    [36.6215598, 36.3360283],
    [36.6207983, 36.3356238],
    [36.619799, 36.3351955],
    [36.6188948, 36.3348148],
    [36.6182761, 36.3347434],
    [36.6176337, 36.3348148],
    [36.616777, 36.3351717],
    [36.6156825, 36.3357666],
    [36.6150638, 36.3363615],
    [36.6143024, 36.3368373],
    [36.6139455, 36.3373608],
    [36.6137551, 36.3376702],
    [36.6135172, 36.3377653],
    [36.613303, 36.3376226],
    [36.6129699, 36.3371229],
    [36.612494, 36.3368373],
    [36.6119229, 36.3366232],
    [36.6110425, 36.3363377],
    [36.6103287, 36.3360759],
    [36.6096863, 36.3358856],
    [36.6092342, 36.3357428],
    [36.6090438, 36.3356238],
    [36.6089486, 36.3353383],
    [36.6091152, 36.3347672],
    [36.6093055, 36.3341723],
    [36.6094245, 36.3337203],
    [36.6095435, 36.3332682],
    [36.6095435, 36.3329112],
    [36.6092667, 36.3324272],
    [36.6085814, 36.3320845],
    [36.6079492, 36.3321498],
    [36.607581, 36.3323611],
    [36.6071811, 36.3326824],
    [36.6068163, 36.3330395],
    [36.6059443, 36.3331645],
    [36.6054306, 36.3328641],
    [36.6052605, 36.3320308],
    [36.6051177, 36.3318167],
    [36.6061059, 36.3308469],
    [36.6060316, 36.3304067],
    [36.6056025, 36.3299784],
    [36.6053318, 36.3296514],
    [36.605546, 36.3292231],
    [36.6059851, 36.3287596],
    [36.6062836, 36.3279619],
    [36.6052128, 36.3273195],
    [36.6029285, 36.3319357],
    [36.6016963, 36.3378064],
    [36.6012153, 36.3400972],
    [36.6000969, 36.3426194],
    [36.5991927, 36.3495675],
    [36.6006918, 36.3496864],
    [36.6027381, 36.349734],
    [36.6046324, 36.3500273],
    [36.6069717, 36.3507372],
    [36.6118439, 36.3525441],
    [36.6129087, 36.3531572],
    [36.6185853, 36.3574197],
    [36.6186567, 36.3574911],
    [36.6186199, 36.3576583],
    [36.6175067, 36.3581907],
    [36.6149415, 36.3596911],
    [36.6116826, 36.3619013],
    [36.6079558, 36.3636921],
    [36.6059668, 36.3651848],
    [36.6053057, 36.3658018],
    [36.604951, 36.3660333],
    [36.6043214, 36.3663389],
    [36.6034973, 36.36677],
    [36.6033711, 36.3668538],
    [36.6032854, 36.3669375],
    [36.6032048, 36.3670646],
    [36.6030992, 36.3671848],
    [36.6030115, 36.3672576],
    [36.6028244, 36.3673382],
    [36.5994698, 36.3685966],
    [36.597921, 36.370952],
    [36.5973843, 36.3714823],
    [36.5970512, 36.3719582],
    [36.5968371, 36.3724817],
    [36.5967657, 36.3728862],
    [36.6023098, 36.3731003],
    [36.6032378, 36.3749563],
    [36.6053079, 36.376503],
    [36.6059504, 36.3777879],
    [36.6061646, 36.3794773],
    [36.6054507, 36.3808574],
    [36.6046477, 36.382254],
    [36.6048422, 36.3846813],
    [36.6050462, 36.3861636],
    [36.6049986, 36.3874247],
    [36.6048321, 36.3881147],
    [36.6048083, 36.3888286],
    [36.6059504, 36.392255],
    [36.6011439, 36.3915887],
    [36.5980744, 36.3922074],
    [36.5961708, 36.3928261],
    [36.5862723, 36.3916601],
    [36.5805616, 36.4071266],
    [36.5799905, 36.4069601],
    [36.5755171, 36.416121],
    [36.5715196, 36.4194998],
    [36.5663324, 36.4189763],
    [36.563715, 36.4268761],
    [36.565452, 36.4323489],
    [36.5661896, 36.4353946],
    [36.5661896, 36.4373696],
    [36.5650475, 36.4392256],
    [36.5641195, 36.4397014],
    [36.5623111, 36.4390352],
    [36.5603123, 36.438821],
    [36.5556486, 36.4427709],
    [36.5551965, 36.4449838],
    [36.5550685, 36.4474565],
    [36.5574095, 36.4494809],
    [36.5572428, 36.4508135],
    [36.5558865, 36.4520033],
    [36.5592178, 36.4534071],
    [36.5580756, 36.4539544],
    [36.5578122, 36.4540984],
    [36.5575209, 36.4544531],
    [36.5575516, 36.4547756],
    [36.5576994, 36.4550485],
    [36.5579421, 36.4553573],
    [36.5586519, 36.4561258],
    [36.5591626, 36.4568277],
    [36.5593053, 36.4572699],
    [36.5593249, 36.4573754],
    [36.5593314, 36.4575312],
    [36.5592357, 36.457706],
    [36.5591394, 36.4578465],
    [36.5589383, 36.4581256],
    [36.5585937, 36.4583807],
    [36.5583872, 36.4585777],
    [36.5583058, 36.4586776],
    [36.558286, 36.4588012],
    [36.5583267, 36.4589357],
    [36.5584264, 36.4590573],
    [36.5601616, 36.4605279],
    [36.5607571, 36.4615991],
    [36.5616708, 36.4632163],
    [36.5619799, 36.4636737],
    [36.5619724, 36.4637385],
    [36.561902, 36.4637787],
    [36.5616987, 36.4638456],
    [36.555316, 36.465936],
    [36.5548816, 36.4661853],
    [36.5547329, 36.466311],
    [36.553307, 36.4674794],
    [36.5532201, 36.4675908],
    [36.55319, 36.4677055],
    [36.5531987, 36.4678382],
    [36.5532601, 36.4679611],
    [36.5537393, 36.4691041],
    [36.5537798, 36.4692149],
    [36.5540633, 36.4704185],
    [36.5547294, 36.4720955],
    [36.5553045, 36.4735015],
    [36.5554827, 36.4738463],
    [36.5539811, 36.4763466],
    [36.5527684, 36.4776519],
    [36.5523897, 36.4780562],
    [36.5515839, 36.478907],
    [36.5507269, 36.4798749],
    [36.5503509, 36.4800016],
    [36.547907, 36.4807831],
    [36.5477914, 36.4808025],
    [36.5473007, 36.4807735],
    [36.5467192, 36.4807654],
    [36.5454569, 36.480769],
    [36.5453668, 36.4807769],
    [36.5452767, 36.4808134],
    [36.5452008, 36.480874],
    [36.5451602, 36.4809461],
    [36.5437143, 36.484175],
    [36.5436812, 36.4842789],
    [36.5437317, 36.4844991],
    [36.5438788, 36.4847797],
    [36.5440219, 36.4849101],
    [36.5442919, 36.485076],
    [36.5448856, 36.4854036],
    [36.5459507, 36.4865069],
    [36.5461784, 36.487111],
    [36.54628, 36.4872991],
    [36.5464062, 36.4874079],
    [36.5470836, 36.4877987],
    [36.5488415, 36.4888726],
    [36.5491403, 36.4890001],
    [36.5492197, 36.4891278],
    [36.5488928, 36.4915982],
    [36.548449, 36.4928395],
    [36.5478769, 36.4941681],
    [36.5477421, 36.4947468],
    [36.5477418, 36.4949331],
    [36.5478403, 36.495647],
    [36.5481646, 36.4973707],
    [36.5481603, 36.4974211],
    [36.5481046, 36.4974565],
    [36.5480399, 36.4974523],
    [36.5479094, 36.4974223],
    [36.5460675, 36.4969991],
    [36.5459658, 36.4969751],
    [36.5458636, 36.4970234],
    [36.5457987, 36.497124],
    [36.5455103, 36.4995393],
    [36.5455294, 36.4996869],
    [36.5455905, 36.499767],
    [36.5457313, 36.4998034],
    [36.5466266, 36.4998001],
    [36.5466994, 36.5006884],
    [36.5445064, 36.5015863],
    [36.544182, 36.5017737],
    [36.5441674, 36.5018126],
    [36.544193, 36.5018539],
    [36.5494564, 36.5071682],
    [36.5502781, 36.507999],
    [36.5506327, 36.5083325],
    [36.5510142, 36.5086487],
    [36.5513221, 36.5088941],
    [36.5515552, 36.509064],
    [36.554492, 36.5110213],
    [36.5569927, 36.5126745],
    [36.5579566, 36.5132969],
    [36.5610699, 36.5158991],
    [36.5621918, 36.516843],
    [36.5631597, 36.5176183],
    [36.564348, 36.5187917],
    [36.5655023, 36.5199744],
    [36.5668994, 36.5210245],
    [36.567404, 36.521622],
    [36.5678904, 36.5221394],
    [36.5679729, 36.5222506],
    [36.5680271, 36.5223683],
    [36.5684639, 36.5238691],
    [36.5686009, 36.5241889],
    [36.5696762, 36.5259265],
    [36.5697969, 36.5260733],
    [36.5699457, 36.5261713],
    [36.5710956, 36.5267702],
    [36.5709289, 36.5275585],
    [36.5723692, 36.528412],
    [36.5730093, 36.5313638],
    [36.5774844, 36.5524555],
    [36.5799971, 36.5554822],
    [36.580511, 36.5587944],
    [36.5812534, 36.560736],
    [36.5826811, 36.5683884],
    [36.5867245, 36.5759732],
    [36.5873336, 36.5767548],
    [36.5880094, 36.5804704],
    [36.5886042, 36.5828974],
    [36.5914358, 36.5855386],
    [36.5874145, 36.5930101],
    [36.5863675, 36.5963414],
    [36.5851778, 36.5990539],
    [36.5803237, 36.603908],
    [36.5779205, 36.6079293],
    [36.5767069, 36.6165906],
    [36.5753268, 36.6202311],
    [36.5759455, 36.6223489],
    [36.5775064, 36.6246058],
    [36.5761739, 36.6316252],
    [36.5767306, 36.636578],
    [36.5756361, 36.6416463],
    [36.5753982, 36.6442637],
    [36.5741133, 36.6508548],
    [36.5741133, 36.6547333],
    [36.5737785, 36.6573984],
    [36.5736284, 36.6591334],
    [36.5741529, 36.6595524],
    [36.5744702, 36.6598492],
    [36.5749461, 36.6599681],
    [36.5754934, 36.6599444],
    [36.5759217, 36.6598254],
    [36.5764452, 36.6595398],
    [36.5771828, 36.6590639],
    [36.5774684, 36.6588736],
    [36.5778015, 36.6588022],
    [36.5782774, 36.6588974],
    [36.5786105, 36.6590877],
    [36.579015, 36.6591829],
    [36.5793481, 36.6591115],
    [36.5797288, 36.6591353],
    [36.5801333, 36.6593257],
    [36.5807282, 36.6597064],
    [36.5809186, 36.6598492],
    [36.5818704, 36.6602061],
    [36.5825128, 36.6606582],
    [36.5830363, 36.6611341],
    [36.5836074, 36.6617765],
    [36.5844164, 36.6622524],
    [36.585035, 36.6624428],
    [36.5858203, 36.6624904],
    [36.5865103, 36.6623238],
    [36.587129, 36.6619669],
    [36.5877, 36.6618241],
    [36.587938, 36.6618003],
    [36.5881045, 36.6619669],
    [36.5881997, 36.6623238],
    [36.5881045, 36.6626093],
    [36.5880808, 36.6631566],
    [36.5881759, 36.6635611],
    [36.5881283, 36.6637515],
    [36.5880332, 36.6641084],
    [36.5880094, 36.6644653],
    [36.588057, 36.6649888],
    [36.5882949, 36.6655123],
    [36.5885566, 36.6658454],
    [36.5887232, 36.6661547],
    [36.5888898, 36.6666306],
    [36.5890087, 36.6673683],
    [36.5889849, 36.6679393],
    [36.5890801, 36.668558],
    [36.5892467, 36.6689387],
    [36.5894846, 36.6690339],
    [36.5903174, 36.6690577],
    [36.5909837, 36.6691291],
    [36.5916975, 36.6694384],
    [36.5923638, 36.6698667],
    [36.5927683, 36.670295],
    [36.5931728, 36.6707471],
    [36.5935297, 36.6710564],
    [36.5938866, 36.6711516],
    [36.5944577, 36.6710326],
    [36.5950764, 36.6708661],
    [36.5955761, 36.6708185],
    [36.5961947, 36.6710326],
    [36.5968372, 36.6713896],
    [36.5972655, 36.6715799],
    [36.5977176, 36.6720558],
    [36.5981459, 36.6722938],
    [36.5987883, 36.6724603],
    [36.5992166, 36.6724841],
    [36.5993832, 36.6724127],
    [36.600097, 36.6726507],
    [36.600906, 36.6729838],
    [36.6011678, 36.6731742],
    [36.6013343, 36.6736976],
    [36.6027382, 36.6768147],
    [36.6030951, 36.6770765],
    [36.6038566, 36.6771954],
    [36.6045942, 36.6772668],
    [36.6050225, 36.6774334],
    [36.6057126, 36.6775048],
    [36.606355, 36.6772192],
    [36.6067595, 36.6770051],
    [36.6077589, 36.6769099],
    [36.6081158, 36.6770051],
    [36.60833, 36.6775524],
    [36.6085203, 36.6779331],
    [36.6087107, 36.6782424],
    [36.6089248, 36.6785755],
    [36.608901, 36.679218],
    [36.6087345, 36.6797891],
    [36.6086393, 36.6801698],
    [36.6086869, 36.6805743],
    [36.6087821, 36.681074],
    [36.608901, 36.6814309],
    [36.6090438, 36.6815974],
    [36.6093769, 36.6815974],
    [36.6098528, 36.6819306],
    [36.6103049, 36.682454],
    [36.610757, 36.6827396],
    [36.6112567, 36.6830489],
    [36.6115898, 36.6834534],
    [36.6118278, 36.6839293],
    [36.6120181, 36.6842862],
    [36.6122799, 36.684548],
    [36.6123513, 36.6847383],
    [36.6122799, 36.6849525],
    [36.6121609, 36.6851428],
    [36.6118754, 36.6854998],
    [36.6117088, 36.6858329],
    [36.6117564, 36.686166],
    [36.6120181, 36.6866419],
    [36.612494, 36.6870702],
    [36.6129699, 36.6875461],
    [36.6132079, 36.6878554],
    [36.6132079, 36.6885455],
    [36.6131603, 36.6887358],
    [36.6131365, 36.6891165],
    [36.6130175, 36.6893783],
    [36.6127796, 36.6895686],
    [36.6125416, 36.6896876],
    [36.612494, 36.6899256],
    [36.6125178, 36.6901873],
    [36.6128271, 36.6907346],
    [36.6131841, 36.6912343],
    [36.6136124, 36.6918053],
    [36.6139931, 36.692305],
    [36.6142786, 36.6926857],
    [36.6147783, 36.6928999],
    [36.6154683, 36.693233],
    [36.6159442, 36.6932806],
    [36.6169198, 36.693471],
    [36.6174433, 36.6935185],
    [36.6176812, 36.6935661],
    [36.6179668, 36.6937089],
    [36.6185379, 36.6938279],
    [36.6190613, 36.6939706],
    [36.6193945, 36.694042],
    [36.6195372, 36.6942562],
    [36.619442, 36.6945655],
    [36.6193469, 36.6950414],
    [36.6194658, 36.6954697],
    [36.6197752, 36.6959694],
    [36.6202035, 36.6964215],
    [36.6206318, 36.6967784],
    [36.6211315, 36.6970877],
    [36.6213694, 36.6974209],
    [36.6215598, 36.6978016],
    [36.6217263, 36.6983013],
    [36.6219405, 36.698563],
    [36.622345, 36.6988961],
    [36.6227971, 36.6993958],
    [36.6230112, 36.6998955],
    [36.6232016, 36.700419],
    [36.6234871, 36.7008235],
    [36.6240106, 36.7012994],
    [36.6243913, 36.7016325],
    [36.6249624, 36.7019894],
    [36.6253907, 36.702275],
    [36.6256048, 36.7026557],
    [36.6257, 36.7030364],
    [36.6257, 36.7034171],
    [36.6258666, 36.7036313],
    [36.6262949, 36.703774],
    [36.6267232, 36.7038454],
    [36.6271039, 36.7041547],
    [36.6273181, 36.7043927],
    [36.6276036, 36.7046306],
    [36.6279843, 36.7047258],
    [36.6282698, 36.7048924],
    [36.6287457, 36.7053921],
    [36.6290551, 36.7059869],
    [36.6294596, 36.7067721],
    [36.6295548, 36.7071053],
    [36.6295548, 36.707605],
    [36.6297451, 36.7080095],
    [36.6302686, 36.7087471],
    [36.6305541, 36.7094133],
    [36.6308635, 36.7102937],
    [36.6310538, 36.7111741],
    [36.6312918, 36.7117928],
    [36.6313869, 36.7122925],
    [36.6314821, 36.7127208],
    [36.6314821, 36.7130539],
    [36.6313631, 36.7133632],
    [36.6308873, 36.7141009],
    [36.6306493, 36.714553],
    [36.6304114, 36.7154572],
    [36.6303162, 36.7158617],
    [36.6303876, 36.7163614],
    [36.6305779, 36.71698],
    [36.6305779, 36.7176463],
    [36.6305303, 36.7180746],
    [36.6303638, 36.7183125],
    [36.6302686, 36.7187408],
    [36.6304352, 36.7193833],
    [36.6304827, 36.7197878],
    [36.6303876, 36.7199782],
    [36.6300782, 36.7201923],
    [36.6296975, 36.7202399],
    [36.6293406, 36.7201923],
    [36.6289837, 36.7199782],
    [36.6286981, 36.7197164],
    [36.6284364, 36.7195498],
    [36.6281509, 36.7195974],
    [36.627794, 36.719764],
    [36.6273657, 36.7200971],
    [36.6270087, 36.7202875],
    [36.6265328, 36.7203589],
    [36.6260807, 36.7203351],
    [36.6257, 36.7203827],
    [36.6254145, 36.7205254],
    [36.6250814, 36.7213582],
    [36.6246531, 36.7222148],
    [36.6242724, 36.7229049],
    [36.6236299, 36.7235711],
    [36.6229161, 36.7240708],
    [36.6223926, 36.7246657],
    [36.6220119, 36.7253557],
    [36.6214884, 36.7263075],
    [36.6211791, 36.7268072],
    [36.6206556, 36.7270927],
    [36.6202035, 36.7273783],
    [36.6194658, 36.7278066],
    [36.6190137, 36.7280445],
    [36.6187044, 36.7282111],
    [36.6185854, 36.728449],
    [36.6185854, 36.7286632],
    [36.6186806, 36.7288535],
    [36.6187282, 36.7293056],
    [36.618752, 36.7296626],
    [36.6188948, 36.7298291],
    [36.6192279, 36.730186],
    [36.6193707, 36.7304478],
    [36.6193707, 36.7307095],
    [36.6192041, 36.7311616],
    [36.6190851, 36.7314472],
    [36.6188948, 36.732042],
    [36.6186568, 36.7325417],
    [36.6183475, 36.7331366],
    [36.617943, 36.7337314],
    [36.6170864, 36.7347308],
    [36.616777, 36.7349925],
    [36.6166105, 36.7352305],
    [36.6163012, 36.735516],
    [36.615968, 36.7356826],
    [36.6156825, 36.7358254],
    [36.6155159, 36.7360871],
    [36.6155159, 36.7363013],
    [36.6157063, 36.7365154],
    [36.6159918, 36.7366344],
    [36.6162536, 36.7369437],
    [36.6164439, 36.7372054],
    [36.616777, 36.7374672],
    [36.6170864, 36.7376575],
    [36.6171816, 36.7379669],
    [36.6171816, 36.7381572],
    [36.6170388, 36.7383952],
    [36.6166819, 36.7388711],
    [36.6163487, 36.7391804],
    [36.6156825, 36.7395849],
    [36.615278, 36.7397753],
    [36.6148973, 36.7399656],
    [36.6147783, 36.7402036],
    [36.6148735, 36.7404415],
    [36.6149925, 36.7406319],
    [36.6154921, 36.7410602],
    [36.6158253, 36.7412029],
    [36.615968, 36.7412267],
    [36.616087, 36.7414885],
    [36.6160394, 36.7418216],
    [36.6159204, 36.7421547],
    [36.6155397, 36.7425354],
    [36.6150876, 36.7428448],
    [36.6146831, 36.7431541],
    [36.6143262, 36.7434396],
    [36.6138027, 36.7436538],
    [36.6133268, 36.743749],
    [36.6130889, 36.7439155],
    [36.6128985, 36.7442487],
    [36.6128271, 36.7447959],
    [36.6129461, 36.7455336],
    [36.6132079, 36.7459857],
    [36.6133982, 36.746295],
    [36.6138741, 36.7466281],
    [36.6143738, 36.7468899],
    [36.6149687, 36.7473182],
    [36.6156111, 36.7479606],
    [36.616206, 36.7486269],
    [36.6168246, 36.7491503],
    [36.6172291, 36.7496024],
    [36.6176099, 36.7502687],
    [36.6179906, 36.7507684],
    [36.6185616, 36.7513157],
    [36.6188472, 36.7517677],
    [36.6190613, 36.7520295],
    [36.6195134, 36.7522198],
    [36.6197514, 36.752553],
    [36.6200131, 36.7530527],
    [36.6205128, 36.7534334],
    [36.620727, 36.7536237],
    [36.6209173, 36.7541234],
    [36.6210601, 36.7547421],
    [36.6212028, 36.7553369],
    [36.6212028, 36.7556225],
    [36.621298, 36.755908],
    [36.6215122, 36.7560508],
    [36.6221546, 36.7563601],
    [36.6225116, 36.7565743],
    [36.6225829, 36.7567646],
    [36.6226543, 36.7573119],
    [36.6228209, 36.7577164],
    [36.6234157, 36.7584064],
    [36.624201, 36.759382],
    [36.624772, 36.7600007],
    [36.6251765, 36.7603576],
    [36.6254383, 36.7604766],
    [36.6256762, 36.7604766],
    [36.6256762, 36.7606669],
    [36.6255335, 36.7610714],
    [36.6253431, 36.7614046],
    [36.6252717, 36.7616425],
    [36.6253669, 36.761928],
    [36.6255097, 36.7620708],
    [36.6258904, 36.7621898],
    [36.626509, 36.7620946],
    [36.6269136, 36.7621422],
    [36.6277226, 36.7621898],
    [36.6283174, 36.7623563],
    [36.6288171, 36.7626181],
    [36.6289837, 36.7628322],
    [36.6289361, 36.7631416],
    [36.6284602, 36.763903],
    [36.6281033, 36.7644741],
    [36.6280319, 36.764831],
    [36.6280795, 36.7653545],
    [36.6280795, 36.7655924],
    [36.6278653, 36.7659493],
    [36.6276988, 36.7662111],
    [36.6277464, 36.7664966],
    [36.6280081, 36.7668535],
    [36.6286268, 36.7671867],
    [36.6291027, 36.767258],
    [36.630221, 36.7673056],
    [36.6313869, 36.767258],
    [36.6320294, 36.7672342],
    [36.6325291, 36.7673294],
    [36.6332191, 36.7677339],
    [36.6336236, 36.7679719],
    [36.633933, 36.7679719],
    [36.6342661, 36.7678529],
    [36.6345754, 36.7677577],
    [36.6348372, 36.7677815],
    [36.6352417, 36.768186],
    [36.6353131, 36.7686381],
    [36.6353131, 36.7691616],
    [36.6352417, 36.7694233],
    [36.6349323, 36.7701134],
    [36.6346706, 36.7703989],
    [36.6345516, 36.7706369],
    [36.634504, 36.7708034],
    [36.6345278, 36.77097],
    [36.6346468, 36.7711604],
    [36.6349085, 36.7711842],
    [36.6355272, 36.7710176],
    [36.6361697, 36.7707558],
    [36.6366218, 36.7707558],
    [36.637169, 36.7708034],
    [36.6375497, 36.7708272],
    [36.6378115, 36.7707321],
    [36.6380256, 36.7705179],
    [36.638216, 36.770042],
    [36.6386443, 36.7697089],
    [36.6393105, 36.7693758],
    [36.6397864, 36.7692568],
    [36.6401672, 36.7693282],
    [36.6403337, 36.7695185],
    [36.6403575, 36.7697327],
    [36.6402385, 36.7699468],
    [36.640072, 36.7702562],
    [36.6399768, 36.7706607],
    [36.639834, 36.7711366],
    [36.6396913, 36.7714459],
    [36.6395247, 36.7717314],
    [36.6395009, 36.772017],
    [36.6396199, 36.7724453],
    [36.6395247, 36.7728498],
    [36.6394057, 36.7732067],
    [36.6393581, 36.7736588],
    [36.6394533, 36.7739919],
    [36.6396437, 36.7741585],
    [36.6400244, 36.7742537],
    [36.6405241, 36.7743488],
    [36.641, 36.7748961],
    [36.6412617, 36.7751341],
    [36.6413331, 36.775372],
    [36.6412617, 36.775491],
    [36.6411189, 36.7757289],
    [36.6411903, 36.7760145],
    [36.6412855, 36.7763238],
    [36.6415234, 36.7765617],
    [36.6421183, 36.7768235],
    [36.6428797, 36.7772042],
    [36.6432604, 36.7774183],
    [36.643546, 36.7777991],
    [36.6437601, 36.7784177],
    [36.6438315, 36.7791078],
    [36.6437601, 36.7794409],
    [36.6435698, 36.7796312],
    [36.6431891, 36.7797026],
    [36.6428084, 36.779893],
    [36.6425942, 36.7800595],
    [36.642499, 36.7803451],
    [36.6425704, 36.780702],
    [36.6426418, 36.7812493],
    [36.6426894, 36.7814872],
    [36.6429511, 36.7818203],
    [36.6433556, 36.7822011],
    [36.6434984, 36.7824628],
    [36.6435936, 36.7825342],
    [36.6439743, 36.7827721],
    [36.6440933, 36.7829387],
    [36.6441409, 36.7832004],
    [36.6440457, 36.7833194],
    [36.6439267, 36.7836525],
    [36.6439267, 36.7838191],
    [36.6440933, 36.784057],
    [36.6445692, 36.7844377],
    [36.6449499, 36.7848423],
    [36.6450688, 36.7851754],
    [36.645164, 36.7854847],
    [36.6454258, 36.7857465],
    [36.6457351, 36.7859844],
    [36.6458303, 36.7861985],
    [36.6459492, 36.7864365],
    [36.6462586, 36.7866031],
    [36.6466155, 36.7865555],
    [36.6472817, 36.7862699],
    [36.6477338, 36.7862461],
    [36.6484715, 36.7862937],
    [36.6487094, 36.7866506],
    [36.6488046, 36.7867934],
    [36.648876, 36.78696],
    [36.6490187, 36.7869838],
    [36.6500419, 36.7868648],
    [36.6503037, 36.786722],
    [36.650375, 36.7865793],
    [36.6506606, 36.7865317],
    [36.6509699, 36.7866031],
    [36.6512078, 36.786841],
    [36.6514458, 36.7869124],
    [36.6516837, 36.7870552],
    [36.6517551, 36.7871265],
    [36.6517551, 36.7872455],
    [36.6516837, 36.7874597],
    [36.6516362, 36.787769],
    [36.6516837, 36.7880307],
    [36.6519693, 36.7883163],
    [36.6523024, 36.7885066],
    [36.6530162, 36.7886256],
    [36.6537301, 36.7887922],
    [36.6542298, 36.7887684],
    [36.6547532, 36.7888635],
    [36.6553005, 36.7890301],
    [36.655705, 36.7890301],
    [36.6558954, 36.7891491],
    [36.6561095, 36.7896488],
    [36.6563237, 36.7899105],
    [36.6563475, 36.7901247],
    [36.6563713, 36.7904102],
    [36.656514, 36.7907671],
    [36.6567044, 36.7909813],
    [36.6568472, 36.7912668],
    [36.6569186, 36.7916237],
    [36.6570613, 36.7918855],
    [36.6573469, 36.7921234],
    [36.6577038, 36.7924327],
    [36.6578941, 36.792861],
    [36.6581321, 36.7930752],
    [36.6584652, 36.793099],
    [36.6588221, 36.7930276],
    [36.6592742, 36.7927659],
    [36.6596549, 36.7926469],
    [36.6601308, 36.7925993],
    [36.6604877, 36.7926945],
    [36.6607495, 36.7927897],
    [36.6615585, 36.792861],
    [36.6620582, 36.7931228],
    [36.6624389, 36.7933845],
    [36.6627482, 36.7939318],
    [36.6628672, 36.7943839],
    [36.6628196, 36.7946456],
    [36.6626055, 36.7949312],
    [36.6625817, 36.7951453],
    [36.6626531, 36.7953595],
    [36.6632955, 36.7956688],
    [36.663581, 36.795645],
    [36.6639142, 36.7956212],
    [36.6641521, 36.795645],
    [36.6643187, 36.795764],
    [36.6643425, 36.7960257],
    [36.6642949, 36.7961923],
    [36.6641283, 36.7964302],
    [36.6640569, 36.796692],
    [36.6640807, 36.797263],
    [36.6641521, 36.7977389],
    [36.6643425, 36.7980007],
    [36.6648184, 36.7980959],
    [36.6652705, 36.7983814],
    [36.665675, 36.7984766],
    [36.6659129, 36.7984766],
    [36.666603, 36.7983814],
    [36.6668647, 36.7984766],
    [36.6670075, 36.7986669],
    [36.6670313, 36.7988573],
    [36.6669361, 36.7990714],
    [36.6668409, 36.799357],
    [36.6668647, 36.7995711],
    [36.6667695, 36.7997377],
    [36.6668171, 36.7999756],
    [36.6668885, 36.8001422],
    [36.6671978, 36.8003088],
    [36.6676023, 36.8004753],
    [36.6679355, 36.8005229],
    [36.6685779, 36.8006895],
    [36.6690062, 36.8008084],
    [36.6695059, 36.800856],
    [36.6698866, 36.8007846],
    [36.6701484, 36.8006419],
    [36.6702197, 36.8004515],
    [36.6702197, 36.8001422],
    [36.6703149, 36.7999518],
    [36.6709098, 36.7995711],
    [36.6712667, 36.7994046],
    [36.6714571, 36.7993808],
    [36.6717188, 36.7995473],
    [36.6718378, 36.7998091],
    [36.671814, 36.8002374],
    [36.6716712, 36.8004515],
    [36.6713857, 36.8007846],
    [36.6712905, 36.8010226],
    [36.6712191, 36.8012843],
    [36.6710763, 36.8015461],
    [36.6708384, 36.8017126],
    [36.6705053, 36.8018554],
    [36.6701484, 36.8019268],
    [36.6699104, 36.8020696],
    [36.6696725, 36.8023313],
    [36.6694821, 36.8026882],
    [36.6694345, 36.8029737],
    [36.6694821, 36.8031879],
    [36.6697439, 36.8033307],
    [36.6700532, 36.803521],
    [36.6701722, 36.8038066],
    [36.6701484, 36.8041635],
    [36.670077, 36.8044014],
    [36.6700056, 36.8046156],
    [36.6698866, 36.8049487],
    [36.6697914, 36.8050915],
    [36.6696249, 36.8051629],
    [36.6693869, 36.8051629],
    [36.66903, 36.8051866],
    [36.6687921, 36.805258],
    [36.6685779, 36.8054484],
    [36.6686017, 36.8057577],
    [36.6686255, 36.806067],
    [36.6686969, 36.8062336],
    [36.6688872, 36.8063288],
    [36.6696487, 36.8064954],
    [36.6704815, 36.8068523],
    [36.670648, 36.8070188],
    [36.670886, 36.807352],
    [36.6710526, 36.8076375],
    [36.6710763, 36.8078992],
    [36.6710288, 36.8082086],
    [36.6707908, 36.8089224],
    [36.6711239, 36.8094459],
    [36.6713381, 36.8095411],
    [36.6714333, 36.80966],
    [36.6713619, 36.8100883],
    [36.6712429, 36.8103739],
    [36.6710763, 36.8104691],
    [36.6709336, 36.8106118],
    [36.6709098, 36.8107784],
    [36.6709098, 36.8109211],
    [36.6708384, 36.8110639],
    [36.6705053, 36.8111829],
    [36.6703149, 36.8112781],
    [36.6701246, 36.8114446],
    [36.6700532, 36.8117302],
    [36.6699342, 36.8119443],
    [36.6697439, 36.8122061],
    [36.6696963, 36.8125392],
    [36.6696011, 36.8127533],
    [36.6694107, 36.8128961],
    [36.669149, 36.8129675],
    [36.668911, 36.8129199],
    [36.6686731, 36.8127533],
    [36.6684351, 36.812444],
    [36.668221, 36.8121823],
    [36.6680306, 36.8121109],
    [36.6678403, 36.8121347],
    [36.6676261, 36.8123726],
    [36.667412, 36.812563],
    [36.6671502, 36.8127771],
    [36.6669599, 36.8129913],
    [36.6668885, 36.8132054],
    [36.6668885, 36.813372],
    [36.6670789, 36.813491],
    [36.6675072, 36.8135148],
    [36.6678641, 36.8137527],
    [36.6679355, 36.8142048],
    [36.6680068, 36.8144903],
    [36.6680068, 36.8147283],
    [36.6676737, 36.8152994],
    [36.6674358, 36.8155135],
    [36.6673644, 36.815799],
    [36.6674834, 36.8160846],
    [36.6677927, 36.8165367],
    [36.6683638, 36.8172981],
    [36.66903, 36.817893],
    [36.6697676, 36.8182499],
    [36.670077, 36.8185116],
    [36.6703387, 36.8187496],
    [36.6708146, 36.8190113],
    [36.6711477, 36.8193206],
    [36.6712905, 36.81963],
    [36.6714333, 36.8201773],
    [36.671576, 36.8206056],
    [36.6715284, 36.8210339],
    [36.6714095, 36.8213908],
    [36.6709336, 36.8217001],
    [36.670767, 36.8216287],
    [36.6703387, 36.8212004],
    [36.670077, 36.821129],
    [36.6695773, 36.8211052],
    [36.669149, 36.8212242],
    [36.668911, 36.8214146],
    [36.6687921, 36.8216763],
    [36.6687683, 36.8219143],
    [36.6688397, 36.8222712],
    [36.6689348, 36.8226757],
    [36.6691252, 36.822866],
    [36.6691966, 36.8230564],
    [36.6692918, 36.8237226],
    [36.669149, 36.8243413],
    [36.6689348, 36.824603],
    [36.6683162, 36.8248886],
    [36.6677451, 36.8250789],
    [36.6671264, 36.8253645],
    [36.6667457, 36.8256738],
    [36.6664126, 36.825888],
    [36.6658891, 36.8261973],
    [36.6653044, 36.8266765],
    [36.6651945, 36.8267665],
    [36.6650717, 36.8269071],
    [36.664743, 36.8272213],
    [36.664577, 36.8273329],
    [36.6638646, 36.8273441],
    [36.6630629, 36.8279562],
    [36.6631661, 36.8283566],
    [36.6602289, 36.8305675],
    [36.6547806, 36.835108],
    [36.6401116, 36.8370407],
    [36.636168, 36.8412381],
    [36.6310119, 36.8526826],
    [36.6211483, 36.8660571],
    [36.6022017, 36.8782333],
    [36.5817223, 36.8783318],
    [36.5724176, 36.8745982],
    [36.5580292, 36.8728466],
    [36.5485387, 36.8720751],
    [36.5306109, 36.8734126],
    [36.5199042, 36.8856189],
    [36.5128898, 36.8823669],
    [36.5072243, 36.8837764],
    [36.5021609, 36.8830534],
    [36.4979982, 36.881646],
    [36.4885997, 36.8850443],
    [36.4816115, 36.8862928],
    [36.4781283, 36.8838772],
    [36.4730503, 36.8849013],
    [36.4701912, 36.8863707],
    [36.4675862, 36.8867467],
    [36.4651236, 36.8862731],
    [36.4623101, 36.888135],
    [36.4658546, 36.8956158],
    [36.4637946, 36.9012442],
    [36.4637946, 36.9075585],
    [36.4665412, 36.9157251],
    [36.4708327, 36.9199796],
    [36.4708349, 36.9260633],
    [36.4696031, 36.9321392],
    [36.432929, 36.9313971],
    [36.4111591, 36.9299366],
    [36.3991107, 36.9297265],
    [36.3837713, 36.930899],
    [36.3690029, 36.9316716],
    [36.3488209, 36.9420972],
    [36.3349941, 36.9472586],
    [36.3168361, 36.9516872],
    [36.3038486, 36.9531916],
    [36.293381, 36.9563391],
    [36.2825179, 36.9587322],
    [36.2699201, 36.9626117],
    [36.2463558, 36.963008],
    [36.2353925, 36.964745],
    [36.2259351, 36.9774657],
    [36.2060466, 36.9780534],
    [36.2149417, 36.989985],
    [36.2175473, 36.9982442],
    [36.2159432, 37.0044303],
    [36.2058292, 37.004599],
    [36.1970841, 36.9982688],
    [36.1897143, 36.9963062],
    [36.1866097, 36.9941396],
    [36.1705945, 36.993537],
    [36.1580553, 36.9930651],
    [36.139259, 36.9948249],
    [36.1267314, 36.9966605],
    [36.1152935, 36.9992454],
    [36.0940996, 37.0032476],
    [36.0701861, 37.0032594],
    [36.0575643, 37.0082108],
    [36.0570116, 37.00814],
    [36.0497375, 37.0072064],
    [36.0428257, 37.0042586],
    [36.0305679, 36.9942727],
    [36.0239871, 36.9857624],
    [36.0216368, 36.9822579],
    [36.0076561, 36.9712208],
    [35.9960044, 36.9691233],
    [35.9843527, 36.9670256],
    [35.9854555, 36.9452003],
    [36.0199461, 36.9415479],
    [36.0171137, 36.9363856],
    [36.0100755, 36.9311372],
    [36.0070408, 36.9306381],
    [36.0134092, 36.9296677],
    [36.0186427, 36.9282859],
    [36.0221091, 36.9271466],
    [36.0271712, 36.9256575],
    [36.037104, 36.9220529],
    [36.0374923, 36.921912],
    [36.056906, 36.913441],
    [36.057535, 36.913168],
    [36.0581857, 36.9129495],
    [36.0641777, 36.9102942],
    [36.0711581, 36.9069172],
    [36.082948, 36.900955],
    [36.0841, 36.9003189],
    [36.0856495, 36.8994632],
    [36.0877358, 36.8984583],
    [36.0895099, 36.8973942],
    [36.0902162, 36.8971117],
    [36.0906762, 36.8967702],
    [36.0913334, 36.8965009],
    [36.0918919, 36.8960673],
    [36.0925161, 36.8959031],
    [36.0931321, 36.895463],
    [36.0937317, 36.8952463],
    [36.0944135, 36.8947799],
    [36.0963354, 36.8939391],
    [36.0970582, 36.8933807],
    [36.0979863, 36.8930063],
    [36.0999329, 36.8917911],
    [36.1004586, 36.8915283],
    [36.1009843, 36.8911013],
    [36.1016331, 36.8909437],
    [36.102052, 36.8906152],
    [36.1027091, 36.8903393],
    [36.1035274, 36.8898838],
    [36.1062491, 36.8883686],
    [36.1069966, 36.8879941],
    [36.1097974, 36.8863518],
    [36.1143395, 36.8834809],
    [36.1168117, 36.8818319],
    [36.1205408, 36.8790785],
    [36.1221161, 36.8779034],
    [36.1233063, 36.8771286],
    [36.1259494, 36.8751456],
    [36.1259166, 36.8747713],
    [36.1264009, 36.8743708],
    [36.1292984, 36.8722958],
    [36.1296514, 36.8718099],
    [36.1303409, 36.8713437],
    [36.1308088, 36.8708906],
    [36.1335832, 36.8688221],
    [36.1360375, 36.8670359],
    [36.1366285, 36.8664252],
    [36.1361606, 36.8661231],
    [36.1361606, 36.8660114],
    [36.1363576, 36.8660968],
    [36.1389761, 36.864061],
    [36.1389843, 36.8638049],
    [36.1397476, 36.8630956],
    [36.1409871, 36.8616705],
    [36.1429982, 36.8599236],
    [36.1451816, 36.8581109],
    [36.1452542, 36.8580473],
    [36.1470613, 36.8564624],
    [36.1480791, 36.8553196],
    [36.1488589, 36.8546497],
    [36.1493432, 36.8539929],
    [36.150517, 36.852827],
    [36.1513916, 36.8518251],
    [36.1515198, 36.851543],
    [36.1534595, 36.8494265],
    [36.1541277, 36.8486143],
    [36.1543752, 36.8482214],
    [36.1540427, 36.8480191],
    [36.1522045, 36.8469013],
    [36.1516491, 36.8475319],
    [36.1515871, 36.8477075],
    [36.1514596, 36.8477622],
    [36.1512591, 36.8477537],
    [36.1511843, 36.8476107],
    [36.1518551, 36.8467914],
    [36.1518495, 36.846579],
    [36.1518893, 36.8465234],
    [36.1519455, 36.846445],
    [36.152123, 36.8464065],
    [36.1542673, 36.8478316],
    [36.1548224, 36.8475185],
    [36.1556877, 36.8466812],
    [36.1570984, 36.845206],
    [36.1584931, 36.8434926],
    [36.1592465, 36.8424734],
    [36.1598979, 36.8418752],
    [36.1598075, 36.8415754],
    [36.16149, 36.839952],
    [36.161788, 36.83943],
    [36.1621511, 36.8390067],
    [36.162395, 36.838644],
    [36.1626379, 36.8383204],
    [36.1628627, 36.8379677],
    [36.162991, 36.837601],
    [36.1635005, 36.8367013],
    [36.1634846, 36.8360385],
    [36.1637321, 36.8355245],
    [36.163842, 36.83501],
    [36.163821, 36.834497],
    [36.163811, 36.83424],
    [36.1648951, 36.8329001],
    [36.165949, 36.831872],
    [36.1660581, 36.831727],
    [36.1661923, 36.831312],
    [36.1664262, 36.8311066],
    [36.1668644, 36.8306033],
    [36.1669702, 36.830466],
    [36.1670496, 36.830049],
    [36.1683245, 36.8285616],
    [36.1702492, 36.826514],
    [36.170663, 36.826097],
    [36.1722094, 36.824572],
    [36.1724467, 36.8239921],
    [36.1695611, 36.8222022],
    [36.1694112, 36.8219822],
    [36.1695112, 36.8218322],
    [36.169736, 36.8219022],
    [36.169786, 36.8221122],
    [36.1724092, 36.8236821],
    [36.1731213, 36.8229821],
    [36.1729963, 36.8228621],
    [36.1744454, 36.8214122],
    [36.1743829, 36.8211122],
    [36.1731712, 36.8205322],
    [36.1732961, 36.8203622],
    [36.1715598, 36.8195622],
    [36.1710601, 36.8194822],
    [36.170498, 36.8195522],
    [36.1700608, 36.8197922],
    [36.1678623, 36.8220422],
    [36.1678623, 36.8223021],
    [36.1676749, 36.8224821],
    [36.1674126, 36.8224421],
    [36.1674126, 36.8222721],
    [36.1698609, 36.8197122],
    [36.1704605, 36.8193822],
    [36.1709352, 36.8192722],
    [36.1715473, 36.8193322],
    [36.1754444, 36.8210073],
    [36.176279, 36.8205213],
    [36.1769323, 36.81986],
    [36.177258, 36.8195],
    [36.1777602, 36.818871],
    [36.1783198, 36.8182028],
    [36.1798111, 36.8166441],
    [36.18015, 36.816188],
    [36.1803055, 36.8153009],
    [36.1803712, 36.8150426],
    [36.18053, 36.8148979],
    [36.1805434, 36.8146864],
    [36.1805307, 36.814537],
    [36.1806765, 36.8140544],
    [36.181161, 36.813192],
    [36.1810292, 36.813069],
    [36.181592, 36.812322],
    [36.1822215, 36.811577],
    [36.1831531, 36.810543],
    [36.1851423, 36.8082494],
    [36.187659, 36.8054801],
    [36.1916783, 36.801245],
    [36.1919848, 36.8012681],
    [36.192289, 36.800735],
    [36.192598, 36.80047],
    [36.19306, 36.800073],
    [36.1933098, 36.7998003],
    [36.1936733, 36.7994859],
    [36.193974, 36.799021],
    [36.194282, 36.798756],
    [36.194591, 36.798491],
    [36.194888, 36.797969],
    [36.195197, 36.797704],
    [36.195505, 36.797439],
    [36.195803, 36.796917],
    [36.196111, 36.796652],
    [36.1965039, 36.7962107],
    [36.1972093, 36.795344],
    [36.1975427, 36.7950399],
    [36.1979019, 36.7944704],
    [36.198237, 36.794027],
    [36.198545, 36.793762],
    [36.198843, 36.79324],
    [36.19914, 36.792719],
    [36.1994787, 36.7923794],
    [36.199746, 36.791932],
    [36.200055, 36.791667],
    [36.200363, 36.791402],
    [36.200671, 36.791137],
    [36.200969, 36.790615],
    [36.201266, 36.790093],
    [36.201574, 36.789828],
    [36.203083, 36.787733],
    [36.2034798, 36.787477],
    [36.2038824, 36.7868724],
    [36.2041253, 36.7864315],
    [36.204284, 36.785903],
    [36.20456, 36.784868],
    [36.2044898, 36.7844607],
    [36.2042455, 36.7840042],
    [36.2012876, 36.7834319],
    [36.2010072, 36.7843954],
    [36.201161, 36.78462],
    [36.201369, 36.7847577],
    [36.2013329, 36.7849098],
    [36.2010253, 36.7849026],
    [36.2006906, 36.7845114],
    [36.2010434, 36.7833884],
    [36.2009529, 36.7832146],
    [36.1991529, 36.7828596],
    [36.1992071, 36.782606],
    [36.1876287, 36.7803891],
    [36.1876739, 36.7802297],
    [36.1873302, 36.7801645],
    [36.1872759, 36.7803021],
    [36.1870498, 36.7802659],
    [36.1871583, 36.7798674],
    [36.1873935, 36.7799254],
    [36.1873393, 36.7800631],
    [36.1877011, 36.780121],
    [36.1877463, 36.7799906],
    [36.1929656, 36.7810049],
    [36.1929114, 36.7812222],
    [36.1951185, 36.7816352],
    [36.195209, 36.7814396],
    [36.1959869, 36.781599],
    [36.1959236, 36.7817584],
    [36.2011067, 36.7827582],
    [36.2012876, 36.782606],
    [36.2025178, 36.778346],
    [36.2028706, 36.7782156],
    [36.2032053, 36.7783387],
    [36.204293, 36.778456],
    [36.204601, 36.778191],
    [36.2048195, 36.7777801],
    [36.205186, 36.776891],
    [36.2054911, 36.7762352],
    [36.206057, 36.774813],
    [36.2062807, 36.7739698],
    [36.206333, 36.773778],
    [36.206312, 36.773265],
    [36.206545, 36.771204],
    [36.206374, 36.7671],
    [36.2061572, 36.7661235],
    [36.205661, 36.765322],
    [36.2044733, 36.7636939],
    [36.203461, 36.762299],
    [36.2030315, 36.7617325],
    [36.2028326, 36.7617024],
    [36.202164, 36.7613824],
    [36.201404, 36.7613506],
    [36.2006009, 36.7614254],
    [36.2003312, 36.7614336],
    [36.2004215, 36.7611881],
    [36.199629, 36.7611127],
    [36.199588, 36.761247],
    [36.1996104, 36.7624654],
    [36.1995486, 36.7625892],
    [36.1994311, 36.76251],
    [36.1993358, 36.7618161],
    [36.1991046, 36.7613774],
    [36.1987508, 36.7612118],
    [36.1984752, 36.7611814],
    [36.1985096, 36.7614298],
    [36.1984455, 36.7614712],
    [36.198312, 36.761282],
    [36.1979653, 36.7613109],
    [36.197601, 36.761503],
    [36.1975107, 36.7613208],
    [36.1974273, 36.7611524],
    [36.1971057, 36.7609641],
    [36.1971788, 36.7607462],
    [36.1970368, 36.7591306],
    [36.1966211, 36.7583019],
    [36.1957788, 36.757499],
    [36.195272, 36.757255],
    [36.194778, 36.7569623],
    [36.1942084, 36.7566416],
    [36.193636, 36.756271],
    [36.193471, 36.756148],
    [36.1926828, 36.7545469],
    [36.1924602, 36.7544262],
    [36.1921909, 36.7537892],
    [36.1920136, 36.7535799],
    [36.1915932, 36.7528916],
    [36.1915879, 36.7524492],
    [36.1913773, 36.7507067],
    [36.1909902, 36.7499749],
    [36.1911146, 36.748247],
    [36.1908258, 36.7473423],
    [36.1904574, 36.746854],
    [36.1904432, 36.7462408],
    [36.190315, 36.745447],
    [36.190304, 36.74519],
    [36.190283, 36.7448133],
    [36.1910512, 36.7434256],
    [36.1916, 36.741817],
    [36.1918374, 36.7407269],
    [36.1921069, 36.7402442],
    [36.1918507, 36.7391824],
    [36.1920212, 36.7386832],
    [36.1926268, 36.7381001],
    [36.193013, 36.737413],
    [36.1931683, 36.7366194],
    [36.1946197, 36.7348562],
    [36.1953315, 36.7334748],
    [36.1954368, 36.7330668],
    [36.1883367, 36.7291115],
    [36.187871, 36.728661],
    [36.187446, 36.7280651],
    [36.1871804, 36.726621],
    [36.1872402, 36.7264781],
    [36.1873819, 36.7265803],
    [36.1874144, 36.7268515],
    [36.1876512, 36.727739],
    [36.1878496, 36.7279434],
    [36.1878496, 36.7282274],
    [36.1881756, 36.728625],
    [36.1884624, 36.7287388],
    [36.1894172, 36.7292691],
    [36.1898312, 36.7288697],
    [36.1896572, 36.728803],
    [36.1898002, 36.7285756],
    [36.1967682, 36.7324128],
    [36.1979464, 36.7310601],
    [36.1954589, 36.7296187],
    [36.1968985, 36.727996],
    [36.1986916, 36.7289998],
    [36.1985357, 36.7291702],
    [36.1996802, 36.7297588],
    [36.199953, 36.7294883],
    [36.2004774, 36.7296246],
    [36.2007183, 36.7293179],
    [36.200364, 36.7290566],
    [36.2021403, 36.7269674],
    [36.2016588, 36.7253702],
    [36.2011182, 36.723743],
    [36.1987377, 36.7224255],
    [36.1987023, 36.7222977],
    [36.1990389, 36.7222835],
    [36.1992303, 36.722138],
    [36.2008281, 36.7230503],
    [36.202088, 36.7228536],
    [36.2021391, 36.7225391],
    [36.202285, 36.7225118],
    [36.2021745, 36.7222551],
    [36.2019728, 36.722269],
    [36.2017678, 36.7216234],
    [36.2014659, 36.7213179],
    [36.201023, 36.7211759],
    [36.2008783, 36.7208104],
    [36.2001063, 36.720188],
    [36.197215, 36.7210316],
    [36.1977633, 36.7223119],
    [36.197665, 36.7225648],
    [36.1974523, 36.722588],
    [36.1967216, 36.7209128],
    [36.1882324, 36.7232974],
    [36.1845297, 36.7238171],
    [36.1841222, 36.7240017],
    [36.1837748, 36.7239356],
    [36.1839513, 36.7237311],
    [36.188321, 36.723069],
    [36.1941059, 36.7214303],
    [36.1947633, 36.7203835],
    [36.1954428, 36.7192272],
    [36.1961567, 36.7175672],
    [36.1965811, 36.7155186],
    [36.1963706, 36.7142321],
    [36.1960875, 36.7137085],
    [36.1956451, 36.7127492],
    [36.195401, 36.710384],
    [36.195391, 36.710128],
    [36.195688, 36.709606],
    [36.195499, 36.708687],
    [36.1953436, 36.7083317],
    [36.195049, 36.707906],
    [36.1946723, 36.707317],
    [36.1941832, 36.7071013],
    [36.1937686, 36.706756],
    [36.1935259, 36.7065637],
    [36.1933827, 36.7064503],
    [36.1930549, 36.7060048],
    [36.1931397, 36.7054875],
    [36.1929264, 36.7051516],
    [36.1932797, 36.7049002],
    [36.193736, 36.704536],
    [36.194205, 36.7038751],
    [36.1943004, 36.7032441],
    [36.1913935, 36.7019852],
    [36.1915727, 36.7014726],
    [36.1844256, 36.6986755],
    [36.1846595, 36.6983022],
    [36.1918136, 36.7010944],
    [36.192176, 36.7008301],
    [36.1930733, 36.7010688],
    [36.1948924, 36.6980664],
    [36.1939792, 36.6976621],
    [36.1942054, 36.6972602],
    [36.1864188, 36.6943095],
    [36.1864831, 36.6941808],
    [36.1865678, 36.6940114],
    [36.1942951, 36.696943],
    [36.19525, 36.6953601],
    [36.1972611, 36.6961009],
    [36.1994995, 36.6925337],
    [36.1999587, 36.6926974],
    [36.2000416, 36.6925686],
    [36.2003847, 36.6919019],
    [36.2008206, 36.6906761],
    [36.2009459, 36.6903236],
    [36.2009505, 36.6902831],
    [36.2009944, 36.6898901],
    [36.200572, 36.6893404],
    [36.1994813, 36.688863],
    [36.1992277, 36.6889251],
    [36.1980972, 36.6886028],
    [36.1981397, 36.6883783],
    [36.1937129, 36.6869056],
    [36.1938337, 36.6866795],
    [36.1969102, 36.6877199],
    [36.1982646, 36.6881846],
    [36.1984544, 36.6880201],
    [36.1991182, 36.6881987],
    [36.1993982, 36.6884688],
    [36.2014892, 36.6891963],
    [36.2017818, 36.6892347],
    [36.2020901, 36.689146],
    [36.2028797, 36.6878029],
    [36.2028903, 36.6875595],
    [36.2028024, 36.6874939],
    [36.2006122, 36.6866795],
    [36.1983723, 36.6858429],
    [36.1982015, 36.6859285],
    [36.1980383, 36.6858236],
    [36.1980643, 36.6857175],
    [36.1979857, 36.6856782],
    [36.1980291, 36.6856127],
    [36.1978989, 36.6855672],
    [36.1979564, 36.6854347],
    [36.1945088, 36.6842625],
    [36.1947361, 36.6838474],
    [36.1981916, 36.6850618],
    [36.1982889, 36.6848922],
    [36.1982993, 36.6848732],
    [36.200645, 36.685564],
    [36.2020591, 36.6860278],
    [36.2028909, 36.684473],
    [36.2027924, 36.6841398],
    [36.202759, 36.6838152],
    [36.2029369, 36.6836471],
    [36.1962783, 36.681328],
    [36.1963755, 36.6811473],
    [36.2027157, 36.6833636],
    [36.2032759, 36.6819646],
    [36.203695, 36.6817291],
    [36.2067781, 36.6828527],
    [36.2067858, 36.682691],
    [36.2068273, 36.682548],
    [36.2045832, 36.6817063],
    [36.2033736, 36.6813262],
    [36.2035745, 36.6808909],
    [36.2037348, 36.6809349],
    [36.2044323, 36.6795136],
    [36.2044141, 36.6795109],
    [36.2044447, 36.679437],
    [36.2043714, 36.6794196],
    [36.2043963, 36.6793773],
    [36.2043239, 36.6793628],
    [36.2043916, 36.6792242],
    [36.2042189, 36.6791597],
    [36.2044166, 36.6787594],
    [36.2034744, 36.6784722],
    [36.2034515, 36.6785228],
    [36.2018444, 36.6779842],
    [36.2018042, 36.6780671],
    [36.1985741, 36.6770018],
    [36.1986137, 36.6769118],
    [36.1980486, 36.6767592],
    [36.1980896, 36.6766711],
    [36.1981193, 36.6766072],
    [36.1992487, 36.6769015],
    [36.2039592, 36.6783803],
    [36.2040601, 36.6781801],
    [36.2053531, 36.6785981],
    [36.2056915, 36.6786982],
    [36.2067335, 36.6789393],
    [36.2071723, 36.6788884],
    [36.2073597, 36.6785616],
    [36.2077722, 36.67774],
    [36.2084273, 36.6764357],
    [36.2090337, 36.6753254],
    [36.2103304, 36.675768],
    [36.2108974, 36.6746562],
    [36.2110032, 36.6743128],
    [36.2112135, 36.6737233],
    [36.2116684, 36.672595],
    [36.2119564, 36.6719541],
    [36.2118781, 36.6717435],
    [36.2109624, 36.6711826],
    [36.211233, 36.670531],
    [36.2116995, 36.6693592],
    [36.2118714, 36.6689931],
    [36.212113, 36.66824],
    [36.2129226, 36.6659261],
    [36.212608, 36.6658442],
    [36.2047957, 36.6641531],
    [36.2048168, 36.664081],
    [36.204291, 36.6639581],
    [36.2048303, 36.6640606],
    [36.2048724, 36.6639577],
    [36.2103504, 36.6651676],
    [36.2104065, 36.6652289],
    [36.2126628, 36.6657118],
    [36.2132624, 36.6650501],
    [36.2135546, 36.6641832],
    [36.213582, 36.6641021],
    [36.2141351, 36.6642419],
    [36.2141648, 36.664127],
    [36.2143062, 36.663886],
    [36.2142065, 36.6637372],
    [36.214359, 36.663413],
    [36.214705, 36.662651],
    [36.2148985, 36.662122],
    [36.21507, 36.661375],
    [36.2153012, 36.6605936],
    [36.2153232, 36.6597098],
    [36.2152265, 36.659067],
    [36.2151025, 36.659004],
    [36.2144085, 36.6588492],
    [36.2136685, 36.6587228],
    [36.2136063, 36.6586603],
    [36.2136106, 36.6586162],
    [36.2136307, 36.6585372],
    [36.2136356, 36.6584901],
    [36.2137271, 36.6584502],
    [36.2144222, 36.6585004],
    [36.2145999, 36.6583234],
    [36.2147495, 36.6579681],
    [36.2147545, 36.6571802],
    [36.2142669, 36.6568584],
    [36.2139832, 36.6568799],
    [36.2137211, 36.6566856],
    [36.2136256, 36.6562985],
    [36.2133635, 36.6563496],
    [36.2132264, 36.6562651],
    [36.2133607, 36.6559378],
    [36.2134516, 36.654648],
    [36.2132789, 36.6542444],
    [36.2127663, 36.6535005],
    [36.212341, 36.653092],
    [36.2123638, 36.6522931],
    [36.2124566, 36.6516628],
    [36.2126519, 36.6495632],
    [36.2123689, 36.6479682],
    [36.2124653, 36.6473583],
    [36.2123768, 36.6467353],
    [36.2122105, 36.6464532],
    [36.2122794, 36.6460461],
    [36.2122408, 36.6442102],
    [36.2120581, 36.6429545],
    [36.2118781, 36.6422911],
    [36.2117106, 36.6417845],
    [36.2112637, 36.6409686],
    [36.2106634, 36.6403081],
    [36.2096852, 36.6398216],
    [36.2087678, 36.6397045],
    [36.208369, 36.639515],
    [36.208059, 36.63925],
    [36.2076372, 36.6390183],
    [36.2073386, 36.6386118],
    [36.2074591, 36.6377421],
    [36.2067143, 36.6360478],
    [36.2063105, 36.6357092],
    [36.2063275, 36.6356239],
    [36.2060875, 36.6353998],
    [36.2058352, 36.6353326],
    [36.2056663, 36.6349166],
    [36.205351, 36.634821],
    [36.2050831, 36.6345602],
    [36.2043313, 36.6346753],
    [36.204059, 36.634313],
    [36.2036361, 36.6342421],
    [36.203469, 36.634006],
    [36.2034091, 36.6340329],
    [36.2031381, 36.6334675],
    [36.2025701, 36.633161],
    [36.2020713, 36.6331112],
    [36.2014673, 36.6329129],
    [36.2013591, 36.6324816],
    [36.20035, 36.632135],
    [36.200036, 36.631999],
    [36.1997679, 36.631555],
    [36.1994684, 36.63133],
    [36.199113, 36.631078],
    [36.198186, 36.630286],
    [36.197872, 36.630151],
    [36.197731, 36.629634],
    [36.19774, 36.629377],
    [36.1978935, 36.6290782],
    [36.1978787, 36.6287663],
    [36.1978509, 36.628245],
    [36.1977527, 36.627719],
    [36.197496, 36.627317],
    [36.197206, 36.626539],
    [36.1975105, 36.6187142],
    [36.1971861, 36.6178031],
    [36.1973649, 36.6166667],
    [36.1972749, 36.6163186],
    [36.1969477, 36.6162989],
    [36.1966613, 36.6162136],
    [36.1967104, 36.616161],
    [36.1970131, 36.6162004],
    [36.1973077, 36.6161807],
    [36.1974795, 36.6158852],
    [36.1974285, 36.615175],
    [36.1970786, 36.6151826],
    [36.1970949, 36.6150841],
    [36.1974996, 36.6150744],
    [36.1974003, 36.6150741],
    [36.19712, 36.6150733],
    [36.1951846, 36.6150676],
    [36.1951808, 36.6150932],
    [36.1951156, 36.6150925],
    [36.1951161, 36.6150112],
    [36.1951831, 36.6150142],
    [36.1951848, 36.6150378],
    [36.1971088, 36.6150037],
    [36.1971135, 36.6150148],
    [36.1971583, 36.6150203],
    [36.1971677, 36.6150435],
    [36.1972641, 36.6150455],
    [36.1972292, 36.6148817],
    [36.197263, 36.6147665],
    [36.1969477, 36.6147337],
    [36.1969912, 36.6137469],
    [36.1971324, 36.611526],
    [36.1969261, 36.6106373],
    [36.1965493, 36.6091848],
    [36.196443, 36.608029],
    [36.1956754, 36.6070256],
    [36.1931317, 36.607539],
    [36.1929953, 36.6075096],
    [36.1929084, 36.6074052],
    [36.192762, 36.6068668],
    [36.1923323, 36.6053279],
    [36.1917849, 36.6034925],
    [36.1911844, 36.601265],
    [36.1913828, 36.6009973],
    [36.1927533, 36.6007071],
    [36.192584, 36.600233],
    [36.1923833, 36.5993123],
    [36.1920519, 36.5992965],
    [36.1919996, 36.5990727],
    [36.189221, 36.5995846],
    [36.1890828, 36.5991946],
    [36.1918957, 36.5986279],
    [36.1918455, 36.598413],
    [36.1922879, 36.5982646],
    [36.19219, 36.597912],
    [36.1919986, 36.5978016],
    [36.1919407, 36.5976018],
    [36.191903, 36.597584],
    [36.191808, 36.5977653],
    [36.1913075, 36.5978865],
    [36.1892426, 36.5932113],
    [36.1873914, 36.5937486],
    [36.1854822, 36.5942564],
    [36.1853391, 36.5942986],
    [36.1851399, 36.5939644],
    [36.1852415, 36.5939138],
    [36.1853223, 36.5932482],
    [36.1854788, 36.5929538],
    [36.185676, 36.5927429],
    [36.1839981, 36.5919622],
    [36.1837838, 36.5921031],
    [36.184094, 36.5923688],
    [36.18381, 36.5925869],
    [36.1834843, 36.5923333],
    [36.1832631, 36.592543],
    [36.1836104, 36.5930634],
    [36.183547, 36.5930959],
    [36.1840675, 36.5937696],
    [36.1840651, 36.59394],
    [36.1845363, 36.5946198],
    [36.1844958, 36.5946988],
    [36.1843986, 36.5946953],
    [36.1832763, 36.5930552],
    [36.1831179, 36.5929139],
    [36.1829918, 36.5929992],
    [36.181013, 36.5922339],
    [36.1803717, 36.593313],
    [36.1802152, 36.5932667],
    [36.1804519, 36.5937386],
    [36.1818048, 36.5956141],
    [36.1833615, 36.5949265],
    [36.183402, 36.5950426],
    [36.1818613, 36.5957658],
    [36.185146, 36.6004067],
    [36.1859714, 36.6029546],
    [36.1862408, 36.6033187],
    [36.1860827, 36.6034327],
    [36.1859135, 36.6034842],
    [36.184906, 36.6004801],
    [36.1803646, 36.5940623],
    [36.1801317, 36.5940054],
    [36.1791061, 36.5944239],
    [36.1789982, 36.5943364],
    [36.1790156, 36.5942063],
    [36.1791255, 36.594118],
    [36.1792872, 36.594127],
    [36.1801672, 36.593816],
    [36.179982, 36.5933839],
    [36.1799183, 36.5929472],
    [36.1799947, 36.592496],
    [36.1797679, 36.5922177],
    [36.1795017, 36.592083],
    [36.1794322, 36.5921248],
    [36.179057, 36.5919996],
    [36.1790493, 36.5919189],
    [36.1782486, 36.5916611],
    [36.1781945, 36.5917572],
    [36.1763529, 36.591261],
    [36.1761622, 36.5913922],
    [36.1759946, 36.5927654],
    [36.1758693, 36.5927552],
    [36.1758212, 36.593593],
    [36.1759427, 36.5937742],
    [36.1761279, 36.5939833],
    [36.1762267, 36.5938506],
    [36.177599, 36.5945285],
    [36.1774911, 36.5946754],
    [36.1782748, 36.5950705],
    [36.1788246, 36.5955119],
    [36.1791484, 36.5956214],
    [36.1791892, 36.5956931],
    [36.1791718, 36.5957767],
    [36.1790777, 36.5958584],
    [36.1789172, 36.5958789],
    [36.1782565, 36.595287],
    [36.1771354, 36.5947176],
    [36.1757923, 36.5940391],
    [36.1756399, 36.5937513],
    [36.1755466, 36.5935147],
    [36.1757286, 36.5919947],
    [36.175749, 36.591543],
    [36.1755954, 36.5915105],
    [36.1754706, 36.5922936],
    [36.175063, 36.5921823],
    [36.1747386, 36.5920117],
    [36.1737261, 36.5918782],
    [36.173456, 36.5920296],
    [36.1731082, 36.5923304],
    [36.1731764, 36.5925049],
    [36.1728789, 36.5925804],
    [36.1728966, 36.5926429],
    [36.1728427, 36.5926568],
    [36.1733834, 36.5938225],
    [36.1735274, 36.5937734],
    [36.1735765, 36.5938898],
    [36.173144, 36.5940254],
    [36.1730886, 36.5939119],
    [36.1732213, 36.5938685],
    [36.1726826, 36.592698],
    [36.1726419, 36.5927085],
    [36.1726072, 36.5926082],
    [36.1717256, 36.5927678],
    [36.1716063, 36.5927896],
    [36.171383, 36.5930569],
    [36.170813, 36.5931986],
    [36.1708337, 36.5932955],
    [36.1706438, 36.5933565],
    [36.1703184, 36.5933396],
    [36.170256, 36.5932397],
    [36.1701003, 36.5932342],
    [36.1700121, 36.5932729],
    [36.1698497, 36.5932099],
    [36.168626, 36.593373],
    [36.1676199, 36.5935578],
    [36.166249, 36.5940899],
    [36.165725, 36.594331],
    [36.1655765, 36.5944371],
    [36.1648656, 36.5947836],
    [36.1641539, 36.5953137],
    [36.1636146, 36.5955745],
    [36.1631903, 36.5955896],
    [36.162574, 36.5957407],
    [36.1622873, 36.5958066],
    [36.1619596, 36.5957208],
    [36.1614726, 36.5954127],
    [36.1598681, 36.59446],
    [36.159162, 36.5940499],
    [36.1589194, 36.5939151],
    [36.1582188, 36.5935125],
    [36.1580649, 36.5935128],
    [36.157788, 36.5935144],
    [36.1573026, 36.5934075],
    [36.1568583, 36.5932712],
    [36.156624, 36.5931211],
    [36.1565458, 36.5929582],
    [36.1565676, 36.5926563],
    [36.155075, 36.5919261],
    [36.1540118, 36.5915284],
    [36.1516373, 36.5909947],
    [36.1503382, 36.5905173],
    [36.1500023, 36.590431],
    [36.1497267, 36.5903976],
    [36.1493118, 36.5903826],
    [36.1490884, 36.5905409],
    [36.148915, 36.5907582],
    [36.1492933, 36.590967],
    [36.1492854, 36.5910682],
    [36.1490253, 36.5909796],
    [36.1487889, 36.5908278],
    [36.148655, 36.5906506],
    [36.14862, 36.5902455],
    [36.1476864, 36.5898018],
    [36.1465365, 36.5896483],
    [36.1455964, 36.5892912],
    [36.1447932, 36.5886505],
    [36.144298, 36.588041],
    [36.1432912, 36.5872133],
    [36.1425755, 36.586675],
    [36.1421623, 36.5863511],
    [36.1420002, 36.5862209],
    [36.1419606, 36.5861939],
    [36.1418973, 36.5861701],
    [36.1418281, 36.5861623],
    [36.1417787, 36.5861511],
    [36.1417115, 36.5861019],
    [36.1415868, 36.5859956],
    [36.1414525, 36.585905],
    [36.1413714, 36.5858717],
    [36.1412983, 36.585859],
    [36.1412212, 36.5858605],
    [36.1411164, 36.5858781],
    [36.1409977, 36.5859019],
    [36.1409127, 36.5858987],
    [36.1408653, 36.5858765],
    [36.1408435, 36.5858448],
    [36.1408079, 36.5857924],
    [36.1407862, 36.5857368],
    [36.1407545, 36.5856654],
    [36.1405918, 36.5854827],
    [36.1399161, 36.5848705],
    [36.1397337, 36.5848104],
    [36.1395839, 36.584698],
    [36.1394601, 36.5845881],
    [36.139382, 36.5844652],
    [36.1393429, 36.5843135],
    [36.1393461, 36.5842586],
    [36.1394015, 36.5841775],
    [36.1394178, 36.5841122],
    [36.139408, 36.5840363],
    [36.1393331, 36.5837513],
    [36.1392908, 36.5836493],
    [36.1392191, 36.583529],
    [36.1391572, 36.5834558],
    [36.1390009, 36.5833041],
    [36.1383764, 36.5824864],
    [36.138142, 36.582243],
    [36.1371786, 36.5811796],
    [36.136613, 36.580536],
    [36.1361159, 36.5802087],
    [36.1357637, 36.579967],
    [36.1355524, 36.5799257],
    [36.1353935, 36.5799551],
    [36.1352877, 36.5800493],
    [36.1352051, 36.5801502],
    [36.1350398, 36.5801701],
    [36.1348316, 36.5801926],
    [36.1344011, 36.5804097],
    [36.1341656, 36.5805563],
    [36.1341441, 36.5805324],
    [36.1342907, 36.5803464],
    [36.1345221, 36.5801329],
    [36.1344255, 36.5799639],
    [36.1343636, 36.5799156],
    [36.1336864, 36.579305],
    [36.133334, 36.578788],
    [36.1322129, 36.5780595],
    [36.1312229, 36.5776199],
    [36.130682, 36.577311],
    [36.130373, 36.577047],
    [36.129747, 36.576775],
    [36.12911, 36.57676],
    [36.128802, 36.576495],
    [36.128175, 36.576223],
    [36.1277856, 36.5760742],
    [36.1272792, 36.5759958],
    [36.1268793, 36.5757871],
    [36.1262583, 36.5753917],
    [36.125364, 36.574744],
    [36.1250632, 36.5746991],
    [36.124106, 36.574327],
    [36.1233732, 36.5739788],
    [36.1229325, 36.5737255],
    [36.122544, 36.573519],
    [36.1221489, 36.5733241],
    [36.121922, 36.573119],
    [36.1211505, 36.5723063],
    [36.1210543, 36.5720479],
    [36.1211025, 36.5717511],
    [36.1208144, 36.5714464],
    [36.1205934, 36.5713268],
    [36.120339, 36.5712883],
    [36.1198684, 36.5713152],
    [36.119686, 36.5712844],
    [36.1194796, 36.5711765],
    [36.1193452, 36.5711225],
    [36.118961, 36.5708448],
    [36.1187065, 36.5705903],
    [36.1183512, 36.5702741],
    [36.1181688, 36.5700929],
    [36.1179576, 36.5699503],
    [36.1177751, 36.5698808],
    [36.1176503, 36.5698809],
    [36.1175014, 36.5699309],
    [36.1173958, 36.5699232],
    [36.1172325, 36.5698229],
    [36.1169026, 36.5696518],
    [36.1159232, 36.5693258],
    [36.115389, 36.56898],
    [36.1143605, 36.5689617],
    [36.1140801, 36.5688082],
    [36.1135914, 36.5684823],
    [36.113181, 36.568413],
    [36.1129189, 36.5683093],
    [36.1124067, 36.5681295],
    [36.1116343, 36.5675742],
    [36.1116744, 36.5673391],
    [36.11132, 36.567084],
    [36.110534, 36.5670691],
    [36.1103072, 36.5668591],
    [36.109754, 36.566404],
    [36.108505, 36.565731],
    [36.107874, 36.565588],
    [36.1071487, 36.5653069],
    [36.106625, 36.564915],
    [36.1059726, 36.5647616],
    [36.1054451, 36.5643952],
    [36.1049918, 36.5642972],
    [36.104119, 36.563827],
    [36.103502, 36.563298],
    [36.1032569, 36.5629638],
    [36.102277, 36.561984],
    [36.10166, 36.561455],
    [36.101347, 36.56132],
    [36.100735, 36.560662],
    [36.100108, 36.56039],
    [36.0998, 36.560126],
    [36.0995184, 36.5600509],
    [36.0991879, 36.5600857],
    [36.0986797, 36.5596833],
    [36.0982855, 36.5593076],
    [36.0978079, 36.5589988],
    [36.097335, 36.5587077],
    [36.0961389, 36.5580237],
    [36.095751, 36.557716],
    [36.0946022, 36.5571397],
    [36.093554, 36.556892],
    [36.0922894, 36.5561174],
    [36.0919423, 36.5560769],
    [36.091153, 36.5557141],
    [36.0901851, 36.5551483],
    [36.0900541, 36.5551641],
    [36.0892802, 36.5547598],
    [36.088552, 36.554458],
    [36.088243, 36.554194],
    [36.087862, 36.5541051],
    [36.0875, 36.5540207],
    [36.0870294, 36.5539054],
    [36.0861397, 36.5534098],
    [36.0856843, 36.5533559],
    [36.0853337, 36.553107],
    [36.0843966, 36.5525188],
    [36.0838761, 36.5521216],
    [36.0833212, 36.5517583],
    [36.0829094, 36.5515261],
    [36.0824949, 36.5514312],
    [36.0822118, 36.5517278],
    [36.0820325, 36.5519446],
    [36.0819814, 36.5519111],
    [36.0821576, 36.5516555],
    [36.0817135, 36.5512896],
    [36.0812957, 36.5510864],
    [36.0811371, 36.5512411],
    [36.0810974, 36.5511956],
    [36.0811767, 36.5510409],
    [36.0808575, 36.5508825],
    [36.0799603, 36.5504952],
    [36.0793214, 36.5500696],
    [36.07823, 36.549582],
    [36.077603, 36.54931],
    [36.0761819, 36.5488107],
    [36.0759007, 36.5489813],
    [36.075402, 36.548615],
    [36.0747728, 36.5482751],
    [36.0736213, 36.5477923],
    [36.072792, 36.54748],
    [36.0720971, 36.5471988],
    [36.0712152, 36.5469188],
    [36.06966, 36.5463552],
    [36.0690249, 36.5462978],
    [36.0678629, 36.5458445],
    [36.0671819, 36.5456952],
    [36.066931, 36.545324],
    [36.0665387, 36.5451192],
    [36.0658104, 36.5447452],
    [36.0653552, 36.5445376],
    [36.063497, 36.54357],
    [36.06287, 36.543298],
    [36.060991, 36.542481],
    [36.0599403, 36.5420893],
    [36.0585151, 36.5413075],
    [36.0580417, 36.5418481],
    [36.05799, 36.5418092],
    [36.0581817, 36.5415904],
    [36.0581467, 36.5415107],
    [36.0578113, 36.5411605],
    [36.0575226, 36.5410374],
    [36.0573789, 36.5411298],
    [36.0567826, 36.5406638],
    [36.0555505, 36.5403537],
    [36.0538654, 36.5396862],
    [36.052224, 36.5388079],
    [36.0513387, 36.5384377],
    [36.0496646, 36.5376147],
    [36.0492469, 36.5378963],
    [36.0487533, 36.5374075],
    [36.0480636, 36.5370052],
    [36.047513, 36.537011],
    [36.047205, 36.536746],
    [36.0464271, 36.5364819],
    [36.0460436, 36.5366826],
    [36.045938, 36.536587],
    [36.0451604, 36.5360332],
    [36.0439444, 36.5357253],
    [36.0437198, 36.5354476],
    [36.0429421, 36.5351627],
    [36.042494, 36.535089],
    [36.0420439, 36.5352032],
    [36.0416087, 36.5348854],
    [36.0411371, 36.5348296],
    [36.0407985, 36.5349219],
    [36.0402603, 36.5345974],
    [36.0396348, 36.5345547],
    [36.0387124, 36.5344546],
    [36.0380954, 36.5341405],
    [36.0374975, 36.5335416],
    [36.0369071, 36.5337413],
    [36.0366389, 36.5330438],
    [36.036024, 36.5325109],
    [36.0357388, 36.5322165],
    [36.0355046, 36.5319749],
    [36.0352608, 36.5314292],
    [36.0352083, 36.5309235],
    [36.0350332, 36.5305223],
    [36.0347794, 36.5289859],
    [36.0346068, 36.5278349],
    [36.0342127, 36.5269025],
    [36.0337133, 36.5258373],
    [36.0329963, 36.5246488],
    [36.0324406, 36.5240257],
    [36.0319314, 36.5236047],
    [36.0315373, 36.5234714],
    [36.0312072, 36.523318],
    [36.0308717, 36.5225436],
    [36.0308026, 36.5219111],
    [36.0305651, 36.5214905],
    [36.0303621, 36.5213193],
    [36.0301689, 36.5204347],
    [36.0297381, 36.5196638],
    [36.0292466, 36.5189686],
    [36.0288045, 36.5184582],
    [36.0283789, 36.5181359],
    [36.0278209, 36.517822],
    [36.027077, 36.517558],
    [36.0261136, 36.5173037],
    [36.0250899, 36.5169464],
    [36.0246363, 36.5164457],
    [36.023933, 36.5161355],
    [36.0226735, 36.515226],
    [36.0219513, 36.5146428],
    [36.021497, 36.514148],
    [36.0209737, 36.5135154],
    [36.020424, 36.513094],
    [36.019808, 36.512565],
    [36.019165, 36.5117417],
    [36.018764, 36.510741],
    [36.0180708, 36.5095595],
    [36.0173041, 36.5083658],
    [36.016514, 36.507217],
    [36.0161661, 36.5068619],
    [36.015372, 36.5059292],
    [36.0147401, 36.5051593],
    [36.013982, 36.5042723],
    [36.0134348, 36.5038118],
    [36.0127077, 36.5030605],
    [36.012241, 36.502487],
    [36.011471, 36.501826],
    [36.011163, 36.501562],
    [36.0103566, 36.5009109],
    [36.0098626, 36.5006893],
    [36.0087882, 36.4999105],
    [36.0079155, 36.4991245],
    [36.0071922, 36.4986276],
    [36.006063, 36.497709],
    [36.005442, 36.497309],
    [36.0049549, 36.4969846],
    [36.0040517, 36.4966139],
    [36.0033618, 36.4964732],
    [36.003235, 36.49665],
    [36.0030791, 36.4969099],
    [36.0030037, 36.4968808],
    [36.0031995, 36.4966178],
    [36.0027949, 36.4962653],
    [36.0022456, 36.4958723],
    [36.0013093, 36.4956991],
    [36.0005931, 36.4956811],
    [35.9998798, 36.4956257],
    [35.9992521, 36.4954122],
    [35.998695, 36.495021],
    [35.998531, 36.494897],
    [35.997889, 36.4941226],
    [35.9974815, 36.4937644],
    [35.9971479, 36.4935434],
    [35.9965482, 36.4929986],
    [35.9964205, 36.4927428],
    [35.9963451, 36.4921685],
    [35.996046, 36.4912123],
    [35.99582, 36.490599],
    [35.9953488, 36.4889023],
    [35.995106, 36.488563],
    [35.994768, 36.488058],
    [35.9943676, 36.4875099],
    [35.9940902, 36.4871201],
    [35.9939618, 36.4868489],
    [35.9935915, 36.4863251],
    [35.993192, 36.4858108],
    [35.9926642, 36.4852129],
    [35.9920388, 36.4845013],
    [35.9914783, 36.4838817],
    [35.9910463, 36.4833456],
    [35.9906548, 36.4830616],
    [35.9900591, 36.4826649],
    [35.989967, 36.4825637],
    [35.9897809, 36.4826166],
    [35.9890046, 36.4817146],
    [35.988619, 36.481149],
    [35.988455, 36.481025],
    [35.988117, 36.48052],
    [35.987442, 36.479509],
    [35.986825, 36.4786066],
    [35.986594, 36.4783083],
    [35.9862084, 36.4778449],
    [35.9856876, 36.47728],
    [35.9848697, 36.476361],
    [35.9844132, 36.4759984],
    [35.9841066, 36.4757548],
    [35.983537, 36.4752548],
    [35.9830258, 36.4747623],
    [35.9829108, 36.474625],
    [35.982797, 36.474489],
    [35.9813506, 36.4735222],
    [35.9809508, 36.4735635],
    [35.9806659, 36.4734677],
    [35.9805171, 36.4736224],
    [35.9796454, 36.4732594],
    [35.9793916, 36.4732469],
    [35.9785688, 36.4728101],
    [35.9781608, 36.4725358],
    [35.976827, 36.472198],
    [35.9764367, 36.472002],
    [35.9755846, 36.4716777],
    [35.975372, 36.471592],
    [35.9753387, 36.4715822],
    [35.9750639, 36.4718841],
    [35.9750255, 36.4718547],
    [35.9752395, 36.4715339],
    [35.974908, 36.4714297],
    [35.9747267, 36.4717984],
    [35.9747445, 36.4718602],
    [35.9749807, 36.4719929],
    [35.9749404, 36.4720489],
    [35.9746046, 36.4718706],
    [35.9744848, 36.4719522],
    [35.9744198, 36.4719006],
    [35.9745977, 36.4718012],
    [35.9743101, 36.471576],
    [35.9732559, 36.4711776],
    [35.9727191, 36.4712663],
    [35.972018, 36.471162],
    [35.9708306, 36.470581],
    [35.9699427, 36.47018],
    [35.9691391, 36.469806],
    [35.9686455, 36.4698393],
    [35.9680388, 36.4694403],
    [35.9674119, 36.4691243],
    [35.9665071, 36.4688691],
    [35.9658765, 36.4686468],
    [35.9658017, 36.4685019],
    [35.9657201, 36.4683635],
    [35.9653418, 36.4681922],
    [35.9651558, 36.4681865],
    [35.96498, 36.4683873],
    [35.9649289, 36.4683649],
    [35.9650925, 36.4681576],
    [35.9650907, 36.4680851],
    [35.9649869, 36.4679377],
    [35.9647167, 36.4677623],
    [35.9644521, 36.4677016],
    [35.9642294, 36.4679409],
    [35.9641895, 36.4679205],
    [35.9643979, 36.4676756],
    [35.9642628, 36.4675164],
    [35.9636312, 36.4672704],
    [35.9635624, 36.4673651],
    [35.9633557, 36.4675182],
    [35.9633104, 36.4675036],
    [35.9634174, 36.467387],
    [35.9634446, 36.46725],
    [35.962835, 36.4669936],
    [35.961988, 36.46666],
    [35.9614805, 36.466579],
    [35.9610231, 36.4664689],
    [35.96038, 36.466186],
    [35.95909, 36.465705],
    [35.9576967, 36.4656258],
    [35.9570882, 36.4658585],
    [35.9550902, 36.4659172],
    [35.954633, 36.465558],
    [35.953235, 36.4648605],
    [35.9525887, 36.4646544],
    [35.9520023, 36.4646307],
    [35.9518717, 36.4650772],
    [35.9518702, 36.4654967],
    [35.9517247, 36.4655368],
    [35.9517428, 36.465094],
    [35.9517899, 36.4647874],
    [35.9510877, 36.4643035],
    [35.9505393, 36.4640545],
    [35.949462, 36.463375],
    [35.948817, 36.463134],
    [35.94849, 36.462885],
    [35.94721, 36.46266],
    [35.947045, 36.462536],
    [35.946882, 36.462411],
    [35.946237, 36.46217],
    [35.94591, 36.461922],
    [35.944947, 36.461689],
    [35.944783, 36.461565],
    [35.944446, 36.461059],
    [35.943955, 36.460686],
    [35.942665, 36.460204],
    [35.942338, 36.459955],
    [35.941693, 36.459714],
    [35.9411616, 36.4596217],
    [35.9403201, 36.459382],
    [35.9393468, 36.4591407],
    [35.9376289, 36.4590683],
    [35.9373724, 36.4592706],
    [35.9360024, 36.4591933],
    [35.9352899, 36.459608],
    [35.9352399, 36.4598303],
    [35.934438, 36.4600016],
    [35.93392, 36.4596766],
    [35.933107, 36.459669],
    [35.932799, 36.459933],
    [35.932481, 36.459941],
    [35.931841, 36.459829],
    [35.9314755, 36.4594367],
    [35.9306779, 36.4590707],
    [35.9305139, 36.4589467],
    [35.9302396, 36.4581277],
    [35.9296694, 36.4573833],
    [35.929496, 36.456676],
    [35.9292077, 36.456302],
    [35.9289058, 36.454932],
    [35.9282439, 36.4534247],
    [35.927589, 36.452485],
    [35.927253, 36.45198],
    [35.926598, 36.451482],
    [35.9254422, 36.450234],
    [35.924942, 36.449725],
    [35.923798, 36.448854],
    [35.923162, 36.448869],
    [35.922517, 36.448628],
    [35.921536, 36.447882],
    [35.920901, 36.447897],
    [35.920573, 36.447648],
    [35.919928, 36.447407],
    [35.918475, 36.446801],
    [35.9179722, 36.4464543],
    [35.917811, 36.446047],
    [35.917023, 36.445136],
    [35.9167668, 36.4449777],
    [35.916329, 36.44467],
    [35.9149164, 36.4438667],
    [35.91374, 36.443449],
    [35.913095, 36.443208],
    [35.911825, 36.443239],
    [35.911199, 36.443511],
    [35.9104965, 36.4438977],
    [35.9099249, 36.4444524],
    [35.9093675, 36.4445287],
    [35.909005, 36.444335],
    [35.908841, 36.444211],
    [35.9084261, 36.4436617],
    [35.908019, 36.443461],
    [35.90783, 36.442695],
    [35.907429, 36.4423167],
    [35.907102, 36.441376],
    [35.907109, 36.440401],
    [35.906782, 36.440152],
    [35.9062727, 36.4396217],
    [35.905638, 36.439281],
    [35.904675, 36.439047],
    [35.903395, 36.438822],
    [35.902751, 36.43858],
    [35.902433, 36.438588],
    [35.901803, 36.438732],
    [35.901942, 36.438215],
    [35.90225, 36.437951],
    [35.90224, 36.437694],
    [35.901586, 36.437197],
    [35.901221, 36.435921],
    [35.9007807, 36.430019],
    [35.900664, 36.429514],
    [35.9006076, 36.427998],
    [35.90054, 36.426178],
    [35.900155, 36.42439],
    [35.899305, 36.422869],
    [35.8988808, 36.4214247],
    [35.898752, 36.420828],
    [35.898059, 36.419304],
    [35.8978224, 36.4189234],
    [35.8977899, 36.4188586],
    [35.897377, 36.418036],
    [35.8966823, 36.4172501],
    [35.89605, 36.416527],
    [35.8952464, 36.4156994],
    [35.8949813, 36.4154505],
    [35.8946773, 36.4152071],
    [35.8942792, 36.4149352],
    [35.8939218, 36.4147037],
    [35.8935195, 36.4144746],
    [35.8932119, 36.4143292],
    [35.8929498, 36.4142239],
    [35.8928018, 36.4141644],
    [35.8923868, 36.4140333],
    [35.8920926, 36.4139546],
    [35.8919512, 36.4139311],
    [35.8917843, 36.4139244],
    [35.8916461, 36.4139256],
    [35.8914282, 36.4139368],
    [35.891276, 36.4139551],
    [35.8911205, 36.4139866],
    [35.8909417, 36.4140245],
    [35.8908169, 36.4140426],
    [35.8906823, 36.4140399],
    [35.8904012, 36.4140243],
    [35.8900805, 36.4139918],
    [35.8898691, 36.4139502],
    [35.8895846, 36.4138793],
    [35.8893612, 36.4138123],
    [35.8890679, 36.4137192],
    [35.8888436, 36.4136247],
    [35.8886803, 36.4135596],
    [35.8883875, 36.4134436],
    [35.8882957, 36.4134009],
    [35.8882314, 36.4133616],
    [35.8880432, 36.4131912],
    [35.8875532, 36.4126736],
    [35.8874086, 36.4127004],
    [35.8871592, 36.4127431],
    [35.8879524, 36.4137653],
    [35.8879317, 36.413773],
    [35.887661, 36.4136742],
    [35.8875881, 36.4136615],
    [35.8875491, 36.4136546],
    [35.8875113, 36.4136412],
    [35.8874774, 36.4136194],
    [35.886814, 36.4135622],
    [35.8866101, 36.4135538],
    [35.8861312, 36.4135494],
    [35.8860102, 36.4135678],
    [35.8859176, 36.4135862],
    [35.8859449, 36.413709],
    [35.8858943, 36.4137235],
    [35.8858499, 36.4136019],
    [35.8856985, 36.4136371],
    [35.8857851, 36.4138742],
    [35.8856992, 36.4138962],
    [35.8856311, 36.413716],
    [35.8853352, 36.4138067],
    [35.8851979, 36.4138507],
    [35.885085, 36.413899],
    [35.8849721, 36.4139644],
    [35.8848055, 36.4140615],
    [35.8846677, 36.4141543],
    [35.884522, 36.4142569],
    [35.8844968, 36.4142963],
    [35.8844998, 36.4143358],
    [35.8846121, 36.4145314],
    [35.8847388, 36.4147567],
    [35.884911, 36.4149665],
    [35.884971, 36.4149456],
    [35.885061, 36.4150685],
    [35.8849716, 36.4151115],
    [35.8850044, 36.4151634],
    [35.8849644, 36.4151834],
    [35.8850848, 36.4153967],
    [35.8850616, 36.4154731],
    [35.8856678, 36.4165065],
    [35.8855655, 36.4165456],
    [35.8852393, 36.4160056],
    [35.8852614, 36.4159903],
    [35.8849566, 36.4154795],
    [35.8848794, 36.4154827],
    [35.8845503, 36.415015],
    [35.8844055, 36.4146987],
    [35.8842422, 36.4147035],
    [35.8841225, 36.4147167],
    [35.8840446, 36.4147336],
    [35.8836223, 36.4148762],
    [35.883395, 36.4149624],
    [35.8831832, 36.4150572],
    [35.8828314, 36.4152425],
    [35.8824843, 36.415439],
    [35.8820551, 36.4156896],
    [35.8819336, 36.4157709],
    [35.881806, 36.4158634],
    [35.881742, 36.4159113],
    [35.8816873, 36.4159645],
    [35.8816493, 36.4160227],
    [35.8816355, 36.4160854],
    [35.881647, 36.4161448],
    [35.8816574, 36.416184],
    [35.8816307, 36.4162116],
    [35.8815821, 36.4162412],
    [35.881529, 36.4162882],
    [35.8814724, 36.4163179],
    [35.8813838, 36.4163428],
    [35.8812804, 36.4163606],
    [35.8812012, 36.4163504],
    [35.8811083, 36.4163185],
    [35.8810222, 36.4162983],
    [35.8809933, 36.4162771],
    [35.8809587, 36.4162404],
    [35.8808836, 36.4161879],
    [35.8807964, 36.4161532],
    [35.8806824, 36.4161229],
    [35.8805494, 36.4160695],
    [35.8803682, 36.4160161],
    [35.8802816, 36.4159777],
    [35.8802294, 36.4159255],
    [35.8801836, 36.4158543],
    [35.8801769, 36.4157885],
    [35.8802055, 36.4157341],
    [35.8802188, 36.4156327],
    [35.8802063, 36.4155379],
    [35.8801893, 36.4154689],
    [35.8801379, 36.4153891],
    [35.8800801, 36.4153211],
    [35.8799793, 36.4152143],
    [35.8799173, 36.4151438],
    [35.8798769, 36.4150733],
    [35.8798281, 36.4149798],
    [35.8797767, 36.4149208],
    [35.8796786, 36.4148331],
    [35.8795697, 36.4147691],
    [35.8794217, 36.4146947],
    [35.8793113, 36.4146548],
    [35.8790959, 36.4146226],
    [35.8788833, 36.4145926],
    [35.878719, 36.4145654],
    [35.8785456, 36.4144871],
    [35.8784401, 36.4144436],
    [35.878399, 36.4144152],
    [35.8783617, 36.4143702],
    [35.8783533, 36.414327],
    [35.8783694, 36.4142817],
    [35.8783885, 36.414191],
    [35.878391, 36.4141041],
    [35.8783592, 36.4139111],
    [35.8783218, 36.413752],
    [35.8782736, 36.4136101],
    [35.8782283, 36.4135093],
    [35.8781509, 36.4133999],
    [35.8780925, 36.413344],
    [35.8779991, 36.4132733],
    [35.8778259, 36.4131873],
    [35.8776976, 36.4131481],
    [35.8776254, 36.41315],
    [35.8775624, 36.4131661],
    [35.8775209, 36.4131539],
    [35.8774726, 36.4131076],
    [35.8774137, 36.4130138],
    [35.8772863, 36.4125665],
    [35.877137, 36.412105],
    [35.8769505, 36.4118242],
    [35.876801, 36.411599],
    [35.876474, 36.41135],
    [35.8759237, 36.4102184],
    [35.8754825, 36.4100405],
    [35.8753185, 36.4099165],
    [35.8750328, 36.409269],
    [35.874773, 36.408309],
    [35.8744408, 36.406773],
    [35.8738228, 36.40666],
    [35.8736598, 36.406536],
    [35.8736398, 36.406023],
    [35.8737719, 36.4056022],
    [35.8734704, 36.4052431],
    [35.8731171, 36.4052421],
    [35.8725998, 36.4046235],
    [35.871592, 36.403891],
    [35.870948, 36.403649],
    [35.8703215, 36.403627],
    [35.8701237, 36.4033558],
    [35.8698005, 36.4031136],
    [35.8697913, 36.4026177],
    [35.870102, 36.402257],
    [35.869765, 36.4015574],
    [35.8693325, 36.4011978],
    [35.86942, 36.40099],
    [35.8691201, 36.4008558],
    [35.8686709, 36.4009475],
    [35.8687391, 36.4007142],
    [35.8685364, 36.4005279],
    [35.8679866, 36.3995974],
    [35.8671615, 36.399114],
    [35.8668345, 36.398865],
    [35.8666705, 36.398741],
    [35.866459, 36.398236],
    [35.8660963, 36.3979435],
    [35.865931, 36.3978354],
    [35.8656348, 36.3977162],
    [35.8653033, 36.3975714],
    [35.8650689, 36.3975596],
    [35.8649149, 36.3976413],
    [35.8648323, 36.3977134],
    [35.8646428, 36.3978659],
    [35.8643845, 36.39801],
    [35.8641606, 36.397791],
    [35.8635375, 36.3975966],
    [35.8633917, 36.3973438],
    [35.8630006, 36.396977],
    [35.8624814, 36.3966312],
    [35.86197, 36.3963909],
    [35.8616981, 36.3964918],
    [35.8612375, 36.3964215],
    [35.8610917, 36.3960084],
    [35.8609126, 36.3957588],
    [35.8610297, 36.3952154],
    [35.8611262, 36.3946055],
    [35.8609987, 36.3938541],
    [35.8608127, 36.3935547],
    [35.8605613, 36.3930695],
    [35.8604649, 36.3925122],
    [35.8604511, 36.392307],
    [35.8604545, 36.3919466],
    [35.8603202, 36.3917331],
    [35.8600412, 36.391686],
    [35.8601273, 36.3915723],
    [35.8601893, 36.3914697],
    [35.8602616, 36.3913588],
    [35.8603443, 36.3911897],
    [35.860396, 36.39043],
    [35.860425, 36.389648],
    [35.8604787, 36.3870888],
    [35.8604454, 36.3866967],
    [35.8603833, 36.3859223],
    [35.860352, 36.3854559],
    [35.8603653, 36.3847952],
    [35.8603653, 36.3841076],
    [35.860412, 36.3834469],
    [35.8601051, 36.3823753],
    [35.8597314, 36.3816796],
    [35.8594979, 36.3813331],
    [35.8595012, 36.3807315],
    [35.859443, 36.380168],
    [35.8592944, 36.3788433],
    [35.85906, 36.378379],
    [35.8587906, 36.3778065],
    [35.8584136, 36.3768717],
    [35.8582001, 36.376082],
    [35.8579633, 36.3754776],
    [35.8577464, 36.3751096],
    [35.8575763, 36.3747765],
    [35.8574662, 36.3745778],
    [35.8573327, 36.3742151],
    [35.8571492, 36.3736269],
    [35.8569791, 36.3731595],
    [35.8566655, 36.3726733],
    [35.8563639, 36.3723212],
    [35.8560662, 36.3720132],
    [35.8555601, 36.371263],
    [35.8548406, 36.3703147],
    [35.854517, 36.3699951],
    [35.853791, 36.3694678],
    [35.853628, 36.3693428],
    [35.853164, 36.369145],
    [35.85252, 36.368904],
    [35.8520149, 36.3686116],
    [35.8515278, 36.3681764],
    [35.8512642, 36.367975],
    [35.8508739, 36.3678165],
    [35.8501666, 36.3675129],
    [35.8497029, 36.3674511],
    [35.8492358, 36.3674968],
    [35.8487187, 36.3675774],
    [35.8484885, 36.367572],
    [35.8480481, 36.3674995],
    [35.8473576, 36.3673974],
    [35.846743, 36.367244],
    [35.8452685, 36.3669488],
    [35.8442115, 36.3668144],
    [35.8435577, 36.3667553],
    [35.842827, 36.3666371],
    [35.8424749, 36.3665505],
    [35.8422265, 36.3664894],
    [35.8418295, 36.3663174],
    [35.841279, 36.3661428],
    [35.8403382, 36.365901],
    [35.8394441, 36.3656996],
    [35.8377694, 36.365482],
    [35.8372122, 36.3654363],
    [35.8355141, 36.3654927],
    [35.8336959, 36.3656378],
    [35.8328452, 36.3656458],
    [35.8320345, 36.365619],
    [35.8311871, 36.3654336],
    [35.8305666, 36.3652482],
    [35.829497, 36.364572],
    [35.8291354, 36.3644718],
    [35.8288017, 36.3642977],
    [35.8287284, 36.3641871],
    [35.8285349, 36.3640716],
    [35.8283447, 36.3640393],
    [35.8281779, 36.3639319],
    [35.8280444, 36.363819],
    [35.827709, 36.363459],
    [35.8271904, 36.3632307],
    [35.8265865, 36.3629432],
    [35.8257125, 36.3623038],
    [35.8254222, 36.3620674],
    [35.8251386, 36.361651],
    [35.8249718, 36.3614629],
    [35.824617, 36.361348],
    [35.823329, 36.360865],
    [35.8226226, 36.3608151],
    [35.8222151, 36.3610303],
    [35.8218614, 36.361074],
    [35.8216155, 36.3610453],
    [35.8215388, 36.3607397],
    [35.821238, 36.3604104],
    [35.8207695, 36.3601627],
    [35.8200854, 36.3600021],
    [35.8197551, 36.3603647],
    [35.8195631, 36.360255],
    [35.8180232, 36.3593347],
    [35.8175318, 36.3590153],
    [35.817185, 36.358858],
    [35.8170323, 36.3587827],
    [35.8168629, 36.3586992],
    [35.8172087, 36.3583427],
    [35.8173509, 36.3580928],
    [35.8173283, 36.3576869],
    [35.8172022, 36.357359],
    [35.8170891, 36.356953],
    [35.8169631, 36.3561775],
    [35.8168759, 36.3554696],
    [35.8168274, 36.3551599],
    [35.8166529, 36.3548372],
    [35.8163782, 36.3542074],
    [35.8163233, 36.3539368],
    [35.8163039, 36.3534345],
    [35.8163718, 36.3531534],
    [35.8164525, 36.3530077],
    [35.8165753, 36.3527344],
    [35.8167111, 36.3523388],
    [35.8166497, 36.352076],
    [35.8165107, 36.3518313],
    [35.8162167, 36.3513863],
    [35.8159646, 36.3511495],
    [35.8156996, 36.3508163],
    [35.8154702, 36.3504181],
    [35.8152569, 36.3503244],
    [35.8152278, 36.3502229],
    [35.8152343, 36.3501448],
    [35.8152795, 36.3500381],
    [35.8153377, 36.3498872],
    [35.81546, 36.349622],
    [35.815454, 36.3492339],
    [35.8151244, 36.3481616],
    [35.8149402, 36.3478753],
    [35.8144749, 36.3474303],
    [35.814099, 36.347085],
    [35.8135637, 36.3462955],
    [35.8130951, 36.3457359],
    [35.812843, 36.3455459],
    [35.8123777, 36.3451581],
    [35.8121644, 36.3449368],
    [35.8117573, 36.34451],
    [35.8113404, 36.3441456],
    [35.8109074, 36.3438567],
    [35.81048, 36.343575],
    [35.8100866, 36.3432918],
    [35.8096892, 36.3429405],
    [35.8094339, 36.3427504],
    [35.8090106, 36.3424563],
    [35.808626, 36.3421778],
    [35.8084128, 36.3418733],
    [35.8082253, 36.3413267],
    [35.8077632, 36.3402048],
    [35.8073302, 36.3396712],
    [35.8068908, 36.339325],
    [35.8067001, 36.3391792],
    [35.8063543, 36.3390335],
    [35.8061443, 36.338945],
    [35.805595, 36.3386821],
    [35.8050876, 36.3385155],
    [35.8046675, 36.33844],
    [35.8042119, 36.3384478],
    [35.804018, 36.3384868],
    [35.8037272, 36.3385155],
    [35.8034913, 36.3384998],
    [35.8032651, 36.3384192],
    [35.8028127, 36.3382369],
    [35.8024411, 36.3381276],
    [35.8021535, 36.3380001],
    [35.8020178, 36.337758],
    [35.8019596, 36.3376408],
    [35.801882, 36.3374977],
    [35.8017786, 36.3372894],
    [35.8014361, 36.3371879],
    [35.8013327, 36.3371879],
    [35.8012002, 36.3371775],
    [35.801016, 36.3371463],
    [35.8006541, 36.3370005],
    [35.8005119, 36.3368339],
    [35.8004376, 36.3366933],
    [35.8003697, 36.3365554],
    [35.8003116, 36.336243],
    [35.800024, 36.3356755],
    [35.7997138, 36.3353632],
    [35.7993809, 36.3350768],
    [35.7987766, 36.334767],
    [35.79825, 36.334487],
    [35.7978557, 36.3343453],
    [35.797274, 36.3342282],
    [35.796655, 36.334267],
    [35.7960687, 36.3342178],
    [35.795752, 36.3341813],
    [35.7954677, 36.3340746],
    [35.7952156, 36.3339444],
    [35.7948569, 36.33377],
    [35.7944271, 36.3335071],
    [35.793978, 36.3333717],
    [35.793763, 36.333307],
    [35.793461, 36.333127],
    [35.7928858, 36.3328095],
    [35.79263, 36.332692],
    [35.7924172, 36.3325648],
    [35.7923784, 36.3324997],
    [35.7923881, 36.332406],
    [35.7922007, 36.3319113],
    [35.7918646, 36.3315287],
    [35.7916481, 36.3313855],
    [35.7915189, 36.3312657],
    [35.7913573, 36.3309377],
    [35.7911731, 36.3306878],
    [35.7908532, 36.3304197],
    [35.790482, 36.330302],
    [35.7898805, 36.3301854],
    [35.7895929, 36.3300682],
    [35.789412, 36.330063],
    [35.7892149, 36.3299979],
    [35.788877, 36.329826],
    [35.788551, 36.329576],
    [35.787908, 36.329335],
    [35.7877543, 36.3291675],
    [35.7877833, 36.3289436],
    [35.7877607, 36.3287327],
    [35.7880257, 36.3285479],
    [35.788142, 36.3281262],
    [35.7879869, 36.3278658],
    [35.7878383, 36.3276992],
    [35.7876347, 36.3275196],
    [35.787415, 36.3273399],
    [35.7871435, 36.3271889],
    [35.7870886, 36.32709],
    [35.7870821, 36.3269807],
    [35.7871144, 36.3268765],
    [35.7870143, 36.3264652],
    [35.7869205, 36.3262882],
    [35.7869109, 36.3261163],
    [35.786746, 36.3259159],
    [35.786662, 36.3258586],
    [35.786675, 36.3257675],
    [35.7867331, 36.3256347],
    [35.7865909, 36.325278],
    [35.786271, 36.3246975],
    [35.7862226, 36.3243694],
    [35.7860028, 36.3240101],
    [35.7857152, 36.3234113],
    [35.7856183, 36.3232551],
    [35.7855019, 36.323039],
    [35.7853178, 36.3228307],
    [35.7851368, 36.3226303],
    [35.785014, 36.3225444],
    [35.7847716, 36.3224428],
    [35.7841674, 36.3223361],
    [35.7839767, 36.3222996],
    [35.7838055, 36.3223152],
    [35.7836051, 36.3223699],
    [35.78345, 36.3223439],
    [35.7832787, 36.3223074],
    [35.7829427, 36.3223465],
    [35.7827262, 36.3222918],
    [35.7825484, 36.3223595],
    [35.7823255, 36.3223283],
    [35.7821994, 36.3222892],
    [35.781939, 36.322154],
    [35.7814077, 36.3215654],
    [35.78114, 36.321542],
    [35.7808667, 36.3216778],
    [35.7807434, 36.3218766],
    [35.7803839, 36.321963],
    [35.7801962, 36.3218766],
    [35.7800932, 36.3217148],
  ],
  Kahramanmaraş: [
    [36.2018762, 37.9349539],
    [36.205197, 37.9369252],
    [36.2104117, 37.9370334],
    [36.2153919, 37.9399797],
    [36.2353571, 37.9466038],
    [36.2679566, 37.9220816],
    [36.2923057, 37.906644],
    [36.2928033, 37.8943859],
    [36.2832378, 37.8828419],
    [36.28266, 37.8678701],
    [36.2709921, 37.8447965],
    [36.2694592, 37.816237],
    [36.2638856, 37.8055434],
    [36.2611416, 37.7964598],
    [36.2555253, 37.7898151],
    [36.2684122, 37.7778333],
    [36.2698274, 37.7720868],
    [36.2699156, 37.7651448],
    [36.2672971, 37.7556632],
    [36.2611518, 37.7516016],
    [36.2606669, 37.7497797],
    [36.2603175, 37.7476991],
    [36.2602646, 37.7460634],
    [36.2592802, 37.7447477],
    [36.2588399, 37.7440168],
    [36.2582598, 37.7438905],
    [36.2574023, 37.7439304],
    [36.2548686, 37.7433386],
    [36.2541636, 37.7441012],
    [36.2529214, 37.7436512],
    [36.2516738, 37.7422323],
    [36.2507608, 37.7423482],
    [36.2503377, 37.7418231],
    [36.2502428, 37.7407676],
    [36.2485778, 37.7401378],
    [36.2475489, 37.7387175],
    [36.2461841, 37.7373764],
    [36.2451714, 37.7365605],
    [36.2428983, 37.7347914],
    [36.2418881, 37.7324515],
    [36.2406613, 37.7318807],
    [36.2391098, 37.7326512],
    [36.2382943, 37.7324387],
    [36.2376039, 37.7316242],
    [36.2367284, 37.7314527],
    [36.2352102, 37.731553],
    [36.2336051, 37.7315976],
    [36.2328106, 37.730726],
    [36.231641, 37.7300259],
    [36.2314245, 37.7294837],
    [36.2318214, 37.7286846],
    [36.2298008, 37.7279712],
    [36.228105, 37.7271721],
    [36.2274195, 37.7266585],
    [36.227636, 37.725945],
    [36.2276721, 37.7256596],
    [36.2271308, 37.725203],
    [36.227203, 37.7246893],
    [36.2261566, 37.7245466],
    [36.2250381, 37.7248891],
    [36.224966, 37.7256596],
    [36.2237753, 37.7262875],
    [36.222296, 37.7260306],
    [36.222332, 37.725203],
    [36.2208527, 37.7240329],
    [36.2187239, 37.7221208],
    [36.2181827, 37.7212075],
    [36.2186157, 37.7196663],
    [36.2161622, 37.7178397],
    [36.2146467, 37.7181822],
    [36.2134921, 37.7179824],
    [36.2130953, 37.7172118],
    [36.2130231, 37.7157561],
    [36.21342, 37.7147571],
    [36.2461241, 37.6849913],
    [36.2659868, 37.6685076],
    [36.2662638, 37.6584109],
    [36.2623224, 37.6440565],
    [36.2562991, 37.6285932],
    [36.2553334, 37.6275137],
    [36.2548292, 37.6265281],
    [36.2555802, 37.6259927],
    [36.2569857, 37.6259417],
    [36.2572431, 37.625058],
    [36.2565994, 37.6241318],
    [36.2563091, 37.623153],
    [36.2563527, 37.6226957],
    [36.2570178, 37.6222113],
    [36.2572539, 37.6218544],
    [36.2578761, 37.621387],
    [36.2578976, 37.6210641],
    [36.2574792, 37.6203757],
    [36.2575757, 37.6200613],
    [36.257844, 37.6199253],
    [36.2583867, 37.6200546],
    [36.2587666, 37.6197639],
    [36.2596266, 37.6194899],
    [36.2603499, 37.6177986],
    [36.2614703, 37.6171124],
    [36.2621871, 37.6164722],
    [36.2625248, 37.6157835],
    [36.2623715, 37.6153957],
    [36.261642, 37.6151408],
    [36.2606254, 37.6151797],
    [36.2599683, 37.6147668],
    [36.2598232, 37.6142401],
    [36.2600744, 37.6131336],
    [36.260848, 37.6123022],
    [36.2608875, 37.6114936],
    [36.260612, 37.6110613],
    [36.2601088, 37.6108693],
    [36.2581192, 37.6104869],
    [36.257683, 37.6100244],
    [36.2576715, 37.6093025],
    [36.2583482, 37.6087156],
    [36.2588961, 37.6078668],
    [36.2592709, 37.6069392],
    [36.2591841, 37.6062687],
    [36.2585934, 37.6054399],
    [36.2577796, 37.6052647],
    [36.2574146, 37.6049488],
    [36.2575221, 37.6046528],
    [36.2579046, 37.6043485],
    [36.2591728, 37.603703],
    [36.259979, 37.6030293],
    [36.260376, 37.6022643],
    [36.2603793, 37.6016406],
    [36.2599683, 37.6010742],
    [36.258198, 37.6005047],
    [36.2570781, 37.5991642],
    [36.2552872, 37.596572],
    [36.2494012, 37.5922225],
    [36.2469378, 37.5798596],
    [36.2543488, 37.5678932],
    [36.2512207, 37.5662589],
    [36.2510866, 37.5644004],
    [36.24949, 37.5628517],
    [36.2494983, 37.5621029],
    [36.2514055, 37.5591894],
    [36.2463834, 37.5508194],
    [36.2459613, 37.5438025],
    [36.2433917, 37.5411439],
    [36.2435159, 37.5391211],
    [36.2404358, 37.5372099],
    [36.2391222, 37.532937],
    [36.2376566, 37.5307069],
    [36.2386826, 37.5295505],
    [36.2371835, 37.5246094],
    [36.2365591, 37.5202859],
    [36.2374231, 37.5194614],
    [36.2386001, 37.518853],
    [36.2394132, 37.5181108],
    [36.2389905, 37.5157863],
    [36.2400307, 37.5128323],
    [36.2409002, 37.510612],
    [36.2416538, 37.5100405],
    [36.2427405, 37.5100145],
    [36.2429539, 37.509607],
    [36.242896, 37.5090683],
    [36.241778, 37.5076363],
    [36.2408182, 37.5057436],
    [36.2426132, 37.504781],
    [36.2442773, 37.5045249],
    [36.245312, 37.5038443],
    [36.2466003, 37.5017399],
    [36.2459002, 37.4999994],
    [36.2455058, 37.4990187],
    [36.2448621, 37.4972993],
    [36.245085, 37.4964018],
    [36.246033, 37.496225],
    [36.247952, 37.4970439],
    [36.2512409, 37.4970842],
    [36.2514496, 37.4965672],
    [36.2512085, 37.4951308],
    [36.2499046, 37.4928048],
    [36.247952, 37.4899785],
    [36.2451814, 37.4871933],
    [36.245377, 37.4860795],
    [36.2468147, 37.4841555],
    [36.2476896, 37.4825579],
    [36.2488511, 37.481236],
    [36.2488961, 37.480767],
    [36.2473726, 37.4786385],
    [36.2454343, 37.4773747],
    [36.2451625, 37.4760161],
    [36.2455702, 37.4755733],
    [36.2478018, 37.4749603],
    [36.2495398, 37.4746027],
    [36.2520504, 37.4737853],
    [36.2531564, 37.4727726],
    [36.2534451, 37.4708221],
    [36.2533034, 37.4691502],
    [36.2543034, 37.4679269],
    [36.2548358, 37.4662285],
    [36.254797, 37.464742],
    [36.2565432, 37.4626959],
    [36.2584877, 37.4613527],
    [36.2589206, 37.4609706],
    [36.2578257, 37.4561266],
    [36.2565666, 37.4556211],
    [36.2557004, 37.4556949],
    [36.2549327, 37.4553178],
    [36.2543385, 37.4547668],
    [36.2539172, 37.453279],
    [36.2541332, 37.4526084],
    [36.2521601, 37.4502485],
    [36.2520343, 37.4492739],
    [36.2526205, 37.4483868],
    [36.2548107, 37.4464578],
    [36.2574409, 37.4458518],
    [36.2578752, 37.4452756],
    [36.2576451, 37.4445926],
    [36.2554915, 37.4433549],
    [36.255632, 37.4421106],
    [36.2560143, 37.4413061],
    [36.2545786, 37.4389845],
    [36.2524657, 37.437502],
    [36.2519524, 37.4366596],
    [36.252406, 37.4356408],
    [36.254041, 37.4353318],
    [36.2550927, 37.4357597],
    [36.2555759, 37.4364731],
    [36.2564819, 37.4366633],
    [36.2575484, 37.435236],
    [36.2601835, 37.4328446],
    [36.26056, 37.4318935],
    [36.2599468, 37.4315231],
    [36.2592069, 37.4303782],
    [36.2576276, 37.4290752],
    [36.2526415, 37.4260808],
    [36.2519706, 37.4252663],
    [36.2520712, 37.4244735],
    [36.2522691, 37.4239969],
    [36.2540339, 37.4243607],
    [36.2560877, 37.4249638],
    [36.2570251, 37.4249308],
    [36.2578844, 37.4245901],
    [36.2579413, 37.4237613],
    [36.2573283, 37.4229254],
    [36.2555316, 37.4218043],
    [36.2551269, 37.4206418],
    [36.2546652, 37.4194851],
    [36.2544193, 37.4182779],
    [36.2530505, 37.4173877],
    [36.2526228, 37.4160966],
    [36.2531561, 37.4154332],
    [36.2554003, 37.4154785],
    [36.2571144, 37.4164704],
    [36.2596696, 37.417227],
    [36.2613892, 37.4187491],
    [36.2619674, 37.4188646],
    [36.2624174, 37.4186809],
    [36.2627869, 37.4178977],
    [36.2635018, 37.4150698],
    [36.2621281, 37.4120861],
    [36.2616853, 37.410309],
    [36.2653911, 37.4089435],
    [36.2666553, 37.4076548],
    [36.26485, 37.4049713],
    [36.2632829, 37.4043512],
    [36.2620096, 37.4027965],
    [36.2580482, 37.3986259],
    [36.2574289, 37.3975776],
    [36.2570543, 37.3968021],
    [36.2598144, 37.394748],
    [36.2627088, 37.3932502],
    [36.265912, 37.3728857],
    [36.2734458, 37.3587707],
    [36.2851119, 37.3526451],
    [36.2940384, 37.3567386],
    [36.3022781, 37.3613778],
    [36.3096595, 37.3639701],
    [36.3192726, 37.3656073],
    [36.3273406, 37.365198],
    [36.3356905, 37.3644025],
    [36.3422086, 37.3577723],
    [36.3465667, 37.3591947],
    [36.3525218, 37.3645373],
    [36.357553, 37.3635608],
    [36.361673, 37.3591947],
    [36.367166, 37.351144],
    [36.3759209, 37.3477325],
    [36.3881674, 37.352621],
    [36.4004684, 37.3522357],
    [36.4039017, 37.3520991],
    [36.4075229, 37.3553112],
    [36.4125706, 37.3572843],
    [36.4129997, 37.3604908],
    [36.4119189, 37.363268],
    [36.4126931, 37.3648283],
    [36.4141686, 37.3658662],
    [36.4171196, 37.3665075],
    [36.4183501, 37.3662384],
    [36.4196945, 37.3658118],
    [36.4211536, 37.3644475],
    [36.4208042, 37.3631269],
    [36.4212394, 37.3626739],
    [36.4232994, 37.362401],
    [36.4262969, 37.3629538],
    [36.4277625, 37.3651979],
    [36.4281822, 37.366365],
    [36.4304666, 37.3689051],
    [36.4421822, 37.3687951],
    [36.4436936, 37.3686987],
    [36.4458327, 37.3692488],
    [36.447802, 37.3690415],
    [36.4488221, 37.3685705],
    [36.4509664, 37.3696058],
    [36.4538056, 37.3692279],
    [36.4553941, 37.3697358],
    [36.4561629, 37.370352],
    [36.4563731, 37.3708792],
    [36.4569114, 37.3712982],
    [36.4588683, 37.3719493],
    [36.460191, 37.3727656],
    [36.4631587, 37.3724176],
    [36.4686042, 37.3760909],
    [36.4720155, 37.3811057],
    [36.4744887, 37.3839519],
    [36.4750003, 37.3870689],
    [36.4750802, 37.3950311],
    [36.4734668, 37.3991114],
    [36.4728734, 37.4035561],
    [36.471028, 37.4076253],
    [36.4677968, 37.4107098],
    [36.4653279, 37.4140847],
    [36.4788288, 37.4168007],
    [36.4914321, 37.4217762],
    [36.4964961, 37.4216399],
    [36.5081135, 37.4242177],
    [36.5079974, 37.4272974],
    [36.5116186, 37.4292769],
    [36.5165386, 37.4276146],
    [36.5194209, 37.4278191],
    [36.5234382, 37.4287976],
    [36.5310659, 37.4263768],
    [36.5361499, 37.4262068],
    [36.542246, 37.4175176],
    [36.5519946, 37.4169855],
    [36.5774668, 37.4161806],
    [36.5802189, 37.4133394],
    [36.5788346, 37.4074852],
    [36.5790284, 37.401296],
    [36.5733027, 37.3942662],
    [36.5628924, 37.3876493],
    [36.5582077, 37.380825],
    [36.5438655, 37.3707768],
    [36.5314002, 37.3608378],
    [36.5429902, 37.3616044],
    [36.5671227, 37.3687971],
    [36.5812109, 37.3652459],
    [36.6173657, 37.3719399],
    [36.6290508, 37.3659739],
    [36.6368261, 37.3660921],
    [36.6420559, 37.3647348],
    [36.6498873, 37.3632267],
    [36.660141, 37.3627467],
    [36.6712595, 37.360961],
    [36.6855595, 37.3640842],
    [36.6913618, 37.3583127],
    [36.6822302, 37.3457094],
    [36.6697004, 37.3302385],
    [36.6502911, 37.3187522],
    [36.6421555, 37.3102926],
    [36.6350548, 37.3060682],
    [36.6315937, 37.3017648],
    [36.6305018, 37.2979077],
    [36.636938, 37.2919761],
    [36.6561943, 37.2759774],
    [36.6726207, 37.2754189],
    [36.6880281, 37.2782119],
    [36.6868345, 37.2749463],
    [36.6850609, 37.270477],
    [36.6757177, 37.2632346],
    [36.6743049, 37.25902],
    [36.6762641, 37.2548116],
    [36.6753956, 37.2518953],
    [36.6741795, 37.2472864],
    [36.6711336, 37.2420699],
    [36.6767322, 37.2428659],
    [36.6804497, 37.2417345],
    [36.6892423, 37.2406732],
    [36.6917741, 37.2370487],
    [36.6927208, 37.2328371],
    [36.6919829, 37.2265055],
    [36.6940012, 37.222598],
    [36.6965364, 37.2202735],
    [36.6945733, 37.2187084],
    [36.693138, 37.2161761],
    [36.7055668, 37.217726],
    [36.7139999, 37.2197566],
    [36.7176929, 37.2183265],
    [36.7200504, 37.2187448],
    [36.7232869, 37.220189],
    [36.7271716, 37.2226348],
    [36.7405898, 37.2293467],
    [36.7423232, 37.2309926],
    [36.7445302, 37.2347702],
    [36.7444623, 37.2355304],
    [36.7447454, 37.2357512],
    [36.7452818, 37.2360414],
    [36.7459558, 37.2366776],
    [36.746391, 37.2369043],
    [36.7492238, 37.2361236],
    [36.7508295, 37.2347702],
    [36.752415, 37.2330845],
    [36.7533876, 37.2331934],
    [36.7545492, 37.2335354],
    [36.7555794, 37.2336856],
    [36.756456, 37.2335665],
    [36.7586465, 37.2329943],
    [36.7601757, 37.2323617],
    [36.7602053, 37.2321581],
    [36.7606525, 37.2321509],
    [36.7607535, 37.232325],
    [36.7617618, 37.2320679],
    [36.7619834, 37.232204],
    [36.7622355, 37.232544],
    [36.7633852, 37.2320497],
    [36.7656333, 37.2311544],
    [36.7660738, 37.2311437],
    [36.7661923, 37.2306241],
    [36.7660424, 37.2301699],
    [36.7661736, 37.2296828],
    [36.7664471, 37.2296315],
    [36.7675585, 37.2301345],
    [36.768319, 37.2306555],
    [36.7688151, 37.23023],
    [36.7687968, 37.2295431],
    [36.76967, 37.2292223],
    [36.7704991, 37.2283586],
    [36.7714989, 37.2283798],
    [36.7737549, 37.2298797],
    [36.773522, 37.2313175],
    [36.7749333, 37.2318019],
    [36.7754356, 37.2338098],
    [36.7765157, 37.2336115],
    [36.776742, 37.2341021],
    [36.7811455, 37.2343726],
    [36.7814484, 37.234851],
    [36.7815533, 37.2357057],
    [36.7842273, 37.2358365],
    [36.7854679, 37.2360198],
    [36.7853642, 37.2370767],
    [36.7855663, 37.2374888],
    [36.7869211, 37.2371197],
    [36.7877341, 37.2370332],
    [36.7881709, 37.2370544],
    [36.7885703, 37.2371796],
    [36.789201, 37.2376085],
    [36.7896591, 37.2377674],
    [36.790286, 37.238074],
    [36.7909612, 37.2383281],
    [36.7919243, 37.2385143],
    [36.7928664, 37.2388123],
    [36.793145, 37.238824],
    [36.7938894, 37.2387378],
    [36.7943131, 37.2388596],
    [36.7945966, 37.2387278],
    [36.7948449, 37.2386391],
    [36.7951866, 37.238714],
    [36.7954404, 37.2387521],
    [36.7956809, 37.238675],
    [36.796096, 37.2385288],
    [36.7963392, 37.2385102],
    [36.796389, 37.2383982],
    [36.796351, 37.2382707],
    [36.7961672, 37.2381102],
    [36.7960847, 37.2379389],
    [36.796298, 37.2377987],
    [36.7966835, 37.2376872],
    [36.7971118, 37.2377193],
    [36.7975684, 37.2376987],
    [36.7980576, 37.2375888],
    [36.7982953, 37.2375828],
    [36.798706, 37.2375458],
    [36.799088, 37.2375017],
    [36.7994884, 37.2373753],
    [36.8000476, 37.2372974],
    [36.8002677, 37.2371888],
    [36.8004187, 37.2371189],
    [36.8007009, 37.2372029],
    [36.801243, 37.2371914],
    [36.8015243, 37.2370457],
    [36.8017651, 37.236799],
    [36.8020738, 37.2367061],
    [36.8027329, 37.2367826],
    [36.803737, 37.2369927],
    [36.8052204, 37.2369792],
    [36.8058072, 37.2369062],
    [36.8074514, 37.2364542],
    [36.809301, 37.2360468],
    [36.809978, 37.2363465],
    [36.8102534, 37.2366686],
    [36.8132467, 37.2343783],
    [36.8133196, 37.2352537],
    [36.8133502, 37.2375228],
    [36.8144884, 37.2373942],
    [36.8148085, 37.237482],
    [36.8153175, 37.2373564],
    [36.8169654, 37.2388773],
    [36.8166871, 37.2393347],
    [36.8180779, 37.2410972],
    [36.8193115, 37.2427288],
    [36.8211253, 37.2438009],
    [36.8221985, 37.2441489],
    [36.8241924, 37.2444668],
    [36.8263867, 37.2444422],
    [36.8278014, 37.2444234],
    [36.8288068, 37.2444933],
    [36.8302341, 37.2446923],
    [36.8322072, 37.2449034],
    [36.8336686, 37.2446679],
    [36.834254, 37.2445809],
    [36.8356647, 37.244187],
    [36.8379658, 37.2433718],
    [36.837868, 37.2437869],
    [36.8375042, 37.2445703],
    [36.8373247, 37.2449718],
    [36.8373413, 37.245189],
    [36.8374425, 37.2455147],
    [36.8375772, 37.2457366],
    [36.83787, 37.2459825],
    [36.838241, 37.2461579],
    [36.8383212, 37.2462006],
    [36.8383281, 37.2463151],
    [36.8381378, 37.2465234],
    [36.8375195, 37.2471764],
    [36.8374203, 37.2474028],
    [36.837288, 37.2476701],
    [36.8371437, 37.2478614],
    [36.8369351, 37.2480368],
    [36.8367694, 37.2482035],
    [36.8366841, 37.2483539],
    [36.8366777, 37.248652],
    [36.8366121, 37.24903],
    [36.8365067, 37.2493127],
    [36.8364807, 37.2495264],
    [36.8365092, 37.2497387],
    [36.8365064, 37.2499217],
    [36.8362928, 37.2502099],
    [36.8360654, 37.2504981],
    [36.8360263, 37.2506258],
    [36.8361166, 37.2507831],
    [36.8363956, 37.2511641],
    [36.8365493, 37.2513038],
    [36.836814, 37.2515361],
    [36.8370007, 37.2517523],
    [36.837074, 37.2519764],
    [36.8370782, 37.2521761],
    [36.8368456, 37.2523734],
    [36.8358906, 37.2532816],
    [36.8359256, 37.253526],
    [36.8362327, 37.2539844],
    [36.8363177, 37.2540755],
    [36.8365665, 37.2541493],
    [36.8369361, 37.2542795],
    [36.8372362, 37.254358],
    [36.8373933, 37.2544347],
    [36.8376127, 37.2546469],
    [36.8383886, 37.255221],
    [36.8385658, 37.2552679],
    [36.8387211, 37.2551959],
    [36.8392463, 37.2547383],
    [36.8394641, 37.2547599],
    [36.8404404, 37.2548566],
    [36.8413227, 37.2548735],
    [36.8420023, 37.2549735],
    [36.842267, 37.2550448],
    [36.8428026, 37.2553493],
    [36.8435841, 37.2557202],
    [36.8438922, 37.256054],
    [36.8442332, 37.2564593],
    [36.844359, 37.2567885],
    [36.844451, 37.2572367],
    [36.8447056, 37.2577556],
    [36.845028, 37.2585169],
    [36.8454107, 37.2598075],
    [36.8458943, 37.2611491],
    [36.8460043, 37.2617241],
    [36.8462769, 37.2621567],
    [36.8470179, 37.2621818],
    [36.8477062, 37.2623256],
    [36.8521428, 37.2634355],
    [36.8519675, 37.2642909],
    [36.8521799, 37.2650377],
    [36.8527212, 37.2658467],
    [36.8540503, 37.2668926],
    [36.8550253, 37.2679035],
    [36.8553899, 37.2688548],
    [36.8555758, 37.2698033],
    [36.8554981, 37.2715919],
    [36.8550989, 37.2726055],
    [36.8542589, 37.2730762],
    [36.8545648, 37.2737241],
    [36.8547034, 37.2754266],
    [36.8541038, 37.2760611],
    [36.8544083, 37.276597],
    [36.855182, 37.2770257],
    [36.875996, 37.2911661],
    [36.8795266, 37.3117905],
    [36.8879857, 37.3182651],
    [36.8905875, 37.3230932],
    [36.8957914, 37.3237829],
    [36.9067772, 37.3166557],
    [36.9173976, 37.3164616],
    [36.9358321, 37.3218507],
    [36.951412, 37.3137523],
    [36.9593809, 37.3060104],
    [36.9729422, 37.2986368],
    [36.9750264, 37.2948353],
    [36.9747109, 37.2890338],
    [36.9711573, 37.2846487],
    [36.9701806, 37.2828146],
    [36.9708822, 37.2796533],
    [36.9732082, 37.2800634],
    [36.9749164, 37.2782873],
    [36.9768905, 37.2789703],
    [36.9792937, 37.2778093],
    [36.9796371, 37.2756238],
    [36.9798087, 37.2719356],
    [36.9815604, 37.2703377],
    [36.9844033, 37.2699621],
    [36.9902801, 37.2758286],
    [37.0028113, 37.2705012],
    [37.0077037, 37.2716624],
    [37.0090555, 37.2701074],
    [37.009077, 37.2684521],
    [37.0073604, 37.2667445],
    [37.0036697, 37.2652417],
    [36.998434, 37.2642171],
    [36.9978332, 37.2621678],
    [37.0022964, 37.2586156],
    [37.0005797, 37.2567029],
    [37.0074461, 37.25479],
    [37.012596, 37.2549266],
    [37.0134543, 37.2485046],
    [37.0169523, 37.2481453],
    [37.0170592, 37.245635],
    [37.0192908, 37.2433119],
    [37.0270155, 37.2409887],
    [37.0285605, 37.2342921],
    [37.027194, 37.229135],
    [37.0202475, 37.2265178],
    [37.0087949, 37.2211598],
    [37.0060003, 37.2171578],
    [37.0066053, 37.2144215],
    [37.0155143, 37.2138569],
    [37.0190333, 37.2155657],
    [37.0220374, 37.2152923],
    [37.0225524, 37.2175479],
    [37.0238399, 37.2198035],
    [37.0271014, 37.2187099],
    [37.0296764, 37.2178213],
    [37.0352553, 37.2149506],
    [37.0393752, 37.2162493],
    [37.0495032, 37.2088669],
    [37.0494842, 37.2059283],
    [37.0557583, 37.2027736],
    [37.0631503, 37.2032613],
    [37.0647811, 37.2043551],
    [37.0713042, 37.2029878],
    [37.075323, 37.2046548],
    [37.0778274, 37.2050387],
    [37.0784282, 37.2010736],
    [37.0806598, 37.1993644],
    [37.0818614, 37.1995012],
    [37.0837497, 37.2022358],
    [37.0842558, 37.2048927],
    [37.0876121, 37.210234],
    [37.0908736, 37.2113278],
    [37.0940494, 37.2103024],
    [37.0998859, 37.2172062],
    [37.1000575, 37.2188466],
    [37.1076978, 37.2213531],
    [37.1105289, 37.2247928],
    [37.1102766, 37.2347263],
    [37.1135329, 37.2378454],
    [37.1142196, 37.2411936],
    [37.1197986, 37.2427652],
    [37.1219443, 37.2461815],
    [37.1189403, 37.2472064],
    [37.1169319, 37.2472091],
    [37.1158504, 37.2485045],
    [37.1120738, 37.249256],
    [37.1113013, 37.2541751],
    [37.1148204, 37.2558147],
    [37.1161079, 37.2592304],
    [37.1206569, 37.2594353],
    [37.1270942, 37.2627143],
    [37.1307849, 37.2658565],
    [37.1325873, 37.266198],
    [37.1359347, 37.2674275],
    [37.1402263, 37.2641488],
    [37.1488952, 37.2634657],
    [37.1521567, 37.2639439],
    [37.1542167, 37.2632608],
    [37.15559, 37.26046],
    [37.1593665, 37.2601868],
    [37.1618435, 37.2631408],
    [37.1646022, 37.2632608],
    [37.1668338, 37.2657199],
    [37.1684646, 37.2655833],
    [37.1708678, 37.2680423],
    [37.1728419, 37.2690668],
    [37.1745362, 37.2731793],
    [37.1792792, 37.2775361],
    [37.1895387, 37.2741079],
    [37.1973895, 37.2747359],
    [37.1968745, 37.2790386],
    [37.1952437, 37.2816338],
    [37.1959086, 37.2840171],
    [37.1948146, 37.2859361],
    [37.2087192, 37.2957007],
    [37.2083757, 37.3001371],
    [37.2052859, 37.3048497],
    [37.2071742, 37.3091507],
    [37.2089767, 37.3116765],
    [37.2176456, 37.3157724],
    [37.219963, 37.3159771],
    [37.2198772, 37.3195267],
    [37.2233104, 37.3223935],
    [37.2237395, 37.327922],
    [37.2269951, 37.3320763],
    [37.2268294, 37.3350879],
    [37.230606, 37.3374082],
    [37.2294902, 37.3417073],
    [37.2305202, 37.3455968],
    [37.2336101, 37.3458697],
    [37.2348117, 37.3467568],
    [37.2362708, 37.3465521],
    [37.2376441, 37.3412297],
    [37.2420215, 37.343618],
    [37.2439956, 37.3440956],
    [37.2467422, 37.345665],
    [37.2509479, 37.3449145],
    [37.252407, 37.3423215],
    [37.2494357, 37.339395],
    [37.2500896, 37.338773],
    [37.2530078, 37.3391825],
    [37.2537803, 37.3375447],
    [37.2566985, 37.3376812],
    [37.2577285, 37.3369305],
    [37.2583293, 37.3372717],
    [37.2582435, 37.3391142],
    [37.2591018, 37.3400014],
    [37.2639941, 37.3391825],
    [37.2707532, 37.3409082],
    [37.2703456, 37.3424579],
    [37.2715472, 37.3427309],
    [37.2741222, 37.3414344],
    [37.2763538, 37.3414344],
    [37.2845586, 37.3343442],
    [37.2866534, 37.3346785],
    [37.2879409, 37.3338595],
    [37.2894, 37.3348832],
    [37.2912025, 37.3318121],
    [37.2881126, 37.3292187],
    [37.2887134, 37.3277172],
    [37.2881126, 37.3252601],
    [37.2859668, 37.3223252],
    [37.285881, 37.320141],
    [37.2900008, 37.3208236],
    [37.2958991, 37.3187242],
    [37.2978973, 37.3174106],
    [37.2998714, 37.3171376],
    [37.2991847, 37.3160454],
    [37.3006438, 37.3148849],
    [37.3013305, 37.3144071],
    [37.3022746, 37.3150897],
    [37.3030471, 37.3146802],
    [37.3032188, 37.3122227],
    [37.3036479, 37.3107891],
    [37.3035621, 37.3088093],
    [37.3047637, 37.3065565],
    [37.3070811, 37.3055324],
    [37.3093127, 37.3067613],
    [37.3107719, 37.3062151],
    [37.3130035, 37.3079218],
    [37.3118018, 37.3096286],
    [37.3130893, 37.3111304],
    [37.3104285, 37.3143388],
    [37.3109435, 37.3153628],
    [37.3125743, 37.3157724],
    [37.3128318, 37.3177519],
    [37.3169517, 37.3197997],
    [37.31781, 37.3227347],
    [37.3212432, 37.3240998],
    [37.3220157, 37.3251919],
    [37.3245906, 37.3251919],
    [37.3300838, 37.3226665],
    [37.3338603, 37.322803],
    [37.3378845, 37.3257875],
    [37.3390102, 37.3245093],
    [37.3427009, 37.3249871],
    [37.3453616, 37.3235538],
    [37.3475932, 37.3245093],
    [37.3525714, 37.3242363],
    [37.354288, 37.3234173],
    [37.3573779, 37.3253966],
    [37.3599007, 37.3250663],
    [37.3627853, 37.3238268],
    [37.364931, 37.3245093],
    [37.3670768, 37.3232808],
    [37.3698234, 37.3243728],
    [37.3735999, 37.3238268],
    [37.3755546, 37.3232004],
    [37.3778915, 37.3240998],
    [37.381153, 37.3237586],
    [37.3860951, 37.3230348],
    [37.3871771, 37.3255288],
    [37.3948001, 37.328195],
    [37.3971175, 37.3275125],
    [37.3967742, 37.3301059],
    [37.398405, 37.3314709],
    [37.3984567, 37.3333111],
    [37.4017524, 37.3355656],
    [37.4055289, 37.3362481],
    [37.4086188, 37.3382271],
    [37.4077605, 37.3414344],
    [37.4084116, 37.3421212],
    [37.4109363, 37.3421168],
    [37.413082, 37.3410249],
    [37.4182333, 37.3413782],
    [37.4202918, 37.3398649],
    [37.4255275, 37.3393872],
    [37.4246692, 37.3418438],
    [37.4279265, 37.3438042],
    [37.4497737, 37.3590958],
    [37.447071, 37.3663373],
    [37.4484443, 37.3698844],
    [37.4469852, 37.3738406],
    [37.447634, 37.3782306],
    [37.4499034, 37.3803202],
    [37.4549493, 37.3796035],
    [37.456185, 37.3807932],
    [37.4584006, 37.3837303],
    [37.4539374, 37.3839349],
    [37.4520492, 37.3856398],
    [37.4496459, 37.3855716],
    [37.4480151, 37.3862536],
    [37.4412337, 37.3863443],
    [37.4379729, 37.3876858],
    [37.4360847, 37.3894588],
    [37.4349689, 37.3899362],
    [37.4328231, 37.3917774],
    [37.4328231, 37.3944369],
    [37.4311923, 37.39771],
    [37.4316883, 37.3998978],
    [37.4342822, 37.4031647],
    [37.436428, 37.4033011],
    [37.4384021, 37.4035056],
    [37.4402904, 37.4029602],
    [37.4448516, 37.4036589],
    [37.4452196, 37.4041571],
    [37.4496678, 37.4058728],
    [37.453274, 37.4073423],
    [37.454965, 37.4105728],
    [37.4558858, 37.4135399],
    [37.4602031, 37.4166636],
    [37.4662112, 37.4174135],
    [37.4720937, 37.4148942],
    [37.4795437, 37.4157025],
    [37.4872462, 37.414016],
    [37.4931621, 37.4180952],
    [37.4965953, 37.4181633],
    [37.4980544, 37.4197994],
    [37.4998569, 37.4190495],
    [37.5056933, 37.4187769],
    [37.50741, 37.4168],
    [37.5033759, 37.411278],
    [37.5056933, 37.4098463],
    [37.5084297, 37.4092365],
    [37.5133635, 37.410718],
    [37.5204562, 37.4189814],
    [37.5251769, 37.42089],
    [37.5320434, 37.4198675],
    [37.5366816, 37.414563],
    [37.5468062, 37.4138686],
    [37.5502395, 37.4168681],
    [37.5539302, 37.4172771],
    [37.5580501, 37.4193222],
    [37.565689, 37.4210945],
    [37.5668906, 37.422526],
    [37.5627843, 37.4432985],
    [37.5617409, 37.446005],
    [37.5603673, 37.4485819],
    [37.5580716, 37.4529723],
    [37.5579965, 37.4544713],
    [37.5578999, 37.4550164],
    [37.5576854, 37.4554507],
    [37.5575995, 37.4558596],
    [37.557503, 37.4561363],
    [37.557444, 37.4563535],
    [37.557342, 37.4566601],
    [37.5572509, 37.4570945],
    [37.5570416, 37.4576864],
    [37.5569934, 37.458027],
    [37.5570363, 37.4583677],
    [37.5568968, 37.4588829],
    [37.557047, 37.4591257],
    [37.557342, 37.4598027],
    [37.5577575, 37.4607116],
    [37.5535869, 37.4602115],
    [37.553029, 37.4612846],
    [37.5520634, 37.4626471],
    [37.5515699, 37.4637202],
    [37.5508618, 37.4646143],
    [37.5499177, 37.4691531],
    [37.5495744, 37.4703112],
    [37.5502181, 37.4723037],
    [37.558887, 37.4745856],
    [37.5597453, 37.4744494],
    [37.5618052, 37.4746708],
    [37.5625777, 37.4746197],
    [37.5653457, 37.4751135],
    [37.5672769, 37.4752327],
    [37.5704956, 37.475948],
    [37.5719782, 37.4754927],
    [37.5736069, 37.4752838],
    [37.5756454, 37.475369],
    [37.5773835, 37.4756925],
    [37.5783705, 37.4755222],
    [37.5790572, 37.4751348],
    [37.5795026, 37.474367],
    [37.5839196, 37.4922894],
    [37.5907609, 37.508912],
    [37.5813531, 37.5210816],
    [37.5783759, 37.5426586],
    [37.5845158, 37.5700428],
    [37.5870766, 37.5760561],
    [37.5863239, 37.5831632],
    [37.5806673, 37.5866875],
    [37.5725263, 37.5940898],
    [37.5644118, 37.5987921],
    [37.5632483, 37.6055032],
    [37.5583011, 37.6068939],
    [37.5569446, 37.6087903],
    [37.5538327, 37.6103705],
    [37.549843, 37.6081582],
    [37.5422088, 37.6069297],
    [37.5273919, 37.5907473],
    [37.5175267, 37.5906462],
    [37.512798, 37.5892747],
    [37.5077121, 37.5859672],
    [37.508188, 37.5790962],
    [37.4986735, 37.5755113],
    [37.4941502, 37.57069],
    [37.49571, 37.5609229],
    [37.4863514, 37.5612938],
    [37.4713595, 37.5625519],
    [37.4584318, 37.5736571],
    [37.4506751, 37.5740042],
    [37.4526071, 37.5785963],
    [37.4503923, 37.584078],
    [37.4512338, 37.5938317],
    [37.4495824, 37.6001686],
    [37.4514192, 37.6064322],
    [37.4578566, 37.610563],
    [37.4573845, 37.6114979],
    [37.4573416, 37.6136226],
    [37.4576206, 37.6135886],
    [37.4587793, 37.6134356],
    [37.4603457, 37.6133847],
    [37.4609894, 37.6134866],
    [37.4624056, 37.6131977],
    [37.4623627, 37.6134696],
    [37.4668045, 37.6131977],
    [37.4674267, 37.6134356],
    [37.4675555, 37.6136736],
    [37.4709243, 37.6131977],
    [37.4726195, 37.6128747],
    [37.473628, 37.6129427],
    [37.4746794, 37.6125178],
    [37.4735851, 37.6120588],
    [37.4730701, 37.6116169],
    [37.474615, 37.6114809],
    [37.4783701, 37.610971],
    [37.4781341, 37.6118039],
    [37.4811167, 37.6135036],
    [37.4813957, 37.6142345],
    [37.4846358, 37.6155603],
    [37.4844856, 37.6159173],
    [37.4842925, 37.6161042],
    [37.4836916, 37.6162402],
    [37.4836916, 37.6165292],
    [37.4822111, 37.6180759],
    [37.4813313, 37.621951],
    [37.4821467, 37.6225118],
    [37.483005, 37.6228007],
    [37.4891204, 37.6258939],
    [37.4848074, 37.6320287],
    [37.4735851, 37.6388428],
    [37.4676842, 37.645894],
    [37.4624056, 37.6449596],
    [37.4628992, 37.6433794],
    [37.4620838, 37.6390806],
    [37.4593586, 37.6332692],
    [37.459187, 37.6311621],
    [37.4577922, 37.6301424],
    [37.4579424, 37.6282051],
    [37.4564619, 37.6265227],
    [37.4532647, 37.6257069],
    [37.4515266, 37.6241264],
    [37.4495525, 37.625316],
    [37.4477643, 37.6244341],
    [37.4483416, 37.6268445],
    [37.4424778, 37.6332454],
    [37.4414148, 37.6375292],
    [37.4330263, 37.6397809],
    [37.4352478, 37.6417622],
    [37.4396034, 37.6454084],
    [37.4421344, 37.6496913],
    [37.4455407, 37.6507346],
    [37.4468041, 37.6531608],
    [37.4517539, 37.6544554],
    [37.4535934, 37.6597829],
    [37.4541779, 37.6676822],
    [37.457844, 37.6723778],
    [37.46261, 37.6747042],
    [37.4654386, 37.6786396],
    [37.4693786, 37.6822333],
    [37.4679078, 37.6934886],
    [37.4678193, 37.6992155],
    [37.4674344, 37.7057076],
    [37.4665942, 37.7131482],
    [37.4590618, 37.7211716],
    [37.4558607, 37.735212],
    [37.4562753, 37.7429178],
    [37.4533164, 37.7482244],
    [37.4587519, 37.7557777],
    [37.4670169, 37.7526227],
    [37.4836389, 37.7499436],
    [37.4878312, 37.754256],
    [37.4900957, 37.7591743],
    [37.4997577, 37.7647564],
    [37.5066723, 37.7715793],
    [37.5088116, 37.7762884],
    [37.5046461, 37.7813591],
    [37.5039755, 37.7858608],
    [37.5056514, 37.7900915],
    [37.5095301, 37.7915056],
    [37.507245, 37.7933724],
    [37.5035169, 37.7975966],
    [37.5019575, 37.8035768],
    [37.5096463, 37.8251974],
    [37.51077, 37.8398018],
    [37.5131197, 37.8447232],
    [37.5104636, 37.8581137],
    [37.5126089, 37.8612594],
    [37.5122003, 37.8648081],
    [37.5085225, 37.8675503],
    [37.5081139, 37.8744051],
    [37.5039253, 37.8779532],
    [37.4877839, 37.8819851],
    [37.4829289, 37.8820346],
    [37.4876377, 37.887571],
    [37.4954836, 37.8918926],
    [37.5014498, 37.8971811],
    [37.5092958, 37.9044686],
    [37.5193484, 37.9106589],
    [37.5302183, 37.9167844],
    [37.5356941, 37.9196213],
    [37.537819, 37.9195568],
    [37.5396171, 37.9163332],
    [37.5428862, 37.9114972],
    [37.551386, 37.9080796],
    [37.5585781, 37.9031787],
    [37.5640539, 37.9006636],
    [37.5699384, 37.8998899],
    [37.5828875, 37.9025804],
    [37.5853982, 37.9053042],
    [37.5858166, 37.9087296],
    [37.5873335, 37.9102153],
    [37.6005692, 37.9146944],
    [37.605235, 37.9180783],
    [37.6078503, 37.9213664],
    [37.5992688, 37.9297473],
    [37.5980429, 37.9330994],
    [37.6031101, 37.9358067],
    [37.6095666, 37.9371604],
    [37.6157545, 37.9441663],
    [37.5987768, 37.9449082],
    [37.6032185, 37.9578859],
    [37.6005149, 37.961185],
    [37.6004076, 37.9659895],
    [37.5977039, 37.9696603],
    [37.5990343, 37.9716902],
    [37.5984442, 37.9735001],
    [37.5913095, 37.9771198],
    [37.5887346, 37.9794624],
    [37.5870716, 37.9799867],
    [37.5838959, 37.9829127],
    [37.5831771, 37.9879187],
    [37.5809777, 37.9933641],
    [37.5818682, 37.9976423],
    [37.5808275, 38.0057076],
    [37.5985515, 38.0042367],
    [37.5984871, 38.0082184],
    [37.6013303, 38.0131128],
    [37.5919425, 38.0146765],
    [37.5875115, 38.0146512],
    [37.5830591, 38.0180068],
    [37.5768793, 38.0260192],
    [37.5825763, 38.0349941],
    [37.578789, 38.0377067],
    [37.5784242, 38.0407655],
    [37.57954, 38.0443819],
    [37.5786495, 38.0540473],
    [37.5838208, 38.0564297],
    [37.58793, 38.0577053],
    [37.5958264, 38.0595638],
    [37.6011801, 38.0585754],
    [37.6047635, 38.0591667],
    [37.6089907, 38.0592343],
    [37.6124668, 38.0600622],
    [37.6198053, 38.0640071],
    [37.6240351, 38.0648992],
    [37.6248751, 38.0651455],
    [37.6310062, 38.0667777],
    [37.6312942, 38.0669365],
    [37.6480436, 38.0761698],
    [37.6550388, 38.0779603],
    [37.6744794, 38.0844123],
    [37.6908302, 38.0958287],
    [37.7040266, 38.1096069],
    [37.7100561, 38.1126798],
    [37.715249, 38.1213907],
    [37.7198839, 38.1247668],
    [37.7197551, 38.1266574],
    [37.7268791, 38.1329701],
    [37.7273941, 38.1487326],
    [37.7185643, 38.1560304],
    [37.7181244, 38.1577768],
    [37.7351188, 38.164458],
    [37.7486372, 38.1647616],
    [37.7512979, 38.1741749],
    [37.7516841, 38.1953926],
    [37.7506626, 38.2089976],
    [37.7499461, 38.219234],
    [37.746039, 38.2422567],
    [37.7339709, 38.2544001],
    [37.7364278, 38.2550488],
    [37.717545, 38.2522517],
    [37.702825, 38.2519315],
    [37.6685786, 38.2585872],
    [37.6678276, 38.2702796],
    [37.6709389, 38.2946185],
    [37.6721406, 38.2959657],
    [37.6735782, 38.3044191],
    [37.6758528, 38.3066081],
    [37.6555753, 38.3169458],
    [37.6477217, 38.3188146],
    [37.6434302, 38.3219458],
    [37.6344824, 38.3221478],
    [37.6281738, 38.3238986],
    [37.6261353, 38.3218953],
    [37.6180029, 38.3227875],
    [37.6147198, 38.3246393],
    [37.6120162, 38.3233936],
    [37.6046991, 38.3225014],
    [37.598691, 38.3244036],
    [37.58322, 38.3384416],
    [37.5741005, 38.3365734],
    [37.559402, 38.3467723],
    [37.5584364, 38.3484046],
    [37.5547457, 38.3472603],
    [37.5491881, 38.3484383],
    [37.546699, 38.3497509],
    [37.5383091, 38.3559601],
    [37.5325155, 38.3582821],
    [37.5274086, 38.3621352],
    [37.5212931, 38.3615968],
    [37.5151562, 38.3621352],
    [37.5079465, 38.3624717],
    [37.5017452, 38.3660217],
    [37.4998569, 38.3686798],
    [37.4997496, 38.3734071],
    [37.4875617, 38.382642],
    [37.4874973, 38.3810272],
    [37.4856519, 38.3786555],
    [37.4854159, 38.3773771],
    [37.4721336, 38.3777471],
    [37.4648809, 38.3768556],
    [37.4621558, 38.3784536],
    [37.4575424, 38.3754089],
    [37.4561047, 38.3813636],
    [37.4576068, 38.3851986],
    [37.4564695, 38.3912367],
    [37.4474787, 38.3916739],
    [37.4409985, 38.3940284],
    [37.4328446, 38.3992249],
    [37.4156999, 38.4065733],
    [37.4018168, 38.4128281],
    [37.3842001, 38.4052785],
    [37.3838997, 38.4052113],
    [37.3835134, 38.4054803],
    [37.3832989, 38.4056821],
    [37.3830628, 38.4058334],
    [37.381947, 38.4057662],
    [37.3799085, 38.405598],
    [37.3792004, 38.4053963],
    [37.3785567, 38.405144],
    [37.3782992, 38.4049254],
    [37.3781705, 38.4047237],
    [37.3783421, 38.4045555],
    [37.3784923, 38.4043537],
    [37.3783421, 38.4041856],
    [37.3780203, 38.404236],
    [37.3777199, 38.4043369],
    [37.3774195, 38.4044042],
    [37.3760462, 38.4043201],
    [37.3755312, 38.404421],
    [37.3742866, 38.4050095],
    [37.3735571, 38.4055476],
    [37.3729348, 38.4057157],
    [37.3720121, 38.4058166],
    [37.3716903, 38.4061697],
    [37.3716688, 38.4070105],
    [37.3663687, 38.4063379],
    [37.3662615, 38.4166782],
    [37.3627853, 38.415804],
    [37.3531508, 38.4176365],
    [37.3489237, 38.4222261],
    [37.3579788, 38.4268658],
    [37.3595881, 38.4263447],
    [37.3608327, 38.4269498],
    [37.3586869, 38.4297402],
    [37.354846, 38.4367494],
    [37.3456621, 38.4401781],
    [37.3440528, 38.4376402],
    [37.3383021, 38.4347156],
    [37.3306632, 38.4443629],
    [37.329247, 38.4432369],
    [37.3274445, 38.4434385],
    [37.3247838, 38.4416571],
    [37.3252129, 38.4423125],
    [37.3214793, 38.441573],
    [37.3127031, 38.4406151],
    [37.3105788, 38.4399932],
    [37.3013091, 38.4513034],
    [37.2955155, 38.4548994],
    [37.28513, 38.4558068],
    [37.2855806, 38.4606628],
    [37.2846579, 38.464964],
    [37.2779631, 38.4777653],
    [37.2615266, 38.488213],
    [37.2660327, 38.4918575],
    [37.2624063, 38.4958546],
    [37.2606253, 38.4958882],
    [37.2664189, 38.5015139],
    [37.2692942, 38.5080795],
    [37.2715688, 38.5110011],
    [37.2774052, 38.519782],
    [37.3076391, 38.5464873],
    [37.3160934, 38.5469739],
    [37.3121452, 38.5506491],
    [37.3062229, 38.5675625],
    [37.3209568, 38.5691001],
    [37.3168659, 38.5780475],
    [37.2952365, 38.5823249],
    [37.2794437, 38.5903759],
    [37.268878, 38.5913907],
    [37.2452828, 38.591439],
    [37.2245882, 38.5911145],
    [37.2021022, 38.5786753],
    [37.1831496, 38.5852506],
    [37.168145, 38.5936417],
    [37.1391104, 38.6003713],
    [37.1219443, 38.6090909],
    [37.1137994, 38.6060504],
    [37.1055902, 38.5999167],
    [37.07617, 38.5859123],
    [37.0543098, 38.5876923],
    [37.0497607, 38.5872227],
    [37.0428943, 38.58521],
    [37.0386886, 38.5862163],
    [37.0318221, 38.5900404],
    [37.0289897, 38.5928581],
    [37.0107936, 38.5902417],
    [37.0059013, 38.5813856],
    [36.9969749, 38.5814527],
    [36.9912242, 38.5829288],
    [36.9814395, 38.5844049],
    [36.975088, 38.5846061],
    [36.9586085, 38.5890341],
    [36.9556045, 38.5891012],
    [36.9522571, 38.588162],
    [36.9418716, 38.5895037],
    [36.9382667, 38.5886987],
    [36.9357776, 38.5892354],
    [36.9329452, 38.5891012],
    [36.9300269, 38.5860151],
    [36.9268512, 38.5854112],
    [36.9240188, 38.5837339],
    [36.9200706, 38.5831301],
    [36.9188689, 38.5814527],
    [36.9174098, 38.5815198],
    [36.9156073, 38.5806476],
    [36.9157395, 38.5781033],
    [36.9135473, 38.5775611],
    [36.9130324, 38.5742061],
    [36.9109724, 38.573535],
    [36.9102858, 38.5716561],
    [36.907676, 38.5718362],
    [36.9049643, 38.5767559],
    [36.9054793, 38.5778295],
    [36.904106, 38.5801108],
    [36.9008444, 38.5811844],
    [36.892948, 38.5807818],
    [36.882305, 38.5783663],
    [36.877003, 38.5745881],
    [36.8663405, 38.5697772],
    [36.8615339, 38.5654822],
    [36.8598173, 38.5614554],
    [36.8536375, 38.5626635],
    [36.8426512, 38.5614554],
    [36.8393896, 38.5626635],
    [36.8307122, 38.5618993],
    [36.8227385, 38.5601131],
    [36.8191336, 38.5542067],
    [36.8193085, 38.5487654],
    [36.8050574, 38.5504478],
    [36.7987059, 38.5493738],
    [36.7918394, 38.54736],
    [36.7851446, 38.5426609],
    [36.7804256, 38.5370852],
    [36.7765524, 38.5363669],
    [36.7714153, 38.5355483],
    [36.768721, 38.5354972],
    [36.762558, 38.5344783],
    [36.7591311, 38.5340072],
    [36.7548472, 38.5334297],
    [36.7488152, 38.5310144],
    [36.7492658, 38.5293046],
    [36.754088, 38.5271033],
    [36.7513949, 38.5245808],
    [36.7491093, 38.5243117],
    [36.7453674, 38.5229331],
    [36.7411556, 38.5219058],
    [36.7379247, 38.5219678],
    [36.7378071, 38.52197],
    [36.7353028, 38.5220181],
    [36.7341271, 38.5218325],
    [36.7340692, 38.5218232],
    [36.7304235, 38.5212478],
    [36.726906, 38.5209542],
    [36.7229013, 38.5210536],
    [36.722831, 38.5210553],
    [36.7154917, 38.5219185],
    [36.7154725, 38.5219088],
    [36.7128105, 38.5205642],
    [36.7104139, 38.5192782],
    [36.7081503, 38.518333],
    [36.7057611, 38.5168218],
    [36.7033683, 38.5154235],
    [36.7010439, 38.5141388],
    [36.6987231, 38.5127416],
    [36.6964041, 38.5097327],
    [36.6812752, 38.5032419],
    [36.6781935, 38.498748],
    [36.6681142, 38.4995217],
    [36.6682618, 38.4971592],
    [36.6720511, 38.4952072],
    [36.6760921, 38.4943303],
    [36.6788924, 38.4917389],
    [36.6821327, 38.490055],
    [36.6855214, 38.4886614],
    [36.6858683, 38.4885189],
    [36.6860733, 38.4882081],
    [36.686345, 38.4883717],
    [36.6872508, 38.4886289],
    [36.687735, 38.4884444],
    [36.6886606, 38.4872136],
    [36.6886578, 38.4871778],
    [36.6900669, 38.4830622],
    [36.6917569, 38.4794914],
    [36.6929817, 38.4770375],
    [36.6944994, 38.4743644],
    [36.6961678, 38.4714686],
    [36.6973549, 38.4685708],
    [36.7066339, 38.4622158],
    [36.7169183, 38.4631558],
    [36.7244958, 38.4608975],
    [36.7276967, 38.4529442],
    [36.7277647, 38.4526536],
    [36.7277997, 38.4523108],
    [36.7297517, 38.4467853],
    [36.7283975, 38.4351581],
    [36.7344025, 38.4354544],
    [36.7405164, 38.4368567],
    [36.7401814, 38.4355858],
    [36.7403925, 38.4345172],
    [36.7401397, 38.4332864],
    [36.7404628, 38.4322464],
    [36.7401228, 38.4309458],
    [36.7396253, 38.4296567],
    [36.7384572, 38.4248092],
    [36.7409606, 38.4250908],
    [36.7419692, 38.4226439],
    [36.7404033, 38.4203972],
    [36.7374225, 38.4188703],
    [36.7325622, 38.4175725],
    [36.7248128, 38.4156972],
    [36.7235606, 38.4152614],
    [36.721315, 38.41448],
    [36.7179703, 38.4132158],
    [36.7144111, 38.41133],
    [36.7114106, 38.4105014],
    [36.7070617, 38.408775],
    [36.7020778, 38.4075975],
    [36.6991347, 38.4062114],
    [36.6950722, 38.4052639],
    [36.6909047, 38.4027139],
    [36.6880039, 38.4017294],
    [36.6855139, 38.3999447],
    [36.6830253, 38.3977569],
    [36.6797133, 38.3969269],
    [36.6772906, 38.3945325],
    [36.6742753, 38.392765],
    [36.6577922, 38.3926233],
    [36.6438811, 38.3902067],
    [36.6273575, 38.3854747],
    [36.617625, 38.3831108],
    [36.6163778, 38.3863617],
    [36.61551, 38.3964058],
    [36.6141733, 38.3966806],
    [36.6003911, 38.389805],
    [36.6073166, 38.3856669],
    [36.6057617, 38.3840514],
    [36.6055255, 38.3838061],
    [36.6010086, 38.3791133],
    [36.5935341, 38.3738958],
    [36.5867257, 38.3680078],
    [36.5800124, 38.3621486],
    [36.5754743, 38.357755],
    [36.5731627, 38.3551581],
    [36.5695518, 38.3511006],
    [36.5654949, 38.3437922],
    [36.5654627, 38.3245669],
    [36.5696118, 38.3228239],
    [36.5733485, 38.3204611],
    [36.576871, 38.3182833],
    [36.5813941, 38.3181297],
    [36.5832113, 38.3152328],
    [36.5836613, 38.3119461],
    [36.581498, 38.3095169],
    [36.5798091, 38.3068039],
    [36.5757457, 38.3050408],
    [36.5740541, 38.3019522],
    [36.5699913, 38.3001889],
    [36.5645185, 38.2968603],
    [36.561101, 38.2941125],
    [36.5574366, 38.2909492],
    [36.5540255, 38.2876039],
    [36.5531196, 38.2839056],
    [36.5517985, 38.2816131],
    [36.5504052, 38.2796339],
    [36.550928, 38.2782519],
    [36.5506366, 38.2759447],
    [36.5496693, 38.273185],
    [36.5474721, 38.2727417],
    [36.5457724, 38.2712042],
    [36.5459166, 38.2705772],
    [36.542966, 38.2684081],
    [36.5413916, 38.2676025],
    [36.541008, 38.2674061],
    [36.5398068, 38.2667914],
    [36.5381446, 38.2668778],
    [36.5344949, 38.26668],
    [36.5335538, 38.2665028],
    [36.5309893, 38.2658553],
    [36.528116, 38.2649592],
    [36.5260252, 38.2650631],
    [36.5243057, 38.2629056],
    [36.5228013, 38.2610175],
    [36.5227924, 38.2580128],
    [36.5230541, 38.2552878],
    [36.5218918, 38.2532306],
    [36.520523, 38.2506739],
    [36.5185721, 38.247215],
    [36.5150632, 38.2467364],
    [36.510156, 38.2463644],
    [36.5069793, 38.2461106],
    [36.5017874, 38.2457558],
    [36.498148, 38.2439475],
    [36.4941863, 38.2433997],
    [36.490251, 38.2427961],
    [36.4835432, 38.2423903],
    [36.481583, 38.2448269],
    [36.4781535, 38.2459481],
    [36.4750093, 38.2471597],
    [36.4690052, 38.2499889],
    [36.4658641, 38.2497589],
    [36.4610082, 38.2499764],
    [36.4572927, 38.2510967],
    [36.4527182, 38.2528911],
    [36.4495735, 38.2540122],
    [36.446886, 38.2551339],
    [36.4437338, 38.2556281],
    [36.4445985, 38.2590586],
    [36.4443543, 38.259015],
    [36.4443142, 38.2594311],
    [36.443506, 38.2592428],
    [36.4431116, 38.2599747],
    [36.4425313, 38.2600225],
    [36.4402768, 38.2602347],
    [36.4403363, 38.2604028],
    [36.4400806, 38.2616283],
    [36.4399874, 38.2616428],
    [36.4394558, 38.2617263],
    [36.4388467, 38.2617875],
    [36.4373118, 38.26206],
    [36.4367694, 38.2621927],
    [36.4366822, 38.2621614],
    [36.4363036, 38.2623172],
    [36.4358578, 38.2624988],
    [36.4358164, 38.26255],
    [36.4353863, 38.2623494],
    [36.4345119, 38.262698],
    [36.4340472, 38.2628839],
    [36.4338893, 38.2629278],
    [36.4338075, 38.2629507],
    [36.4335233, 38.2629256],
    [36.4333656, 38.2629117],
    [36.4329575, 38.2629324],
    [36.4317183, 38.2630842],
    [36.430966, 38.2631764],
    [36.4298686, 38.2633107],
    [36.4285936, 38.2636714],
    [36.4285069, 38.2636977],
    [36.4280333, 38.263388],
    [36.4274939, 38.2636783],
    [36.4273372, 38.2637699],
    [36.4271028, 38.263231],
    [36.4269961, 38.2632322],
    [36.426955, 38.2624874],
    [36.4260894, 38.2625024],
    [36.4254583, 38.2627883],
    [36.4255489, 38.2636342],
    [36.4249053, 38.2637685],
    [36.4241916, 38.2639178],
    [36.4236214, 38.2639481],
    [36.42313, 38.2645649],
    [36.4226992, 38.2649686],
    [36.4224539, 38.2641196],
    [36.4220047, 38.2642293],
    [36.4216503, 38.2643243],
    [36.4212397, 38.264386],
    [36.4205283, 38.264761],
    [36.4195325, 38.2637652],
    [36.4194786, 38.2640135],
    [36.4195258, 38.2641064],
    [36.4192492, 38.264595],
    [36.4186181, 38.2650914],
    [36.4182303, 38.2648286],
    [36.4177783, 38.2650278],
    [36.4175167, 38.2653928],
    [36.4171931, 38.2658681],
    [36.4168714, 38.2660222],
    [36.4164875, 38.2661883],
    [36.416085, 38.266105],
    [36.4155707, 38.2654403],
    [36.4149597, 38.2656303],
    [36.4144275, 38.2657222],
    [36.4137558, 38.2658622],
    [36.4136456, 38.2658844],
    [36.4124125, 38.264875],
    [36.4123269, 38.2630181],
    [36.4122419, 38.2627989],
    [36.4115675, 38.2610542],
    [36.4114149, 38.2611897],
    [36.4095784, 38.2628794],
    [36.4072481, 38.2555682],
    [36.4016795, 38.2489555],
    [36.3977408, 38.2430889],
    [36.3914932, 38.2430889],
    [36.3891217, 38.2415567],
    [36.3881953, 38.2432963],
    [36.3876088, 38.2449999],
    [36.3856107, 38.249493],
    [36.3862785, 38.252146],
    [36.3869873, 38.2550458],
    [36.3857718, 38.2566425],
    [36.3825722, 38.2586],
    [36.3808575, 38.2578304],
    [36.3788353, 38.2571889],
    [36.3785492, 38.2557453],
    [36.3794889, 38.2548792],
    [36.3793868, 38.2539649],
    [36.3811842, 38.253195],
    [36.3824383, 38.2510295],
    [36.3820846, 38.248849],
    [36.3804873, 38.2451958],
    [36.3777756, 38.2434089],
    [36.3820106, 38.2374174],
    [36.3804919, 38.2359416],
    [36.3805386, 38.233859],
    [36.3838874, 38.2327411],
    [36.3848381, 38.2268732],
    [36.3834799, 38.2178036],
    [36.3807227, 38.2108005],
    [36.3913574, 38.2157761],
    [36.3940737, 38.2131083],
    [36.3890484, 38.2085195],
    [36.3909498, 38.2061716],
    [36.3860301, 38.1980188],
    [36.3893201, 38.1908016],
    [36.3889127, 38.1862114],
    [36.3852456, 38.1830087],
    [36.3738914, 38.1763946],
    [36.372207, 38.1722253],
    [36.3682682, 38.1692355],
    [36.3609342, 38.1636828],
    [36.3606625, 38.1596248],
    [36.3516985, 38.1484108],
    [36.3626707, 38.1411665],
    [36.3480314, 38.1224515],
    [36.3511553, 38.1128347],
    [36.3582178, 38.1047128],
    [36.3571312, 38.0970175],
    [36.352785, 38.0914593],
    [36.3462658, 38.089749],
    [36.3427345, 38.0859007],
    [36.343142, 38.0824798],
    [36.3516984, 38.0685808],
    [36.3492538, 38.0535027],
    [36.3406973, 38.0582082],
    [36.3321715, 38.0675738],
    [36.3192213, 38.0618438],
    [36.2899255, 38.0545151],
    [36.2740687, 38.0543591],
    [36.2660884, 38.0537064],
    [36.2654039, 38.0532632],
    [36.2653196, 38.0528779],
    [36.2650918, 38.051815],
    [36.2646278, 38.0512835],
    [36.2641047, 38.0498086],
    [36.263666, 38.0493502],
    [36.2627633, 38.049337],
    [36.2619449, 38.0490513],
    [36.2613712, 38.0485065],
    [36.2612447, 38.0477292],
    [36.260865, 38.0460616],
    [36.2600045, 38.0440816],
    [36.2591693, 38.0433242],
    [36.2581738, 38.0420751],
    [36.2565624, 38.0418492],
    [36.25555, 38.0417229],
    [36.2551366, 38.0411648],
    [36.2547907, 38.0397628],
    [36.2542178, 38.0385618],
    [36.2542852, 38.0377258],
    [36.2536534, 38.0365447],
    [36.2539061, 38.0356357],
    [36.2555068, 38.0347532],
    [36.2558269, 38.0340034],
    [36.2555236, 38.0325435],
    [36.2559617, 38.031515],
    [36.2563492, 38.0305329],
    [36.2566694, 38.0300684],
    [36.2568715, 38.0294977],
    [36.2554225, 38.0275201],
    [36.2544032, 38.0260336],
    [36.2532069, 38.0253965],
    [36.2516736, 38.0249984],
    [36.2508733, 38.0250647],
    [36.2504099, 38.0258213],
    [36.249559, 38.0268034],
    [36.2484133, 38.0272547],
    [36.2476719, 38.0269892],
    [36.2469811, 38.0266176],
    [36.2453383, 38.0249851],
    [36.244757, 38.0238569],
    [36.2439399, 38.0223039],
    [36.2435355, 38.0218991],
    [36.2424656, 38.0217266],
    [36.2411008, 38.0218128],
    [36.2393485, 38.021846],
    [36.2376299, 38.0215739],
    [36.2350014, 38.0222641],
    [36.2343106, 38.0219854],
    [36.2337967, 38.0215806],
    [36.2336029, 38.0203859],
    [36.2342011, 38.0195762],
    [36.2349593, 38.018229],
    [36.2349172, 38.0175055],
    [36.2342516, 38.0165431],
    [36.2337967, 38.013875],
    [36.2326762, 38.0128262],
    [36.2323982, 38.012428],
    [36.2315474, 38.0113394],
    [36.2309071, 38.0103239],
    [36.2309913, 38.0090959],
    [36.2300815, 38.0078546],
    [36.2298624, 38.0059495],
    [36.2288262, 38.0051994],
    [36.2283966, 38.0045621],
    [36.227015, 38.0034602],
    [36.2261978, 38.0017541],
    [36.2262062, 38.0011832],
    [36.2270318, 38.0004994],
    [36.2271919, 37.9997293],
    [36.227571, 37.9983087],
    [36.2285566, 37.9973593],
    [36.2297445, 37.996151],
    [36.229972, 37.9957062],
    [36.2297529, 37.9951352],
    [36.2290537, 37.9951021],
    [36.2280933, 37.9947037],
    [36.2270739, 37.9937809],
    [36.2258187, 37.9928978],
    [36.2251363, 37.9921078],
    [36.2251531, 37.991477],
    [36.2258018, 37.9909459],
    [36.2261219, 37.9901093],
    [36.2257681, 37.9893856],
    [36.2245718, 37.9888478],
    [36.2222804, 37.9873804],
    [36.2209745, 37.986484],
    [36.2198288, 37.9845053],
    [36.2194918, 37.9838148],
    [36.2191043, 37.9826594],
    [36.2187252, 37.9820285],
    [36.217411, 37.9807337],
    [36.2157008, 37.9791134],
    [36.215229, 37.9783962],
    [36.2149089, 37.9766896],
    [36.2155407, 37.9759923],
    [36.2157008, 37.9750294],
    [36.2153975, 37.9743786],
    [36.2148078, 37.9737875],
    [36.2140496, 37.9723464],
    [36.2130386, 37.9714764],
    [36.2118255, 37.970613],
    [36.2120291, 37.9697771],
    [36.2122492, 37.9689859],
    [36.2125473, 37.9684028],
    [36.2132675, 37.9673134],
    [36.2132161, 37.9661567],
    [36.2133663, 37.9658317],
    [36.2132914, 37.9650806],
    [36.2125416, 37.9637257],
    [36.2122214, 37.963294],
    [36.2117665, 37.9622977],
    [36.2101743, 37.9613744],
    [36.2080597, 37.9602253],
    [36.2066444, 37.9588039],
    [36.2056756, 37.9576614],
    [36.205684, 37.9566318],
    [36.2054734, 37.9558214],
    [36.2056503, 37.9549313],
    [36.2058778, 37.9538419],
    [36.2053976, 37.9525598],
    [36.2048584, 37.9506001],
    [36.2044624, 37.949205],
    [36.2047152, 37.9480956],
    [36.2042097, 37.9476106],
    [36.2039654, 37.9470725],
    [36.2035273, 37.9455645],
    [36.2027354, 37.9447739],
    [36.202727, 37.9442092],
    [36.2026006, 37.9441029],
    [36.2015307, 37.943485],
    [36.2013032, 37.9430332],
    [36.2014886, 37.9425682],
    [36.2026017, 37.9418083],
    [36.2046617, 37.9401161],
    [36.2052291, 37.9391591],
    [36.2051353, 37.9386579],
    [36.2045758, 37.9384915],
    [36.2035065, 37.9381147],
    [36.2028333, 37.9372044],
    [36.2024355, 37.9366664],
    [36.2019986, 37.9355958],
    [36.2018762, 37.9349539],
  ],
  Gaziantep: [
    [36.4623101, 36.888135],
    [36.4658546, 36.8956158],
    [36.4637946, 36.9012442],
    [36.4637946, 36.9075585],
    [36.4665412, 36.9157251],
    [36.4708327, 36.9199796],
    [36.4708349, 36.9260633],
    [36.4696031, 36.9321392],
    [36.4712679, 36.938786],
    [36.4761274, 36.9454767],
    [36.4688891, 36.9530131],
    [36.4660262, 36.9572995],
    [36.4682483, 36.9613871],
    [36.473783, 36.9679895],
    [36.4803765, 36.973005],
    [36.491003, 36.9783525],
    [36.4946937, 36.9839062],
    [36.4918536, 36.9950209],
    [36.4969253, 36.9988512],
    [36.4993964, 37.0038797],
    [36.4976977, 37.0094755],
    [36.5013026, 37.012217],
    [36.5062808, 37.016192],
    [36.5155359, 37.0185623],
    [36.5240478, 37.0196871],
    [36.5307875, 37.0239133],
    [36.5394448, 37.0317743],
    [36.5480924, 37.040981],
    [36.5532745, 37.0469477],
    [36.5622653, 37.0513246],
    [36.5696239, 37.0546977],
    [36.5720239, 37.0728118],
    [36.5735384, 37.0927793],
    [36.5781426, 37.0932351],
    [36.5827607, 37.0952047],
    [36.5880042, 37.1024389],
    [36.594091, 37.101326],
    [36.5958452, 37.1023573],
    [36.5974974, 37.1040858],
    [36.6040376, 37.1044903],
    [36.6080919, 37.110553],
    [36.6175618, 37.1185609],
    [36.6237938, 37.1261465],
    [36.6215291, 37.1336876],
    [36.6217232, 37.1388009],
    [36.6180331, 37.1410695],
    [36.6211867, 37.1425982],
    [36.6216911, 37.1445393],
    [36.6256856, 37.1537221],
    [36.6371251, 37.1555041],
    [36.6462247, 37.1578684],
    [36.6479138, 37.1619606],
    [36.6565253, 37.1710981],
    [36.6795332, 37.1839792],
    [36.693138, 37.2161761],
    [36.7055668, 37.217726],
    [36.7139999, 37.2197566],
    [36.7176929, 37.2183265],
    [36.7200504, 37.2187448],
    [36.7232869, 37.220189],
    [36.7271716, 37.2226348],
    [36.7405898, 37.2293467],
    [36.7423232, 37.2309926],
    [36.7445302, 37.2347702],
    [36.7444623, 37.2355304],
    [36.7447454, 37.2357512],
    [36.7452818, 37.2360414],
    [36.7459558, 37.2366776],
    [36.746391, 37.2369043],
    [36.7492238, 37.2361236],
    [36.7508295, 37.2347702],
    [36.752415, 37.2330845],
    [36.7533876, 37.2331934],
    [36.7545492, 37.2335354],
    [36.7555794, 37.2336856],
    [36.756456, 37.2335665],
    [36.7586465, 37.2329943],
    [36.7601757, 37.2323617],
    [36.7602053, 37.2321581],
    [36.7606525, 37.2321509],
    [36.7607535, 37.232325],
    [36.7617618, 37.2320679],
    [36.7619834, 37.232204],
    [36.7622355, 37.232544],
    [36.7633852, 37.2320497],
    [36.7656333, 37.2311544],
    [36.7660738, 37.2311437],
    [36.7661923, 37.2306241],
    [36.7660424, 37.2301699],
    [36.7661736, 37.2296828],
    [36.7664471, 37.2296315],
    [36.7675585, 37.2301345],
    [36.768319, 37.2306555],
    [36.7688151, 37.23023],
    [36.7687968, 37.2295431],
    [36.76967, 37.2292223],
    [36.7704991, 37.2283586],
    [36.7714989, 37.2283798],
    [36.7737549, 37.2298797],
    [36.773522, 37.2313175],
    [36.7749333, 37.2318019],
    [36.7754356, 37.2338098],
    [36.7765157, 37.2336115],
    [36.776742, 37.2341021],
    [36.7811455, 37.2343726],
    [36.7814484, 37.234851],
    [36.7815533, 37.2357057],
    [36.7842273, 37.2358365],
    [36.7854679, 37.2360198],
    [36.7853642, 37.2370767],
    [36.7855663, 37.2374888],
    [36.7869211, 37.2371197],
    [36.7877341, 37.2370332],
    [36.7881709, 37.2370544],
    [36.7885703, 37.2371796],
    [36.789201, 37.2376085],
    [36.7896591, 37.2377674],
    [36.790286, 37.238074],
    [36.7909612, 37.2383281],
    [36.7919243, 37.2385143],
    [36.7928664, 37.2388123],
    [36.793145, 37.238824],
    [36.7938894, 37.2387378],
    [36.7943131, 37.2388596],
    [36.7945966, 37.2387278],
    [36.7948449, 37.2386391],
    [36.7951866, 37.238714],
    [36.7954404, 37.2387521],
    [36.7956809, 37.238675],
    [36.796096, 37.2385288],
    [36.7963392, 37.2385102],
    [36.796389, 37.2383982],
    [36.796351, 37.2382707],
    [36.7961672, 37.2381102],
    [36.7960847, 37.2379389],
    [36.796298, 37.2377987],
    [36.7966835, 37.2376872],
    [36.7971118, 37.2377193],
    [36.7975684, 37.2376987],
    [36.7980576, 37.2375888],
    [36.7982953, 37.2375828],
    [36.798706, 37.2375458],
    [36.799088, 37.2375017],
    [36.7994884, 37.2373753],
    [36.8000476, 37.2372974],
    [36.8002677, 37.2371888],
    [36.8004187, 37.2371189],
    [36.8007009, 37.2372029],
    [36.801243, 37.2371914],
    [36.8015243, 37.2370457],
    [36.8017651, 37.236799],
    [36.8020738, 37.2367061],
    [36.8027329, 37.2367826],
    [36.803737, 37.2369927],
    [36.8052204, 37.2369792],
    [36.8058072, 37.2369062],
    [36.8074514, 37.2364542],
    [36.809301, 37.2360468],
    [36.809978, 37.2363465],
    [36.8102534, 37.2366686],
    [36.8132467, 37.2343783],
    [36.8133196, 37.2352537],
    [36.8133502, 37.2375228],
    [36.8144884, 37.2373942],
    [36.8148085, 37.237482],
    [36.8153175, 37.2373564],
    [36.8169654, 37.2388773],
    [36.8166871, 37.2393347],
    [36.8180779, 37.2410972],
    [36.8193115, 37.2427288],
    [36.8211253, 37.2438009],
    [36.8221985, 37.2441489],
    [36.8241924, 37.2444668],
    [36.8263867, 37.2444422],
    [36.8278014, 37.2444234],
    [36.8288068, 37.2444933],
    [36.8302341, 37.2446923],
    [36.8322072, 37.2449034],
    [36.8336686, 37.2446679],
    [36.834254, 37.2445809],
    [36.8356647, 37.244187],
    [36.8379658, 37.2433718],
    [36.837868, 37.2437869],
    [36.8375042, 37.2445703],
    [36.8373247, 37.2449718],
    [36.8373413, 37.245189],
    [36.8374425, 37.2455147],
    [36.8375772, 37.2457366],
    [36.83787, 37.2459825],
    [36.838241, 37.2461579],
    [36.8383212, 37.2462006],
    [36.8383281, 37.2463151],
    [36.8381378, 37.2465234],
    [36.8375195, 37.2471764],
    [36.8374203, 37.2474028],
    [36.837288, 37.2476701],
    [36.8371437, 37.2478614],
    [36.8369351, 37.2480368],
    [36.8367694, 37.2482035],
    [36.8366841, 37.2483539],
    [36.8366777, 37.248652],
    [36.8366121, 37.24903],
    [36.8365067, 37.2493127],
    [36.8364807, 37.2495264],
    [36.8365092, 37.2497387],
    [36.8365064, 37.2499217],
    [36.8362928, 37.2502099],
    [36.8360654, 37.2504981],
    [36.8360263, 37.2506258],
    [36.8361166, 37.2507831],
    [36.8363956, 37.2511641],
    [36.8365493, 37.2513038],
    [36.836814, 37.2515361],
    [36.8370007, 37.2517523],
    [36.837074, 37.2519764],
    [36.8370782, 37.2521761],
    [36.8368456, 37.2523734],
    [36.8358906, 37.2532816],
    [36.8359256, 37.253526],
    [36.8362327, 37.2539844],
    [36.8363177, 37.2540755],
    [36.8365665, 37.2541493],
    [36.8369361, 37.2542795],
    [36.8372362, 37.254358],
    [36.8373933, 37.2544347],
    [36.8376127, 37.2546469],
    [36.8383886, 37.255221],
    [36.8385658, 37.2552679],
    [36.8387211, 37.2551959],
    [36.8392463, 37.2547383],
    [36.8394641, 37.2547599],
    [36.8404404, 37.2548566],
    [36.8413227, 37.2548735],
    [36.8420023, 37.2549735],
    [36.842267, 37.2550448],
    [36.8428026, 37.2553493],
    [36.8435841, 37.2557202],
    [36.8438922, 37.256054],
    [36.8442332, 37.2564593],
    [36.844359, 37.2567885],
    [36.844451, 37.2572367],
    [36.8447056, 37.2577556],
    [36.845028, 37.2585169],
    [36.8454107, 37.2598075],
    [36.8458943, 37.2611491],
    [36.8460043, 37.2617241],
    [36.8462769, 37.2621567],
    [36.8470179, 37.2621818],
    [36.8477062, 37.2623256],
    [36.8521428, 37.2634355],
    [36.8519675, 37.2642909],
    [36.8521799, 37.2650377],
    [36.8527212, 37.2658467],
    [36.8540503, 37.2668926],
    [36.8550253, 37.2679035],
    [36.8553899, 37.2688548],
    [36.8555758, 37.2698033],
    [36.8554981, 37.2715919],
    [36.8550989, 37.2726055],
    [36.8542589, 37.2730762],
    [36.8545648, 37.2737241],
    [36.8547034, 37.2754266],
    [36.8541038, 37.2760611],
    [36.8544083, 37.276597],
    [36.855182, 37.2770257],
    [36.875996, 37.2911661],
    [36.8795266, 37.3117905],
    [36.8879857, 37.3182651],
    [36.8905875, 37.3230932],
    [36.8957914, 37.3237829],
    [36.9067772, 37.3166557],
    [36.9173976, 37.3164616],
    [36.9358321, 37.3218507],
    [36.951412, 37.3137523],
    [36.9593809, 37.3060104],
    [36.9729422, 37.2986368],
    [36.9750264, 37.2948353],
    [36.9747109, 37.2890338],
    [36.9711573, 37.2846487],
    [36.9701806, 37.2828146],
    [36.9708822, 37.2796533],
    [36.9732082, 37.2800634],
    [36.9749164, 37.2782873],
    [36.9768905, 37.2789703],
    [36.9792937, 37.2778093],
    [36.9796371, 37.2756238],
    [36.9798087, 37.2719356],
    [36.9815604, 37.2703377],
    [36.9844033, 37.2699621],
    [36.9902801, 37.2758286],
    [37.0028113, 37.2705012],
    [37.0077037, 37.2716624],
    [37.0090555, 37.2701074],
    [37.009077, 37.2684521],
    [37.0073604, 37.2667445],
    [37.0036697, 37.2652417],
    [36.998434, 37.2642171],
    [36.9978332, 37.2621678],
    [37.0022964, 37.2586156],
    [37.0005797, 37.2567029],
    [37.0074461, 37.25479],
    [37.012596, 37.2549266],
    [37.0134543, 37.2485046],
    [37.0169523, 37.2481453],
    [37.0170592, 37.245635],
    [37.0192908, 37.2433119],
    [37.0270155, 37.2409887],
    [37.0285605, 37.2342921],
    [37.027194, 37.229135],
    [37.0202475, 37.2265178],
    [37.0087949, 37.2211598],
    [37.0060003, 37.2171578],
    [37.0066053, 37.2144215],
    [37.0155143, 37.2138569],
    [37.0190333, 37.2155657],
    [37.0220374, 37.2152923],
    [37.0225524, 37.2175479],
    [37.0238399, 37.2198035],
    [37.0271014, 37.2187099],
    [37.0296764, 37.2178213],
    [37.0352553, 37.2149506],
    [37.0393752, 37.2162493],
    [37.0495032, 37.2088669],
    [37.0494842, 37.2059283],
    [37.0557583, 37.2027736],
    [37.0631503, 37.2032613],
    [37.0647811, 37.2043551],
    [37.0713042, 37.2029878],
    [37.075323, 37.2046548],
    [37.0778274, 37.2050387],
    [37.0784282, 37.2010736],
    [37.0806598, 37.1993644],
    [37.0818614, 37.1995012],
    [37.0837497, 37.2022358],
    [37.0842558, 37.2048927],
    [37.0876121, 37.210234],
    [37.0908736, 37.2113278],
    [37.0940494, 37.2103024],
    [37.0998859, 37.2172062],
    [37.1000575, 37.2188466],
    [37.1076978, 37.2213531],
    [37.1105289, 37.2247928],
    [37.1102766, 37.2347263],
    [37.1135329, 37.2378454],
    [37.1142196, 37.2411936],
    [37.1197986, 37.2427652],
    [37.1219443, 37.2461815],
    [37.1189403, 37.2472064],
    [37.1169319, 37.2472091],
    [37.1158504, 37.2485045],
    [37.1120738, 37.249256],
    [37.1113013, 37.2541751],
    [37.1148204, 37.2558147],
    [37.1161079, 37.2592304],
    [37.1206569, 37.2594353],
    [37.1270942, 37.2627143],
    [37.1307849, 37.2658565],
    [37.1325873, 37.266198],
    [37.1359347, 37.2674275],
    [37.1402263, 37.2641488],
    [37.1488952, 37.2634657],
    [37.1521567, 37.2639439],
    [37.1542167, 37.2632608],
    [37.15559, 37.26046],
    [37.1593665, 37.2601868],
    [37.1618435, 37.2631408],
    [37.1646022, 37.2632608],
    [37.1668338, 37.2657199],
    [37.1684646, 37.2655833],
    [37.1708678, 37.2680423],
    [37.1728419, 37.2690668],
    [37.1745362, 37.2731793],
    [37.1792792, 37.2775361],
    [37.1895387, 37.2741079],
    [37.1973895, 37.2747359],
    [37.1968745, 37.2790386],
    [37.1952437, 37.2816338],
    [37.1959086, 37.2840171],
    [37.1948146, 37.2859361],
    [37.2087192, 37.2957007],
    [37.2083757, 37.3001371],
    [37.2052859, 37.3048497],
    [37.2071742, 37.3091507],
    [37.2089767, 37.3116765],
    [37.2176456, 37.3157724],
    [37.219963, 37.3159771],
    [37.2198772, 37.3195267],
    [37.2233104, 37.3223935],
    [37.2237395, 37.327922],
    [37.2269951, 37.3320763],
    [37.2268294, 37.3350879],
    [37.230606, 37.3374082],
    [37.2294902, 37.3417073],
    [37.2305202, 37.3455968],
    [37.2336101, 37.3458697],
    [37.2348117, 37.3467568],
    [37.2362708, 37.3465521],
    [37.2376441, 37.3412297],
    [37.2420215, 37.343618],
    [37.2439956, 37.3440956],
    [37.2467422, 37.345665],
    [37.2509479, 37.3449145],
    [37.252407, 37.3423215],
    [37.2494357, 37.339395],
    [37.2500896, 37.338773],
    [37.2530078, 37.3391825],
    [37.2537803, 37.3375447],
    [37.2566985, 37.3376812],
    [37.2577285, 37.3369305],
    [37.2583293, 37.3372717],
    [37.2582435, 37.3391142],
    [37.2591018, 37.3400014],
    [37.2639941, 37.3391825],
    [37.2707532, 37.3409082],
    [37.2703456, 37.3424579],
    [37.2715472, 37.3427309],
    [37.2741222, 37.3414344],
    [37.2763538, 37.3414344],
    [37.2845586, 37.3343442],
    [37.2866534, 37.3346785],
    [37.2879409, 37.3338595],
    [37.2894, 37.3348832],
    [37.2912025, 37.3318121],
    [37.2881126, 37.3292187],
    [37.2887134, 37.3277172],
    [37.2881126, 37.3252601],
    [37.2859668, 37.3223252],
    [37.285881, 37.320141],
    [37.2900008, 37.3208236],
    [37.2958991, 37.3187242],
    [37.2978973, 37.3174106],
    [37.2998714, 37.3171376],
    [37.2991847, 37.3160454],
    [37.3006438, 37.3148849],
    [37.3013305, 37.3144071],
    [37.3022746, 37.3150897],
    [37.3030471, 37.3146802],
    [37.3032188, 37.3122227],
    [37.3036479, 37.3107891],
    [37.3035621, 37.3088093],
    [37.3047637, 37.3065565],
    [37.3070811, 37.3055324],
    [37.3093127, 37.3067613],
    [37.3107719, 37.3062151],
    [37.3130035, 37.3079218],
    [37.3118018, 37.3096286],
    [37.3130893, 37.3111304],
    [37.3104285, 37.3143388],
    [37.3109435, 37.3153628],
    [37.3125743, 37.3157724],
    [37.3128318, 37.3177519],
    [37.3169517, 37.3197997],
    [37.31781, 37.3227347],
    [37.3212432, 37.3240998],
    [37.3220157, 37.3251919],
    [37.3245906, 37.3251919],
    [37.3300838, 37.3226665],
    [37.3338603, 37.322803],
    [37.3378845, 37.3257875],
    [37.3390102, 37.3245093],
    [37.3427009, 37.3249871],
    [37.3453616, 37.3235538],
    [37.3475932, 37.3245093],
    [37.3525714, 37.3242363],
    [37.354288, 37.3234173],
    [37.3573779, 37.3253966],
    [37.3599007, 37.3250663],
    [37.3627853, 37.3238268],
    [37.364931, 37.3245093],
    [37.3670768, 37.3232808],
    [37.3698234, 37.3243728],
    [37.3735999, 37.3238268],
    [37.3755546, 37.3232004],
    [37.3778915, 37.3240998],
    [37.381153, 37.3237586],
    [37.3860951, 37.3230348],
    [37.3871771, 37.3255288],
    [37.3948001, 37.328195],
    [37.3971175, 37.3275125],
    [37.3967742, 37.3301059],
    [37.398405, 37.3314709],
    [37.3984567, 37.3333111],
    [37.4017524, 37.3355656],
    [37.4055289, 37.3362481],
    [37.4086188, 37.3382271],
    [37.4077605, 37.3414344],
    [37.4084116, 37.3421212],
    [37.4109363, 37.3421168],
    [37.413082, 37.3410249],
    [37.4182333, 37.3413782],
    [37.4202918, 37.3398649],
    [37.4255275, 37.3393872],
    [37.4246692, 37.3418438],
    [37.4279265, 37.3438042],
    [37.4497737, 37.3590958],
    [37.447071, 37.3663373],
    [37.4484443, 37.3698844],
    [37.4469852, 37.3738406],
    [37.447634, 37.3782306],
    [37.4499034, 37.3803202],
    [37.4549493, 37.3796035],
    [37.456185, 37.3807932],
    [37.4584006, 37.3837303],
    [37.4539374, 37.3839349],
    [37.4520492, 37.3856398],
    [37.4496459, 37.3855716],
    [37.4480151, 37.3862536],
    [37.4412337, 37.3863443],
    [37.4379729, 37.3876858],
    [37.4360847, 37.3894588],
    [37.4349689, 37.3899362],
    [37.4328231, 37.3917774],
    [37.4328231, 37.3944369],
    [37.4311923, 37.39771],
    [37.4316883, 37.3998978],
    [37.4342822, 37.4031647],
    [37.436428, 37.4033011],
    [37.4384021, 37.4035056],
    [37.4402904, 37.4029602],
    [37.4448516, 37.4036589],
    [37.4452196, 37.4041571],
    [37.4496678, 37.4058728],
    [37.453274, 37.4073423],
    [37.454965, 37.4105728],
    [37.4558858, 37.4135399],
    [37.4602031, 37.4166636],
    [37.4662112, 37.4174135],
    [37.4720937, 37.4148942],
    [37.4795437, 37.4157025],
    [37.4872462, 37.414016],
    [37.4931621, 37.4180952],
    [37.4965953, 37.4181633],
    [37.4980544, 37.4197994],
    [37.4998569, 37.4190495],
    [37.5056933, 37.4187769],
    [37.50741, 37.4168],
    [37.5033759, 37.411278],
    [37.5056933, 37.4098463],
    [37.5084297, 37.4092365],
    [37.5133635, 37.410718],
    [37.5204562, 37.4189814],
    [37.5251769, 37.42089],
    [37.5320434, 37.4198675],
    [37.5366816, 37.414563],
    [37.5468062, 37.4138686],
    [37.5502395, 37.4168681],
    [37.5539302, 37.4172771],
    [37.5580501, 37.4193222],
    [37.565689, 37.4210945],
    [37.5668906, 37.422526],
    [37.5627843, 37.4432985],
    [37.5617409, 37.446005],
    [37.5603673, 37.4485819],
    [37.5580716, 37.4529723],
    [37.5579965, 37.4544713],
    [37.5578999, 37.4550164],
    [37.5576854, 37.4554507],
    [37.5575995, 37.4558596],
    [37.557503, 37.4561363],
    [37.557444, 37.4563535],
    [37.557342, 37.4566601],
    [37.5572509, 37.4570945],
    [37.5570416, 37.4576864],
    [37.5569934, 37.458027],
    [37.5570363, 37.4583677],
    [37.5568968, 37.4588829],
    [37.557047, 37.4591257],
    [37.557342, 37.4598027],
    [37.5577575, 37.4607116],
    [37.5535869, 37.4602115],
    [37.553029, 37.4612846],
    [37.5520634, 37.4626471],
    [37.5515699, 37.4637202],
    [37.5508618, 37.4646143],
    [37.5499177, 37.4691531],
    [37.5495744, 37.4703112],
    [37.5502181, 37.4723037],
    [37.558887, 37.4745856],
    [37.5597453, 37.4744494],
    [37.5618052, 37.4746708],
    [37.5625777, 37.4746197],
    [37.5653457, 37.4751135],
    [37.5672769, 37.4752327],
    [37.5704956, 37.475948],
    [37.5719782, 37.4754927],
    [37.5736069, 37.4752838],
    [37.5756454, 37.475369],
    [37.5773835, 37.4756925],
    [37.5783705, 37.4755222],
    [37.5790572, 37.4751348],
    [37.5795026, 37.474367],
    [37.5798082, 37.4741599],
    [37.581675, 37.4743643],
    [37.5828123, 37.4741599],
    [37.583456, 37.4743132],
    [37.584545, 37.4742578],
    [37.5850224, 37.4739897],
    [37.6240984, 37.4865489],
    [37.6301035, 37.4869958],
    [37.6232922, 37.5004912],
    [37.6233566, 37.5008998],
    [37.6235604, 37.5012913],
    [37.6244402, 37.5015296],
    [37.6249981, 37.5019042],
    [37.6253307, 37.5022787],
    [37.6263606, 37.5025255],
    [37.627809, 37.5028745],
    [37.6287235, 37.5034082],
    [37.6294184, 37.5038107],
    [37.6297402, 37.5039043],
    [37.630223, 37.5038533],
    [37.631489, 37.503666],
    [37.6319933, 37.5036235],
    [37.6323688, 37.5039809],
    [37.633034, 37.5040661],
    [37.6336777, 37.5045257],
    [37.634536, 37.5049853],
    [37.6358528, 37.50608],
    [37.637937, 37.5080237],
    [37.6386237, 37.5088067],
    [37.6388168, 37.509062],
    [37.6387429, 37.50961],
    [37.6386559, 37.5106365],
    [37.6386344, 37.5116067],
    [37.6385892, 37.5119571],
    [37.6385164, 37.5127045],
    [37.6385915, 37.513113],
    [37.6387739, 37.5135556],
    [37.6494776, 37.5116811],
    [37.6591771, 37.5144651],
    [37.6710903, 37.5169996],
    [37.6828534, 37.5186852],
    [37.6899901, 37.5193538],
    [37.7011166, 37.5206699],
    [37.719488, 37.5265555],
    [37.7314597, 37.527081],
    [37.7478151, 37.5221903],
    [37.7579785, 37.5149596],
    [37.7713513, 37.5045566],
    [37.7850846, 37.4957965],
    [37.797996, 37.4859832],
    [37.8173538, 37.4758988],
    [37.8376565, 37.4697604],
    [37.8610024, 37.4666591],
    [37.8977106, 37.466166],
    [37.9308229, 37.4678043],
    [37.9677736, 37.4729765],
    [37.9835159, 37.4745578],
    [38.0000034, 37.4754649],
    [38.0258471, 37.4733466],
    [38.0416631, 37.4729179],
    [38.0531507, 37.4708624],
    [38.0631272, 37.4684609],
    [38.0760636, 37.463924],
    [38.0796738, 37.4610584],
    [38.0801251, 37.4585509],
    [38.0810748, 37.4540667],
    [38.0809598, 37.4479079],
    [38.0750868, 37.4441156],
    [38.0701453, 37.4357315],
    [38.0669329, 37.4302809],
    [38.0626413, 37.4291222],
    [38.0596486, 37.4299798],
    [38.0588841, 37.4305205],
    [38.0516992, 37.4356008],
    [38.0450225, 37.4397497],
    [38.0403108, 37.4418418],
    [38.0371689, 37.4426835],
    [38.0283092, 37.4424801],
    [38.0193828, 37.4394134],
    [38.0104564, 37.4353925],
    [38.0073352, 37.4357991],
    [38.0050491, 37.4363467],
    [37.995436, 37.4398223],
    [37.9895137, 37.4403675],
    [37.9850505, 37.4398223],
    [37.9794358, 37.4385865],
    [37.9750941, 37.4349836],
    [37.9697726, 37.428168],
    [37.9683135, 37.4212154],
    [37.9634212, 37.415012],
    [37.9591366, 37.4127574],
    [37.9496883, 37.4077855],
    [37.9398177, 37.3995355],
    [37.9378183, 37.3940202],
    [37.9376493, 37.3935539],
    [37.9367278, 37.3910118],
    [37.9338954, 37.3863063],
    [37.9326858, 37.3855654],
    [37.9215357, 37.3787361],
    [37.9171378, 37.3733362],
    [37.9035114, 37.3594999],
    [37.9001834, 37.3573625],
    [37.8927825, 37.3526091],
    [37.8821395, 37.3480377],
    [37.8741573, 37.3426472],
    [37.8738785, 37.3369373],
    [37.8805465, 37.3327633],
    [37.8856586, 37.3307047],
    [37.887976, 37.3266097],
    [37.8865653, 37.3247144],
    [37.8854869, 37.3232653],
    [37.8791656, 37.3205412],
    [37.8663467, 37.318419],
    [37.8615401, 37.3150742],
    [37.8613491, 37.3142935],
    [37.8591369, 37.3052438],
    [37.8551028, 37.2960266],
    [37.8533004, 37.2874229],
    [37.849953, 37.2803207],
    [37.8430865, 37.2720566],
    [37.8408549, 37.2677535],
    [37.8412419, 37.2638532],
    [37.8417133, 37.2624255],
    [37.8488372, 37.2498554],
    [37.8640016, 37.2441193],
    [37.8645442, 37.2434329],
    [37.8745006, 37.2333198],
    [37.8810237, 37.225119],
    [37.882397, 37.2176008],
    [37.8786205, 37.2134996],
    [37.8747176, 37.2119372],
    [37.8697125, 37.2130502],
    [37.8633196, 37.2172092],
    [37.8564761, 37.2230686],
    [37.8484081, 37.2247089],
    [37.8402613, 37.2238913],
    [37.8386234, 37.2191045],
    [37.8363918, 37.2133629],
    [37.8361712, 37.2121339],
    [37.8351901, 37.2066638],
    [37.8374217, 37.1977764],
    [37.8417133, 37.1877939],
    [37.8441616, 37.1791532],
    [37.8502963, 37.1693297],
    [37.8633426, 37.1544182],
    [37.8738829, 37.1428142],
    [37.8757022, 37.1405985],
    [37.8777621, 37.1333456],
    [37.878156, 37.1284212],
    [37.8783186, 37.1263882],
    [37.8762172, 37.1182902],
    [37.8758396, 37.1160185],
    [37.8743289, 37.1069282],
    [37.8691791, 37.0961122],
    [37.8666536, 37.0786053],
    [37.8695224, 37.0705035],
    [37.8741573, 37.0643397],
    [37.8772451, 37.0622421],
    [37.8868602, 37.0557096],
    [37.8949283, 37.0509146],
    [37.9021381, 37.0489966],
    [37.9031472, 37.0481669],
    [37.9078029, 37.0443382],
    [37.9133225, 37.0419382],
    [37.9227675, 37.0402752],
    [37.9335521, 37.0403647],
    [37.9398238, 37.0425423],
    [37.9441951, 37.0444752],
    [37.9526065, 37.0525587],
    [37.9602975, 37.0556319],
    [37.9623912, 37.0561206],
    [37.9721759, 37.0568055],
    [37.977669, 37.0535177],
    [37.9812739, 37.0470786],
    [37.9805873, 37.0400906],
    [37.9763196, 37.0320383],
    [37.9744196, 37.0332851],
    [37.9720866, 37.0342773],
    [37.9718451, 37.0345533],
    [37.9718733, 37.035237],
    [37.9706431, 37.035559],
    [37.9670876, 37.0366557],
    [37.9665769, 37.0364389],
    [37.9658562, 37.0358557],
    [37.9651482, 37.0358529],
    [37.9626273, 37.0362846],
    [37.9623454, 37.0368622],
    [37.9623222, 37.0371864],
    [37.9594263, 37.0376998],
    [37.9571853, 37.03769],
    [37.9561329, 37.0376272],
    [37.9565915, 37.0372419],
    [37.9565248, 37.0371164],
    [37.9535226, 37.0372188],
    [37.9549976, 37.0339314],
    [37.951972, 37.0335831],
    [37.9516241, 37.033567],
    [37.9521196, 37.0317277],
    [37.9528381, 37.0300547],
    [37.9539027, 37.0282922],
    [37.9558409, 37.0276465],
    [37.9567518, 37.0257091],
    [37.9583322, 37.0251351],
    [37.9582469, 37.0232945],
    [37.9583023, 37.0224587],
    [37.9597914, 37.0218028],
    [37.9594242, 37.0209572],
    [37.9603385, 37.0208728],
    [37.9601872, 37.0202857],
    [37.9610019, 37.0202113],
    [37.9624369, 37.0200283],
    [37.9639714, 37.0206969],
    [37.9654806, 37.0219182],
    [37.9670264, 37.0206783],
    [37.9677338, 37.0199532],
    [37.969974, 37.018712],
    [37.9671977, 37.0133665],
    [37.9636937, 37.0061988],
    [37.9623912, 36.9995211],
    [37.9641646, 36.9938893],
    [37.9663846, 36.9869313],
    [37.9730526, 36.9783323],
    [37.9780425, 36.9733272],
    [37.990887, 36.9663367],
    [38.0041049, 36.9623591],
    [38.0074337, 36.9581198],
    [38.0081181, 36.9535595],
    [38.0087398, 36.9476813],
    [38.0075381, 36.9402727],
    [38.0071948, 36.9305307],
    [38.0099414, 36.9169447],
    [38.0144046, 36.9082978],
    [38.0214427, 36.898689],
    [38.0312274, 36.8911384],
    [38.0361096, 36.8861003],
    [38.0386045, 36.8833233],
    [38.0383225, 36.8794322],
    [38.0370639, 36.8738378],
    [38.0305408, 36.8673834],
    [38.0292139, 36.8652602],
    [38.0269359, 36.8616151],
    [38.0241893, 36.8558465],
    [38.0221293, 36.8430715],
    [38.0217309, 36.8397802],
    [38.0214361, 36.8373447],
    [38.0250356, 36.8303399],
    [38.0242114, 36.8297837],
    [38.0213326, 36.8278034],
    [38.0194215, 36.826467],
    [38.0184769, 36.8259766],
    [38.0179331, 36.8258029],
    [38.0173823, 36.8256672],
    [38.0165775, 36.8255349],
    [38.0156752, 36.8255113],
    [38.0147876, 36.8255904],
    [38.013928, 36.8257946],
    [38.0132394, 36.8260597],
    [38.0125762, 36.8263738],
    [38.0119594, 36.8268025],
    [38.011115, 36.8275426],
    [38.0105361, 36.8280438],
    [38.0098466, 36.8285414],
    [38.0096611, 36.8286274],
    [38.0095095, 36.8286111],
    [38.0082493, 36.829101],
    [38.0047193, 36.829874],
    [38.0045362, 36.8292736],
    [38.0025196, 36.8297303],
    [38.0008927, 36.8301406],
    [38.0005708, 36.8287809],
    [37.9989091, 36.82915],
    [37.9991984, 36.8299634],
    [37.9959506, 36.8306783],
    [37.9960701, 36.831179],
    [37.9960148, 36.831695],
    [37.9955802, 36.8318604],
    [37.9952936, 36.8319074],
    [37.9947857, 36.8319276],
    [37.9941786, 36.8319824],
    [37.9940077, 36.8320481],
    [37.9937564, 36.8320139],
    [37.9933487, 36.8319708],
    [37.9929784, 36.8318999],
    [37.9924505, 36.8317737],
    [37.991619, 36.8314793],
    [37.9655, 36.8215008],
    [37.9645296, 36.8211165],
    [37.9635, 36.8207962],
    [37.9624393, 36.8205863],
    [37.9613696, 36.8204739],
    [37.9450041, 36.8192271],
    [37.9425102, 36.8189951],
    [37.9407121, 36.8185642],
    [37.9406617, 36.8185445],
    [37.9395044, 36.8180913],
    [37.9383911, 36.8175863],
    [37.9262845, 36.8116288],
    [37.9249165, 36.8109384],
    [37.9241789, 36.8103911],
    [37.9228226, 36.8092014],
    [37.9056429, 36.793259],
    [37.9045959, 36.7923786],
    [37.9035013, 36.7917964],
    [37.9029977, 36.7915956],
    [37.9019165, 36.7912046],
    [37.9006243, 36.7909253],
    [37.8974338, 36.7904743],
    [37.8802271, 36.7881864],
    [37.878846, 36.7879195],
    [37.8776629, 36.7876063],
    [37.8768949, 36.787354],
    [37.8756782, 36.7869433],
    [37.8745081, 36.7865189],
    [37.8282463, 36.7691094],
    [37.8259216, 36.7683653],
    [37.8244517, 36.7680325],
    [37.8229895, 36.7677574],
    [37.8042302, 36.7643723],
    [37.8015535, 36.7638344],
    [37.7997093, 36.7632778],
    [37.7986799, 36.7628318],
    [37.797636, 36.762248],
    [37.7966635, 36.7615407],
    [37.7959735, 36.7608507],
    [37.7920732, 36.7566945],
    [37.7881325, 36.752471],
    [37.7874489, 36.7518071],
    [37.786429, 36.7510674],
    [37.7852439, 36.7503647],
    [37.7844075, 36.7500084],
    [37.7833776, 36.7496219],
    [37.7822836, 36.7493388],
    [37.7812149, 36.7491471],
    [37.7803221, 36.7490544],
    [37.7794333, 36.7490073],
    [37.7776396, 36.7490892],
    [37.7466155, 36.7507601],
    [37.7387416, 36.751175],
    [37.7298095, 36.7516462],
    [37.7288513, 36.751702],
    [37.7278733, 36.751785],
    [37.7266836, 36.7520229],
    [37.7260638, 36.752185],
    [37.7076717, 36.756996],
    [37.7065993, 36.757277],
    [37.705435, 36.7575433],
    [37.7041739, 36.7576817],
    [37.7027913, 36.757683],
    [37.7015364, 36.7575403],
    [37.7003116, 36.7572644],
    [37.69877, 36.7566813],
    [37.697607, 36.7560689],
    [37.696853, 36.7555371],
    [37.6961075, 36.7549496],
    [37.693159, 36.7526592],
    [37.6924804, 36.7521345],
    [37.6912341, 36.7510862],
    [37.6899685, 36.7497386],
    [37.687827, 36.7475733],
    [37.6858283, 36.7455508],
    [37.6850232, 36.7449073],
    [37.6837244, 36.7443108],
    [37.6827436, 36.7439893],
    [37.6818308, 36.7438376],
    [37.6803317, 36.7437662],
    [37.6785709, 36.7437662],
    [37.6756442, 36.743671],
    [37.674383, 36.74379],
    [37.6732885, 36.7440041],
    [37.6723605, 36.7443848],
    [37.6716229, 36.7448131],
    [37.6704569, 36.7457411],
    [37.6693386, 36.7466215],
    [37.6681013, 36.7476685],
    [37.6672209, 36.7482872],
    [37.6662691, 36.7487155],
    [37.6653411, 36.7490962],
    [37.6642941, 36.7494055],
    [37.6631758, 36.7495721],
    [37.6617481, 36.7496672],
    [37.6605346, 36.7496196],
    [37.6592735, 36.7493817],
    [37.6581789, 36.7490486],
    [37.6568226, 36.7484299],
    [37.6551808, 36.7474543],
    [37.6548953, 36.7465739],
    [37.6494939, 36.7430047],
    [37.6477807, 36.7422909],
    [37.6449729, 36.7404111],
    [37.6395001, 36.736604],
    [37.6353123, 36.7337249],
    [37.6292447, 36.729656],
    [37.6237243, 36.7258012],
    [37.6220349, 36.7247781],
    [37.6176329, 36.7228269],
    [37.6154438, 36.7217562],
    [37.6137306, 36.7211613],
    [37.6071633, 36.718758],
    [37.5997631, 36.716093],
    [37.5974026, 36.7151604],
    [37.5805679, 36.709122],
    [37.5788001, 36.7084074],
    [37.5761113, 36.7072652],
    [37.5727431, 36.7058108],
    [37.5651, 36.7158],
    [37.5579, 36.7202],
    [37.5545, 36.7252],
    [37.5508, 36.7264],
    [37.5442, 36.7225],
    [37.5417, 36.7288],
    [37.5441, 36.7309],
    [37.5393, 36.7427],
    [37.5312, 36.7442],
    [37.5187, 36.7407],
    [37.518053, 36.7387466],
    [37.5168, 36.7373],
    [37.5163, 36.7396],
    [37.5114, 36.7388],
    [37.5105, 36.7421],
    [37.5086, 36.7425],
    [37.5072, 36.7505],
    [37.5017, 36.7526],
    [37.4914, 36.752],
    [37.4909, 36.7572],
    [37.4875, 36.7651],
    [37.4957, 36.7676],
    [37.4973, 36.767],
    [37.4988, 36.7708],
    [37.4928, 36.7759],
    [37.4911, 36.7747],
    [37.493, 36.7841],
    [37.4896, 36.7846],
    [37.4893, 36.7821],
    [37.4763, 36.7837],
    [37.477, 36.7814],
    [37.4631, 36.7859],
    [37.458, 36.7857],
    [37.4533, 36.7885],
    [37.4527, 36.7931],
    [37.4468, 36.7946],
    [37.4474, 36.8084],
    [37.449, 36.8087],
    [37.4493, 36.8135],
    [37.4452, 36.8134],
    [37.4454, 36.8162],
    [37.4436, 36.8146],
    [37.4338, 36.8151],
    [37.4326, 36.817],
    [37.4306, 36.8164],
    [37.4224, 36.8195],
    [37.4151, 36.8258],
    [37.4094, 36.8265],
    [37.4056, 36.8144],
    [37.4038, 36.8141],
    [37.4031, 36.8105],
    [37.4047, 36.807],
    [37.3807, 36.8041],
    [37.3818, 36.7941],
    [37.385, 36.7872],
    [37.3831, 36.7833],
    [37.3863, 36.7839],
    [37.3826, 36.7758],
    [37.391, 36.771],
    [37.3945, 36.7708],
    [37.401, 36.7688],
    [37.3975, 36.7595],
    [37.3844, 36.7488],
    [37.3858, 36.7419],
    [37.3927, 36.7285],
    [37.3832, 36.7276],
    [37.3691, 36.7238],
    [37.3693, 36.7262],
    [37.3661, 36.7286],
    [37.3648, 36.7327],
    [37.3596, 36.7307],
    [37.3583314, 36.7328734],
    [37.3518563, 36.7427124],
    [37.3413676, 36.7479737],
    [37.3156964, 36.7602706],
    [37.3059052, 36.7693862],
    [37.3028984, 36.7824591],
    [37.3027879, 36.8197464],
    [37.3093829, 36.8354829],
    [37.3083661, 36.8464653],
    [37.2981374, 36.8596611],
    [37.2969409, 36.8603722],
    [37.2779583, 36.8716512],
    [37.2569488, 36.8773291],
    [37.2211356, 36.8806151],
    [37.1890691, 36.8807972],
    [37.1648166, 36.8833982],
    [37.1526636, 36.8909464],
    [37.1251632, 36.8951049],
    [37.1134665, 36.8951014],
    [37.1019499, 36.8980269],
    [37.0907044, 36.9038777],
    [37.0891752, 36.9043492],
    [37.0851696, 36.9089562],
    [37.0794729, 36.9166396],
    [37.0747577, 36.9214128],
    [37.0681833, 36.9247783],
    [37.0655177, 36.9298278],
    [37.0680007, 36.9336403],
    [37.0687283, 36.9353195],
    [37.0695065, 36.9364561],
    [37.06873, 36.9370355],
    [37.0692255, 36.937656],
    [37.0687309, 36.938501],
    [37.0678259, 36.9391931],
    [37.0678774, 36.9398929],
    [37.0666761, 36.9405341],
    [37.0651756, 36.9407241],
    [37.0636789, 36.9414706],
    [37.0630822, 36.9412671],
    [37.0630972, 36.9415852],
    [37.0632565, 36.9418323],
    [37.0631953, 36.9420902],
    [37.0627836, 36.942479],
    [37.0625817, 36.9435875],
    [37.0616134, 36.9444596],
    [37.061343, 36.9453797],
    [37.0616284, 36.9456026],
    [37.0615173, 36.9459383],
    [37.0613202, 36.9468736],
    [37.0616693, 36.9472691],
    [37.0620939, 36.9472529],
    [37.0626751, 36.9474254],
    [37.0629502, 36.94701],
    [37.0633179, 36.9467482],
    [37.0644866, 36.9461496],
    [37.0646345, 36.9472525],
    [37.0649691, 36.9473807],
    [37.0648664, 36.9479431],
    [37.0649257, 36.9485425],
    [37.0651789, 36.9491517],
    [37.0658505, 36.9494327],
    [37.0662037, 36.9497241],
    [37.0669356, 36.950374],
    [37.0675862, 36.950024],
    [37.068668, 36.9508916],
    [37.068963, 36.9515777],
    [37.0694586, 36.9515869],
    [37.069774, 36.9516346],
    [37.0700115, 36.9515473],
    [37.0702922, 36.9512076],
    [37.070167, 36.9521746],
    [37.0701858, 36.9535966],
    [37.0700524, 36.9539233],
    [37.0705404, 36.9552296],
    [37.0715302, 36.9550263],
    [37.0733874, 36.9539719],
    [37.0750006, 36.953484],
    [37.0752141, 36.9547959],
    [37.0750786, 36.955952],
    [37.0750362, 36.9563141],
    [37.074989, 36.9564213],
    [37.0744176, 36.9568459],
    [37.0742318, 36.9569873],
    [37.0740279, 36.9572382],
    [37.0734055, 36.9585457],
    [37.0730882, 36.9593235],
    [37.0730373, 36.9594893],
    [37.0730204, 36.9596856],
    [37.0729676, 36.9598136],
    [37.0728769, 36.9596337],
    [37.0727363, 36.9595019],
    [37.0724155, 36.9594569],
    [37.0723625, 36.9594318],
    [37.0723492, 36.9592953],
    [37.0725149, 36.9590466],
    [37.072546, 36.9588584],
    [37.0724836, 36.9587147],
    [37.0720786, 36.9582104],
    [37.0717727, 36.9580873],
    [37.0713755, 36.9580004],
    [37.0713643, 36.9576867],
    [37.0712545, 36.9576456],
    [37.0708411, 36.957521],
    [37.0707165, 36.9572304],
    [37.0705868, 36.9571504],
    [37.0702691, 36.9570259],
    [37.0701655, 36.9568367],
    [37.0665512, 36.9613493],
    [37.0647831, 36.9643634],
    [37.0662953, 36.9658707],
    [37.0647134, 36.9658984],
    [37.0647876, 36.9669498],
    [37.0633675, 36.967066],
    [37.0634452, 36.9672951],
    [37.0628861, 36.9672639],
    [37.0610032, 36.9669924],
    [37.0608405, 36.9678521],
    [37.0590785, 36.9668739],
    [37.0591346, 36.9671169],
    [37.0574004, 36.9674254],
    [37.0576153, 36.967906],
    [37.0548082, 36.9678221],
    [37.054994, 36.9684109],
    [37.0526527, 36.9684852],
    [37.0523556, 36.9691188],
    [37.0522725, 36.9692958],
    [37.0516604, 36.9700754],
    [37.0509454, 36.970759],
    [37.049124, 36.9714603],
    [37.049095, 36.9716729],
    [37.0490195, 36.9722266],
    [37.0495621, 36.9724872],
    [37.0495666, 36.973044],
    [37.0500258, 36.9732526],
    [37.0503933, 36.9736619],
    [37.0502542, 36.9739454],
    [37.0505742, 36.9745057],
    [37.0505988, 36.9753741],
    [37.0509164, 36.975506],
    [37.0506609, 36.9757115],
    [37.0508808, 36.9759663],
    [37.0511704, 36.975975],
    [37.0513644, 36.9763771],
    [37.0516064, 36.9765155],
    [37.0524993, 36.9764895],
    [37.0532395, 36.9769605],
    [37.0532818, 36.9773328],
    [37.0545966, 36.978277],
    [37.055278, 36.9790907],
    [37.0549562, 36.9798145],
    [37.0566391, 36.9815439],
    [37.0573824, 36.982559],
    [37.059468, 36.9832956],
    [37.0620744, 36.9847939],
    [37.0634455, 36.985179],
    [37.064959, 36.9853451],
    [37.0653054, 36.988316],
    [37.0664082, 36.9880163],
    [37.066049, 36.9904197],
    [37.0650196, 36.993057],
    [37.0642133, 36.9945052],
    [37.0637425, 36.9959725],
    [37.0625304, 36.9956912],
    [37.0625083, 36.9956861],
    [37.0619532, 36.9958221],
    [37.0617379, 36.9961164],
    [37.0615254, 36.9963848],
    [37.0612222, 36.996886],
    [37.0608944, 36.9968585],
    [37.0601164, 36.9959538],
    [37.0592619, 36.995572],
    [37.0584808, 36.9956763],
    [37.0582855, 36.9959879],
    [37.0584829, 36.9965322],
    [37.058093, 36.996938],
    [37.0572689, 36.9967164],
    [37.0561756, 36.9969179],
    [37.0552891, 36.9968507],
    [37.0541966, 36.9970573],
    [37.0533494, 36.9970981],
    [37.0524067, 36.9979955],
    [37.0515265, 36.9981053],
    [37.0503813, 36.9965833],
    [37.0484034, 36.9963269],
    [37.0459573, 36.9981864],
    [37.041082, 36.9998531],
    [37.039777, 36.9973788],
    [37.0388894, 36.9953763],
    [37.0372713, 36.993781],
    [37.0359264, 36.9952468],
    [37.0342451, 36.9964143],
    [37.0320981, 36.9950408],
    [37.0307639, 36.9943107],
    [37.028406, 36.9936958],
    [37.0248516, 36.9926658],
    [37.0234687, 36.9898626],
    [37.0234471, 36.9900837],
    [37.0229757, 36.990322],
    [37.0224801, 36.9905727],
    [37.0221977, 36.9911307],
    [37.0220465, 36.9913453],
    [37.0215166, 36.9920975],
    [37.0211158, 36.9926662],
    [37.0204374, 36.9932245],
    [37.0195538, 36.9937043],
    [37.0189903, 36.9938896],
    [37.0183196, 36.994461],
    [37.0180663, 36.9958271],
    [37.0170977, 36.9960648],
    [37.0169031, 36.9961434],
    [37.0163691, 36.9972438],
    [37.0163131, 36.9974511],
    [37.0164015, 36.9977856],
    [37.0164911, 36.9981247],
    [37.0165195, 36.9982321],
    [37.0167266, 36.9984268],
    [37.0172841, 36.9991211],
    [37.0173909, 36.9992541],
    [37.0178058, 37.0002648],
    [37.0177906, 37.000495],
    [37.0176831, 37.0007778],
    [37.0175691, 37.0008269],
    [37.0172884, 37.0009476],
    [37.0167713, 37.0011701],
    [37.0155, 37.0017911],
    [37.0150655, 37.0020033],
    [37.014178, 37.0026501],
    [37.013421, 37.0033586],
    [37.0122024, 37.004033],
    [37.0111411, 37.0043638],
    [37.0108056, 37.0045743],
    [37.0102732, 37.0045794],
    [37.0084754, 37.0047248],
    [37.008322, 37.0049869],
    [37.0086913, 37.0054465],
    [37.0090038, 37.0056402],
    [37.0093701, 37.0059996],
    [37.0095413, 37.0064408],
    [37.0094487, 37.0066437],
    [37.0089031, 37.0075534],
    [37.0080264, 37.0084225],
    [37.0052366, 37.0100003],
    [37.0049608, 37.0118145],
    [37.0061155, 37.0156802],
    [37.0000496, 37.0225141],
    [37.0017657, 37.0262281],
    [37.0015263, 37.0300643],
    [37.0026987, 37.030048],
    [37.0041835, 37.0314461],
    [36.9937195, 37.0346424],
    [36.9768539, 37.0244718],
    [36.950866, 37.0138314],
    [36.9521499, 37.0001942],
    [36.9400307, 36.9840696],
    [36.9364714, 36.9801164],
    [36.9339507, 36.9769311],
    [36.932057, 36.9697734],
    [36.9285786, 36.9620971],
    [36.9268845, 36.9559572],
    [36.9190982, 36.949653],
    [36.9045935, 36.9443877],
    [36.8950299, 36.9383138],
    [36.8897757, 36.9320726],
    [36.884886, 36.9298951],
    [36.8847787, 36.9296641],
    [36.8843677, 36.929463],
    [36.8840627, 36.9291284],
    [36.8832614, 36.9287726],
    [36.882622, 36.9279701],
    [36.8819112, 36.9277086],
    [36.8805201, 36.9275423],
    [36.8802431, 36.9274255],
    [36.880163, 36.9271996],
    [36.8804891, 36.9266739],
    [36.8818574, 36.9261647],
    [36.8822317, 36.9258429],
    [36.8820704, 36.9255957],
    [36.8821325, 36.9249997],
    [36.8823618, 36.9245061],
    [36.8828919, 36.9229112],
    [36.8835637, 36.9214773],
    [36.8843074, 36.9211079],
    [36.8855457, 36.9209147],
    [36.8868863, 36.9205897],
    [36.8873283, 36.9202799],
    [36.8871458, 36.9193192],
    [36.8868936, 36.9188583],
    [36.8865728, 36.9178798],
    [36.8862865, 36.9172493],
    [36.886361, 36.9165076],
    [36.8865793, 36.9161181],
    [36.8872645, 36.9155703],
    [36.8879342, 36.9151792],
    [36.8888576, 36.9148724],
    [36.889239, 36.914578],
    [36.8893658, 36.9139494],
    [36.8891044, 36.9128169],
    [36.8888377, 36.9123701],
    [36.888067, 36.9119513],
    [36.887267, 36.9111047],
    [36.8868771, 36.9106761],
    [36.8863291, 36.9103651],
    [36.8861434, 36.9100821],
    [36.885918, 36.9098855],
    [36.8850708, 36.9097376],
    [36.8849107, 36.909606],
    [36.884964, 36.9093716],
    [36.8848777, 36.9087752],
    [36.8846007, 36.9081661],
    [36.8841545, 36.90794],
    [36.8837082, 36.9076575],
    [36.8833141, 36.9076219],
    [36.8828158, 36.907557],
    [36.8825001, 36.9074489],
    [36.8820682, 36.9072291],
    [36.8818864, 36.9069717],
    [36.8819352, 36.9065988],
    [36.8821535, 36.9062185],
    [36.8828946, 36.9056457],
    [36.8833127, 36.9053885],
    [36.88364, 36.9053214],
    [36.8840188, 36.9050997],
    [36.8845825, 36.9041983],
    [36.8847311, 36.9035761],
    [36.884566, 36.9026449],
    [36.8839259, 36.9018357],
    [36.8833249, 36.9013622],
    [36.8819402, 36.9011476],
    [36.8810476, 36.9011476],
    [36.8805937, 36.9008462],
    [36.8803831, 36.9004844],
    [36.8803517, 36.900051],
    [36.8806585, 36.8994247],
    [36.8812125, 36.8988904],
    [36.882804, 36.8980352],
    [36.883077, 36.8977747],
    [36.8833921, 36.8974005],
    [36.8832428, 36.8972076],
    [36.882877, 36.8969476],
    [36.8827402, 36.8964265],
    [36.8825548, 36.8958499],
    [36.87229, 36.8916584],
    [36.8618915, 36.8918071],
    [36.8530935, 36.896317],
    [36.8392704, 36.906104],
    [36.828222, 36.9097821],
    [36.8149215, 36.9198619],
    [36.7988775, 36.9231361],
    [36.7855918, 36.9223121],
    [36.7821773, 36.9076509],
    [36.7767104, 36.8979707],
    [36.7681871, 36.8885094],
    [36.7602356, 36.8802029],
    [36.7554534, 36.8758375],
    [36.7473592, 36.8705724],
    [36.7204744, 36.8573248],
    [36.7140768, 36.8503678],
    [36.718872, 36.8377013],
    [36.7181967, 36.8311344],
    [36.7123606, 36.8267519],
    [36.7118633, 36.8266317],
    [36.7115798, 36.8262721],
    [36.7115798, 36.826019],
    [36.7113553, 36.8257285],
    [36.7108439, 36.8256751],
    [36.7105992, 36.8258642],
    [36.710385, 36.8261021],
    [36.7101233, 36.8261735],
    [36.7099329, 36.8260783],
    [36.7097664, 36.8261021],
    [36.7095284, 36.8261973],
    [36.7091953, 36.8262449],
    [36.7089336, 36.8261735],
    [36.7088622, 36.8260545],
    [36.7087913, 36.8258222],
    [36.7082846, 36.825608],
    [36.7081246, 36.8257928],
    [36.70772, 36.825888],
    [36.7076011, 36.8260307],
    [36.7074826, 36.8260535],
    [36.7070062, 36.8261259],
    [36.7067207, 36.8261735],
    [36.7065541, 36.8262925],
    [36.7062686, 36.8262687],
    [36.7060782, 36.8261497],
    [36.7056499, 36.8260783],
    [36.7053882, 36.8261497],
    [36.705174, 36.8260783],
    [36.7050313, 36.8259831],
    [36.7050551, 36.8254121],
    [36.7049599, 36.8252455],
    [36.7047933, 36.8252693],
    [36.7045792, 36.8254597],
    [36.7043174, 36.8256738],
    [36.7041271, 36.8257452],
    [36.7041033, 36.8259118],
    [36.7038891, 36.8262925],
    [36.7037463, 36.8263876],
    [36.7035322, 36.8263401],
    [36.7033656, 36.8263163],
    [36.7030325, 36.8265542],
    [36.7028659, 36.8265542],
    [36.7026756, 36.826459],
    [36.7021283, 36.8259831],
    [36.7017, 36.8258642],
    [36.700891, 36.8259593],
    [36.7004389, 36.8260545],
    [36.6999392, 36.8261497],
    [36.6994157, 36.8262687],
    [36.6987733, 36.8265066],
    [36.6979881, 36.8269825],
    [36.6978729, 36.8271513],
    [36.6975544, 36.8272107],
    [36.6970839, 36.8270301],
    [36.6967983, 36.8271015],
    [36.6965842, 36.8270301],
    [36.6957752, 36.8269825],
    [36.6950851, 36.8270539],
    [36.694633, 36.8271491],
    [36.6941571, 36.827268],
    [36.6936098, 36.8271729],
    [36.6930626, 36.8271491],
    [36.6919024, 36.8265114],
    [36.6918966, 36.8262925],
    [36.6916587, 36.8262449],
    [36.6915397, 36.8264114],
    [36.6911513, 36.8264561],
    [36.6909211, 36.8264828],
    [36.6905403, 36.8264828],
    [36.69035, 36.8262925],
    [36.6901358, 36.8259355],
    [36.6897313, 36.825888],
    [36.689541, 36.8256976],
    [36.6887795, 36.8257928],
    [36.688494, 36.8259593],
    [36.6878753, 36.8261259],
    [36.6872091, 36.8260307],
    [36.6861859, 36.8256976],
    [36.6858528, 36.8256976],
    [36.6856624, 36.82565],
    [36.6853531, 36.8254121],
    [36.6851866, 36.8252455],
    [36.6850438, 36.8252931],
    [36.6849724, 36.8255072],
    [36.6841872, 36.8259593],
    [36.683402, 36.8263163],
    [36.6826405, 36.8265066],
    [36.6821408, 36.8265066],
    [36.6819267, 36.8262687],
    [36.6817839, 36.8260069],
    [36.6814984, 36.8257452],
    [36.6812129, 36.8256262],
    [36.6809035, 36.8256738],
    [36.680737, 36.8258166],
    [36.6804276, 36.8259118],
    [36.6792141, 36.8259831],
    [36.6788572, 36.8260307],
    [36.6786906, 36.8261259],
    [36.6785479, 36.8263638],
    [36.6785479, 36.8265542],
    [36.6784765, 36.8268635],
    [36.6782861, 36.8271491],
    [36.6780244, 36.8274822],
    [36.6777626, 36.8276012],
    [36.6775485, 36.827506],
    [36.6770814, 36.8274351],
    [36.676561, 36.8275185],
    [36.6752804, 36.8280171],
    [36.6746643, 36.8281062],
    [36.6739841, 36.8279755],
    [36.6735091, 36.827988],
    [36.6729814, 36.8280738],
    [36.6723207, 36.8283189],
    [36.6720239, 36.828358],
    [36.6719185, 36.8283433],
    [36.6715157, 36.8282864],
    [36.6714141, 36.8283125],
    [36.6708449, 36.828371],
    [36.6702351, 36.8286347],
    [36.6700847, 36.8288493],
    [36.6698448, 36.8290153],
    [36.6697066, 36.8289893],
    [36.6695033, 36.8287484],
    [36.6683447, 36.8284492],
    [36.6675031, 36.8277658],
    [36.6671086, 36.8277722],
    [36.6667754, 36.8279253],
    [36.666377, 36.8284329],
    [36.6662144, 36.828384],
    [36.6660395, 36.8284264],
    [36.6656696, 36.8283612],
    [36.6652386, 36.8281139],
    [36.6652291, 36.828],
    [36.6653457, 36.8277776],
    [36.6655258, 36.8276117],
    [36.6651945, 36.8267665],
    [36.6650717, 36.8269071],
    [36.664743, 36.8272213],
    [36.664577, 36.8273329],
    [36.6638646, 36.8273441],
    [36.6630629, 36.8279562],
    [36.6631661, 36.8283566],
    [36.6602289, 36.8305675],
    [36.6547806, 36.835108],
    [36.6401116, 36.8370407],
    [36.636168, 36.8412381],
    [36.6310119, 36.8526826],
    [36.6211483, 36.8660571],
    [36.6022017, 36.8782333],
    [36.5817223, 36.8783318],
    [36.5724176, 36.8745982],
    [36.5580292, 36.8728466],
    [36.5485387, 36.8720751],
    [36.5306109, 36.8734126],
    [36.5199042, 36.8856189],
    [36.5128898, 36.8823669],
    [36.5072243, 36.8837764],
    [36.5021609, 36.8830534],
    [36.4979982, 36.881646],
    [36.4885997, 36.8850443],
    [36.4816115, 36.8862928],
    [36.4781283, 36.8838772],
    [36.4730503, 36.8849013],
    [36.4701912, 36.8863707],
    [36.4675862, 36.8867467],
    [36.4651236, 36.8862731],
    [36.4623101, 36.888135],
  ],
  Malatya: [
    [37.3168659, 38.5780475],
    [37.3209568, 38.5691001],
    [37.3062229, 38.5675625],
    [37.3121452, 38.5506491],
    [37.3160934, 38.5469739],
    [37.3076391, 38.5464873],
    [37.2774052, 38.519782],
    [37.2715688, 38.5110011],
    [37.2692942, 38.5080795],
    [37.2664189, 38.5015139],
    [37.2606253, 38.4958882],
    [37.2624063, 38.4958546],
    [37.2660327, 38.4918575],
    [37.2615266, 38.488213],
    [37.2779631, 38.4777653],
    [37.2846579, 38.464964],
    [37.2855806, 38.4606628],
    [37.28513, 38.4558068],
    [37.2955155, 38.4548994],
    [37.3013091, 38.4513034],
    [37.3105788, 38.4399932],
    [37.3127031, 38.4406151],
    [37.3214793, 38.441573],
    [37.3252129, 38.4423125],
    [37.3247838, 38.4416571],
    [37.3274445, 38.4434385],
    [37.329247, 38.4432369],
    [37.3306632, 38.4443629],
    [37.3383021, 38.4347156],
    [37.3440528, 38.4376402],
    [37.3456621, 38.4401781],
    [37.354846, 38.4367494],
    [37.3586869, 38.4297402],
    [37.3608327, 38.4269498],
    [37.3595881, 38.4263447],
    [37.3579788, 38.4268658],
    [37.3489237, 38.4222261],
    [37.3531508, 38.4176365],
    [37.3627853, 38.415804],
    [37.3662615, 38.4166782],
    [37.3663687, 38.4063379],
    [37.3716688, 38.4070105],
    [37.3716903, 38.4061697],
    [37.3720121, 38.4058166],
    [37.3729348, 38.4057157],
    [37.3735571, 38.4055476],
    [37.3742866, 38.4050095],
    [37.3755312, 38.404421],
    [37.3760462, 38.4043201],
    [37.3774195, 38.4044042],
    [37.3777199, 38.4043369],
    [37.3780203, 38.404236],
    [37.3783421, 38.4041856],
    [37.3784923, 38.4043537],
    [37.3783421, 38.4045555],
    [37.3781705, 38.4047237],
    [37.3782992, 38.4049254],
    [37.3785567, 38.405144],
    [37.3792004, 38.4053963],
    [37.3799085, 38.405598],
    [37.381947, 38.4057662],
    [37.3830628, 38.4058334],
    [37.3832989, 38.4056821],
    [37.3835134, 38.4054803],
    [37.3838997, 38.4052113],
    [37.3842001, 38.4052785],
    [37.4018168, 38.4128281],
    [37.4156999, 38.4065733],
    [37.4328446, 38.3992249],
    [37.4409985, 38.3940284],
    [37.4474787, 38.3916739],
    [37.4564695, 38.3912367],
    [37.4576068, 38.3851986],
    [37.4561047, 38.3813636],
    [37.4575424, 38.3754089],
    [37.4621558, 38.3784536],
    [37.4648809, 38.3768556],
    [37.4721336, 38.3777471],
    [37.4854159, 38.3773771],
    [37.4856519, 38.3786555],
    [37.4874973, 38.3810272],
    [37.4875617, 38.382642],
    [37.4997496, 38.3734071],
    [37.4998569, 38.3686798],
    [37.5017452, 38.3660217],
    [37.5079465, 38.3624717],
    [37.5151562, 38.3621352],
    [37.5212931, 38.3615968],
    [37.5274086, 38.3621352],
    [37.5325155, 38.3582821],
    [37.5383091, 38.3559601],
    [37.546699, 38.3497509],
    [37.5491881, 38.3484383],
    [37.5547457, 38.3472603],
    [37.5584364, 38.3484046],
    [37.559402, 38.3467723],
    [37.5741005, 38.3365734],
    [37.58322, 38.3384416],
    [37.598691, 38.3244036],
    [37.6046991, 38.3225014],
    [37.6120162, 38.3233936],
    [37.6147198, 38.3246393],
    [37.6180029, 38.3227875],
    [37.6261353, 38.3218953],
    [37.6281738, 38.3238986],
    [37.6344824, 38.3221478],
    [37.6434302, 38.3219458],
    [37.6477217, 38.3188146],
    [37.6555753, 38.3169458],
    [37.6758528, 38.3066081],
    [37.6735782, 38.3044191],
    [37.6721406, 38.2959657],
    [37.6709389, 38.2946185],
    [37.6678276, 38.2702796],
    [37.6685786, 38.2585872],
    [37.702825, 38.2519315],
    [37.717545, 38.2522517],
    [37.7364278, 38.2550488],
    [37.7339709, 38.2544001],
    [37.746039, 38.2422567],
    [37.7499461, 38.219234],
    [37.7506626, 38.2089976],
    [37.7516841, 38.1953926],
    [37.7512979, 38.1741749],
    [37.7486372, 38.1647616],
    [37.7351188, 38.164458],
    [37.7181244, 38.1577768],
    [37.7185643, 38.1560304],
    [37.7273941, 38.1487326],
    [37.7268791, 38.1329701],
    [37.7197551, 38.1266574],
    [37.7198839, 38.1247668],
    [37.715249, 38.1213907],
    [37.7100561, 38.1126798],
    [37.7040266, 38.1096069],
    [37.6908302, 38.0958287],
    [37.6744794, 38.0844123],
    [37.6550388, 38.0779603],
    [37.6480436, 38.0761698],
    [37.6312942, 38.0669365],
    [37.6310062, 38.0667777],
    [37.6248751, 38.0651455],
    [37.6240351, 38.0648992],
    [37.6198053, 38.0640071],
    [37.6124668, 38.0600622],
    [37.6089907, 38.0592343],
    [37.6047635, 38.0591667],
    [37.6011801, 38.0585754],
    [37.5958264, 38.0595638],
    [37.58793, 38.0577053],
    [37.5838208, 38.0564297],
    [37.5786495, 38.0540473],
    [37.57954, 38.0443819],
    [37.5784242, 38.0407655],
    [37.578789, 38.0377067],
    [37.5825763, 38.0349941],
    [37.5768793, 38.0260192],
    [37.5830591, 38.0180068],
    [37.5875115, 38.0146512],
    [37.5919425, 38.0146765],
    [37.6013303, 38.0131128],
    [37.5984871, 38.0082184],
    [37.5985515, 38.0042367],
    [37.5808275, 38.0057076],
    [37.5818682, 37.9976423],
    [37.5809777, 37.9933641],
    [37.5831771, 37.9879187],
    [37.5838959, 37.9829127],
    [37.5870716, 37.9799867],
    [37.5887346, 37.9794624],
    [37.5913095, 37.9771198],
    [37.5984442, 37.9735001],
    [37.5990343, 37.9716902],
    [37.5977039, 37.9696603],
    [37.6004076, 37.9659895],
    [37.6005149, 37.961185],
    [37.6032185, 37.9578859],
    [37.5987768, 37.9449082],
    [37.6157545, 37.9441663],
    [37.6320791, 37.943453],
    [37.6453399, 37.947108],
    [37.6854658, 37.928154],
    [37.6884655, 37.9253849],
    [37.6844787, 37.9209772],
    [37.6823759, 37.9133597],
    [37.6877403, 37.9077729],
    [37.6935768, 37.9078745],
    [37.7022028, 37.9126148],
    [37.7048206, 37.9169485],
    [37.7069234, 37.9157297],
    [37.7141761, 37.9138337],
    [37.7305698, 37.9129872],
    [37.7549028, 37.9093305],
    [37.7770471, 37.8992397],
    [37.7893638, 37.8975803],
    [37.7949536, 37.8935418],
    [37.8134715, 37.8908832],
    [37.8231811, 37.889503],
    [37.8447354, 37.8942868],
    [37.8541016, 37.8865564],
    [37.8835116, 37.8901724],
    [37.8948712, 37.891569],
    [37.9103637, 37.8988756],
    [37.9262638, 37.8965982],
    [37.9655957, 37.8910102],
    [37.9746938, 37.887979],
    [38.0056796, 37.8883193],
    [38.0147767, 37.8884193],
    [38.0320609, 37.890536],
    [38.0333161, 37.8908747],
    [38.0553532, 37.8963611],
    [38.0594194, 37.8992735],
    [38.073914, 37.9082046],
    [38.0867457, 37.9241594],
    [38.0947923, 37.9396793],
    [38.1120014, 37.9449928],
    [38.1425142, 37.9590872],
    [38.1492519, 37.9655497],
    [38.1588649, 37.9774581],
    [38.1674229, 37.9854617],
    [38.1689301, 37.9879176],
    [38.1573093, 37.9923833],
    [38.145411, 37.9925693],
    [38.1475996, 37.9960947],
    [38.1544447, 38.0070856],
    [38.1546807, 38.0087002],
    [38.1523311, 38.010729],
    [38.1528354, 38.0125295],
    [38.1502712, 38.013434],
    [38.1460869, 38.0171362],
    [38.1447565, 38.0220301],
    [38.1422138, 38.0253854],
    [38.1398535, 38.0254953],
    [38.1379223, 38.0278447],
    [38.1332874, 38.0296617],
    [38.1335878, 38.0333209],
    [38.1315601, 38.0356871],
    [38.1342423, 38.0387038],
    [38.1342745, 38.0426075],
    [38.1332552, 38.0452015],
    [38.1342959, 38.0461816],
    [38.1348753, 38.0477024],
    [38.137064, 38.0665116],
    [38.1503355, 38.0731209],
    [38.1295434, 38.0931654],
    [38.1317705, 38.1052243],
    [38.1486511, 38.1076822],
    [38.1564819, 38.1131973],
    [38.1592659, 38.1193167],
    [38.1704974, 38.116019],
    [38.1876462, 38.1036802],
    [38.1937593, 38.1408404],
    [38.2052135, 38.1414425],
    [38.2154396, 38.1482475],
    [38.2175731, 38.1588566],
    [38.2229375, 38.1675959],
    [38.2594681, 38.202546],
    [38.263235, 38.2100626],
    [38.2695865, 38.2066904],
    [38.2749939, 38.2084777],
    [38.271346, 38.201497],
    [38.2565832, 38.1881071],
    [38.2555532, 38.1840256],
    [38.2580852, 38.1787631],
    [38.2641363, 38.1747147],
    [38.2672106, 38.1713934],
    [38.270788, 38.1675285],
    [38.2791566, 38.1664149],
    [38.2675695, 38.1617923],
    [38.2728052, 38.159599],
    [38.282032, 38.1565619],
    [38.2831049, 38.1543347],
    [38.2905292, 38.1506225],
    [38.2910871, 38.1495426],
    [38.2904219, 38.1484288],
    [38.2897353, 38.148142],
    [38.2880401, 38.1472307],
    [38.2846284, 38.1468257],
    [38.281517, 38.1463364],
    [38.2755947, 38.1463364],
    [38.2685136, 38.1471802],
    [38.2754659, 38.1401936],
    [38.2784271, 38.1380671],
    [38.2848215, 38.1330376],
    [38.2913446, 38.1331051],
    [38.2974913, 38.1324398],
    [38.2997131, 38.132835],
    [38.301773, 38.1326325],
    [38.3071374, 38.1415943],
    [38.3094978, 38.1492726],
    [38.3113861, 38.1572706],
    [38.3098197, 38.1584854],
    [38.307867, 38.1579961],
    [38.2908725, 38.1601389],
    [38.2996273, 38.1663306],
    [38.3150501, 38.167431],
    [38.3212995, 38.1646604],
    [38.3103131, 38.1485639],
    [38.307867, 38.1413243],
    [38.3049273, 38.1364976],
    [38.3038974, 38.1317211],
    [38.3043909, 38.1290711],
    [38.3052277, 38.1275182],
    [38.3062791, 38.1266068],
    [38.3072233, 38.1278389],
    [38.3077168, 38.1280415],
    [38.3090043, 38.1276195],
    [38.3136177, 38.1263704],
    [38.318789, 38.1260159],
    [38.3209884, 38.1252817],
    [38.3242929, 38.1199389],
    [38.3184457, 38.1166302],
    [38.3136606, 38.1144017],
    [38.3109355, 38.112342],
    [38.3064508, 38.1121647],
    [38.3035433, 38.1113966],
    [38.2987046, 38.1103836],
    [38.3016014, 38.1088303],
    [38.3014726, 38.1068718],
    [38.3035969, 38.1051496],
    [38.3069873, 38.1063146],
    [38.3089399, 38.1067029],
    [38.3112359, 38.1068886],
    [38.312813, 38.1065509],
    [38.3140683, 38.1069393],
    [38.3269858, 38.1128486],
    [38.339629, 38.1153325],
    [38.3454609, 38.1164782],
    [38.3506206, 38.1191935],
    [38.3552126, 38.1188825],
    [38.3596873, 38.1186897],
    [38.3650212, 38.117423],
    [38.3747582, 38.1176906],
    [38.3781194, 38.1187235],
    [38.3797073, 38.1165289],
    [38.3829259, 38.1156173],
    [38.3840847, 38.1140978],
    [38.3871746, 38.1133888],
    [38.3910459, 38.1094061],
    [38.3958081, 38.1064144],
    [38.3992338, 38.1058587],
    [38.4018253, 38.1070765],
    [38.4054162, 38.1059173],
    [38.4104346, 38.1035624],
    [38.4151265, 38.1025569],
    [38.4165286, 38.1076485],
    [38.4163141, 38.1159549],
    [38.422451, 38.1159887],
    [38.4291503, 38.1258615],
    [38.4417411, 38.1751234],
    [38.4562602, 38.1819215],
    [38.4822085, 38.187279],
    [38.4919597, 38.1902752],
    [38.4933471, 38.1780209],
    [38.4951495, 38.1745798],
    [38.4949779, 38.1695866],
    [38.4971236, 38.1657402],
    [38.4963512, 38.1607463],
    [38.496437, 38.1582492],
    [38.5073397, 38.1586112],
    [38.5083675, 38.1573044],
    [38.5155509, 38.1542657],
    [38.5175943, 38.1532886],
    [38.5218429, 38.154436],
    [38.5236454, 38.1542672],
    [38.5258129, 38.1553758],
    [38.5270786, 38.1546384],
    [38.5281944, 38.1545709],
    [38.5295677, 38.1536598],
    [38.5300183, 38.154031],
    [38.5308981, 38.1539298],
    [38.532958, 38.1523099],
    [38.5335803, 38.1524111],
    [38.5348248, 38.151635],
    [38.5364438, 38.1515692],
    [38.5372924, 38.1508925],
    [38.5381078, 38.1497451],
    [38.5407686, 38.1492726],
    [38.5416698, 38.1495088],
    [38.5423994, 38.1490701],
    [38.5434722, 38.1487326],
    [38.5438682, 38.1478183],
    [38.5445022, 38.1473489],
    [38.5455322, 38.1471801],
    [38.5460472, 38.1475851],
    [38.5475492, 38.1474501],
    [38.5480642, 38.1466739],
    [38.5488367, 38.1468089],
    [38.5493516, 38.1458639],
    [38.5668182, 38.1390798],
    [38.5706805, 38.133814],
    [38.5756587, 38.133544],
    [38.5824023, 38.1270023],
    [38.5800361, 38.1259147],
    [38.5739972, 38.1243472],
    [38.5856494, 38.1196298],
    [38.5983084, 38.1139981],
    [38.6095716, 38.1039552],
    [38.6131561, 38.0979226],
    [38.618322, 38.0951616],
    [38.6244106, 38.0914211],
    [38.6359402, 38.0875318],
    [38.638084, 38.0878895],
    [38.6385512, 38.0862448],
    [38.6388516, 38.0857634],
    [38.6391931, 38.0850937],
    [38.638916, 38.0847121],
    [38.6384332, 38.0841421],
    [38.642435, 38.0782475],
    [38.6538993, 38.0706117],
    [38.6665727, 38.0620732],
    [38.6680609, 38.0613039],
    [38.6700494, 38.0603777],
    [38.672331, 38.059813],
    [38.6734468, 38.0593272],
    [38.6751312, 38.0579672],
    [38.6755013, 38.055323],
    [38.6786127, 38.0549935],
    [38.6818528, 38.0537727],
    [38.6823034, 38.0510904],
    [38.6825234, 38.0509214],
    [38.6827487, 38.0508623],
    [38.6830598, 38.050896],
    [38.6835587, 38.0509256],
    [38.6839342, 38.0508454],
    [38.6844331, 38.0508285],
    [38.6851036, 38.0506848],
    [38.6854792, 38.0506891],
    [38.6856347, 38.0507102],
    [38.6858332, 38.0509594],
    [38.6859512, 38.0510608],
    [38.68608, 38.0510481],
    [38.6863536, 38.0506215],
    [38.6865574, 38.0504272],
    [38.6867076, 38.050406],
    [38.6872601, 38.050499],
    [38.6877966, 38.0505201],
    [38.6883545, 38.0504145],
    [38.6886281, 38.0506342],
    [38.68873, 38.0506341],
    [38.6888748, 38.0505623],
    [38.689025, 38.0502793],
    [38.6895561, 38.0502159],
    [38.6900711, 38.0502117],
    [38.6903822, 38.0503427],
    [38.6905914, 38.050406],
    [38.6907015, 38.0503804],
    [38.690967, 38.0501315],
    [38.691321, 38.0500301],
    [38.6915731, 38.050047],
    [38.6920184, 38.0498991],
    [38.6925334, 38.0498104],
    [38.6926728, 38.049595],
    [38.6927962, 38.04954],
    [38.6930323, 38.0496161],
    [38.6937028, 38.0499878],
    [38.6941588, 38.0501272],
    [38.6946523, 38.0500808],
    [38.6950332, 38.0500216],
    [38.6952907, 38.0500681],
    [38.6955374, 38.0502075],
    [38.695575, 38.0504609],
    [38.695634, 38.0506003],
    [38.6957949, 38.0506468],
    [38.696031, 38.0505961],
    [38.6962455, 38.0501864],
    [38.6961973, 38.0497301],
    [38.6960578, 38.0494091],
    [38.6957467, 38.0490796],
    [38.6957359, 38.0489022],
    [38.6959827, 38.0486571],
    [38.6962348, 38.0485727],
    [38.6963689, 38.0486065],
    [38.6964762, 38.0487923],
    [38.6966103, 38.0488261],
    [38.6966801, 38.048729],
    [38.696664, 38.0484628],
    [38.6967605, 38.0482854],
    [38.6972272, 38.0479474],
    [38.6975759, 38.047694],
    [38.6981874, 38.047542],
    [38.6986971, 38.0473687],
    [38.6992121, 38.0471279],
    [38.7000757, 38.0467984],
    [38.7009555, 38.0465153],
    [38.7011808, 38.0464815],
    [38.7015617, 38.0465407],
    [38.7021357, 38.0465618],
    [38.7028384, 38.0466463],
    [38.7033856, 38.0466885],
    [38.703863, 38.0467012],
    [38.7045658, 38.046735],
    [38.7051451, 38.0466716],
    [38.7056172, 38.0465449],
    [38.7062234, 38.0463041],
    [38.7067491, 38.0461394],
    [38.7069529, 38.0461098],
    [38.707146, 38.0461605],
    [38.7072748, 38.0461182],
    [38.7072855, 38.0459957],
    [38.7073445, 38.0458521],
    [38.7074679, 38.0457465],
    [38.7077576, 38.0457042],
    [38.7080687, 38.0457887],
    [38.7082994, 38.0458521],
    [38.7084228, 38.0458225],
    [38.7085086, 38.0455606],
    [38.7086159, 38.0453198],
    [38.708809, 38.0452057],
    [38.7091094, 38.045193],
    [38.7093937, 38.0452902],
    [38.7096137, 38.0454465],
    [38.7098014, 38.0454381],
    [38.7099141, 38.0452522],
    [38.7100428, 38.0451001],
    [38.7102789, 38.0450579],
    [38.7109494, 38.0451592],
    [38.7113249, 38.0451592],
    [38.7116092, 38.0452606],
    [38.7118453, 38.0453832],
    [38.7120169, 38.045455],
    [38.7122101, 38.0454592],
    [38.7124354, 38.0453071],
    [38.7129611, 38.045155],
    [38.7134707, 38.0450367],
    [38.7138194, 38.0450241],
    [38.7141734, 38.0450874],
    [38.7144417, 38.0452184],
    [38.7146777, 38.0453029],
    [38.7154394, 38.0454212],
    [38.715933, 38.0455648],
    [38.7168717, 38.0459028],
    [38.7172902, 38.0462745],
    [38.7176871, 38.0466505],
    [38.7178963, 38.0467984],
    [38.7180787, 38.0468111],
    [38.7183845, 38.0467688],
    [38.7185347, 38.0468195],
    [38.7186313, 38.0468955],
    [38.7188941, 38.0469336],
    [38.7193501, 38.0471321],
    [38.7208607, 38.0511632],
    [38.7218284, 38.0526027],
    [38.7311411, 38.0531096],
    [38.7318277, 38.0539037],
    [38.7359905, 38.0464182],
    [38.7387799, 38.0431906],
    [38.7417943, 38.0411508],
    [38.7443589, 38.0388644],
    [38.746376, 38.0376138],
    [38.7473201, 38.0358561],
    [38.7545299, 38.0288927],
    [38.7637138, 38.0191902],
    [38.7568903, 38.0095202],
    [38.7515258, 37.9927807],
    [38.760581, 37.9870309],
    [38.7821245, 37.9974647],
    [38.791418, 38.0008306],
    [38.7943125, 38.0023176],
    [38.7974667, 38.0059697],
    [38.8100301, 38.0053484],
    [38.8255763, 38.0094188],
    [38.8337194, 38.0184295],
    [38.8375316, 38.0212809],
    [38.8389551, 38.0241261],
    [38.8465171, 38.0299924],
    [38.8472271, 38.0313012],
    [38.8508963, 38.035366],
    [38.8566899, 38.0369377],
    [38.8623118, 38.0422273],
    [38.8637924, 38.0433258],
    [38.8703752, 38.0429712],
    [38.8760876, 38.0462492],
    [38.8838768, 38.0473814],
    [38.8846278, 38.0483952],
    [38.8891554, 38.0502709],
    [38.8946056, 38.0494598],
    [38.9007854, 38.0522816],
    [38.8993573, 38.0542941],
    [38.8962364, 38.054664],
    [38.8908505, 38.0578911],
    [38.8835871, 38.0652234],
    [38.8738344, 38.0723355],
    [38.863084, 38.0760349],
    [38.88201, 38.0795987],
    [38.8948953, 38.0838972],
    [38.9009303, 38.0849992],
    [38.904401, 38.085451],
    [38.9128768, 38.087123],
    [38.9212453, 38.0880476],
    [38.9247171, 38.0888516],
    [38.9276933, 38.088149],
    [38.9300913, 38.0880814],
    [38.9330186, 38.0882563],
    [38.9358043, 38.0588542],
    [38.939495, 38.0608985],
    [38.9455032, 38.0622163],
    [38.9504814, 38.0627907],
    [38.9524126, 38.0640071],
    [38.9554596, 38.0644125],
    [38.9574336, 38.0656626],
    [38.9587211, 38.0670986],
    [38.9618754, 38.0680785],
    [38.9639568, 38.0685008],
    [38.9671969, 38.0698015],
    [38.9715459, 38.0715619],
    [38.9788807, 38.0719975],
    [38.9818096, 38.0716597],
    [38.9858437, 38.0721665],
    [38.9939319, 38.0740029],
    [39.0023875, 38.0703336],
    [39.0038574, 38.0716428],
    [39.007357, 38.071043],
    [39.0102088, 38.0728548],
    [39.012476, 38.0728244],
    [39.0144145, 38.074506],
    [39.0237441, 38.0752601],
    [39.030919, 38.081002],
    [39.036988, 38.0836269],
    [39.0403247, 38.087883],
    [39.0398097, 38.0892932],
    [39.042213, 38.0893185],
    [39.0437189, 38.0929893],
    [39.0466869, 38.0938529],
    [39.0517562, 38.1022617],
    [39.0525116, 38.102906],
    [39.0543597, 38.1050589],
    [39.0562499, 38.1134697],
    [39.0600308, 38.1256334],
    [39.0612084, 38.1288939],
    [39.0648578, 38.1328576],
    [39.0662134, 38.1353076],
    [39.0678844, 38.1423754],
    [39.0686679, 38.1438677],
    [39.0687427, 38.1450934],
    [39.0729222, 38.1458286],
    [39.072639, 38.1486461],
    [39.0725653, 38.149553],
    [39.0725337, 38.1501377],
    [39.0724753, 38.1508365],
    [39.0724217, 38.1515569],
    [39.0727883, 38.1518644],
    [39.0733756, 38.152331],
    [39.0735811, 38.1524377],
    [39.0745302, 38.1527653],
    [39.0784257, 38.1539035],
    [39.0837934, 38.1552116],
    [39.0874856, 38.1586006],
    [39.0900999, 38.1585777],
    [39.095156, 38.1595145],
    [39.1003432, 38.1618056],
    [39.1068045, 38.1638463],
    [39.1106695, 38.164769],
    [39.1134593, 38.1666491],
    [39.1153692, 38.1683553],
    [39.1219363, 38.1696787],
    [39.1259413, 38.1704074],
    [39.1317483, 38.1730787],
    [39.1319333, 38.173426],
    [39.1320483, 38.1742323],
    [39.1321562, 38.1752664],
    [39.1333936, 38.1765768],
    [39.1349495, 38.1775812],
    [39.1354741, 38.1782934],
    [39.1361756, 38.178616],
    [39.1367727, 38.1787397],
    [39.140468, 38.177239],
    [39.144182, 38.1784627],
    [39.1473599, 38.178241],
    [39.1475978, 38.17808],
    [39.1481636, 38.1781624],
    [39.1490809, 38.1782869],
    [39.154183, 38.1786864],
    [39.1596511, 38.1799327],
    [39.1625579, 38.1808915],
    [39.1649972, 38.1821378],
    [39.1658306, 38.1826011],
    [39.1663591, 38.1835598],
    [39.1663894, 38.1852472],
    [39.163188, 38.1836717],
    [39.1579639, 38.1827929],
    [39.154427, 38.182697],
    [39.1493654, 38.1840711],
    [39.145239, 38.185621],
    [39.143999, 38.1856529],
    [39.1417224, 38.1867234],
    [39.1388765, 38.1882253],
    [39.137352, 38.1889921],
    [39.136295, 38.1891359],
    [39.1353396, 38.1891519],
    [39.1336931, 38.1888803],
    [39.1321888, 38.1882892],
    [39.1303797, 38.1873465],
    [39.1279608, 38.1867394],
    [39.125989, 38.1868672],
    [39.1219983, 38.18831],
    [39.1157448, 38.1923192],
    [39.1117475, 38.1955929],
    [39.1076485, 38.2006914],
    [39.10654, 38.2038181],
    [39.110813, 38.2084593],
    [39.119683, 38.210644],
    [39.1286328, 38.2186526],
    [39.1311912, 38.2222205],
    [39.1326961, 38.2242238],
    [39.1345093, 38.2253854],
    [39.1354936, 38.2266579],
    [39.1388564, 38.2310564],
    [39.1413765, 38.2557199],
    [39.1455351, 38.2712578],
    [39.1463547, 38.2743196],
    [39.1502792, 38.2882926],
    [39.1511612, 38.2914325],
    [39.1514252, 38.2992521],
    [39.1433633, 38.3118548],
    [39.1209501, 38.3288456],
    [39.1190119, 38.3303145],
    [39.1131485, 38.3440357],
    [39.1062794, 38.3478962],
    [39.0997085, 38.3414975],
    [39.0862601, 38.3413963],
    [39.0745337, 38.343342],
    [39.066982, 38.3399765],
    [39.0591653, 38.3364926],
    [39.0482239, 38.3381319],
    [39.0421209, 38.3354834],
    [39.0380474, 38.3337155],
    [39.0333326, 38.3334484],
    [39.0294414, 38.3320605],
    [39.0166127, 38.3333442],
    [39.0061845, 38.3324171],
    [38.9994354, 38.3259485],
    [38.9890407, 38.3193776],
    [38.9797135, 38.3192804],
    [38.9697186, 38.3189905],
    [38.9677751, 38.3188945],
    [38.9585749, 38.3239364],
    [38.9531427, 38.327428],
    [38.9520266, 38.3286505],
    [38.9476393, 38.3334557],
    [38.9338645, 38.3415267],
    [38.9277226, 38.3492261],
    [38.9176505, 38.3556886],
    [38.9089559, 38.3569956],
    [38.9019042, 38.3565849],
    [38.894535, 38.3561556],
    [38.8902423, 38.3581649],
    [38.8864729, 38.3628081],
    [38.8848906, 38.3647569],
    [38.8820887, 38.3767706],
    [38.8767065, 38.386426],
    [38.867576, 38.3935787],
    [38.8575968, 38.3995787],
    [38.8568232, 38.4000436],
    [38.843572, 38.404847],
    [38.8383504, 38.4076572],
    [38.8344345, 38.4097645],
    [38.8329282, 38.4117725],
    [38.8318099, 38.4166294],
    [38.8322564, 38.4257289],
    [38.8337801, 38.4319104],
    [38.8302175, 38.4352509],
    [38.8248892, 38.4381948],
    [38.8194599, 38.4402079],
    [38.813149, 38.4435089],
    [38.7930337, 38.4352518],
    [38.7874254, 38.4344095],
    [38.7724291, 38.432157],
    [38.7636728, 38.4308418],
    [38.7569425, 38.4298307],
    [38.7488785, 38.4273366],
    [38.7410552, 38.422673],
    [38.7358245, 38.4195546],
    [38.7323856, 38.4182922],
    [38.7280425, 38.4167767],
    [38.7192269, 38.4157204],
    [38.7141646, 38.4151136],
    [38.7083205, 38.4156708],
    [38.6733246, 38.4234457],
    [38.6521569, 38.4295087],
    [38.6291655, 38.4381696],
    [38.6147156, 38.4456618],
    [38.6094435, 38.4467758],
    [38.6047136, 38.4465017],
    [38.5966565, 38.4439908],
    [38.5943056, 38.4438578],
    [38.5919416, 38.4437238],
    [38.5872124, 38.4456402],
    [38.5797194, 38.4487138],
    [38.5787559, 38.4498777],
    [38.5730434, 38.4567778],
    [38.5661085, 38.4609358],
    [38.5447155, 38.4637128],
    [38.5324934, 38.4687178],
    [38.5230405, 38.4678787],
    [38.5111004, 38.4642698],
    [38.5058286, 38.4642698],
    [38.4944256, 38.468472],
    [38.4847105, 38.4720519],
    [38.4685975, 38.4748289],
    [38.4616545, 38.4789948],
    [38.4566576, 38.4839918],
    [38.4505534, 38.4884399],
    [38.4459094, 38.4918777],
    [38.4408196, 38.4956452],
    [38.4243043, 38.5082621],
    [38.4091128, 38.5167587],
    [38.384858, 38.5253308],
    [38.3760984, 38.532332],
    [38.3694084, 38.536208],
    [38.3618552, 38.5418474],
    [38.3573921, 38.5517823],
    [38.3574904, 38.569838],
    [38.3574905, 38.5855454],
    [38.3574905, 38.6065052],
    [38.3597953, 38.6187405],
    [38.3658035, 38.6324195],
    [38.3758314, 38.6464992],
    [38.3783184, 38.6495502],
    [38.3872145, 38.6562182],
    [38.4038845, 38.6712252],
    [38.4124953, 38.684296],
    [38.42537, 38.6887178],
    [38.444081, 38.6916655],
    [38.4585007, 38.6946131],
    [38.4672084, 38.6970593],
    [38.4713746, 38.7034443],
    [38.4749905, 38.7159414],
    [38.4761046, 38.7289954],
    [38.4732183, 38.7319837],
    [38.4710422, 38.7376978],
    [38.467008, 38.746066],
    [38.4712138, 38.7502163],
    [38.4772109, 38.7533471],
    [38.4810287, 38.7542924],
    [38.4838309, 38.7541655],
    [38.4919354, 38.7512275],
    [38.5019412, 38.7463338],
    [38.5090507, 38.7479844],
    [38.5144592, 38.7514169],
    [38.5245473, 38.7636464],
    [38.5332162, 38.7651187],
    [38.5421856, 38.7641819],
    [38.5581072, 38.7583257],
    [38.5684927, 38.7536069],
    [38.5815389, 38.7517663],
    [38.587118, 38.7513647],
    [38.5950573, 38.7520006],
    [38.6004646, 38.7542429],
    [38.603855, 38.7544102],
    [38.6078461, 38.753038],
    [38.6248405, 38.7442356],
    [38.6281879, 38.7448714],
    [38.6352362, 38.7483421],
    [38.64545, 38.7564413],
    [38.6452785, 38.7612602],
    [38.6334338, 38.7685549],
    [38.6272539, 38.77431],
    [38.6272001, 38.7768708],
    [38.6299895, 38.7796476],
    [38.6308478, 38.7869405],
    [38.6412334, 38.7933629],
    [38.6528635, 38.7986476],
    [38.6523056, 38.8080119],
    [38.6487007, 38.8181106],
    [38.6614465, 38.8377689],
    [38.6600303, 38.8413455],
    [38.6568975, 38.8437856],
    [38.655425, 38.8441115],
    [38.653593, 38.8446881],
    [38.652091, 38.8450223],
    [38.6478424, 38.8458913],
    [38.6471986, 38.8461253],
    [38.6468339, 38.8463718],
    [38.646614, 38.8465932],
    [38.6462706, 38.847011],
    [38.6457181, 38.8478215],
    [38.6455196, 38.8480764],
    [38.6453211, 38.8482727],
    [38.6451602, 38.848396],
    [38.6450019, 38.8484963],
    [38.6446533, 38.8486696],
    [38.6443502, 38.8487866],
    [38.6440042, 38.8488827],
    [38.643465, 38.8489997],
    [38.6430037, 38.8490791],
    [38.6415124, 38.8493005],
    [38.6404824, 38.8495094],
    [38.6391735, 38.8497308],
    [38.6374353, 38.8499899],
    [38.63603, 38.8502363],
    [38.6348498, 38.8505079],
    [38.6349893, 38.8513392],
    [38.6349893, 38.8517048],
    [38.6349034, 38.8520118],
    [38.6347666, 38.8522249],
    [38.6346486, 38.8523899],
    [38.6343294, 38.8526823],
    [38.6339861, 38.8529058],
    [38.6333369, 38.8533028],
    [38.6314273, 38.8544474],
    [38.6306173, 38.8549695],
    [38.6303142, 38.8552327],
    [38.6300164, 38.8555251],
    [38.6298125, 38.8558553],
    [38.629673, 38.8561372],
    [38.6294049, 38.8570958],
    [38.6291742, 38.8581068],
    [38.6288631, 38.859385],
    [38.6288147, 38.8598027],
    [38.6282945, 38.8613817],
    [38.6277366, 38.8616323],
    [38.6276615, 38.8640967],
    [38.6272107, 38.8659514],
    [38.6270606, 38.8676053],
    [38.6270499, 38.8680146],
    [38.6271787, 38.86819],
    [38.6273289, 38.8686411],
    [38.6273718, 38.869017],
    [38.6272859, 38.8694931],
    [38.6271787, 38.8697604],
    [38.6270392, 38.8702115],
    [38.626889, 38.8706292],
    [38.6268783, 38.8709299],
    [38.6268031, 38.871122],
    [38.6265993, 38.8712473],
    [38.6266959, 38.871832],
    [38.6266529, 38.8719406],
    [38.6268139, 38.8723582],
    [38.6267066, 38.8724919],
    [38.6267066, 38.8726422],
    [38.6268031, 38.8727675],
    [38.6269319, 38.8729513],
    [38.6268568, 38.8730849],
    [38.6266959, 38.8733104],
    [38.6265027, 38.8734107],
    [38.6263525, 38.8735109],
    [38.6262989, 38.8737698],
    [38.6261272, 38.8738116],
    [38.6260414, 38.8739369],
    [38.6260253, 38.8740225],
    [38.6260736, 38.8740998],
    [38.6260334, 38.8741582],
    [38.6258805, 38.8743796],
    [38.6257625, 38.8741749],
    [38.6256176, 38.8740413],
    [38.6255157, 38.8738408],
    [38.6253977, 38.8736988],
    [38.6253279, 38.8735819],
    [38.6253709, 38.873419],
    [38.6252743, 38.8733439],
    [38.6249578, 38.8732102],
    [38.6248934, 38.8731141],
    [38.6248183, 38.872993],
    [38.6247969, 38.8728552],
    [38.6247486, 38.8727257],
    [38.6247539, 38.872638],
    [38.6247325, 38.8725462],
    [38.6246145, 38.8724919],
    [38.6244374, 38.8724292],
    [38.6243087, 38.8723457],
    [38.6242497, 38.8722455],
    [38.6241853, 38.872141],
    [38.6240834, 38.8720074],
    [38.62396, 38.8720157],
    [38.6238795, 38.872045],
    [38.6238044, 38.8720951],
    [38.6236006, 38.8721243],
    [38.6233699, 38.8720533],
    [38.6232305, 38.871974],
    [38.62293, 38.8718821],
    [38.6225438, 38.871786],
    [38.6220449, 38.8716733],
    [38.6217982, 38.8715605],
    [38.6216319, 38.871406],
    [38.6215675, 38.8713559],
    [38.6214173, 38.8713141],
    [38.6210847, 38.8713016],
    [38.6208165, 38.8712932],
    [38.6203551, 38.8712765],
    [38.6200601, 38.8712264],
    [38.6194593, 38.8711011],
    [38.6191535, 38.8710176],
    [38.618896, 38.8709382],
    [38.6186814, 38.8708756],
    [38.6185205, 38.8708923],
    [38.6183649, 38.8709299],
    [38.6182845, 38.8709716],
    [38.6181182, 38.8710134],
    [38.6180109, 38.8710468],
    [38.6178768, 38.8710677],
    [38.6176515, 38.8709967],
    [38.6175227, 38.8710343],
    [38.6174691, 38.8710802],
    [38.6173457, 38.8711554],
    [38.6172491, 38.8712097],
    [38.6170614, 38.8712473],
    [38.6169702, 38.8712389],
    [38.6168361, 38.8712306],
    [38.6167341, 38.8712598],
    [38.6166215, 38.8712347],
    [38.6164337, 38.8712055],
    [38.616305, 38.8712932],
    [38.6162031, 38.8713893],
    [38.6160368, 38.8714018],
    [38.6158597, 38.8713976],
    [38.6157632, 38.8715313],
    [38.6156076, 38.8715981],
    [38.6154574, 38.8715939],
    [38.6152106, 38.871573],
    [38.6150604, 38.8716065],
    [38.6149049, 38.8717568],
    [38.614862, 38.8718612],
    [38.6148083, 38.8719322],
    [38.6146742, 38.8720116],
    [38.6145508, 38.8720742],
    [38.6144918, 38.8721243],
    [38.6144489, 38.8722162],
    [38.6143792, 38.872212],
    [38.6142558, 38.8721995],
    [38.6141324, 38.8721995],
    [38.6137177, 38.8723455],
    [38.608446, 38.8789401],
    [38.6086177, 38.8887113],
    [38.6067723, 38.8884692],
    [38.6053562, 38.8884524],
    [38.6049807, 38.8882186],
    [38.603661, 38.8874837],
    [38.6015689, 38.8868657],
    [38.6010271, 38.8869576],
    [38.6006784, 38.887229],
    [38.6004209, 38.8873251],
    [38.5994124, 38.8875088],
    [38.5989189, 38.8879931],
    [38.5966014, 38.8890287],
    [38.5935115, 38.8904984],
    [38.59306, 38.8906975],
    [38.5909366, 38.8916341],
    [38.5895633, 38.8913001],
    [38.5869884, 38.8923022],
    [38.5814094, 38.8968447],
    [38.5800361, 38.8973123],
    [38.5801219, 38.8983143],
    [38.571968, 38.9059958],
    [38.5698222, 38.9065301],
    [38.5660457, 38.909736],
    [38.5650157, 38.9092017],
    [38.56287, 38.9108713],
    [38.560853, 38.9125578],
    [38.5582351, 38.9150287],
    [38.5565094, 38.9174537],
    [38.5548019, 38.9197535],
    [38.5482787, 38.9229588],
    [38.5342455, 38.928167],
    [38.5334086, 38.9287013],
    [38.5317992, 38.9300366],
    [38.530699, 38.9309969],
    [38.5300398, 38.9315723],
    [38.5292029, 38.9326072],
    [38.5285806, 38.9342429],
    [38.5279797, 38.9357785],
    [38.5284518, 38.9387161],
    [38.5313701, 38.9397842],
    [38.5337733, 38.9401848],
    [38.5363483, 38.9401848],
    [38.5420131, 38.9389163],
    [38.5467053, 38.9394778],
    [38.5475921, 38.9395839],
    [38.5559177, 38.9417202],
    [38.5609817, 38.9373808],
    [38.564791, 38.935598],
    [38.5697793, 38.9337756],
    [38.5743713, 38.9353111],
    [38.5730838, 38.9375477],
    [38.5742425, 38.9393169],
    [38.5746717, 38.940385],
    [38.5760878, 38.941086],
    [38.5772037, 38.9421541],
    [38.5778045, 38.9429886],
    [38.5778904, 38.945325],
    [38.5789203, 38.9456922],
    [38.5849714, 38.9442569],
    [38.5896062, 38.9441235],
    [38.5914945, 38.9448577],
    [38.5929107, 38.9445907],
    [38.5969019, 38.9439899],
    [38.5987901, 38.943556],
    [38.5999059, 38.9435226],
    [38.6002063, 38.9440567],
    [38.6024057, 38.9439649],
    [38.603028, 38.9439816],
    [38.60394, 38.9442069],
    [38.6043692, 38.9442235],
    [38.6047554, 38.9445156],
    [38.6050558, 38.944599],
    [38.6051738, 38.9448077],
    [38.6052704, 38.9450163],
    [38.6055493, 38.9453751],
    [38.6063433, 38.9458507],
    [38.6070621, 38.9459759],
    [38.6073303, 38.9462929],
    [38.6078775, 38.946827],
    [38.6082637, 38.9471274],
    [38.608768, 38.9471524],
    [38.6093259, 38.9472108],
    [38.6096692, 38.9472108],
    [38.6104631, 38.9474528],
    [38.6108172, 38.9475195],
    [38.6112785, 38.9474194],
    [38.6118686, 38.9472609],
    [38.6123299, 38.9470105],
    [38.6129844, 38.9471858],
    [38.6133224, 38.9470898],
    [38.6135959, 38.9470899],
    [38.614127, 38.9474486],
    [38.6143953, 38.9477782],
    [38.6147707, 38.9480744],
    [38.6149156, 38.9484123],
    [38.6154038, 38.9488295],
    [38.6156666, 38.9489589],
    [38.6158115, 38.9491591],
    [38.6161226, 38.9491257],
    [38.6169004, 38.9495638],
    [38.6178446, 38.950298],
    [38.6190354, 38.9510156],
    [38.6173725, 38.9510323],
    [38.6170077, 38.9510239],
    [38.616761, 38.951316],
    [38.6162245, 38.9514912],
    [38.6157095, 38.9519334],
    [38.6155915, 38.9523172],
    [38.6153769, 38.9526426],
    [38.6149263, 38.9529179],
    [38.6141539, 38.9528929],
    [38.6134457, 38.9527511],
    [38.613081, 38.9530764],
    [38.6125231, 38.9533017],
    [38.6121154, 38.9539692],
    [38.6115575, 38.954086],
    [38.6110854, 38.9542862],
    [38.6103558, 38.9549704],
    [38.60924, 38.9551539],
    [38.6085319, 38.9550204],
    [38.6080384, 38.9558214],
    [38.6070299, 38.9565222],
    [38.6056351, 38.9571563],
    [38.6042619, 38.9573565],
    [38.603189, 38.9576735],
    [38.6016655, 38.9577069],
    [38.6003994, 38.9579906],
    [38.597567, 38.9590918],
    [38.5980304, 38.9629785],
    [38.5991764, 38.972589],
    [38.5990476, 38.9762923],
    [38.6011934, 38.9797953],
    [38.6055278, 38.983932],
    [38.6100339, 38.987935],
    [38.6186308, 38.9941318],
    [38.6243247, 38.9979748],
    [38.632307, 39.004645],
    [38.6397314, 39.0082799],
    [38.654103, 39.017806],
    [38.6593008, 39.021251],
    [38.6660814, 39.0275189],
    [38.6723899, 39.0348196],
    [38.6780242, 39.0420367],
    [38.6765956, 39.0444194],
    [38.6757803, 39.0476524],
    [38.6742782, 39.0483523],
    [38.6723041, 39.0495188],
    [38.669815, 39.0504853],
    [38.6689889, 39.05221],
    [38.6686242, 39.0524183],
    [38.6684525, 39.0525433],
    [38.6681843, 39.0529765],
    [38.6678731, 39.0531765],
    [38.667562, 39.0533764],
    [38.6673796, 39.0535014],
    [38.6671758, 39.0536514],
    [38.6668432, 39.0535681],
    [38.6667359, 39.0536347],
    [38.6665964, 39.053843],
    [38.6664247, 39.0540846],
    [38.666296, 39.0542013],
    [38.6661887, 39.0541263],
    [38.6660492, 39.053943],
    [38.6659098, 39.053718],
    [38.6658025, 39.0535764],
    [38.6656093, 39.0534181],
    [38.6653948, 39.0532181],
    [38.6651909, 39.0531098],
    [38.6643219, 39.0532181],
    [38.6639142, 39.0534348],
    [38.663013, 39.0541846],
    [38.6626911, 39.0546178],
    [38.6619615, 39.0547511],
    [38.6609959, 39.0545679],
    [38.6601591, 39.0541679],
    [38.6593008, 39.053918],
    [38.6590647, 39.054118],
    [38.6592579, 39.0545012],
    [38.6587643, 39.0548178],
    [38.6580992, 39.055201],
    [38.6576915, 39.0558342],
    [38.6572408, 39.0565507],
    [38.6567259, 39.056784],
    [38.6559534, 39.056634],
    [38.6553097, 39.0567173],
    [38.6549878, 39.0565174],
    [38.6547518, 39.0569339],
    [38.6544299, 39.0569839],
    [38.6542153, 39.0568506],
    [38.6534858, 39.0566507],
    [38.6526489, 39.0568673],
    [38.6519837, 39.0572838],
    [38.6517048, 39.0575838],
    [38.6514258, 39.057967],
    [38.65134, 39.0575171],
    [38.6507392, 39.0577171],
    [38.6504388, 39.0579503],
    [38.6501598, 39.0582502],
    [38.6498809, 39.0587001],
    [38.6497736, 39.0591167],
    [38.6503529, 39.0597332],
    [38.6506319, 39.0600497],
    [38.6507177, 39.0602663],
    [38.6504388, 39.0605662],
    [38.6500954, 39.0606995],
    [38.6498594, 39.0608495],
    [38.6496663, 39.0610994],
    [38.6494732, 39.0615826],
    [38.6489582, 39.0619158],
    [38.6489582, 39.0622824],
    [38.648529, 39.0625489],
    [38.648014, 39.0629155],
    [38.6477136, 39.0632487],
    [38.6481428, 39.0635153],
    [38.6488723, 39.0639818],
    [38.6492586, 39.0644149],
    [38.649044, 39.0648814],
    [38.6485719, 39.0655145],
    [38.6480999, 39.0659477],
    [38.6473274, 39.0667474],
    [38.6446666, 39.0676137],
    [38.643465, 39.0682468],
    [38.6427784, 39.0687132],
    [38.6423063, 39.0690797],
    [38.6430358, 39.0699793],
    [38.6427354, 39.0704125],
    [38.6420917, 39.0709789],
    [38.6417055, 39.0713121],
    [38.6420488, 39.0716119],
    [38.6417055, 39.0719451],
    [38.6408042, 39.0724449],
    [38.6405038, 39.0730446],
    [38.6408901, 39.0736776],
    [38.6415338, 39.0742773],
    [38.6425638, 39.0745438],
    [38.6434221, 39.0750436],
    [38.6437654, 39.0756099],
    [38.6441516, 39.0762763],
    [38.6436796, 39.0767427],
    [38.6426925, 39.0767094],
    [38.6415767, 39.0770758],
    [38.6406541, 39.0771258],
    [38.6400103, 39.077409],
    [38.6395168, 39.0780253],
    [38.6387229, 39.0780753],
    [38.638401, 39.077842],
    [38.6380362, 39.0774423],
    [38.6372423, 39.0773257],
    [38.6367488, 39.0775755],
    [38.6365557, 39.0781252],
    [38.6370492, 39.0786083],
    [38.6375856, 39.0789747],
    [38.6378646, 39.0792079],
    [38.6376929, 39.0798242],
    [38.6373067, 39.0803406],
    [38.6368346, 39.0805405],
    [38.6361909, 39.0809403],
    [38.6359763, 39.0811235],
    [38.6359763, 39.08139],
    [38.6362552, 39.0818064],
    [38.6360621, 39.0820562],
    [38.6354399, 39.0822061],
    [38.6349034, 39.0824726],
    [38.6343884, 39.0829224],
    [38.6345386, 39.0832388],
    [38.6343026, 39.0837885],
    [38.6339164, 39.0841549],
    [38.6336803, 39.0846213],
    [38.6330795, 39.0848711],
    [38.6326289, 39.0851209],
    [38.632822, 39.0853375],
    [38.6334872, 39.0857539],
    [38.634088, 39.085987],
    [38.6339593, 39.0863535],
    [38.6333155, 39.08647],
    [38.6327576, 39.0869197],
    [38.6323499, 39.0869697],
    [38.6321354, 39.0867199],
    [38.6317491, 39.0866199],
    [38.6312556, 39.0867032],
    [38.631041, 39.0869364],
    [38.6305475, 39.0875526],
    [38.6294317, 39.0878191],
    [38.6286807, 39.0879357],
    [38.6283803, 39.0881689],
    [38.6280799, 39.088502],
    [38.6274147, 39.0889683],
    [38.6267495, 39.0894346],
    [38.6258697, 39.0904339],
    [38.6251831, 39.0912333],
    [38.6246466, 39.0916996],
    [38.6237454, 39.0919328],
    [38.6234021, 39.0922159],
    [38.6233806, 39.0928154],
    [38.6231017, 39.0929986],
    [38.6223078, 39.0927655],
    [38.6218786, 39.0931152],
    [38.6207843, 39.0940478],
    [38.6203337, 39.0943975],
    [38.6199474, 39.0948971],
    [38.6199474, 39.0953135],
    [38.6202049, 39.0955966],
    [38.6199689, 39.0958797],
    [38.6195826, 39.0957132],
    [38.6189389, 39.0954301],
    [38.618617, 39.0955466],
    [38.6184454, 39.0957798],
    [38.6184668, 39.0959463],
    [38.6186385, 39.0961628],
    [38.6183166, 39.0963793],
    [38.6180377, 39.0965958],
    [38.6178875, 39.0967623],
    [38.6178446, 39.0970287],
    [38.6182737, 39.0971287],
    [38.6182308, 39.0973618],
    [38.6179948, 39.0975616],
    [38.6180377, 39.0977948],
    [38.6181664, 39.0979946],
    [38.6185741, 39.0980612],
    [38.6186385, 39.0983943],
    [38.6184454, 39.098694],
    [38.6181021, 39.0988272],
    [38.6176944, 39.0986274],
    [38.6169648, 39.0985108],
    [38.6167502, 39.098694],
    [38.6166429, 39.0990271],
    [38.6169004, 39.0996099],
    [38.6158704, 39.0999596],
    [38.6139392, 39.102191],
    [38.6069011, 39.1069865],
    [38.5789632, 39.1069865],
    [38.5748863, 39.1068866],
    [38.5701227, 39.1090511],
    [38.566389, 39.1123144],
    [38.5591793, 39.1127806],
    [38.5389232, 39.1202723],
    [38.5287523, 39.1124809],
    [38.5044622, 39.1097171],
    [38.4682416, 39.1408787],
    [38.4387588, 39.1291947],
    [38.4207773, 39.1213044],
    [38.4070444, 39.1140126],
    [38.391509, 39.1135798],
    [38.3814668, 39.1149782],
    [38.3749866, 39.1138794],
    [38.3343029, 39.1503144],
    [38.3241319, 39.1506638],
    [38.3153343, 39.1463372],
    [38.3028888, 39.1417774],
    [38.2913017, 39.1429424],
    [38.2809162, 39.1400799],
    [38.2778263, 39.1401132],
    [38.2767534, 39.1399467],
    [38.2746934, 39.1404793],
    [38.2723331, 39.1407123],
    [38.2680416, 39.1405792],
    [38.2509183, 39.136718],
    [38.1955574, 39.1018247],
    [38.1960725, 39.0994267],
    [38.1792497, 39.0941311],
    [38.160882, 39.0977615],
    [38.1386089, 39.0888351],
    [38.1116581, 39.0827059],
    [38.1024742, 39.0787082],
    [38.0973672, 39.0695462],
    [38.0936543, 39.0662564],
    [38.0887842, 39.0622324],
    [38.0840635, 39.0748437],
    [38.0674552, 39.0789748],
    [38.0583572, 39.0771091],
    [38.0449891, 39.0803573],
    [38.0446887, 39.0805738],
    [38.0443454, 39.0804572],
    [38.0393457, 39.0819064],
    [38.0053567, 39.0758098],
    [37.9983615, 39.0779753],
    [37.9920101, 39.0791746],
    [37.9872894, 39.0782752],
    [37.979865, 39.0777754],
    [37.9782772, 39.0778754],
    [37.9731702, 39.0787082],
    [37.9689645, 39.0787082],
    [37.960639, 39.0823727],
    [37.9557037, 39.0768759],
    [37.9486656, 39.071412],
    [37.9409408, 39.0674471],
    [37.9402113, 39.0680801],
    [37.9393101, 39.06838],
    [37.9384947, 39.0686799],
    [37.936778, 39.0686299],
    [37.9358339, 39.0691464],
    [37.9295682, 39.0701126],
    [37.9208564, 39.0660477],
    [37.910428, 39.0662809],
    [37.8995704, 39.0679469],
    [37.8938627, 39.0719118],
    [37.8870821, 39.0734777],
    [37.8769326, 39.0748103],
    [37.8737139, 39.0785417],
    [37.8682637, 39.0828557],
    [37.8651953, 39.083955],
    [37.8667831, 39.0851043],
    [37.8545737, 39.0900675],
    [37.854445, 39.0895179],
    [37.8536296, 39.0876026],
    [37.851634, 39.0889017],
    [37.8504109, 39.0902341],
    [37.8480506, 39.0908003],
    [37.8474068, 39.0898177],
    [37.8460979, 39.0877025],
    [37.8440165, 39.0888184],
    [37.8439951, 39.0907337],
    [37.8407764, 39.0922159],
    [37.8321504, 39.0945974],
    [37.8223657, 39.095097],
    [37.8106284, 39.0973118],
    [37.7999425, 39.0982444],
    [37.7936125, 39.099127],
    [37.7861452, 39.0997764],
    [37.7834201, 39.098361],
    [37.7811026, 39.0978947],
    [37.7779913, 39.0988272],
    [37.7751803, 39.1003925],
    [37.7737856, 39.1012751],
    [37.7704811, 39.1024074],
    [37.7670479, 39.1018579],
    [37.7647948, 39.0998597],
    [37.7628207, 39.0969788],
    [37.7604818, 39.0892847],
    [37.75913, 39.0815232],
    [37.7585935, 39.0757099],
    [37.7591514, 39.0716952],
    [37.7582502, 39.0695295],
    [37.7576709, 39.0659643],
    [37.7551389, 39.0627155],
    [37.7602887, 39.0599164],
    [37.7669191, 39.050302],
    [37.7662968, 39.0490522],
    [37.7662754, 39.0467525],
    [37.7677345, 39.0437195],
    [37.7682495, 39.041053],
    [37.7678418, 39.0406696],
    [37.7673054, 39.0404197],
    [37.7661252, 39.0392863],
    [37.7659321, 39.0386197],
    [37.7655029, 39.0379697],
    [37.7654385, 39.0371697],
    [37.7652454, 39.0363696],
    [37.765181, 39.0355863],
    [37.7653742, 39.0352696],
    [37.7653742, 39.0349863],
    [37.7652883, 39.0346029],
    [37.7650952, 39.0342862],
    [37.7651596, 39.0339195],
    [37.7653527, 39.0332195],
    [37.7651596, 39.0327528],
    [37.7656317, 39.0317694],
    [37.765696, 39.0312193],
    [37.7659106, 39.0308193],
    [37.7668118, 39.0294358],
    [37.7674126, 39.0292691],
    [37.7684426, 39.0277522],
    [37.7687001, 39.0267687],
    [37.7690434, 39.0265187],
    [37.7694511, 39.0256685],
    [37.7697301, 39.0250017],
    [37.7689361, 39.0219677],
    [37.7674556, 39.0181001],
    [37.7674556, 39.0173832],
    [37.7673697, 39.0166997],
    [37.7676272, 39.0155993],
    [37.7679705, 39.0149157],
    [37.7684426, 39.0141321],
    [37.7689147, 39.0135653],
    [37.7687859, 39.0060456],
    [37.769494, 39.0053786],
    [37.7682495, 39.0050284],
    [37.7690005, 39.0036444],
    [37.7718115, 39.0016768],
    [37.7729058, 38.9996257],
    [37.7727556, 38.9993255],
    [37.7697086, 38.9998424],
    [37.7693438, 38.9995423],
    [37.7697944, 38.9991087],
    [37.7661252, 38.9971076],
    [37.7634215, 38.9949563],
    [37.7630996, 38.9946561],
    [37.7633357, 38.9940724],
    [37.7629924, 38.993522],
    [37.7626276, 38.9934887],
    [37.7623486, 38.9933219],
    [37.7621126, 38.9925047],
    [37.7615762, 38.9918543],
    [37.7610826, 38.9902532],
    [37.7605891, 38.9892692],
    [37.7609753, 38.9882685],
    [37.7606106, 38.9875513],
    [37.7604174, 38.9869009],
    [37.7605676, 38.9864505],
    [37.7610397, 38.9861003],
    [37.7614689, 38.9860169],
    [37.761662, 38.9855999],
    [37.7611684, 38.9853664],
    [37.7593446, 38.9842322],
    [37.7564049, 38.9817636],
    [37.7386379, 38.9746742],
    [37.7258491, 38.9738902],
    [37.7054214, 38.9792615],
    [37.7047777, 38.9796952],
    [37.7027821, 38.981063],
    [37.6751232, 38.9967574],
    [37.6742434, 38.9997424],
    [37.6681709, 38.999659],
    [37.6601672, 39.0034943],
    [37.6602745, 39.0061456],
    [37.6600599, 39.0077963],
    [37.6523566, 39.0099139],
    [37.6472926, 39.0097472],
    [37.6310277, 39.0126483],
    [37.610836, 39.0154993],
    [37.5853228, 39.0101973],
    [37.5843787, 39.011748],
    [37.5758815, 39.0175833],
    [37.5582003, 39.0111144],
    [37.5524497, 39.0157494],
    [37.5420427, 39.0209842],
    [37.5276661, 39.0209175],
    [37.5256061, 39.0301192],
    [37.5205207, 39.0330028],
    [37.511208, 39.0304025],
    [37.5090194, 39.0294358],
    [37.5014233, 39.0333028],
    [37.4976253, 39.0385863],
    [37.498784, 39.0429029],
    [37.4912524, 39.0437362],
    [37.4917299, 39.0406905],
    [37.4894178, 39.041128],
    [37.4908018, 39.0312026],
    [37.4918961, 39.0291857],
    [37.4909385, 39.0275293],
    [37.4886775, 39.0236181],
    [37.490201, 39.0223678],
    [37.4877333, 39.0155993],
    [37.4909091, 39.0143322],
    [37.4887633, 39.0062457],
    [37.489407, 39.0052952],
    [37.4886989, 39.0045615],
    [37.4865961, 39.0049784],
    [37.4863386, 39.0027273],
    [37.4869609, 38.9989086],
    [37.4909305, 38.9981915],
    [37.4930763, 38.9962237],
    [37.4945998, 38.9969575],
    [37.4981403, 38.9932219],
    [37.4995994, 38.989386],
    [37.5133538, 38.9500978],
    [37.5168943, 38.9444238],
    [37.5150919, 38.9333249],
    [37.5315285, 38.9142941],
    [37.5266361, 38.9033574],
    [37.5270653, 38.8881935],
    [37.5297475, 38.8639547],
    [37.5333953, 38.8614986],
    [37.5341463, 38.8589756],
    [37.5396609, 38.8564693],
    [37.5406265, 38.8568369],
    [37.5497246, 38.8492838],
    [37.553072, 38.8498687],
    [37.5516557, 38.8471614],
    [37.5518703, 38.8443538],
    [37.5464844, 38.8393734],
    [37.5435383, 38.8352256],
    [37.5427508, 38.8298126],
    [37.5390386, 38.8251989],
    [37.5477505, 38.8054201],
    [37.5501752, 38.8030957],
    [37.5538659, 38.8009887],
    [37.5606036, 38.7997178],
    [37.5784778, 38.789466],
    [37.5811386, 38.7830432],
    [37.5823831, 38.7780083],
    [37.5722337, 38.7707981],
    [37.5556683, 38.7709153],
    [37.5463128, 38.7738596],
    [37.5337815, 38.7659799],
    [37.5262499, 38.7644741],
    [37.5115299, 38.7502679],
    [37.5019169, 38.743942],
    [37.4811888, 38.7368122],
    [37.4692797, 38.7310877],
    [37.4612009, 38.7190012],
    [37.4378657, 38.690166],
    [37.4314177, 38.68927],
    [37.4244332, 38.6893788],
    [37.4226844, 38.680803],
    [37.4196803, 38.6756771],
    [37.4150777, 38.6703833],
    [37.4175346, 38.6624753],
    [37.4157321, 38.6613695],
    [37.4157965, 38.659895],
    [37.4083185, 38.6559574],
    [37.4052286, 38.6542482],
    [37.395122, 38.6514162],
    [37.395755, 38.6316898],
    [37.3944783, 38.6285049],
    [37.3904872, 38.6165016],
    [37.3824191, 38.6081015],
    [37.347207, 38.6007233],
    [37.3308778, 38.5930928],
    [37.3168659, 38.5780475],
  ],
  Diyarbakır: [
    [39.10654, 38.2038181],
    [39.1076485, 38.2006914],
    [39.1117475, 38.1955929],
    [39.1157448, 38.1923192],
    [39.1219983, 38.18831],
    [39.125989, 38.1868672],
    [39.1279608, 38.1867394],
    [39.1303797, 38.1873465],
    [39.1321888, 38.1882892],
    [39.1336931, 38.1888803],
    [39.1353396, 38.1891519],
    [39.136295, 38.1891359],
    [39.137352, 38.1889921],
    [39.1388765, 38.1882253],
    [39.1417224, 38.1867234],
    [39.143999, 38.1856529],
    [39.145239, 38.185621],
    [39.1493654, 38.1840711],
    [39.154427, 38.182697],
    [39.1579639, 38.1827929],
    [39.163188, 38.1836717],
    [39.1663894, 38.1852472],
    [39.1663008, 38.1855472],
    [39.1741635, 38.1912495],
    [39.187092, 38.2018767],
    [39.1952249, 38.20683],
    [39.203274, 38.2058703],
    [39.2069683, 38.2076169],
    [39.2134447, 38.2068102],
    [39.2200999, 38.2077978],
    [39.2264891, 38.205923],
    [39.2279589, 38.2033818],
    [39.2319327, 38.2006845],
    [39.2334622, 38.1916129],
    [39.2383251, 38.191381],
    [39.2436477, 38.1892529],
    [39.2530368, 38.1829581],
    [39.2567722, 38.1778616],
    [39.2585028, 38.1698656],
    [39.2583911, 38.1659156],
    [39.2632664, 38.1527768],
    [39.2629314, 38.1461334],
    [39.2535531, 38.1330203],
    [39.2465566, 38.1254382],
    [39.2378482, 38.1214273],
    [39.2361297, 38.1148865],
    [39.2350935, 38.1061437],
    [39.2274856, 38.0883254],
    [39.2260922, 38.0805047],
    [39.2223263, 38.0717694],
    [39.201536, 38.0629103],
    [39.1966009, 38.0487133],
    [39.1830143, 38.0417499],
    [39.176696, 38.0293465],
    [39.1753595, 38.0283615],
    [39.1594208, 38.0180994],
    [39.1505093, 38.015617],
    [39.1375294, 38.0179548],
    [39.1315153, 38.0100854],
    [39.1332705, 38.0021331],
    [39.1455626, 37.9969034],
    [39.1522693, 37.9958583],
    [39.1530633, 37.9960781],
    [39.1553641, 37.9958804],
    [39.1636205, 37.9985131],
    [39.1664944, 38.0004536],
    [39.1699505, 38.0013538],
    [39.1728902, 38.0031292],
    [39.1772675, 38.0035857],
    [39.1813263, 38.0053064],
    [39.1911002, 38.0017106],
    [39.2087897, 37.999168],
    [39.2234423, 37.9972288],
    [39.2360209, 37.993342],
    [39.2511344, 37.9865103],
    [39.2604761, 37.9796562],
    [39.269656, 37.9703489],
    [39.2966334, 37.9624054],
    [39.3038681, 37.9602749],
    [39.3212037, 37.9586011],
    [39.3514497, 37.960178],
    [39.3751752, 37.9491196],
    [39.3797941, 37.940674],
    [39.3856691, 37.9327709],
    [39.4027274, 37.933814],
    [39.4188847, 37.9288544],
    [39.454388, 37.9204484],
    [39.4690683, 37.917058],
    [39.4777325, 37.9116525],
    [39.4891094, 37.9096528],
    [39.4935027, 37.9028936],
    [39.5086759, 37.8972505],
    [39.5160749, 37.8891501],
    [39.5218429, 37.8874614],
    [39.5256301, 37.8821987],
    [39.5221381, 37.8755034],
    [39.5324651, 37.8683801],
    [39.5402552, 37.859302],
    [39.5429062, 37.8515274],
    [39.5508593, 37.8498827],
    [39.5687269, 37.8547325],
    [39.5885583, 37.853064],
    [39.5939219, 37.8511461],
    [39.6035296, 37.8455876],
    [39.61426, 37.826361],
    [39.6260856, 37.8021105],
    [39.6440645, 37.7964232],
    [39.6629038, 37.794301],
    [39.6753892, 37.7959222],
    [39.681283, 37.8013698],
    [39.6867699, 37.8052843],
    [39.6907259, 37.8144934],
    [39.6956156, 37.8293489],
    [39.7019393, 37.8367496],
    [39.7094472, 37.8413952],
    [39.744851, 37.8566911],
    [39.7655243, 37.8632436],
    [39.773905, 37.8650526],
    [39.7827553, 37.86337],
    [39.7990138, 37.8589128],
    [39.8050117, 37.8542543],
    [39.8087489, 37.8488853],
    [39.8106766, 37.8461045],
    [39.8124454, 37.8380613],
    [39.8154559, 37.8210134],
    [39.8148159, 37.8209628],
    [39.8122933, 37.802011],
    [39.8292253, 37.7593105],
    [39.8418687, 37.741524],
    [39.8444885, 37.7309706],
    [39.8286969, 37.713606],
    [39.8310305, 37.6623313],
    [39.8370385, 37.6337176],
    [39.8332352, 37.6170246],
    [39.8353711, 37.580607],
    [39.8558938, 37.5625957],
    [39.8592644, 37.5559333],
    [39.8616921, 37.5480423],
    [39.8621776, 37.5341829],
    [39.8663046, 37.5295625],
    [39.873102, 37.5268672],
    [39.8881533, 37.523209],
    [39.8930096, 37.520925],
    [39.9220156, 37.5269653],
    [39.9463905, 37.5371285],
    [39.9951303, 37.5128299],
    [40.0189498, 37.5185309],
    [40.0473105, 37.5276913],
    [40.0805414, 37.5372527],
    [40.1019724, 37.5553075],
    [40.1226431, 37.5624413],
    [40.1453814, 37.5611676],
    [40.1576036, 37.548621],
    [40.1896875, 37.5450781],
    [40.1896137, 37.5354233],
    [40.205768, 37.528993],
    [40.206362, 37.529024],
    [40.206834, 37.529049],
    [40.207793, 37.529415],
    [40.208292, 37.529417],
    [40.208817, 37.529287],
    [40.21035, 37.529109],
    [40.210666, 37.529194],
    [40.213039, 37.528374],
    [40.214786, 37.527534],
    [40.215494, 37.52702],
    [40.216108, 37.526841],
    [40.217225, 37.526721],
    [40.217463, 37.526626],
    [40.216893, 37.526122],
    [40.216833, 37.525759],
    [40.217635, 37.526014],
    [40.218041, 37.525936],
    [40.21781, 37.525567],
    [40.217877, 37.525299],
    [40.217963, 37.525117],
    [40.21874, 37.524667],
    [40.218649, 37.524266],
    [40.219843, 37.524063],
    [40.21997, 37.524355],
    [40.220479, 37.524221],
    [40.220147, 37.523126],
    [40.220471, 37.522983],
    [40.221522, 37.523878],
    [40.222135, 37.523582],
    [40.224639, 37.5232],
    [40.225846, 37.522855],
    [40.227192, 37.521554],
    [40.227803, 37.52074],
    [40.22835, 37.520767],
    [40.229523, 37.520051],
    [40.229853, 37.520011],
    [40.230082, 37.519893],
    [40.230036, 37.519504],
    [40.229874, 37.51929],
    [40.22985, 37.519032],
    [40.229985, 37.518788],
    [40.22996, 37.518462],
    [40.229554, 37.518277],
    [40.229292, 37.518053],
    [40.229413, 37.517538],
    [40.232565, 37.515693],
    [40.237028, 37.514984],
    [40.236992, 37.515219],
    [40.236813, 37.515195],
    [40.236913, 37.51662],
    [40.237638, 37.516467],
    [40.237916, 37.51649],
    [40.2383, 37.516688],
    [40.239182, 37.516824],
    [40.239271, 37.516899],
    [40.239185, 37.517001],
    [40.238807, 37.517131],
    [40.238815, 37.517605],
    [40.238689, 37.517707],
    [40.237454, 37.51784],
    [40.237059, 37.518419],
    [40.236746, 37.518669],
    [40.236508, 37.518671],
    [40.236219, 37.518921],
    [40.236111, 37.519105],
    [40.235898, 37.520163],
    [40.249774, 37.519407],
    [40.250716, 37.519891],
    [40.252047, 37.51973],
    [40.252164, 37.519526],
    [40.252901, 37.519255],
    [40.253458, 37.519386],
    [40.253854, 37.519723],
    [40.254692, 37.520997],
    [40.255015, 37.52103],
    [40.255276, 37.520967],
    [40.255514, 37.520857],
    [40.255674, 37.520569],
    [40.256241, 37.520378],
    [40.257004, 37.52031],
    [40.257727, 37.520482],
    [40.258186, 37.520751],
    [40.25985, 37.522089],
    [40.261185, 37.520779],
    [40.261573, 37.520667],
    [40.262264, 37.520755],
    [40.262785, 37.520711],
    [40.263225, 37.520545],
    [40.263659, 37.520087],
    [40.264246, 37.519746],
    [40.264968, 37.519482],
    [40.265844, 37.51986],
    [40.266474, 37.519865],
    [40.266906, 37.519712],
    [40.267224, 37.51941],
    [40.267844, 37.519301],
    [40.268174, 37.519625],
    [40.268491, 37.519793],
    [40.268837, 37.520535],
    [40.268687, 37.520716],
    [40.267297, 37.520981],
    [40.266942, 37.521169],
    [40.267009, 37.52196],
    [40.267254, 37.523006],
    [40.267239, 37.523436],
    [40.266969, 37.523422],
    [40.266931, 37.523714],
    [40.266718, 37.523897],
    [40.265944, 37.524069],
    [40.266655, 37.524646],
    [40.268664, 37.524676],
    [40.269536, 37.524529],
    [40.272346, 37.524323],
    [40.273106, 37.524555],
    [40.273698, 37.525125],
    [40.274642, 37.524779],
    [40.274981, 37.52438],
    [40.275127, 37.523459],
    [40.275527, 37.522827],
    [40.275735, 37.522757],
    [40.276555, 37.521745],
    [40.27632, 37.521683],
    [40.276231, 37.521254],
    [40.27578, 37.520664],
    [40.27563, 37.520257],
    [40.275672, 37.520099],
    [40.277359, 37.519307],
    [40.277516, 37.519048],
    [40.277726, 37.518947],
    [40.280063, 37.519589],
    [40.280012, 37.519692],
    [40.28026, 37.519783],
    [40.280437, 37.520255],
    [40.280514, 37.520987],
    [40.280819, 37.521407],
    [40.283363, 37.523226],
    [40.282966, 37.527274],
    [40.285728, 37.527954],
    [40.285946, 37.528333],
    [40.285995, 37.528907],
    [40.285882, 37.53007],
    [40.285929, 37.531499],
    [40.286219, 37.532407],
    [40.286993, 37.533341],
    [40.28706, 37.533761],
    [40.287223, 37.533798],
    [40.287914, 37.533692],
    [40.288519, 37.533508],
    [40.289655, 37.533428],
    [40.289792, 37.534173],
    [40.289047, 37.534207],
    [40.288993, 37.534853],
    [40.288306, 37.534798],
    [40.287597, 37.5368],
    [40.288293, 37.537373],
    [40.289726, 37.538209],
    [40.295984, 37.535736],
    [40.307162, 37.530636],
    [40.309526, 37.529843],
    [40.3135, 37.529187],
    [40.318548, 37.527699],
    [40.32594, 37.522819],
    [40.330305, 37.523601],
    [40.336924, 37.521143],
    [40.342276, 37.519691],
    [40.346923, 37.518127],
    [40.350303, 37.514106],
    [40.352134, 37.520361],
    [40.355373, 37.524159],
    [40.357626, 37.526281],
    [40.359457, 37.527845],
    [40.361851, 37.52952],
    [40.364949, 37.534434],
    [40.365513, 37.537114],
    [40.367062, 37.539459],
    [40.369487, 37.542054],
    [40.370212, 37.544196],
    [40.370723, 37.544451],
    [40.370921, 37.54463],
    [40.371207, 37.545175],
    [40.372207, 37.548261],
    [40.372135, 37.549709],
    [40.372455, 37.55132],
    [40.372571, 37.552754],
    [40.372799, 37.553036],
    [40.372716, 37.553874],
    [40.37281, 37.554146],
    [40.37286, 37.555028],
    [40.373242, 37.555938],
    [40.373307, 37.556416],
    [40.373681, 37.55723],
    [40.373368, 37.557908],
    [40.373395, 37.558089],
    [40.37318, 37.558838],
    [40.372225, 37.558645],
    [40.371574, 37.558631],
    [40.371084, 37.55872],
    [40.371092, 37.559251],
    [40.371899, 37.559495],
    [40.372484, 37.559388],
    [40.374092, 37.559584],
    [40.374543, 37.560085],
    [40.374889, 37.560151],
    [40.377899, 37.563737],
    [40.379324, 37.563968],
    [40.38017, 37.562294],
    [40.385302, 37.561344],
    [40.390059, 37.561722],
    [40.390411, 37.561489],
    [40.394087, 37.56254],
    [40.40147, 37.564326],
    [40.404602, 37.563002],
    [40.40493, 37.56312],
    [40.406361, 37.564654],
    [40.406916, 37.56455],
    [40.408995, 37.563454],
    [40.410513, 37.564479],
    [40.414273, 37.564833],
    [40.41689, 37.565163],
    [40.417852, 37.564677],
    [40.419242, 37.565214],
    [40.421944, 37.564083],
    [40.424542, 37.562681],
    [40.426658, 37.563283],
    [40.426796, 37.563071],
    [40.426672, 37.562873],
    [40.426708, 37.562754],
    [40.427349, 37.562705],
    [40.428052, 37.562364],
    [40.428794, 37.562389],
    [40.429656, 37.562132],
    [40.43036, 37.56177],
    [40.430434, 37.562247],
    [40.430813, 37.562343],
    [40.431253, 37.561705],
    [40.431662, 37.561352],
    [40.431856, 37.561351],
    [40.432985, 37.561684],
    [40.432752, 37.562219],
    [40.432141, 37.562286],
    [40.432586, 37.562668],
    [40.432491, 37.562845],
    [40.433148, 37.563321],
    [40.433923, 37.564473],
    [40.434277, 37.564736],
    [40.434622, 37.565543],
    [40.434675, 37.565509],
    [40.434696, 37.565939],
    [40.434048, 37.566906],
    [40.434295, 37.567667],
    [40.432154, 37.56926],
    [40.432296, 37.570189],
    [40.429546, 37.571994],
    [40.428811, 37.572379],
    [40.429055, 37.573046],
    [40.430544, 37.574012],
    [40.431171, 37.578326],
    [40.427737, 37.580273],
    [40.424754, 37.582078],
    [40.423849, 37.583815],
    [40.424127, 37.585121],
    [40.42352, 37.587037],
    [40.422747, 37.588549],
    [40.422792, 37.588726],
    [40.422291, 37.589054],
    [40.422264, 37.589217],
    [40.422353, 37.589328],
    [40.422848, 37.589478],
    [40.422901, 37.589658],
    [40.422645, 37.589761],
    [40.42206, 37.589834],
    [40.421945, 37.58995],
    [40.42189, 37.590212],
    [40.421452, 37.590456],
    [40.421309, 37.59074],
    [40.419799, 37.591888],
    [40.418226, 37.592177],
    [40.417975, 37.592316],
    [40.41724, 37.593026],
    [40.417057, 37.593351],
    [40.416859, 37.593492],
    [40.416631, 37.594247],
    [40.416749, 37.595302],
    [40.41707, 37.596253],
    [40.416731, 37.596314],
    [40.416329, 37.596262],
    [40.415698, 37.595959],
    [40.414443, 37.595586],
    [40.415227, 37.59545],
    [40.414792, 37.594474],
    [40.413954, 37.593779],
    [40.413756, 37.593718],
    [40.413021, 37.594649],
    [40.413181, 37.596321],
    [40.413698, 37.596607],
    [40.414946, 37.596989],
    [40.415577, 37.597334],
    [40.415605, 37.597996],
    [40.41576, 37.598217],
    [40.416599, 37.598153],
    [40.418056, 37.600052],
    [40.417981, 37.600218],
    [40.418062, 37.60117],
    [40.417847, 37.601567],
    [40.416924, 37.602366],
    [40.416192, 37.60281],
    [40.415441, 37.604274],
    [40.41514, 37.604591],
    [40.414591, 37.605469],
    [40.412477, 37.607103],
    [40.411928, 37.607655],
    [40.41076, 37.60842],
    [40.411809, 37.609166],
    [40.411255, 37.609475],
    [40.410523, 37.609742],
    [40.410229, 37.609772],
    [40.409454, 37.609654],
    [40.409139, 37.61001],
    [40.409637, 37.610135],
    [40.410378, 37.609995],
    [40.410986, 37.610116],
    [40.411644, 37.609984],
    [40.412444, 37.610272],
    [40.412829, 37.610247],
    [40.412853, 37.610453],
    [40.41305, 37.610695],
    [40.412731, 37.610571],
    [40.412712, 37.610666],
    [40.412919, 37.611151],
    [40.412875, 37.611313],
    [40.413005, 37.611895],
    [40.412609, 37.612379],
    [40.412159, 37.612749],
    [40.41063, 37.613263],
    [40.408812, 37.613645],
    [40.408244, 37.613565],
    [40.408169, 37.613474],
    [40.407375, 37.613273],
    [40.406222, 37.613287],
    [40.406118, 37.613056],
    [40.405344, 37.612813],
    [40.405008, 37.612594],
    [40.404374, 37.612349],
    [40.403803, 37.611814],
    [40.403183, 37.613406],
    [40.401207, 37.61314],
    [40.400773, 37.613008],
    [40.400105, 37.612557],
    [40.399218, 37.612333],
    [40.398835, 37.612317],
    [40.398386, 37.612458],
    [40.398265, 37.612735],
    [40.398032, 37.612815],
    [40.397356, 37.61216],
    [40.397374, 37.611993],
    [40.39556, 37.611138],
    [40.394826, 37.611058],
    [40.39442, 37.613289],
    [40.394563, 37.613608],
    [40.394755, 37.614567],
    [40.394723, 37.615373],
    [40.395199, 37.616774],
    [40.395101, 37.61753],
    [40.394666, 37.618624],
    [40.395, 37.619344],
    [40.395265, 37.619595],
    [40.395223, 37.619906],
    [40.394635, 37.619289],
    [40.39432, 37.618624],
    [40.393996, 37.618925],
    [40.39384, 37.618916],
    [40.393558, 37.619126],
    [40.393429, 37.618976],
    [40.393316, 37.618705],
    [40.393662, 37.618656],
    [40.394136, 37.618313],
    [40.394104, 37.61822],
    [40.392861, 37.618487],
    [40.391025, 37.619218],
    [40.390517, 37.619869],
    [40.390264, 37.620006],
    [40.390206, 37.619931],
    [40.390587, 37.619175],
    [40.390501, 37.619004],
    [40.390308, 37.618885],
    [40.389654, 37.618883],
    [40.389431, 37.618782],
    [40.386766, 37.616639],
    [40.386483, 37.617011],
    [40.385991, 37.617124],
    [40.385347, 37.616973],
    [40.384625, 37.616612],
    [40.384409, 37.616639],
    [40.384241, 37.6167],
    [40.384107, 37.616929],
    [40.38397, 37.617485],
    [40.383759, 37.617787],
    [40.383913, 37.617944],
    [40.383642, 37.618288],
    [40.383135, 37.618494],
    [40.382803, 37.618856],
    [40.382373, 37.618912],
    [40.381773, 37.619159],
    [40.381466, 37.619429],
    [40.381239, 37.61938],
    [40.380787, 37.619471],
    [40.380823, 37.619633],
    [40.380577, 37.619748],
    [40.380273, 37.619623],
    [40.379567, 37.619948],
    [40.37889, 37.620642],
    [40.378295, 37.621121],
    [40.377844, 37.621212],
    [40.376969, 37.621128],
    [40.376487, 37.621262],
    [40.3759, 37.622795],
    [40.375552, 37.623185],
    [40.37497, 37.623209],
    [40.373603, 37.623998],
    [40.37363, 37.624204],
    [40.371999, 37.624637],
    [40.372171, 37.625122],
    [40.371474, 37.625448],
    [40.371186, 37.625297],
    [40.370744, 37.625262],
    [40.370196, 37.625018],
    [40.370186, 37.624873],
    [40.369592, 37.624681],
    [40.369154, 37.625033],
    [40.368858, 37.625454],
    [40.368208, 37.625885],
    [40.367359, 37.626236],
    [40.367312, 37.626349],
    [40.367736, 37.62671],
    [40.368005, 37.626837],
    [40.368596, 37.626914],
    [40.369632, 37.627205],
    [40.369438, 37.628695],
    [40.369086, 37.638295],
    [40.369887, 37.64089],
    [40.372414, 37.645463],
    [40.372731, 37.647796],
    [40.373674, 37.647742],
    [40.378685, 37.65681],
    [40.378982, 37.659218],
    [40.380256, 37.662296],
    [40.38269, 37.665426],
    [40.383066, 37.663525],
    [40.385477, 37.662859],
    [40.386823, 37.660961],
    [40.38746, 37.661065],
    [40.38855, 37.660936],
    [40.389943, 37.658523],
    [40.39109, 37.657727],
    [40.392298, 37.658136],
    [40.392709, 37.658107],
    [40.393197, 37.657565],
    [40.393558, 37.657302],
    [40.393572, 37.656941],
    [40.393754, 37.656826],
    [40.393997, 37.657155],
    [40.394173, 37.657768],
    [40.392862, 37.65892],
    [40.392424, 37.659445],
    [40.395954, 37.658203],
    [40.396307, 37.658567],
    [40.397725, 37.656149],
    [40.399364, 37.655906],
    [40.401829, 37.65497],
    [40.40395, 37.654456],
    [40.408368, 37.654763],
    [40.407524, 37.654019],
    [40.406747, 37.65383],
    [40.406807, 37.653617],
    [40.406086, 37.653601],
    [40.405726, 37.653308],
    [40.405805, 37.653217],
    [40.406573, 37.653361],
    [40.407018, 37.653343],
    [40.407185, 37.653134],
    [40.407391, 37.653169],
    [40.407541, 37.653077],
    [40.408154, 37.653455],
    [40.409122, 37.652765],
    [40.410949, 37.651045],
    [40.411827, 37.650519],
    [40.411064, 37.650386],
    [40.410947, 37.649804],
    [40.410912, 37.648808],
    [40.411362, 37.648556],
    [40.411362, 37.648141],
    [40.413195, 37.64721],
    [40.414366, 37.647303],
    [40.415039, 37.645806],
    [40.416076, 37.645398],
    [40.417892, 37.642086],
    [40.418753, 37.641548],
    [40.418042, 37.640782],
    [40.417097, 37.639017],
    [40.41731, 37.638704],
    [40.418021, 37.639885],
    [40.418347, 37.640293],
    [40.418571, 37.640444],
    [40.418831, 37.640861],
    [40.420137, 37.641464],
    [40.420575, 37.641874],
    [40.421488, 37.642397],
    [40.421764, 37.642707],
    [40.421948, 37.643373],
    [40.422273, 37.643704],
    [40.422633, 37.643828],
    [40.423481, 37.643862],
    [40.423484, 37.643573],
    [40.423929, 37.643128],
    [40.424143, 37.642246],
    [40.424216, 37.642167],
    [40.424487, 37.642124],
    [40.424905, 37.643261],
    [40.424889, 37.64374],
    [40.425567, 37.644321],
    [40.426237, 37.644661],
    [40.426905, 37.644843],
    [40.427796, 37.644951],
    [40.428547, 37.644952],
    [40.4293, 37.645153],
    [40.431974, 37.645549],
    [40.432614, 37.645771],
    [40.433297, 37.646134],
    [40.434181, 37.644844],
    [40.434392, 37.645294],
    [40.434336, 37.645876],
    [40.43448, 37.646348],
    [40.434688, 37.646577],
    [40.435239, 37.6467],
    [40.436309, 37.646708],
    [40.437226, 37.646886],
    [40.437734, 37.646612],
    [40.437991, 37.646621],
    [40.438457, 37.645895],
    [40.43909, 37.643383],
    [40.440711, 37.643578],
    [40.441647, 37.643104],
    [40.444148, 37.642581],
    [40.444791, 37.641404],
    [40.44476, 37.640914],
    [40.445004, 37.640319],
    [40.447074, 37.641362],
    [40.448512, 37.641922],
    [40.448833, 37.642128],
    [40.448679, 37.639171],
    [40.448104, 37.638893],
    [40.445753, 37.636416],
    [40.443848, 37.634942],
    [40.443136, 37.634063],
    [40.442939, 37.63358],
    [40.443018, 37.632974],
    [40.443536, 37.632245],
    [40.443992, 37.631976],
    [40.44424, 37.63138],
    [40.444123, 37.630002],
    [40.443614, 37.629224],
    [40.443537, 37.628925],
    [40.443583, 37.62861],
    [40.44374, 37.62836],
    [40.444653, 37.62791],
    [40.444739, 37.627635],
    [40.444585, 37.627384],
    [40.44415, 37.627054],
    [40.443306, 37.626723],
    [40.441644, 37.626392],
    [40.441184, 37.626418],
    [40.440882, 37.626618],
    [40.440808, 37.626805],
    [40.441081, 37.627473],
    [40.440993, 37.627655],
    [40.440627, 37.62783],
    [40.440302, 37.627844],
    [40.439397, 37.627674],
    [40.438528, 37.627721],
    [40.438317, 37.627595],
    [40.438024, 37.626504],
    [40.437507, 37.625963],
    [40.437269, 37.625423],
    [40.437147, 37.624795],
    [40.437386, 37.624053],
    [40.437646, 37.623735],
    [40.439286, 37.623948],
    [40.439479, 37.623202],
    [40.439765, 37.622836],
    [40.44522, 37.623863],
    [40.450413, 37.62047],
    [40.450431, 37.620247],
    [40.450765, 37.620239],
    [40.451371, 37.620076],
    [40.452255, 37.619705],
    [40.452695, 37.61966],
    [40.453087, 37.619478],
    [40.453003, 37.618827],
    [40.453062, 37.618703],
    [40.452836, 37.618513],
    [40.453018, 37.618409],
    [40.453053, 37.618161],
    [40.453277, 37.618053],
    [40.453209, 37.617806],
    [40.453311, 37.613558],
    [40.453779, 37.60722],
    [40.456897, 37.604396],
    [40.457216, 37.604382],
    [40.457149, 37.603871],
    [40.45762, 37.603861],
    [40.457502, 37.603204],
    [40.458854, 37.603136],
    [40.459396, 37.602854],
    [40.459753, 37.602502],
    [40.46021, 37.602568],
    [40.460437, 37.602957],
    [40.460837, 37.602923],
    [40.462068, 37.602487],
    [40.462288, 37.602704],
    [40.466541, 37.601041],
    [40.468723, 37.600545],
    [40.470954, 37.600908],
    [40.473355, 37.601555],
    [40.478971, 37.602058],
    [40.490164, 37.605802],
    [40.490702, 37.606696],
    [40.491426, 37.606839],
    [40.491875, 37.606682],
    [40.492009, 37.606723],
    [40.492249, 37.606838],
    [40.492274, 37.606934],
    [40.492138, 37.607034],
    [40.492272, 37.607116],
    [40.492271, 37.607332],
    [40.492215, 37.607515],
    [40.492661, 37.607826],
    [40.493826, 37.608177],
    [40.494472, 37.608052],
    [40.494902, 37.607399],
    [40.495863, 37.607589],
    [40.4967033, 37.6070051],
    [40.4978602, 37.6048252],
    [40.49919, 37.6032194],
    [40.5005657, 37.6038395],
    [40.5020158, 37.6049215],
    [40.5030042, 37.6058605],
    [40.504241, 37.607905],
    [40.505935, 37.607806],
    [40.505842, 37.607969],
    [40.506036, 37.608098],
    [40.506633, 37.60823],
    [40.507377, 37.608227],
    [40.507832, 37.608304],
    [40.508414, 37.608559],
    [40.509178, 37.609067],
    [40.509374, 37.609337],
    [40.509576, 37.609913],
    [40.509831, 37.610032],
    [40.509636, 37.610208],
    [40.509559, 37.610067],
    [40.509461, 37.610094],
    [40.50922, 37.610641],
    [40.509204, 37.611347],
    [40.509537, 37.611677],
    [40.510078, 37.611847],
    [40.510894, 37.611791],
    [40.511374, 37.61187],
    [40.511944, 37.612315],
    [40.512443, 37.612852],
    [40.51298, 37.612536],
    [40.513466, 37.612881],
    [40.514353, 37.613185],
    [40.51462, 37.613569],
    [40.515106, 37.61383],
    [40.515378, 37.614199],
    [40.515907, 37.614588],
    [40.516241, 37.614662],
    [40.517076, 37.614356],
    [40.517238, 37.614559],
    [40.51761, 37.614775],
    [40.52147, 37.616827],
    [40.52395, 37.618484],
    [40.524069, 37.617877],
    [40.523966, 37.617757],
    [40.524103, 37.617569],
    [40.52405, 37.61741],
    [40.52428, 37.616875],
    [40.524114, 37.616573],
    [40.523504, 37.616222],
    [40.523264, 37.615978],
    [40.523197, 37.615774],
    [40.522976, 37.615673],
    [40.522984, 37.615568],
    [40.52339, 37.614692],
    [40.523863, 37.614077],
    [40.523878, 37.613828],
    [40.524338, 37.613492],
    [40.524514, 37.613262],
    [40.524941, 37.613109],
    [40.52542, 37.613085],
    [40.526353, 37.613254],
    [40.527123, 37.613042],
    [40.52757, 37.612844],
    [40.527904, 37.612792],
    [40.528605, 37.612317],
    [40.530797, 37.61144],
    [40.530976, 37.611798],
    [40.531236, 37.61196],
    [40.531456, 37.612414],
    [40.531729, 37.612725],
    [40.532988, 37.613783],
    [40.534051, 37.613647],
    [40.537481, 37.611313],
    [40.539085, 37.610544],
    [40.53999, 37.611028],
    [40.539868, 37.603459],
    [40.545367, 37.598888],
    [40.551407, 37.60111],
    [40.552826, 37.601434],
    [40.553945, 37.602418],
    [40.553528, 37.602806],
    [40.55373, 37.602916],
    [40.553893, 37.604162],
    [40.556286, 37.602362],
    [40.557579, 37.602659],
    [40.558832, 37.602618],
    [40.563875, 37.601951],
    [40.565109, 37.6005],
    [40.565791, 37.598209],
    [40.566038, 37.596343],
    [40.567497, 37.594276],
    [40.570793, 37.597085],
    [40.573563, 37.600503],
    [40.573504, 37.600759],
    [40.574872, 37.600861],
    [40.575169, 37.600999],
    [40.575393, 37.601462],
    [40.575744, 37.601262],
    [40.578091, 37.600824],
    [40.579404, 37.600943],
    [40.58005, 37.601391],
    [40.581941, 37.600258],
    [40.583478, 37.59968],
    [40.583935, 37.596082],
    [40.58722, 37.59567],
    [40.587326, 37.595714],
    [40.587342, 37.59592],
    [40.587509, 37.59612],
    [40.5881, 37.596371],
    [40.588248, 37.596394],
    [40.588398, 37.596236],
    [40.589048, 37.596244],
    [40.589279, 37.596416],
    [40.589113, 37.596512],
    [40.589748, 37.596549],
    [40.589916, 37.596454],
    [40.590521, 37.596663],
    [40.591022, 37.597449],
    [40.591259, 37.597678],
    [40.593728, 37.597913],
    [40.597349, 37.599141],
    [40.59748, 37.599675],
    [40.598601, 37.600387],
    [40.601871, 37.598037],
    [40.6041, 37.59706],
    [40.605459, 37.593965],
    [40.606151, 37.591247],
    [40.60959, 37.592575],
    [40.611529, 37.593804],
    [40.611511, 37.596848],
    [40.613855, 37.599311],
    [40.61557, 37.602155],
    [40.615372, 37.604475],
    [40.616461, 37.604853],
    [40.618922, 37.604945],
    [40.621076, 37.604695],
    [40.621327, 37.60486],
    [40.621187, 37.605023],
    [40.625097, 37.605315],
    [40.627848, 37.603433],
    [40.629878, 37.603193],
    [40.631347, 37.603922],
    [40.63481, 37.604539],
    [40.636365, 37.606882],
    [40.638159, 37.609102],
    [40.637024, 37.611515],
    [40.635734, 37.613094],
    [40.636802, 37.617515],
    [40.638311, 37.619495],
    [40.639009, 37.620849],
    [40.639336, 37.622964],
    [40.639016, 37.624752],
    [40.637323, 37.626934],
    [40.637503, 37.628436],
    [40.638486, 37.631091],
    [40.637825, 37.63234],
    [40.637948, 37.633008],
    [40.639497, 37.636758],
    [40.639724, 37.637891],
    [40.636964, 37.640372],
    [40.636156, 37.640533],
    [40.635708, 37.64084],
    [40.63645, 37.644469],
    [40.636315, 37.646801],
    [40.636484, 37.64899],
    [40.637143, 37.650462],
    [40.636883, 37.651753],
    [40.636997, 37.654708],
    [40.63598, 37.655811],
    [40.63466, 37.658215],
    [40.632518, 37.661132],
    [40.631748, 37.663343],
    [40.63097, 37.665963],
    [40.630355, 37.667178],
    [40.630981, 37.66822],
    [40.633446, 37.669229],
    [40.633739, 37.66927],
    [40.636594, 37.668707],
    [40.637349, 37.669929],
    [40.634068, 37.671656],
    [40.633245, 37.670721],
    [40.629956, 37.669739],
    [40.62987, 37.671237],
    [40.630584, 37.671686],
    [40.629893, 37.673069],
    [40.629213, 37.673634],
    [40.62749, 37.674019],
    [40.627, 37.675847],
    [40.626289, 37.67768],
    [40.62966, 37.679213],
    [40.629562, 37.67948],
    [40.628797, 37.679939],
    [40.627995, 37.680248],
    [40.625631, 37.68097],
    [40.624988, 37.680683],
    [40.62415, 37.680977],
    [40.623914, 37.682223],
    [40.622809, 37.684093],
    [40.622099, 37.685919],
    [40.621466, 37.688155],
    [40.620338, 37.690304],
    [40.618652, 37.693003],
    [40.615776, 37.69539],
    [40.615139, 37.696049],
    [40.615277, 37.696315],
    [40.615725, 37.696672],
    [40.615981, 37.697363],
    [40.617588, 37.698473],
    [40.618361, 37.699098],
    [40.618707, 37.699245],
    [40.620386, 37.701384],
    [40.623062, 37.701817],
    [40.624428, 37.70246],
    [40.625018, 37.703304],
    [40.62528, 37.704332],
    [40.625394, 37.705289],
    [40.625333, 37.705748],
    [40.625465, 37.706203],
    [40.625688, 37.706499],
    [40.625858, 37.707044],
    [40.626071, 37.70737],
    [40.626452, 37.709059],
    [40.626228, 37.70957],
    [40.62698, 37.710162],
    [40.627639, 37.711025],
    [40.628336, 37.712748],
    [40.629476, 37.714948],
    [40.630041, 37.715808],
    [40.630745, 37.71604],
    [40.631963, 37.715418],
    [40.637365, 37.713215],
    [40.639269, 37.712356],
    [40.640206, 37.712047],
    [40.640997, 37.711572],
    [40.643872, 37.710216],
    [40.644475, 37.710886],
    [40.644843, 37.711467],
    [40.645569, 37.711746],
    [40.645603, 37.712211],
    [40.645752, 37.712377],
    [40.645989, 37.713106],
    [40.646894, 37.714035],
    [40.646892, 37.715118],
    [40.647294, 37.716149],
    [40.647473, 37.716402],
    [40.647549, 37.716838],
    [40.647826, 37.717321],
    [40.648112, 37.717651],
    [40.648983, 37.71831],
    [40.648997, 37.718533],
    [40.649213, 37.718579],
    [40.649586, 37.718901],
    [40.650095, 37.718816],
    [40.653114, 37.722642],
    [40.653611, 37.723594],
    [40.653429, 37.723856],
    [40.651785, 37.724346],
    [40.650397, 37.724937],
    [40.649241, 37.725855],
    [40.649171, 37.726246],
    [40.649389, 37.726741],
    [40.649384, 37.727289],
    [40.649975, 37.727808],
    [40.650577, 37.728772],
    [40.651038, 37.729883],
    [40.65284, 37.731317],
    [40.653129, 37.731443],
    [40.655442, 37.731712],
    [40.656566, 37.732021],
    [40.65678, 37.732026],
    [40.657469, 37.731843],
    [40.658162, 37.732492],
    [40.657313, 37.7332],
    [40.655815, 37.733985],
    [40.66596, 37.737708],
    [40.666503, 37.738211],
    [40.667482, 37.738289],
    [40.67148, 37.73865],
    [40.672034, 37.738346],
    [40.672227, 37.738183],
    [40.672217, 37.738044],
    [40.672299, 37.73799],
    [40.672742, 37.737923],
    [40.673691, 37.738211],
    [40.674063, 37.738435],
    [40.674331, 37.738345],
    [40.674492, 37.738154],
    [40.674788, 37.737456],
    [40.674751, 37.737384],
    [40.673569, 37.736656],
    [40.673156, 37.736215],
    [40.673136, 37.736071],
    [40.673694, 37.735777],
    [40.673859, 37.735609],
    [40.674889, 37.735446],
    [40.675617, 37.735476],
    [40.675776, 37.735366],
    [40.67563, 37.734587],
    [40.675639, 37.734101],
    [40.675364, 37.733941],
    [40.674876, 37.733861],
    [40.674243, 37.733568],
    [40.674653, 37.732094],
    [40.674921, 37.731583],
    [40.675265, 37.730089],
    [40.674967, 37.729917],
    [40.674071, 37.729797],
    [40.673621, 37.727968],
    [40.673709, 37.727527],
    [40.673816, 37.727571],
    [40.673923, 37.727183],
    [40.674144, 37.726849],
    [40.673469, 37.726314],
    [40.673256, 37.726226],
    [40.673194, 37.726284],
    [40.673377, 37.72658],
    [40.673349, 37.726627],
    [40.67321, 37.726524],
    [40.672374, 37.725577],
    [40.672411, 37.725055],
    [40.672568, 37.724859],
    [40.673587, 37.724295],
    [40.674463, 37.724074],
    [40.674913, 37.723876],
    [40.675039, 37.723758],
    [40.674755, 37.72337],
    [40.675495, 37.722525],
    [40.675904, 37.722287],
    [40.677002, 37.722047],
    [40.677548, 37.722033],
    [40.677669, 37.721692],
    [40.678868, 37.720719],
    [40.680567, 37.719744],
    [40.682047, 37.719094],
    [40.683748, 37.718585],
    [40.683997, 37.718655],
    [40.685253, 37.718421],
    [40.6871, 37.717906],
    [40.689926, 37.717818],
    [40.690797, 37.717852],
    [40.69116, 37.71778],
    [40.691833, 37.717408],
    [40.692387, 37.716808],
    [40.692574, 37.716482],
    [40.692863, 37.715629],
    [40.693561, 37.714271],
    [40.693774, 37.712868],
    [40.693722, 37.712549],
    [40.693872, 37.711911],
    [40.693788, 37.711763],
    [40.693782, 37.711313],
    [40.694283, 37.710658],
    [40.694701, 37.709916],
    [40.695197, 37.708635],
    [40.695029, 37.707877],
    [40.694582, 37.707214],
    [40.69427, 37.706335],
    [40.693459, 37.705341],
    [40.693388, 37.704807],
    [40.693514, 37.704451],
    [40.694314, 37.703606],
    [40.695639, 37.70294],
    [40.69661, 37.702019],
    [40.697188, 37.701809],
    [40.696998, 37.70165],
    [40.696641, 37.701046],
    [40.697285, 37.700581],
    [40.698202, 37.700169],
    [40.698393, 37.699992],
    [40.698793, 37.69993],
    [40.699322, 37.700007],
    [40.700393, 37.699868],
    [40.702772, 37.698999],
    [40.703085, 37.698818],
    [40.703412, 37.698368],
    [40.703359, 37.698123],
    [40.703538, 37.697316],
    [40.703853, 37.696875],
    [40.704096, 37.696983],
    [40.704214, 37.697286],
    [40.704581, 37.697401],
    [40.705256, 37.697236],
    [40.706, 37.696924],
    [40.706499, 37.69652],
    [40.707557, 37.695179],
    [40.708053, 37.694273],
    [40.708781, 37.693666],
    [40.709169, 37.693618],
    [40.709517, 37.693748],
    [40.710605, 37.692751],
    [40.711222, 37.690525],
    [40.711135, 37.690354],
    [40.7112, 37.6893],
    [40.711384, 37.689322],
    [40.711449, 37.688506],
    [40.711675, 37.688651],
    [40.711692, 37.688577],
    [40.712627, 37.689313],
    [40.712733, 37.689525],
    [40.712651, 37.689678],
    [40.712678, 37.690024],
    [40.712928, 37.690946],
    [40.712485, 37.691567],
    [40.712383, 37.691872],
    [40.712111, 37.693758],
    [40.711691, 37.694427],
    [40.711676, 37.694741],
    [40.710907, 37.695749],
    [40.709293, 37.696606],
    [40.708632, 37.696784],
    [40.707743, 37.697239],
    [40.707111, 37.697673],
    [40.706033, 37.699435],
    [40.706235, 37.699773],
    [40.706169, 37.699878],
    [40.708467, 37.700753],
    [40.709895, 37.702273],
    [40.712234, 37.703866],
    [40.712397, 37.703904],
    [40.712022, 37.704639],
    [40.711489, 37.705219],
    [40.711461, 37.705439],
    [40.711624, 37.705933],
    [40.711791, 37.70605],
    [40.711718, 37.708396],
    [40.711883, 37.709924],
    [40.711935, 37.710037],
    [40.712834, 37.710394],
    [40.713968, 37.710687],
    [40.716865, 37.711004],
    [40.717995, 37.711323],
    [40.719195, 37.711534],
    [40.72105, 37.711802],
    [40.721929, 37.711856],
    [40.723, 37.711857],
    [40.72581, 37.711258],
    [40.725932, 37.711195],
    [40.725993, 37.710789],
    [40.725925, 37.710029],
    [40.728621, 37.709366],
    [40.732369, 37.708094],
    [40.736685, 37.707065],
    [40.736533, 37.70567],
    [40.737139, 37.705584],
    [40.736981, 37.704886],
    [40.741252, 37.704065],
    [40.740913, 37.703017],
    [40.740977, 37.703051],
    [40.743819, 37.702282],
    [40.745695, 37.70188],
    [40.747103, 37.70372],
    [40.749018, 37.702952],
    [40.749034, 37.702822],
    [40.748801, 37.70241],
    [40.748474, 37.701458],
    [40.748478, 37.701217],
    [40.748641, 37.700824],
    [40.750259, 37.699805],
    [40.753051, 37.698825],
    [40.758649, 37.6986],
    [40.760468, 37.69904],
    [40.763644, 37.700217],
    [40.76356, 37.700735],
    [40.766271, 37.702383],
    [40.766807, 37.69878],
    [40.768535, 37.698407],
    [40.768642, 37.69923],
    [40.771541, 37.699283],
    [40.77154, 37.698118],
    [40.771242, 37.697508],
    [40.771903, 37.697219],
    [40.771019, 37.695538],
    [40.772485, 37.694633],
    [40.771075, 37.693893],
    [40.767597, 37.694406],
    [40.767003, 37.692266],
    [40.771007, 37.691312],
    [40.771191, 37.692025],
    [40.772296, 37.691806],
    [40.77188, 37.69029],
    [40.772766, 37.690113],
    [40.773091, 37.689963],
    [40.772792, 37.689346],
    [40.772624, 37.688721],
    [40.774535, 37.688194],
    [40.773801, 37.686788],
    [40.7733, 37.685271],
    [40.77478, 37.685118],
    [40.775426, 37.684824],
    [40.775825, 37.687667],
    [40.776142, 37.688607],
    [40.779561, 37.687995],
    [40.778839, 37.686171],
    [40.780929, 37.685847],
    [40.781001, 37.685974],
    [40.781895, 37.68562],
    [40.782898, 37.683776],
    [40.784056, 37.685445],
    [40.785518, 37.685343],
    [40.786092, 37.686484],
    [40.788225, 37.686792],
    [40.78826, 37.687886],
    [40.78897, 37.690467],
    [40.789973, 37.693515],
    [40.792053, 37.693192],
    [40.791942, 37.69155],
    [40.793874, 37.691192],
    [40.793968, 37.691268],
    [40.796008, 37.691424],
    [40.7963, 37.691032],
    [40.797074, 37.690997],
    [40.797068, 37.69393],
    [40.798351, 37.692805],
    [40.800665, 37.693931],
    [40.799748, 37.695024],
    [40.800709, 37.695966],
    [40.803712, 37.694059],
    [40.805343, 37.695928],
    [40.807594, 37.694294],
    [40.810037, 37.693269],
    [40.810972, 37.692784],
    [40.811825, 37.693459],
    [40.811827, 37.694125],
    [40.810717, 37.694396],
    [40.811263, 37.69525],
    [40.811917, 37.69566],
    [40.81243, 37.695302],
    [40.813458, 37.695393],
    [40.81367, 37.695647],
    [40.813345, 37.696078],
    [40.813378, 37.697229],
    [40.813886, 37.697244],
    [40.814462, 37.697755],
    [40.814581, 37.698271],
    [40.814017, 37.698537],
    [40.813831, 37.69905],
    [40.813929, 37.700071],
    [40.81418, 37.701103],
    [40.815003, 37.70203],
    [40.815447, 37.702148],
    [40.815757, 37.702484],
    [40.816438, 37.702683],
    [40.817085, 37.70311],
    [40.817422, 37.703207],
    [40.81752, 37.70366],
    [40.81777, 37.703889],
    [40.817465, 37.704678],
    [40.819004, 37.707762],
    [40.821175, 37.707824],
    [40.820938, 37.708397],
    [40.820754, 37.709566],
    [40.820765, 37.710485],
    [40.821015, 37.711547],
    [40.821251, 37.711852],
    [40.821482, 37.711754],
    [40.822, 37.712718],
    [40.82259, 37.713395],
    [40.82339, 37.713913],
    [40.823199, 37.714319],
    [40.824656, 37.715271],
    [40.82561, 37.713902],
    [40.828358, 37.712179],
    [40.829659, 37.712924],
    [40.831113, 37.711357],
    [40.831436, 37.71168],
    [40.832908, 37.711661],
    [40.833465, 37.711744],
    [40.833407, 37.70957],
    [40.834033, 37.708704],
    [40.835457, 37.70918],
    [40.836282, 37.708183],
    [40.837046, 37.707005],
    [40.838154, 37.706125],
    [40.838548, 37.705664],
    [40.839504, 37.704055],
    [40.84045, 37.702203],
    [40.840956, 37.699902],
    [40.841091, 37.69722],
    [40.839706, 37.69716],
    [40.839723, 37.696287],
    [40.840115, 37.694882],
    [40.840079, 37.694256],
    [40.840247, 37.693218],
    [40.840113, 37.693194],
    [40.840047, 37.691286],
    [40.841317, 37.690974],
    [40.841359, 37.690891],
    [40.840923, 37.690596],
    [40.839767, 37.689468],
    [40.838793, 37.688221],
    [40.838015, 37.687399],
    [40.837595, 37.686669],
    [40.838386, 37.686551],
    [40.838542, 37.687032],
    [40.838877, 37.686979],
    [40.838805, 37.686691],
    [40.838894, 37.686664],
    [40.838366, 37.685135],
    [40.83653, 37.684071],
    [40.836242, 37.683856],
    [40.836286, 37.683821],
    [40.836051, 37.683527],
    [40.836878, 37.682967],
    [40.837139, 37.682664],
    [40.836579, 37.682244],
    [40.836013, 37.681667],
    [40.835981, 37.681555],
    [40.836221, 37.681223],
    [40.836194, 37.681076],
    [40.835618, 37.680273],
    [40.835868, 37.679711],
    [40.83574, 37.679587],
    [40.835785, 37.679492],
    [40.836083, 37.679367],
    [40.83643, 37.678911],
    [40.836901, 37.678507],
    [40.838905, 37.67888],
    [40.839017, 37.678783],
    [40.839047, 37.678102],
    [40.838916, 37.67798],
    [40.838376, 37.6779],
    [40.838007, 37.677241],
    [40.837416, 37.676613],
    [40.837318, 37.676418],
    [40.837421, 37.675908],
    [40.839666, 37.676171],
    [40.841557, 37.676228],
    [40.844479, 37.676452],
    [40.844781, 37.676417],
    [40.845264, 37.676195],
    [40.846261, 37.674918],
    [40.849505, 37.671772],
    [40.85053, 37.669947],
    [40.851128, 37.668256],
    [40.851896, 37.666837],
    [40.855655, 37.661765],
    [40.858282, 37.657276],
    [40.861976, 37.655408],
    [40.862147, 37.654681],
    [40.862126, 37.653075],
    [40.855148, 37.650867],
    [40.853981, 37.651594],
    [40.850408, 37.647657],
    [40.851416, 37.639675],
    [40.854993, 37.630088],
    [40.858294, 37.623768],
    [40.861104, 37.619614],
    [40.865473, 37.619042],
    [40.877829, 37.619628],
    [40.880856, 37.621589],
    [40.883433, 37.628885],
    [40.886044, 37.635185],
    [40.887184, 37.636555],
    [40.88929, 37.638736],
    [40.893297, 37.644714],
    [40.893604, 37.645815],
    [40.893915, 37.646442],
    [40.893977, 37.646933],
    [40.89319, 37.647551],
    [40.892478, 37.64787],
    [40.891957, 37.647913],
    [40.891092, 37.648444],
    [40.89037, 37.648726],
    [40.889943, 37.648773],
    [40.889704, 37.648688],
    [40.889835, 37.649558],
    [40.889506, 37.650936],
    [40.888898, 37.651598],
    [40.887941, 37.652861],
    [40.887459, 37.65317],
    [40.889251, 37.654616],
    [40.889368, 37.654845],
    [40.888911, 37.654639],
    [40.888799, 37.655039],
    [40.889354, 37.65541],
    [40.889327, 37.655556],
    [40.889604, 37.655746],
    [40.889495, 37.656012],
    [40.888227, 37.655278],
    [40.887611, 37.655115],
    [40.886793, 37.655056],
    [40.885778, 37.654727],
    [40.886334, 37.655324],
    [40.887547, 37.656047],
    [40.889222, 37.657345],
    [40.889959, 37.657778],
    [40.890382, 37.658384],
    [40.891191, 37.658888],
    [40.893239, 37.659853],
    [40.893542, 37.659938],
    [40.894392, 37.659953],
    [40.895005, 37.660151],
    [40.895214, 37.660295],
    [40.895578, 37.660928],
    [40.895822, 37.661407],
    [40.895916, 37.662005],
    [40.896119, 37.662146],
    [40.897061, 37.662073],
    [40.897639, 37.661812],
    [40.898403, 37.661734],
    [40.89888, 37.661853],
    [40.899925, 37.662455],
    [40.900636, 37.662479],
    [40.901098, 37.662304],
    [40.902387, 37.66149],
    [40.904075, 37.661302],
    [40.905084, 37.661499],
    [40.905717, 37.661851],
    [40.906505, 37.661944],
    [40.906802, 37.661931],
    [40.907268, 37.661791],
    [40.907885, 37.661802],
    [40.909172, 37.662283],
    [40.909531, 37.662612],
    [40.910222, 37.664259],
    [40.910836, 37.665089],
    [40.911141, 37.666398],
    [40.911318, 37.667731],
    [40.911334, 37.668323],
    [40.911067, 37.668969],
    [40.910784, 37.66948],
    [40.90963, 37.67093],
    [40.909559, 37.671246],
    [40.909693, 37.67171],
    [40.91011, 37.67186],
    [40.911374, 37.672114],
    [40.912798, 37.673194],
    [40.913209, 37.673418],
    [40.91364, 37.673589],
    [40.914419, 37.673726],
    [40.91488, 37.67389],
    [40.915848, 37.674483],
    [40.917029, 37.674317],
    [40.917655, 37.674352],
    [40.918093, 37.674742],
    [40.918727, 37.675097],
    [40.919033, 37.675413],
    [40.919356, 37.676615],
    [40.919601, 37.676999],
    [40.920251, 37.676569],
    [40.920719, 37.676142],
    [40.921702, 37.674932],
    [40.922036, 37.674261],
    [40.923093, 37.673694],
    [40.924105, 37.673384],
    [40.924876, 37.673253],
    [40.925826, 37.673396],
    [40.926356, 37.673733],
    [40.927017, 37.673955],
    [40.927787, 37.673694],
    [40.928633, 37.673167],
    [40.929609, 37.67277],
    [40.93435, 37.673243],
    [40.93939, 37.673514],
    [40.942593, 37.674088],
    [40.944867, 37.674402],
    [40.945455, 37.675001],
    [40.945839, 37.676691],
    [40.944878, 37.6809],
    [40.945028, 37.682759],
    [40.945731, 37.683356],
    [40.946354, 37.68371],
    [40.946867, 37.684439],
    [40.947131, 37.684628],
    [40.947391, 37.68521],
    [40.948161, 37.686373],
    [40.948078, 37.687062],
    [40.947913, 37.687547],
    [40.948014, 37.68843],
    [40.947765, 37.689417],
    [40.946439, 37.691727],
    [40.947234, 37.692269],
    [40.948213, 37.692696],
    [40.948503, 37.693134],
    [40.948112, 37.693803],
    [40.951655, 37.696698],
    [40.952213, 37.6968],
    [40.952696, 37.69672],
    [40.953227, 37.696786],
    [40.954578, 37.696618],
    [40.957569, 37.695912],
    [40.958821, 37.695759],
    [40.95895, 37.695724],
    [40.958957, 37.695623],
    [40.958798, 37.695394],
    [40.959332, 37.694728],
    [40.95953, 37.694796],
    [40.96044, 37.694406],
    [40.961088, 37.694707],
    [40.961594, 37.694233],
    [40.962131, 37.693978],
    [40.962818, 37.693799],
    [40.963175, 37.693839],
    [40.96518, 37.693673],
    [40.966608, 37.69314],
    [40.967496, 37.692896],
    [40.96852, 37.692905],
    [40.969832, 37.692786],
    [40.970978, 37.692562],
    [40.97206, 37.692528],
    [40.972786, 37.692602],
    [40.97398, 37.692336],
    [40.974326, 37.692346],
    [40.976874, 37.69313],
    [40.977328, 37.69343],
    [40.979273, 37.69369],
    [40.981728, 37.693899],
    [40.986901, 37.695497],
    [40.989358, 37.69616],
    [40.988528, 37.699842],
    [40.988663, 37.702014],
    [40.994184, 37.706356],
    [40.993449, 37.707057],
    [40.992235, 37.707585],
    [40.990106, 37.710211],
    [40.991096, 37.711209],
    [40.990267, 37.712304],
    [40.990419, 37.712801],
    [40.989878, 37.71388],
    [40.988703, 37.713277],
    [40.988751, 37.71415],
    [40.989774, 37.714875],
    [40.988163, 37.71607],
    [40.986305, 37.716988],
    [40.987642, 37.717444],
    [40.98901, 37.716929],
    [40.990751, 37.719303],
    [40.990418, 37.720599],
    [40.990804, 37.720944],
    [40.990612, 37.72124],
    [40.990043, 37.721525],
    [40.990198, 37.722652],
    [40.991547, 37.723565],
    [40.992848, 37.723801],
    [40.992968, 37.723267],
    [40.99291, 37.722877],
    [40.993752, 37.722039],
    [40.996005, 37.720945],
    [40.998685, 37.71672],
    [41.000318, 37.717452],
    [41.000934, 37.716609],
    [41.001218, 37.716886],
    [41.001728, 37.717135],
    [41.002686, 37.717046],
    [41.002906, 37.717115],
    [41.00306, 37.717295],
    [41.003964, 37.717491],
    [41.003506, 37.718702],
    [41.002253, 37.720151],
    [41.003059, 37.720824],
    [41.003899, 37.720015],
    [41.004864, 37.719455],
    [41.005074, 37.719665],
    [41.006201, 37.719588],
    [41.006143, 37.720319],
    [41.004816, 37.720479],
    [41.004578, 37.720772],
    [41.004577, 37.720881],
    [41.004641, 37.721061],
    [41.004828, 37.721203],
    [41.005892, 37.721804],
    [41.007924, 37.723895],
    [41.007821, 37.724185],
    [41.006598, 37.725117],
    [41.009029, 37.726387],
    [41.010268, 37.725549],
    [41.010977, 37.725326],
    [41.011316, 37.725354],
    [41.011403, 37.725304],
    [41.0451669, 37.7450313],
    [41.0590358, 37.7589665],
    [41.0633146, 37.7685812],
    [41.0565052, 37.7707409],
    [41.0518335, 37.7731468],
    [41.0505735, 37.774747],
    [41.0504034, 37.7814331],
    [41.0475002, 37.7822064],
    [41.0445601, 37.7823905],
    [41.0382288, 37.7818267],
    [41.027959, 37.7735443],
    [41.0214276, 37.774544],
    [41.0179885, 37.7758579],
    [41.0171197, 37.7774259],
    [41.0181003, 37.7792715],
    [41.0281409, 37.7855006],
    [41.0302946, 37.7888052],
    [41.0309219, 37.7919843],
    [41.029786, 37.7947459],
    [41.0280587, 37.7964832],
    [41.0260132, 37.7972752],
    [41.0223421, 37.797492],
    [41.0203125, 37.7971315],
    [41.0180458, 37.7904901],
    [41.0127539, 37.7878131],
    [41.0091952, 37.7906188],
    [41.0034109, 37.795151],
    [40.9967047, 37.7979139],
    [40.9920619, 37.8021712],
    [40.9877057, 37.8039375],
    [40.9864447, 37.8058396],
    [40.9867312, 37.8081944],
    [40.9920046, 37.8126323],
    [40.9961888, 37.8180208],
    [40.9986535, 37.8219147],
    [40.9957322, 37.825401],
    [40.9965031, 37.8280532],
    [41.0023792, 37.8317845],
    [41.0020353, 37.8360398],
    [40.997448, 37.8380404],
    [40.997448, 37.8406338],
    [40.997853, 37.8422092],
    [40.998258, 37.8437844],
    [41.0028176, 37.845335],
    [41.0055318, 37.8490307],
    [41.0040988, 37.8542356],
    [41.007366, 37.8588064],
    [41.0101426, 37.8622132],
    [41.011276, 37.8636037],
    [41.0183137, 37.8693047],
    [41.0233579, 37.8695309],
    [41.0270836, 37.8731054],
    [41.0300437, 37.8767744],
    [41.0272304, 37.8778654],
    [41.0205468, 37.8806893],
    [41.0170491, 37.8852033],
    [41.0226924, 37.8936366],
    [41.0192601, 37.8962918],
    [41.0192656, 37.8984151],
    [41.019396, 37.8997915],
    [41.0194537, 37.899923],
    [41.0200092, 37.9003128],
    [41.0205489, 37.9006922],
    [41.0209716, 37.9006303],
    [41.0219706, 37.9004229],
    [41.0226542, 37.9004408],
    [41.0234551, 37.9006896],
    [41.023889, 37.9010359],
    [41.0242257, 37.9014548],
    [41.024481, 37.9019538],
    [41.0245797, 37.9023257],
    [41.0244097, 37.9029885],
    [41.0244099, 37.9033955],
    [41.0244137, 37.9035321],
    [41.0245803, 37.9036965],
    [41.02549, 37.9041737],
    [41.0267767, 37.9045547],
    [41.0275332, 37.9047899],
    [41.0278792, 37.9049525],
    [41.0280414, 37.9050517],
    [41.0281468, 37.9051866],
    [41.0282951, 37.9056771],
    [41.0294251, 37.9060405],
    [41.0300616, 37.9063024],
    [41.0302696, 37.9064595],
    [41.0304288, 37.9067],
    [41.0304071, 37.9080818],
    [41.0324104, 37.9130343],
    [41.0347173, 37.9203033],
    [41.0329457, 37.9273115],
    [41.0307326, 37.9310299],
    [41.0335007, 37.9343993],
    [41.0315633, 37.9433272],
    [41.033477, 37.9514895],
    [41.0347108, 37.9556009],
    [41.0342495, 37.9574028],
    [41.0347967, 37.9605073],
    [41.0357452, 37.9607129],
    [41.0420923, 37.9592807],
    [41.0476605, 37.9575381],
    [41.0580694, 37.9564348],
    [41.0684079, 37.9549173],
    [41.0720558, 37.9542759],
    [41.0772414, 37.952035],
    [41.0819357, 37.9479739],
    [41.0851289, 37.9508612],
    [41.0858962, 37.9516286],
    [41.0857308, 37.9525803],
    [41.086313, 37.9531018],
    [41.0886239, 37.9524679],
    [41.0904411, 37.9515021],
    [41.0919632, 37.9536222],
    [41.0883733, 37.9547274],
    [41.0888553, 37.9554333],
    [41.0909613, 37.9562594],
    [41.0908286, 37.9570499],
    [41.0920093, 37.9579163],
    [41.0908546, 37.9586506],
    [41.0918018, 37.9596426],
    [41.0907197, 37.9616259],
    [41.0880808, 37.9617278],
    [41.087918, 37.9628296],
    [41.0900634, 37.9631674],
    [41.0899224, 37.9650105],
    [41.0875803, 37.9664251],
    [41.0864461, 37.9662334],
    [41.0825037, 37.9678575],
    [41.0825387, 37.9688388],
    [41.0845252, 37.968761],
    [41.0850829, 37.9693177],
    [41.0839128, 37.9722798],
    [41.0826404, 37.9731613],
    [41.0848947, 37.9766446],
    [41.0847183, 37.9800233],
    [41.1004339, 37.9761586],
    [41.10439, 37.9760129],
    [41.1134606, 37.9756826],
    [41.122595, 37.9751324],
    [41.1230718, 37.9772548],
    [41.1267794, 37.9801772],
    [41.1296095, 37.9813277],
    [41.1352284, 37.9850341],
    [41.1374182, 37.9858946],
    [41.1439192, 37.983938],
    [41.1469683, 37.9849507],
    [41.1497264, 37.9872819],
    [41.1537583, 37.9898193],
    [41.1550434, 37.9913682],
    [41.1543627, 37.9941151],
    [41.1538075, 37.996461],
    [41.1538075, 37.997798],
    [41.149663, 37.9979981],
    [41.14858, 38.0000745],
    [41.1468007, 38.0020323],
    [41.1481673, 38.0066506],
    [41.1509485, 38.0097428],
    [41.1528445, 38.016008],
    [41.1566963, 38.0182115],
    [41.1625171, 38.0185405],
    [41.1639982, 38.0203882],
    [41.1623108, 38.0236249],
    [41.1647686, 38.0283929],
    [41.1632279, 38.0303578],
    [41.1646952, 38.0322938],
    [41.1678867, 38.033045],
    [41.1695641, 38.0342016],
    [41.1733142, 38.0356122],
    [41.1724298, 38.0463838],
    [41.178926, 38.0517264],
    [41.1767747, 38.0633721],
    [41.1823364, 38.0691361],
    [41.1857083, 38.0700013],
    [41.1889776, 38.0692195],
    [41.1918927, 38.0671502],
    [41.1964054, 38.0687708],
    [41.2088225, 38.0816432],
    [41.2058953, 38.0855574],
    [41.2065161, 38.0902992],
    [41.2053064, 38.106098],
    [41.208685, 38.1237552],
    [41.2137306, 38.1265629],
    [41.2139654, 38.1287842],
    [41.2103176, 38.1352655],
    [41.2120342, 38.1395523],
    [41.216197, 38.1416449],
    [41.2159961, 38.1463638],
    [41.2142615, 38.150222],
    [41.2172085, 38.1541522],
    [41.2208324, 38.1619342],
    [41.2216564, 38.1663812],
    [41.2219315, 38.1705472],
    [41.2191615, 38.1849902],
    [41.2194364, 38.1891552],
    [41.2261443, 38.1967453],
    [41.220514, 38.2025174],
    [41.2185939, 38.2077251],
    [41.2072122, 38.2118728],
    [41.2023408, 38.2327892],
    [41.1902015, 38.2403934],
    [41.175092, 38.2440861],
    [41.1783277, 38.2656351],
    [41.1836533, 38.275856],
    [41.1834536, 38.2842325],
    [41.187239, 38.2891364],
    [41.1838468, 38.2951737],
    [41.1818255, 38.2959397],
    [41.1815535, 38.3033851],
    [41.1810377, 38.304209],
    [41.1792124, 38.3049223],
    [41.1774447, 38.3084465],
    [41.1782569, 38.3115206],
    [41.1758202, 38.3129827],
    [41.1748169, 38.3141823],
    [41.1756203, 38.3150035],
    [41.1778269, 38.3146321],
    [41.1833213, 38.3129827],
    [41.1858057, 38.3155318],
    [41.1887679, 38.316244],
    [41.1934864, 38.3164229],
    [41.1971291, 38.3193269],
    [41.1993268, 38.3247154],
    [41.2009077, 38.3277499],
    [41.2078264, 38.3404089],
    [41.2098545, 38.3419345],
    [41.2115349, 38.3452725],
    [41.2152466, 38.3497823],
    [41.2166831, 38.3536792],
    [41.2153349, 38.356014],
    [41.2163989, 38.3579145],
    [41.2171729, 38.3597054],
    [41.217206, 38.3622319],
    [41.2190841, 38.3638027],
    [41.2315005, 38.3671258],
    [41.2305186, 38.3710983],
    [41.2323447, 38.3721231],
    [41.2375659, 38.3706996],
    [41.239573, 38.3715013],
    [41.2402292, 38.3735143],
    [41.238352, 38.37636],
    [41.2354231, 38.3791331],
    [41.2323278, 38.3858797],
    [41.2318975, 38.3891401],
    [41.2312484, 38.3904129],
    [41.2324712, 38.3916883],
    [41.2346227, 38.3954731],
    [41.2352884, 38.3983834],
    [41.2361901, 38.3982328],
    [41.2371571, 38.3980172],
    [41.2378567, 38.3981841],
    [41.2381435, 38.3990331],
    [41.2378202, 38.4004333],
    [41.2378381, 38.4010588],
    [41.2388187, 38.4025957],
    [41.2385905, 38.4036619],
    [41.2386727, 38.4041698],
    [41.2395796, 38.4053069],
    [41.2398502, 38.4058594],
    [41.2397752, 38.4068051],
    [41.2392418, 38.4082344],
    [41.2393129, 38.408986],
    [41.2395705, 38.4095778],
    [41.2400501, 38.4101379],
    [41.2409694, 38.4104628],
    [41.2427509, 38.4105842],
    [41.2442484, 38.4109316],
    [41.2450163, 38.4115204],
    [41.2460132, 38.4120409],
    [41.2468209, 38.4123169],
    [41.2486362, 38.4127205],
    [41.2494842, 38.4130878],
    [41.2500054, 38.4135396],
    [41.2500972, 38.4144027],
    [41.2498557, 38.414887],
    [41.2497935, 38.4150117],
    [41.2498504, 38.4154017],
    [41.2504437, 38.4157503],
    [41.2519572, 38.4159156],
    [41.2535021, 38.4159253],
    [41.2548027, 38.4154872],
    [41.2554784, 38.4154524],
    [41.2560931, 38.4158728],
    [41.2565737, 38.4167106],
    [41.2570407, 38.4171707],
    [41.258079, 38.4171394],
    [41.258828, 38.4166377],
    [41.2596627, 38.4166467],
    [41.260367, 38.4167452],
    [41.2622548, 38.4160441],
    [41.2630122, 38.4159002],
    [41.2639092, 38.4161493],
    [41.2643135, 38.4165541],
    [41.2646915, 38.4175189],
    [41.2652067, 38.4179129],
    [41.2653603, 38.4181622],
    [41.2654154, 38.4185077],
    [41.2650216, 38.4193584],
    [41.2647861, 38.4202715],
    [41.2649506, 38.4211936],
    [41.2654188, 38.4216252],
    [41.2661391, 38.4215856],
    [41.2664771, 38.4216946],
    [41.26656, 38.4219471],
    [41.2661891, 38.4223556],
    [41.2660722, 38.4227424],
    [41.2660722, 38.4235178],
    [41.2658167, 38.4241566],
    [41.2658557, 38.4246562],
    [41.2660613, 38.4249567],
    [41.2667667, 38.4253379],
    [41.2673233, 38.4260555],
    [41.2673921, 38.4266392],
    [41.2670977, 38.4276262],
    [41.2665949, 38.4282072],
    [41.2667742, 38.4286612],
    [41.2672897, 38.4291536],
    [41.2685186, 38.4300288],
    [41.2693059, 38.43014],
    [41.2699918, 38.4300646],
    [41.2707278, 38.4302218],
    [41.2710125, 38.4305936],
    [41.2708356, 38.4315546],
    [41.2707503, 38.4327759],
    [41.2707503, 38.4339005],
    [41.2706755, 38.4342327],
    [41.2707369, 38.434449],
    [41.2713399, 38.4348355],
    [41.2727302, 38.4346014],
    [41.2732161, 38.4340516],
    [41.273013, 38.4330844],
    [41.2732734, 38.4325435],
    [41.273753, 38.432159],
    [41.2744193, 38.4320793],
    [41.2760067, 38.4321846],
    [41.2766507, 38.431992],
    [41.2770971, 38.4321747],
    [41.2772375, 38.4326147],
    [41.2776319, 38.432945],
    [41.2784617, 38.4330791],
    [41.278927, 38.4329313],
    [41.2805746, 38.4327828],
    [41.2812495, 38.4329414],
    [41.2819104, 38.4335483],
    [41.2820085, 38.4341635],
    [41.2816457, 38.4344315],
    [41.2799704, 38.4347479],
    [41.2791622, 38.434833],
    [41.2783391, 38.4349421],
    [41.2778643, 38.4353034],
    [41.2777801, 38.4357431],
    [41.2783666, 38.4364705],
    [41.2783666, 38.4366076],
    [41.2774604, 38.4369079],
    [41.2771766, 38.437208],
    [41.2772181, 38.4377276],
    [41.2776946, 38.4380905],
    [41.2787242, 38.4385472],
    [41.2798236, 38.4387905],
    [41.2805804, 38.4394015],
    [41.2816575, 38.4409764],
    [41.2818433, 38.4415586],
    [41.2817401, 38.4422682],
    [41.2811478, 38.4428342],
    [41.2808478, 38.4432734],
    [41.2809093, 38.4436824],
    [41.2816436, 38.4438151],
    [41.2827926, 38.4435248],
    [41.2838754, 38.4433681],
    [41.2851798, 38.4436614],
    [41.2860768, 38.4435911],
    [41.2865116, 38.4433569],
    [41.2868106, 38.4428682],
    [41.2869812, 38.4417902],
    [41.2872694, 38.4411129],
    [41.2882739, 38.4405586],
    [41.2894432, 38.4406402],
    [41.290317, 38.4409014],
    [41.2908469, 38.4415372],
    [41.2907754, 38.4422279],
    [41.2911111, 38.4430166],
    [41.2909113, 38.4435602],
    [41.2904256, 38.4435868],
    [41.2898703, 38.4440216],
    [41.2895753, 38.4446728],
    [41.2898713, 38.4449657],
    [41.2910236, 38.4450638],
    [41.2924677, 38.4453604],
    [41.2931592, 38.4458665],
    [41.2932562, 38.4463644],
    [41.2926908, 38.4470241],
    [41.2920084, 38.4475982],
    [41.2919458, 38.448448],
    [41.2927425, 38.4492796],
    [41.2935843, 38.4495563],
    [41.2958209, 38.4493045],
    [41.2968027, 38.4487446],
    [41.2969832, 38.4487942],
    [41.2976976, 38.449785],
    [41.2983137, 38.4500736],
    [41.2994816, 38.4500269],
    [41.3000437, 38.4501716],
    [41.3003759, 38.4505736],
    [41.2999966, 38.45194],
    [41.3018754, 38.4529703],
    [41.3025293, 38.453106],
    [41.303425, 38.4527053],
    [41.3035014, 38.4521617],
    [41.303789, 38.4519462],
    [41.3043007, 38.4520652],
    [41.3048898, 38.452491],
    [41.3055986, 38.4524185],
    [41.3072043, 38.4512717],
    [41.3082919, 38.4509015],
    [41.3093109, 38.4515662],
    [41.3100493, 38.4517196],
    [41.3115405, 38.4518377],
    [41.3143477, 38.4518286],
    [41.3154232, 38.452027],
    [41.317196, 38.4530745],
    [41.3181177, 38.4541084],
    [41.319449, 38.4556213],
    [41.3199452, 38.4571608],
    [41.3207925, 38.4579624],
    [41.3213119, 38.4590887],
    [41.320834, 38.4610209],
    [41.3211662, 38.4616272],
    [41.3218544, 38.4620035],
    [41.3240132, 38.4624404],
    [41.3249167, 38.4630043],
    [41.3265318, 38.4630631],
    [41.3279226, 38.4639722],
    [41.329461, 38.4643854],
    [41.3311427, 38.4651503],
    [41.3329681, 38.4655151],
    [41.3337387, 38.4658628],
    [41.335751, 38.465206],
    [41.3371694, 38.4653325],
    [41.3379374, 38.4653756],
    [41.3394137, 38.4636361],
    [41.3419633, 38.4627973],
    [41.3459896, 38.4594309],
    [41.3504111, 38.4589362],
    [41.3513829, 38.4606103],
    [41.3558044, 38.4615614],
    [41.3563389, 38.4633115],
    [41.3544925, 38.4669637],
    [41.3591275, 38.4762392],
    [41.3617741, 38.4762243],
    [41.3634025, 38.4772737],
    [41.3636614, 38.4780866],
    [41.3654868, 38.4794373],
    [41.3654618, 38.481062],
    [41.3665812, 38.4832901],
    [41.3650664, 38.4836117],
    [41.3590651, 38.4894445],
    [41.3553143, 38.4919888],
    [41.33616, 38.4958248],
    [41.3323093, 38.5036526],
    [41.3282084, 38.5065487],
    [41.3215569, 38.5094055],
    [41.3107046, 38.5106969],
    [41.3046032, 38.5121057],
    [41.3010025, 38.511323],
    [41.2959514, 38.5122231],
    [41.2958013, 38.5138275],
    [41.2973517, 38.5151579],
    [41.3013525, 38.516371],
    [41.3056035, 38.5169188],
    [41.3073038, 38.5184057],
    [41.3048533, 38.5277178],
    [41.3106046, 38.5346033],
    [41.3109546, 38.536481],
    [41.3091041, 38.5366766],
    [41.3064537, 38.535151],
    [41.3023027, 38.5350336],
    [41.2928507, 38.5396496],
    [41.2893499, 38.5442653],
    [41.2905002, 38.5461819],
    [41.2895999, 38.5492327],
    [41.285399, 38.5540825],
    [41.2851985, 38.5567193],
    [41.2868413, 38.5576177],
    [41.2861477, 38.5616965],
    [41.2835803, 38.5643769],
    [41.2835282, 38.5658766],
    [41.2828473, 38.5681797],
    [41.2832576, 38.5683497],
    [41.282939, 38.569536],
    [41.2844932, 38.5708054],
    [41.2870493, 38.5756286],
    [41.2873495, 38.5782481],
    [41.2806979, 38.586497],
    [41.2793978, 38.5898197],
    [41.2734965, 38.5958002],
    [41.2732964, 38.5992007],
    [41.2744966, 38.6025228],
    [41.2735464, 38.6091276],
    [41.2702457, 38.6137779],
    [41.2695455, 38.6163179],
    [41.2720461, 38.6187796],
    [41.271796, 38.6237028],
    [41.2744162, 38.6260071],
    [41.2740662, 38.6298945],
    [41.2724832, 38.6314031],
    [41.2722796, 38.6316712],
    [41.2720205, 38.6317673],
    [41.2719865, 38.6318394],
    [41.2717547, 38.6318394],
    [41.2716375, 38.6320925],
    [41.2715479, 38.6321317],
    [41.2714016, 38.6321005],
    [41.2710253, 38.6323311],
    [41.2708593, 38.6326164],
    [41.2707578, 38.6327881],
    [41.2704917, 38.6329352],
    [41.2704489, 38.6330913],
    [41.2702837, 38.6333453],
    [41.2695251, 38.6334349],
    [41.2694551, 38.6336535],
    [41.2692712, 38.6337622],
    [41.268965, 38.6337979],
    [41.2688405, 38.6339156],
    [41.2686218, 38.6339709],
    [41.2684807, 38.6341428],
    [41.2683026, 38.6341222],
    [41.2682308, 38.6342413],
    [41.2678696, 38.6344779],
    [41.2675929, 38.6345781],
    [41.2675006, 38.6347312],
    [41.2675997, 38.6348205],
    [41.2675997, 38.6348395],
    [41.2674019, 38.6349699],
    [41.2673906, 38.6351428],
    [41.2672932, 38.6351705],
    [41.2669205, 38.6354664],
    [41.2666599, 38.6354901],
    [41.2664569, 38.6356079],
    [41.2662697, 38.6355724],
    [41.2659949, 38.6356355],
    [41.2658604, 38.6358142],
    [41.26571, 38.635901],
    [41.2656124, 38.6360963],
    [41.2653588, 38.6362074],
    [41.2652996, 38.6363083],
    [41.2651302, 38.6363242],
    [41.2650838, 38.6364752],
    [41.2648744, 38.6366247],
    [41.264728, 38.636844],
    [41.2644636, 38.6369816],
    [41.2643334, 38.6371985],
    [41.2646414, 38.6375228],
    [41.2646825, 38.6377273],
    [41.2645081, 38.6379725],
    [41.2644358, 38.6381178],
    [41.2639609, 38.6382231],
    [41.2637071, 38.6383585],
    [41.2633286, 38.6384235],
    [41.263366, 38.6387335],
    [41.2633301, 38.6388178],
    [41.26313, 38.6389694],
    [41.2629289, 38.6391681],
    [41.262481, 38.6393181],
    [41.2622784, 38.639189],
    [41.2620119, 38.6391011],
    [41.2617641, 38.638803],
    [41.2613383, 38.6388394],
    [41.2609768, 38.6386597],
    [41.2605597, 38.6388226],
    [41.2606431, 38.638988],
    [41.2602392, 38.6391739],
    [41.2599863, 38.6394728],
    [41.2598774, 38.6398083],
    [41.2596133, 38.6399829],
    [41.259673, 38.6402101],
    [41.259673, 38.640391],
    [41.2598622, 38.6406922],
    [41.2601935, 38.6408838],
    [41.2602869, 38.6411124],
    [41.2606466, 38.6413314],
    [41.2606571, 38.6414139],
    [41.2604963, 38.6415674],
    [41.260463, 38.6417686],
    [41.260674, 38.6420184],
    [41.2608436, 38.6422746],
    [41.2607455, 38.6426767],
    [41.2608713, 38.6430157],
    [41.2608421, 38.6431827],
    [41.2605296, 38.6433646],
    [41.2604302, 38.6436394],
    [41.2605972, 38.6439605],
    [41.2605788, 38.6442485],
    [41.2606609, 38.6444902],
    [41.2606672, 38.6448069],
    [41.2606078, 38.6450719],
    [41.260653, 38.6455171],
    [41.2608189, 38.6458483],
    [41.2612628, 38.6461778],
    [41.2613302, 38.6464352],
    [41.2612392, 38.646783],
    [41.2612913, 38.6469359],
    [41.2618485, 38.6468867],
    [41.2621449, 38.647057],
    [41.2624905, 38.6470768],
    [41.2626226, 38.6472773],
    [41.2625918, 38.6474339],
    [41.2624554, 38.6476896],
    [41.2624901, 38.648063],
    [41.2622803, 38.6482661],
    [41.2621187, 38.6485186],
    [41.2623118, 38.6487963],
    [41.2627088, 38.6490342],
    [41.2629569, 38.6491506],
    [41.2629993, 38.6492499],
    [41.262692, 38.6495565],
    [41.2626235, 38.6498243],
    [41.2619816, 38.6501451],
    [41.2616286, 38.6503206],
    [41.2614975, 38.6502523],
    [41.2613506, 38.6499582],
    [41.2608936, 38.6498609],
    [41.2605506, 38.6500596],
    [41.2606557, 38.6502985],
    [41.260558, 38.6504619],
    [41.2603345, 38.6504947],
    [41.26017, 38.6504165],
    [41.2599016, 38.6499832],
    [41.2594658, 38.6498239],
    [41.2593103, 38.6497453],
    [41.2590974, 38.6496961],
    [41.2590368, 38.6495278],
    [41.2592106, 38.649342],
    [41.2591152, 38.6491928],
    [41.258829, 38.6489843],
    [41.258157, 38.6487973],
    [41.2573166, 38.6487687],
    [41.2557183, 38.6491636],
    [41.2550708, 38.6495992],
    [41.254462, 38.6499184],
    [41.2538323, 38.6500414],
    [41.2533672, 38.6497387],
    [41.2529735, 38.6496599],
    [41.2514747, 38.650536],
    [41.2511, 38.6505996],
    [41.2505928, 38.6504562],
    [41.2499591, 38.6504418],
    [41.2484729, 38.6507232],
    [41.2477919, 38.6510521],
    [41.2470884, 38.6512986],
    [41.2464041, 38.6519302],
    [41.2459529, 38.6520868],
    [41.2453139, 38.6520733],
    [41.2444501, 38.6519924],
    [41.2440137, 38.651685],
    [41.2434659, 38.6512786],
    [41.2426134, 38.6511628],
    [41.2420218, 38.6512764],
    [41.2417867, 38.6517638],
    [41.2413987, 38.6519378],
    [41.2409775, 38.6520201],
    [41.2404849, 38.6521644],
    [41.2401166, 38.6521905],
    [41.239665, 38.652082],
    [41.2387969, 38.651925],
    [41.2382105, 38.6521127],
    [41.2378451, 38.6526833],
    [41.2374999, 38.6530944],
    [41.2370066, 38.6533402],
    [41.235563, 38.6536135],
    [41.2350127, 38.6535657],
    [41.2340759, 38.6535373],
    [41.2335719, 38.6537492],
    [41.2333185, 38.6544065],
    [41.2329479, 38.6547796],
    [41.2326609, 38.6547736],
    [41.2321961, 38.6546898],
    [41.2316384, 38.6547105],
    [41.2310756, 38.6545787],
    [41.2280054, 38.6544228],
    [41.2254979, 38.656965],
    [41.2234095, 38.6572126],
    [41.2221693, 38.657068],
    [41.2209522, 38.6558771],
    [41.2204536, 38.6559344],
    [41.2197644, 38.656484],
    [41.2181514, 38.6566329],
    [41.2158639, 38.6568734],
    [41.2150134, 38.6567359],
    [41.2133125, 38.6560489],
    [41.2123447, 38.6560832],
    [41.2114649, 38.6567359],
    [41.2114795, 38.6576864],
    [41.210937, 38.6582131],
    [41.2103504, 38.6584306],
    [41.2093845, 38.6583176],
    [41.2085981, 38.6579383],
    [41.2080043, 38.6571367],
    [41.2071244, 38.6565413],
    [41.2047929, 38.6574802],
    [41.2029747, 38.6577322],
    [41.2002326, 38.6569993],
    [41.1982383, 38.6565871],
    [41.198033, 38.6560145],
    [41.1972575, 38.6554123],
    [41.1952469, 38.6549382],
    [41.1946018, 38.6547206],
    [41.1930892, 38.653269],
    [41.1721771, 38.6562884],
    [41.1620157, 38.6564101],
    [41.1395461, 38.6620034],
    [41.1192728, 38.6632384],
    [41.1163652, 38.6725316],
    [41.1044966, 38.6824864],
    [41.1118309, 38.7014153],
    [41.1204681, 38.7018816],
    [41.1293166, 38.7033091],
    [41.1354989, 38.7078061],
    [41.1387913, 38.7114168],
    [41.1391815, 38.7145841],
    [41.1282377, 38.7136672],
    [41.1093848, 38.7100311],
    [41.0870519, 38.6994421],
    [41.0584395, 38.690391],
    [41.0314464, 38.6843038],
    [41.0237129, 38.6804916],
    [41.0147094, 38.6736501],
    [41.0109328, 38.6741862],
    [41.0091304, 38.6751243],
    [41.0051822, 38.6753923],
    [41.0018348, 38.6781396],
    [40.9979724, 38.6782066],
    [40.9932583, 38.6796661],
    [40.9898185, 38.6759284],
    [40.9847545, 38.6740522],
    [40.9771155, 38.6723769],
    [40.9713649, 38.6745212],
    [40.9670734, 38.6741192],
    [40.9571126, 38.6701456],
    [40.9506797, 38.667485],
    [40.9444999, 38.667552],
    [40.9423541, 38.6664797],
    [40.9416675, 38.6632629],
    [40.9395217, 38.6591746],
    [40.9417533, 38.6567617],
    [40.9421825, 38.654952],
    [40.9437274, 38.6516676],
    [40.9427833, 38.6493886],
    [40.9450149, 38.6459028],
    [40.9441566, 38.6436235],
    [40.946474, 38.637791],
    [40.9410667, 38.6312876],
    [40.9346106, 38.6287345],
    [40.9210681, 38.6267281],
    [40.9134292, 38.6170047],
    [40.9014129, 38.6178094],
    [40.8946424, 38.6096988],
    [40.8869933, 38.6062068],
    [40.8780818, 38.5990255],
    [40.8473453, 38.5764445],
    [40.8363415, 38.5768466],
    [40.8252811, 38.57689],
    [40.8147054, 38.5639084],
    [40.8132409, 38.561555],
    [40.8115119, 38.56114],
    [40.8088677, 38.5610684],
    [40.8086181, 38.5617553],
    [40.8074563, 38.5628536],
    [40.8071933, 38.5654404],
    [40.8077783, 38.5667774],
    [40.8067434, 38.5679034],
    [40.8054448, 38.5681042],
    [40.8061006, 38.5704969],
    [40.8080406, 38.571736],
    [40.8090516, 38.5725905],
    [40.8090789, 38.5734022],
    [40.808232, 38.5742567],
    [40.806729, 38.5747908],
    [40.8056361, 38.5744276],
    [40.8039148, 38.574748],
    [40.8018107, 38.5744276],
    [40.8003694, 38.5740144],
    [40.7991057, 38.5744149],
    [40.7991057, 38.5756699],
    [40.7985933, 38.5760972],
    [40.7976029, 38.5756966],
    [40.7956219, 38.5761506],
    [40.7948705, 38.5756966],
    [40.7942557, 38.5743348],
    [40.7931286, 38.5741212],
    [40.7914892, 38.5746286],
    [40.79125, 38.5756432],
    [40.7923089, 38.5778327],
    [40.7923089, 38.5804227],
    [40.7937786, 38.5819278],
    [40.7841602, 38.5926033],
    [40.7672685, 38.6000713],
    [40.7441555, 38.6011042],
    [40.7175288, 38.59655],
    [40.7045702, 38.5974431],
    [40.6720445, 38.6012963],
    [40.6589129, 38.5963836],
    [40.6463325, 38.5780993],
    [40.633144, 38.5707328],
    [40.624166, 38.5690352],
    [40.6191582, 38.5633583],
    [40.6033615, 38.5633016],
    [40.5855513, 38.5803969],
    [40.5740015, 38.5832946],
    [40.5609594, 38.5827356],
    [40.5596486, 38.5841228],
    [40.5558045, 38.5856965],
    [40.551448, 38.5895709],
    [40.5410197, 38.5925798],
    [40.5128511, 38.6065425],
    [40.4984054, 38.614197],
    [40.4842907, 38.6194695],
    [40.4730503, 38.6205199],
    [40.4574375, 38.6184368],
    [40.4476738, 38.6138195],
    [40.4381508, 38.5993236],
    [40.4447921, 38.5976544],
    [40.4453022, 38.5944519],
    [40.4403865, 38.5934068],
    [40.4379233, 38.5881263],
    [40.4433296, 38.5811644],
    [40.400589, 38.5649687],
    [40.3659921, 38.5449394],
    [40.3543737, 38.5325035],
    [40.3580427, 38.5100178],
    [40.3593744, 38.5057574],
    [40.3664183, 38.5008431],
    [40.3615246, 38.4950009],
    [40.3606657, 38.4949577],
    [40.3592229, 38.4945244],
    [40.3584475, 38.4942468],
    [40.357044, 38.4930768],
    [40.3567346, 38.4923503],
    [40.3567346, 38.4916804],
    [40.3562967, 38.4909607],
    [40.3559078, 38.4904804],
    [40.3555894, 38.490087],
    [40.3549105, 38.4891945],
    [40.3535934, 38.4887309],
    [40.3524343, 38.4887241],
    [40.3502646, 38.4889364],
    [40.3490739, 38.4888712],
    [40.3464198, 38.488227],
    [40.3446862, 38.4872778],
    [40.3441849, 38.487247],
    [40.3423684, 38.4882335],
    [40.341252, 38.4892081],
    [40.340635, 38.4898971],
    [40.3396193, 38.4900943],
    [40.3386304, 38.4896946],
    [40.3378063, 38.4891645],
    [40.3372887, 38.4879425],
    [40.3373902, 38.4871016],
    [40.3368568, 38.4854865],
    [40.3361446, 38.4851317],
    [40.3340287, 38.4849823],
    [40.3330748, 38.485169],
    [40.3312104, 38.4861778],
    [40.3299734, 38.4870598],
    [40.3285252, 38.4877504],
    [40.3273101, 38.4878709],
    [40.3264639, 38.4875085],
    [40.3257356, 38.4866828],
    [40.3254625, 38.4864531],
    [40.3252802, 38.4860048],
    [40.324816, 38.4857574],
    [40.3241228, 38.4858261],
    [40.3223174, 38.4855251],
    [40.3217295, 38.4853317],
    [40.3196678, 38.4850594],
    [40.3191329, 38.4848741],
    [40.3175082, 38.4850803],
    [40.3171445, 38.4852628],
    [40.3168164, 38.4855635],
    [40.3158851, 38.4856025],
    [40.3148814, 38.4860767],
    [40.3143019, 38.4862968],
    [40.3127632, 38.487102],
    [40.3118146, 38.48751],
    [40.3112641, 38.4878879],
    [40.3105692, 38.4881665],
    [40.3093299, 38.4889771],
    [40.308306, 38.4893873],
    [40.3074463, 38.4891285],
    [40.3057253, 38.489216],
    [40.3049437, 38.4892496],
    [40.3041933, 38.4893882],
    [40.3040214, 38.4893828],
    [40.303879, 38.4893221],
    [40.3037127, 38.4886513],
    [40.3033758, 38.4882321],
    [40.3028504, 38.4872305],
    [40.3026114, 38.4869031],
    [40.3022994, 38.4863826],
    [40.3021308, 38.4843165],
    [40.3020296, 38.4836697],
    [40.3020453, 38.483362],
    [40.3024456, 38.4827088],
    [40.3024366, 38.4820693],
    [40.3021734, 38.4815174],
    [40.3016537, 38.4813933],
    [40.3006289, 38.4803759],
    [40.3002007, 38.4799955],
    [40.300003, 38.4795112],
    [40.2987719, 38.4786773],
    [40.2979194, 38.4783572],
    [40.2961096, 38.4779269],
    [40.2950667, 38.4773978],
    [40.2945171, 38.4770416],
    [40.2935596, 38.4767006],
    [40.2925575, 38.4763442],
    [40.2916582, 38.4757306],
    [40.290651, 38.4743474],
    [40.2898868, 38.4740035],
    [40.2892833, 38.4737336],
    [40.2875424, 38.4732682],
    [40.2867064, 38.4729075],
    [40.2858194, 38.4727339],
    [40.2844038, 38.4722325],
    [40.2814909, 38.4711156],
    [40.2804027, 38.470855],
    [40.2796712, 38.4707947],
    [40.2788341, 38.4705512],
    [40.2783172, 38.469951],
    [40.2770254, 38.4699657],
    [40.276611, 38.469838],
    [40.2756824, 38.4688992],
    [40.274613, 38.4684159],
    [40.272796, 38.4680018],
    [40.2707271, 38.4674036],
    [40.2683149, 38.4677028],
    [40.2673872, 38.4674543],
    [40.2662221, 38.4668203],
    [40.2657344, 38.4661818],
    [40.2653738, 38.4644985],
    [40.26394, 38.463086],
    [40.2628246, 38.4624031],
    [40.2615671, 38.4617936],
    [40.2606368, 38.4614365],
    [40.259682, 38.4619921],
    [40.2585947, 38.4620853],
    [40.2576582, 38.4614698],
    [40.2562632, 38.4612541],
    [40.2556584, 38.4610873],
    [40.2545296, 38.4609503],
    [40.2537364, 38.4611287],
    [40.2511153, 38.4606287],
    [40.2498329, 38.4603996],
    [40.2481903, 38.4605147],
    [40.2458594, 38.4611187],
    [40.2444801, 38.4611447],
    [40.2433694, 38.4609455],
    [40.2408408, 38.4609455],
    [40.2396175, 38.4608602],
    [40.2390104, 38.4607155],
    [40.2374953, 38.4610884],
    [40.2363692, 38.461317],
    [40.2333424, 38.4611524],
    [40.2323433, 38.4609289],
    [40.2307852, 38.4612804],
    [40.2289327, 38.4626833],
    [40.228747, 38.4635226],
    [40.2280443, 38.4639602],
    [40.227211, 38.464238],
    [40.2261637, 38.4644047],
    [40.2255234, 38.4646586],
    [40.2244, 38.4646065],
    [40.2238394, 38.4646388],
    [40.2233851, 38.464507],
    [40.2217235, 38.464507],
    [40.2209468, 38.4642625],
    [40.2204853, 38.4638061],
    [40.2199473, 38.4631084],
    [40.2193962, 38.4622993],
    [40.2183438, 38.4615783],
    [40.2167911, 38.4612522],
    [40.2151735, 38.4611444],
    [40.2143997, 38.4612339],
    [40.2129597, 38.4617298],
    [40.2119879, 38.4624702],
    [40.2109351, 38.4644179],
    [40.2096952, 38.4654082],
    [40.2057792, 38.4664258],
    [40.2051906, 38.4663823],
    [40.2045908, 38.4661855],
    [40.2028734, 38.4651654],
    [40.2017746, 38.4648668],
    [40.1989649, 38.4647139],
    [40.1976485, 38.4643768],
    [40.1960729, 38.4633421],
    [40.1952505, 38.4630859],
    [40.1916498, 38.4629526],
    [40.1905476, 38.4627999],
    [40.1890993, 38.4627264],
    [40.1878482, 38.4630336],
    [40.1854275, 38.4623066],
    [40.1837611, 38.4610018],
    [40.1832397, 38.4590772],
    [40.1838475, 38.4577864],
    [40.1842558, 38.4570244],
    [40.1841504, 38.4561026],
    [40.1837798, 38.4553699],
    [40.180628, 38.4566781],
    [40.1624298, 38.4598645],
    [40.1482442, 38.4585811],
    [40.1352854, 38.4557569],
    [40.1188034, 38.4620391],
    [40.0809575, 38.4666183],
    [40.052164, 38.4627419],
    [40.0358054, 38.4613738],
    [40.0177614, 38.4609125],
    [39.9978047, 38.4660177],
    [39.993673, 38.460322],
    [39.994236, 38.459995],
    [39.994236, 38.458988],
    [39.993399, 38.457779],
    [39.992949, 38.456318],
    [39.993078, 38.454858],
    [39.99192, 38.453548],
    [39.989412, 38.452339],
    [39.988158, 38.451559],
    [39.987772, 38.450627],
    [39.9879, 38.449846],
    [39.987482, 38.448536],
    [39.986582, 38.448184],
    [39.986067, 38.447302],
    [39.986035, 38.446572],
    [39.98491, 38.445615],
    [39.983816, 38.444859],
    [39.981855, 38.444884],
    [39.979378, 38.444708],
    [39.975391, 38.442517],
    [39.974297, 38.442618],
    [39.972271, 38.443172],
    [39.971081, 38.443827],
    [39.968895, 38.444708],
    [39.967319, 38.444884],
    [39.966161, 38.444658],
    [39.965132, 38.443927],
    [39.962077, 38.443348],
    [39.960148, 38.443323],
    [39.959537, 38.44365],
    [39.957414, 38.44428],
    [39.956128, 38.444431],
    [39.955195, 38.445464],
    [39.952976, 38.447378],
    [39.952365, 38.448486],
    [39.951593, 38.449065],
    [39.950178, 38.44904],
    [39.947927, 38.448713],
    [39.944969, 38.44904],
    [39.944165, 38.448965],
    [39.943908, 38.448587],
    [39.944004, 38.447856],
    [39.944551, 38.446925],
    [39.944454, 38.446194],
    [39.943972, 38.445842],
    [39.939931, 38.446428],
    [39.938826, 38.445842],
    [39.937926, 38.445816],
    [39.937219, 38.446244],
    [39.936672, 38.446068],
    [39.936093, 38.44564],
    [39.935514, 38.445867],
    [39.934228, 38.446698],
    [39.933167, 38.446824],
    [39.932395, 38.446396],
    [39.932041, 38.445287],
    [39.932041, 38.44423],
    [39.932363, 38.443222],
    [39.932138, 38.442643],
    [39.931655, 38.442466],
    [39.930401, 38.442895],
    [39.929243, 38.443424],
    [39.928246, 38.443701],
    [39.927475, 38.443726],
    [39.927024, 38.443424],
    [39.926156, 38.442164],
    [39.925738, 38.44224],
    [39.925449, 38.442618],
    [39.924741, 38.443071],
    [39.923937, 38.443373],
    [39.922619, 38.443701],
    [39.921686, 38.443675],
    [39.921075, 38.443499],
    [39.91876, 38.443701],
    [39.916974, 38.443593],
    [39.916906, 38.442325],
    [39.917042, 38.441515],
    [39.917858, 38.440407],
    [39.919163, 38.43898],
    [39.920768, 38.43817],
    [39.92153, 38.438042],
    [39.92221, 38.437808],
    [39.922509, 38.437616],
    [39.922618, 38.437147],
    [39.9224, 38.436593],
    [39.921938, 38.436039],
    [39.921639, 38.435485],
    [39.921612, 38.434995],
    [39.92202, 38.434633],
    [39.922672, 38.434356],
    [39.923271, 38.433887],
    [39.924495, 38.432375],
    [39.92493, 38.432161],
    [39.925665, 38.43197],
    [39.927161, 38.431799],
    [39.928031, 38.43197],
    [39.928738, 38.432289],
    [39.929282, 38.432353],
    [39.93018, 38.432161],
    [39.931649, 38.431458],
    [39.933118, 38.431011],
    [39.933934, 38.431075],
    [39.934124, 38.431416],
    [39.933852, 38.43246],
    [39.933934, 38.432929],
    [39.934152, 38.433674],
    [39.93456, 38.434271],
    [39.935131, 38.434612],
    [39.93592, 38.434655],
    [39.936382, 38.434441],
    [39.937117, 38.433504],
    [39.937497, 38.43214],
    [39.937253, 38.431139],
    [39.936872, 38.430585],
    [39.935811, 38.429903],
    [39.935376, 38.429349],
    [39.935104, 38.428326],
    [39.935131, 38.427921],
    [39.935593, 38.427516],
    [39.9366, 38.427218],
    [39.936981, 38.426493],
    [39.937552, 38.42498],
    [39.938613, 38.422615],
    [39.941877, 38.420697],
    [39.944815, 38.419588],
    [39.947535, 38.419034],
    [39.948025, 38.418097],
    [39.94748, 38.415411],
    [39.947426, 38.412086],
    [39.945508, 38.410456],
    [39.94355, 38.409432],
    [39.943278, 38.408793],
    [39.942435, 38.40826],
    [39.941972, 38.406853],
    [39.941646, 38.406278],
    [39.941048, 38.405734],
    [39.940938, 38.405127],
    [39.939306, 38.403219],
    [39.938637, 38.402973],
    [39.938028, 38.403038],
    [39.936926, 38.402558],
    [39.936552, 38.401807],
    [39.936702, 38.401497],
    [39.936634, 38.401274],
    [39.935124, 38.400325],
    [39.93439, 38.399717],
    [39.932867, 38.398976],
    [39.932805, 38.398614],
    [39.932479, 38.398593],
    [39.931839, 38.398252],
    [39.931119, 38.398273],
    [39.930738, 38.398124],
    [39.930017, 38.39806],
    [39.929554, 38.398156],
    [39.928861, 38.398017],
    [39.928426, 38.398071],
    [39.927079, 38.3979],
    [39.926222, 38.398294],
    [39.925651, 38.398337],
    [39.925202, 38.398113],
    [39.924563, 38.396461],
    [39.924305, 38.396205],
    [39.924345, 38.396003],
    [39.924808, 38.395235],
    [39.924726, 38.394713],
    [39.925447, 38.393988],
    [39.925569, 38.393508],
    [39.925841, 38.393221],
    [39.925801, 38.392869],
    [39.925991, 38.392496],
    [39.925882, 38.391835],
    [39.926263, 38.391035],
    [39.926317, 38.390705],
    [39.926222, 38.390438],
    [39.926018, 38.390257],
    [39.926209, 38.389809],
    [39.926277, 38.389244],
    [39.925964, 38.388797],
    [39.925964, 38.388381],
    [39.925651, 38.38821],
    [39.925297, 38.388146],
    [39.925025, 38.387741],
    [39.925066, 38.38691],
    [39.925393, 38.386526],
    [39.925338, 38.385971],
    [39.924985, 38.385524],
    [39.923897, 38.385033],
    [39.923407, 38.384692],
    [39.923189, 38.383882],
    [39.92308, 38.383029],
    [39.923135, 38.382453],
    [39.922836, 38.382197],
    [39.921829, 38.381728],
    [39.921448, 38.381366],
    [39.920442, 38.379894],
    [39.920061, 38.379745],
    [39.919381, 38.379766],
    [39.917694, 38.380193],
    [39.916389, 38.380662],
    [39.914947, 38.380875],
    [39.914621, 38.381302],
    [39.91375, 38.383072],
    [39.912417, 38.383114],
    [39.911574, 38.383434],
    [39.910486, 38.384244],
    [39.906732, 38.385907],
    [39.905209, 38.386483],
    [39.903903, 38.386888],
    [39.902652, 38.386931],
    [39.902081, 38.387528],
    [39.901373, 38.388573],
    [39.901057, 38.389775],
    [39.899452, 38.38922],
    [39.897847, 38.388197],
    [39.896814, 38.38792],
    [39.894746, 38.38809],
    [39.893549, 38.387856],
    [39.892679, 38.387557],
    [39.891727, 38.38664],
    [39.890748, 38.385041],
    [39.89053, 38.384188],
    [39.889877, 38.383165],
    [39.88781, 38.381928],
    [39.887293, 38.38099],
    [39.887211, 38.380158],
    [39.886831, 38.379668],
    [39.886015, 38.379348],
    [39.884845, 38.379327],
    [39.884437, 38.378964],
    [39.883974, 38.377685],
    [39.883294, 38.37696],
    [39.882669, 38.376426],
    [39.880003, 38.375616],
    [39.878663, 38.373862],
    [39.878446, 38.373201],
    [39.878609, 38.372732],
    [39.878554, 38.372433],
    [39.878228, 38.371921],
    [39.876922, 38.370439],
    [39.87646, 38.369789],
    [39.876283, 38.369234],
    [39.875739, 38.368904],
    [39.875212, 38.36872],
    [39.875103, 38.368485],
    [39.875035, 38.367835],
    [39.875158, 38.367163],
    [39.87509, 38.366214],
    [39.874899, 38.365883],
    [39.87445, 38.365606],
    [39.87358, 38.36486],
    [39.873335, 38.364316],
    [39.873213, 38.363036],
    [39.873322, 38.362631],
    [39.873308, 38.362119],
    [39.873417, 38.361863],
    [39.873455, 38.359376],
    [39.8728358, 38.3564646],
    [39.864149, 38.3590898],
    [39.8599004, 38.3674205],
    [39.8507202, 38.3718709],
    [39.8348604, 38.3700785],
    [39.8278451, 38.3739825],
    [39.8245879, 38.3735406],
    [39.8211742, 38.3721901],
    [39.8178545, 38.3737616],
    [39.8117474, 38.3797768],
    [39.810492, 38.380825],
    [39.8045109, 38.3819471],
    [39.7967418, 38.3788209],
    [39.7903379, 38.3757931],
    [39.7863794, 38.3738843],
    [39.7832489, 38.3724459],
    [39.781296, 38.3743729],
    [39.7777042, 38.3750383],
    [39.7758251, 38.3734915],
    [39.7757596, 38.371551],
    [39.7745723, 38.3680896],
    [39.7707828, 38.3669109],
    [39.7694674, 38.3656341],
    [39.7707515, 38.3637432],
    [39.7736954, 38.3621471],
    [39.7721295, 38.3608701],
    [39.7698119, 38.3605017],
    [39.7664295, 38.361926],
    [39.7645191, 38.3635713],
    [39.7610114, 38.3636941],
    [39.7603537, 38.3631048],
    [39.7605729, 38.3615577],
    [39.760479, 38.3595931],
    [39.7613872, 38.3577512],
    [39.7600092, 38.3556146],
    [39.7575977, 38.3561549],
    [39.756157, 38.3582915],
    [39.754873, 38.359151],
    [39.7520856, 38.3594212],
    [39.7510521, 38.3587581],
    [39.7514906, 38.3571618],
    [39.7535889, 38.3550989],
    [39.7538708, 38.3534779],
    [39.7530252, 38.3526675],
    [39.750551, 38.3527411],
    [39.7496707, 38.3534676],
    [39.7484527, 38.3547796],
    [39.7467615, 38.3549761],
    [39.7453974, 38.3546817],
    [39.7448318, 38.3538147],
    [39.7444386, 38.3525143],
    [39.743642, 38.3524943],
    [39.7429232, 38.3528166],
    [39.7416852, 38.3543027],
    [39.7388064, 38.3535095],
    [39.7339835, 38.35392],
    [39.7306106, 38.3508253],
    [39.7286043, 38.3478559],
    [39.7273448, 38.3478938],
    [39.7260177, 38.3485235],
    [39.725065, 38.3483877],
    [39.7237049, 38.3477862],
    [39.721292, 38.3469401],
    [39.7174997, 38.3475076],
    [39.7124388, 38.346878],
    [39.7102976, 38.3454098],
    [39.7070181, 38.3446797],
    [39.7060344, 38.3409421],
    [39.7048532, 38.3400587],
    [39.7012684, 38.339515],
    [39.6988521, 38.3406024],
    [39.7013872, 38.3435539],
    [39.6991792, 38.3448315],
    [39.6973667, 38.3422801],
    [39.6952348, 38.3427815],
    [39.6931679, 38.3455266],
    [39.6923453, 38.3471132],
    [39.6909376, 38.3476226],
    [39.6901415, 38.3478484],
    [39.6891253, 38.347689],
    [39.6882954, 38.3470116],
    [39.6870081, 38.3467991],
    [39.6862798, 38.3475031],
    [39.6861104, 38.348765],
    [39.6864831, 38.3501863],
    [39.6861443, 38.3506644],
    [39.6850264, 38.3506512],
    [39.6845691, 38.3501863],
    [39.6845183, 38.349562],
    [39.6840102, 38.3492963],
    [39.682164, 38.3494291],
    [39.682164, 38.3489908],
    [39.6828246, 38.3487118],
    [39.682723, 38.3481008],
    [39.6815204, 38.3480743],
    [39.6816051, 38.3472108],
    [39.6805381, 38.3460817],
    [39.6811986, 38.3443947],
    [39.678696, 38.3423098],
    [39.678133, 38.3419504],
    [39.6763166, 38.341684],
    [39.6756205, 38.3395537],
    [39.6735203, 38.3381189],
    [39.6702683, 38.337747],
    [39.6697265, 38.3369197],
    [39.6683713, 38.3363653],
    [39.6677159, 38.3355729],
    [39.667411, 38.3337659],
    [39.6664286, 38.3334072],
    [39.6664286, 38.3325834],
    [39.6656657, 38.3318136],
    [39.66631, 38.3304309],
    [39.6655817, 38.3282118],
    [39.6658955, 38.3264699],
    [39.6630191, 38.3241949],
    [39.6588186, 38.3222815],
    [39.6594121, 38.3179036],
    [39.6559818, 38.3178229],
    [39.654876, 38.3177562],
    [39.6454772, 38.3143287],
    [39.6405459, 38.3101589],
    [39.6351663, 38.3106207],
    [39.6197977, 38.3051373],
    [39.6071759, 38.3053126],
    [39.598447, 38.2961593],
    [39.5848653, 38.2916736],
    [39.5793227, 38.2808539],
    [39.5806522, 38.2734842],
    [39.5745807, 38.2671067],
    [39.561305, 38.2511757],
    [39.5512332, 38.2501731],
    [39.5519211, 38.2432225],
    [39.5460626, 38.2428627],
    [39.5394415, 38.2400668],
    [39.5366694, 38.2502845],
    [39.5388503, 38.2537605],
    [39.5352679, 38.2558389],
    [39.5151359, 38.2730233],
    [39.4990708, 38.2648714],
    [39.478372, 38.267211],
    [39.477189, 38.26846],
    [39.476481, 38.268938],
    [39.475792, 38.269637],
    [39.474979, 38.270015],
    [39.474591, 38.270416],
    [39.474453, 38.270491],
    [39.473373, 38.27043],
    [39.473297, 38.270719],
    [39.473166, 38.270783],
    [39.472915, 38.270807],
    [39.472864, 38.271116],
    [39.472658, 38.271343],
    [39.471627, 38.271896],
    [39.471184, 38.271992],
    [39.470881, 38.272157],
    [39.47, 38.272346],
    [39.469888, 38.272422],
    [39.469479, 38.272724],
    [39.469412, 38.272921],
    [39.468949, 38.273344],
    [39.468873, 38.273526],
    [39.468918, 38.273647],
    [39.469086, 38.27384],
    [39.469123, 38.273946],
    [39.469111, 38.274018],
    [39.469051, 38.274086],
    [39.468877, 38.274122],
    [39.467455, 38.274018],
    [39.467405, 38.274229],
    [39.467494, 38.274539],
    [39.46758, 38.274778],
    [39.467471, 38.275001],
    [39.467278, 38.275272],
    [39.466972, 38.27537],
    [39.466634, 38.275604],
    [39.466153, 38.275758],
    [39.465373, 38.276319],
    [39.465178, 38.276563],
    [39.464735, 38.276817],
    [39.463262, 38.277194],
    [39.462767, 38.277479],
    [39.462464, 38.27753],
    [39.462361, 38.277534],
    [39.462289, 38.277581],
    [39.46211, 38.277721],
    [39.462067, 38.277842],
    [39.461622, 38.278033],
    [39.461182, 38.27803],
    [39.46108, 38.278033],
    [39.460606, 38.278065],
    [39.460273, 38.277959],
    [39.459754, 38.277884],
    [39.45912, 38.278038],
    [39.458594, 38.277798],
    [39.458453, 38.277848],
    [39.458224, 38.278142],
    [39.457882, 38.278183],
    [39.458906, 38.278818],
    [39.459077, 38.278881],
    [39.459347, 38.278765],
    [39.459539, 38.278764],
    [39.459574, 38.278846],
    [39.459774, 38.279261],
    [39.459743, 38.279464],
    [39.459858, 38.279739],
    [39.460095, 38.279898],
    [39.460081, 38.280015],
    [39.460086, 38.280143],
    [39.460227, 38.280308],
    [39.460274, 38.280423],
    [39.460299, 38.280678],
    [39.460406, 38.280767],
    [39.460709, 38.280822],
    [39.460742, 38.280914],
    [39.460603, 38.281408],
    [39.460837, 38.281617],
    [39.461558, 38.281617],
    [39.463297, 38.281318],
    [39.463795, 38.281298],
    [39.464714, 38.281507],
    [39.465923, 38.282],
    [39.466939, 38.282608],
    [39.467108, 38.283006],
    [39.466576, 38.283614],
    [39.466528, 38.284031],
    [39.467664, 38.285284],
    [39.467423, 38.286005],
    [39.466697, 38.286689],
    [39.466141, 38.287771],
    [39.468511, 38.288397],
    [39.469526, 38.289099],
    [39.469817, 38.289915],
    [39.47047, 38.290485],
    [39.471485, 38.291035],
    [39.471969, 38.291548],
    [39.474145, 38.291529],
    [39.474581, 38.291851],
    [39.47205, 38.293256],
    [39.471615, 38.294661],
    [39.471954, 38.295914],
    [39.476507, 38.298001],
    [39.479524, 38.299468],
    [39.479668, 38.299595],
    [39.479581, 38.300385],
    [39.479714, 38.300973],
    [39.476961, 38.30226],
    [39.474467, 38.303697],
    [39.47419, 38.306044],
    [39.474296, 38.30679],
    [39.474184, 38.309029],
    [39.472856, 38.310035],
    [39.473294, 38.311244],
    [39.473188, 38.312441],
    [39.4722, 38.313477],
    [39.46883, 38.314201],
    [39.468057, 38.314061],
    [39.467341, 38.314263],
    [39.466961, 38.314096],
    [39.465661, 38.313888],
    [39.465389, 38.313637],
    [39.464973, 38.313796],
    [39.464724, 38.313656],
    [39.464812, 38.313409],
    [39.464503, 38.312828],
    [39.463478, 38.313669],
    [39.462419, 38.313742],
    [39.461969, 38.314023],
    [39.460056, 38.313919],
    [39.459611, 38.314235],
    [39.459484, 38.314235],
    [39.458856, 38.313788],
    [39.457634, 38.313642],
    [39.457323, 38.31346],
    [39.457022, 38.313427],
    [39.455867, 38.313685],
    [39.455054, 38.313706],
    [39.454352, 38.313578],
    [39.453715, 38.313877],
    [39.452019, 38.313729],
    [39.45156, 38.313595],
    [39.450937, 38.313984],
    [39.450008, 38.313367],
    [39.448793, 38.312868],
    [39.446886, 38.313393],
    [39.445806, 38.313476],
    [39.445423, 38.313663],
    [39.444011, 38.313986],
    [39.443901, 38.314146],
    [39.443477, 38.314184],
    [39.443349, 38.31446],
    [39.442637, 38.31495],
    [39.442277, 38.315444],
    [39.441099, 38.316135],
    [39.440897, 38.316407],
    [39.440272, 38.316618],
    [39.439694, 38.317511],
    [39.438836, 38.317831],
    [39.438589, 38.318576],
    [39.437201, 38.319316],
    [39.436656, 38.319466],
    [39.435978, 38.319771],
    [39.435533, 38.319742],
    [39.434497, 38.319963],
    [39.433674, 38.320344],
    [39.432786, 38.320135],
    [39.432319, 38.319743],
    [39.431397, 38.320063],
    [39.430503, 38.319439],
    [39.426358, 38.321419],
    [39.425852, 38.321525],
    [39.423974, 38.3216],
    [39.419907, 38.324164],
    [39.41684, 38.323594],
    [39.415219, 38.322641],
    [39.408555, 38.321597],
    [39.405164, 38.322313],
    [39.403611, 38.322767],
    [39.401788, 38.322647],
    [39.399995, 38.323053],
    [39.399174, 38.323529],
    [39.398232, 38.324722],
    [39.397783, 38.324774],
    [39.391974, 38.319932],
    [39.387655, 38.317467],
    [39.385631, 38.315974],
    [39.382792, 38.312135],
    [39.380677, 38.309694],
    [39.377929, 38.305451],
    [39.376539, 38.303982],
    [39.374365, 38.303034],
    [39.373338, 38.303034],
    [39.365968, 38.30903],
    [39.359066, 38.317018],
    [39.35805, 38.31944],
    [39.357282, 38.32032],
    [39.356297, 38.321239],
    [39.356346, 38.321301],
    [39.356366, 38.32141],
    [39.355895, 38.322692],
    [39.355019, 38.323261],
    [39.352905, 38.32537],
    [39.351304, 38.327502],
    [39.351186, 38.328207],
    [39.351638, 38.329885],
    [39.351742, 38.329967],
    [39.35017, 38.331179],
    [39.350101, 38.331409],
    [39.34991, 38.331628],
    [39.349864, 38.332387],
    [39.349788, 38.332482],
    [39.349407, 38.332729],
    [39.349146, 38.33304],
    [39.348195, 38.333332],
    [39.348166, 38.333463],
    [39.347842, 38.333813],
    [39.347185, 38.334379],
    [39.346923, 38.334767],
    [39.346456, 38.33521],
    [39.345435, 38.336552],
    [39.344611, 38.338211],
    [39.3295288, 38.3464687],
    [39.3247105, 38.3485772],
    [39.3197095, 38.3502652],
    [39.3125362, 38.3556864],
    [39.3048711, 38.3554333],
    [39.300512, 38.3523471],
    [39.2800003, 38.3409411],
    [39.2762303, 38.3382817],
    [39.27387, 38.3354121],
    [39.2725181, 38.3352185],
    [39.2719558, 38.3316693],
    [39.2707805, 38.3296411],
    [39.2704164, 38.3292553],
    [39.2687952, 38.3292516],
    [39.268291, 38.3285025],
    [39.2687644, 38.3275046],
    [39.2690635, 38.3268276],
    [39.2685699, 38.3256493],
    [39.2688382, 38.3249254],
    [39.2687697, 38.3244459],
    [39.2669821, 38.3243026],
    [39.2652333, 38.3237302],
    [39.2638566, 38.3231197],
    [39.2622329, 38.3222074],
    [39.2617893, 38.3200014],
    [39.2598492, 38.3186623],
    [39.2595363, 38.3172068],
    [39.2595792, 38.3168027],
    [39.259429, 38.3163482],
    [39.2585699, 38.3159292],
    [39.257133, 38.3146814],
    [39.2567789, 38.3142269],
    [39.255985, 38.3140332],
    [39.2553306, 38.3142942],
    [39.2546976, 38.3141595],
    [39.2530024, 38.3134103],
    [39.2524719, 38.3126101],
    [39.2511463, 38.3120297],
    [39.2498803, 38.311533],
    [39.2486894, 38.3109858],
    [39.2484641, 38.3107417],
    [39.248271, 38.3104218],
    [39.2480779, 38.3102955],
    [39.247638, 38.3104302],
    [39.2470157, 38.3106322],
    [39.2468119, 38.3106154],
    [39.2462432, 38.3100261],
    [39.2456639, 38.3101945],
    [39.2449665, 38.3099503],
    [39.2446232, 38.3096473],
    [39.2450309, 38.3094452],
    [39.2456746, 38.3087886],
    [39.2457497, 38.3078709],
    [39.2449021, 38.3076689],
    [39.243561, 38.308174],
    [39.2432177, 38.3087633],
    [39.2427671, 38.308898],
    [39.2418444, 38.3084181],
    [39.2414474, 38.3079888],
    [39.2418122, 38.3071301],
    [39.2416406, 38.3068607],
    [39.2407071, 38.3065407],
    [39.24016, 38.3061029],
    [39.2390442, 38.3058588],
    [39.2382073, 38.3062208],
    [39.2365551, 38.3062797],
    [39.235729, 38.3059514],
    [39.2352784, 38.3054378],
    [39.2340553, 38.305],
    [39.2338943, 38.3046633],
    [39.2341196, 38.3044107],
    [39.2346883, 38.3041329],
    [39.2349136, 38.3037372],
    [39.2350852, 38.3030215],
    [39.234817, 38.3027268],
    [39.2334974, 38.3028868],
    [39.2330038, 38.3027184],
    [39.2326498, 38.3023732],
    [39.231931, 38.3022132],
    [39.2313516, 38.3023227],
    [39.2312229, 38.3027605],
    [39.2316627, 38.303333],
    [39.2315125, 38.3039392],
    [39.2309332, 38.3044023],
    [39.2303109, 38.3045622],
    [39.2291736, 38.3041581],
    [39.2283797, 38.3043181],
    [39.2278325, 38.3047054],
    [39.2271566, 38.3055641],
    [39.2267167, 38.3070459],
    [39.2263842, 38.3072816],
    [39.2256868, 38.3070711],
    [39.2253971, 38.3065576],
    [39.2247212, 38.3065323],
    [39.2240989, 38.3065828],
    [39.2239272, 38.3070795],
    [39.2231226, 38.3077951],
    [39.2224145, 38.3079972],
    [39.2218029, 38.3079972],
    [39.2213631, 38.3076436],
    [39.2213845, 38.3073237],
    [39.2220497, 38.3070374],
    [39.222275, 38.3065828],
    [39.2219961, 38.3060608],
    [39.2212987, 38.3058083],
    [39.2209875, 38.3059009],
    [39.2205799, 38.3064229],
    [39.2201292, 38.3073069],
    [39.2191744, 38.3077025],
    [39.2181444, 38.3076689],
    [39.2176831, 38.3074163],
    [39.2169642, 38.3074163],
    [39.2157948, 38.3076352],
    [39.2152583, 38.3074921],
    [39.2147756, 38.3074921],
    [39.2142928, 38.3079972],
    [39.2132628, 38.3081908],
    [39.211278, 38.308275],
    [39.2107415, 38.3079804],
    [39.210602, 38.3075005],
    [39.2100334, 38.3066754],
    [39.2097437, 38.3062713],
    [39.2096043, 38.3057999],
    [39.2098081, 38.305261],
    [39.2100441, 38.3048232],
    [39.2097437, 38.3046043],
    [39.2088103, 38.3048148],
    [39.2084455, 38.3049832],
    [39.2078125, 38.3049916],
    [39.2071044, 38.3045791],
    [39.2068899, 38.3045791],
    [39.2064392, 38.3048822],
    [39.2062247, 38.30516],
    [39.2047548, 38.3057157],
    [39.2040038, 38.3057493],
    [39.2025769, 38.3054463],
    [39.2010963, 38.3056399],
    [39.2008924, 38.3061535],
    [39.2004311, 38.3063471],
    [39.1996586, 38.3064313],
    [39.1987789, 38.3069954],
    [39.1985214, 38.3077783],
    [39.198178, 38.308174],
    [39.1971695, 38.3087633],
    [39.1966009, 38.3092516],
    [39.1954636, 38.3092263],
    [39.1948736, 38.3095378],
    [39.1937685, 38.3094031],
    [39.1930389, 38.3093105],
    [39.1919982, 38.3085444],
    [39.1916978, 38.3078204],
    [39.190861, 38.3073069],
    [39.1903674, 38.3072648],
    [39.1897237, 38.3075257],
    [39.1893053, 38.3078709],
    [39.1882753, 38.3082666],
    [39.1876531, 38.3079804],
    [39.1872239, 38.3075257],
    [39.1868377, 38.3073742],
    [39.1865265, 38.3074416],
    [39.1861296, 38.3081656],
    [39.1863012, 38.3087801],
    [39.186033, 38.3093189],
    [39.1848099, 38.3091506],
    [39.1841233, 38.3089064],
    [39.1835976, 38.3089906],
    [39.1832752, 38.309218],
    [39.1826414, 38.308921],
    [39.182986, 38.3079635],
    [39.1827805, 38.3074878],
    [39.1810114, 38.3074341],
    [39.1801289, 38.3066089],
    [39.177518, 38.3070791],
    [39.1750551, 38.306163],
    [39.1752445, 38.3036365],
    [39.1735401, 38.3031907],
    [39.172025, 38.3050484],
    [39.1643077, 38.3038223],
    [39.1514252, 38.2992521],
    [39.1511612, 38.2914325],
    [39.1502792, 38.2882926],
    [39.1463547, 38.2743196],
    [39.1455351, 38.2712578],
    [39.1413765, 38.2557199],
    [39.1388564, 38.2310564],
    [39.1354936, 38.2266579],
    [39.1345093, 38.2253854],
    [39.1326961, 38.2242238],
    [39.1311912, 38.2222205],
    [39.1286328, 38.2186526],
    [39.119683, 38.210644],
    [39.110813, 38.2084593],
    [39.10654, 38.2038181],
  ],
  Kilis: [
    [36.7123606, 36.8267519],
    [36.7181967, 36.8311344],
    [36.718872, 36.8377013],
    [36.7140768, 36.8503678],
    [36.7204744, 36.8573248],
    [36.7473592, 36.8705724],
    [36.7554534, 36.8758375],
    [36.7602356, 36.8802029],
    [36.7681871, 36.8885094],
    [36.7767104, 36.8979707],
    [36.7821773, 36.9076509],
    [36.7855918, 36.9223121],
    [36.7988775, 36.9231361],
    [36.8149215, 36.9198619],
    [36.828222, 36.9097821],
    [36.8392704, 36.906104],
    [36.8530935, 36.896317],
    [36.8618915, 36.8918071],
    [36.87229, 36.8916584],
    [36.8825548, 36.8958499],
    [36.8827402, 36.8964265],
    [36.882877, 36.8969476],
    [36.8832428, 36.8972076],
    [36.8833921, 36.8974005],
    [36.883077, 36.8977747],
    [36.882804, 36.8980352],
    [36.8812125, 36.8988904],
    [36.8806585, 36.8994247],
    [36.8803517, 36.900051],
    [36.8803831, 36.9004844],
    [36.8805937, 36.9008462],
    [36.8810476, 36.9011476],
    [36.8819402, 36.9011476],
    [36.8833249, 36.9013622],
    [36.8839259, 36.9018357],
    [36.884566, 36.9026449],
    [36.8847311, 36.9035761],
    [36.8845825, 36.9041983],
    [36.8840188, 36.9050997],
    [36.88364, 36.9053214],
    [36.8833127, 36.9053885],
    [36.8828946, 36.9056457],
    [36.8821535, 36.9062185],
    [36.8819352, 36.9065988],
    [36.8818864, 36.9069717],
    [36.8820682, 36.9072291],
    [36.8825001, 36.9074489],
    [36.8828158, 36.907557],
    [36.8833141, 36.9076219],
    [36.8837082, 36.9076575],
    [36.8841545, 36.90794],
    [36.8846007, 36.9081661],
    [36.8848777, 36.9087752],
    [36.884964, 36.9093716],
    [36.8849107, 36.909606],
    [36.8850708, 36.9097376],
    [36.885918, 36.9098855],
    [36.8861434, 36.9100821],
    [36.8863291, 36.9103651],
    [36.8868771, 36.9106761],
    [36.887267, 36.9111047],
    [36.888067, 36.9119513],
    [36.8888377, 36.9123701],
    [36.8891044, 36.9128169],
    [36.8893658, 36.9139494],
    [36.889239, 36.914578],
    [36.8888576, 36.9148724],
    [36.8879342, 36.9151792],
    [36.8872645, 36.9155703],
    [36.8865793, 36.9161181],
    [36.886361, 36.9165076],
    [36.8862865, 36.9172493],
    [36.8865728, 36.9178798],
    [36.8868936, 36.9188583],
    [36.8871458, 36.9193192],
    [36.8873283, 36.9202799],
    [36.8868863, 36.9205897],
    [36.8855457, 36.9209147],
    [36.8843074, 36.9211079],
    [36.8835637, 36.9214773],
    [36.8828919, 36.9229112],
    [36.8823618, 36.9245061],
    [36.8821325, 36.9249997],
    [36.8820704, 36.9255957],
    [36.8822317, 36.9258429],
    [36.8818574, 36.9261647],
    [36.8804891, 36.9266739],
    [36.880163, 36.9271996],
    [36.8802431, 36.9274255],
    [36.8805201, 36.9275423],
    [36.8819112, 36.9277086],
    [36.882622, 36.9279701],
    [36.8832614, 36.9287726],
    [36.8840627, 36.9291284],
    [36.8843677, 36.929463],
    [36.8847787, 36.9296641],
    [36.884886, 36.9298951],
    [36.8897757, 36.9320726],
    [36.8950299, 36.9383138],
    [36.9045935, 36.9443877],
    [36.9190982, 36.949653],
    [36.9268845, 36.9559572],
    [36.9285786, 36.9620971],
    [36.932057, 36.9697734],
    [36.9339507, 36.9769311],
    [36.9364714, 36.9801164],
    [36.9400307, 36.9840696],
    [36.9521499, 37.0001942],
    [36.950866, 37.0138314],
    [36.9768539, 37.0244718],
    [36.9937195, 37.0346424],
    [37.0041835, 37.0314461],
    [37.0026987, 37.030048],
    [37.0015263, 37.0300643],
    [37.0017657, 37.0262281],
    [37.0000496, 37.0225141],
    [37.0061155, 37.0156802],
    [37.0049608, 37.0118145],
    [37.0052366, 37.0100003],
    [37.0080264, 37.0084225],
    [37.0089031, 37.0075534],
    [37.0094487, 37.0066437],
    [37.0095413, 37.0064408],
    [37.0093701, 37.0059996],
    [37.0090038, 37.0056402],
    [37.0086913, 37.0054465],
    [37.008322, 37.0049869],
    [37.0084754, 37.0047248],
    [37.0102732, 37.0045794],
    [37.0108056, 37.0045743],
    [37.0111411, 37.0043638],
    [37.0122024, 37.004033],
    [37.013421, 37.0033586],
    [37.014178, 37.0026501],
    [37.0150655, 37.0020033],
    [37.0155, 37.0017911],
    [37.0167713, 37.0011701],
    [37.0172884, 37.0009476],
    [37.0175691, 37.0008269],
    [37.0176831, 37.0007778],
    [37.0177906, 37.000495],
    [37.0178058, 37.0002648],
    [37.0173909, 36.9992541],
    [37.0172841, 36.9991211],
    [37.0167266, 36.9984268],
    [37.0165195, 36.9982321],
    [37.0164911, 36.9981247],
    [37.0164015, 36.9977856],
    [37.0163131, 36.9974511],
    [37.0163691, 36.9972438],
    [37.0169031, 36.9961434],
    [37.0170977, 36.9960648],
    [37.0180663, 36.9958271],
    [37.0183196, 36.994461],
    [37.0189903, 36.9938896],
    [37.0195538, 36.9937043],
    [37.0204374, 36.9932245],
    [37.0211158, 36.9926662],
    [37.0215166, 36.9920975],
    [37.0220465, 36.9913453],
    [37.0221977, 36.9911307],
    [37.0224801, 36.9905727],
    [37.0229757, 36.990322],
    [37.0234471, 36.9900837],
    [37.0234687, 36.9898626],
    [37.0248516, 36.9926658],
    [37.028406, 36.9936958],
    [37.0307639, 36.9943107],
    [37.0320981, 36.9950408],
    [37.0342451, 36.9964143],
    [37.0359264, 36.9952468],
    [37.0372713, 36.993781],
    [37.0388894, 36.9953763],
    [37.039777, 36.9973788],
    [37.041082, 36.9998531],
    [37.0459573, 36.9981864],
    [37.0484034, 36.9963269],
    [37.0503813, 36.9965833],
    [37.0515265, 36.9981053],
    [37.0524067, 36.9979955],
    [37.0533494, 36.9970981],
    [37.0541966, 36.9970573],
    [37.0552891, 36.9968507],
    [37.0561756, 36.9969179],
    [37.0572689, 36.9967164],
    [37.058093, 36.996938],
    [37.0584829, 36.9965322],
    [37.0582855, 36.9959879],
    [37.0584808, 36.9956763],
    [37.0592619, 36.995572],
    [37.0601164, 36.9959538],
    [37.0608944, 36.9968585],
    [37.0612222, 36.996886],
    [37.0615254, 36.9963848],
    [37.0617379, 36.9961164],
    [37.0619532, 36.9958221],
    [37.0625083, 36.9956861],
    [37.0625304, 36.9956912],
    [37.0637425, 36.9959725],
    [37.0642133, 36.9945052],
    [37.0650196, 36.993057],
    [37.066049, 36.9904197],
    [37.0664082, 36.9880163],
    [37.0653054, 36.988316],
    [37.064959, 36.9853451],
    [37.0634455, 36.985179],
    [37.0620744, 36.9847939],
    [37.059468, 36.9832956],
    [37.0573824, 36.982559],
    [37.0566391, 36.9815439],
    [37.0549562, 36.9798145],
    [37.055278, 36.9790907],
    [37.0545966, 36.978277],
    [37.0532818, 36.9773328],
    [37.0532395, 36.9769605],
    [37.0524993, 36.9764895],
    [37.0516064, 36.9765155],
    [37.0513644, 36.9763771],
    [37.0511704, 36.975975],
    [37.0508808, 36.9759663],
    [37.0506609, 36.9757115],
    [37.0509164, 36.975506],
    [37.0505988, 36.9753741],
    [37.0505742, 36.9745057],
    [37.0502542, 36.9739454],
    [37.0503933, 36.9736619],
    [37.0500258, 36.9732526],
    [37.0495666, 36.973044],
    [37.0495621, 36.9724872],
    [37.0490195, 36.9722266],
    [37.049095, 36.9716729],
    [37.049124, 36.9714603],
    [37.0509454, 36.970759],
    [37.0516604, 36.9700754],
    [37.0522725, 36.9692958],
    [37.0523556, 36.9691188],
    [37.0526527, 36.9684852],
    [37.054994, 36.9684109],
    [37.0548082, 36.9678221],
    [37.0576153, 36.967906],
    [37.0574004, 36.9674254],
    [37.0591346, 36.9671169],
    [37.0590785, 36.9668739],
    [37.0608405, 36.9678521],
    [37.0610032, 36.9669924],
    [37.0628861, 36.9672639],
    [37.0634452, 36.9672951],
    [37.0633675, 36.967066],
    [37.0647876, 36.9669498],
    [37.0647134, 36.9658984],
    [37.0662953, 36.9658707],
    [37.0647831, 36.9643634],
    [37.0665512, 36.9613493],
    [37.0701655, 36.9568367],
    [37.0702691, 36.9570259],
    [37.0705868, 36.9571504],
    [37.0707165, 36.9572304],
    [37.0708411, 36.957521],
    [37.0712545, 36.9576456],
    [37.0713643, 36.9576867],
    [37.0713755, 36.9580004],
    [37.0717727, 36.9580873],
    [37.0720786, 36.9582104],
    [37.0724836, 36.9587147],
    [37.072546, 36.9588584],
    [37.0725149, 36.9590466],
    [37.0723492, 36.9592953],
    [37.0723625, 36.9594318],
    [37.0724155, 36.9594569],
    [37.0727363, 36.9595019],
    [37.0728769, 36.9596337],
    [37.0729676, 36.9598136],
    [37.0730204, 36.9596856],
    [37.0730373, 36.9594893],
    [37.0730882, 36.9593235],
    [37.0734055, 36.9585457],
    [37.0740279, 36.9572382],
    [37.0742318, 36.9569873],
    [37.0744176, 36.9568459],
    [37.074989, 36.9564213],
    [37.0750362, 36.9563141],
    [37.0750786, 36.955952],
    [37.0752141, 36.9547959],
    [37.0750006, 36.953484],
    [37.0733874, 36.9539719],
    [37.0715302, 36.9550263],
    [37.0705404, 36.9552296],
    [37.0700524, 36.9539233],
    [37.0701858, 36.9535966],
    [37.070167, 36.9521746],
    [37.0702922, 36.9512076],
    [37.0700115, 36.9515473],
    [37.069774, 36.9516346],
    [37.0694586, 36.9515869],
    [37.068963, 36.9515777],
    [37.068668, 36.9508916],
    [37.0675862, 36.950024],
    [37.0669356, 36.950374],
    [37.0662037, 36.9497241],
    [37.0658505, 36.9494327],
    [37.0651789, 36.9491517],
    [37.0649257, 36.9485425],
    [37.0648664, 36.9479431],
    [37.0649691, 36.9473807],
    [37.0646345, 36.9472525],
    [37.0644866, 36.9461496],
    [37.0633179, 36.9467482],
    [37.0629502, 36.94701],
    [37.0626751, 36.9474254],
    [37.0620939, 36.9472529],
    [37.0616693, 36.9472691],
    [37.0613202, 36.9468736],
    [37.0615173, 36.9459383],
    [37.0616284, 36.9456026],
    [37.061343, 36.9453797],
    [37.0616134, 36.9444596],
    [37.0625817, 36.9435875],
    [37.0627836, 36.942479],
    [37.0631953, 36.9420902],
    [37.0632565, 36.9418323],
    [37.0630972, 36.9415852],
    [37.0630822, 36.9412671],
    [37.0636789, 36.9414706],
    [37.0651756, 36.9407241],
    [37.0666761, 36.9405341],
    [37.0678774, 36.9398929],
    [37.0678259, 36.9391931],
    [37.0687309, 36.938501],
    [37.0692255, 36.937656],
    [37.06873, 36.9370355],
    [37.0695065, 36.9364561],
    [37.0687283, 36.9353195],
    [37.0680007, 36.9336403],
    [37.0655177, 36.9298278],
    [37.0681833, 36.9247783],
    [37.0747577, 36.9214128],
    [37.0794729, 36.9166396],
    [37.0851696, 36.9089562],
    [37.0891752, 36.9043492],
    [37.0907044, 36.9038777],
    [37.1019499, 36.8980269],
    [37.1134665, 36.8951014],
    [37.1251632, 36.8951049],
    [37.1526636, 36.8909464],
    [37.1648166, 36.8833982],
    [37.1890691, 36.8807972],
    [37.2211356, 36.8806151],
    [37.2569488, 36.8773291],
    [37.2779583, 36.8716512],
    [37.2969409, 36.8603722],
    [37.2981374, 36.8596611],
    [37.3083661, 36.8464653],
    [37.3093829, 36.8354829],
    [37.3027879, 36.8197464],
    [37.3028984, 36.7824591],
    [37.3059052, 36.7693862],
    [37.3156964, 36.7602706],
    [37.3413676, 36.7479737],
    [37.3518563, 36.7427124],
    [37.3583314, 36.7328734],
    [37.3596, 36.7307],
    [37.3648, 36.7327],
    [37.3661, 36.7286],
    [37.3693, 36.7262],
    [37.3691, 36.7238],
    [37.3832, 36.7276],
    [37.3927, 36.7285],
    [37.3858, 36.7419],
    [37.3844, 36.7488],
    [37.3975, 36.7595],
    [37.401, 36.7688],
    [37.3945, 36.7708],
    [37.391, 36.771],
    [37.3826, 36.7758],
    [37.3863, 36.7839],
    [37.3831, 36.7833],
    [37.385, 36.7872],
    [37.3818, 36.7941],
    [37.3807, 36.8041],
    [37.4047, 36.807],
    [37.4031, 36.8105],
    [37.4038, 36.8141],
    [37.4056, 36.8144],
    [37.4094, 36.8265],
    [37.4151, 36.8258],
    [37.4224, 36.8195],
    [37.4306, 36.8164],
    [37.4326, 36.817],
    [37.4338, 36.8151],
    [37.4436, 36.8146],
    [37.4454, 36.8162],
    [37.4452, 36.8134],
    [37.4493, 36.8135],
    [37.449, 36.8087],
    [37.4474, 36.8084],
    [37.4468, 36.7946],
    [37.4527, 36.7931],
    [37.4533, 36.7885],
    [37.458, 36.7857],
    [37.4631, 36.7859],
    [37.477, 36.7814],
    [37.4763, 36.7837],
    [37.4893, 36.7821],
    [37.4896, 36.7846],
    [37.493, 36.7841],
    [37.4911, 36.7747],
    [37.4928, 36.7759],
    [37.4988, 36.7708],
    [37.4973, 36.767],
    [37.4957, 36.7676],
    [37.4875, 36.7651],
    [37.4909, 36.7572],
    [37.4914, 36.752],
    [37.5017, 36.7526],
    [37.5072, 36.7505],
    [37.5086, 36.7425],
    [37.5105, 36.7421],
    [37.5114, 36.7388],
    [37.5163, 36.7396],
    [37.5168, 36.7373],
    [37.518053, 36.7387466],
    [37.5187, 36.7407],
    [37.5312, 36.7442],
    [37.5393, 36.7427],
    [37.5441, 36.7309],
    [37.5417, 36.7288],
    [37.5442, 36.7225],
    [37.5508, 36.7264],
    [37.5545, 36.7252],
    [37.5579, 36.7202],
    [37.5651, 36.7158],
    [37.5727431, 36.7058108],
    [37.5708765, 36.7050048],
    [37.5654989, 36.7026253],
    [37.5616442, 36.7010073],
    [37.5569566, 36.6992941],
    [37.5527212, 36.6977712],
    [37.5472778, 36.6959279],
    [37.5422516, 36.6939879],
    [37.5362315, 36.691775],
    [37.5299974, 36.6895383],
    [37.5282366, 36.6889434],
    [37.5266423, 36.6883247],
    [37.5247149, 36.6876109],
    [37.52205, 36.686445],
    [37.5195753, 36.6853028],
    [37.5152447, 36.6832327],
    [37.5108427, 36.6813529],
    [37.5085108, 36.680187],
    [37.5044419, 36.678331],
    [37.5012297, 36.6768319],
    [37.4988502, 36.6757374],
    [37.4967563, 36.6745715],
    [37.495281, 36.6736435],
    [37.4932109, 36.6720016],
    [37.4913787, 36.6706215],
    [37.4901414, 36.6695984],
    [37.4892134, 36.6683611],
    [37.4885709, 36.6671475],
    [37.4883568, 36.6662909],
    [37.4882616, 36.665244],
    [37.4882616, 36.6637925],
    [37.488095, 36.653775],
    [37.4880475, 36.6528232],
    [37.4879285, 36.651919],
    [37.4876429, 36.6508958],
    [37.4871195, 36.6497775],
    [37.4862867, 36.6485639],
    [37.4800525, 36.6408069],
    [37.4780238, 36.6381428],
    [37.4779992, 36.6378517],
    [37.474147, 36.6330137],
    [37.4735157, 36.6333776],
    [37.4706454, 36.6340567],
    [37.4586029, 36.6340331],
    [37.4556591, 36.6329317],
    [37.4522333, 36.6322901],
    [37.4429423, 36.632599],
    [37.4398117, 36.6341205],
    [37.4249796, 36.6428965],
    [37.4159042, 36.6433695],
    [37.4148442, 36.6434248],
    [37.4109247, 36.6435127],
    [37.407568, 36.6437994],
    [37.4062055, 36.6439165],
    [37.4000662, 36.6467281],
    [37.3964643, 36.648034],
    [37.3926005, 36.6490668],
    [37.3785627, 36.6517203],
    [37.3731267, 36.6527248],
    [37.3717194, 36.6533995],
    [37.3660261, 36.6544668],
    [37.3600005, 36.6553258],
    [37.3573991, 36.6558517],
    [37.3549919, 36.6568793],
    [37.3508995, 36.6572883],
    [37.3462727, 36.6582921],
    [37.3385405, 36.6593337],
    [37.3255589, 36.6615363],
    [37.3189045, 36.6631154],
    [37.315573, 36.6637568],
    [37.3035825, 36.6659713],
    [37.2960846, 36.6636568],
    [37.2912451, 36.6658834],
    [37.2915336, 36.6681034],
    [37.2898872, 36.6688042],
    [37.2828663, 36.6700158],
    [37.2791033, 36.6699434],
    [37.2784979, 36.665424],
    [37.2780244, 36.6633585],
    [37.275089, 36.6637724],
    [37.2690468, 36.6656793],
    [37.2653213, 36.6673388],
    [37.2610682, 36.6673108],
    [37.2585353, 36.6677626],
    [37.2554083, 36.6693267],
    [37.2549155, 36.6694244],
    [37.253846, 36.6696363],
    [37.2521599, 36.6698325],
    [37.250264, 36.6699884],
    [37.2493467, 36.6701691],
    [37.248542, 36.6705864],
    [37.2463179, 36.6708187],
    [37.2436661, 36.6707217],
    [37.2412043, 36.6701388],
    [37.2404025, 36.6703928],
    [37.2379064, 36.671654],
    [37.2318521, 36.672504],
    [37.231078, 36.6722401],
    [37.2307038, 36.6721176],
    [37.2300517, 36.671776],
    [37.2293686, 36.6715276],
    [37.2285812, 36.6714705],
    [37.2275933, 36.6715482],
    [37.2264103, 36.6718349],
    [37.2258269, 36.6720318],
    [37.2257978, 36.6722107],
    [37.225953, 36.6724902],
    [37.2260151, 36.6727386],
    [37.2257357, 36.6729249],
    [37.2252699, 36.6729559],
    [37.2249905, 36.6728317],
    [37.2244937, 36.672397],
    [37.2240602, 36.672114],
    [37.2237174, 36.6722418],
    [37.2232516, 36.6724902],
    [37.2223201, 36.6726765],
    [37.2219697, 36.6732021],
    [37.221799, 36.6733283],
    [37.2215411, 36.6733543],
    [37.2214258, 36.6733024],
    [37.2213748, 36.6731504],
    [37.221062, 36.6730331],
    [37.2208273, 36.6731699],
    [37.2206744, 36.6733907],
    [37.2202708, 36.6737322],
    [37.2197874, 36.6739651],
    [37.2179289, 36.6745205],
    [37.2156267, 36.6748984],
    [37.2125761, 36.6731229],
    [37.2102527, 36.6728799],
    [37.2045118, 36.6708163],
    [37.2030844, 36.6703032],
    [37.1927767, 36.6677392],
    [37.1842521, 36.6628334],
    [37.1798118, 36.6620572],
    [37.1766038, 36.6610046],
    [37.1678884, 36.6581448],
    [37.1625607, 36.6568669],
    [37.1581564, 36.6614575],
    [37.1549294, 36.6611865],
    [37.1525804, 36.6646654],
    [37.1499101, 36.6674289],
    [37.1467508, 36.6701861],
    [37.1464945, 36.6704098],
    [37.1454572, 36.6715431],
    [37.1451602, 36.6721888],
    [37.1442589, 36.6733907],
    [37.1438234, 36.6741572],
    [37.1436599, 36.6744569],
    [37.143531, 36.6750687],
    [37.1432902, 36.6756879],
    [37.1430406, 36.6761248],
    [37.142075, 36.6773217],
    [37.1413468, 36.6767886],
    [37.1378314, 36.6738564],
    [37.1352125, 36.6719855],
    [37.1246715, 36.6656086],
    [37.1211261, 36.6665366],
    [37.120674, 36.6672266],
    [37.1199364, 36.6677263],
    [37.1191512, 36.6687257],
    [37.1160579, 36.6704151],
    [37.1143923, 36.6720569],
    [37.1064328, 36.6714136],
    [37.1038646, 36.6622545],
    [37.103219, 36.6598604],
    [37.1015321, 36.6538796],
    [37.100101, 36.6491717],
    [37.0998292, 36.6484816],
    [37.0903345, 36.63856],
    [37.0897724, 36.637922],
    [37.0882188, 36.6362927],
    [37.0864727, 36.6344499],
    [37.0862072, 36.6342148],
    [37.0856081, 36.6343819],
    [37.0856512, 36.6344406],
    [37.0737777, 36.6389763],
    [37.0721401, 36.6396102],
    [37.0704696, 36.640189],
    [37.0696299, 36.6406803],
    [37.0677639, 36.6416576],
    [37.066946, 36.6418972],
    [37.0651237, 36.6422556],
    [37.0640692, 36.6426789],
    [37.0601805, 36.6440174],
    [37.0578855, 36.6449398],
    [37.0574304, 36.6452725],
    [37.0571081, 36.6454482],
    [37.0570895, 36.6455681],
    [37.0571186, 36.6459066],
    [37.0569759, 36.6466443],
    [37.0574675, 36.6541613],
    [37.0571803, 36.6549145],
    [37.0566028, 36.6551698],
    [37.0528984, 36.6561442],
    [37.0495225, 36.6569686],
    [37.0482746, 36.6580059],
    [37.0387998, 36.6623713],
    [37.036044, 36.6640122],
    [37.0349438, 36.6646672],
    [37.0204899, 36.6650183],
    [37.0198163, 36.6677897],
    [37.0199742, 36.668939],
    [37.0209231, 36.672425],
    [37.0206242, 36.6798725],
    [37.0198801, 36.6832642],
    [37.020094, 36.6836104],
    [37.0199268, 36.6838485],
    [37.01972, 36.6839802],
    [37.0192675, 36.6840539],
    [37.0185494, 36.6842414],
    [37.0179122, 36.6844511],
    [37.0173848, 36.684643],
    [37.0165323, 36.6850647],
    [37.0161792, 36.6852375],
    [37.0159051, 36.686219],
    [37.0156476, 36.6872619],
    [37.0155936, 36.6877275],
    [37.015694, 36.6882548],
    [37.015798, 36.6888077],
    [37.0162546, 36.6895228],
    [37.0168073, 36.6903709],
    [37.0175062, 36.6912112],
    [37.0170367, 36.691961],
    [37.0169596, 36.6922426],
    [37.0168632, 36.6926144],
    [37.0164523, 36.6930405],
    [37.0163114, 36.6935801],
    [37.0161142, 36.6939427],
    [37.0160115, 36.6940674],
    [37.016245, 36.6946902],
    [37.0159437, 36.6950137],
    [37.0159875, 36.6955194],
    [37.0161502, 36.6958887],
    [37.0168092, 36.6962691],
    [37.0175997, 36.696994],
    [37.0185688, 36.6976469],
    [37.0196105, 36.6982076],
    [37.02027, 36.6985353],
    [37.0213548, 36.6988106],
    [37.0217862, 36.6988543],
    [37.0222756, 36.6987804],
    [37.0231728, 36.6988461],
    [37.0243502, 36.6989948],
    [37.0250081, 36.6989707],
    [37.0263148, 36.698798],
    [37.0277697, 36.6988444],
    [37.0290536, 36.698859],
    [37.0298303, 36.6987992],
    [37.0298768, 36.698798],
    [37.0307728, 36.6988078],
    [37.0315625, 36.6989941],
    [37.0325832, 36.6993403],
    [37.0346634, 36.699989],
    [37.035427, 36.7003744],
    [37.035854, 36.7008193],
    [37.0360821, 36.7012392],
    [37.0360767, 36.7018521],
    [37.0362525, 36.7021314],
    [37.0369771, 36.7023311],
    [37.0377495, 36.7025981],
    [37.0389637, 36.7029472],
    [37.039981, 36.703097],
    [37.0401841, 36.7032076],
    [37.0402457, 36.7035353],
    [37.040309, 36.7039332],
    [37.040415, 36.7043765],
    [37.0405488, 36.7050019],
    [37.0405321, 36.7057453],
    [37.0405072, 36.7061595],
    [37.0409408, 36.7068746],
    [37.0412346, 36.7074787],
    [37.0411535, 36.7080923],
    [37.0409451, 36.7087638],
    [37.0407702, 36.709415],
    [37.0408324, 36.7098857],
    [37.0408416, 36.709933],
    [37.0409582, 36.7105339],
    [37.0415413, 36.7106537],
    [37.0420899, 36.7106941],
    [37.0427101, 36.7107509],
    [37.0430964, 36.7110094],
    [37.0434013, 36.7114],
    [37.0435623, 36.7117995],
    [37.0435983, 36.7125638],
    [37.0435893, 36.7133381],
    [37.0436843, 36.7139173],
    [37.0439363, 36.7142286],
    [37.0447638, 36.7143189],
    [37.0456573, 36.7145289],
    [37.0462841, 36.7148556],
    [37.0464094, 36.715114],
    [37.0466986, 36.7153916],
    [37.0467538, 36.7156823],
    [37.0466212, 36.7160076],
    [37.0463376, 36.7162965],
    [37.0462526, 36.7166837],
    [37.0462038, 36.717142],
    [37.0462679, 36.7175677],
    [37.0466568, 36.7179453],
    [37.0470114, 36.7183439],
    [37.0472229, 36.7188708],
    [37.0475151, 36.7193769],
    [37.0476634, 36.7198127],
    [37.0476029, 36.720271],
    [37.0474395, 36.7208716],
    [37.0471195, 36.7213037],
    [37.0466987, 36.7216913],
    [37.0465272, 36.7221333],
    [37.0465137, 36.7226498],
    [37.0466158, 36.7229705],
    [37.0464883, 36.7232542],
    [37.0465139, 36.7236754],
    [37.0463565, 36.7238296],
    [37.0461081, 36.7242509],
    [37.0463097, 36.7247146],
    [37.0465211, 36.7251518],
    [37.046579, 36.7256012],
    [37.0465514, 36.7262012],
    [37.0461538, 36.7263353],
    [37.0457801, 36.7264729],
    [37.0457451, 36.7266337],
    [37.0457488, 36.7267715],
    [37.0456643, 36.7269295],
    [37.0456333, 36.7271331],
    [37.0455091, 36.7273335],
    [37.0454428, 36.7275246],
    [37.0453714, 36.7276403],
    [37.0453248, 36.7278498],
    [37.0453635, 36.728069],
    [37.0454267, 36.7282618],
    [37.0454048, 36.7285082],
    [37.0452946, 36.7287185],
    [37.0452276, 36.7288568],
    [37.0451065, 36.7291009],
    [37.0449062, 36.7294185],
    [37.0447088, 36.7297554],
    [37.044557, 36.7298376],
    [37.044369, 36.7301446],
    [37.0441886, 36.7302258],
    [37.0439874, 36.7304955],
    [37.043781, 36.7307536],
    [37.0435877, 36.731111],
    [37.0435569, 36.731308],
    [37.0433976, 36.7314433],
    [37.0434243, 36.7316282],
    [37.043416, 36.7317988],
    [37.0434557, 36.73192],
    [37.0434662, 36.7321411],
    [37.0433752, 36.732302],
    [37.0434692, 36.7324099],
    [37.0433762, 36.7327369],
    [37.0432328, 36.7330418],
    [37.0429345, 36.7334067],
    [37.0425975, 36.7335831],
    [37.0422886, 36.733727],
    [37.0420831, 36.7339705],
    [37.0418064, 36.7341687],
    [37.0415542, 36.7342616],
    [37.0412663, 36.7342229],
    [37.0410514, 36.734269],
    [37.0409085, 36.7343762],
    [37.0407038, 36.7344299],
    [37.0404688, 36.7344687],
    [37.040146, 36.734526],
    [37.039767, 36.7346483],
    [37.0396237, 36.7347476],
    [37.039485, 36.734736],
    [37.0394323, 36.7346665],
    [37.0392819, 36.7346157],
    [37.0391243, 36.7346205],
    [37.0389386, 36.7346578],
    [37.0388332, 36.7347243],
    [37.0385842, 36.7348821],
    [37.0384572, 36.7349097],
    [37.0381616, 36.7349188],
    [37.0379759, 36.7349561],
    [37.0379342, 36.7350848],
    [37.0378235, 36.7352463],
    [37.0377018, 36.7353844],
    [37.0376354, 36.7354418],
    [37.0376509, 36.73556],
    [37.037771, 36.7355958],
    [37.0377467, 36.7357073],
    [37.0376308, 36.7357583],
    [37.0373559, 36.735996],
    [37.0369836, 36.7362604],
    [37.0367332, 36.736181],
    [37.0364356, 36.7363562],
    [37.0362135, 36.7364578],
    [37.0360252, 36.7364399],
    [37.0357322, 36.7365042],
    [37.0355101, 36.7366058],
    [37.035324, 36.7366352],
    [37.0351675, 36.7366637],
    [37.0349855, 36.73678],
    [37.0348801, 36.7368465],
    [37.0347126, 36.7368516],
    [37.0345618, 36.7367929],
    [37.0343504, 36.7367045],
    [37.0341909, 36.7366698],
    [37.033865, 36.7366639],
    [37.0337282, 36.7366918],
    [37.0335789, 36.7366647],
    [37.0334171, 36.7365827],
    [37.0333065, 36.7365386],
    [37.0331094, 36.7365446],
    [37.033034, 36.7366181],
    [37.0330082, 36.736698],
    [37.0328293, 36.7366718],
    [37.0326986, 36.7366204],
    [37.0324311, 36.7365969],
    [37.0322662, 36.7366573],
    [37.0321945, 36.7368097],
    [37.0321603, 36.7369215],
    [37.0320644, 36.7369798],
    [37.0318378, 36.7369867],
    [37.0315206, 36.7369568],
    [37.0313334, 36.7369625],
    [37.031117, 36.736977],
    [37.0309805, 36.7370128],
    [37.0309748, 36.7371],
    [37.0308569, 36.7371115],
    [37.0306985, 36.7371005],
    [37.0304609, 36.7371238],
    [37.03022, 36.7369901],
    [37.0298231, 36.7370304],
    [37.0292966, 36.7368115],
    [37.0289439, 36.736794],
    [37.0284561, 36.7366491],
    [37.0282797, 36.7367626],
    [37.0279541, 36.7367852],
    [37.0275319, 36.7366664],
    [37.0272326, 36.7366831],
    [37.0266139, 36.7368258],
    [37.0260191, 36.7366831],
    [37.0256622, 36.73704],
    [37.0251625, 36.7368972],
    [37.0247104, 36.7370876],
    [37.0241631, 36.7369448],
    [37.0240441, 36.7370162],
    [37.0239727, 36.7373017],
    [37.0238776, 36.7373969],
    [37.0234968, 36.7372541],
    [37.0230447, 36.7371828],
    [37.0225213, 36.7373255],
    [37.0219264, 36.7374921],
    [37.0214505, 36.7374683],
    [37.0202846, 36.7370638],
    [37.0202846, 36.7374207],
    [37.0200942, 36.7375397],
    [37.0199514, 36.7375159],
    [37.0197135, 36.7373255],
    [37.0194756, 36.73704],
    [37.0189997, 36.7368734],
    [37.0187617, 36.7369686],
    [37.0186189, 36.7372303],
    [37.0185, 36.7378014],
    [37.0183572, 36.7378966],
    [37.017691, 36.7378966],
    [37.0175006, 36.737849],
    [37.0171437, 36.7370638],
    [37.0168581, 36.736921],
    [37.0162633, 36.7370876],
    [37.0160729, 36.7373493],
    [37.0157398, 36.7373017],
    [37.0152877, 36.7369448],
    [37.0150022, 36.7369448],
    [37.0148594, 36.7371352],
    [37.0148832, 36.7374207],
    [37.0145263, 36.7373731],
    [37.0131462, 36.7374683],
    [37.0126703, 36.7376349],
    [37.0120754, 36.7373969],
    [37.0118137, 36.7375635],
    [37.0114568, 36.7374445],
    [37.0112188, 36.7376349],
    [37.0107905, 36.73773],
    [37.0102908, 36.73773],
    [37.009458, 36.7378966],
    [37.008649, 36.7381107],
    [37.0077924, 36.7383011],
    [37.006912, 36.7385628],
    [37.0057223, 36.7389674],
    [37.0045087, 36.7392291],
    [37.0040329, 36.7392767],
    [37.0034856, 36.7393005],
    [37.0031049, 36.7393957],
    [37.0028431, 36.7395384],
    [37.0025338, 36.7399429],
    [37.0021769, 36.7401571],
    [37.0017724, 36.7402999],
    [37.0014392, 36.7402523],
    [37.0012727, 36.7401333],
    [37.0010109, 36.7397526],
    [37.000773, 36.7396812],
    [37.0004399, 36.7397526],
    [36.999845, 36.7399667],
    [36.9992025, 36.7401571],
    [36.9987267, 36.7401095],
    [36.9982032, 36.7403712],
    [36.9977511, 36.7404188],
    [36.9973466, 36.740514],
    [36.9965851, 36.7404902],
    [36.9963234, 36.7407044],
    [36.9960379, 36.7407995],
    [36.9957047, 36.7409661],
    [36.9953478, 36.7410613],
    [36.9945388, 36.7416086],
    [36.9927304, 36.7421558],
    [36.992897, 36.7432504],
    [36.9930635, 36.7437025],
    [36.9938726, 36.7445829],
    [36.9956096, 36.7457488],
    [36.9967755, 36.7467006],
    [36.9973942, 36.7470099],
    [36.9983935, 36.7472241],
    [36.9986553, 36.7473906],
    [36.998917, 36.7477],
    [36.9990598, 36.7480807],
    [36.9993215, 36.7490087],
    [36.9995357, 36.7500556],
    [36.9996071, 36.7505791],
    [36.9994643, 36.7509122],
    [36.9990836, 36.7513168],
    [36.9981318, 36.7517451],
    [36.9977511, 36.7521496],
    [36.9972276, 36.7524589],
    [36.9966565, 36.7526493],
    [36.9961806, 36.7528634],
    [36.9960617, 36.7531251],
    [36.9960617, 36.7548384],
    [36.9961568, 36.7556474],
    [36.9961806, 36.7568847],
    [36.9960141, 36.7572892],
    [36.9957047, 36.7576223],
    [36.9952051, 36.7579317],
    [36.9945388, 36.7581696],
    [36.9940391, 36.7585979],
    [36.9936346, 36.7591452],
    [36.9932365, 36.7593449],
    [36.9932127, 36.7594807],
    [36.9932663, 36.7596017],
    [36.9934962, 36.7598095],
    [36.9936923, 36.7599109],
    [36.9939742, 36.7600021],
    [36.9944174, 36.7600654],
    [36.994614, 36.7601745],
    [36.9947, 36.7601719],
    [36.9948452, 36.7602059],
    [36.9949932, 36.7603011],
    [36.9953136, 36.7603988],
    [36.9961145, 36.7605357],
    [36.9966311, 36.7607348],
    [36.9967501, 36.7608232],
    [36.9967728, 36.7608992],
    [36.9967551, 36.7610011],
    [36.9967203, 36.7612782],
    [36.9969907, 36.7621442],
    [36.9970843, 36.7623024],
    [36.9972263, 36.7624745],
    [36.9973951, 36.7628067],
    [36.9975877, 36.763238],
    [36.9975287, 36.7638149],
    [36.9975746, 36.7645879],
    [36.9976489, 36.7649461],
    [36.9977215, 36.7654729],
    [36.9975347, 36.7655706],
    [36.9949158, 36.7648368],
    [36.993053, 36.7643026],
    [36.9921765, 36.7640026],
    [36.9914808, 36.7638625],
    [36.9904466, 36.7636483],
    [36.9898262, 36.763483],
    [36.9891828, 36.763234],
    [36.9882264, 36.7628411],
    [36.9874567, 36.7625063],
    [36.9870502, 36.7624112],
    [36.986772, 36.7623966],
    [36.9865797, 36.7623794],
    [36.986295, 36.7622269],
    [36.986022, 36.7621201],
    [36.985703, 36.762053],
    [36.9853826, 36.7619553],
    [36.984985, 36.7618446],
    [36.9847259, 36.7618294],
    [36.984459, 36.7618527],
    [36.9841744, 36.7619073],
    [36.9839266, 36.7619301],
    [36.9836487, 36.7619231],
    [36.9833708, 36.7619161],
    [36.9829624, 36.7619821],
    [36.9826947, 36.7619901],
    [36.9824448, 36.761967],
    [36.982067, 36.761871],
    [36.9817756, 36.7617801],
    [36.9814705, 36.7618046],
    [36.9813102, 36.7618554],
    [36.9810886, 36.7620307],
    [36.9808336, 36.7623068],
    [36.9806654, 36.7623302],
    [36.9794768, 36.7625478],
    [36.9747669, 36.7634802],
    [36.9708393, 36.7643244],
    [36.9650097, 36.7672829],
    [36.9559311, 36.7696591],
    [36.955518, 36.7698092],
    [36.9552107, 36.7699887],
    [36.9500968, 36.7744514],
    [36.9458708, 36.776907],
    [36.9457566, 36.7764501],
    [36.9450157, 36.7760833],
    [36.9439628, 36.7755845],
    [36.9420787, 36.7741992],
    [36.9415246, 36.7739775],
    [36.9410489, 36.7740675],
    [36.9408, 36.7743705],
    [36.9397934, 36.77489],
    [36.9391116, 36.7752905],
    [36.9385921, 36.775442],
    [36.9382133, 36.7752688],
    [36.9376289, 36.7749333],
    [36.9371094, 36.7745329],
    [36.9367414, 36.7742731],
    [36.9359297, 36.7740026],
    [36.9348474, 36.7738186],
    [36.93396, 36.7738511],
    [36.9331375, 36.7741757],
    [36.9328777, 36.7749983],
    [36.9327587, 36.7760156],
    [36.9333647, 36.7769896],
    [36.93396, 36.7777581],
    [36.9345977, 36.7785215],
    [36.9348258, 36.7791001],
    [36.9348258, 36.7796629],
    [36.9336569, 36.7801932],
    [36.9328128, 36.7803014],
    [36.9319037, 36.7801066],
    [36.9312435, 36.7801932],
    [36.930843, 36.7800417],
    [36.9306078, 36.779519],
    [36.9304101, 36.7789377],
    [36.9300313, 36.7786022],
    [36.9295335, 36.778494],
    [36.9290007, 36.778577],
    [36.9285486, 36.7788187],
    [36.9291116, 36.7805165],
    [36.9289707, 36.7812971],
    [36.9289861, 36.7820107],
    [36.9285816, 36.7823676],
    [36.9279867, 36.7827245],
    [36.9272491, 36.7830339],
    [36.9267018, 36.7831766],
    [36.9262497, 36.7833432],
    [36.9258214, 36.7832718],
    [36.9253218, 36.7833908],
    [36.9248697, 36.7838191],
    [36.9247031, 36.7840808],
    [36.9247269, 36.784414],
    [36.9246555, 36.7846519],
    [36.9246317, 36.7848185],
    [36.9244889, 36.7853419],
    [36.9239179, 36.7863175],
    [36.9213005, 36.7851992],
    [36.9203963, 36.7861272],
    [36.9173981, 36.7859368],
    [36.9104992, 36.7816873],
    [36.9100935, 36.7814082],
    [36.9095892, 36.7812336],
    [36.9077124, 36.7795906],
    [36.9074187, 36.7791467],
    [36.9067198, 36.778714],
    [36.9055719, 36.7784192],
    [36.9044206, 36.7780569],
    [36.9035133, 36.7776035],
    [36.9005668, 36.7771054],
    [36.8971645, 36.7770374],
    [36.8959104, 36.7772679],
    [36.893178, 36.7773286],
    [36.8906405, 36.7782757],
    [36.8890548, 36.7784506],
    [36.8877131, 36.7778831],
    [36.8853223, 36.7772561],
    [36.8849322, 36.7771513],
    [36.8845209, 36.7771317],
    [36.8789563, 36.7792989],
    [36.8780868, 36.7793727],
    [36.8774875, 36.7794946],
    [36.8771855, 36.7795882],
    [36.8761902, 36.7803421],
    [36.8759105, 36.7805149],
    [36.8757428, 36.7805814],
    [36.8756111, 36.7805887],
    [36.8749026, 36.7805451],
    [36.8739232, 36.7805503],
    [36.871947, 36.7806909],
    [36.871271, 36.7807921],
    [36.8701958, 36.7812911],
    [36.8687508, 36.7817519],
    [36.8682307, 36.7821001],
    [36.867856, 36.7822684],
    [36.8637587, 36.7830569],
    [36.8560191, 36.7850577],
    [36.8530449, 36.78598],
    [36.8458481, 36.7878998],
    [36.8428578, 36.788661],
    [36.8410102, 36.7889527],
    [36.8386142, 36.7897439],
    [36.8370816, 36.7901145],
    [36.836023, 36.7902332],
    [36.8349348, 36.7902832],
    [36.8308519, 36.7912829],
    [36.8288744, 36.7918758],
    [36.8268835, 36.7916237],
    [36.8248609, 36.7915999],
    [36.8158428, 36.7931466],
    [36.8119166, 36.7934321],
    [36.7933568, 36.7968347],
    [36.7893356, 36.7980245],
    [36.7847194, 36.7995711],
    [36.7806743, 36.8013081],
    [36.7753443, 36.804568],
    [36.7733932, 36.8056863],
    [36.770514, 36.8070902],
    [36.7690388, 36.8080182],
    [36.7673731, 36.8088034],
    [36.7656123, 36.8094697],
    [36.7639467, 36.8104453],
    [36.7626699, 36.8112202],
    [36.7615728, 36.811837],
    [36.7612352, 36.8126793],
    [36.7599962, 36.813043],
    [36.7574157, 36.8147567],
    [36.7543384, 36.8171088],
    [36.7528039, 36.8188481],
    [36.7519744, 36.8192153],
    [36.7514315, 36.8194627],
    [36.7496157, 36.8196643],
    [36.7475224, 36.8203546],
    [36.7461016, 36.8204769],
    [36.7449679, 36.8204291],
    [36.7437642, 36.81987],
    [36.7402473, 36.8187734],
    [36.7382961, 36.8188448],
    [36.7375109, 36.8194396],
    [36.734319, 36.820285],
    [36.7331089, 36.8206056],
    [36.7314195, 36.8208435],
    [36.7294921, 36.8207721],
    [36.7291828, 36.8208911],
    [36.7287307, 36.8210814],
    [36.728112, 36.821129],
    [36.7280093, 36.8212023],
    [36.7277789, 36.821367],
    [36.7274696, 36.8216287],
    [36.7271364, 36.8219856],
    [36.7267319, 36.8222474],
    [36.7261133, 36.8225091],
    [36.7258753, 36.8227233],
    [36.725804, 36.822866],
    [36.7258425, 36.8229549],
    [36.725922, 36.8229947],
    [36.7260187, 36.8232961],
    [36.7262234, 36.8234552],
    [36.7263655, 36.8237566],
    [36.7263598, 36.8240806],
    [36.7256719, 36.8243876],
    [36.7253478, 36.8244615],
    [36.7249044, 36.8246492],
    [36.724677, 36.8248879],
    [36.7245519, 36.8249277],
    [36.7242449, 36.8251949],
    [36.7232898, 36.8254223],
    [36.7224086, 36.8256725],
    [36.7219765, 36.8258942],
    [36.7214136, 36.8260875],
    [36.7213511, 36.8261046],
    [36.7209475, 36.8259909],
    [36.7198388, 36.8263149],
    [36.7197365, 36.8262922],
    [36.7195603, 36.8261899],
    [36.7189519, 36.8261273],
    [36.7188325, 36.8259966],
    [36.718065, 36.8260307],
    [36.717397, 36.8264196],
    [36.7168572, 36.8270539],
    [36.7167858, 36.8273394],
    [36.7165954, 36.827506],
    [36.7161433, 36.827506],
    [36.7155009, 36.8276726],
    [36.7153581, 36.8274108],
    [36.7150964, 36.8269825],
    [36.7148584, 36.8268873],
    [36.7143587, 36.8269587],
    [36.7134064, 36.8269074],
    [36.7123606, 36.8267519],
  ],
  Şanlıurfa: [
    [37.8361712, 37.2121339],
    [37.8363918, 37.2133629],
    [37.8386234, 37.2191045],
    [37.8402613, 37.2238913],
    [37.8484081, 37.2247089],
    [37.8564761, 37.2230686],
    [37.8633196, 37.2172092],
    [37.8697125, 37.2130502],
    [37.8747176, 37.2119372],
    [37.8786205, 37.2134996],
    [37.882397, 37.2176008],
    [37.8810237, 37.225119],
    [37.8745006, 37.2333198],
    [37.8645442, 37.2434329],
    [37.8640016, 37.2441193],
    [37.8488372, 37.2498554],
    [37.8417133, 37.2624255],
    [37.8412419, 37.2638532],
    [37.8408549, 37.2677535],
    [37.8430865, 37.2720566],
    [37.849953, 37.2803207],
    [37.8533004, 37.2874229],
    [37.8551028, 37.2960266],
    [37.8591369, 37.3052438],
    [37.8613491, 37.3142935],
    [37.8615401, 37.3150742],
    [37.8663467, 37.318419],
    [37.8791656, 37.3205412],
    [37.8854869, 37.3232653],
    [37.8865653, 37.3247144],
    [37.887976, 37.3266097],
    [37.8856586, 37.3307047],
    [37.8805465, 37.3327633],
    [37.8738785, 37.3369373],
    [37.8741573, 37.3426472],
    [37.8821395, 37.3480377],
    [37.8927825, 37.3526091],
    [37.9001834, 37.3573625],
    [37.9035114, 37.3594999],
    [37.9171378, 37.3733362],
    [37.9215357, 37.3787361],
    [37.9326858, 37.3855654],
    [37.9338954, 37.3863063],
    [37.9367278, 37.3910118],
    [37.9376493, 37.3935539],
    [37.9378183, 37.3940202],
    [37.9398177, 37.3995355],
    [37.9496883, 37.4077855],
    [37.9591366, 37.4127574],
    [37.9634212, 37.415012],
    [37.9683135, 37.4212154],
    [37.9697726, 37.428168],
    [37.9750941, 37.4349836],
    [37.9794358, 37.4385865],
    [37.9850505, 37.4398223],
    [37.9895137, 37.4403675],
    [37.995436, 37.4398223],
    [38.0050491, 37.4363467],
    [38.0073352, 37.4357991],
    [38.0104564, 37.4353925],
    [38.0193828, 37.4394134],
    [38.0283092, 37.4424801],
    [38.0371689, 37.4426835],
    [38.0403108, 37.4418418],
    [38.0450225, 37.4397497],
    [38.0516992, 37.4356008],
    [38.0588841, 37.4305205],
    [38.0596486, 37.4299798],
    [38.0626413, 37.4291222],
    [38.0669329, 37.4302809],
    [38.0701453, 37.4357315],
    [38.0750868, 37.4441156],
    [38.0809598, 37.4479079],
    [38.0838415, 37.4484767],
    [38.0911371, 37.4488174],
    [38.0974028, 37.4469095],
    [38.1120798, 37.4330753],
    [38.1178305, 37.4276908],
    [38.1216415, 37.4263341],
    [38.1277868, 37.4241464],
    [38.1343457, 37.4221931],
    [38.1380865, 37.421079],
    [38.1483412, 37.4184209],
    [38.1504461, 37.4178752],
    [38.1574934, 37.4194333],
    [38.1641086, 37.4218],
    [38.170273, 37.4191022],
    [38.1724188, 37.4167845],
    [38.1701872, 37.4116715],
    [38.1749937, 37.4096945],
    [38.1810877, 37.4102399],
    [38.1864426, 37.4134592],
    [38.1913312, 37.4190683],
    [38.1916233, 37.4194034],
    [38.1933615, 37.4233285],
    [38.1905291, 37.4293948],
    [38.1903875, 37.4352976],
    [38.1903574, 37.4365511],
    [38.1946157, 37.4475156],
    [38.1990396, 37.4511082],
    [38.2035924, 37.4517156],
    [38.2120586, 37.4526396],
    [38.2170508, 37.4522244],
    [38.2315561, 37.4450015],
    [38.2383368, 37.4430934],
    [38.2474348, 37.4435023],
    [38.2541296, 37.4513386],
    [38.2568023, 37.4568154],
    [38.2635745, 37.4671511],
    [38.2669315, 37.4721089],
    [38.2711719, 37.4757462],
    [38.2749865, 37.4766137],
    [38.2796493, 37.4772979],
    [38.2841704, 37.4766137],
    [38.2924273, 37.4755801],
    [38.2980494, 37.4738842],
    [38.3054564, 37.4725948],
    [38.3087371, 37.4748725],
    [38.309943, 37.4802724],
    [38.3106396, 37.4814756],
    [38.3100949, 37.48477],
    [38.3101696, 37.4874974],
    [38.3274486, 37.4901505],
    [38.3411025, 37.4874872],
    [38.3524855, 37.4863812],
    [38.3830415, 37.4811093],
    [38.3830656, 37.4811114],
    [38.3927684, 37.4819332],
    [38.4027703, 37.4872203],
    [38.4072184, 37.4880443],
    [38.4108275, 37.4897152],
    [38.4208295, 37.4899983],
    [38.4492454, 37.4888683],
    [38.4630505, 37.4883192],
    [38.4763794, 37.4905543],
    [38.4994354, 37.4986032],
    [38.5091624, 37.5044322],
    [38.5174864, 37.5119323],
    [38.5197214, 37.5149912],
    [38.5419384, 37.5347133],
    [38.5616604, 37.5463863],
    [38.5674895, 37.5508193],
    [38.5722195, 37.5566632],
    [38.5752715, 37.5641553],
    [38.5794294, 37.5705413],
    [38.5924984, 37.5786053],
    [38.5994415, 37.5819393],
    [38.6149974, 37.5869372],
    [38.6260985, 37.5969313],
    [38.6444394, 37.6208263],
    [38.6513744, 37.6241603],
    [38.6747205, 37.6266552],
    [38.6941524, 37.6274943],
    [38.7213744, 37.6258313],
    [38.7413784, 37.6263882],
    [38.7497175, 37.6272122],
    [38.7579494, 37.6292033],
    [38.7628704, 37.6319652],
    [38.7682035, 37.6349942],
    [38.7830504, 37.6405483],
    [38.7872085, 37.6413883],
    [38.8011014, 37.6433252],
    [38.8216625, 37.6430432],
    [38.8391645, 37.6463772],
    [38.847403, 37.6476225],
    [38.8532306, 37.6495603],
    [38.8568562, 37.6509607],
    [38.8565759, 37.6537197],
    [38.8570345, 37.655003],
    [38.8472134, 37.6627652],
    [38.8169456, 37.6695585],
    [38.8058314, 37.6819303],
    [38.81025, 37.6935728],
    [38.8269345, 37.6977763],
    [38.8494685, 37.6911083],
    [38.8583645, 37.6926168],
    [38.862711, 37.6946295],
    [38.8716211, 37.7010564],
    [38.8800765, 37.7065894],
    [38.8958756, 37.712528],
    [38.9126324, 37.7129401],
    [38.9148325, 37.7250536],
    [38.9030794, 37.7320201],
    [38.9053398, 37.7411985],
    [38.9241829, 37.7479292],
    [38.9586118, 37.7529805],
    [38.9645242, 37.7637199],
    [38.9552592, 37.7726931],
    [38.9547195, 37.7908322],
    [38.9541624, 37.7986072],
    [38.9507781, 37.8050391],
    [38.9413306, 37.8078403],
    [38.9304464, 37.8044439],
    [38.9211767, 37.8014599],
    [38.9146536, 37.7984759],
    [38.9016073, 37.7916935],
    [38.8968008, 37.7938639],
    [38.898165, 37.8045722],
    [38.9154603, 37.8211623],
    [38.9207978, 37.826398],
    [38.9413754, 37.8286147],
    [38.9680405, 37.8222203],
    [38.9727703, 37.8224942],
    [38.9766614, 37.8238752],
    [38.9783324, 37.8274912],
    [38.9791564, 37.8319392],
    [38.9785994, 37.8358303],
    [38.9760962, 37.8452763],
    [38.9738655, 37.8599718],
    [38.9835527, 37.8698433],
    [38.9924006, 37.8851361],
    [38.9772184, 37.8938822],
    [38.9736967, 37.9000873],
    [38.9960628, 37.9126713],
    [39.0100758, 37.9162384],
    [39.0248663, 37.9166176],
    [39.0314331, 37.9257468],
    [39.0393375, 37.9305378],
    [39.0391529, 37.9369998],
    [39.0442507, 37.9442939],
    [39.0464808, 37.9524618],
    [39.0572124, 37.9647213],
    [39.0555494, 37.9716563],
    [39.0563885, 37.9763793],
    [39.0591653, 37.9844433],
    [39.0672144, 37.9922103],
    [39.0813824, 37.9988783],
    [39.0883243, 38.0074306],
    [39.0971421, 38.0127192],
    [39.1120022, 38.0179778],
    [39.1279015, 38.0205168],
    [39.1375294, 38.0179548],
    [39.1315153, 38.0100854],
    [39.1332705, 38.0021331],
    [39.1455626, 37.9969034],
    [39.1522693, 37.9958583],
    [39.1530633, 37.9960781],
    [39.1553641, 37.9958804],
    [39.1636205, 37.9985131],
    [39.1664944, 38.0004536],
    [39.1699505, 38.0013538],
    [39.1728902, 38.0031292],
    [39.1772675, 38.0035857],
    [39.1813263, 38.0053064],
    [39.1911002, 38.0017106],
    [39.2087897, 37.999168],
    [39.2234423, 37.9972288],
    [39.2360209, 37.993342],
    [39.2511344, 37.9865103],
    [39.2604761, 37.9796562],
    [39.269656, 37.9703489],
    [39.2966334, 37.9624054],
    [39.3038681, 37.9602749],
    [39.3212037, 37.9586011],
    [39.3514497, 37.960178],
    [39.3751752, 37.9491196],
    [39.3797941, 37.940674],
    [39.3856691, 37.9327709],
    [39.4027274, 37.933814],
    [39.4188847, 37.9288544],
    [39.454388, 37.9204484],
    [39.4690683, 37.917058],
    [39.4777325, 37.9116525],
    [39.4891094, 37.9096528],
    [39.4935027, 37.9028936],
    [39.5086759, 37.8972505],
    [39.5160749, 37.8891501],
    [39.5218429, 37.8874614],
    [39.5256301, 37.8821987],
    [39.5221381, 37.8755034],
    [39.5324651, 37.8683801],
    [39.5402552, 37.859302],
    [39.5429062, 37.8515274],
    [39.5508593, 37.8498827],
    [39.5687269, 37.8547325],
    [39.5885583, 37.853064],
    [39.5939219, 37.8511461],
    [39.6035296, 37.8455876],
    [39.61426, 37.826361],
    [39.6260856, 37.8021105],
    [39.6440645, 37.7964232],
    [39.6629038, 37.794301],
    [39.6753892, 37.7959222],
    [39.681283, 37.8013698],
    [39.6867699, 37.8052843],
    [39.6907259, 37.8144934],
    [39.6956156, 37.8293489],
    [39.7019393, 37.8367496],
    [39.7094472, 37.8413952],
    [39.744851, 37.8566911],
    [39.7655243, 37.8632436],
    [39.773905, 37.8650526],
    [39.7827553, 37.86337],
    [39.7990138, 37.8589128],
    [39.8050117, 37.8542543],
    [39.8087489, 37.8488853],
    [39.8106766, 37.8461045],
    [39.8124454, 37.8380613],
    [39.8154559, 37.8210134],
    [39.8148159, 37.8209628],
    [39.8122933, 37.802011],
    [39.8292253, 37.7593105],
    [39.8418687, 37.741524],
    [39.8444885, 37.7309706],
    [39.8286969, 37.713606],
    [39.8310305, 37.6623313],
    [39.8370385, 37.6337176],
    [39.8332352, 37.6170246],
    [39.8353711, 37.580607],
    [39.8558938, 37.5625957],
    [39.8592644, 37.5559333],
    [39.8616921, 37.5480423],
    [39.8621776, 37.5341829],
    [39.8663046, 37.5295625],
    [39.873102, 37.5268672],
    [39.8881533, 37.523209],
    [39.8930096, 37.520925],
    [39.8911214, 37.5193082],
    [39.890821, 37.5186785],
    [39.8907137, 37.5175893],
    [39.890306, 37.5166362],
    [39.8899841, 37.5148151],
    [39.8891043, 37.5140491],
    [39.8888469, 37.5133683],
    [39.8884606, 37.5128577],
    [39.8884177, 37.512296],
    [39.8879242, 37.5116833],
    [39.8878598, 37.5111046],
    [39.886508, 37.5111897],
    [39.8854136, 37.5110876],
    [39.8845553, 37.510628],
    [39.8841047, 37.5099301],
    [39.8841691, 37.5086705],
    [39.8849416, 37.507479],
    [39.8857784, 37.5071726],
    [39.8862505, 37.5065428],
    [39.8857784, 37.504415],
    [39.8863148, 37.5039043],
    [39.8867225, 37.5029851],
    [39.8873234, 37.5025255],
    [39.8876667, 37.5016403],
    [39.8878383, 37.4986442],
    [39.8873448, 37.4974695],
    [39.8867869, 37.496397],
    [39.88698, 37.4954606],
    [39.886744, 37.4949159],
    [39.8866582, 37.4945413],
    [39.8879456, 37.4935198],
    [39.8883962, 37.4928729],
    [39.8895764, 37.4920216],
    [39.8900914, 37.4906936],
    [39.8898768, 37.4899785],
    [39.889791, 37.4890421],
    [39.8905849, 37.4880887],
    [39.8909497, 37.4862328],
    [39.8909068, 37.4847004],
    [39.8906064, 37.4833212],
    [39.8898983, 37.4817717],
    [39.8901558, 37.4811587],
    [39.8914647, 37.4800689],
    [39.8923015, 37.4788599],
    [39.8927521, 37.478349],
    [39.8937392, 37.4779403],
    [39.8946404, 37.4772422],
    [39.8952842, 37.4765099],
    [39.8955416, 37.4751987],
    [39.8957991, 37.4742791],
    [39.8963785, 37.4733595],
    [39.8959708, 37.4723037],
    [39.8959923, 37.470771],
    [39.8957133, 37.4695107],
    [39.8947477, 37.4686081],
    [39.8941684, 37.4666666],
    [39.8920226, 37.4645206],
    [39.8916364, 37.4635839],
    [39.8918724, 37.462596],
    [39.8915076, 37.4612675],
    [39.8904991, 37.4599731],
    [39.8908424, 37.458917],
    [39.8914003, 37.4580995],
    [39.8914861, 37.4569412],
    [39.8924303, 37.4542158],
    [39.894061, 37.449855],
    [39.8938465, 37.4479471],
    [39.9274492, 37.4457154],
    [39.9273634, 37.4452043],
    [39.9266767, 37.4444377],
    [39.9268055, 37.4439436],
    [39.9265694, 37.4435177],
    [39.9259043, 37.4430748],
    [39.9257755, 37.4427681],
    [39.9268055, 37.4425296],
    [39.928329, 37.4425978],
    [39.9309468, 37.4427852],
    [39.9316764, 37.4422741],
    [39.9333286, 37.4412178],
    [39.9361825, 37.4392244],
    [39.9365258, 37.4387474],
    [39.9384355, 37.4379977],
    [39.9390149, 37.4357658],
    [39.9404526, 37.4348968],
    [39.9431348, 37.4339427],
    [39.9433279, 37.4333122],
    [39.9431991, 37.4328692],
    [39.9433923, 37.4320173],
    [39.9427914, 37.4313698],
    [39.941976, 37.4308927],
    [39.942255, 37.4305689],
    [39.943521, 37.4298533],
    [39.9438643, 37.4294443],
    [39.9441218, 37.4286605],
    [39.9436497, 37.4270417],
    [39.9436927, 37.4259852],
    [39.9447012, 37.4247242],
    [39.9457097, 37.4228838],
    [39.9450445, 37.4205492],
    [39.9460816, 37.4187216],
    [39.9467621, 37.4183184],
    [39.9516105, 37.4145332],
    [39.9524474, 37.41288],
    [39.9519845, 37.4095714],
    [39.9525761, 37.4083293],
    [39.9518591, 37.4034535],
    [39.9500441, 37.3968917],
    [39.9508595, 37.395221],
    [39.9503875, 37.3942493],
    [39.9484777, 37.3929026],
    [39.9494272, 37.3903838],
    [39.9490615, 37.3884184],
    [39.9493452, 37.386635],
    [39.9518895, 37.3829971],
    [39.9517607, 37.3811386],
    [39.9530563, 37.3798998],
    [39.9568613, 37.3770835],
    [39.9588753, 37.3734156],
    [39.9622311, 37.3722978],
    [39.9627806, 37.3709347],
    [39.9625592, 37.3678992],
    [39.9663662, 37.3660953],
    [39.9683253, 37.3628028],
    [39.9682581, 37.35693],
    [39.967067, 37.3548641],
    [39.9667627, 37.3539949],
    [39.9670645, 37.3528267],
    [39.9678853, 37.3520659],
    [39.9689336, 37.3517477],
    [39.968738, 37.3508132],
    [39.9681536, 37.349911],
    [39.9667535, 37.349179],
    [39.9659039, 37.3469402],
    [39.9677126, 37.3465776],
    [39.9692572, 37.3458096],
    [39.9701672, 37.344435],
    [39.9698106, 37.3424504],
    [39.9702415, 37.3411486],
    [39.9720094, 37.3404458],
    [39.9729157, 37.3390046],
    [39.9731819, 37.3369588],
    [39.9744011, 37.3352973],
    [39.9761714, 37.334875],
    [39.9771817, 37.3338525],
    [39.9770931, 37.3318804],
    [39.9793145, 37.3301846],
    [39.9792202, 37.3290593],
    [39.9775307, 37.3284229],
    [39.9773605, 37.3272056],
    [39.9783383, 37.3257214],
    [39.9778056, 37.3228975],
    [39.9784864, 37.3219501],
    [39.9800848, 37.3215971],
    [39.9820489, 37.3188991],
    [39.9829097, 37.318265],
    [39.9839182, 37.3171275],
    [39.9843599, 37.3165197],
    [39.9847331, 37.3142508],
    [39.9845512, 37.3124892],
    [39.983758, 37.3106666],
    [39.9842731, 37.309574],
    [39.9859632, 37.3085126],
    [39.9869529, 37.3057933],
    [39.9863221, 37.3035714],
    [39.9845391, 37.3020113],
    [39.9838499, 37.3009148],
    [39.9846287, 37.2994691],
    [39.986141, 37.2982661],
    [39.9871139, 37.2959444],
    [39.9885671, 37.2953323],
    [39.9918557, 37.29611],
    [39.9932397, 37.2954494],
    [39.9940596, 37.2939274],
    [39.9933471, 37.2917307],
    [39.9936026, 37.2907819],
    [39.995992, 37.289585],
    [39.9967156, 37.2883615],
    [39.9977807, 37.2849721],
    [40.0001831, 37.2815738],
    [40.000272, 37.2786047],
    [40.0013201, 37.2770063],
    [40.0035512, 37.275799],
    [40.0039968, 37.2738843],
    [40.0050341, 37.2730589],
    [40.0065446, 37.2725639],
    [40.0088874, 37.2706279],
    [40.0088803, 37.2682153],
    [40.0074027, 37.2666244],
    [40.0077438, 37.2649279],
    [40.0102996, 37.2638056],
    [40.0112119, 37.2626439],
    [40.012197, 37.2577972],
    [40.0111091, 37.2559932],
    [40.0083876, 37.2548317],
    [40.0050585, 37.2532223],
    [40.0039496, 37.2516606],
    [40.0050553, 37.2502388],
    [40.0080285, 37.2493811],
    [40.0102871, 37.2474393],
    [40.0107338, 37.2443101],
    [40.0120623, 37.2408552],
    [40.0131394, 37.2386401],
    [40.0123322, 37.2366406],
    [40.0119812, 37.234475],
    [40.0131164, 37.2320416],
    [40.0156597, 37.2306451],
    [40.0202496, 37.2289253],
    [40.0198772, 37.2271464],
    [40.0179706, 37.2254836],
    [40.0118066, 37.224502],
    [40.0119036, 37.2220901],
    [40.0126159, 37.2210615],
    [40.013131, 37.2207058],
    [40.0143831, 37.2200468],
    [40.0158827, 37.218455],
    [40.0184013, 37.2174816],
    [40.0206667, 37.2171508],
    [40.0219907, 37.2169891],
    [40.0229752, 37.215911],
    [40.0237877, 37.2138704],
    [40.0224841, 37.2105087],
    [40.0202266, 37.2080756],
    [40.0194466, 37.2053576],
    [40.0190095, 37.2019532],
    [40.020313, 37.2007611],
    [40.0219763, 37.1986179],
    [40.0257443, 37.196591],
    [40.0323103, 37.1924617],
    [40.0328131, 37.1901787],
    [40.0311961, 37.1870948],
    [40.0317963, 37.1858442],
    [40.033182, 37.1858442],
    [40.0350112, 37.1864578],
    [40.0401933, 37.1908197],
    [40.041383, 37.1928339],
    [40.0429969, 37.1932901],
    [40.0448719, 37.192079],
    [40.0458982, 37.1906541],
    [40.0484669, 37.1895999],
    [40.0503935, 37.1887686],
    [40.0509203, 37.1869284],
    [40.0499619, 37.1845105],
    [40.04741, 37.1824461],
    [40.0468809, 37.1806997],
    [40.0484557, 37.1796839],
    [40.0550956, 37.1796839],
    [40.0596846, 37.1769101],
    [40.0606917, 37.176161],
    [40.0608905, 37.1744193],
    [40.0590765, 37.1727448],
    [40.0588845, 37.1713534],
    [40.0590512, 37.1705663],
    [40.0597259, 37.169967],
    [40.0644316, 37.1691302],
    [40.0692963, 37.1687205],
    [40.0706323, 37.1675914],
    [40.0717453, 37.1651523],
    [40.0717843, 37.1633164],
    [40.072919, 37.1620806],
    [40.0754486, 37.1618668],
    [40.0778939, 37.1624854],
    [40.0805405, 37.1637446],
    [40.081589, 37.1637446],
    [40.0823769, 37.1611053],
    [40.0799463, 37.158816],
    [40.0785372, 37.1577492],
    [40.0790167, 37.1566575],
    [40.0802162, 37.1557015],
    [40.0819764, 37.1553087],
    [40.087157, 37.156685],
    [40.0903229, 37.1586347],
    [40.0913871, 37.1599917],
    [40.0913871, 37.161342],
    [40.0900815, 37.163943],
    [40.0899337, 37.1667712],
    [40.0926026, 37.1685436],
    [40.0950492, 37.1689571],
    [40.0972876, 37.1683031],
    [40.0979898, 37.1672959],
    [40.0996865, 37.1678029],
    [40.1009139, 37.1674361],
    [40.1022767, 37.1656865],
    [40.100082, 37.1637044],
    [40.1005071, 37.1616718],
    [40.1026156, 37.1584848],
    [40.1026156, 37.1544166],
    [40.1026156, 37.1525788],
    [40.1034205, 37.1507126],
    [40.103113, 37.1500998],
    [40.1010806, 37.147612],
    [40.0989842, 37.1435785],
    [40.0981722, 37.1410533],
    [40.0979914, 37.1393107],
    [40.0960759, 37.1366814],
    [40.0954628, 37.1360554],
    [40.0956053, 37.1353952],
    [40.09645, 37.1355229],
    [40.0975335, 37.1360981],
    [40.0983549, 37.1361906],
    [40.1006815, 37.1357017],
    [40.1034649, 37.1379653],
    [40.1070993, 37.1384796],
    [40.1080792, 37.1381857],
    [40.1087023, 37.1367353],
    [40.1082169, 37.1345937],
    [40.1058024, 37.1327947],
    [40.1055937, 37.1315187],
    [40.1062133, 37.130585],
    [40.1073164, 37.1305309],
    [40.1088404, 37.1307291],
    [40.1101546, 37.1300511],
    [40.1104457, 37.1286585],
    [40.1110495, 37.1282841],
    [40.1122372, 37.1281065],
    [40.1140721, 37.1249465],
    [40.1160967, 37.1217755],
    [40.11764, 37.1204864],
    [40.1175637, 37.1192097],
    [40.1164131, 37.1181775],
    [40.1168972, 37.1164679],
    [40.1197212, 37.1157923],
    [40.1218735, 37.1161356],
    [40.1249722, 37.1166651],
    [40.1267698, 37.1152913],
    [40.1274368, 37.1136366],
    [40.1270761, 37.1105309],
    [40.1282349, 37.1095525],
    [40.1301842, 37.1101076],
    [40.1335485, 37.1129072],
    [40.1355268, 37.1124218],
    [40.136821, 37.110904],
    [40.1347767, 37.1088662],
    [40.1357973, 37.1063664],
    [40.1342277, 37.105353],
    [40.131868, 37.1031289],
    [40.1321235, 37.0996778],
    [40.1316267, 37.0984341],
    [40.1288586, 37.0960341],
    [40.1268498, 37.0942548],
    [40.1265665, 37.0921076],
    [40.1274327, 37.0903801],
    [40.1277893, 37.0883319],
    [40.1295197, 37.0877797],
    [40.134087, 37.0887628],
    [40.1400031, 37.0873645],
    [40.1428842, 37.0862345],
    [40.145502, 37.0825713],
    [40.1516008, 37.0789988],
    [40.1514303, 37.0785152],
    [40.1515404, 37.0775543],
    [40.1515499, 37.0763484],
    [40.1497275, 37.0746222],
    [40.1493971, 37.073513],
    [40.1495014, 37.072537],
    [40.1507013, 37.0703971],
    [40.153314, 37.0700027],
    [40.1575246, 37.0708715],
    [40.1605728, 37.0703377],
    [40.1612567, 37.0690644],
    [40.1611005, 37.06813],
    [40.1578745, 37.0670185],
    [40.1552243, 37.0647894],
    [40.1547952, 37.0626781],
    [40.1548667, 37.0605689],
    [40.1567242, 37.0586875],
    [40.1632705, 37.0550305],
    [40.1638123, 37.0537336],
    [40.1604336, 37.0518578],
    [40.1584957, 37.0488217],
    [40.1584241, 37.0460807],
    [40.1595626, 37.0444338],
    [40.1643492, 37.0414815],
    [40.1660445, 37.0394223],
    [40.1659704, 37.0371746],
    [40.1622459, 37.0324526],
    [40.1589327, 37.0303252],
    [40.1585723, 37.027844],
    [40.1592929, 37.0233781],
    [40.1607163, 37.0217303],
    [40.1621875, 37.0210255],
    [40.1624222, 37.0200262],
    [40.1610193, 37.0187293],
    [40.1599594, 37.0171496],
    [40.1612234, 37.015019],
    [40.1653436, 37.0143264],
    [40.1666879, 37.0131337],
    [40.1669355, 37.0112122],
    [40.1659942, 37.010692],
    [40.1657474, 37.0101608],
    [40.1660251, 37.0090644],
    [40.1693815, 37.0076684],
    [40.1736091, 37.007552],
    [40.174745, 37.0065844],
    [40.1754049, 37.0038911],
    [40.1755495, 37.0019858],
    [40.1763322, 37.0003947],
    [40.1782456, 36.9992062],
    [40.1827355, 36.9990327],
    [40.1844575, 36.9983151],
    [40.186067, 36.9948679],
    [40.1866965, 36.9935338],
    [40.1856387, 36.9928101],
    [40.1837718, 36.9925444],
    [40.1804173, 36.9928844],
    [40.1777018, 36.9908707],
    [40.1752116, 36.9865288],
    [40.1743818, 36.9847772],
    [40.1752427, 36.9833607],
    [40.1752551, 36.9819268],
    [40.1774296, 36.9787575],
    [40.178567, 36.9773946],
    [40.1842656, 36.9753727],
    [40.1863697, 36.9757799],
    [40.1907921, 36.9748577],
    [40.1944185, 36.9741624],
    [40.2038628, 36.971442],
    [40.2079093, 36.9706395],
    [40.2094146, 36.9699086],
    [40.2120281, 36.9675898],
    [40.214334, 36.9647688],
    [40.2171804, 36.9645982],
    [40.2198151, 36.9647736],
    [40.2263838, 36.9618578],
    [40.2290642, 36.9598318],
    [40.2365405, 36.9574538],
    [40.2384707, 36.9559113],
    [40.2384707, 36.9535943],
    [40.2352079, 36.9507001],
    [40.2346285, 36.9487444],
    [40.2348787, 36.946392],
    [40.2345199, 36.9450063],
    [40.2298515, 36.9435952],
    [40.2228759, 36.9434262],
    [40.2215195, 36.9425109],
    [40.2195669, 36.9420261],
    [40.2185164, 36.9408499],
    [40.2170779, 36.9391504],
    [40.213595, 36.936721],
    [40.2132581, 36.9355655],
    [40.2133626, 36.9325245],
    [40.21541, 36.9299233],
    [40.2177484, 36.9256279],
    [40.2179618, 36.9233482],
    [40.2195752, 36.9214474],
    [40.2207636, 36.9202937],
    [40.2228172, 36.9192675],
    [40.2253816, 36.9174224],
    [40.2271353, 36.9170218],
    [40.228654, 36.917359],
    [40.2299657, 36.9176387],
    [40.2325625, 36.9171543],
    [40.2332533, 36.9168636],
    [40.2341975, 36.9157189],
    [40.2340809, 36.9143599],
    [40.2328957, 36.9128238],
    [40.2333991, 36.911434],
    [40.2315733, 36.9098448],
    [40.229599, 36.9082921],
    [40.2266246, 36.9057699],
    [40.2239121, 36.9034618],
    [40.2219609, 36.9020103],
    [40.2206046, 36.9010347],
    [40.2194625, 36.9003923],
    [40.2175351, 36.8993453],
    [40.2103015, 36.8955144],
    [40.2073659, 36.8940162],
    [40.2014333, 36.8909056],
    [40.1985381, 36.8893815],
    [40.1972597, 36.8886855],
    [40.1956963, 36.8877319],
    [40.1951682, 36.8874004],
    [40.1933122, 36.8859728],
    [40.1915752, 36.8845689],
    [40.1883153, 36.8815945],
    [40.1867211, 36.8802145],
    [40.1856979, 36.8793103],
    [40.1849365, 36.8786916],
    [40.1840323, 36.8781919],
    [40.1828902, 36.8776922],
    [40.181867, 36.8773591],
    [40.1808438, 36.8771212],
    [40.1757042, 36.8760742],
    [40.166686, 36.8741468],
    [40.1589766, 36.8725764],
    [40.1518858, 36.8711011],
    [40.145485, 36.8697686],
    [40.1446284, 36.8696259],
    [40.143748, 36.8695307],
    [40.1380135, 36.8691976],
    [40.1291619, 36.8687217],
    [40.1221663, 36.8683172],
    [40.1140761, 36.8678888],
    [40.1120536, 36.8677461],
    [40.1107449, 36.8675319],
    [40.1099597, 36.8673178],
    [40.1067712, 36.8664374],
    [40.1015602, 36.8649859],
    [40.0977055, 36.8638676],
    [40.0928989, 36.8624875],
    [40.088949, 36.8613929],
    [40.0848326, 36.860227],
    [40.080264, 36.8589421],
    [40.078527, 36.8584424],
    [40.0776717, 36.8582132],
    [40.0764093, 36.8580379],
    [40.0753861, 36.8579189],
    [40.0742678, 36.8577761],
    [40.0731494, 36.8576334],
    [40.0709603, 36.857324],
    [40.0678908, 36.8569909],
    [40.0643454, 36.8565388],
    [40.0631081, 36.8563485],
    [40.0622753, 36.8561105],
    [40.0614901, 36.8558012],
    [40.0608238, 36.8554205],
    [40.0601814, 36.855016],
    [40.0597293, 36.8546353],
    [40.0593961, 36.8542783],
    [40.0588489, 36.8535645],
    [40.0586347, 36.8531718],
    [40.0584206, 36.8527793],
    [40.0581425, 36.8519219],
    [40.0579096, 36.8512645],
    [40.0577439, 36.8508815],
    [40.0575886, 36.8504466],
    [40.0573039, 36.8496701],
    [40.0569571, 36.8486555],
    [40.0566361, 36.847848],
    [40.0564808, 36.847615],
    [40.0562531, 36.8473251],
    [40.0556888, 36.8466625],
    [40.0554032, 36.8462144],
    [40.054933, 36.8454771],
    [40.0544723, 36.8448818],
    [40.0539634, 36.8443404],
    [40.0530116, 36.8432935],
    [40.0524178, 36.842716],
    [40.0523273, 36.8426299],
    [40.0516463, 36.8420412],
    [40.0507035, 36.8413661],
    [40.0500135, 36.8409854],
    [40.049438, 36.8406758],
    [40.0488451, 36.8403778],
    [40.0472771, 36.8389866],
    [40.0469916, 36.8386773],
    [40.0446359, 36.8367975],
    [40.0418519, 36.8346084],
    [40.0396, 36.8328026],
    [40.0393297, 36.8325859],
    [40.037093, 36.8308013],
    [40.0354395, 36.8294486],
    [40.0349437, 36.8290431],
    [40.0332383, 36.8278032],
    [40.0322627, 36.8272083],
    [40.0311682, 36.8267086],
    [40.0299308, 36.8262565],
    [40.0287649, 36.825971],
    [40.0273134, 36.8257568],
    [40.0223641, 36.8252096],
    [40.016701, 36.8245909],
    [40.0107762, 36.8239247],
    [40.0061362, 36.823425],
    [40.0036616, 36.8231394],
    [40.0020673, 36.8229729],
    [40.0003779, 36.8228063],
    [39.9991406, 36.8225446],
    [39.9979747, 36.8222114],
    [39.9962377, 36.821569],
    [39.9898845, 36.8192847],
    [39.9848163, 36.8174049],
    [39.9786297, 36.8151207],
    [39.9726334, 36.812884],
    [39.9688501, 36.8114801],
    [39.9671606, 36.8108852],
    [39.9629252, 36.8093148],
    [39.9580473, 36.8075302],
    [39.9537643, 36.8059597],
    [39.9520511, 36.8053649],
    [39.9505282, 36.80477],
    [39.9451744, 36.802795],
    [39.9393923, 36.8006535],
    [39.9335574, 36.7984755],
    [39.9305835, 36.7973563],
    [39.9285936, 36.7968629],
    [39.9242722, 36.7957527],
    [39.9179058, 36.7941814],
    [39.9124806, 36.7927775],
    [39.9108838, 36.7923229],
    [39.909078, 36.7919209],
    [39.9036555, 36.7904814],
    [39.896681, 36.7887086],
    [39.8901613, 36.7870668],
    [39.8825232, 36.7851157],
    [39.8742454, 36.7829576],
    [39.8669378, 36.7805947],
    [39.8608939, 36.7786197],
    [39.8541531, 36.7763601],
    [39.854009, 36.775851],
    [39.8533428, 36.7751134],
    [39.8518665, 36.7739166],
    [39.8510823, 36.7735667],
    [39.8497498, 36.7735429],
    [39.8482905, 36.7722287],
    [39.8478084, 36.7725969],
    [39.8467361, 36.7717193],
    [39.8453798, 36.7705534],
    [39.8441663, 36.7695064],
    [39.8424769, 36.7679597],
    [39.8409065, 36.7665797],
    [39.8396453, 36.7654851],
    [39.8383842, 36.7643668],
    [39.83748, 36.7637005],
    [39.8367186, 36.7632722],
    [39.835862, 36.7628201],
    [39.834815, 36.7624394],
    [39.8334587, 36.7620349],
    [39.8323642, 36.7617256],
    [39.8303374, 36.7611021],
    [39.8292359, 36.7608044],
    [39.8283022, 36.7604775],
    [39.8274969, 36.7601561],
    [39.8267831, 36.7597516],
    [39.826201, 36.7594019],
    [39.8230441, 36.7576404],
    [39.8219587, 36.7571099],
    [39.8207742, 36.7567011],
    [39.8196559, 36.7563917],
    [39.8183472, 36.7562014],
    [39.8166577, 36.7560586],
    [39.8121294, 36.7558338],
    [39.7725921, 36.7537306],
    [39.7696178, 36.7535878],
    [39.7645158, 36.7532971],
    [39.7594099, 36.7530405],
    [39.7590106, 36.7530131],
    [39.7474252, 36.7523639],
    [39.7447309, 36.7522172],
    [39.7420382, 36.7520689],
    [39.7366623, 36.7517794],
    [39.7291908, 36.7513511],
    [39.7222903, 36.7509942],
    [39.7164844, 36.7506611],
    [39.7144977, 36.7505324],
    [39.7128051, 36.7503957],
    [39.7117255, 36.7502328],
    [39.7107261, 36.749971],
    [39.709465, 36.7494713],
    [39.7047537, 36.747544],
    [39.6947362, 36.7433561],
    [39.6902628, 36.7415001],
    [39.6837431, 36.7387638],
    [39.6824388, 36.7382702],
    [39.6737255, 36.7354563],
    [39.6693235, 36.7341],
    [39.6666585, 36.7332196],
    [39.6590443, 36.730745],
    [39.6519026, 36.7283917],
    [39.6499785, 36.7277944],
    [39.6477284, 36.7270386],
    [39.6462533, 36.7265541],
    [39.6457193, 36.7264144],
    [39.6438395, 36.7258195],
    [39.6429353, 36.7255577],
    [39.6417694, 36.7251532],
    [39.6404607, 36.7248201],
    [39.6397944, 36.7247011],
    [39.6392233, 36.7246298],
    [39.6386523, 36.7245584],
    [39.6366059, 36.7245108],
    [39.6336792, 36.7243918],
    [39.6304193, 36.7243204],
    [39.6287923, 36.7242541],
    [39.628793, 36.7241215],
    [39.6258533, 36.7235711],
    [39.6213722, 36.7239101],
    [39.6213137, 36.7240299],
    [39.6202471, 36.7239992],
    [39.6172214, 36.7238794],
    [39.6170039, 36.723862],
    [39.6127756, 36.7237613],
    [39.6074218, 36.7236185],
    [39.6024964, 36.7234995],
    [39.5994041, 36.7233794],
    [39.5970236, 36.7232616],
    [39.5960718, 36.7231902],
    [39.5948991, 36.7229961],
    [39.5934544, 36.7227381],
    [39.5906704, 36.7222146],
    [39.5860067, 36.7213342],
    [39.5810812, 36.7204538],
    [39.5712523, 36.7186466],
    [39.5647819, 36.7174557],
    [39.5585477, 36.7162897],
    [39.5556924, 36.7157901],
    [39.5532415, 36.7153142],
    [39.5491488, 36.7145527],
    [39.5454369, 36.7138865],
    [39.544033, 36.7135296],
    [39.5408921, 36.7127681],
    [39.5372753, 36.7118402],
    [39.52909, 36.7097938],
    [39.521214, 36.7078189],
    [39.5132904, 36.7058201],
    [39.5095784, 36.7049159],
    [39.5073417, 36.7043686],
    [39.5020415, 36.7029979],
    [39.4969378, 36.7016697],
    [39.4949363, 36.7011449],
    [39.4936303, 36.7008103],
    [39.4926129, 36.7006091],
    [39.4912328, 36.7003474],
    [39.4897575, 36.7002284],
    [39.4826905, 36.6998001],
    [39.475632, 36.6993421],
    [39.4703887, 36.6989911],
    [39.4616323, 36.6984914],
    [39.4509481, 36.6978585],
    [39.4389689, 36.6970566],
    [39.4269634, 36.6963023],
    [39.4170887, 36.6956598],
    [39.4139478, 36.6954933],
    [39.4034544, 36.6948032],
    [39.3923697, 36.6940925],
    [39.3923697, 36.6936644],
    [39.3920604, 36.693593],
    [39.3916558, 36.6935692],
    [39.3912513, 36.6935216],
    [39.3909182, 36.6933789],
    [39.3905851, 36.6931647],
    [39.3900616, 36.692903],
    [39.3896571, 36.6927364],
    [39.3892764, 36.6926888],
    [39.3890622, 36.6927126],
    [39.3884198, 36.692903],
    [39.3851276, 36.6932665],
    [39.3850645, 36.6936087],
    [39.3833003, 36.6935183],
    [39.3768758, 36.6931138],
    [39.3705702, 36.6927093],
    [39.3660254, 36.6924238],
    [39.3631939, 36.6922572],
    [39.3611951, 36.692043],
    [39.3582446, 36.6915196],
    [39.3565076, 36.6911864],
    [39.350807, 36.6902353],
    [39.3425164, 36.6887832],
    [39.3383265, 36.6880572],
    [39.3341407, 36.6873317],
    [39.3253604, 36.6858088],
    [39.3193404, 36.6847381],
    [39.3129159, 36.6836197],
    [39.3078339, 36.6827401],
    [39.3055871, 36.6823348],
    [39.3031839, 36.6819303],
    [39.3008116, 36.6815258],
    [39.2976397, 36.6809785],
    [39.294475, 36.6803837],
    [39.2870273, 36.6791226],
    [39.2802697, 36.6779328],
    [39.2729885, 36.6766717],
    [39.2640527, 36.675106],
    [39.2563113, 36.6741657],
    [39.246172, 36.6729836],
    [39.2416748, 36.6724839],
    [39.2390829, 36.672164],
    [39.2346554, 36.6716511],
    [39.2299711, 36.6711146],
    [39.2253041, 36.6705565],
    [39.2172119, 36.6696281],
    [39.2130037, 36.6691518],
    [39.212182, 36.6691026],
    [39.2113605, 36.669105],
    [39.2103849, 36.6692002],
    [39.2094549, 36.6693321],
    [39.2087193, 36.6695095],
    [39.2054118, 36.670271],
    [39.1971551, 36.6721745],
    [39.1885652, 36.6741495],
    [39.1826166, 36.6755296],
    [39.1774294, 36.6767193],
    [39.1711714, 36.6781708],
    [39.1671025, 36.6791226],
    [39.1624625, 36.6802171],
    [39.1586554, 36.6810499],
    [39.154182, 36.6821207],
    [39.1504463, 36.6830011],
    [39.1480906, 36.6834532],
    [39.1426178, 36.684405],
    [39.1361695, 36.6854995],
    [39.1310061, 36.6863799],
    [39.1286028, 36.6867844],
    [39.1273893, 36.6870938],
    [39.1233918, 36.6882359],
    [39.114445, 36.6909009],
    [39.1122752, 36.6915303],
    [39.1089771, 36.6924867],
    [39.106569, 36.6931852],
    [39.09441, 36.6967544],
    [39.0838214, 36.6998715],
    [39.07378, 36.702822],
    [39.0685928, 36.7043211],
    [39.0670283, 36.7047158],
    [39.0652854, 36.7051555],
    [39.0636585, 36.7054465],
    [39.0622159, 36.7057266],
    [39.058522, 36.7063469],
    [39.0515797, 36.7071288],
    [39.0420142, 36.7082234],
    [39.0235258, 36.7103887],
    [39.0205753, 36.7107218],
    [39.0197529, 36.7107457],
    [39.0189481, 36.7107606],
    [39.0161257, 36.7105077],
    [39.0097373, 36.7099461],
    [39.0033728, 36.7093844],
    [38.9995097, 36.7090361],
    [38.996401, 36.7087521],
    [38.9920266, 36.7083652],
    [38.9865604, 36.7078411],
    [38.9807441, 36.7073404],
    [38.9703488, 36.7063977],
    [38.9644601, 36.7058058],
    [38.9607929, 36.7054896],
    [38.9591054, 36.7053705],
    [38.9589454, 36.7053702],
    [38.9588581, 36.7053674],
    [38.9587708, 36.7053644],
    [38.9549356, 36.7052386],
    [38.9537103, 36.7051999],
    [38.9537302, 36.7046753],
    [38.9496509, 36.7046382],
    [38.9493561, 36.7046355],
    [38.9492809, 36.7046348],
    [38.9492112, 36.7046342],
    [38.9487818, 36.7046303],
    [38.9484793, 36.7046275],
    [38.9447603, 36.7045937],
    [38.9445792, 36.7050288],
    [38.9423061, 36.7049888],
    [38.9421145, 36.7049854],
    [38.9374514, 36.7048696],
    [38.9321354, 36.7047501],
    [38.9281549, 36.7046169],
    [38.9253672, 36.7044109],
    [38.9183004, 36.7035984],
    [38.913525, 36.7030183],
    [38.9083837, 36.7024175],
    [38.9033583, 36.7018105],
    [38.9015114, 36.7016015],
    [38.90066, 36.7015051],
    [38.8952729, 36.7008946],
    [38.8936548, 36.7007995],
    [38.8906567, 36.7007281],
    [38.8873017, 36.700847],
    [38.8832804, 36.7010374],
    [38.8799016, 36.701204],
    [38.8710738, 36.7015133],
    [38.8634066, 36.7017483],
    [38.8538703, 36.7022747],
    [38.8476028, 36.7025737],
    [38.8413305, 36.702822],
    [38.8308421, 36.7033066],
    [38.8129169, 36.7039501],
    [38.7957782, 36.7047518],
    [38.7692944, 36.7058872],
    [38.7690617, 36.7058972],
    [38.7616091, 36.7062167],
    [38.7476019, 36.7067821],
    [38.7469761, 36.7068368],
    [38.7462818, 36.7068899],
    [38.7453558, 36.7070014],
    [38.7449239, 36.7070534],
    [38.7424433, 36.7074439],
    [38.7408697, 36.7077713],
    [38.7392713, 36.7081478],
    [38.738926, 36.708251],
    [38.737425, 36.7086996],
    [38.7357776, 36.7092703],
    [38.7336954, 36.71015],
    [38.7314303, 36.7112371],
    [38.7310513, 36.7111573],
    [38.7263186, 36.7138249],
    [38.7243492, 36.7150057],
    [38.7243467, 36.7153745],
    [38.6931618, 36.7335563],
    [38.6912138, 36.7347935],
    [38.6890116, 36.7364326],
    [38.6676098, 36.7533078],
    [38.6474427, 36.7691344],
    [38.6453394, 36.7707639],
    [38.6440888, 36.7717365],
    [38.6427683, 36.7728823],
    [38.6401987, 36.7755627],
    [38.6390243, 36.7766928],
    [38.6380845, 36.7776945],
    [38.6367862, 36.7787849],
    [38.6359941, 36.7793351],
    [38.6350738, 36.7799049],
    [38.6335601, 36.7808719],
    [38.6308608, 36.7825173],
    [38.6183849, 36.7904059],
    [38.6172231, 36.7910668],
    [38.6161306, 36.7916126],
    [38.6135767, 36.7928485],
    [38.6126002, 36.7933736],
    [38.6118163, 36.7939105],
    [38.6110519, 36.7945123],
    [38.6104375, 36.7950363],
    [38.609897, 36.795587],
    [38.6080853, 36.7975312],
    [38.6075022, 36.7981313],
    [38.6068983, 36.7986621],
    [38.6064675, 36.7990246],
    [38.6059928, 36.799384],
    [38.6053514, 36.7998149],
    [38.6047183, 36.8002144],
    [38.6040754, 36.8005849],
    [38.6032214, 36.8010176],
    [38.5972314, 36.8038168],
    [38.5963557, 36.8043763],
    [38.5952853, 36.8052278],
    [38.5944339, 36.8061278],
    [38.5938744, 36.806882],
    [38.5934122, 36.8076604],
    [38.5928253, 36.8090206],
    [38.5911843, 36.813466],
    [38.591045, 36.8139217],
    [38.5909398, 36.8142684],
    [38.590847, 36.8146984],
    [38.5907491, 36.8151852],
    [38.5907298, 36.8153756],
    [38.5906802, 36.8158235],
    [38.5906513, 36.816534],
    [38.5906511, 36.817125],
    [38.5906977, 36.8178074],
    [38.5908169, 36.8186391],
    [38.5910805, 36.8197545],
    [38.5912193, 36.8205698],
    [38.5913676, 36.8214687],
    [38.5913693, 36.8220539],
    [38.591298, 36.8225898],
    [38.5911037, 36.8232994],
    [38.5907424, 36.8240506],
    [38.590347, 36.8246159],
    [38.5897145, 36.8253214],
    [38.589348, 36.8256243],
    [38.5888606, 36.825964],
    [38.5881506, 36.8263551],
    [38.5873224, 36.826712],
    [38.5865681, 36.8269513],
    [38.5857953, 36.8270809],
    [38.5851989, 36.8271183],
    [38.5842811, 36.8271544],
    [38.5833167, 36.8270729],
    [38.5826861, 36.8269239],
    [38.5820806, 36.8267504],
    [38.5813126, 36.8264367],
    [38.5803116, 36.8260021],
    [38.5781945, 36.8250397],
    [38.5776173, 36.8248056],
    [38.5770161, 36.8246159],
    [38.5757268, 36.8243484],
    [38.5743414, 36.8241887],
    [38.5732699, 36.8242267],
    [38.5720535, 36.8243727],
    [38.570574, 36.8247188],
    [38.5694506, 36.8251755],
    [38.5683514, 36.8257643],
    [38.5672126, 36.8266107],
    [38.5663505, 36.827605],
    [38.5658818, 36.8283428],
    [38.5654492, 36.8290901],
    [38.560087, 36.8395498],
    [38.5596227, 36.840378],
    [38.5589172, 36.8413526],
    [38.5579738, 36.8423885],
    [38.5573007, 36.8430058],
    [38.5564, 36.8436604],
    [38.5556818, 36.8441501],
    [38.5544099, 36.8448105],
    [38.5524531, 36.8456302],
    [38.526162, 36.8548434],
    [38.5181723, 36.857643],
    [38.4840153, 36.8697288],
    [38.4663171, 36.8759069],
    [38.4405499, 36.8849007],
    [38.4264171, 36.8898332],
    [38.4050376, 36.8975023],
    [38.4009279, 36.8990039],
    [38.399956, 36.899359],
    [38.3952853, 36.9009402],
    [38.3924148, 36.9018646],
    [38.3907119, 36.9023268],
    [38.3887415, 36.9026674],
    [38.3871116, 36.902789],
    [38.3856657, 36.9028264],
    [38.3849709, 36.9028377],
    [38.3825139, 36.9026917],
    [38.3772351, 36.9021322],
    [38.3699614, 36.9013781],
    [38.3636366, 36.9007456],
    [38.3573117, 36.900478],
    [38.354136, 36.900315],
    [38.3528568, 36.9002493],
    [38.3528619, 36.9001051],
    [38.3525417, 36.9000878],
    [38.3525504, 36.8998368],
    [38.3503322, 36.8997765],
    [38.3500059, 36.8997676],
    [38.3499864, 36.899544],
    [38.3495974, 36.8995318],
    [38.3494849, 36.8995313],
    [38.3489557, 36.899502],
    [38.3489338, 36.8995236],
    [38.3489112, 36.8997171],
    [38.3454104, 36.8995772],
    [38.3454034, 36.8999502],
    [38.3447965, 36.8999407],
    [38.344704, 36.8999392],
    [38.3446097, 36.8999671],
    [38.3410616, 36.8998942],
    [38.3403805, 36.8999672],
    [38.339529, 36.9000645],
    [38.324372, 36.9026774],
    [38.3176109, 36.9037621],
    [38.304279, 36.9060287],
    [38.2954981, 36.9075327],
    [38.2862251, 36.9097676],
    [38.2738233, 36.9126412],
    [38.2654793, 36.9146603],
    [38.2639224, 36.9151469],
    [38.2624871, 36.9157794],
    [38.2610519, 36.9167038],
    [38.2590328, 36.918066],
    [38.2564299, 36.9195743],
    [38.2554325, 36.9200122],
    [38.2529998, 36.9208636],
    [38.2462614, 36.9230286],
    [38.2430503, 36.9240017],
    [38.2420286, 36.924099],
    [38.2408852, 36.9241233],
    [38.2397419, 36.9240017],
    [38.2388905, 36.9237828],
    [38.2377228, 36.9234179],
    [38.2368677, 36.9230167],
    [38.2317621, 36.9197184],
    [38.2314223, 36.919477],
    [38.2310331, 36.9191121],
    [38.2306196, 36.9187229],
    [38.2296708, 36.9174822],
    [38.2290596, 36.9167999],
    [38.2283815, 36.9163145],
    [38.2237595, 36.9143441],
    [38.2225675, 36.9137846],
    [38.2214972, 36.9130791],
    [38.2209376, 36.9126169],
    [38.2190645, 36.9106465],
    [38.2184225, 36.9102277],
    [38.2182131, 36.910014],
    [38.2175563, 36.9095761],
    [38.2167048, 36.9091626],
    [38.2153669, 36.908676],
    [38.2141992, 36.9083111],
    [38.2130315, 36.9081895],
    [38.2114746, 36.9081895],
    [38.2032766, 36.9083111],
    [38.1988492, 36.9084084],
    [38.1978518, 36.9085544],
    [38.1967815, 36.908822],
    [38.1957111, 36.9091626],
    [38.1943245, 36.9095761],
    [38.1932785, 36.9096977],
    [38.1919892, 36.9097707],
    [38.1908215, 36.9096734],
    [38.1885348, 36.9092355],
    [38.1871482, 36.9090409],
    [38.1835235, 36.9090409],
    [38.1828424, 36.9091139],
    [38.1822099, 36.9092112],
    [38.1815531, 36.9094058],
    [38.1758121, 36.9116195],
    [38.174839, 36.9118628],
    [38.1739146, 36.9119358],
    [38.1728199, 36.9118385],
    [38.1699894, 36.9111309],
    [38.1679546, 36.9106222],
    [38.1669329, 36.9103059],
    [38.1660328, 36.9099167],
    [38.1652057, 36.9093815],
    [38.1645003, 36.9086274],
    [38.1639651, 36.9078003],
    [38.1636975, 36.9067299],
    [38.1636732, 36.9059271],
    [38.1638434, 36.905173],
    [38.1642327, 36.9040783],
    [38.1644029, 36.9033485],
    [38.1643786, 36.9025458],
    [38.164184, 36.901743],
    [38.1638921, 36.9010132],
    [38.1634056, 36.9004294],
    [38.1627487, 36.8996266],
    [38.1623109, 36.8993103],
    [38.161727, 36.8990184],
    [38.1611675, 36.8987508],
    [38.1602918, 36.8985076],
    [38.1595863, 36.8983616],
    [38.15837, 36.89824],
    [38.1567401, 36.898021],
    [38.1559617, 36.8978264],
    [38.1542102, 36.8972669],
    [38.1520694, 36.8964398],
    [38.149371, 36.895552],
    [38.144041, 36.8938388],
    [38.1433374, 36.8937057],
    [38.1426409, 36.8936584],
    [38.1416616, 36.8937198],
    [38.140686, 36.8939102],
    [38.1398647, 36.8941836],
    [38.1387377, 36.8946862],
    [38.1376194, 36.89512],
    [38.1367461, 36.8953066],
    [38.1357639, 36.8953669],
    [38.1347883, 36.8953286],
    [38.1337142, 36.8950523],
    [38.1327148, 36.8945526],
    [38.1321556, 36.8942464],
    [38.1314375, 36.8936265],
    [38.130493, 36.8927391],
    [38.1295231, 36.89209],
    [38.1285746, 36.8917367],
    [38.1275632, 36.8915142],
    [38.1263038, 36.8914355],
    [38.1251515, 36.8913759],
    [38.1242774, 36.8911939],
    [38.1232921, 36.8908407],
    [38.1229641, 36.8906704],
    [38.1223679, 36.8903611],
    [38.1215852, 36.8896354],
    [38.1210651, 36.8889509],
    [38.1206484, 36.8882588],
    [38.1202114, 36.8876166],
    [38.1196579, 36.8869979],
    [38.1190596, 36.8865385],
    [38.1185191, 36.8862493],
    [38.1176666, 36.8859252],
    [38.11682, 36.8855583],
    [38.1160586, 36.8851062],
    [38.1154161, 36.8845113],
    [38.1149878, 36.884083],
    [38.1146214, 36.8835898],
    [38.114032, 36.8825465],
    [38.1134415, 36.8819137],
    [38.112756, 36.8813791],
    [38.1120146, 36.880976],
    [38.1112619, 36.8807118],
    [38.1102893, 36.880484],
    [38.1087467, 36.8802147],
    [38.1081644, 36.8800131],
    [38.1065972, 36.8784129],
    [38.104624, 36.8778305],
    [38.1039601, 36.8775443],
    [38.1030937, 36.877906],
    [38.1021237, 36.8785934],
    [38.1014305, 36.878573],
    [38.1007886, 36.8783409],
    [38.1003812, 36.8778858],
    [38.0998896, 36.8773938],
    [38.0996165, 36.8771588],
    [38.0989265, 36.8767067],
    [38.0978557, 36.8762784],
    [38.0969515, 36.8760642],
    [38.0959059, 36.8759184],
    [38.0949533, 36.8759699],
    [38.0942262, 36.8760913],
    [38.0935405, 36.8762699],
    [38.0927945, 36.8765964],
    [38.0919498, 36.8770257],
    [38.0913633, 36.8773002],
    [38.0906601, 36.8775428],
    [38.0900846, 36.87769],
    [38.0893892, 36.8777748],
    [38.0886986, 36.8778095],
    [38.088044, 36.8777675],
    [38.0872839, 36.8776593],
    [38.0850915, 36.8771062],
    [38.083232, 36.8766976],
    [38.0806998, 36.8764449],
    [38.0800569, 36.8763534],
    [38.0794411, 36.8762027],
    [38.0786099, 36.8758104],
    [38.0775205, 36.875149],
    [38.0770119, 36.8748404],
    [38.0737283, 36.8725614],
    [38.0722841, 36.8714319],
    [38.0717509, 36.8709624],
    [38.0712858, 36.870456],
    [38.0709091, 36.8698707],
    [38.0693768, 36.8665013],
    [38.0691559, 36.8660398],
    [38.068697, 36.8654491],
    [38.0684451, 36.8651717],
    [38.0675584, 36.8644747],
    [38.0665757, 36.8639587],
    [38.0660152, 36.8637864],
    [38.0653915, 36.8636101],
    [38.0647671, 36.8635025],
    [38.0640805, 36.8634651],
    [38.0634205, 36.8634668],
    [38.0625966, 36.8635853],
    [38.0619423, 36.8638003],
    [38.0613023, 36.864078],
    [38.061043, 36.8642517],
    [38.0595464, 36.8652377],
    [38.0584916, 36.8657054],
    [38.057787, 36.8658996],
    [38.0571253, 36.8660098],
    [38.0564374, 36.8660696],
    [38.0558914, 36.8660624],
    [38.0553215, 36.8660093],
    [38.0545898, 36.8658805],
    [38.0540461, 36.865725],
    [38.0530174, 36.8653226],
    [38.0523639, 36.865115],
    [38.0516671, 36.8649799],
    [38.0509244, 36.8648539],
    [38.0478299, 36.8648558],
    [38.0465688, 36.8647689],
    [38.0457415, 36.8645651],
    [38.0449111, 36.8642478],
    [38.0443305, 36.86389],
    [38.0436516, 36.8633817],
    [38.0422174, 36.8622969],
    [38.0414332, 36.8618711],
    [38.0406173, 36.8615286],
    [38.0399542, 36.8613701],
    [38.03828, 36.8611747],
    [38.0376512, 36.8610844],
    [38.0370155, 36.8609429],
    [38.0363807, 36.8607418],
    [38.0357642, 36.8604394],
    [38.0350933, 36.8599689],
    [38.0344777, 36.8593591],
    [38.0334353, 36.8578983],
    [38.0330759, 36.8573365],
    [38.0326461, 36.856852],
    [38.0319928, 36.8562468],
    [38.0313282, 36.855805],
    [38.0306143, 36.8553884],
    [38.03016, 36.8550774],
    [38.0296128, 36.8546015],
    [38.0292321, 36.854078],
    [38.0289278, 36.8535746],
    [38.0287024, 36.8530312],
    [38.0285018, 36.8521034],
    [38.0283293, 36.8480006],
    [38.0284499, 36.8466706],
    [38.0286725, 36.8456745],
    [38.029126, 36.844601],
    [38.0317098, 36.8400116],
    [38.0318708, 36.8394651],
    [38.0319352, 36.8388945],
    [38.0319758, 36.8381684],
    [38.0318685, 36.837584],
    [38.0316244, 36.8369221],
    [38.0311442, 36.8360356],
    [38.0303933, 36.8347662],
    [38.029846, 36.8340043],
    [38.0291927, 36.8333394],
    [38.0282934, 36.83268],
    [38.0250356, 36.8303399],
    [38.0214361, 36.8373447],
    [38.0217309, 36.8397802],
    [38.0221293, 36.8430715],
    [38.0241893, 36.8558465],
    [38.0269359, 36.8616151],
    [38.0292139, 36.8652602],
    [38.0305408, 36.8673834],
    [38.0370639, 36.8738378],
    [38.0383225, 36.8794322],
    [38.0386045, 36.8833233],
    [38.0361096, 36.8861003],
    [38.0312274, 36.8911384],
    [38.0214427, 36.898689],
    [38.0144046, 36.9082978],
    [38.0099414, 36.9169447],
    [38.0071948, 36.9305307],
    [38.0075381, 36.9402727],
    [38.0087398, 36.9476813],
    [38.0081181, 36.9535595],
    [38.0074337, 36.9581198],
    [38.0041049, 36.9623591],
    [37.990887, 36.9663367],
    [37.9780425, 36.9733272],
    [37.9730526, 36.9783323],
    [37.9663846, 36.9869313],
    [37.9641646, 36.9938893],
    [37.9623912, 36.9995211],
    [37.9636937, 37.0061988],
    [37.9671977, 37.0133665],
    [37.969974, 37.018712],
    [37.9677338, 37.0199532],
    [37.9670264, 37.0206783],
    [37.9654806, 37.0219182],
    [37.9639714, 37.0206969],
    [37.9624369, 37.0200283],
    [37.9610019, 37.0202113],
    [37.9601872, 37.0202857],
    [37.9603385, 37.0208728],
    [37.9594242, 37.0209572],
    [37.9597914, 37.0218028],
    [37.9583023, 37.0224587],
    [37.9582469, 37.0232945],
    [37.9583322, 37.0251351],
    [37.9567518, 37.0257091],
    [37.9558409, 37.0276465],
    [37.9539027, 37.0282922],
    [37.9528381, 37.0300547],
    [37.9521196, 37.0317277],
    [37.9516241, 37.033567],
    [37.951972, 37.0335831],
    [37.9549976, 37.0339314],
    [37.9535226, 37.0372188],
    [37.9565248, 37.0371164],
    [37.9565915, 37.0372419],
    [37.9561329, 37.0376272],
    [37.9571853, 37.03769],
    [37.9594263, 37.0376998],
    [37.9623222, 37.0371864],
    [37.9623454, 37.0368622],
    [37.9626273, 37.0362846],
    [37.9651482, 37.0358529],
    [37.9658562, 37.0358557],
    [37.9665769, 37.0364389],
    [37.9670876, 37.0366557],
    [37.9706431, 37.035559],
    [37.9718733, 37.035237],
    [37.9718451, 37.0345533],
    [37.9720866, 37.0342773],
    [37.9744196, 37.0332851],
    [37.9763196, 37.0320383],
    [37.9805873, 37.0400906],
    [37.9812739, 37.0470786],
    [37.977669, 37.0535177],
    [37.9721759, 37.0568055],
    [37.9623912, 37.0561206],
    [37.9602975, 37.0556319],
    [37.9526065, 37.0525587],
    [37.9441951, 37.0444752],
    [37.9398238, 37.0425423],
    [37.9335521, 37.0403647],
    [37.9227675, 37.0402752],
    [37.9133225, 37.0419382],
    [37.9078029, 37.0443382],
    [37.9031472, 37.0481669],
    [37.9021381, 37.0489966],
    [37.8949283, 37.0509146],
    [37.8868602, 37.0557096],
    [37.8772451, 37.0622421],
    [37.8741573, 37.0643397],
    [37.8695224, 37.0705035],
    [37.8666536, 37.0786053],
    [37.8691791, 37.0961122],
    [37.8743289, 37.1069282],
    [37.8758396, 37.1160185],
    [37.8762172, 37.1182902],
    [37.8783186, 37.1263882],
    [37.878156, 37.1284212],
    [37.8777621, 37.1333456],
    [37.8757022, 37.1405985],
    [37.8738829, 37.1428142],
    [37.8633426, 37.1544182],
    [37.8502963, 37.1693297],
    [37.8441616, 37.1791532],
    [37.8417133, 37.1877939],
    [37.8374217, 37.1977764],
    [37.8351901, 37.2066638],
    [37.8361712, 37.2121339],
  ],
  Adıyaman: [
    [37.4477643, 37.6244341],
    [37.4483416, 37.6268445],
    [37.4424778, 37.6332454],
    [37.4414148, 37.6375292],
    [37.4330263, 37.6397809],
    [37.4352478, 37.6417622],
    [37.4396034, 37.6454084],
    [37.4421344, 37.6496913],
    [37.4455407, 37.6507346],
    [37.4468041, 37.6531608],
    [37.4517539, 37.6544554],
    [37.4535934, 37.6597829],
    [37.4541779, 37.6676822],
    [37.457844, 37.6723778],
    [37.46261, 37.6747042],
    [37.4654386, 37.6786396],
    [37.4693786, 37.6822333],
    [37.4679078, 37.6934886],
    [37.4678193, 37.6992155],
    [37.4674344, 37.7057076],
    [37.4665942, 37.7131482],
    [37.4590618, 37.7211716],
    [37.4558607, 37.735212],
    [37.4562753, 37.7429178],
    [37.4533164, 37.7482244],
    [37.4587519, 37.7557777],
    [37.4670169, 37.7526227],
    [37.4836389, 37.7499436],
    [37.4878312, 37.754256],
    [37.4900957, 37.7591743],
    [37.4997577, 37.7647564],
    [37.5066723, 37.7715793],
    [37.5088116, 37.7762884],
    [37.5046461, 37.7813591],
    [37.5039755, 37.7858608],
    [37.5056514, 37.7900915],
    [37.5095301, 37.7915056],
    [37.507245, 37.7933724],
    [37.5035169, 37.7975966],
    [37.5019575, 37.8035768],
    [37.5096463, 37.8251974],
    [37.51077, 37.8398018],
    [37.5131197, 37.8447232],
    [37.5104636, 37.8581137],
    [37.5126089, 37.8612594],
    [37.5122003, 37.8648081],
    [37.5085225, 37.8675503],
    [37.5081139, 37.8744051],
    [37.5039253, 37.8779532],
    [37.4877839, 37.8819851],
    [37.4829289, 37.8820346],
    [37.4876377, 37.887571],
    [37.4954836, 37.8918926],
    [37.5014498, 37.8971811],
    [37.5092958, 37.9044686],
    [37.5193484, 37.9106589],
    [37.5302183, 37.9167844],
    [37.5356941, 37.9196213],
    [37.537819, 37.9195568],
    [37.5396171, 37.9163332],
    [37.5428862, 37.9114972],
    [37.551386, 37.9080796],
    [37.5585781, 37.9031787],
    [37.5640539, 37.9006636],
    [37.5699384, 37.8998899],
    [37.5828875, 37.9025804],
    [37.5853982, 37.9053042],
    [37.5858166, 37.9087296],
    [37.5873335, 37.9102153],
    [37.6005692, 37.9146944],
    [37.605235, 37.9180783],
    [37.6078503, 37.9213664],
    [37.5992688, 37.9297473],
    [37.5980429, 37.9330994],
    [37.6031101, 37.9358067],
    [37.6095666, 37.9371604],
    [37.6157545, 37.9441663],
    [37.6320791, 37.943453],
    [37.6453399, 37.947108],
    [37.6854658, 37.928154],
    [37.6884655, 37.9253849],
    [37.6844787, 37.9209772],
    [37.6823759, 37.9133597],
    [37.6877403, 37.9077729],
    [37.6935768, 37.9078745],
    [37.7022028, 37.9126148],
    [37.7048206, 37.9169485],
    [37.7069234, 37.9157297],
    [37.7141761, 37.9138337],
    [37.7305698, 37.9129872],
    [37.7549028, 37.9093305],
    [37.7770471, 37.8992397],
    [37.7893638, 37.8975803],
    [37.7949536, 37.8935418],
    [37.8134715, 37.8908832],
    [37.8231811, 37.889503],
    [37.8447354, 37.8942868],
    [37.8541016, 37.8865564],
    [37.8835116, 37.8901724],
    [37.8948712, 37.891569],
    [37.9103637, 37.8988756],
    [37.9262638, 37.8965982],
    [37.9655957, 37.8910102],
    [37.9746938, 37.887979],
    [38.0056796, 37.8883193],
    [38.0147767, 37.8884193],
    [38.0320609, 37.890536],
    [38.0333161, 37.8908747],
    [38.0553532, 37.8963611],
    [38.0594194, 37.8992735],
    [38.073914, 37.9082046],
    [38.0867457, 37.9241594],
    [38.0947923, 37.9396793],
    [38.1120014, 37.9449928],
    [38.1425142, 37.9590872],
    [38.1492519, 37.9655497],
    [38.1588649, 37.9774581],
    [38.1674229, 37.9854617],
    [38.1689301, 37.9879176],
    [38.1573093, 37.9923833],
    [38.145411, 37.9925693],
    [38.1475996, 37.9960947],
    [38.1544447, 38.0070856],
    [38.1546807, 38.0087002],
    [38.1523311, 38.010729],
    [38.1528354, 38.0125295],
    [38.1502712, 38.013434],
    [38.1460869, 38.0171362],
    [38.1447565, 38.0220301],
    [38.1422138, 38.0253854],
    [38.1398535, 38.0254953],
    [38.1379223, 38.0278447],
    [38.1332874, 38.0296617],
    [38.1335878, 38.0333209],
    [38.1315601, 38.0356871],
    [38.1342423, 38.0387038],
    [38.1342745, 38.0426075],
    [38.1332552, 38.0452015],
    [38.1342959, 38.0461816],
    [38.1348753, 38.0477024],
    [38.137064, 38.0665116],
    [38.1503355, 38.0731209],
    [38.1295434, 38.0931654],
    [38.1317705, 38.1052243],
    [38.1486511, 38.1076822],
    [38.1564819, 38.1131973],
    [38.1592659, 38.1193167],
    [38.1704974, 38.116019],
    [38.1876462, 38.1036802],
    [38.1937593, 38.1408404],
    [38.2052135, 38.1414425],
    [38.2154396, 38.1482475],
    [38.2175731, 38.1588566],
    [38.2229375, 38.1675959],
    [38.2594681, 38.202546],
    [38.263235, 38.2100626],
    [38.2695865, 38.2066904],
    [38.2749939, 38.2084777],
    [38.271346, 38.201497],
    [38.2565832, 38.1881071],
    [38.2555532, 38.1840256],
    [38.2580852, 38.1787631],
    [38.2641363, 38.1747147],
    [38.2672106, 38.1713934],
    [38.270788, 38.1675285],
    [38.2791566, 38.1664149],
    [38.2675695, 38.1617923],
    [38.2728052, 38.159599],
    [38.282032, 38.1565619],
    [38.2831049, 38.1543347],
    [38.2905292, 38.1506225],
    [38.2910871, 38.1495426],
    [38.2904219, 38.1484288],
    [38.2897353, 38.148142],
    [38.2880401, 38.1472307],
    [38.2846284, 38.1468257],
    [38.281517, 38.1463364],
    [38.2755947, 38.1463364],
    [38.2685136, 38.1471802],
    [38.2754659, 38.1401936],
    [38.2784271, 38.1380671],
    [38.2848215, 38.1330376],
    [38.2913446, 38.1331051],
    [38.2974913, 38.1324398],
    [38.2997131, 38.132835],
    [38.301773, 38.1326325],
    [38.3071374, 38.1415943],
    [38.3094978, 38.1492726],
    [38.3113861, 38.1572706],
    [38.3098197, 38.1584854],
    [38.307867, 38.1579961],
    [38.2908725, 38.1601389],
    [38.2996273, 38.1663306],
    [38.3150501, 38.167431],
    [38.3212995, 38.1646604],
    [38.3103131, 38.1485639],
    [38.307867, 38.1413243],
    [38.3049273, 38.1364976],
    [38.3038974, 38.1317211],
    [38.3043909, 38.1290711],
    [38.3052277, 38.1275182],
    [38.3062791, 38.1266068],
    [38.3072233, 38.1278389],
    [38.3077168, 38.1280415],
    [38.3090043, 38.1276195],
    [38.3136177, 38.1263704],
    [38.318789, 38.1260159],
    [38.3209884, 38.1252817],
    [38.3242929, 38.1199389],
    [38.3184457, 38.1166302],
    [38.3136606, 38.1144017],
    [38.3109355, 38.112342],
    [38.3064508, 38.1121647],
    [38.3035433, 38.1113966],
    [38.2987046, 38.1103836],
    [38.3016014, 38.1088303],
    [38.3014726, 38.1068718],
    [38.3035969, 38.1051496],
    [38.3069873, 38.1063146],
    [38.3089399, 38.1067029],
    [38.3112359, 38.1068886],
    [38.312813, 38.1065509],
    [38.3140683, 38.1069393],
    [38.3269858, 38.1128486],
    [38.339629, 38.1153325],
    [38.3454609, 38.1164782],
    [38.3506206, 38.1191935],
    [38.3552126, 38.1188825],
    [38.3596873, 38.1186897],
    [38.3650212, 38.117423],
    [38.3747582, 38.1176906],
    [38.3781194, 38.1187235],
    [38.3797073, 38.1165289],
    [38.3829259, 38.1156173],
    [38.3840847, 38.1140978],
    [38.3871746, 38.1133888],
    [38.3910459, 38.1094061],
    [38.3958081, 38.1064144],
    [38.3992338, 38.1058587],
    [38.4018253, 38.1070765],
    [38.4054162, 38.1059173],
    [38.4104346, 38.1035624],
    [38.4151265, 38.1025569],
    [38.4165286, 38.1076485],
    [38.4163141, 38.1159549],
    [38.422451, 38.1159887],
    [38.4291503, 38.1258615],
    [38.4417411, 38.1751234],
    [38.4562602, 38.1819215],
    [38.4822085, 38.187279],
    [38.4919597, 38.1902752],
    [38.4933471, 38.1780209],
    [38.4951495, 38.1745798],
    [38.4949779, 38.1695866],
    [38.4971236, 38.1657402],
    [38.4963512, 38.1607463],
    [38.496437, 38.1582492],
    [38.5073397, 38.1586112],
    [38.5083675, 38.1573044],
    [38.5155509, 38.1542657],
    [38.5175943, 38.1532886],
    [38.5218429, 38.154436],
    [38.5236454, 38.1542672],
    [38.5258129, 38.1553758],
    [38.5270786, 38.1546384],
    [38.5281944, 38.1545709],
    [38.5295677, 38.1536598],
    [38.5300183, 38.154031],
    [38.5308981, 38.1539298],
    [38.532958, 38.1523099],
    [38.5335803, 38.1524111],
    [38.5348248, 38.151635],
    [38.5364438, 38.1515692],
    [38.5372924, 38.1508925],
    [38.5381078, 38.1497451],
    [38.5407686, 38.1492726],
    [38.5416698, 38.1495088],
    [38.5423994, 38.1490701],
    [38.5434722, 38.1487326],
    [38.5438682, 38.1478183],
    [38.5445022, 38.1473489],
    [38.5455322, 38.1471801],
    [38.5460472, 38.1475851],
    [38.5475492, 38.1474501],
    [38.5480642, 38.1466739],
    [38.5488367, 38.1468089],
    [38.5493516, 38.1458639],
    [38.5668182, 38.1390798],
    [38.5706805, 38.133814],
    [38.5756587, 38.133544],
    [38.5824023, 38.1270023],
    [38.5800361, 38.1259147],
    [38.5739972, 38.1243472],
    [38.5856494, 38.1196298],
    [38.5983084, 38.1139981],
    [38.6095716, 38.1039552],
    [38.6131561, 38.0979226],
    [38.618322, 38.0951616],
    [38.6244106, 38.0914211],
    [38.6359402, 38.0875318],
    [38.638084, 38.0878895],
    [38.6385512, 38.0862448],
    [38.6388516, 38.0857634],
    [38.6391931, 38.0850937],
    [38.638916, 38.0847121],
    [38.6384332, 38.0841421],
    [38.642435, 38.0782475],
    [38.6538993, 38.0706117],
    [38.6665727, 38.0620732],
    [38.6680609, 38.0613039],
    [38.6700494, 38.0603777],
    [38.672331, 38.059813],
    [38.6734468, 38.0593272],
    [38.6751312, 38.0579672],
    [38.6755013, 38.055323],
    [38.6786127, 38.0549935],
    [38.6818528, 38.0537727],
    [38.6823034, 38.0510904],
    [38.6825234, 38.0509214],
    [38.6827487, 38.0508623],
    [38.6830598, 38.050896],
    [38.6835587, 38.0509256],
    [38.6839342, 38.0508454],
    [38.6844331, 38.0508285],
    [38.6851036, 38.0506848],
    [38.6854792, 38.0506891],
    [38.6856347, 38.0507102],
    [38.6858332, 38.0509594],
    [38.6859512, 38.0510608],
    [38.68608, 38.0510481],
    [38.6863536, 38.0506215],
    [38.6865574, 38.0504272],
    [38.6867076, 38.050406],
    [38.6872601, 38.050499],
    [38.6877966, 38.0505201],
    [38.6883545, 38.0504145],
    [38.6886281, 38.0506342],
    [38.68873, 38.0506341],
    [38.6888748, 38.0505623],
    [38.689025, 38.0502793],
    [38.6895561, 38.0502159],
    [38.6900711, 38.0502117],
    [38.6903822, 38.0503427],
    [38.6905914, 38.050406],
    [38.6907015, 38.0503804],
    [38.690967, 38.0501315],
    [38.691321, 38.0500301],
    [38.6915731, 38.050047],
    [38.6920184, 38.0498991],
    [38.6925334, 38.0498104],
    [38.6926728, 38.049595],
    [38.6927962, 38.04954],
    [38.6930323, 38.0496161],
    [38.6937028, 38.0499878],
    [38.6941588, 38.0501272],
    [38.6946523, 38.0500808],
    [38.6950332, 38.0500216],
    [38.6952907, 38.0500681],
    [38.6955374, 38.0502075],
    [38.695575, 38.0504609],
    [38.695634, 38.0506003],
    [38.6957949, 38.0506468],
    [38.696031, 38.0505961],
    [38.6962455, 38.0501864],
    [38.6961973, 38.0497301],
    [38.6960578, 38.0494091],
    [38.6957467, 38.0490796],
    [38.6957359, 38.0489022],
    [38.6959827, 38.0486571],
    [38.6962348, 38.0485727],
    [38.6963689, 38.0486065],
    [38.6964762, 38.0487923],
    [38.6966103, 38.0488261],
    [38.6966801, 38.048729],
    [38.696664, 38.0484628],
    [38.6967605, 38.0482854],
    [38.6972272, 38.0479474],
    [38.6975759, 38.047694],
    [38.6981874, 38.047542],
    [38.6986971, 38.0473687],
    [38.6992121, 38.0471279],
    [38.7000757, 38.0467984],
    [38.7009555, 38.0465153],
    [38.7011808, 38.0464815],
    [38.7015617, 38.0465407],
    [38.7021357, 38.0465618],
    [38.7028384, 38.0466463],
    [38.7033856, 38.0466885],
    [38.703863, 38.0467012],
    [38.7045658, 38.046735],
    [38.7051451, 38.0466716],
    [38.7056172, 38.0465449],
    [38.7062234, 38.0463041],
    [38.7067491, 38.0461394],
    [38.7069529, 38.0461098],
    [38.707146, 38.0461605],
    [38.7072748, 38.0461182],
    [38.7072855, 38.0459957],
    [38.7073445, 38.0458521],
    [38.7074679, 38.0457465],
    [38.7077576, 38.0457042],
    [38.7080687, 38.0457887],
    [38.7082994, 38.0458521],
    [38.7084228, 38.0458225],
    [38.7085086, 38.0455606],
    [38.7086159, 38.0453198],
    [38.708809, 38.0452057],
    [38.7091094, 38.045193],
    [38.7093937, 38.0452902],
    [38.7096137, 38.0454465],
    [38.7098014, 38.0454381],
    [38.7099141, 38.0452522],
    [38.7100428, 38.0451001],
    [38.7102789, 38.0450579],
    [38.7109494, 38.0451592],
    [38.7113249, 38.0451592],
    [38.7116092, 38.0452606],
    [38.7118453, 38.0453832],
    [38.7120169, 38.045455],
    [38.7122101, 38.0454592],
    [38.7124354, 38.0453071],
    [38.7129611, 38.045155],
    [38.7134707, 38.0450367],
    [38.7138194, 38.0450241],
    [38.7141734, 38.0450874],
    [38.7144417, 38.0452184],
    [38.7146777, 38.0453029],
    [38.7154394, 38.0454212],
    [38.715933, 38.0455648],
    [38.7168717, 38.0459028],
    [38.7172902, 38.0462745],
    [38.7176871, 38.0466505],
    [38.7178963, 38.0467984],
    [38.7180787, 38.0468111],
    [38.7183845, 38.0467688],
    [38.7185347, 38.0468195],
    [38.7186313, 38.0468955],
    [38.7188941, 38.0469336],
    [38.7193501, 38.0471321],
    [38.7208607, 38.0511632],
    [38.7218284, 38.0526027],
    [38.7311411, 38.0531096],
    [38.7318277, 38.0539037],
    [38.7359905, 38.0464182],
    [38.7387799, 38.0431906],
    [38.7417943, 38.0411508],
    [38.7443589, 38.0388644],
    [38.746376, 38.0376138],
    [38.7473201, 38.0358561],
    [38.7545299, 38.0288927],
    [38.7637138, 38.0191902],
    [38.7568903, 38.0095202],
    [38.7515258, 37.9927807],
    [38.760581, 37.9870309],
    [38.7821245, 37.9974647],
    [38.791418, 38.0008306],
    [38.7943125, 38.0023176],
    [38.7974667, 38.0059697],
    [38.8100301, 38.0053484],
    [38.8255763, 38.0094188],
    [38.8337194, 38.0184295],
    [38.8375316, 38.0212809],
    [38.8389551, 38.0241261],
    [38.8465171, 38.0299924],
    [38.8472271, 38.0313012],
    [38.8508963, 38.035366],
    [38.8566899, 38.0369377],
    [38.8623118, 38.0422273],
    [38.8637924, 38.0433258],
    [38.8703752, 38.0429712],
    [38.8760876, 38.0462492],
    [38.8838768, 38.0473814],
    [38.8846278, 38.0483952],
    [38.8891554, 38.0502709],
    [38.8946056, 38.0494598],
    [38.9007854, 38.0522816],
    [38.8993573, 38.0542941],
    [38.8962364, 38.054664],
    [38.8908505, 38.0578911],
    [38.8835871, 38.0652234],
    [38.8738344, 38.0723355],
    [38.863084, 38.0760349],
    [38.88201, 38.0795987],
    [38.8948953, 38.0838972],
    [38.9009303, 38.0849992],
    [38.904401, 38.085451],
    [38.9128768, 38.087123],
    [38.9212453, 38.0880476],
    [38.9247171, 38.0888516],
    [38.9276933, 38.088149],
    [38.9300913, 38.0880814],
    [38.9330186, 38.0882563],
    [38.9358043, 38.0588542],
    [38.939495, 38.0608985],
    [38.9455032, 38.0622163],
    [38.9504814, 38.0627907],
    [38.9524126, 38.0640071],
    [38.9554596, 38.0644125],
    [38.9574336, 38.0656626],
    [38.9587211, 38.0670986],
    [38.9618754, 38.0680785],
    [38.9639568, 38.0685008],
    [38.9671969, 38.0698015],
    [38.9715459, 38.0715619],
    [38.9788807, 38.0719975],
    [38.9818096, 38.0716597],
    [38.9858437, 38.0721665],
    [38.9939319, 38.0740029],
    [39.0023875, 38.0703336],
    [39.0038574, 38.0716428],
    [39.007357, 38.071043],
    [39.0102088, 38.0728548],
    [39.012476, 38.0728244],
    [39.0144145, 38.074506],
    [39.0237441, 38.0752601],
    [39.030919, 38.081002],
    [39.036988, 38.0836269],
    [39.0403247, 38.087883],
    [39.0398097, 38.0892932],
    [39.042213, 38.0893185],
    [39.0437189, 38.0929893],
    [39.0466869, 38.0938529],
    [39.0517562, 38.1022617],
    [39.0525116, 38.102906],
    [39.0543597, 38.1050589],
    [39.0562499, 38.1134697],
    [39.0600308, 38.1256334],
    [39.0612084, 38.1288939],
    [39.0648578, 38.1328576],
    [39.0662134, 38.1353076],
    [39.0678844, 38.1423754],
    [39.0686679, 38.1438677],
    [39.0687427, 38.1450934],
    [39.0729222, 38.1458286],
    [39.072639, 38.1486461],
    [39.0725653, 38.149553],
    [39.0725337, 38.1501377],
    [39.0724753, 38.1508365],
    [39.0724217, 38.1515569],
    [39.0727883, 38.1518644],
    [39.0733756, 38.152331],
    [39.0735811, 38.1524377],
    [39.0745302, 38.1527653],
    [39.0784257, 38.1539035],
    [39.0837934, 38.1552116],
    [39.0874856, 38.1586006],
    [39.0900999, 38.1585777],
    [39.095156, 38.1595145],
    [39.1003432, 38.1618056],
    [39.1068045, 38.1638463],
    [39.1106695, 38.164769],
    [39.1134593, 38.1666491],
    [39.1153692, 38.1683553],
    [39.1219363, 38.1696787],
    [39.1259413, 38.1704074],
    [39.1317483, 38.1730787],
    [39.1319333, 38.173426],
    [39.1320483, 38.1742323],
    [39.1321562, 38.1752664],
    [39.1333936, 38.1765768],
    [39.1349495, 38.1775812],
    [39.1354741, 38.1782934],
    [39.1361756, 38.178616],
    [39.1367727, 38.1787397],
    [39.140468, 38.177239],
    [39.144182, 38.1784627],
    [39.1473599, 38.178241],
    [39.1475978, 38.17808],
    [39.1481636, 38.1781624],
    [39.1490809, 38.1782869],
    [39.154183, 38.1786864],
    [39.1596511, 38.1799327],
    [39.1625579, 38.1808915],
    [39.1649972, 38.1821378],
    [39.1658306, 38.1826011],
    [39.1663591, 38.1835598],
    [39.1663894, 38.1852472],
    [39.1663008, 38.1855472],
    [39.1741635, 38.1912495],
    [39.187092, 38.2018767],
    [39.1952249, 38.20683],
    [39.203274, 38.2058703],
    [39.2069683, 38.2076169],
    [39.2134447, 38.2068102],
    [39.2200999, 38.2077978],
    [39.2264891, 38.205923],
    [39.2279589, 38.2033818],
    [39.2319327, 38.2006845],
    [39.2334622, 38.1916129],
    [39.2383251, 38.191381],
    [39.2436477, 38.1892529],
    [39.2530368, 38.1829581],
    [39.2567722, 38.1778616],
    [39.2585028, 38.1698656],
    [39.2583911, 38.1659156],
    [39.2632664, 38.1527768],
    [39.2629314, 38.1461334],
    [39.2535531, 38.1330203],
    [39.2465566, 38.1254382],
    [39.2378482, 38.1214273],
    [39.2361297, 38.1148865],
    [39.2350935, 38.1061437],
    [39.2274856, 38.0883254],
    [39.2260922, 38.0805047],
    [39.2223263, 38.0717694],
    [39.201536, 38.0629103],
    [39.1966009, 38.0487133],
    [39.1830143, 38.0417499],
    [39.176696, 38.0293465],
    [39.1753595, 38.0283615],
    [39.1594208, 38.0180994],
    [39.1505093, 38.015617],
    [39.1375294, 38.0179548],
    [39.1279015, 38.0205168],
    [39.1120022, 38.0179778],
    [39.0971421, 38.0127192],
    [39.0883243, 38.0074306],
    [39.0813824, 37.9988783],
    [39.0672144, 37.9922103],
    [39.0591653, 37.9844433],
    [39.0563885, 37.9763793],
    [39.0555494, 37.9716563],
    [39.0572124, 37.9647213],
    [39.0464808, 37.9524618],
    [39.0442507, 37.9442939],
    [39.0391529, 37.9369998],
    [39.0393375, 37.9305378],
    [39.0314331, 37.9257468],
    [39.0248663, 37.9166176],
    [39.0100758, 37.9162384],
    [38.9960628, 37.9126713],
    [38.9736967, 37.9000873],
    [38.9772184, 37.8938822],
    [38.9924006, 37.8851361],
    [38.9835527, 37.8698433],
    [38.9738655, 37.8599718],
    [38.9760962, 37.8452763],
    [38.9785994, 37.8358303],
    [38.9791564, 37.8319392],
    [38.9783324, 37.8274912],
    [38.9766614, 37.8238752],
    [38.9727703, 37.8224942],
    [38.9680405, 37.8222203],
    [38.9413754, 37.8286147],
    [38.9207978, 37.826398],
    [38.9154603, 37.8211623],
    [38.898165, 37.8045722],
    [38.8968008, 37.7938639],
    [38.9016073, 37.7916935],
    [38.9146536, 37.7984759],
    [38.9211767, 37.8014599],
    [38.9304464, 37.8044439],
    [38.9413306, 37.8078403],
    [38.9507781, 37.8050391],
    [38.9541624, 37.7986072],
    [38.9547195, 37.7908322],
    [38.9552592, 37.7726931],
    [38.9645242, 37.7637199],
    [38.9586118, 37.7529805],
    [38.9241829, 37.7479292],
    [38.9053398, 37.7411985],
    [38.9030794, 37.7320201],
    [38.9148325, 37.7250536],
    [38.9126324, 37.7129401],
    [38.8958756, 37.712528],
    [38.8800765, 37.7065894],
    [38.8716211, 37.7010564],
    [38.862711, 37.6946295],
    [38.8583645, 37.6926168],
    [38.8494685, 37.6911083],
    [38.8269345, 37.6977763],
    [38.81025, 37.6935728],
    [38.8058314, 37.6819303],
    [38.8169456, 37.6695585],
    [38.8472134, 37.6627652],
    [38.8570345, 37.655003],
    [38.8565759, 37.6537197],
    [38.8568562, 37.6509607],
    [38.8532306, 37.6495603],
    [38.847403, 37.6476225],
    [38.8391645, 37.6463772],
    [38.8216625, 37.6430432],
    [38.8011014, 37.6433252],
    [38.7872085, 37.6413883],
    [38.7830504, 37.6405483],
    [38.7682035, 37.6349942],
    [38.7628704, 37.6319652],
    [38.7579494, 37.6292033],
    [38.7497175, 37.6272122],
    [38.7413784, 37.6263882],
    [38.7213744, 37.6258313],
    [38.6941524, 37.6274943],
    [38.6747205, 37.6266552],
    [38.6513744, 37.6241603],
    [38.6444394, 37.6208263],
    [38.6260985, 37.5969313],
    [38.6149974, 37.5869372],
    [38.5994415, 37.5819393],
    [38.5924984, 37.5786053],
    [38.5794294, 37.5705413],
    [38.5752715, 37.5641553],
    [38.5722195, 37.5566632],
    [38.5674895, 37.5508193],
    [38.5616604, 37.5463863],
    [38.5419384, 37.5347133],
    [38.5197214, 37.5149912],
    [38.5174864, 37.5119323],
    [38.5091624, 37.5044322],
    [38.4994354, 37.4986032],
    [38.4763794, 37.4905543],
    [38.4630505, 37.4883192],
    [38.4492454, 37.4888683],
    [38.4208295, 37.4899983],
    [38.4108275, 37.4897152],
    [38.4072184, 37.4880443],
    [38.4027703, 37.4872203],
    [38.3927684, 37.4819332],
    [38.3830656, 37.4811114],
    [38.3830415, 37.4811093],
    [38.3524855, 37.4863812],
    [38.3411025, 37.4874872],
    [38.3274486, 37.4901505],
    [38.3101696, 37.4874974],
    [38.3100949, 37.48477],
    [38.3106396, 37.4814756],
    [38.309943, 37.4802724],
    [38.3087371, 37.4748725],
    [38.3054564, 37.4725948],
    [38.2980494, 37.4738842],
    [38.2924273, 37.4755801],
    [38.2841704, 37.4766137],
    [38.2796493, 37.4772979],
    [38.2749865, 37.4766137],
    [38.2711719, 37.4757462],
    [38.2669315, 37.4721089],
    [38.2635745, 37.4671511],
    [38.2568023, 37.4568154],
    [38.2541296, 37.4513386],
    [38.2474348, 37.4435023],
    [38.2383368, 37.4430934],
    [38.2315561, 37.4450015],
    [38.2170508, 37.4522244],
    [38.2120586, 37.4526396],
    [38.2035924, 37.4517156],
    [38.1990396, 37.4511082],
    [38.1946157, 37.4475156],
    [38.1903574, 37.4365511],
    [38.1903875, 37.4352976],
    [38.1905291, 37.4293948],
    [38.1933615, 37.4233285],
    [38.1916233, 37.4194034],
    [38.1913312, 37.4190683],
    [38.1864426, 37.4134592],
    [38.1810877, 37.4102399],
    [38.1749937, 37.4096945],
    [38.1701872, 37.4116715],
    [38.1724188, 37.4167845],
    [38.170273, 37.4191022],
    [38.1641086, 37.4218],
    [38.1574934, 37.4194333],
    [38.1504461, 37.4178752],
    [38.1483412, 37.4184209],
    [38.1380865, 37.421079],
    [38.1343457, 37.4221931],
    [38.1277868, 37.4241464],
    [38.1216415, 37.4263341],
    [38.1178305, 37.4276908],
    [38.1120798, 37.4330753],
    [38.0974028, 37.4469095],
    [38.0911371, 37.4488174],
    [38.0838415, 37.4484767],
    [38.0809598, 37.4479079],
    [38.0810748, 37.4540667],
    [38.0801251, 37.4585509],
    [38.0796738, 37.4610584],
    [38.0760636, 37.463924],
    [38.0631272, 37.4684609],
    [38.0531507, 37.4708624],
    [38.0416631, 37.4729179],
    [38.0258471, 37.4733466],
    [38.0000034, 37.4754649],
    [37.9835159, 37.4745578],
    [37.9677736, 37.4729765],
    [37.9308229, 37.4678043],
    [37.8977106, 37.466166],
    [37.8610024, 37.4666591],
    [37.8376565, 37.4697604],
    [37.8173538, 37.4758988],
    [37.797996, 37.4859832],
    [37.7850846, 37.4957965],
    [37.7713513, 37.5045566],
    [37.7579785, 37.5149596],
    [37.7478151, 37.5221903],
    [37.7314597, 37.527081],
    [37.719488, 37.5265555],
    [37.7011166, 37.5206699],
    [37.6899901, 37.5193538],
    [37.6828534, 37.5186852],
    [37.6710903, 37.5169996],
    [37.6591771, 37.5144651],
    [37.6494776, 37.5116811],
    [37.6387739, 37.5135556],
    [37.6385915, 37.513113],
    [37.6385164, 37.5127045],
    [37.6385892, 37.5119571],
    [37.6386344, 37.5116067],
    [37.6386559, 37.5106365],
    [37.6387429, 37.50961],
    [37.6388168, 37.509062],
    [37.6386237, 37.5088067],
    [37.637937, 37.5080237],
    [37.6358528, 37.50608],
    [37.634536, 37.5049853],
    [37.6336777, 37.5045257],
    [37.633034, 37.5040661],
    [37.6323688, 37.5039809],
    [37.6319933, 37.5036235],
    [37.631489, 37.503666],
    [37.630223, 37.5038533],
    [37.6297402, 37.5039043],
    [37.6294184, 37.5038107],
    [37.6287235, 37.5034082],
    [37.627809, 37.5028745],
    [37.6263606, 37.5025255],
    [37.6253307, 37.5022787],
    [37.6249981, 37.5019042],
    [37.6244402, 37.5015296],
    [37.6235604, 37.5012913],
    [37.6233566, 37.5008998],
    [37.6232922, 37.5004912],
    [37.6301035, 37.4869958],
    [37.6240984, 37.4865489],
    [37.5850224, 37.4739897],
    [37.584545, 37.4742578],
    [37.583456, 37.4743132],
    [37.5828123, 37.4741599],
    [37.581675, 37.4743643],
    [37.5798082, 37.4741599],
    [37.5795026, 37.474367],
    [37.5839196, 37.4922894],
    [37.5907609, 37.508912],
    [37.5813531, 37.5210816],
    [37.5783759, 37.5426586],
    [37.5845158, 37.5700428],
    [37.5870766, 37.5760561],
    [37.5863239, 37.5831632],
    [37.5806673, 37.5866875],
    [37.5725263, 37.5940898],
    [37.5644118, 37.5987921],
    [37.5632483, 37.6055032],
    [37.5583011, 37.6068939],
    [37.5569446, 37.6087903],
    [37.5538327, 37.6103705],
    [37.549843, 37.6081582],
    [37.5422088, 37.6069297],
    [37.5273919, 37.5907473],
    [37.5175267, 37.5906462],
    [37.512798, 37.5892747],
    [37.5077121, 37.5859672],
    [37.508188, 37.5790962],
    [37.4986735, 37.5755113],
    [37.4941502, 37.57069],
    [37.49571, 37.5609229],
    [37.4863514, 37.5612938],
    [37.4713595, 37.5625519],
    [37.4584318, 37.5736571],
    [37.4506751, 37.5740042],
    [37.4526071, 37.5785963],
    [37.4503923, 37.584078],
    [37.4512338, 37.5938317],
    [37.4495824, 37.6001686],
    [37.4514192, 37.6064322],
    [37.4578566, 37.610563],
    [37.4573845, 37.6114979],
    [37.4573416, 37.6136226],
    [37.4576206, 37.6135886],
    [37.4587793, 37.6134356],
    [37.4603457, 37.6133847],
    [37.4609894, 37.6134866],
    [37.4624056, 37.6131977],
    [37.4623627, 37.6134696],
    [37.4668045, 37.6131977],
    [37.4674267, 37.6134356],
    [37.4675555, 37.6136736],
    [37.4709243, 37.6131977],
    [37.4726195, 37.6128747],
    [37.473628, 37.6129427],
    [37.4746794, 37.6125178],
    [37.4735851, 37.6120588],
    [37.4730701, 37.6116169],
    [37.474615, 37.6114809],
    [37.4783701, 37.610971],
    [37.4781341, 37.6118039],
    [37.4811167, 37.6135036],
    [37.4813957, 37.6142345],
    [37.4846358, 37.6155603],
    [37.4844856, 37.6159173],
    [37.4842925, 37.6161042],
    [37.4836916, 37.6162402],
    [37.4836916, 37.6165292],
    [37.4822111, 37.6180759],
    [37.4813313, 37.621951],
    [37.4821467, 37.6225118],
    [37.483005, 37.6228007],
    [37.4891204, 37.6258939],
    [37.4848074, 37.6320287],
    [37.4735851, 37.6388428],
    [37.4676842, 37.645894],
    [37.4624056, 37.6449596],
    [37.4628992, 37.6433794],
    [37.4620838, 37.6390806],
    [37.4593586, 37.6332692],
    [37.459187, 37.6311621],
    [37.4577922, 37.6301424],
    [37.4579424, 37.6282051],
    [37.4564619, 37.6265227],
    [37.4532647, 37.6257069],
    [37.4515266, 37.6241264],
    [37.4495525, 37.625316],
    [37.4477643, 37.6244341],
  ],
  Osmaniye: [
    [35.8764548, 37.1849843],
    [35.8776394, 37.1857325],
    [35.878609, 37.186234],
    [35.8797519, 37.186488],
    [35.8802835, 37.1870809],
    [35.8802835, 37.1880549],
    [35.8797383, 37.1894846],
    [35.879805, 37.1914003],
    [35.8807619, 37.193327],
    [35.8813466, 37.1939834],
    [35.8818739, 37.194286],
    [35.8833271, 37.1945742],
    [35.8841373, 37.1948302],
    [35.8841967, 37.195398],
    [35.883654, 37.1962769],
    [35.8837185, 37.1970035],
    [35.8839172, 37.1979756],
    [35.884164, 37.1987576],
    [35.8844775, 37.1990501],
    [35.8866038, 37.2004186],
    [35.886944, 37.2009199],
    [35.8860403, 37.2031775],
    [35.8862838, 37.2044093],
    [35.8861005, 37.2056283],
    [35.8862992, 37.2060785],
    [35.8869374, 37.2064059],
    [35.8873958, 37.207009],
    [35.8867752, 37.2078546],
    [35.8873693, 37.2084485],
    [35.8885653, 37.208512],
    [35.8894372, 37.2087728],
    [35.8899739, 37.2091682],
    [35.8905852, 37.2100573],
    [35.8926052, 37.2114755],
    [35.8926052, 37.2120258],
    [35.8921268, 37.212555],
    [35.8912763, 37.2131053],
    [35.8912763, 37.2138038],
    [35.891861, 37.2141637],
    [35.8924537, 37.2147064],
    [35.8929507, 37.2173386],
    [35.8978411, 37.2238785],
    [35.9002066, 37.2272011],
    [35.8997892, 37.228663],
    [35.9001612, 37.2290921],
    [35.9013062, 37.2289493],
    [35.9035555, 37.2322589],
    [35.9041934, 37.2327456],
    [35.9050705, 37.232741],
    [35.905602, 37.2328514],
    [35.9064525, 37.2333592],
    [35.9086585, 37.2334862],
    [35.9100938, 37.234375],
    [35.9129642, 37.2343115],
    [35.9139211, 37.2343327],
    [35.9146387, 37.235031],
    [35.9146061, 37.2357217],
    [35.9148618, 37.2361224],
    [35.9146386, 37.2365596],
    [35.9147156, 37.2373265],
    [35.9142498, 37.2376903],
    [35.9130788, 37.2376693],
    [35.9126453, 37.2387551],
    [35.9126187, 37.2393053],
    [35.9133098, 37.2403844],
    [35.9131514, 37.2407805],
    [35.9115922, 37.2413644],
    [35.9112901, 37.2417879],
    [35.9113026, 37.2423381],
    [35.9118154, 37.2427513],
    [35.9126719, 37.2429235],
    [35.91626, 37.2425638],
    [35.916951, 37.2431774],
    [35.9168713, 37.2449758],
    [35.9162334, 37.2469858],
    [35.9155423, 37.2476628],
    [35.9155689, 37.2486572],
    [35.9159676, 37.2492073],
    [35.9154095, 37.2500535],
    [35.915436, 37.2509632],
    [35.9159942, 37.2515133],
    [35.9173497, 37.2516402],
    [35.9172965, 37.2533962],
    [35.9164726, 37.2546443],
    [35.9153829, 37.255829],
    [35.914931, 37.2575002],
    [35.9141337, 37.2579845],
    [35.9127632, 37.2584472],
    [35.9127632, 37.2618847],
    [35.9122234, 37.2650245],
    [35.9118081, 37.2656525],
    [35.9112596, 37.2679312],
    [35.9114343, 37.2683956],
    [35.9123003, 37.2688405],
    [35.912447, 37.2690448],
    [35.9119742, 37.2701471],
    [35.9099393, 37.2716012],
    [35.9098562, 37.272163],
    [35.9105207, 37.272857],
    [35.9101054, 37.273518],
    [35.9091502, 37.2738815],
    [35.9086104, 37.2746415],
    [35.9093579, 37.2755668],
    [35.9088595, 37.2761617],
    [35.9076137, 37.2763599],
    [35.9071569, 37.2768226],
    [35.9070323, 37.2777148],
    [35.907323, 37.2787392],
    [35.9070738, 37.2794661],
    [35.9059941, 37.2802592],
    [35.9045821, 37.2810522],
    [35.9043744, 37.2832991],
    [35.9034193, 37.2846208],
    [35.9038761, 37.2855129],
    [35.9049974, 37.2874624],
    [35.9041668, 37.2882884],
    [35.9041253, 37.2888831],
    [35.9052465, 37.2894448],
    [35.9052465, 37.2901716],
    [35.9048313, 37.2917245],
    [35.9054127, 37.2927156],
    [35.9054127, 37.2937068],
    [35.905122, 37.294764],
    [35.905911, 37.2964158],
    [35.9054542, 37.2972417],
    [35.906251, 37.2992899],
    [35.9065002, 37.3007435],
    [35.9056281, 37.3015693],
    [35.9052128, 37.3023291],
    [35.9063341, 37.3033201],
    [35.9071005, 37.3043251],
    [35.9074407, 37.3082657],
    [35.907951, 37.3090944],
    [35.9080629, 37.310366],
    [35.9083011, 37.311056],
    [35.9084542, 37.3119895],
    [35.908182, 37.3128824],
    [35.9085222, 37.3136671],
    [35.909781, 37.3141676],
    [35.9097129, 37.3147493],
    [35.9088964, 37.3156963],
    [35.9092366, 37.3170627],
    [35.9109376, 37.3178879],
    [35.9108356, 37.3193759],
    [35.9108356, 37.3205123],
    [35.9102572, 37.3224331],
    [35.9106995, 37.3244757],
    [35.9102062, 37.3259366],
    [35.9108016, 37.3280602],
    [35.9106315, 37.3290882],
    [35.9118732, 37.3296968],
    [35.9118732, 37.3302784],
    [35.9109887, 37.3309682],
    [35.9121284, 37.3319962],
    [35.9122474, 37.3331458],
    [35.9126104, 37.3338931],
    [35.9127858, 37.3344992],
    [35.913217, 37.3350799],
    [35.9146542, 37.336539],
    [35.9147733, 37.3368834],
    [35.9148422, 37.3372204],
    [35.9149058, 37.3379286],
    [35.9153773, 37.3382717],
    [35.916602, 37.3380824],
    [35.9169433, 37.3384482],
    [35.917111, 37.3392531],
    [35.9172893, 37.3396174],
    [35.9173322, 37.3401206],
    [35.9173981, 37.3409971],
    [35.9176594, 37.3417285],
    [35.9179866, 37.3424407],
    [35.9187793, 37.3438029],
    [35.9197999, 37.344425],
    [35.9201572, 37.3449524],
    [35.9202762, 37.3456826],
    [35.9187496, 37.3469715],
    [35.918723, 37.348028],
    [35.9173675, 37.3485562],
    [35.9167562, 37.3487463],
    [35.9164107, 37.3494647],
    [35.9159588, 37.3498662],
    [35.9166499, 37.3508803],
    [35.9163575, 37.3519367],
    [35.9168093, 37.3526339],
    [35.9160917, 37.3540283],
    [35.9161449, 37.3552326],
    [35.916696, 37.3566692],
    [35.9165382, 37.3574771],
    [35.916377, 37.3582066],
    [35.9163575, 37.359648],
    [35.9167296, 37.3602817],
    [35.916844, 37.3610971],
    [35.9167279, 37.3621448],
    [35.9159528, 37.3632371],
    [35.9157221, 37.3636544],
    [35.9155684, 37.3644726],
    [35.9159257, 37.3653177],
    [35.9158259, 37.3665768],
    [35.9158259, 37.3673373],
    [35.9161183, 37.3679287],
    [35.9166499, 37.3713295],
    [35.9170486, 37.3720265],
    [35.9169954, 37.373484],
    [35.9163309, 37.3743499],
    [35.9160917, 37.3757017],
    [35.9157994, 37.3774759],
    [35.9152678, 37.3783207],
    [35.914325, 37.3820177],
    [35.9140676, 37.3840286],
    [35.9147968, 37.3849147],
    [35.9147968, 37.3858008],
    [35.9155794, 37.3874751],
    [35.9167699, 37.3907083],
    [35.9174774, 37.3922205],
    [35.9181637, 37.3924805],
    [35.9188287, 37.3932982],
    [35.9196437, 37.3948317],
    [35.9194983, 37.3961945],
    [35.9194292, 37.3968422],
    [35.9202442, 37.3984097],
    [35.9203299, 37.3992616],
    [35.9200726, 37.4002157],
    [35.9202871, 37.4011016],
    [35.9200297, 37.4019534],
    [35.9208018, 37.4026008],
    [35.9208298, 37.4032036],
    [35.9204294, 37.4037864],
    [35.9204128, 37.4041776],
    [35.9207471, 37.4048873],
    [35.9208875, 37.4068939],
    [35.9211878, 37.4082227],
    [35.9212339, 37.4086873],
    [35.9209023, 37.4088736],
    [35.9202105, 37.4081235],
    [35.9198152, 37.4081546],
    [35.9195125, 37.4082597],
    [35.9193005, 37.408836],
    [35.9194292, 37.4095514],
    [35.9197136, 37.40927],
    [35.9202933, 37.4094532],
    [35.9207666, 37.4091619],
    [35.9213594, 37.4092789],
    [35.9218432, 37.4094908],
    [35.9217883, 37.4097899],
    [35.9209304, 37.4101306],
    [35.9209304, 37.4106417],
    [35.921917, 37.4109142],
    [35.9222601, 37.4120726],
    [35.9220456, 37.4126858],
    [35.9213594, 37.4121067],
    [35.9209304, 37.4124814],
    [35.9208875, 37.4136057],
    [35.9220028, 37.414253],
    [35.921488, 37.4156157],
    [35.9220028, 37.416808],
    [35.9218418, 37.4187074],
    [35.9221163, 37.4194159],
    [35.9232931, 37.4194363],
    [35.9234302, 37.4198281],
    [35.92358, 37.4198893],
    [35.9245527, 37.4195023],
    [35.9247126, 37.4186063],
    [35.925216, 37.4187136],
    [35.9256714, 37.4189498],
    [35.9268718, 37.4191794],
    [35.9274862, 37.4191171],
    [35.928357, 37.4193131],
    [35.9293092, 37.4187135],
    [35.9299207, 37.41878],
    [35.9304519, 37.418973],
    [35.9306554, 37.4194559],
    [35.9303687, 37.419833],
    [35.9304426, 37.4207442],
    [35.9308419, 37.4208466],
    [35.9314542, 37.4203473],
    [35.9321298, 37.42065],
    [35.9324702, 37.4206114],
    [35.9330306, 37.4201861],
    [35.9341478, 37.4203609],
    [35.9346326, 37.4203517],
    [35.9367371, 37.4210464],
    [35.9374921, 37.4218054],
    [35.9375508, 37.4226887],
    [35.9377922, 37.4232618],
    [35.9387036, 37.4241509],
    [35.9385074, 37.4247416],
    [35.9369111, 37.4256185],
    [35.9368339, 37.4264461],
    [35.9366112, 37.4279112],
    [35.9365994, 37.4287807],
    [35.9359215, 37.4298304],
    [35.9362346, 37.4305019],
    [35.9367465, 37.4306911],
    [35.9382444, 37.4312404],
    [35.9384288, 37.4314552],
    [35.938743, 37.4324969],
    [35.9386408, 37.4329238],
    [35.9387236, 37.433233],
    [35.9393497, 37.4338305],
    [35.9391223, 37.4345967],
    [35.9391638, 37.4352722],
    [35.9395713, 37.4360214],
    [35.9396087, 37.4365143],
    [35.940044, 37.4372467],
    [35.9401436, 37.437317],
    [35.9405494, 37.437271],
    [35.9408598, 37.4374353],
    [35.9410328, 37.4377982],
    [35.9408111, 37.4382383],
    [35.9407939, 37.4386917],
    [35.9401777, 37.4389726],
    [35.9399435, 37.4391294],
    [35.9399473, 37.4392636],
    [35.9400619, 37.4394173],
    [35.9408601, 37.4393642],
    [35.9412221, 37.439493],
    [35.941383, 37.4397486],
    [35.9414396, 37.4401178],
    [35.9410205, 37.4403625],
    [35.9404313, 37.4405155],
    [35.9400079, 37.4406132],
    [35.9397781, 37.4410055],
    [35.9397235, 37.4412437],
    [35.9398003, 37.4413144],
    [35.9406735, 37.4412754],
    [35.9408599, 37.4415713],
    [35.9408599, 37.4417824],
    [35.9402463, 37.441822],
    [35.9403727, 37.4420384],
    [35.9403592, 37.4422578],
    [35.9399861, 37.4425633],
    [35.9395488, 37.4428059],
    [35.9393396, 37.4430709],
    [35.9391983, 37.4434342],
    [35.9386999, 37.4436574],
    [35.9386812, 37.4437414],
    [35.938933, 37.4440585],
    [35.9388244, 37.4442969],
    [35.9381147, 37.4453284],
    [35.9377923, 37.4455285],
    [35.9371384, 37.4456973],
    [35.9367333, 37.4458447],
    [35.9361838, 37.445788],
    [35.9356164, 37.445857],
    [35.9352947, 37.4459942],
    [35.9351209, 37.446251],
    [35.9349798, 37.4468109],
    [35.9346284, 37.4471678],
    [35.9343192, 37.4473074],
    [35.9339791, 37.4473123],
    [35.9336066, 37.4471666],
    [35.9332331, 37.4470767],
    [35.9330582, 37.4469053],
    [35.9326857, 37.446887],
    [35.9324894, 37.4469567],
    [35.9324451, 37.4470563],
    [35.9324846, 37.447303],
    [35.9322357, 37.4475599],
    [35.9317727, 37.4477608],
    [35.9315325, 37.4479896],
    [35.9314526, 37.4484975],
    [35.9310627, 37.4485574],
    [35.9306827, 37.4486685],
    [35.9305072, 37.4488222],
    [35.9304811, 37.4489704],
    [35.9305489, 37.4492101],
    [35.9302192, 37.4494204],
    [35.9299849, 37.4497751],
    [35.9294418, 37.450019],
    [35.9292306, 37.4501904],
    [35.9291996, 37.4503839],
    [35.9293189, 37.4506552],
    [35.9292376, 37.4509579],
    [35.9289707, 37.451144],
    [35.9287138, 37.4511504],
    [35.9284992, 37.4508704],
    [35.9278827, 37.4510413],
    [35.9275464, 37.451071],
    [35.9268676, 37.4512812],
    [35.9269049, 37.4514955],
    [35.9267027, 37.4517878],
    [35.9267871, 37.451941],
    [35.9271405, 37.4521342],
    [35.9270783, 37.4524304],
    [35.9266463, 37.4528009],
    [35.9261555, 37.4529936],
    [35.926022, 37.4533117],
    [35.9260274, 37.4539964],
    [35.9258531, 37.4541846],
    [35.9253628, 37.4542415],
    [35.9252734, 37.4542988],
    [35.925257, 37.4544936],
    [35.9255274, 37.4547432],
    [35.9250481, 37.4550216],
    [35.9250531, 37.455095],
    [35.9254456, 37.4552235],
    [35.9259818, 37.4556398],
    [35.9259085, 37.4559201],
    [35.9257609, 37.4560729],
    [35.9253137, 37.4562669],
    [35.9250395, 37.4569076],
    [35.924741, 37.4574481],
    [35.9247589, 37.4576793],
    [35.9249337, 37.4578113],
    [35.9256451, 37.4579183],
    [35.9258156, 37.4580654],
    [35.9257954, 37.4582627],
    [35.9256729, 37.4585626],
    [35.9258533, 37.4589685],
    [35.9262511, 37.4595452],
    [35.926737, 37.4601725],
    [35.9274097, 37.4609628],
    [35.9276897, 37.4614026],
    [35.9273964, 37.4617145],
    [35.9272733, 37.4619999],
    [35.9273118, 37.4623132],
    [35.9278565, 37.4628783],
    [35.9278565, 37.4630516],
    [35.9276192, 37.4633426],
    [35.9275945, 37.4637676],
    [35.9278114, 37.4642025],
    [35.9277095, 37.4646452],
    [35.9273858, 37.4648498],
    [35.9271063, 37.4649607],
    [35.9270661, 37.465129],
    [35.9274302, 37.4653488],
    [35.9275026, 37.4656571],
    [35.9272323, 37.4659613],
    [35.9269281, 37.4660382],
    [35.9265605, 37.4658613],
    [35.9263401, 37.4658613],
    [35.9261123, 37.4659573],
    [35.9260189, 37.4660437],
    [35.9260377, 37.4662527],
    [35.9255832, 37.4663465],
    [35.9252985, 37.4666572],
    [35.9250375, 37.4668775],
    [35.9245954, 37.4671486],
    [35.9246001, 37.4674786],
    [35.9248101, 37.4677941],
    [35.924438, 37.4683802],
    [35.924147, 37.468585],
    [35.9238585, 37.4688808],
    [35.9235575, 37.4688211],
    [35.9233852, 37.4688992],
    [35.9232154, 37.469135],
    [35.9231887, 37.4697193],
    [35.9230657, 37.469931],
    [35.9225803, 37.4700149],
    [35.9218115, 37.4698314],
    [35.9212359, 37.4699134],
    [35.9210115, 37.4701129],
    [35.9210115, 37.4706282],
    [35.9211411, 37.472269],
    [35.9210756, 37.4725333],
    [35.9203141, 37.4744117],
    [35.9205884, 37.4752351],
    [35.9210962, 37.475422],
    [35.9215295, 37.4753078],
    [35.921876, 37.475496],
    [35.9293736, 37.4838809],
    [35.9377426, 37.4873044],
    [35.9471889, 37.4885968],
    [35.9516687, 37.5069188],
    [35.9567982, 37.5097878],
    [35.9621728, 37.5168932],
    [35.9650666, 37.5285815],
    [35.9616479, 37.534735],
    [35.9644281, 37.5494389],
    [35.9716379, 37.5663131],
    [35.9838518, 37.5790502],
    [35.989072, 37.5837035],
    [35.9934992, 37.5875469],
    [36.0259136, 37.6082843],
    [36.0372039, 37.6131543],
    [36.0522712, 37.6273153],
    [36.0694029, 37.6408679],
    [36.0872151, 37.6625165],
    [36.1034375, 37.6861197],
    [36.1277692, 37.7096089],
    [36.1371991, 37.7232086],
    [36.1407914, 37.7270891],
    [36.1432876, 37.7290634],
    [36.146572, 37.7327001],
    [36.1547174, 37.7373755],
    [36.1740299, 37.7387262],
    [36.1911089, 37.7465178],
    [36.2035897, 37.749011],
    [36.2606669, 37.7497797],
    [36.2603175, 37.7476991],
    [36.2602646, 37.7460634],
    [36.2592802, 37.7447477],
    [36.2588399, 37.7440168],
    [36.2582598, 37.7438905],
    [36.2574023, 37.7439304],
    [36.2548686, 37.7433386],
    [36.2541636, 37.7441012],
    [36.2529214, 37.7436512],
    [36.2516738, 37.7422323],
    [36.2507608, 37.7423482],
    [36.2503377, 37.7418231],
    [36.2502428, 37.7407676],
    [36.2485778, 37.7401378],
    [36.2475489, 37.7387175],
    [36.2461841, 37.7373764],
    [36.2451714, 37.7365605],
    [36.2428983, 37.7347914],
    [36.2418881, 37.7324515],
    [36.2406613, 37.7318807],
    [36.2391098, 37.7326512],
    [36.2382943, 37.7324387],
    [36.2376039, 37.7316242],
    [36.2367284, 37.7314527],
    [36.2352102, 37.731553],
    [36.2336051, 37.7315976],
    [36.2328106, 37.730726],
    [36.231641, 37.7300259],
    [36.2314245, 37.7294837],
    [36.2318214, 37.7286846],
    [36.2298008, 37.7279712],
    [36.228105, 37.7271721],
    [36.2274195, 37.7266585],
    [36.227636, 37.725945],
    [36.2276721, 37.7256596],
    [36.2271308, 37.725203],
    [36.227203, 37.7246893],
    [36.2261566, 37.7245466],
    [36.2250381, 37.7248891],
    [36.224966, 37.7256596],
    [36.2237753, 37.7262875],
    [36.222296, 37.7260306],
    [36.222332, 37.725203],
    [36.2208527, 37.7240329],
    [36.2187239, 37.7221208],
    [36.2181827, 37.7212075],
    [36.2186157, 37.7196663],
    [36.2161622, 37.7178397],
    [36.2146467, 37.7181822],
    [36.2134921, 37.7179824],
    [36.2130953, 37.7172118],
    [36.2130231, 37.7157561],
    [36.21342, 37.7147571],
    [36.2461241, 37.6849913],
    [36.2659868, 37.6685076],
    [36.2662638, 37.6584109],
    [36.2623224, 37.6440565],
    [36.2562991, 37.6285932],
    [36.2553334, 37.6275137],
    [36.2548292, 37.6265281],
    [36.2555802, 37.6259927],
    [36.2569857, 37.6259417],
    [36.2572431, 37.625058],
    [36.2565994, 37.6241318],
    [36.2563091, 37.623153],
    [36.2563527, 37.6226957],
    [36.2570178, 37.6222113],
    [36.2572539, 37.6218544],
    [36.2578761, 37.621387],
    [36.2578976, 37.6210641],
    [36.2574792, 37.6203757],
    [36.2575757, 37.6200613],
    [36.257844, 37.6199253],
    [36.2583867, 37.6200546],
    [36.2587666, 37.6197639],
    [36.2596266, 37.6194899],
    [36.2603499, 37.6177986],
    [36.2614703, 37.6171124],
    [36.2621871, 37.6164722],
    [36.2625248, 37.6157835],
    [36.2623715, 37.6153957],
    [36.261642, 37.6151408],
    [36.2606254, 37.6151797],
    [36.2599683, 37.6147668],
    [36.2598232, 37.6142401],
    [36.2600744, 37.6131336],
    [36.260848, 37.6123022],
    [36.2608875, 37.6114936],
    [36.260612, 37.6110613],
    [36.2601088, 37.6108693],
    [36.2581192, 37.6104869],
    [36.257683, 37.6100244],
    [36.2576715, 37.6093025],
    [36.2583482, 37.6087156],
    [36.2588961, 37.6078668],
    [36.2592709, 37.6069392],
    [36.2591841, 37.6062687],
    [36.2585934, 37.6054399],
    [36.2577796, 37.6052647],
    [36.2574146, 37.6049488],
    [36.2575221, 37.6046528],
    [36.2579046, 37.6043485],
    [36.2591728, 37.603703],
    [36.259979, 37.6030293],
    [36.260376, 37.6022643],
    [36.2603793, 37.6016406],
    [36.2599683, 37.6010742],
    [36.258198, 37.6005047],
    [36.2570781, 37.5991642],
    [36.2552872, 37.596572],
    [36.2494012, 37.5922225],
    [36.2469378, 37.5798596],
    [36.2543488, 37.5678932],
    [36.2512207, 37.5662589],
    [36.2510866, 37.5644004],
    [36.24949, 37.5628517],
    [36.2494983, 37.5621029],
    [36.2514055, 37.5591894],
    [36.2463834, 37.5508194],
    [36.2459613, 37.5438025],
    [36.2433917, 37.5411439],
    [36.2435159, 37.5391211],
    [36.2404358, 37.5372099],
    [36.2391222, 37.532937],
    [36.2376566, 37.5307069],
    [36.2386826, 37.5295505],
    [36.2371835, 37.5246094],
    [36.2365591, 37.5202859],
    [36.2374231, 37.5194614],
    [36.2386001, 37.518853],
    [36.2394132, 37.5181108],
    [36.2389905, 37.5157863],
    [36.2400307, 37.5128323],
    [36.2409002, 37.510612],
    [36.2416538, 37.5100405],
    [36.2427405, 37.5100145],
    [36.2429539, 37.509607],
    [36.242896, 37.5090683],
    [36.241778, 37.5076363],
    [36.2408182, 37.5057436],
    [36.2426132, 37.504781],
    [36.2442773, 37.5045249],
    [36.245312, 37.5038443],
    [36.2466003, 37.5017399],
    [36.2459002, 37.4999994],
    [36.2455058, 37.4990187],
    [36.2448621, 37.4972993],
    [36.245085, 37.4964018],
    [36.246033, 37.496225],
    [36.247952, 37.4970439],
    [36.2512409, 37.4970842],
    [36.2514496, 37.4965672],
    [36.2512085, 37.4951308],
    [36.2499046, 37.4928048],
    [36.247952, 37.4899785],
    [36.2451814, 37.4871933],
    [36.245377, 37.4860795],
    [36.2468147, 37.4841555],
    [36.2476896, 37.4825579],
    [36.2488511, 37.481236],
    [36.2488961, 37.480767],
    [36.2473726, 37.4786385],
    [36.2454343, 37.4773747],
    [36.2451625, 37.4760161],
    [36.2455702, 37.4755733],
    [36.2478018, 37.4749603],
    [36.2495398, 37.4746027],
    [36.2520504, 37.4737853],
    [36.2531564, 37.4727726],
    [36.2534451, 37.4708221],
    [36.2533034, 37.4691502],
    [36.2543034, 37.4679269],
    [36.2548358, 37.4662285],
    [36.254797, 37.464742],
    [36.2565432, 37.4626959],
    [36.2584877, 37.4613527],
    [36.2589206, 37.4609706],
    [36.2578257, 37.4561266],
    [36.2565666, 37.4556211],
    [36.2557004, 37.4556949],
    [36.2549327, 37.4553178],
    [36.2543385, 37.4547668],
    [36.2539172, 37.453279],
    [36.2541332, 37.4526084],
    [36.2521601, 37.4502485],
    [36.2520343, 37.4492739],
    [36.2526205, 37.4483868],
    [36.2548107, 37.4464578],
    [36.2574409, 37.4458518],
    [36.2578752, 37.4452756],
    [36.2576451, 37.4445926],
    [36.2554915, 37.4433549],
    [36.255632, 37.4421106],
    [36.2560143, 37.4413061],
    [36.2545786, 37.4389845],
    [36.2524657, 37.437502],
    [36.2519524, 37.4366596],
    [36.252406, 37.4356408],
    [36.254041, 37.4353318],
    [36.2550927, 37.4357597],
    [36.2555759, 37.4364731],
    [36.2564819, 37.4366633],
    [36.2575484, 37.435236],
    [36.2601835, 37.4328446],
    [36.26056, 37.4318935],
    [36.2599468, 37.4315231],
    [36.2592069, 37.4303782],
    [36.2576276, 37.4290752],
    [36.2526415, 37.4260808],
    [36.2519706, 37.4252663],
    [36.2520712, 37.4244735],
    [36.2522691, 37.4239969],
    [36.2540339, 37.4243607],
    [36.2560877, 37.4249638],
    [36.2570251, 37.4249308],
    [36.2578844, 37.4245901],
    [36.2579413, 37.4237613],
    [36.2573283, 37.4229254],
    [36.2555316, 37.4218043],
    [36.2551269, 37.4206418],
    [36.2546652, 37.4194851],
    [36.2544193, 37.4182779],
    [36.2530505, 37.4173877],
    [36.2526228, 37.4160966],
    [36.2531561, 37.4154332],
    [36.2554003, 37.4154785],
    [36.2571144, 37.4164704],
    [36.2596696, 37.417227],
    [36.2613892, 37.4187491],
    [36.2619674, 37.4188646],
    [36.2624174, 37.4186809],
    [36.2627869, 37.4178977],
    [36.2635018, 37.4150698],
    [36.2621281, 37.4120861],
    [36.2616853, 37.410309],
    [36.2653911, 37.4089435],
    [36.2666553, 37.4076548],
    [36.26485, 37.4049713],
    [36.2632829, 37.4043512],
    [36.2620096, 37.4027965],
    [36.2580482, 37.3986259],
    [36.2574289, 37.3975776],
    [36.2570543, 37.3968021],
    [36.2598144, 37.394748],
    [36.2627088, 37.3932502],
    [36.265912, 37.3728857],
    [36.2734458, 37.3587707],
    [36.2851119, 37.3526451],
    [36.2940384, 37.3567386],
    [36.3022781, 37.3613778],
    [36.3096595, 37.3639701],
    [36.3192726, 37.3656073],
    [36.3273406, 37.365198],
    [36.3356905, 37.3644025],
    [36.3422086, 37.3577723],
    [36.3465667, 37.3591947],
    [36.3525218, 37.3645373],
    [36.357553, 37.3635608],
    [36.361673, 37.3591947],
    [36.367166, 37.351144],
    [36.3759209, 37.3477325],
    [36.3881674, 37.352621],
    [36.4004684, 37.3522357],
    [36.4039017, 37.3520991],
    [36.4075229, 37.3553112],
    [36.4125706, 37.3572843],
    [36.4129997, 37.3604908],
    [36.4119189, 37.363268],
    [36.4126931, 37.3648283],
    [36.4141686, 37.3658662],
    [36.4171196, 37.3665075],
    [36.4183501, 37.3662384],
    [36.4196945, 37.3658118],
    [36.4211536, 37.3644475],
    [36.4208042, 37.3631269],
    [36.4212394, 37.3626739],
    [36.4232994, 37.362401],
    [36.4262969, 37.3629538],
    [36.4277625, 37.3651979],
    [36.4281822, 37.366365],
    [36.4304666, 37.3689051],
    [36.4421822, 37.3687951],
    [36.4436936, 37.3686987],
    [36.4458327, 37.3692488],
    [36.447802, 37.3690415],
    [36.4488221, 37.3685705],
    [36.4509664, 37.3696058],
    [36.4538056, 37.3692279],
    [36.4553941, 37.3697358],
    [36.4561629, 37.370352],
    [36.4563731, 37.3708792],
    [36.4569114, 37.3712982],
    [36.4588683, 37.3719493],
    [36.460191, 37.3727656],
    [36.4631587, 37.3724176],
    [36.4686042, 37.3760909],
    [36.4720155, 37.3811057],
    [36.4744887, 37.3839519],
    [36.4750003, 37.3870689],
    [36.4750802, 37.3950311],
    [36.4734668, 37.3991114],
    [36.4728734, 37.4035561],
    [36.471028, 37.4076253],
    [36.4677968, 37.4107098],
    [36.4653279, 37.4140847],
    [36.4788288, 37.4168007],
    [36.4914321, 37.4217762],
    [36.4964961, 37.4216399],
    [36.5081135, 37.4242177],
    [36.5079974, 37.4272974],
    [36.5116186, 37.4292769],
    [36.5165386, 37.4276146],
    [36.5194209, 37.4278191],
    [36.5234382, 37.4287976],
    [36.5310659, 37.4263768],
    [36.5361499, 37.4262068],
    [36.542246, 37.4175176],
    [36.5519946, 37.4169855],
    [36.5774668, 37.4161806],
    [36.5802189, 37.4133394],
    [36.5788346, 37.4074852],
    [36.5790284, 37.401296],
    [36.5733027, 37.3942662],
    [36.5628924, 37.3876493],
    [36.5582077, 37.380825],
    [36.5438655, 37.3707768],
    [36.5314002, 37.3608378],
    [36.5429902, 37.3616044],
    [36.5671227, 37.3687971],
    [36.5812109, 37.3652459],
    [36.6173657, 37.3719399],
    [36.6290508, 37.3659739],
    [36.6368261, 37.3660921],
    [36.6420559, 37.3647348],
    [36.6498873, 37.3632267],
    [36.660141, 37.3627467],
    [36.6712595, 37.360961],
    [36.6855595, 37.3640842],
    [36.6913618, 37.3583127],
    [36.6822302, 37.3457094],
    [36.6697004, 37.3302385],
    [36.6502911, 37.3187522],
    [36.6421555, 37.3102926],
    [36.6350548, 37.3060682],
    [36.6315937, 37.3017648],
    [36.6305018, 37.2979077],
    [36.636938, 37.2919761],
    [36.6561943, 37.2759774],
    [36.6726207, 37.2754189],
    [36.6880281, 37.2782119],
    [36.6868345, 37.2749463],
    [36.6850609, 37.270477],
    [36.6757177, 37.2632346],
    [36.6743049, 37.25902],
    [36.6762641, 37.2548116],
    [36.6753956, 37.2518953],
    [36.6741795, 37.2472864],
    [36.6711336, 37.2420699],
    [36.6767322, 37.2428659],
    [36.6804497, 37.2417345],
    [36.6892423, 37.2406732],
    [36.6917741, 37.2370487],
    [36.6927208, 37.2328371],
    [36.6919829, 37.2265055],
    [36.6940012, 37.222598],
    [36.6965364, 37.2202735],
    [36.6945733, 37.2187084],
    [36.693138, 37.2161761],
    [36.6795332, 37.1839792],
    [36.6565253, 37.1710981],
    [36.6479138, 37.1619606],
    [36.6462247, 37.1578684],
    [36.6371251, 37.1555041],
    [36.6256856, 37.1537221],
    [36.6216911, 37.1445393],
    [36.6211867, 37.1425982],
    [36.6180331, 37.1410695],
    [36.6217232, 37.1388009],
    [36.6215291, 37.1336876],
    [36.6237938, 37.1261465],
    [36.6175618, 37.1185609],
    [36.6080919, 37.110553],
    [36.6040376, 37.1044903],
    [36.5974974, 37.1040858],
    [36.5958452, 37.1023573],
    [36.594091, 37.101326],
    [36.5880042, 37.1024389],
    [36.5827607, 37.0952047],
    [36.5781426, 37.0932351],
    [36.5735384, 37.0927793],
    [36.5720239, 37.0728118],
    [36.5696239, 37.0546977],
    [36.5622653, 37.0513246],
    [36.5532745, 37.0469477],
    [36.5480924, 37.040981],
    [36.5394448, 37.0317743],
    [36.5307875, 37.0239133],
    [36.5240478, 37.0196871],
    [36.5155359, 37.0185623],
    [36.5062808, 37.016192],
    [36.5013026, 37.012217],
    [36.4976977, 37.0094755],
    [36.4993964, 37.0038797],
    [36.4969253, 36.9988512],
    [36.4918536, 36.9950209],
    [36.4946937, 36.9839062],
    [36.491003, 36.9783525],
    [36.4803765, 36.973005],
    [36.473783, 36.9679895],
    [36.4682483, 36.9613871],
    [36.4660262, 36.9572995],
    [36.4688891, 36.9530131],
    [36.4761274, 36.9454767],
    [36.4712679, 36.938786],
    [36.4696031, 36.9321392],
    [36.432929, 36.9313971],
    [36.4111591, 36.9299366],
    [36.3991107, 36.9297265],
    [36.3837713, 36.930899],
    [36.3690029, 36.9316716],
    [36.3488209, 36.9420972],
    [36.3349941, 36.9472586],
    [36.3168361, 36.9516872],
    [36.3038486, 36.9531916],
    [36.293381, 36.9563391],
    [36.2825179, 36.9587322],
    [36.2699201, 36.9626117],
    [36.2463558, 36.963008],
    [36.2353925, 36.964745],
    [36.2259351, 36.9774657],
    [36.2060466, 36.9780534],
    [36.2149417, 36.989985],
    [36.2175473, 36.9982442],
    [36.2159432, 37.0044303],
    [36.2058292, 37.004599],
    [36.1970841, 36.9982688],
    [36.1897143, 36.9963062],
    [36.1866097, 36.9941396],
    [36.1705945, 36.993537],
    [36.1580553, 36.9930651],
    [36.139259, 36.9948249],
    [36.1267314, 36.9966605],
    [36.1152935, 36.9992454],
    [36.0940996, 37.0032476],
    [36.0701861, 37.0032594],
    [36.0575643, 37.0082108],
    [36.0554493, 37.0185091],
    [36.0571269, 37.045933],
    [36.0628526, 37.0528076],
    [36.0645587, 37.0569373],
    [36.0718255, 37.0572131],
    [36.0766168, 37.0594713],
    [36.0825255, 37.0606599],
    [36.086452, 37.0572914],
    [36.091566, 37.0580015],
    [36.0923051, 37.0581042],
    [36.0950967, 37.0610369],
    [36.0922806, 37.0703973],
    [36.0904891, 37.0736673],
    [36.0895002, 37.0809415],
    [36.0991659, 37.0845361],
    [36.0946672, 37.0938374],
    [36.0881454, 37.1001697],
    [36.0779915, 37.105474],
    [36.0773473, 37.1155119],
    [36.0828997, 37.1221819],
    [36.087219, 37.1278955],
    [36.0940849, 37.1346929],
    [36.0965446, 37.1434541],
    [36.0961581, 37.1549804],
    [36.0856054, 37.1583788],
    [36.0831513, 37.1616548],
    [36.0791205, 37.1632142],
    [36.0760322, 37.1639049],
    [36.0724314, 37.1634623],
    [36.0714207, 37.1634919],
    [36.0710341, 37.1635629],
    [36.0676844, 37.1657226],
    [36.0679673, 37.1671464],
    [36.0673119, 37.167327],
    [36.0676251, 37.1684953],
    [36.0678285, 37.1702902],
    [36.0687458, 37.1723846],
    [36.0713913, 37.1763803],
    [36.0700178, 37.1779273],
    [36.0695496, 37.1789169],
    [36.0691729, 37.1798696],
    [36.0691073, 37.1807055],
    [36.0697246, 37.1822221],
    [36.0701753, 37.1826273],
    [36.0735939, 37.1821153],
    [36.0749318, 37.1870394],
    [36.0755296, 37.1887525],
    [36.0760282, 37.1896122],
    [36.076678, 37.1902739],
    [36.0771835, 37.1911852],
    [36.078823, 37.194578],
    [36.0795077, 37.1960556],
    [36.0751556, 37.1966606],
    [36.0708565, 37.1974249],
    [36.061066, 37.204565],
    [36.056335, 37.2064572],
    [36.052534, 37.2071099],
    [36.0494633, 37.207282],
    [36.0484474, 37.2073548],
    [36.0424518, 37.2089865],
    [36.03544, 37.2118329],
    [36.0318942, 37.2118847],
    [36.0271817, 37.2108955],
    [36.0260652, 37.2112349],
    [36.0217881, 37.213498],
    [36.0204792, 37.2136689],
    [36.0178184, 37.2129512],
    [36.0144067, 37.2129682],
    [36.0136771, 37.2136689],
    [36.0096504, 37.2139546],
    [36.0084794, 37.2141756],
    [36.0067418, 37.2144979],
    [36.0049369, 37.214739],
    [36.0039147, 37.2149051],
    [35.9979272, 37.2168131],
    [35.9966182, 37.217958],
    [35.9927992, 37.2186244],
    [35.9915836, 37.2187793],
    [35.9891577, 37.2185153],
    [35.9877135, 37.2178237],
    [35.9844752, 37.2171324],
    [35.9835227, 37.217037],
    [35.9818785, 37.2172888],
    [35.9803588, 37.2174909],
    [35.9796889, 37.217684],
    [35.9791872, 37.2179303],
    [35.9769424, 37.2191239],
    [35.9763073, 37.2194046],
    [35.9754427, 37.2196702],
    [35.9745549, 37.2198636],
    [35.9739283, 37.2199298],
    [35.9737331, 37.2199504],
    [35.9729222, 37.2198585],
    [35.9716629, 37.2196029],
    [35.9708854, 37.2194269],
    [35.9697269, 37.2189079],
    [35.9692076, 37.2185639],
    [35.9685203, 37.2179223],
    [35.9672637, 37.2165891],
    [35.9660519, 37.2151981],
    [35.9655875, 37.2147134],
    [35.9653158, 37.2143175],
    [35.9651966, 37.2141438],
    [35.964899, 37.2136783],
    [35.9643478, 37.212444],
    [35.9639237, 37.2115725],
    [35.9634477, 37.2110106],
    [35.9622832, 37.2097874],
    [35.9610694, 37.2085838],
    [35.9582747, 37.2063057],
    [35.9579014, 37.2061011],
    [35.9573543, 37.2059604],
    [35.9568642, 37.2059493],
    [35.9564843, 37.205975],
    [35.9553624, 37.2061469],
    [35.9524128, 37.2071054],
    [35.94775, 37.2102019],
    [35.9467605, 37.2104831],
    [35.9462822, 37.2103897],
    [35.9448811, 37.2099948],
    [35.9452095, 37.2081342],
    [35.9455103, 37.2069835],
    [35.9459475, 37.2056845],
    [35.9458134, 37.2052317],
    [35.9456074, 37.2045365],
    [35.9453323, 37.2041604],
    [35.9450012, 37.203974],
    [35.9446769, 37.2039057],
    [35.9441933, 37.2039071],
    [35.9405903, 37.2040974],
    [35.9392726, 37.2041539],
    [35.9383189, 37.2042735],
    [35.9372437, 37.2044749],
    [35.9350908, 37.2047612],
    [35.9340295, 37.2045694],
    [35.9334807, 37.2042894],
    [35.9323545, 37.2035703],
    [35.9318883, 37.2032359],
    [35.9316164, 37.2029601],
    [35.9311028, 37.2020962],
    [35.9309233, 37.201611],
    [35.930814, 37.2010414],
    [35.930644, 37.1995094],
    [35.9305236, 37.1981369],
    [35.9304865, 37.1972855],
    [35.9306645, 37.1960385],
    [35.9307689, 37.1953697],
    [35.9307508, 37.1951052],
    [35.9307028, 37.1949406],
    [35.930535, 37.1945836],
    [35.9301703, 37.1942374],
    [35.929234, 37.1934171],
    [35.9283809, 37.192766],
    [35.9261315, 37.1916451],
    [35.9254047, 37.1912824],
    [35.9245443, 37.1909851],
    [35.9236935, 37.1908051],
    [35.9214841, 37.1902887],
    [35.914936, 37.1891987],
    [35.9132959, 37.1889593],
    [35.9128325, 37.1888782],
    [35.9124989, 37.1887897],
    [35.9097514, 37.1877036],
    [35.90736, 37.1863972],
    [35.9065258, 37.1856537],
    [35.9052079, 37.1847386],
    [35.9031387, 37.1837567],
    [35.9024625, 37.1835808],
    [35.9018163, 37.1834748],
    [35.9013496, 37.1834649],
    [35.8970363, 37.1838584],
    [35.8966349, 37.1838696],
    [35.8961841, 37.1837724],
    [35.8959194, 37.1836341],
    [35.8956365, 37.1833736],
    [35.8955386, 37.1831309],
    [35.8955498, 37.1827691],
    [35.895671, 37.182345],
    [35.8960376, 37.1814942],
    [35.8964577, 37.1806729],
    [35.8967093, 37.1803087],
    [35.8971136, 37.1798638],
    [35.8993748, 37.1774727],
    [35.8997385, 37.1769603],
    [35.8999138, 37.176648],
    [35.9000361, 37.176278],
    [35.9000645, 37.1760521],
    [35.9000433, 37.1757698],
    [35.899975, 37.1755705],
    [35.8998486, 37.1753262],
    [35.8997622, 37.1751778],
    [35.8996189, 37.1750165],
    [35.8994488, 37.1748435],
    [35.89918, 37.1746807],
    [35.8988676, 37.1745598],
    [35.8983152, 37.174399],
    [35.8978684, 37.1743004],
    [35.8973386, 37.1742191],
    [35.8970706, 37.174211],
    [35.896812, 37.1742146],
    [35.8966453, 37.1742294],
    [35.8964973, 37.1742591],
    [35.8962961, 37.1743399],
    [35.8961134, 37.1744324],
    [35.8959792, 37.1745274],
    [35.895873, 37.1746224],
    [35.8957622, 37.1747295],
    [35.895642, 37.1748906],
    [35.8954682, 37.1751845],
    [35.8951048, 37.1758356],
    [35.8943894, 37.1765589],
    [35.893815, 37.1769389],
    [35.893224, 37.1772329],
    [35.8926097, 37.1774507],
    [35.8922314, 37.1775512],
    [35.8915026, 37.1776745],
    [35.8909202, 37.1777088],
    [35.890391, 37.1777057],
    [35.8899749, 37.1776539],
    [35.8892371, 37.1775047],
    [35.888675, 37.1773149],
    [35.8876137, 37.1768196],
    [35.8865157, 37.1761772],
    [35.8847246, 37.1749317],
    [35.8845342, 37.1748359],
    [35.884207, 37.1747037],
    [35.883947, 37.1746437],
    [35.8836142, 37.1745758],
    [35.8833485, 37.1745304],
    [35.88307, 37.1745118],
    [35.8828226, 37.1745225],
    [35.8824618, 37.1745725],
    [35.8821774, 37.1746058],
    [35.8819148, 37.1746862],
    [35.8816848, 37.1747667],
    [35.8814688, 37.1748676],
    [35.8805681, 37.175697],
    [35.8812496, 37.1771734],
    [35.8827465, 37.1796346],
    [35.8826921, 37.1810007],
    [35.8819402, 37.1824013],
    [35.8800767, 37.1838235],
    [35.8764548, 37.1849843],
  ],
  Adana: [
    [34.7392966, 36.574],
    [34.9056995, 36.7241076],
    [34.9071176, 36.725444],
    [34.9114609, 36.728669],
    [34.9135297, 36.7302049],
    [34.9212004, 36.7353412],
    [34.9230927, 36.7375377],
    [34.9270125, 36.737977],
    [34.9274518, 36.7393287],
    [34.9258974, 36.7417617],
    [34.9261339, 36.7436878],
    [34.9271477, 36.745107],
    [34.9285331, 36.7454787],
    [34.9297834, 36.745276],
    [34.930831, 36.7429782],
    [34.9325206, 36.7406803],
    [34.9353591, 36.7401059],
    [34.9385355, 36.7410858],
    [34.9411374, 36.7432823],
    [34.9420836, 36.7457491],
    [34.943638, 36.7458842],
    [34.9452488, 36.7417414],
    [34.9476248, 36.740527],
    [34.9557031, 36.740791],
    [34.9579206, 36.7412662],
    [34.9598214, 36.7432726],
    [34.9595794, 36.746403],
    [34.9574167, 36.7489171],
    [34.9566328, 36.7517826],
    [34.9577141, 36.7539182],
    [34.9587954, 36.7546211],
    [34.9607148, 36.75454],
    [34.9652023, 36.7518096],
    [34.9674461, 36.7511338],
    [34.9688518, 36.7516474],
    [34.9700142, 36.7543778],
    [34.9685869, 36.7582923],
    [34.9690843, 36.759179],
    [34.9711821, 36.7596548],
    [34.9733447, 36.7590925],
    [34.9747505, 36.7576651],
    [34.9756155, 36.7575354],
    [34.9766148, 36.7577854],
    [34.9782062, 36.7597714],
    [34.9815733, 36.7607535],
    [34.9932652, 36.7608549],
    [34.9980663, 36.7596654],
    [35.0002947, 36.7572432],
    [35.0029107, 36.752205],
    [35.0047654, 36.7512084],
    [35.0084886, 36.7511807],
    [35.0102187, 36.7529109],
    [35.0105371, 36.7558867],
    [35.0091391, 36.7581429],
    [35.0065647, 36.7597346],
    [35.00428, 36.7629711],
    [35.0023917, 36.7636243],
    [35.0014476, 36.7642775],
    [35.0011901, 36.7652745],
    [35.0018338, 36.7664434],
    [35.0031213, 36.767406],
    [35.005267, 36.7679217],
    [35.0083569, 36.7676123],
    [35.0114039, 36.7658246],
    [35.0163821, 36.7604957],
    [35.0198582, 36.7577107],
    [35.0223902, 36.7568854],
    [35.025566, 36.7569198],
    [35.0283984, 36.7580202],
    [35.0308875, 36.76053],
    [35.0310162, 36.7622491],
    [35.0303296, 36.7642087],
    [35.0259522, 36.7674747],
    [35.0244073, 36.7695718],
    [35.0244931, 36.7746938],
    [35.0250081, 36.7766532],
    [35.0266818, 36.7783719],
    [35.0292567, 36.7793687],
    [35.0318745, 36.7793343],
    [35.034149, 36.7786469],
    [35.0360802, 36.7764469],
    [35.0370673, 36.7737313],
    [35.0369385, 36.7666497],
    [35.0375394, 36.7653089],
    [35.0385693, 36.7642431],
    [35.0393418, 36.7640369],
    [35.0407151, 36.7640369],
    [35.0421742, 36.7642431],
    [35.0464658, 36.7661684],
    [35.0477103, 36.7678185],
    [35.0479678, 36.7710156],
    [35.0476245, 36.7719782],
    [35.0477961, 36.7739032],
    [35.048354, 36.7763782],
    [35.0496415, 36.7785781],
    [35.056465, 36.782909],
    [35.0601557, 36.783837],
    [35.0669364, 36.7857274],
    [35.0693396, 36.7873428],
    [35.0703696, 36.7894736],
    [35.0717858, 36.7952129],
    [35.0733737, 36.7980652],
    [35.0738457, 36.7985807],
    [35.0750045, 36.7988213],
    [35.076764, 36.7989244],
    [35.0785664, 36.7983402],
    [35.0817422, 36.7972061],
    [35.0824288, 36.7963814],
    [35.0868062, 36.7901266],
    [35.0877932, 36.7876521],
    [35.0885657, 36.7869303],
    [35.0907544, 36.7856587],
    [35.0924281, 36.7843526],
    [35.0956467, 36.7832183],
    [35.0986079, 36.7834589],
    [35.1034573, 36.7852806],
    [35.1094655, 36.7887175],
    [35.1141003, 36.7891643],
    [35.116289, 36.7907796],
    [35.1190356, 36.7919138],
    [35.1265887, 36.79212],
    [35.128949, 36.7926355],
    [35.1305798, 36.7926011],
    [35.130494, 36.7918107],
    [35.1293782, 36.7909171],
    [35.1293782, 36.7896799],
    [35.1307515, 36.7885113],
    [35.1326398, 36.7880989],
    [35.1337985, 36.7881677],
    [35.1338843, 36.7852807],
    [35.1353863, 36.7821883],
    [35.137382, 36.7816546],
    [35.140131, 36.7822337],
    [35.1428336, 36.784115],
    [35.1434593, 36.7851096],
    [35.1431829, 36.785623],
    [35.1406983, 36.7854968],
    [35.1385636, 36.7863509],
    [35.1358871, 36.7887454],
    [35.1371244, 36.7899923],
    [35.1403792, 36.7908297],
    [35.1417834, 36.7939827],
    [35.144196, 36.7967404],
    [35.1444693, 36.7971313],
    [35.1445709, 36.7974191],
    [35.1446024, 36.7978915],
    [35.1444508, 36.7983381],
    [35.1470746, 36.799052],
    [35.1465672, 36.7995518],
    [35.144849, 36.8008826],
    [35.143273, 36.8026195],
    [35.1465679, 36.8045367],
    [35.1449275, 36.8068318],
    [35.1464908, 36.8077033],
    [35.1447144, 36.8094758],
    [35.1441052, 36.8110493],
    [35.1415837, 36.8127206],
    [35.1414174, 36.8127871],
    [35.141274, 36.8127723],
    [35.1410498, 36.8126744],
    [35.1407517, 36.8126587],
    [35.1397884, 36.8128836],
    [35.1380871, 36.8132904],
    [35.1366769, 36.8135009],
    [35.1344644, 36.8138681],
    [35.1338731, 36.8141032],
    [35.132569, 36.8146503],
    [35.1312303, 36.8151127],
    [35.1301532, 36.8153612],
    [35.129443, 36.8154682],
    [35.1286373, 36.8154873],
    [35.1272474, 36.8153166],
    [35.1262521, 36.8153171],
    [35.1253682, 36.8154032],
    [35.1253425, 36.8171555],
    [35.1249516, 36.8195347],
    [35.1244182, 36.8223629],
    [35.1257731, 36.823061],
    [35.1319508, 36.8265833],
    [35.1390627, 36.830352],
    [35.1388371, 36.8305562],
    [35.1439818, 36.8367316],
    [35.1418353, 36.8376988],
    [35.1434607, 36.8397749],
    [35.1439044, 36.8401318],
    [35.1443839, 36.8412667],
    [35.144715, 36.8422611],
    [35.1454782, 36.8437309],
    [35.1463856, 36.84517],
    [35.1460361, 36.8451991],
    [35.1455984, 36.8453171],
    [35.1438348, 36.8464945],
    [35.1504836, 36.8543613],
    [35.1494226, 36.8553383],
    [35.1516494, 36.8579385],
    [35.1515373, 36.8590794],
    [35.1501989, 36.8592529],
    [35.1487613, 36.8594761],
    [35.1480639, 36.8596735],
    [35.147742, 36.8596821],
    [35.1473343, 36.8597765],
    [35.1458752, 36.8600083],
    [35.1435578, 36.8604289],
    [35.141809, 36.8607551],
    [35.1396816, 36.8611438],
    [35.139159, 36.8612787],
    [35.1380968, 36.8616993],
    [35.1379681, 36.8617165],
    [35.1371361, 36.862098],
    [35.1372063, 36.8624547],
    [35.1373672, 36.8628496],
    [35.1376135, 36.8632648],
    [35.1384253, 36.8638714],
    [35.1392571, 36.8646406],
    [35.1397437, 36.8651071],
    [35.1401501, 36.865522],
    [35.1402572, 36.8659384],
    [35.1401621, 36.8663217],
    [35.1399339, 36.8671364],
    [35.1398778, 36.8684461],
    [35.1398885, 36.8690469],
    [35.1398456, 36.8695447],
    [35.1399636, 36.8700339],
    [35.1402477, 36.8709168],
    [35.1407348, 36.8716132],
    [35.141648, 36.8727848],
    [35.1421201, 36.8733727],
    [35.1426801, 36.874209],
    [35.1432681, 36.874909],
    [35.1437823, 36.8757398],
    [35.1430803, 36.8760633],
    [35.142737, 36.8761534],
    [35.1424205, 36.8763637],
    [35.1420397, 36.8766297],
    [35.1419699, 36.876737],
    [35.1412189, 36.877252],
    [35.1410472, 36.8775437],
    [35.1411115, 36.8778956],
    [35.1406394, 36.8783591],
    [35.140339, 36.8788139],
    [35.1401674, 36.8792344],
    [35.1401674, 36.8796893],
    [35.1402747, 36.8810023],
    [35.1405429, 36.8827272],
    [35.1408647, 36.8839973],
    [35.1412939, 36.8847525],
    [35.1403068, 36.8853188],
    [35.1393198, 36.8859024],
    [35.1379152, 36.8868437],
    [35.1387083, 36.888584],
    [35.1393145, 36.8898325],
    [35.1393521, 36.8900599],
    [35.1394271, 36.8901843],
    [35.1396792, 36.8904203],
    [35.139808, 36.8906991],
    [35.1399957, 36.8910595],
    [35.1409291, 36.8919305],
    [35.1412832, 36.8923981],
    [35.1416962, 36.8928743],
    [35.1419108, 36.8931146],
    [35.1422219, 36.8934192],
    [35.1422488, 36.8935221],
    [35.1424526, 36.8937881],
    [35.1430106, 36.8946204],
    [35.143504, 36.8953069],
    [35.1438443, 36.8958712],
    [35.1438796, 36.8963622],
    [35.144003, 36.8966068],
    [35.1439065, 36.8973703],
    [35.1438796, 36.8983999],
    [35.1438957, 36.9008881],
    [35.1439815, 36.9019819],
    [35.1439387, 36.9027627],
    [35.1437348, 36.9031402],
    [35.1439118, 36.9032432],
    [35.1441264, 36.9033719],
    [35.1453388, 36.9102092],
    [35.1401299, 36.9106596],
    [35.1399957, 36.9103036],
    [35.1398188, 36.9099261],
    [35.1395881, 36.9095273],
    [35.1378989, 36.9100635],
    [35.1341164, 36.9115904],
    [35.1326036, 36.9094114],
    [35.1322067, 36.9089911],
    [35.1317775, 36.908545],
    [35.1307904, 36.9076485],
    [35.1298732, 36.9088753],
    [35.1289451, 36.910295],
    [35.1286876, 36.9107068],
    [35.1279258, 36.911333],
    [35.1282692, 36.9118134],
    [35.1285588, 36.9122252],
    [35.1289129, 36.9133318],
    [35.1305973, 36.9128428],
    [35.131166, 36.9153004],
    [35.127604, 36.9158151],
    [35.1254207, 36.916214],
    [35.1243049, 36.9164155],
    [35.1236021, 36.9164199],
    [35.1240849, 36.9179209],
    [35.1243151, 36.9184602],
    [35.1247823, 36.919555],
    [35.1249433, 36.9200396],
    [35.1250076, 36.9205542],
    [35.1250023, 36.9214763],
    [35.1250237, 36.9218451],
    [35.1250505, 36.9224241],
    [35.1250988, 36.9225999],
    [35.1253831, 36.9230931],
    [35.1257694, 36.9239036],
    [35.1260215, 36.9245426],
    [35.126338, 36.9247999],
    [35.1267511, 36.9250744],
    [35.1266438, 36.9252502],
    [35.1264828, 36.9252545],
    [35.1262522, 36.9253017],
    [35.1260912, 36.9253403],
    [35.1259035, 36.9259406],
    [35.1256513, 36.926704],
    [35.1253188, 36.9273215],
    [35.124661, 36.928743],
    [35.1241171, 36.9304605],
    [35.1239562, 36.9305805],
    [35.1238918, 36.9307349],
    [35.1235378, 36.9314039],
    [35.1221216, 36.9349714],
    [35.1220572, 36.9355117],
    [35.1213003, 36.9356982],
    [35.1213384, 36.9361292],
    [35.121274, 36.9364893],
    [35.1209521, 36.936901],
    [35.1204685, 36.9375287],
    [35.120244, 36.9381101],
    [35.1202226, 36.938779],
    [35.1200616, 36.9393793],
    [35.1198578, 36.9403654],
    [35.1199114, 36.9409314],
    [35.1200294, 36.9413515],
    [35.1159954, 36.9419518],
    [35.1159954, 36.9425949],
    [35.1158023, 36.9437782],
    [35.1155124, 36.9451377],
    [35.1150191, 36.9451416],
    [35.1145041, 36.9469937],
    [35.1137316, 36.9467022],
    [35.1130664, 36.9464192],
    [35.1123476, 36.9460848],
    [35.1115966, 36.9456475],
    [35.1108777, 36.9450644],
    [35.1106095, 36.945536],
    [35.1102877, 36.9462735],
    [35.1095581, 36.9463163],
    [35.1087212, 36.9464021],
    [35.107917, 36.9494984],
    [35.1070261, 36.9496003],
    [35.1060095, 36.9496882],
    [35.1055509, 36.9497503],
    [35.105111, 36.9497718],
    [35.1049769, 36.9498061],
    [35.1048428, 36.9497975],
    [35.1046872, 36.9497632],
    [35.1043707, 36.9497246],
    [35.1039255, 36.9497246],
    [35.1036035, 36.9496924],
    [35.1033299, 36.9496668],
    [35.1031207, 36.9496281],
    [35.1029651, 36.9495853],
    [35.1027881, 36.9495274],
    [35.1026111, 36.9495788],
    [35.1024984, 36.949566],
    [35.1022141, 36.9494588],
    [35.1019727, 36.9493688],
    [35.1015167, 36.949253],
    [35.1004814, 36.9490001],
    [35.0995266, 36.9487857],
    [35.0987542, 36.9486142],
    [35.0980139, 36.9483999],
    [35.0975525, 36.9481855],
    [35.0970536, 36.9479369],
    [35.0965226, 36.9477397],
    [35.0962398, 36.9477386],
    [35.09619, 36.9478769],
    [35.0962061, 36.9480398],
    [35.0963402, 36.9482584],
    [35.0966835, 36.9487857],
    [35.0970912, 36.9493045],
    [35.0977939, 36.9504877],
    [35.0982612, 36.9516989],
    [35.098419, 36.9522844],
    [35.0985342, 36.9530384],
    [35.0985771, 36.9535828],
    [35.0985932, 36.9540201],
    [35.0985718, 36.9546674],
    [35.0985342, 36.9556233],
    [35.0985074, 36.9563221],
    [35.0984752, 36.9572609],
    [35.0984591, 36.9578524],
    [35.0985074, 36.958161],
    [35.0985825, 36.958534],
    [35.0987644, 36.9590739],
    [35.0989419, 36.9595284],
    [35.0991189, 36.9600342],
    [35.099296, 36.9605915],
    [35.0993711, 36.9609301],
    [35.0994086, 36.9612002],
    [35.0994086, 36.9618045],
    [35.0993228, 36.9623232],
    [35.0991511, 36.9628718],
    [35.0988614, 36.9634805],
    [35.0984216, 36.9645692],
    [35.0962435, 36.9640806],
    [35.096104, 36.9663865],
    [35.0947415, 36.9663265],
    [35.0938081, 36.9663179],
    [35.0929498, 36.9663351],
    [35.0899672, 36.9668151],
    [35.0882077, 36.967038],
    [35.0881251, 36.9695793],
    [35.0891948, 36.9696868],
    [35.0891304, 36.9706639],
    [35.0915766, 36.971264],
    [35.0916731, 36.9723611],
    [35.0921559, 36.9732268],
    [35.092628, 36.9737411],
    [35.0935292, 36.974504],
    [35.093776, 36.9754897],
    [35.0919092, 36.975464],
    [35.0917482, 36.9768096],
    [35.0892484, 36.9768011],
    [35.0891411, 36.9778981],
    [35.092231, 36.9778553],
    [35.0941837, 36.9778382],
    [35.0940871, 36.9785238],
    [35.0939906, 36.9792009],
    [35.0939391, 36.980398],
    [35.1098691, 36.9830149],
    [35.1119827, 36.9833491],
    [35.1142681, 36.9837904],
    [35.1185487, 36.9844889],
    [35.1207871, 36.9848435],
    [35.1205873, 36.9859287],
    [35.1205766, 36.9865114],
    [35.1205552, 36.9872056],
    [35.1205659, 36.9877369],
    [35.1206517, 36.9883968],
    [35.1208877, 36.9894337],
    [35.1210487, 36.9904278],
    [35.1210379, 36.991379],
    [35.1209843, 36.9919103],
    [35.1189994, 36.995338],
    [35.11842, 36.9958264],
    [35.117669, 36.996272],
    [35.1172614, 36.9965976],
    [35.1161992, 36.9978829],
    [35.1152229, 36.9988512],
    [35.1142359, 37.0002564],
    [35.1150083, 37.0001107],
    [35.1160382, 37.0000679],
    [35.1158129, 37.0024071],
    [35.118227, 37.0024242],
    [35.1186559, 37.003581],
    [35.1196645, 37.0057571],
    [35.1186775, 37.0066481],
    [35.1171647, 37.0081046],
    [35.117744, 37.0083789],
    [35.1192998, 37.0092098],
    [35.1215636, 37.0101351],
    [35.1208233, 37.0123197],
    [35.1202868, 37.0121655],
    [35.1181695, 37.0125475],
    [35.1171379, 37.0127823],
    [35.1167678, 37.0139903],
    [35.117374, 37.0143801],
    [35.1174652, 37.0145514],
    [35.1184629, 37.0152068],
    [35.1186507, 37.0152882],
    [35.1193159, 37.0158536],
    [35.1194501, 37.0159692],
    [35.1196539, 37.0160335],
    [35.1199007, 37.0167616],
    [35.1200241, 37.0170229],
    [35.1200293, 37.0173912],
    [35.1198845, 37.0178281],
    [35.1196485, 37.0179267],
    [35.1197021, 37.0180337],
    [35.1195842, 37.0183764],
    [35.1195815, 37.0185541],
    [35.1195708, 37.018734],
    [35.1196164, 37.0189782],
    [35.1197022, 37.0190317],
    [35.1198176, 37.0189846],
    [35.1199409, 37.019051],
    [35.1200053, 37.0191666],
    [35.1201314, 37.0192908],
    [35.1202869, 37.0194043],
    [35.1204746, 37.0195157],
    [35.1204157, 37.0198027],
    [35.1206033, 37.0199483],
    [35.1206033, 37.0201068],
    [35.1205872, 37.0201839],
    [35.1205175, 37.0203638],
    [35.1204801, 37.0205608],
    [35.1205497, 37.0208006],
    [35.1206248, 37.0209163],
    [35.1207106, 37.0210148],
    [35.1208608, 37.0210705],
    [35.1208501, 37.0211261],
    [35.1204665, 37.0211004],
    [35.1200884, 37.0211176],
    [35.1198657, 37.0210962],
    [35.1198255, 37.0212718],
    [35.1196726, 37.021653],
    [35.1193803, 37.0223082],
    [35.1191469, 37.0228093],
    [35.1190933, 37.0230428],
    [35.119053, 37.0232762],
    [35.1190235, 37.0234689],
    [35.1189967, 37.0236552],
    [35.1188167, 37.0236402],
    [35.1186936, 37.0241713],
    [35.1186292, 37.0275932],
    [35.118538, 37.0278245],
    [35.1185649, 37.0281585],
    [35.1186239, 37.0285097],
    [35.1190369, 37.0287838],
    [35.1192032, 37.0288909],
    [35.1192649, 37.0289722],
    [35.1192569, 37.0290579],
    [35.1192032, 37.029135],
    [35.119171, 37.0292763],
    [35.1198362, 37.0297902],
    [35.1198899, 37.0299058],
    [35.1199542, 37.0299487],
    [35.1201474, 37.0299315],
    [35.1203083, 37.0300043],
    [35.1201956, 37.0302056],
    [35.1197772, 37.0304283],
    [35.1195465, 37.0307238],
    [35.1193856, 37.0309979],
    [35.1193856, 37.0315246],
    [35.1203727, 37.0321284],
    [35.1213211, 37.0326383],
    [35.1225013, 37.0406507],
    [35.0983054, 37.0436354],
    [35.0810383, 37.0508335],
    [35.0815882, 37.059084],
    [35.0814782, 37.0646131],
    [35.0660808, 37.0573286],
    [35.0533229, 37.0675092],
    [35.046834, 37.065754],
    [35.041005, 37.068299],
    [35.0374855, 37.068299],
    [35.0327563, 37.0658417],
    [35.0270373, 37.0669826],
    [35.0210983, 37.0668948],
    [35.0118598, 37.0647886],
    [35.0065807, 37.0769863],
    [34.9997043, 37.0840982],
    [34.9889639, 37.1061581],
    [34.9827881, 37.1164362],
    [34.9816055, 37.1208959],
    [34.9739326, 37.1263165],
    [34.9704954, 37.1295002],
    [34.9645066, 37.1339548],
    [34.9577421, 37.1422846],
    [34.9528802, 37.1470523],
    [34.9505122, 37.154642],
    [34.954639, 37.1661494],
    [34.9513348, 37.1747989],
    [34.9447495, 37.1798108],
    [34.9377624, 37.1866775],
    [34.9308191, 37.1985685],
    [34.9308795, 37.2054237],
    [34.9374539, 37.209692],
    [34.9502038, 37.2135407],
    [34.9568239, 37.2156263],
    [34.9623193, 37.2188806],
    [34.9637762, 37.2251676],
    [34.9688144, 37.2303254],
    [34.971331, 37.2379301],
    [34.9691156, 37.2486849],
    [34.9745713, 37.2676678],
    [34.9551286, 37.283249],
    [34.9349448, 37.2918433],
    [34.906095, 37.2967213],
    [34.8775903, 37.2961533],
    [34.8715757, 37.2960166],
    [34.840987, 37.303125],
    [34.8174441, 37.3090026],
    [34.8043837, 37.3150164],
    [34.7787786, 37.3308686],
    [34.7872577, 37.3493015],
    [34.7875428, 37.3758108],
    [34.7731858, 37.4003733],
    [34.7696835, 37.4139627],
    [34.7747917, 37.4208587],
    [34.788204, 37.4239896],
    [34.797161, 37.4315526],
    [34.7994912, 37.446447],
    [34.796274, 37.4558891],
    [34.8020666, 37.4660784],
    [34.8226562, 37.4734906],
    [34.8320546, 37.4822985],
    [34.8415027, 37.4978581],
    [34.8434049, 37.5037808],
    [34.8499294, 37.5147164],
    [34.8474673, 37.5395567],
    [34.8574033, 37.5497332],
    [34.8695169, 37.5608259],
    [34.8689415, 37.5734438],
    [34.8652974, 37.5821078],
    [34.8548449, 37.5884719],
    [34.8585847, 37.6012563],
    [34.8476525, 37.6087016],
    [34.858036, 37.6230284],
    [34.8552334, 37.6339022],
    [34.8668407, 37.6573586],
    [34.8795525, 37.6819708],
    [34.8918344, 37.6908596],
    [34.9055869, 37.6948433],
    [34.9365085, 37.6936458],
    [34.9568771, 37.6848776],
    [34.9693458, 37.674327],
    [34.9896636, 37.6702121],
    [34.9993281, 37.6666141],
    [35.05271, 37.6500268],
    [35.0910019, 37.6495881],
    [35.3111078, 37.7175513],
    [35.3206733, 37.7056077],
    [35.3350933, 37.7038857],
    [35.3476628, 37.7037355],
    [35.3589297, 37.7036055],
    [35.3746167, 37.6985943],
    [35.3887822, 37.6942032],
    [35.3997269, 37.6934552],
    [35.4051022, 37.6914407],
    [35.4088889, 37.6924741],
    [35.4090056, 37.692506],
    [35.4290472, 37.697973],
    [35.4429342, 37.7105599],
    [35.4743869, 37.724172],
    [35.4808022, 37.719386],
    [35.4785669, 37.7135521],
    [35.4824597, 37.7079577],
    [35.49924, 37.7163891],
    [35.5128047, 37.7183185],
    [35.5166111, 37.7208302],
    [35.5195961, 37.7251738],
    [35.5195392, 37.7299071],
    [35.5206578, 37.7323191],
    [35.5219864, 37.735996],
    [35.5226556, 37.7495977],
    [35.5204428, 37.7537916],
    [35.5174089, 37.7606488],
    [35.5203481, 37.7628077],
    [35.5224386, 37.763133],
    [35.5237647, 37.7658949],
    [35.5262367, 37.7667843],
    [35.5299356, 37.7761302],
    [35.5187336, 37.7999674],
    [35.5187469, 37.8010113],
    [35.5295944, 37.7987435],
    [35.5396628, 37.7983607],
    [35.5387681, 37.7995324],
    [35.5611436, 37.8023096],
    [35.564645, 37.7989638],
    [35.5747347, 37.789336],
    [35.5761028, 37.7880552],
    [35.5764856, 37.7880296],
    [35.5768219, 37.7882746],
    [35.57796, 37.7885585],
    [35.5788414, 37.7887455],
    [35.5789336, 37.7888455],
    [35.5829157, 37.7927439],
    [35.5831352, 37.7928267],
    [35.5862319, 37.795],
    [35.5890128, 37.7969392],
    [35.5925324, 37.7990381],
    [35.5997022, 37.8006289],
    [35.6037414, 37.8017789],
    [35.6048297, 37.8060347],
    [35.6045928, 37.8106253],
    [35.605585, 37.814175],
    [35.607008, 37.8198633],
    [35.6087167, 37.8230444],
    [35.6099822, 37.8270044],
    [35.6102407, 37.8271675],
    [35.6109714, 37.8273625],
    [35.6119394, 37.8274725],
    [35.612185, 37.8275431],
    [35.6123075, 37.8276431],
    [35.61242, 37.82795],
    [35.6126224, 37.8284172],
    [35.6127722, 37.8286808],
    [35.61292, 37.8288611],
    [35.6130633, 37.8289933],
    [35.6132094, 37.8290497],
    [35.6137214, 37.8290844],
    [35.6138963, 37.8291719],
    [35.614285, 37.8294944],
    [35.6145589, 37.8298281],
    [35.6148668, 37.8301714],
    [35.6155786, 37.8307292],
    [35.6158336, 37.8309619],
    [35.6160106, 37.8311831],
    [35.6166247, 37.8322125],
    [35.6168614, 37.8326561],
    [35.6168675, 37.8329078],
    [35.6169397, 37.8330628],
    [35.6175347, 37.8335869],
    [35.6183828, 37.8351294],
    [35.6192825, 37.8356917],
    [35.6194689, 37.8359958],
    [35.6197719, 37.8363374],
    [35.6200586, 37.8365819],
    [35.6209125, 37.83702],
    [35.6211942, 37.8372531],
    [35.6215514, 37.8375058],
    [35.6220122, 37.8380644],
    [35.6225678, 37.838975],
    [35.6227644, 37.8394625],
    [35.623175, 37.8398767],
    [35.6233178, 37.8401753],
    [35.6235522, 37.8408321],
    [35.6133714, 37.8425778],
    [35.6121797, 37.8427561],
    [35.6136864, 37.8442636],
    [35.6189331, 37.8496997],
    [35.6171556, 37.8488919],
    [35.6167069, 37.8486852],
    [35.6160228, 37.8489653],
    [35.6155339, 37.8491377],
    [35.61552, 37.8491456],
    [35.6148158, 37.8494944],
    [35.6146103, 37.8496889],
    [35.6142586, 37.8501408],
    [35.61148, 37.8512142],
    [35.6102089, 37.8513906],
    [35.6094239, 37.8517531],
    [35.6075342, 37.8527958],
    [35.6066358, 37.8535692],
    [35.6060414, 37.8539628],
    [35.6058867, 37.85428],
    [35.605705, 37.8548378],
    [35.6053792, 37.8551807],
    [35.6040069, 37.8600436],
    [35.5972875, 37.8601466],
    [35.5971256, 37.8647283],
    [35.5978967, 37.865226],
    [35.5978331, 37.8656211],
    [35.5978514, 37.8660244],
    [35.5979419, 37.8663875],
    [35.5979861, 37.8667625],
    [35.5980825, 37.866995],
    [35.5978292, 37.8672669],
    [35.5974958, 37.8680611],
    [35.5967861, 37.8686392],
    [35.5967083, 37.8687681],
    [35.5965108, 37.8688589],
    [35.5963736, 37.8688889],
    [35.59609, 37.8690408],
    [35.5957342, 37.8691483],
    [35.5954636, 37.8692661],
    [35.5952311, 37.8694758],
    [35.5951106, 37.8696214],
    [35.5950747, 37.8698928],
    [35.5948717, 37.8702114],
    [35.5948211, 37.8704444],
    [35.5948333, 37.8706461],
    [35.5948836, 37.8707481],
    [35.5949103, 37.8709583],
    [35.5950381, 37.8711319],
    [35.59514, 37.8712581],
    [35.5952458, 37.8714722],
    [35.5953908, 37.8716153],
    [35.5955531, 37.8717142],
    [35.5956508, 37.8718469],
    [35.5956797, 37.8720475],
    [35.5958583, 37.8722483],
    [35.5958964, 37.8723536],
    [35.5958994, 37.8725678],
    [35.5961361, 37.8729753],
    [35.5959378, 37.873175],
    [35.5952017, 37.8735586],
    [35.5950281, 37.8736997],
    [35.5949406, 37.8739439],
    [35.5945264, 37.8747336],
    [35.5940033, 37.8752464],
    [35.5938103, 37.8755719],
    [35.5937467, 37.8757597],
    [35.5937842, 37.8761308],
    [35.5937589, 37.8762389],
    [35.5935992, 37.8763689],
    [35.5934389, 37.8765425],
    [35.5932908, 37.8769794],
    [35.5929839, 37.8773961],
    [35.5929367, 37.8775025],
    [35.5930842, 37.8781667],
    [35.5924997, 37.8785956],
    [35.5921639, 37.8787553],
    [35.5914053, 37.8789485],
    [35.5911706, 37.8791653],
    [35.5908189, 37.8792403],
    [35.5906119, 37.8794181],
    [35.5904722, 37.87987],
    [35.5904139, 37.8800025],
    [35.5900289, 37.8801253],
    [35.5899233, 37.8803425],
    [35.5898894, 37.8805033],
    [35.5898886, 37.8806447],
    [35.5898136, 37.8807992],
    [35.5895489, 37.8810311],
    [35.5894739, 37.8811614],
    [35.5894547, 37.8816703],
    [35.5893767, 37.8818703],
    [35.5890603, 37.8819953],
    [35.5885339, 37.8823503],
    [35.5876333, 37.8833853],
    [35.5874669, 37.8836567],
    [35.5873453, 37.8838019],
    [35.5870694, 37.8840447],
    [35.5867181, 37.8845547],
    [35.5866119, 37.8848503],
    [35.58658, 37.8851875],
    [35.5866492, 37.8855533],
    [35.5867058, 37.8857536],
    [35.5868525, 37.8864961],
    [35.5869244, 37.886925],
    [35.5872506, 37.8879925],
    [35.5873539, 37.8881819],
    [35.5873506, 37.8883039],
    [35.5872092, 37.8885514],
    [35.5870847, 37.8886881],
    [35.5868706, 37.8892053],
    [35.5869358, 37.8892946],
    [35.586785, 37.8897511],
    [35.5863589, 37.8912989],
    [35.5867242, 37.8913282],
    [35.5871903, 37.8914191],
    [35.5876447, 37.8916818],
    [35.5886225, 37.8921182],
    [35.5888328, 37.8923041],
    [35.5890464, 37.8923918],
    [35.5892211, 37.8925774],
    [35.5893917, 37.8930088],
    [35.5894678, 37.8931721],
    [35.5900131, 37.8938507],
    [35.5901928, 37.8941474],
    [35.5903472, 37.8944807],
    [35.5906492, 37.8950258],
    [35.5908047, 37.8951591],
    [35.5944075, 37.9051441],
    [35.5927364, 37.9088005],
    [35.5908989, 37.9129327],
    [35.5864217, 37.9211135],
    [35.5838764, 37.9239618],
    [35.5767414, 37.9311052],
    [35.5756911, 37.9414231],
    [35.5839969, 37.9490386],
    [35.5912942, 37.9592891],
    [35.5981667, 37.9654569],
    [35.6047461, 37.9680317],
    [35.6075611, 37.9684433],
    [35.6110408, 37.9683939],
    [35.6160647, 37.9702816],
    [35.6194275, 37.9717892],
    [35.6239303, 37.9717544],
    [35.6276064, 37.9732043],
    [35.6315275, 37.9722561],
    [35.6359464, 37.9731094],
    [35.6395108, 37.9736617],
    [35.6459475, 37.9765953],
    [35.6476317, 37.9774172],
    [35.6483517, 37.9780502],
    [35.6494311, 37.97826],
    [35.6504419, 37.9784449],
    [35.6520103, 37.978523],
    [35.6526547, 37.9786874],
    [35.6530503, 37.9787774],
    [35.6541092, 37.9791132],
    [35.6557978, 37.9796599],
    [35.6574378, 37.9801057],
    [35.6593297, 37.9805166],
    [35.6630081, 37.9811516],
    [35.6640161, 37.9813405],
    [35.6709506, 37.9837752],
    [35.69, 37.973646],
    [35.6891194, 37.9793513],
    [35.6902369, 37.9809991],
    [35.6908764, 37.9864124],
    [35.6929583, 37.9958161],
    [35.70913, 38.0256011],
    [35.7092081, 38.0256014],
    [35.7104019, 38.0268356],
    [35.7119661, 38.028445],
    [35.7106919, 38.032445],
    [35.7098503, 38.0348533],
    [35.7117714, 38.0382975],
    [35.7146244, 38.0416303],
    [35.7163153, 38.0447947],
    [35.7196542, 38.0452081],
    [35.7216906, 38.0488875],
    [35.7237453, 38.0516386],
    [35.7246611, 38.0548339],
    [35.7227683, 38.0571631],
    [35.7217047, 38.0602969],
    [35.7224917, 38.0626483],
    [35.7399253, 38.0541503],
    [35.7399286, 38.0490056],
    [35.7398075, 38.0477456],
    [35.7411158, 38.0456275],
    [35.7433636, 38.0438283],
    [35.7484335, 38.0407119],
    [35.7596694, 38.0416281],
    [35.7638816, 38.0437833],
    [35.7641875, 38.0489756],
    [35.7645975, 38.055215],
    [35.7637203, 38.0606119],
    [35.7616667, 38.0708164],
    [35.7633619, 38.0657939],
    [35.7644169, 38.0620467],
    [35.7659889, 38.0579056],
    [35.7712211, 38.055525],
    [35.7746958, 38.0540336],
    [35.7767231, 38.0534706],
    [35.7778689, 38.0989714],
    [35.7746881, 38.0995953],
    [35.7746025, 38.0997075],
    [35.7709272, 38.1006078],
    [35.7698967, 38.1024428],
    [35.7755411, 38.1061656],
    [35.7776917, 38.1073769],
    [35.7829108, 38.1074692],
    [35.7865617, 38.1084581],
    [35.7878631, 38.1113775],
    [35.7914597, 38.1148033],
    [35.7909247, 38.1160319],
    [35.7962567, 38.1167914],
    [35.8006214, 38.1208911],
    [35.8018219, 38.1185628],
    [35.8015578, 38.1143197],
    [35.8062681, 38.1123381],
    [35.8099194, 38.1107353],
    [35.8157536, 38.10852],
    [35.8124994, 38.105275],
    [35.8097728, 38.1026975],
    [35.8065383, 38.0994739],
    [35.7993536, 38.0982167],
    [35.7964475, 38.0929272],
    [35.794385, 38.0892919],
    [35.7926503, 38.0860656],
    [35.7896992, 38.0811531],
    [35.79525, 38.077838],
    [35.8035581, 38.0758658],
    [35.8176989, 38.0762577],
    [35.8265817, 38.0755222],
    [35.8352183, 38.076065],
    [35.8395372, 38.07981],
    [35.8405478, 38.0806861],
    [35.8463806, 38.0856178],
    [35.8517442, 38.0902456],
    [35.8592103, 38.0887367],
    [35.8674947, 38.0868586],
    [35.8747303, 38.0845494],
    [35.8810861, 38.0867228],
    [35.8881014, 38.0881453],
    [35.8907842, 38.0902594],
    [35.8913319, 38.0908586],
    [35.8926572, 38.0903483],
    [35.8952283, 38.0910778],
    [35.8961822, 38.0909822],
    [35.8992117, 38.0911956],
    [35.9007878, 38.0936203],
    [35.9022194, 38.0949364],
    [35.9042553, 38.0970647],
    [35.9065489, 38.1008344],
    [35.9140978, 38.1034464],
    [35.9198094, 38.1098517],
    [35.9215781, 38.1086575],
    [35.9238342, 38.1070717],
    [35.9256825, 38.1054619],
    [35.9276436, 38.10387],
    [35.9295722, 38.1022],
    [35.9325903, 38.1006831],
    [35.9350747, 38.0985403],
    [35.9373414, 38.0955106],
    [35.9394947, 38.095995],
    [35.9424461, 38.0963611],
    [35.945245, 38.0968586],
    [35.9466669, 38.0983203],
    [35.9479656, 38.0997217],
    [35.9496275, 38.1011281],
    [35.95074, 38.1022731],
    [35.9517572, 38.1027083],
    [35.9525244, 38.1030369],
    [35.9541589, 38.1055908],
    [35.9579053, 38.1060864],
    [35.9607244, 38.1074403],
    [35.9635264, 38.1092044],
    [35.9651911, 38.1111083],
    [35.9665989, 38.1125706],
    [35.9677778, 38.1143894],
    [35.9679936, 38.1159144],
    [35.9714931, 38.1164725],
    [35.9744614, 38.1172944],
    [35.9733561, 38.1191297],
    [35.9728725, 38.1204658],
    [35.9722006, 38.1221019],
    [35.9726389, 38.1233414],
    [35.9720672, 38.1260492],
    [35.9708658, 38.1280147],
    [35.9710886, 38.1291214],
    [35.9713872, 38.1304358],
    [35.9710161, 38.1322169],
    [35.9713514, 38.1331742],
    [35.9717291, 38.1344146],
    [35.9718363, 38.1356482],
    [35.9721703, 38.137405],
    [35.9729152, 38.1390474],
    [35.9747796, 38.1402746],
    [35.9763488, 38.141358],
    [35.977706, 38.142598],
    [35.9792571, 38.1434027],
    [35.9813038, 38.1450077],
    [35.9827938, 38.1458805],
    [35.985356, 38.1470816],
    [35.9879928, 38.1482942],
    [35.9908033, 38.1503558],
    [35.9913605, 38.1514388],
    [35.9922046, 38.1528563],
    [35.9928613, 38.1542688],
    [35.9930264, 38.1560552],
    [35.9919955, 38.1573991],
    [35.9924163, 38.1596746],
    [35.9924985, 38.1624582],
    [35.9924213, 38.1647541],
    [35.9916066, 38.1673338],
    [35.9923738, 38.169633],
    [35.995158, 38.1735191],
    [35.9979827, 38.175921],
    [36.0021085, 38.1764266],
    [36.0054482, 38.1805705],
    [36.0117727, 38.180808],
    [36.0123924, 38.1847913],
    [36.0160332, 38.1865349],
    [36.0209181, 38.1897481],
    [36.0003833, 38.1950336],
    [35.9961882, 38.2005443],
    [35.9949557, 38.2039443],
    [35.9949435, 38.2039885],
    [35.9949313, 38.2040324],
    [35.9940068, 38.2072991],
    [35.9945408, 38.2121014],
    [35.9903569, 38.2234299],
    [35.9916305, 38.223985],
    [35.9928127, 38.2245],
    [35.9939475, 38.2258519],
    [35.9951599, 38.227725],
    [35.9996225, 38.2296861],
    [35.9997006, 38.2297808],
    [36.0000777, 38.23024],
    [36.0039749, 38.2323297],
    [36.012798, 38.2422206],
    [36.0246986, 38.2469731],
    [36.0247013, 38.2469741],
    [36.0281047, 38.2489819],
    [36.0391661, 38.2565578],
    [36.0444732, 38.2594817],
    [36.0454785, 38.2597694],
    [36.0451733, 38.2647411],
    [36.0471908, 38.2668481],
    [36.0486153, 38.2684211],
    [36.0485996, 38.2703664],
    [36.0486421, 38.2708908],
    [36.0487178, 38.2711633],
    [36.0488577, 38.2714739],
    [36.0490399, 38.2717364],
    [36.0496888, 38.2724647],
    [36.0529714, 38.2742444],
    [36.0536394, 38.2746956],
    [36.054608, 38.2753497],
    [36.0583713, 38.2781803],
    [36.0619117, 38.2808425],
    [36.0674794, 38.2879667],
    [36.0704683, 38.2911061],
    [36.0709082, 38.2920036],
    [36.077975, 38.3001943],
    [36.0856968, 38.3060372],
    [36.0926755, 38.3098092],
    [36.0967406, 38.3127166],
    [36.0969082, 38.3129881],
    [36.0970046, 38.3130792],
    [36.0982591, 38.3141942],
    [36.1018649, 38.3187608],
    [36.1037946, 38.3221889],
    [36.106306, 38.3254211],
    [36.1085547, 38.3304958],
    [36.1128167, 38.3317122],
    [36.1167518, 38.3345344],
    [36.1196574, 38.3368614],
    [36.1240617, 38.3407033],
    [36.1304933, 38.3413658],
    [36.1347325, 38.3415181],
    [36.1395169, 38.3435792],
    [36.1393063, 38.3378664],
    [36.1356232, 38.32221],
    [36.1408982, 38.3235414],
    [36.1436093, 38.3230831],
    [36.1494863, 38.3239303],
    [36.1517557, 38.3256028],
    [36.1567096, 38.3329739],
    [36.1612374, 38.3387953],
    [36.1649943, 38.3436722],
    [36.1707716, 38.3461078],
    [36.1774889, 38.3475705],
    [36.1846263, 38.3489997],
    [36.1872906, 38.3595392],
    [36.1861657, 38.3630322],
    [36.1851813, 38.3660883],
    [36.1841263, 38.3693631],
    [36.1830716, 38.3726375],
    [36.1816649, 38.3770036],
    [36.1815422, 38.3773847],
    [36.1815196, 38.3774544],
    [36.1809617, 38.3791867],
    [36.1795194, 38.3836617],
    [36.1895362, 38.3872004],
    [36.2060719, 38.3930628],
    [36.2282652, 38.3995853],
    [36.2319455, 38.4000149],
    [36.236758, 38.4005768],
    [36.2410046, 38.4010727],
    [36.245251, 38.4015682],
    [36.2494977, 38.4020635],
    [36.2523288, 38.4023938],
    [36.2562453, 38.4028502],
    [36.2724603, 38.4040077],
    [36.2724902, 38.40401],
    [36.2807964, 38.4046019],
    [36.2812027, 38.4042903],
    [36.2814146, 38.4040183],
    [36.2816456, 38.4036675],
    [36.2813944, 38.4033975],
    [36.2814105, 38.4031753],
    [36.2815491, 38.4027556],
    [36.2817417, 38.4024992],
    [36.2821527, 38.4023068],
    [36.2829205, 38.4016383],
    [36.2833071, 38.4012303],
    [36.2833132, 38.4012231],
    [36.2833385, 38.401195],
    [36.2835024, 38.4010119],
    [36.2840013, 38.4004561],
    [36.2840485, 38.4004196],
    [36.284408, 38.4002411],
    [36.2845991, 38.3998528],
    [36.2846263, 38.3996325],
    [36.284591, 38.3992167],
    [36.2846788, 38.3988642],
    [36.2846571, 38.3984608],
    [36.284703, 38.3984608],
    [36.2847513, 38.3982682],
    [36.2847127, 38.3977714],
    [36.2847935, 38.3970808],
    [36.284791, 38.3968917],
    [36.284758, 38.3963508],
    [36.2844527, 38.3958956],
    [36.2848055, 38.3956968],
    [36.2875146, 38.3945111],
    [36.2853057, 38.3913416],
    [36.2856397, 38.3910821],
    [36.2861447, 38.3908718],
    [36.2867831, 38.3907938],
    [36.2873932, 38.3907016],
    [36.2875886, 38.3905513],
    [36.2877939, 38.3902599],
    [36.2879344, 38.3900713],
    [36.2884117, 38.3898477],
    [36.2887472, 38.3894963],
    [36.2891147, 38.3889735],
    [36.2892333, 38.3887252],
    [36.289261, 38.3884699],
    [36.2895339, 38.3879974],
    [36.2899289, 38.3874563],
    [36.2902086, 38.3869532],
    [36.2902936, 38.3864946],
    [36.2905564, 38.385776],
    [36.2908872, 38.385133],
    [36.2919519, 38.3840899],
    [36.3192463, 38.380198],
    [36.3273511, 38.3799291],
    [36.3358133, 38.3779936],
    [36.335826, 38.3784174],
    [36.3361341, 38.3789143],
    [36.3363194, 38.3792349],
    [36.3365268, 38.3800371],
    [36.3365893, 38.3802941],
    [36.336733, 38.3804863],
    [36.3369991, 38.3806782],
    [36.3374077, 38.3808216],
    [36.3377546, 38.3808527],
    [36.3380605, 38.3808357],
    [36.3384474, 38.380706],
    [36.3387324, 38.3805768],
    [36.3388949, 38.3804318],
    [36.3389757, 38.380223],
    [36.3390143, 38.3797571],
    [36.3391563, 38.3796282],
    [36.3393807, 38.3796435],
    [36.3395038, 38.3797557],
    [36.3399128, 38.3799311],
    [36.3403821, 38.3800421],
    [36.340566, 38.3800738],
    [36.3408516, 38.380073],
    [36.3416863, 38.3797332],
    [36.3422577, 38.3797799],
    [36.3424213, 38.3798596],
    [36.3425241, 38.380068],
    [36.3425249, 38.3802285],
    [36.3427096, 38.3804368],
    [36.3432603, 38.3804353],
    [36.3438519, 38.3804175],
    [36.3442197, 38.3805447],
    [36.3447706, 38.3805753],
    [36.3453219, 38.3807022],
    [36.3459953, 38.3807483],
    [36.3465669, 38.3808592],
    [36.3472816, 38.3810335],
    [36.347915, 38.3812082],
    [36.3484667, 38.3814152],
    [36.348998, 38.3816224],
    [36.3493467, 38.3819908],
    [36.3495925, 38.382215],
    [36.3498378, 38.3823425],
    [36.3502464, 38.3824858],
    [36.3509606, 38.3825319],
    [36.3513892, 38.3825789],
    [36.3517366, 38.3826902],
    [36.3521658, 38.3828656],
    [36.3524116, 38.3830893],
    [36.3528196, 38.3830721],
    [36.3532058, 38.3827819],
    [36.3535111, 38.3826206],
    [36.3538574, 38.3825874],
    [36.354225, 38.3826183],
    [36.3547342, 38.3824883],
    [36.3551828, 38.3824067],
    [36.3557328, 38.3822928],
    [36.3563849, 38.382146],
    [36.3569561, 38.3821444],
    [36.3576089, 38.3821425],
    [36.3582002, 38.3821246],
    [36.3583622, 38.3818672],
    [36.3584228, 38.3817225],
    [36.358626, 38.3815935],
    [36.3593196, 38.3816232],
    [36.3595842, 38.3814942],
    [36.3598894, 38.3813006],
    [36.360215, 38.381155],
    [36.36048, 38.3811383],
    [36.3607444, 38.3809608],
    [36.3609269, 38.3807356],
    [36.3612114, 38.3805419],
    [36.3616594, 38.3803639],
    [36.3622093, 38.3802177],
    [36.3625156, 38.3802489],
    [36.362985, 38.3803436],
    [36.3635569, 38.3804544],
    [36.3639653, 38.3805656],
    [36.3644761, 38.3807083],
    [36.3649049, 38.3808032],
    [36.3653536, 38.3807858],
    [36.3656594, 38.3807528],
    [36.3659843, 38.3804949],
    [36.3662078, 38.3803017],
    [36.3664522, 38.3802044],
    [36.3667991, 38.3802516],
    [36.3669831, 38.3803153],
    [36.3676158, 38.3803775],
    [36.3682088, 38.3807127],
    [36.3683931, 38.3807925],
    [36.3686578, 38.3807433],
    [36.3690031, 38.3804211],
    [36.3693477, 38.3800027],
    [36.3695919, 38.3799056],
    [36.3699383, 38.3798242],
    [36.3702444, 38.3798392],
    [36.3705097, 38.3798706],
    [36.3708149, 38.3797571],
    [36.3713244, 38.3796431],
    [36.3726707, 38.3796388],
    [36.3729356, 38.3794775],
    [36.3734839, 38.37901],
    [36.3739717, 38.3787033],
    [36.3744394, 38.3784131],
    [36.3747044, 38.37838],
    [36.3782706, 38.3739914],
    [36.3802672, 38.370565],
    [36.3806258, 38.3702838],
    [36.3808686, 38.3700678],
    [36.3810267, 38.3698999],
    [36.3809725, 38.3698131],
    [36.3809203, 38.3697141],
    [36.3808589, 38.3696344],
    [36.3806953, 38.369741],
    [36.3805589, 38.3698347],
    [36.3804325, 38.3697027],
    [36.3803378, 38.3695606],
    [36.3802317, 38.3694093],
    [36.3801239, 38.3693052],
    [36.3801461, 38.3692542],
    [36.3802558, 38.3691731],
    [36.3801586, 38.3690556],
    [36.3801389, 38.3690568],
    [36.3795419, 38.3683106],
    [36.3806736, 38.3680816],
    [36.3823931, 38.3662216],
    [36.3813347, 38.3650131],
    [36.3812416, 38.3650431],
    [36.3811405, 38.3650478],
    [36.3810724, 38.3650769],
    [36.3809789, 38.3650831],
    [36.3809317, 38.3650672],
    [36.380856, 38.3650475],
    [36.3807803, 38.3650017],
    [36.3807374, 38.364955],
    [36.3805277, 38.3649097],
    [36.3804267, 38.3648572],
    [36.3802241, 38.3646792],
    [36.3800892, 38.3645872],
    [36.3799086, 38.3644903],
    [36.3797374, 38.3643981],
    [36.3796075, 38.3643197],
    [36.3794717, 38.3642272],
    [36.3791877, 38.3640336],
    [36.3790577, 38.3639506],
    [36.3790046, 38.3639042],
    [36.378851, 38.3638258],
    [36.3786916, 38.3637617],
    [36.3786207, 38.36372],
    [36.3786031, 38.3636736],
    [36.3786263, 38.3636181],
    [36.3786847, 38.3635483],
    [36.378761, 38.3635108],
    [36.3788552, 38.3634967],
    [36.3789672, 38.363515],
    [36.3791443, 38.3635839],
    [36.3792505, 38.3636344],
    [36.3793688, 38.3637083],
    [36.3794691, 38.3637589],
    [36.3795931, 38.3638003],
    [36.3796813, 38.3638325],
    [36.3797993, 38.36386],
    [36.3799172, 38.3638689],
    [36.3800586, 38.3638917],
    [36.3801766, 38.3639097],
    [36.3802766, 38.3639186],
    [36.3803906, 38.3639369],
    [36.3797806, 38.3632407],
    [36.3799277, 38.3606803],
    [36.3799313, 38.3606222],
    [36.3799621, 38.3605794],
    [36.3799966, 38.3605122],
    [36.3800621, 38.3604267],
    [36.3801396, 38.3603417],
    [36.3801988, 38.3602625],
    [36.3802632, 38.3602072],
    [36.3803068, 38.3601039],
    [36.3803557, 38.3600114],
    [36.3804588, 38.3598513],
    [36.3803185, 38.3597542],
    [36.3806482, 38.3593],
    [36.3807021, 38.3592246],
    [36.3808463, 38.3591225],
    [36.380943, 38.3590522],
    [36.3810974, 38.3589339],
    [36.3811841, 38.3588908],
    [36.3811731, 38.3587542],
    [36.3811416, 38.3585919],
    [36.3810918, 38.3585385],
    [36.3810677, 38.3584864],
    [36.381071, 38.3584475],
    [36.3810946, 38.3584219],
    [36.3811338, 38.3584058],
    [36.381251, 38.3583664],
    [36.3813121, 38.3583381],
    [36.3813593, 38.3582722],
    [36.3813524, 38.3582133],
    [36.3812755, 38.3581706],
    [36.3812277, 38.3581467],
    [36.3812157, 38.35812],
    [36.3812349, 38.3580906],
    [36.3812971, 38.3580742],
    [36.3813632, 38.3580336],
    [36.3813766, 38.3579881],
    [36.3813424, 38.3579614],
    [36.3812799, 38.3579467],
    [36.3803507, 38.3566003],
    [36.3808257, 38.3563897],
    [36.3808332, 38.3563031],
    [36.3809785, 38.35625],
    [36.3811043, 38.3562247],
    [36.3812616, 38.3562025],
    [36.3814071, 38.3561833],
    [36.3814935, 38.3561894],
    [36.3820338, 38.356235],
    [36.3836446, 38.3555381],
    [36.3836268, 38.355455],
    [36.383556, 38.3553678],
    [36.3835557, 38.3553172],
    [36.383573, 38.3552158],
    [36.3836543, 38.3551697],
    [36.3835782, 38.3551331],
    [36.383496, 38.3550506],
    [36.3834488, 38.3549908],
    [36.3834368, 38.3549355],
    [36.3834946, 38.3549189],
    [36.3835949, 38.3549053],
    [36.3837149, 38.3547994],
    [36.3838182, 38.3546911],
    [36.3839181, 38.3545853],
    [36.3840177, 38.3544505],
    [36.3841177, 38.3543394],
    [36.3842008, 38.3542547],
    [36.3843243, 38.35417],
    [36.384451, 38.3540828],
    [36.384543, 38.3540389],
    [36.3846249, 38.3540319],
    [36.384708, 38.3539342],
    [36.3848368, 38.3538464],
    [36.3849388, 38.3538967],
    [36.3849793, 38.353965],
    [36.3850099, 38.3540492],
    [36.3850068, 38.3541389],
    [36.3849774, 38.3542258],
    [36.3849143, 38.3543447],
    [36.3850916, 38.3543153],
    [36.3852888, 38.3542722],
    [36.3855296, 38.3542136],
    [36.3858474, 38.3541492],
    [36.3860077, 38.3540856],
    [36.386288, 38.3539421],
    [36.3864282, 38.3538469],
    [36.3865846, 38.3537039],
    [36.3868449, 38.3535158],
    [36.3870649, 38.3533675],
    [36.3873455, 38.3532322],
    [36.387566, 38.3531497],
    [36.3875832, 38.3532683],
    [36.3876071, 38.3533422],
    [36.387661, 38.3534264],
    [36.3877418, 38.3534972],
    [36.3878763, 38.3535942],
    [36.3880207, 38.3536914],
    [36.3881349, 38.353778],
    [36.3881713, 38.3537039],
    [36.3881943, 38.3535906],
    [36.3881466, 38.3534535],
    [36.3881086, 38.3532272],
    [36.3880943, 38.35304],
    [36.3880868, 38.3528924],
    [36.3880593, 38.3527608],
    [36.3880416, 38.3525813],
    [36.3880041, 38.3524443],
    [36.3879193, 38.3522736],
    [36.3879018, 38.3521206],
    [36.387898, 38.3520336],
    [36.3879074, 38.3519125],
    [36.3879305, 38.35182],
    [36.3879199, 38.3517147],
    [36.3879161, 38.3516144],
    [36.3878785, 38.3515225],
    [36.3878177, 38.3514225],
    [36.3877603, 38.3512986],
    [36.3877063, 38.3512144],
    [36.3876757, 38.3511196],
    [36.3876144, 38.3509514],
    [36.3875732, 38.3507483],
    [36.3875491, 38.3506218],
    [36.3875417, 38.3504769],
    [36.3875377, 38.35034],
    [36.3875133, 38.3501633],
    [36.3874991, 38.3500264],
    [36.387475, 38.3498814],
    [36.3874307, 38.3497708],
    [36.3873566, 38.3496947],
    [36.3873661, 38.3495814],
    [36.3874363, 38.34956],
    [36.3875699, 38.3495199],
    [36.3877306, 38.3494878],
    [36.3878677, 38.3494664],
    [36.3878907, 38.3494107],
    [36.3878772, 38.3493689],
    [36.3877735, 38.349385],
    [36.387713, 38.3493788],
    [36.3876492, 38.3492983],
    [36.3876085, 38.3492194],
    [36.387528, 38.3491538],
    [36.3873264, 38.3491317],
    [36.3872538, 38.3491239],
    [36.387223, 38.3490705],
    [36.3871725, 38.3490047],
    [36.3871385, 38.3489469],
    [36.3871852, 38.3489124],
    [36.3873289, 38.3488514],
    [36.3874791, 38.348798],
    [36.387643, 38.3487421],
    [36.3878033, 38.3486783],
    [36.3878463, 38.3486228],
    [36.3878252, 38.348561],
    [36.3877753, 38.3484986],
    [36.3876952, 38.3484258],
    [36.3875852, 38.3483788],
    [36.3874294, 38.3484006],
    [36.3873318, 38.3481836],
    [36.3872282, 38.3480585],
    [36.3871382, 38.3480014],
    [36.3870663, 38.3479794],
    [36.3869749, 38.3479618],
    [36.3868952, 38.3479353],
    [36.3868238, 38.3478883],
    [36.3866924, 38.3478305],
    [36.3865499, 38.3477794],
    [36.3863018, 38.347695],
    [36.3862218, 38.3476571],
    [36.3861677, 38.3475969],
    [36.3861643, 38.3475497],
    [36.3793877, 38.3426355],
    [36.3769371, 38.3363872],
    [36.3767866, 38.33648],
    [36.3767157, 38.3366913],
    [36.3764574, 38.3373003],
    [36.3763402, 38.3374397],
    [36.3761643, 38.3375535],
    [36.3753143, 38.3376542],
    [36.3744571, 38.3376003],
    [36.3731163, 38.3376406],
    [36.372691, 38.3375956],
    [36.3709405, 38.3368489],
    [36.3708291, 38.3368028],
    [36.3706585, 38.3366952],
    [36.3705141, 38.3366182],
    [36.3703521, 38.3364696],
    [36.370268, 38.3363888],
    [36.3701771, 38.3362675],
    [36.3701057, 38.3361407],
    [36.3700474, 38.3360397],
    [36.3700213, 38.3359788],
    [36.3698527, 38.3357663],
    [36.3697427, 38.3356349],
    [36.3696263, 38.3355441],
    [36.369523, 38.3354835],
    [36.369381, 38.3354282],
    [36.369278, 38.3354235],
    [36.3691174, 38.3354543],
    [36.3689566, 38.3354752],
    [36.3687827, 38.335496],
    [36.3681085, 38.3356468],
    [36.3677696, 38.3359005],
    [36.3676918, 38.3360449],
    [36.3675413, 38.3360146],
    [36.3672657, 38.3358607],
    [36.3672577, 38.3355516],
    [36.3673955, 38.334361],
    [36.3670783, 38.3336975],
    [36.3671346, 38.3332232],
    [36.3667043, 38.3321735],
    [36.366448, 38.331443],
    [36.3658441, 38.3306674],
    [36.3655522, 38.3303331],
    [36.3655555, 38.3297332],
    [36.3656885, 38.3292402],
    [36.3656493, 38.3287352],
    [36.3656653, 38.3279908],
    [36.3658391, 38.3277368],
    [36.3693513, 38.3229991],
    [36.3648518, 38.3085516],
    [36.3582532, 38.3019149],
    [36.358213, 38.3018677],
    [36.357061, 38.3007055],
    [36.3570011, 38.3006533],
    [36.3562943, 38.2999363],
    [36.3624581, 38.2994336],
    [36.362471, 38.2989999],
    [36.3625317, 38.2954983],
    [36.3625477, 38.2947127],
    [36.3635952, 38.2945305],
    [36.3691058, 38.2935511],
    [36.3747019, 38.2914319],
    [36.3761906, 38.2908564],
    [36.3778239, 38.2877603],
    [36.3807314, 38.2873036],
    [36.385425, 38.2881342],
    [36.3891367, 38.2874317],
    [36.3908411, 38.2871092],
    [36.39182, 38.2865825],
    [36.3918156, 38.2863894],
    [36.3919956, 38.2860186],
    [36.3930925, 38.2857411],
    [36.3932139, 38.2860761],
    [36.3942017, 38.2859786],
    [36.3947067, 38.2859358],
    [36.3945092, 38.2853331],
    [36.3944218, 38.2850864],
    [36.3943118, 38.2847453],
    [36.3942366, 38.2845486],
    [36.3945368, 38.2845075],
    [36.3948369, 38.2844964],
    [36.3948899, 38.2846461],
    [36.3949274, 38.2846872],
    [36.3953157, 38.2846947],
    [36.3957117, 38.2847053],
    [36.3961913, 38.2847144],
    [36.3963368, 38.2846975],
    [36.3964686, 38.28466],
    [36.3971339, 38.2844303],
    [36.3974299, 38.2843253],
    [36.3977161, 38.2842428],
    [36.3981639, 38.2841203],
    [36.3985053, 38.2840578],
    [36.3988203, 38.2840097],
    [36.3989894, 38.2839644],
    [36.3994308, 38.2841158],
    [36.3997339, 38.2842061],
    [36.400145, 38.2842931],
    [36.4006722, 38.2844617],
    [36.4005853, 38.2847233],
    [36.4008081, 38.2847447],
    [36.4014883, 38.284815],
    [36.4014703, 38.2849653],
    [36.4020161, 38.28509],
    [36.4023321, 38.2829406],
    [36.4028142, 38.283],
    [36.4036514, 38.2828989],
    [36.4043728, 38.2827889],
    [36.40512, 38.2827242],
    [36.4052189, 38.2827247],
    [36.4054253, 38.2827261],
    [36.4061692, 38.2826508],
    [36.406385, 38.2825206],
    [36.4068422, 38.2821539],
    [36.4070972, 38.2816675],
    [36.4072542, 38.2810256],
    [36.4091283, 38.2810308],
    [36.4091447, 38.2809006],
    [36.4079994, 38.2777242],
    [36.4077475, 38.2762664],
    [36.4070639, 38.2728897],
    [36.4061731, 38.2702522],
    [36.4078083, 38.2645078],
    [36.4095784, 38.2628794],
    [36.4072481, 38.2555682],
    [36.4016795, 38.2489555],
    [36.3977408, 38.2430889],
    [36.3914932, 38.2430889],
    [36.3891217, 38.2415567],
    [36.3881953, 38.2432963],
    [36.3876088, 38.2449999],
    [36.3856107, 38.249493],
    [36.3862785, 38.252146],
    [36.3869873, 38.2550458],
    [36.3857718, 38.2566425],
    [36.3825722, 38.2586],
    [36.3808575, 38.2578304],
    [36.3788353, 38.2571889],
    [36.3785492, 38.2557453],
    [36.3794889, 38.2548792],
    [36.3793868, 38.2539649],
    [36.3811842, 38.253195],
    [36.3824383, 38.2510295],
    [36.3820846, 38.248849],
    [36.3804873, 38.2451958],
    [36.3777756, 38.2434089],
    [36.3820106, 38.2374174],
    [36.3804919, 38.2359416],
    [36.3805386, 38.233859],
    [36.3838874, 38.2327411],
    [36.3848381, 38.2268732],
    [36.3834799, 38.2178036],
    [36.3807227, 38.2108005],
    [36.3913574, 38.2157761],
    [36.3940737, 38.2131083],
    [36.3890484, 38.2085195],
    [36.3909498, 38.2061716],
    [36.3860301, 38.1980188],
    [36.3893201, 38.1908016],
    [36.3889127, 38.1862114],
    [36.3852456, 38.1830087],
    [36.3738914, 38.1763946],
    [36.372207, 38.1722253],
    [36.3682682, 38.1692355],
    [36.3609342, 38.1636828],
    [36.3606625, 38.1596248],
    [36.3516985, 38.1484108],
    [36.3626707, 38.1411665],
    [36.3480314, 38.1224515],
    [36.3511553, 38.1128347],
    [36.3582178, 38.1047128],
    [36.3571312, 38.0970175],
    [36.352785, 38.0914593],
    [36.3462658, 38.089749],
    [36.3427345, 38.0859007],
    [36.343142, 38.0824798],
    [36.3516984, 38.0685808],
    [36.3492538, 38.0535027],
    [36.3406973, 38.0582082],
    [36.3321715, 38.0675738],
    [36.3192213, 38.0618438],
    [36.2899255, 38.0545151],
    [36.2740687, 38.0543591],
    [36.2660884, 38.0537064],
    [36.2654039, 38.0532632],
    [36.2653196, 38.0528779],
    [36.2650918, 38.051815],
    [36.2646278, 38.0512835],
    [36.2641047, 38.0498086],
    [36.263666, 38.0493502],
    [36.2627633, 38.049337],
    [36.2619449, 38.0490513],
    [36.2613712, 38.0485065],
    [36.2612447, 38.0477292],
    [36.260865, 38.0460616],
    [36.2600045, 38.0440816],
    [36.2591693, 38.0433242],
    [36.2581738, 38.0420751],
    [36.2565624, 38.0418492],
    [36.25555, 38.0417229],
    [36.2551366, 38.0411648],
    [36.2547907, 38.0397628],
    [36.2542178, 38.0385618],
    [36.2542852, 38.0377258],
    [36.2536534, 38.0365447],
    [36.2539061, 38.0356357],
    [36.2555068, 38.0347532],
    [36.2558269, 38.0340034],
    [36.2555236, 38.0325435],
    [36.2559617, 38.031515],
    [36.2563492, 38.0305329],
    [36.2566694, 38.0300684],
    [36.2568715, 38.0294977],
    [36.2554225, 38.0275201],
    [36.2544032, 38.0260336],
    [36.2532069, 38.0253965],
    [36.2516736, 38.0249984],
    [36.2508733, 38.0250647],
    [36.2504099, 38.0258213],
    [36.249559, 38.0268034],
    [36.2484133, 38.0272547],
    [36.2476719, 38.0269892],
    [36.2469811, 38.0266176],
    [36.2453383, 38.0249851],
    [36.244757, 38.0238569],
    [36.2439399, 38.0223039],
    [36.2435355, 38.0218991],
    [36.2424656, 38.0217266],
    [36.2411008, 38.0218128],
    [36.2393485, 38.021846],
    [36.2376299, 38.0215739],
    [36.2350014, 38.0222641],
    [36.2343106, 38.0219854],
    [36.2337967, 38.0215806],
    [36.2336029, 38.0203859],
    [36.2342011, 38.0195762],
    [36.2349593, 38.018229],
    [36.2349172, 38.0175055],
    [36.2342516, 38.0165431],
    [36.2337967, 38.013875],
    [36.2326762, 38.0128262],
    [36.2323982, 38.012428],
    [36.2315474, 38.0113394],
    [36.2309071, 38.0103239],
    [36.2309913, 38.0090959],
    [36.2300815, 38.0078546],
    [36.2298624, 38.0059495],
    [36.2288262, 38.0051994],
    [36.2283966, 38.0045621],
    [36.227015, 38.0034602],
    [36.2261978, 38.0017541],
    [36.2262062, 38.0011832],
    [36.2270318, 38.0004994],
    [36.2271919, 37.9997293],
    [36.227571, 37.9983087],
    [36.2285566, 37.9973593],
    [36.2297445, 37.996151],
    [36.229972, 37.9957062],
    [36.2297529, 37.9951352],
    [36.2290537, 37.9951021],
    [36.2280933, 37.9947037],
    [36.2270739, 37.9937809],
    [36.2258187, 37.9928978],
    [36.2251363, 37.9921078],
    [36.2251531, 37.991477],
    [36.2258018, 37.9909459],
    [36.2261219, 37.9901093],
    [36.2257681, 37.9893856],
    [36.2245718, 37.9888478],
    [36.2222804, 37.9873804],
    [36.2209745, 37.986484],
    [36.2198288, 37.9845053],
    [36.2194918, 37.9838148],
    [36.2191043, 37.9826594],
    [36.2187252, 37.9820285],
    [36.217411, 37.9807337],
    [36.2157008, 37.9791134],
    [36.215229, 37.9783962],
    [36.2149089, 37.9766896],
    [36.2155407, 37.9759923],
    [36.2157008, 37.9750294],
    [36.2153975, 37.9743786],
    [36.2148078, 37.9737875],
    [36.2140496, 37.9723464],
    [36.2130386, 37.9714764],
    [36.2118255, 37.970613],
    [36.2120291, 37.9697771],
    [36.2122492, 37.9689859],
    [36.2125473, 37.9684028],
    [36.2132675, 37.9673134],
    [36.2132161, 37.9661567],
    [36.2133663, 37.9658317],
    [36.2132914, 37.9650806],
    [36.2125416, 37.9637257],
    [36.2122214, 37.963294],
    [36.2117665, 37.9622977],
    [36.2101743, 37.9613744],
    [36.2080597, 37.9602253],
    [36.2066444, 37.9588039],
    [36.2056756, 37.9576614],
    [36.205684, 37.9566318],
    [36.2054734, 37.9558214],
    [36.2056503, 37.9549313],
    [36.2058778, 37.9538419],
    [36.2053976, 37.9525598],
    [36.2048584, 37.9506001],
    [36.2044624, 37.949205],
    [36.2047152, 37.9480956],
    [36.2042097, 37.9476106],
    [36.2039654, 37.9470725],
    [36.2035273, 37.9455645],
    [36.2027354, 37.9447739],
    [36.202727, 37.9442092],
    [36.2026006, 37.9441029],
    [36.2015307, 37.943485],
    [36.2013032, 37.9430332],
    [36.2014886, 37.9425682],
    [36.2026017, 37.9418083],
    [36.2046617, 37.9401161],
    [36.2052291, 37.9391591],
    [36.2051353, 37.9386579],
    [36.2045758, 37.9384915],
    [36.2035065, 37.9381147],
    [36.2028333, 37.9372044],
    [36.2024355, 37.9366664],
    [36.2019986, 37.9355958],
    [36.2018762, 37.9349539],
    [36.205197, 37.9369252],
    [36.2104117, 37.9370334],
    [36.2153919, 37.9399797],
    [36.2353571, 37.9466038],
    [36.2679566, 37.9220816],
    [36.2923057, 37.906644],
    [36.2928033, 37.8943859],
    [36.2832378, 37.8828419],
    [36.28266, 37.8678701],
    [36.2709921, 37.8447965],
    [36.2694592, 37.816237],
    [36.2638856, 37.8055434],
    [36.2611416, 37.7964598],
    [36.2555253, 37.7898151],
    [36.2684122, 37.7778333],
    [36.2698274, 37.7720868],
    [36.2699156, 37.7651448],
    [36.2672971, 37.7556632],
    [36.2611518, 37.7516016],
    [36.2606669, 37.7497797],
    [36.2035897, 37.749011],
    [36.1911089, 37.7465178],
    [36.1740299, 37.7387262],
    [36.1547174, 37.7373755],
    [36.146572, 37.7327001],
    [36.1432876, 37.7290634],
    [36.1407914, 37.7270891],
    [36.1371991, 37.7232086],
    [36.1277692, 37.7096089],
    [36.1034375, 37.6861197],
    [36.0872151, 37.6625165],
    [36.0694029, 37.6408679],
    [36.0522712, 37.6273153],
    [36.0372039, 37.6131543],
    [36.0259136, 37.6082843],
    [35.9934992, 37.5875469],
    [35.989072, 37.5837035],
    [35.9838518, 37.5790502],
    [35.9716379, 37.5663131],
    [35.9644281, 37.5494389],
    [35.9616479, 37.534735],
    [35.9650666, 37.5285815],
    [35.9621728, 37.5168932],
    [35.9567982, 37.5097878],
    [35.9516687, 37.5069188],
    [35.9471889, 37.4885968],
    [35.9377426, 37.4873044],
    [35.9293736, 37.4838809],
    [35.921876, 37.475496],
    [35.9215295, 37.4753078],
    [35.9210962, 37.475422],
    [35.9205884, 37.4752351],
    [35.9203141, 37.4744117],
    [35.9210756, 37.4725333],
    [35.9211411, 37.472269],
    [35.9210115, 37.4706282],
    [35.9210115, 37.4701129],
    [35.9212359, 37.4699134],
    [35.9218115, 37.4698314],
    [35.9225803, 37.4700149],
    [35.9230657, 37.469931],
    [35.9231887, 37.4697193],
    [35.9232154, 37.469135],
    [35.9233852, 37.4688992],
    [35.9235575, 37.4688211],
    [35.9238585, 37.4688808],
    [35.924147, 37.468585],
    [35.924438, 37.4683802],
    [35.9248101, 37.4677941],
    [35.9246001, 37.4674786],
    [35.9245954, 37.4671486],
    [35.9250375, 37.4668775],
    [35.9252985, 37.4666572],
    [35.9255832, 37.4663465],
    [35.9260377, 37.4662527],
    [35.9260189, 37.4660437],
    [35.9261123, 37.4659573],
    [35.9263401, 37.4658613],
    [35.9265605, 37.4658613],
    [35.9269281, 37.4660382],
    [35.9272323, 37.4659613],
    [35.9275026, 37.4656571],
    [35.9274302, 37.4653488],
    [35.9270661, 37.465129],
    [35.9271063, 37.4649607],
    [35.9273858, 37.4648498],
    [35.9277095, 37.4646452],
    [35.9278114, 37.4642025],
    [35.9275945, 37.4637676],
    [35.9276192, 37.4633426],
    [35.9278565, 37.4630516],
    [35.9278565, 37.4628783],
    [35.9273118, 37.4623132],
    [35.9272733, 37.4619999],
    [35.9273964, 37.4617145],
    [35.9276897, 37.4614026],
    [35.9274097, 37.4609628],
    [35.926737, 37.4601725],
    [35.9262511, 37.4595452],
    [35.9258533, 37.4589685],
    [35.9256729, 37.4585626],
    [35.9257954, 37.4582627],
    [35.9258156, 37.4580654],
    [35.9256451, 37.4579183],
    [35.9249337, 37.4578113],
    [35.9247589, 37.4576793],
    [35.924741, 37.4574481],
    [35.9250395, 37.4569076],
    [35.9253137, 37.4562669],
    [35.9257609, 37.4560729],
    [35.9259085, 37.4559201],
    [35.9259818, 37.4556398],
    [35.9254456, 37.4552235],
    [35.9250531, 37.455095],
    [35.9250481, 37.4550216],
    [35.9255274, 37.4547432],
    [35.925257, 37.4544936],
    [35.9252734, 37.4542988],
    [35.9253628, 37.4542415],
    [35.9258531, 37.4541846],
    [35.9260274, 37.4539964],
    [35.926022, 37.4533117],
    [35.9261555, 37.4529936],
    [35.9266463, 37.4528009],
    [35.9270783, 37.4524304],
    [35.9271405, 37.4521342],
    [35.9267871, 37.451941],
    [35.9267027, 37.4517878],
    [35.9269049, 37.4514955],
    [35.9268676, 37.4512812],
    [35.9275464, 37.451071],
    [35.9278827, 37.4510413],
    [35.9284992, 37.4508704],
    [35.9287138, 37.4511504],
    [35.9289707, 37.451144],
    [35.9292376, 37.4509579],
    [35.9293189, 37.4506552],
    [35.9291996, 37.4503839],
    [35.9292306, 37.4501904],
    [35.9294418, 37.450019],
    [35.9299849, 37.4497751],
    [35.9302192, 37.4494204],
    [35.9305489, 37.4492101],
    [35.9304811, 37.4489704],
    [35.9305072, 37.4488222],
    [35.9306827, 37.4486685],
    [35.9310627, 37.4485574],
    [35.9314526, 37.4484975],
    [35.9315325, 37.4479896],
    [35.9317727, 37.4477608],
    [35.9322357, 37.4475599],
    [35.9324846, 37.447303],
    [35.9324451, 37.4470563],
    [35.9324894, 37.4469567],
    [35.9326857, 37.446887],
    [35.9330582, 37.4469053],
    [35.9332331, 37.4470767],
    [35.9336066, 37.4471666],
    [35.9339791, 37.4473123],
    [35.9343192, 37.4473074],
    [35.9346284, 37.4471678],
    [35.9349798, 37.4468109],
    [35.9351209, 37.446251],
    [35.9352947, 37.4459942],
    [35.9356164, 37.445857],
    [35.9361838, 37.445788],
    [35.9367333, 37.4458447],
    [35.9371384, 37.4456973],
    [35.9377923, 37.4455285],
    [35.9381147, 37.4453284],
    [35.9388244, 37.4442969],
    [35.938933, 37.4440585],
    [35.9386812, 37.4437414],
    [35.9386999, 37.4436574],
    [35.9391983, 37.4434342],
    [35.9393396, 37.4430709],
    [35.9395488, 37.4428059],
    [35.9399861, 37.4425633],
    [35.9403592, 37.4422578],
    [35.9403727, 37.4420384],
    [35.9402463, 37.441822],
    [35.9408599, 37.4417824],
    [35.9408599, 37.4415713],
    [35.9406735, 37.4412754],
    [35.9398003, 37.4413144],
    [35.9397235, 37.4412437],
    [35.9397781, 37.4410055],
    [35.9400079, 37.4406132],
    [35.9404313, 37.4405155],
    [35.9410205, 37.4403625],
    [35.9414396, 37.4401178],
    [35.941383, 37.4397486],
    [35.9412221, 37.439493],
    [35.9408601, 37.4393642],
    [35.9400619, 37.4394173],
    [35.9399473, 37.4392636],
    [35.9399435, 37.4391294],
    [35.9401777, 37.4389726],
    [35.9407939, 37.4386917],
    [35.9408111, 37.4382383],
    [35.9410328, 37.4377982],
    [35.9408598, 37.4374353],
    [35.9405494, 37.437271],
    [35.9401436, 37.437317],
    [35.940044, 37.4372467],
    [35.9396087, 37.4365143],
    [35.9395713, 37.4360214],
    [35.9391638, 37.4352722],
    [35.9391223, 37.4345967],
    [35.9393497, 37.4338305],
    [35.9387236, 37.433233],
    [35.9386408, 37.4329238],
    [35.938743, 37.4324969],
    [35.9384288, 37.4314552],
    [35.9382444, 37.4312404],
    [35.9367465, 37.4306911],
    [35.9362346, 37.4305019],
    [35.9359215, 37.4298304],
    [35.9365994, 37.4287807],
    [35.9366112, 37.4279112],
    [35.9368339, 37.4264461],
    [35.9369111, 37.4256185],
    [35.9385074, 37.4247416],
    [35.9387036, 37.4241509],
    [35.9377922, 37.4232618],
    [35.9375508, 37.4226887],
    [35.9374921, 37.4218054],
    [35.9367371, 37.4210464],
    [35.9346326, 37.4203517],
    [35.9341478, 37.4203609],
    [35.9330306, 37.4201861],
    [35.9324702, 37.4206114],
    [35.9321298, 37.42065],
    [35.9314542, 37.4203473],
    [35.9308419, 37.4208466],
    [35.9304426, 37.4207442],
    [35.9303687, 37.419833],
    [35.9306554, 37.4194559],
    [35.9304519, 37.418973],
    [35.9299207, 37.41878],
    [35.9293092, 37.4187135],
    [35.928357, 37.4193131],
    [35.9274862, 37.4191171],
    [35.9268718, 37.4191794],
    [35.9256714, 37.4189498],
    [35.925216, 37.4187136],
    [35.9247126, 37.4186063],
    [35.9245527, 37.4195023],
    [35.92358, 37.4198893],
    [35.9234302, 37.4198281],
    [35.9232931, 37.4194363],
    [35.9221163, 37.4194159],
    [35.9218418, 37.4187074],
    [35.9220028, 37.416808],
    [35.921488, 37.4156157],
    [35.9220028, 37.414253],
    [35.9208875, 37.4136057],
    [35.9209304, 37.4124814],
    [35.9213594, 37.4121067],
    [35.9220456, 37.4126858],
    [35.9222601, 37.4120726],
    [35.921917, 37.4109142],
    [35.9209304, 37.4106417],
    [35.9209304, 37.4101306],
    [35.9217883, 37.4097899],
    [35.9218432, 37.4094908],
    [35.9213594, 37.4092789],
    [35.9207666, 37.4091619],
    [35.9202933, 37.4094532],
    [35.9197136, 37.40927],
    [35.9194292, 37.4095514],
    [35.9193005, 37.408836],
    [35.9195125, 37.4082597],
    [35.9198152, 37.4081546],
    [35.9202105, 37.4081235],
    [35.9209023, 37.4088736],
    [35.9212339, 37.4086873],
    [35.9211878, 37.4082227],
    [35.9208875, 37.4068939],
    [35.9207471, 37.4048873],
    [35.9204128, 37.4041776],
    [35.9204294, 37.4037864],
    [35.9208298, 37.4032036],
    [35.9208018, 37.4026008],
    [35.9200297, 37.4019534],
    [35.9202871, 37.4011016],
    [35.9200726, 37.4002157],
    [35.9203299, 37.3992616],
    [35.9202442, 37.3984097],
    [35.9194292, 37.3968422],
    [35.9194983, 37.3961945],
    [35.9196437, 37.3948317],
    [35.9188287, 37.3932982],
    [35.9181637, 37.3924805],
    [35.9174774, 37.3922205],
    [35.9167699, 37.3907083],
    [35.9155794, 37.3874751],
    [35.9147968, 37.3858008],
    [35.9147968, 37.3849147],
    [35.9140676, 37.3840286],
    [35.914325, 37.3820177],
    [35.9152678, 37.3783207],
    [35.9157994, 37.3774759],
    [35.9160917, 37.3757017],
    [35.9163309, 37.3743499],
    [35.9169954, 37.373484],
    [35.9170486, 37.3720265],
    [35.9166499, 37.3713295],
    [35.9161183, 37.3679287],
    [35.9158259, 37.3673373],
    [35.9158259, 37.3665768],
    [35.9159257, 37.3653177],
    [35.9155684, 37.3644726],
    [35.9157221, 37.3636544],
    [35.9159528, 37.3632371],
    [35.9167279, 37.3621448],
    [35.916844, 37.3610971],
    [35.9167296, 37.3602817],
    [35.9163575, 37.359648],
    [35.916377, 37.3582066],
    [35.9165382, 37.3574771],
    [35.916696, 37.3566692],
    [35.9161449, 37.3552326],
    [35.9160917, 37.3540283],
    [35.9168093, 37.3526339],
    [35.9163575, 37.3519367],
    [35.9166499, 37.3508803],
    [35.9159588, 37.3498662],
    [35.9164107, 37.3494647],
    [35.9167562, 37.3487463],
    [35.9173675, 37.3485562],
    [35.918723, 37.348028],
    [35.9187496, 37.3469715],
    [35.9202762, 37.3456826],
    [35.9201572, 37.3449524],
    [35.9197999, 37.344425],
    [35.9187793, 37.3438029],
    [35.9179866, 37.3424407],
    [35.9176594, 37.3417285],
    [35.9173981, 37.3409971],
    [35.9173322, 37.3401206],
    [35.9172893, 37.3396174],
    [35.917111, 37.3392531],
    [35.9169433, 37.3384482],
    [35.916602, 37.3380824],
    [35.9153773, 37.3382717],
    [35.9149058, 37.3379286],
    [35.9148422, 37.3372204],
    [35.9147733, 37.3368834],
    [35.9146542, 37.336539],
    [35.913217, 37.3350799],
    [35.9127858, 37.3344992],
    [35.9126104, 37.3338931],
    [35.9122474, 37.3331458],
    [35.9121284, 37.3319962],
    [35.9109887, 37.3309682],
    [35.9118732, 37.3302784],
    [35.9118732, 37.3296968],
    [35.9106315, 37.3290882],
    [35.9108016, 37.3280602],
    [35.9102062, 37.3259366],
    [35.9106995, 37.3244757],
    [35.9102572, 37.3224331],
    [35.9108356, 37.3205123],
    [35.9108356, 37.3193759],
    [35.9109376, 37.3178879],
    [35.9092366, 37.3170627],
    [35.9088964, 37.3156963],
    [35.9097129, 37.3147493],
    [35.909781, 37.3141676],
    [35.9085222, 37.3136671],
    [35.908182, 37.3128824],
    [35.9084542, 37.3119895],
    [35.9083011, 37.311056],
    [35.9080629, 37.310366],
    [35.907951, 37.3090944],
    [35.9074407, 37.3082657],
    [35.9071005, 37.3043251],
    [35.9063341, 37.3033201],
    [35.9052128, 37.3023291],
    [35.9056281, 37.3015693],
    [35.9065002, 37.3007435],
    [35.906251, 37.2992899],
    [35.9054542, 37.2972417],
    [35.905911, 37.2964158],
    [35.905122, 37.294764],
    [35.9054127, 37.2937068],
    [35.9054127, 37.2927156],
    [35.9048313, 37.2917245],
    [35.9052465, 37.2901716],
    [35.9052465, 37.2894448],
    [35.9041253, 37.2888831],
    [35.9041668, 37.2882884],
    [35.9049974, 37.2874624],
    [35.9038761, 37.2855129],
    [35.9034193, 37.2846208],
    [35.9043744, 37.2832991],
    [35.9045821, 37.2810522],
    [35.9059941, 37.2802592],
    [35.9070738, 37.2794661],
    [35.907323, 37.2787392],
    [35.9070323, 37.2777148],
    [35.9071569, 37.2768226],
    [35.9076137, 37.2763599],
    [35.9088595, 37.2761617],
    [35.9093579, 37.2755668],
    [35.9086104, 37.2746415],
    [35.9091502, 37.2738815],
    [35.9101054, 37.273518],
    [35.9105207, 37.272857],
    [35.9098562, 37.272163],
    [35.9099393, 37.2716012],
    [35.9119742, 37.2701471],
    [35.912447, 37.2690448],
    [35.9123003, 37.2688405],
    [35.9114343, 37.2683956],
    [35.9112596, 37.2679312],
    [35.9118081, 37.2656525],
    [35.9122234, 37.2650245],
    [35.9127632, 37.2618847],
    [35.9127632, 37.2584472],
    [35.9141337, 37.2579845],
    [35.914931, 37.2575002],
    [35.9153829, 37.255829],
    [35.9164726, 37.2546443],
    [35.9172965, 37.2533962],
    [35.9173497, 37.2516402],
    [35.9159942, 37.2515133],
    [35.915436, 37.2509632],
    [35.9154095, 37.2500535],
    [35.9159676, 37.2492073],
    [35.9155689, 37.2486572],
    [35.9155423, 37.2476628],
    [35.9162334, 37.2469858],
    [35.9168713, 37.2449758],
    [35.916951, 37.2431774],
    [35.91626, 37.2425638],
    [35.9126719, 37.2429235],
    [35.9118154, 37.2427513],
    [35.9113026, 37.2423381],
    [35.9112901, 37.2417879],
    [35.9115922, 37.2413644],
    [35.9131514, 37.2407805],
    [35.9133098, 37.2403844],
    [35.9126187, 37.2393053],
    [35.9126453, 37.2387551],
    [35.9130788, 37.2376693],
    [35.9142498, 37.2376903],
    [35.9147156, 37.2373265],
    [35.9146386, 37.2365596],
    [35.9148618, 37.2361224],
    [35.9146061, 37.2357217],
    [35.9146387, 37.235031],
    [35.9139211, 37.2343327],
    [35.9129642, 37.2343115],
    [35.9100938, 37.234375],
    [35.9086585, 37.2334862],
    [35.9064525, 37.2333592],
    [35.905602, 37.2328514],
    [35.9050705, 37.232741],
    [35.9041934, 37.2327456],
    [35.9035555, 37.2322589],
    [35.9013062, 37.2289493],
    [35.9001612, 37.2290921],
    [35.8997892, 37.228663],
    [35.9002066, 37.2272011],
    [35.8978411, 37.2238785],
    [35.8929507, 37.2173386],
    [35.8924537, 37.2147064],
    [35.891861, 37.2141637],
    [35.8912763, 37.2138038],
    [35.8912763, 37.2131053],
    [35.8921268, 37.212555],
    [35.8926052, 37.2120258],
    [35.8926052, 37.2114755],
    [35.8905852, 37.2100573],
    [35.8899739, 37.2091682],
    [35.8894372, 37.2087728],
    [35.8885653, 37.208512],
    [35.8873693, 37.2084485],
    [35.8867752, 37.2078546],
    [35.8873958, 37.207009],
    [35.8869374, 37.2064059],
    [35.8862992, 37.2060785],
    [35.8861005, 37.2056283],
    [35.8862838, 37.2044093],
    [35.8860403, 37.2031775],
    [35.886944, 37.2009199],
    [35.8866038, 37.2004186],
    [35.8844775, 37.1990501],
    [35.884164, 37.1987576],
    [35.8839172, 37.1979756],
    [35.8837185, 37.1970035],
    [35.883654, 37.1962769],
    [35.8841967, 37.195398],
    [35.8841373, 37.1948302],
    [35.8833271, 37.1945742],
    [35.8818739, 37.194286],
    [35.8813466, 37.1939834],
    [35.8807619, 37.193327],
    [35.879805, 37.1914003],
    [35.8797383, 37.1894846],
    [35.8802835, 37.1880549],
    [35.8802835, 37.1870809],
    [35.8797519, 37.186488],
    [35.878609, 37.186234],
    [35.8776394, 37.1857325],
    [35.8764548, 37.1849843],
    [35.8800767, 37.1838235],
    [35.8819402, 37.1824013],
    [35.8826921, 37.1810007],
    [35.8827465, 37.1796346],
    [35.8812496, 37.1771734],
    [35.8805681, 37.175697],
    [35.8814688, 37.1748676],
    [35.8816848, 37.1747667],
    [35.8819148, 37.1746862],
    [35.8821774, 37.1746058],
    [35.8824618, 37.1745725],
    [35.8828226, 37.1745225],
    [35.88307, 37.1745118],
    [35.8833485, 37.1745304],
    [35.8836142, 37.1745758],
    [35.883947, 37.1746437],
    [35.884207, 37.1747037],
    [35.8845342, 37.1748359],
    [35.8847246, 37.1749317],
    [35.8865157, 37.1761772],
    [35.8876137, 37.1768196],
    [35.888675, 37.1773149],
    [35.8892371, 37.1775047],
    [35.8899749, 37.1776539],
    [35.890391, 37.1777057],
    [35.8909202, 37.1777088],
    [35.8915026, 37.1776745],
    [35.8922314, 37.1775512],
    [35.8926097, 37.1774507],
    [35.893224, 37.1772329],
    [35.893815, 37.1769389],
    [35.8943894, 37.1765589],
    [35.8951048, 37.1758356],
    [35.8954682, 37.1751845],
    [35.895642, 37.1748906],
    [35.8957622, 37.1747295],
    [35.895873, 37.1746224],
    [35.8959792, 37.1745274],
    [35.8961134, 37.1744324],
    [35.8962961, 37.1743399],
    [35.8964973, 37.1742591],
    [35.8966453, 37.1742294],
    [35.896812, 37.1742146],
    [35.8970706, 37.174211],
    [35.8973386, 37.1742191],
    [35.8978684, 37.1743004],
    [35.8983152, 37.174399],
    [35.8988676, 37.1745598],
    [35.89918, 37.1746807],
    [35.8994488, 37.1748435],
    [35.8996189, 37.1750165],
    [35.8997622, 37.1751778],
    [35.8998486, 37.1753262],
    [35.899975, 37.1755705],
    [35.9000433, 37.1757698],
    [35.9000645, 37.1760521],
    [35.9000361, 37.176278],
    [35.8999138, 37.176648],
    [35.8997385, 37.1769603],
    [35.8993748, 37.1774727],
    [35.8971136, 37.1798638],
    [35.8967093, 37.1803087],
    [35.8964577, 37.1806729],
    [35.8960376, 37.1814942],
    [35.895671, 37.182345],
    [35.8955498, 37.1827691],
    [35.8955386, 37.1831309],
    [35.8956365, 37.1833736],
    [35.8959194, 37.1836341],
    [35.8961841, 37.1837724],
    [35.8966349, 37.1838696],
    [35.8970363, 37.1838584],
    [35.9013496, 37.1834649],
    [35.9018163, 37.1834748],
    [35.9024625, 37.1835808],
    [35.9031387, 37.1837567],
    [35.9052079, 37.1847386],
    [35.9065258, 37.1856537],
    [35.90736, 37.1863972],
    [35.9097514, 37.1877036],
    [35.9124989, 37.1887897],
    [35.9128325, 37.1888782],
    [35.9132959, 37.1889593],
    [35.914936, 37.1891987],
    [35.9214841, 37.1902887],
    [35.9236935, 37.1908051],
    [35.9245443, 37.1909851],
    [35.9254047, 37.1912824],
    [35.9261315, 37.1916451],
    [35.9283809, 37.192766],
    [35.929234, 37.1934171],
    [35.9301703, 37.1942374],
    [35.930535, 37.1945836],
    [35.9307028, 37.1949406],
    [35.9307508, 37.1951052],
    [35.9307689, 37.1953697],
    [35.9306645, 37.1960385],
    [35.9304865, 37.1972855],
    [35.9305236, 37.1981369],
    [35.930644, 37.1995094],
    [35.930814, 37.2010414],
    [35.9309233, 37.201611],
    [35.9311028, 37.2020962],
    [35.9316164, 37.2029601],
    [35.9318883, 37.2032359],
    [35.9323545, 37.2035703],
    [35.9334807, 37.2042894],
    [35.9340295, 37.2045694],
    [35.9350908, 37.2047612],
    [35.9372437, 37.2044749],
    [35.9383189, 37.2042735],
    [35.9392726, 37.2041539],
    [35.9405903, 37.2040974],
    [35.9441933, 37.2039071],
    [35.9446769, 37.2039057],
    [35.9450012, 37.203974],
    [35.9453323, 37.2041604],
    [35.9456074, 37.2045365],
    [35.9458134, 37.2052317],
    [35.9459475, 37.2056845],
    [35.9455103, 37.2069835],
    [35.9452095, 37.2081342],
    [35.9448811, 37.2099948],
    [35.9462822, 37.2103897],
    [35.9467605, 37.2104831],
    [35.94775, 37.2102019],
    [35.9524128, 37.2071054],
    [35.9553624, 37.2061469],
    [35.9564843, 37.205975],
    [35.9568642, 37.2059493],
    [35.9573543, 37.2059604],
    [35.9579014, 37.2061011],
    [35.9582747, 37.2063057],
    [35.9610694, 37.2085838],
    [35.9622832, 37.2097874],
    [35.9634477, 37.2110106],
    [35.9639237, 37.2115725],
    [35.9643478, 37.212444],
    [35.964899, 37.2136783],
    [35.9651966, 37.2141438],
    [35.9653158, 37.2143175],
    [35.9655875, 37.2147134],
    [35.9660519, 37.2151981],
    [35.9672637, 37.2165891],
    [35.9685203, 37.2179223],
    [35.9692076, 37.2185639],
    [35.9697269, 37.2189079],
    [35.9708854, 37.2194269],
    [35.9716629, 37.2196029],
    [35.9729222, 37.2198585],
    [35.9737331, 37.2199504],
    [35.9739283, 37.2199298],
    [35.9745549, 37.2198636],
    [35.9754427, 37.2196702],
    [35.9763073, 37.2194046],
    [35.9769424, 37.2191239],
    [35.9791872, 37.2179303],
    [35.9796889, 37.217684],
    [35.9803588, 37.2174909],
    [35.9818785, 37.2172888],
    [35.9835227, 37.217037],
    [35.9844752, 37.2171324],
    [35.9877135, 37.2178237],
    [35.9891577, 37.2185153],
    [35.9915836, 37.2187793],
    [35.9927992, 37.2186244],
    [35.9966182, 37.217958],
    [35.9979272, 37.2168131],
    [36.0039147, 37.2149051],
    [36.0049369, 37.214739],
    [36.0067418, 37.2144979],
    [36.0084794, 37.2141756],
    [36.0096504, 37.2139546],
    [36.0136771, 37.2136689],
    [36.0144067, 37.2129682],
    [36.0178184, 37.2129512],
    [36.0204792, 37.2136689],
    [36.0217881, 37.213498],
    [36.0260652, 37.2112349],
    [36.0271817, 37.2108955],
    [36.0318942, 37.2118847],
    [36.03544, 37.2118329],
    [36.0424518, 37.2089865],
    [36.0484474, 37.2073548],
    [36.0494633, 37.207282],
    [36.052534, 37.2071099],
    [36.056335, 37.2064572],
    [36.061066, 37.204565],
    [36.0708565, 37.1974249],
    [36.0751556, 37.1966606],
    [36.0795077, 37.1960556],
    [36.078823, 37.194578],
    [36.0771835, 37.1911852],
    [36.076678, 37.1902739],
    [36.0760282, 37.1896122],
    [36.0755296, 37.1887525],
    [36.0749318, 37.1870394],
    [36.0735939, 37.1821153],
    [36.0701753, 37.1826273],
    [36.0697246, 37.1822221],
    [36.0691073, 37.1807055],
    [36.0691729, 37.1798696],
    [36.0695496, 37.1789169],
    [36.0700178, 37.1779273],
    [36.0713913, 37.1763803],
    [36.0687458, 37.1723846],
    [36.0678285, 37.1702902],
    [36.0676251, 37.1684953],
    [36.0673119, 37.167327],
    [36.0679673, 37.1671464],
    [36.0676844, 37.1657226],
    [36.0710341, 37.1635629],
    [36.0714207, 37.1634919],
    [36.0724314, 37.1634623],
    [36.0760322, 37.1639049],
    [36.0791205, 37.1632142],
    [36.0831513, 37.1616548],
    [36.0856054, 37.1583788],
    [36.0961581, 37.1549804],
    [36.0965446, 37.1434541],
    [36.0940849, 37.1346929],
    [36.087219, 37.1278955],
    [36.0828997, 37.1221819],
    [36.0773473, 37.1155119],
    [36.0779915, 37.105474],
    [36.0881454, 37.1001697],
    [36.0946672, 37.0938374],
    [36.0991659, 37.0845361],
    [36.0895002, 37.0809415],
    [36.0904891, 37.0736673],
    [36.0922806, 37.0703973],
    [36.0950967, 37.0610369],
    [36.0923051, 37.0581042],
    [36.091566, 37.0580015],
    [36.086452, 37.0572914],
    [36.0825255, 37.0606599],
    [36.0766168, 37.0594713],
    [36.0718255, 37.0572131],
    [36.0645587, 37.0569373],
    [36.0628526, 37.0528076],
    [36.0571269, 37.045933],
    [36.0554493, 37.0185091],
    [36.0575643, 37.0082108],
    [36.0570116, 37.00814],
    [36.0497375, 37.0072064],
    [36.0428257, 37.0042586],
    [36.0305679, 36.9942727],
    [36.0239871, 36.9857624],
    [36.0216368, 36.9822579],
    [36.0076561, 36.9712208],
    [35.9960044, 36.9691233],
    [35.9843527, 36.9670256],
    [35.9854555, 36.9452003],
    [36.0199461, 36.9415479],
    [36.0171137, 36.9363856],
    [36.0100755, 36.9311372],
    [36.0070408, 36.9306381],
    [36.0003722, 36.7858063],
    [36.0003722, 36.7339134],
    [35.9650416, 36.670966],
    [35.8884918, 36.6111182],
    [35.7236155, 36.4975949],
    [35.402576, 36.2630414],
    [35.2099655, 36.368],
    [35.2059521, 36.37],
    [35.2002326, 36.373],
    [35.1966012, 36.375],
    [35.1931024, 36.377],
    [35.1897268, 36.379],
    [35.1864663, 36.381],
    [35.1802622, 36.385],
    [35.1744415, 36.389],
    [35.1689648, 36.393],
    [35.1663451, 36.395],
    [35.1625538, 36.398],
    [35.157741, 36.402],
    [35.1554323, 36.404],
    [35.1520847, 36.407],
    [35.1507789, 36.408],
    [35.1442757, 36.411],
    [35.1299423, 36.418],
    [35.1144443, 36.426],
    [35.1101845, 36.428],
    [35.1035621, 36.431],
    [35.0973373, 36.434],
    [35.0952799, 36.435],
    [35.0911717, 36.437],
    [35.0865596, 36.439],
    [35.0800284, 36.442],
    [35.0752963, 36.444],
    [35.0685873, 36.447],
    [35.0637699, 36.449],
    [35.059157, 36.451],
    [35.0547636, 36.453],
    [35.0505672, 36.455],
    [35.0415034, 36.459],
    [35.0372107, 36.461],
    [35.0311175, 36.464],
    [35.0245323, 36.467],
    [35.0097025, 36.474],
    [35.0074035, 36.475],
    [35.001937, 36.477],
    [34.9943306, 36.48],
    [34.9873177, 36.483],
    [34.9808, 36.486],
    [34.9766933, 36.488],
    [34.972757, 36.49],
    [34.9689765, 36.492],
    [34.9635715, 36.495],
    [34.9601301, 36.497],
    [34.9568078, 36.499],
    [34.9535972, 36.501],
    [34.9489755, 36.504],
    [34.9473543, 36.505],
    [34.9395098, 36.509],
    [34.9288922, 36.514],
    [34.9164642, 36.52],
    [34.9100914, 36.523],
    [34.9033654, 36.524],
    [34.8814298, 36.525],
    [34.8712221, 36.526],
    [34.8634008, 36.527],
    [34.856826, 36.528],
    [34.8510527, 36.529],
    [34.8458515, 36.53],
    [34.841086, 36.531],
    [34.8366668, 36.532],
    [34.8325319, 36.533],
    [34.828636, 36.534],
    [34.8212141, 36.536],
    [34.817701, 36.537],
    [34.8111282, 36.539],
    [34.8050595, 36.541],
    [34.7994059, 36.543],
    [34.7941028, 36.545],
    [34.789101, 36.547],
    [34.782082, 36.55],
    [34.7776829, 36.552],
    [34.7714484, 36.555],
    [34.7655981, 36.558],
    [34.7600847, 36.561],
    [34.7548707, 36.564],
    [34.7499254, 36.567],
    [34.7452234, 36.57],
    [34.7392966, 36.574],
  ],
};
