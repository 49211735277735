import React, { useState, useEffect } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { errors } from "../errors/authErrors";
import { useDispatch } from "react-redux";
import { set } from "../auth/userSlice";
import Alert from "@mui/material/Alert";
import axios from "axios";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Cookies from "universal-cookie";
import "./Register.scss";

export default function Register() {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [postData, addFormData] = useState("");
  const [password, setPassword] = useState("");
  const [submitPressed, setSP] = useState(0);
  const [authError, setAuthError] = useState();
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const createUser = () => {
    axios
      .post(
        `https://bfqidatjs5.execute-api.eu-central-1.amazonaws.com/api/users`,
        {
          ...postData,
          username: postData.username + "_" + postData.user_id.slice(0, 6),
        }
      )
      .then((response) => {
        dispatch(set(response.data));
        cookies.set(
          "user",
          {
            ...postData,
            username: postData.username + "_" + postData.user_id.slice(0, 6),
          },
          {
            path: "/",
          }
        );
        setSuccess(true);
        setAuthError("");
      })
      .catch((error) => {
        if (!error.response) {
          // network error
          setAuthError("Bir hata oluştu, daha sonra tekrar deneyin");
        } else {
          setAuthError("Bir hata oluştu, daha sonra tekrar deneyin");
        }
      });
  };

  useEffect(() => {
    if (submitPressed > 0 && postData["user_id"]) {
      createUser();
    } else {
    }
    // eslint-disable-next-line
  }, [submitPressed]);

  const signUp = () => {
    setAuthError();
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        addFormData({
          ...postData,
          user_id: user.uid,
        });
        setSP(submitPressed + 1);
        sendEmailVerification(auth.currentUser).then(() => {
          // Email verification sent!
          // ...
        });
        // navigate("/");
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setAuthError(errors[errorCode] || errorMessage);
        // ..
      });
  };

  const onChange = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      addFormData({
        ...postData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
    } else {
      addFormData({
        ...postData,
        [e.target.name]: e.target.value,
      });
    }
  };

  // if success is true, show RegistrationSuccess component
  if (success) {
    return <h1>Üyelik Oluşturuldu</h1>;
  }
  return (
    <div className="form-container-register">
      <div className="form">
        <h1>Kayıt Ol</h1>
        <input
          className="input"
          label="Username"
          variant="outlined"
          name="username"
          placeholder="Kullanıcı Adı"
          onChange={(e) => onChange(e)}
        />
        <input
          className="input"
          label="Email"
          variant="outlined"
          name="email"
          placeholder="Email"
          onChange={(e) => onChange(e)}
        />
        <div className="password">
          <input
            className="input"
            label="Password"
            variant="outlined"
            name="password"
            placeholder="Şifre"
            type={showPassword ? "text" : "password"}
            onChange={(e) => onChange(e)}
          />
          <div className="show-password">
            {showPassword ? (
              <AiFillEyeInvisible
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <AiFillEye onClick={() => setShowPassword(!showPassword)} />
            )}
          </div>
        </div>

        <button className="button" onClick={() => signUp()}>
          Kayıt Ol
        </button>
        <div className="login">
          <p>
            <a href="/login">Hesabına Giriş Yap</a>
          </p>
        </div>

        {authError && (
          <Alert variant="filled" severity="warning">
            {authError}
          </Alert>
        )}
      </div>
    </div>
  );
}
