import "../auth/Login.scss";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Cookies from "universal-cookie";
import React, { useEffect, useState } from "react";
import Spinner from "../media/spinner.gif";
import { cityBoundaryList } from "../static/cityPolygons/cityBoundaryList";
import axios from "axios";

const izinliSehirler = [
  "Kahramanmaraş",
  "Hatay",
  "Gaziantep",
  "Malatya",
  "Diyarbakır",
  "Kilis",
  "Şanlıurfa",
  "Adıyaman",
  "Osmaniye",
  "Adana",
];

const CagriFormu = () => {
  console.log = console.warn = console.error = () => {};
  // const [infoWindow, setInfoWindow] = useState(null);
  const [mapApiStatus, setMapApiStatus] = useState(0); //0 = tryingForTheFirstTime (loading), 1 = error, 2 = success
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [closeCagri, setCloseCagri] = useState("");
  const [updateMarker, setUpdateMarker] = useState(false);
  const cookies = new Cookies();
  const cookieUser = cookies.get("user");
  const [postData, addFormData] = useState({
    category: "gida",
    address: "",
    city: "Kahramanmaraş",
  });
  const [city, setCity] = useState("Kahramanmaraş");
  const [showInfoAlert, setShowInfoAlert] = useState(true);
  const [success, setSuccess] = useState(false);
  const [address, setAddress] = useState("");
  const [authError, setAuthError] = useState();
  const [validationError, setValidationError] = useState();
  const [marker, setMarker] = useState();
  const [map, setMap] = useState(null);
  const user = useSelector((state) => state.user.value);
  const auth = getAuth();
  const [location, setLocation] = useState({
    latitude: 37.5753,
    longitude: 36.9228,
  });

  function getCityNameByLocation(latitude, longitude) {
    // console.log("deniyorum");
    for (const [cityName, cityBoundaries] of Object.entries(cityBoundaryList)) {
      // console.log("checking", cityName);
      const point = [longitude, latitude];
      const polygon = cityBoundaries;

      let inside = false;
      for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i][0],
          yi = polygon[i][1];
        const xj = polygon[j][0],
          yj = polygon[j][1];
        const intersect =
          yi > point[1] !== yj > point[1] &&
          point[0] < ((xj - xi) * (point[1] - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
      }

      if (inside) {
        setCity(cityName);
        return cityName;
        // break;
      }
    }
    // console.log("denedim");
  }

  useEffect(() => {
    if (isLoggedIn && (!cookieUser || !cookieUser.username)) {
      cookies.remove("user", { path: "/" });
      setIsLoggedIn(false);
    }
  }, [isLoggedIn, cookieUser]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    if (!cookieUser) {
      setIsLoggedIn(false);
    }
  }, [auth, cookieUser]);

  const createEmergencyCall = () => {
    setValidationError("");
    if (postData && !postData.message) {
      setValidationError(
        "Mesaj alanı boş bırakılamaz. Lütfen yardım ihtiyaçlarınızı belirtiniz"
      );
    }
    // if address is empty
    else if (postData && !postData.address) {
      setValidationError(
        "Adres alanı boş bırakılamaz. Lütfen adresinizi giriniz"
      );
    } else {
      // reformat postdata message and address to send new lines properly as json
      axios
        .post(
          "https://rn3ovzqjig.execute-api.eu-central-1.amazonaws.com/dev/emergency/proximity",
          {
            ...postData,
            city: city,
            lat: location.latitude,
            lng: location.longitude,
            // get created_at and updated_at value in epoch time
            created_at: Math.floor(Date.now() / 1000),
            updated_at: Math.floor(Date.now() / 1000),
            media: [],
            status: "0",
          }
        )
        .then((res) => {
          if (res && res.data && res.data.body) {
            const resJson = JSON.parse(res.data.body);
            if (resJson && resJson.closeid) {
              setValidationError(
                "İşaretlediğiniz yere 3 metreden yakın bir yardım çağrısı bulunmaktadır. Lütfen yardım çağrısı oluşturmadan önce yardım çağrılarını kontrol ediniz."
              );
              setCloseCagri(resJson.closeid);
              setSuccess(false);
            } else {
              setValidationError("");
              setSuccess(true);
            }
          }
        })
        .catch((error) => {
          setSuccess(false);
          if (!error.response) {
            // network error
            setValidationError(
              "Servera bağlanılamıyor. Lütfen daha sonra tekrar deneyin"
            );
          } else {
            setValidationError(
              "Bilinmeyen bir hata oluştu. Lütfen daha sonra tekrar deneyin"
            );
          }
        });
    }
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    if (name === "city") {
      console.log("city changed", value);
      setCity(value);
    }

    if (name === "message" || name === "address") {
      const htmlValue = value
        .replace(/\n\n/g, "</p><p>")
        .replace(/\n/g, "<br/>");
      addFormData({
        ...postData,
        [name]: htmlValue,
      });
    } else {
      addFormData({
        ...postData,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    if (auth.currentUser) {
      addFormData({
        ...postData,
        user_id: auth.currentUser.uid,
        email: auth.currentUser.email,
        address: address,
      });
    }

    // eslint-disable-next-line
  }, [auth, user, address]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log("position coords by device location", position.coords);
        setLocation(position.coords);
        setUpdateMarker(true);
      },
      (error) => {
        //  console.error(error)
      }
    );
    // eslint-disable-next-line
  }, []);

  // init map when page is loaded first
  useEffect(() => {
    if (location && !map) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&callback=initMap`;
      script.async = true;
      script.defer = true;

      document.head.appendChild(script);

      let checkMapsLoadedInterval = setInterval(() => {
        if (window.google && window.google.maps) {
          clearInterval(checkMapsLoadedInterval);
          setMap(
            new window.google.maps.Map(document.getElementById("map"), {
              center: { lat: location.latitude, lng: location.longitude },
              zoom: 12,
            })
          );
        }
      }, 500);

      window.initMap = () => {
        // This function is needed for the Google Maps API to work properly
      };
    }
  }, [location]);

  function findAddress(latitude, longitude) {
    console.log("find address", latitude, longitude);
    if (map) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder
        .geocode({
          location: {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          },
        })
        .then((data) => {
          console.log("data", data);
          // setMapApiStatus(2);
          setMapApiStatus(1);
          setAddress(data.results[0].formatted_address);
          const filteredAddress = data.results[0].address_components.filter(
            (component) =>
              component.types.includes("administrative_area_level_1")
          );
          setCity(filteredAddress[0].long_name);

          if (!izinliSehirler.includes(filteredAddress[0].long_name)) {
            setAuthError(
              "Şu anda sadece Kahramanmaraş, Hatay, Gaziantep, Malatya, Diyarbakır, Kilis, Şanlıurfa, Adıyaman, Osmaniye ve Adana illerinde yardım çağrısı açılabilir."
            );
            setMapApiStatus(2);
          } else {
            setAuthError("");
            setMapApiStatus(1);
          }
        })
        .catch((error) => {
          const selectedCityByLatLng = getCityNameByLocation(
            location.latitude,
            location.longitude
          );
          if (!izinliSehirler.includes(selectedCityByLatLng)) {
            setAuthError(
              "Şu anda sadece Kahramanmaraş, Hatay, Gaziantep, Malatya, Diyarbakır, Kilis, Şanlıurfa, Adıyaman, Osmaniye ve Adana illerinde yardım çağrısı açılabilir."
            );
            setMapApiStatus(2);
          } else {
            setAuthError("");
            setMapApiStatus(1);
          }
          //  console.error(error)
        });
    }
  }

  useEffect(() => {
    // console.log("location", location);
    if (location && map) {
      // setAddress("");
      const selectedCityByLatLng = getCityNameByLocation(
        location.latitude,
        location.longitude
      );
      if (!izinliSehirler.includes(selectedCityByLatLng)) {
        setAuthError(
          "Şu anda sadece Kahramanmaraş, Hatay, Gaziantep, Malatya, Diyarbakır, Kilis, Şanlıurfa, Adıyaman, Osmaniye ve Adana illerinde yardım çağrısı açılabilir."
        );
        setMapApiStatus(2);
      } else {
        setAuthError("");
        setMapApiStatus(1);
      }
      console.log("location", location);
    }
  }, [location, map]);

  function placeMarkerAndPanTo(latLng, map, marker) {
    setLocation({
      latitude: latLng.lat(),
      longitude: latLng.lng(),
    });
    // findAddress(latLng.lat(), latLng.lng());
    marker.setPosition(latLng);

    map.panTo(latLng);
  }

  useEffect(() => {
    if (map && location) {
      if (marker) {
        marker.setPosition({
          lat: location.latitude,
          lng: location.longitude,
        });
        map.panTo(marker.getPosition());
        map.setZoom(12);
      } else {
        const newMarker = new window.google.maps.Marker({
          position: {
            lat: location.latitude,
            lng: location.longitude,
          },
          map: map,
        });

        setMarker(newMarker);
      }

      map.addListener("click", (e) => {
        placeMarkerAndPanTo(e.latLng, map, marker);
      });
    }
  }, [map, location, address]);

  useEffect(() => {
    if (map && updateMarker && marker) {
      console.log("updateMarker location with current", updateMarker);
      marker.setPosition({
        lat: location.latitude,
        lng: location.longitude,
      });
      map.panTo(marker.getPosition());
      setUpdateMarker(false);
    }
  }, [updateMarker, marker, location]);
  //  0 --> loading spinner
  //  1 --> dropdown input box for city name
  //  2 --> remove dropdown box and show city name only

  if (success) {
    return (
      <>
        <Alert severity="success">Yardım Çağrınız başarıyla oluşturuldu.</Alert>
        <br />
        <Alert>
          <Link to="/">Anasayfa'ya git</Link>
        </Alert>
        <br />

        <Alert>
          <Link to="/cagrilarim">Çağrılarım'a git</Link>
        </Alert>
      </>
    );
  }
  if (isLoggedIn) {
    return (
      <div>
        <h2>Yardım Çağrısı Oluşturma Formu</h2>
        {showInfoAlert && (
          // <Alert severity="info" onClose={() => setShowInfoAlert(false)}>
          <Alert severity="info" variant="filled">
            Çağrı oluşturmak için yardımın gelmesini istediğiniz noktaya
            haritada tıklayın.
          </Alert>
        )}
        <div id="map" style={{ height: "50vh", width: "100%" }} />
        {mapApiStatus === 0 && (
          <>
            {" "}
            <p>Harita Yükleniyor...</p>
            <img
              src={Spinner}
              alt="loading"
              style={{ width: "6rem", padding: "2rem" }}
            />
          </>
        )}
        <div className="form-container">
          <div className="form">
            {mapApiStatus == 1 && (
              <Alert severity="info" variant="filled">
                Haritada işaretlediğiniz şehri seçmeyi ve adres bilgilerini
                girmeyi unutmayın.
              </Alert>
            )}
            {mapApiStatus == 2 && (
              <Alert severity="error" variant="filled">
                İşaretlediğiniz şehrin afet bölgesinde olduğuna emin olun!
              </Alert>
            )}
            <button
              className="adres-doldur"
              onClick={() => findAddress(location.latitude, location.longitude)}
            >
              İşaretli Adresi Doldur
            </button>
            {
              (mapApiStatus === 1 || mapApiStatus === 2) && (
                <div className="input-container">
                  <label>Şehir:</label>
                  <select
                    className="input"
                    name="city"
                    value={city}
                    onChange={(e) => onChange(e)}
                    defaultValue={city}
                  >
                    {/* map izinliSehirler as options*/}
                    {izinliSehirler.map((sehir) => (
                      <option key={sehir} value={sehir}>
                        {sehir}
                      </option>
                    ))}
                  </select>
                </div>
              )
              // mapApiStatus === 1 && (
              //   <div className="input-container">
              //     <label>Şehir:</label>
              //     {city}
              //   </div>
              // )
            }
            <div className="input-container">
              <label>Adres Detayları:</label>
              <input
                className="input"
                label="Address"
                variant="outlined"
                name="address"
                placeholder="Adres"
                value={postData["address"]}
                onChange={(e) => onChange(e)}
              />
              {/* button to fill out the address automatically */}
            </div>

            <div className="input-container">
              <label>Kategori:</label>
              <select
                className="input"
                name="category"
                onChange={(e) => onChange(e)}
                defaultValue="gida"
              >
                <option value="gida">Gıda</option>
                <option value="saglik">Sağlık</option>
                <option value="diger">Diğer</option>
              </select>
            </div>
            <div className="input-container">
              <label>Mesajınız:</label>
              <textarea
                className="input textarea"
                rows="4"
                label="Message"
                variant="outlined"
                name="message"
                rows={5}
                placeholder="Mesajınız. İhtiyacınız olan ürünler, sağlık durumunuz, ısıtma gibi."
                onChange={(e) => onChange(e)}
              />
            </div>

            {mapApiStatus != 0 && authError && (
              <Alert variant="filled" severity="warning">
                {authError}
              </Alert>
            )}

            {validationError ? (
              <>
                <Alert variant="filled" severity="error">
                  {validationError}
                </Alert>
                {closeCagri && (
                  <Link to={`/cagri/${closeCagri}`} style={{ color: "blue" }}>
                    Yakındaki Çağrıyı Gör
                  </Link>
                )}
              </>
            ) : (
              <button className="button" onClick={() => createEmergencyCall()}>
                Çağrı Oluştur
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
  return (
    <Alert severity="error">
      Lütfen{" "}
      <a href="/login" style={{ color: "blue" }}>
        <b>giriş</b>
      </a>{" "}
      yapınız.
    </Alert>
  );
};
export default CagriFormu;
