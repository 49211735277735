import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cagrilarim: [],
};

const kullaniciCagrilari = createSlice({
  name: "kullanicicagri",
  initialState,
  reducers: {
    setCagrilarim: (state, action) => {
      state.cagrilarim = action.payload;
    },
  },
});

export const { setCagrilarim } = kullaniciCagrilari.actions;
export default kullaniciCagrilari.reducer;
