import "./Bagis.scss";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ibb from "../media/logos/ibb_logo.png";
import abb from "../media/logos/abb_logo.png";
import izmir from "../media/logos/izmirbb_logo.png";
import afad from "../media/logos/afad_logo.png";
import ahbap from "../media/logos/ahbap_logo.png";
import kizilay from "../media/logos/kizilay_logo.png";

const Bagis = () => {
  return (
    <div>
      <h1>Bağış Yapabileceğiniz Yerler </h1>
      <Row>
        <Col xs={12} md={6} lg={4} className="logo-card">
          <a
            className="bagis-link"
            href="https://bagis.ahbap.org/kampanya/donation-campaign-for-earthquake-in-turkey"
            target="_blank"
          >
            <img className="image-card" variant="top" src={ahbap} />
          </a>
        </Col>
        <Col xs={12} md={6} lg={4} className="logo-card">
          <a
            className="bagis-link"
            href="https://afad.gov.tr/depremkampanyasi2"
            target="_blank"
          >
            <img className="image-card" variant="top" src={afad} />
          </a>
        </Col>
        <Col xs={12} md={6} lg={4} className="logo-card">
          <a
            className="bagis-link"
            href="https://www.kizilay.org.tr/Bagis/BagisYap/404/pazarcik-depremi-bagisi#:~:text=deste%C4%9Finize%20ihtiya%C3%A7%20var.-,%C4%B0stedi%C4%9Finiz%20tutarda%20ba%C4%9F%C4%B1%C5%9F%20yaparak%20siz%20de%20depremzedelerimize%20yard%C4%B1m%20eli%20uzatabilirsiniz,k%C4%B1sm%C4%B1na%20%E2%80%9CDEPREM%E2%80%9D%20yaz%C4%B1lmas%C4%B1%20yeterlidir"
            target="_blank"
          >
            <img className="image-card" variant="top" src={kizilay} />
          </a>
        </Col>
        <Col xs={12} md={6} lg={4} className="logo-card">
          <a
            className="bagis-link"
            href="https://forms.ibb.gov.tr/bagis/"
            target="_blank"
          >
            <img className="image-card" variant="top" src={ibb} />
          </a>
        </Col>
        <Col xs={12} md={6} lg={4} className="logo-card">
          <a
            className="bagis-link"
            href="https://www.ankara.bel.tr/duyurular/sartsiz-bagis-yapilabilecek-hesaplar-350"
            target="_blank"
          >
            <img className="image-card" variant="top" src={abb} />
          </a>
        </Col>
        <Col xs={12} md={6} lg={4} className="logo-card">
          <a
            className="bagis-link"
            href="https://yardimlasma.izmir.bel.tr/bagis.html"
            target="_blank"
          >
            <img className="image-card" variant="top" src={izmir} />
          </a>
        </Col>
      </Row>
    </div>
  );
};
export default Bagis;
